import { AutoResizeTextarea } from 'Atoms';
import { TimePeriodsEnums } from 'containers/Esrs/pieces/DisclosureRequirements/Requirement';
import { debounce } from 'lodash';
import { DatapointFieldsFragment_, Esrs_Answer_ } from 'models';
import { useRef, useMemo, useCallback } from 'react';
import { DatapointInput } from '../../DisclosureRequirement.utils';

export const AnswerTextInput = ({
  textAnswer,
  placeholder,
  metricRef,
  answer,
  placement,
  dataPointPerYear,
  onDatapointChange,
  onFocus,
  isSelected,
}: {
  textAnswer?: string;
  placeholder?: string;
  answer?: Esrs_Answer_;
  metricRef?: string;
  placement?: string;
  dataPointPerYear?: DatapointFieldsFragment_;
  onDatapointChange: (val: DatapointInput) => Promise<void>;
  onFocus: () => void;
  isSelected: boolean;
}) => {
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  const handleFocus = () => {
    const textarea = textareaRef.current;
    textarea?.setSelectionRange(textarea.value.length, textarea.value.length);
    onFocus();
  };

  const data = useMemo(
    () =>
      placement === 'before'
        ? dataPointPerYear?.metadata?.before
        : dataPointPerYear?.metadata?.after,
    [placement, dataPointPerYear]
  );

  const debounceSave = useCallback(
    debounce((newValue) => {
      const metadata = placement
        ? {
            ...dataPointPerYear?.metadata,
            [placement]: {
              ...(data ?? {}),
              text: newValue,
            },
          }
        : {};
      onDatapointChange({
        value: newValue,
        factValue: dataPointPerYear?.factValue,
        hasOptedOut: answer?.hasOptedOut ?? false,
        optOutReason: answer?.optOutReason ?? '',
        dp: dataPointPerYear ?? { timeframe: TimePeriodsEnums.year },
        metadata: placement ? metadata : undefined,
      });
    }, 1000),
    [onDatapointChange, answer, dataPointPerYear]
  );

  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newValue = event.target.value === '' ? null : event.target.value;
    debounceSave(newValue);
  };

  return (
    <AutoResizeTextarea
      key={metricRef}
      ref={textareaRef}
      variant="ghost"
      borderRadius="6px"
      padding="0px"
      minH="0px"
      lineHeight="20px"
      cursor={isSelected ? 'text' : 'pointer'}
      placeholder={placeholder}
      defaultValue={textAnswer}
      opacity={answer?.hasOptedOut ? 0.4 : 1}
      onFocus={handleFocus}
      onChange={handleChange}
      onClick={(e) => e.stopPropagation()}
      _focus={{ bg: 'none' }}
      _hover={{ bg: 'none' }}
    />
  );
};
