import { Box, BoxProps, Center, HStack, Icon, Stack, VStack } from '@chakra-ui/react';
import { Button, Checkbox, EmptyState, ExternalLink, VisibleIf } from 'Atoms';
import { useFeatureFlags, useUserSetting } from 'containers/Navigation';
import { TOP_MENU_HEIGHT } from 'containers/Navigation/pieces';
import { ContentLayout } from 'Molecules';
import { useEffect, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'utils/translation';
import { TeamMembersIllustration, Typography } from 'Tokens';
import { nhost } from 'utils/nhost';
import { QuoteIcon } from 'Tokens/Icons/Custom';
import { useCurrentCompany, useCurrentCompanyId } from 'utils/hooks';

export const CommunityTermsAndConditions = ({
  onConfirm,
  ...rest
}: { onConfirm: () => void } & BoxProps) => {
  const [isTermsAcceptedChecked, setIsTermsAcceptedChecked] = useState<boolean>(false);
  const { t } = useTranslation(['community']);

  return (
    <Box w="100%" flexGrow="1" {...rest}>
      <EmptyState
        title={t('community:terms.title')}
        description={
          <Box>
            <Trans i18nKey={'community:terms.description'}>
              <Typography mb="12px" />
              <Typography mb="12px" />
              <Typography mb="12px" />
              <Typography mb="12px">
                <ExternalLink
                  fontSize="md"
                  target="_blank"
                  rel="noopener noreferrer"
                  href={`https://www.celsia.io/user-terms`}
                />
                <ExternalLink
                  fontSize="md"
                  target="_blank"
                  rel="noopener noreferrer"
                  href={`https://www.celsia.io/privacy-policy`}
                />
              </Typography>
              <HStack alignItems="center">
                <Checkbox
                  isChecked={isTermsAcceptedChecked}
                  onChange={(e) => setIsTermsAcceptedChecked(e.target.checked)}
                />
                <Typography />
              </HStack>
            </Trans>
          </Box>
        }
        callToAction={{
          text: t('community:terms.accept'),
          variant: 'primary',
          onClick: onConfirm,
          buttonProps: {
            isDisabled: !isTermsAcceptedChecked,
            mb: '12px',
          },
        }}
        icon={<TeamMembersIllustration boxSize="120px" color="border.default" />}
      />
    </Box>
  );
};

const CommunityNoAccessPage = ({ isLoading }: { isLoading: boolean }) => {
  const { t } = useTranslation(['community']);

  const CommunityExampleBox = ({ exampleText }: { exampleText: string }) => {
    return (
      <VStack
        w="100%"
        bg="bg.interactive"
        borderRadius="12px"
        p="16px"
        display="flex"
        alignItems="start"
        justifyContent="start"
      >
        <Icon as={QuoteIcon} boxSize="24px" color="bg.progress" />
        <Typography variant="body">{exampleText}</Typography>
      </VStack>
    );
  };

  return (
    <ContentLayout header={t('community:title')} isLoading={isLoading}>
      <Center width="100%" flexGrow="1" bg="bg.muted" borderRadius="12px" alignItems={'center'}>
        <VStack
          alignItems={'start'}
          w="50%"
          maxW="700px"
          minW="400px"
          minH="140px"
          spacing={'8px'}
          paddingY="56px"
        >
          <Typography variant={'h2'}>{t('community:noAccess.title')}</Typography>
          <Typography variant={'body'}>{t('community:noAccess.body')}</Typography>
          <Typography variant={'body'}>{t('community:noAccess.bodyTwo')}</Typography>
          <Stack direction="row" paddingY="16px">
            <CommunityExampleBox exampleText={t('community:noAccess.exampleOne')} />
            <CommunityExampleBox exampleText={t('community:noAccess.exampleTwo')} />
            <CommunityExampleBox exampleText={t('community:noAccess.exampleThree')} />
          </Stack>
          <Typography variant={'body'}>{t('community:noAccess.bulletpointsTitle')}</Typography>
          <Box padding="16px">
            <ul>
              <li>
                <Typography variant={'bodyStrong'}>
                  {t('community:noAccess.bulletpoints.one')}
                </Typography>
              </li>
              <li>
                <Typography variant={'bodyStrong'}>
                  {t('community:noAccess.bulletpoints.two')}
                </Typography>
              </li>
              <li>
                <Typography variant={'bodyStrong'}>
                  {t('community:noAccess.bulletpoints.three')}
                </Typography>
              </li>
              <li>
                <Typography variant={'bodyStrong'}>
                  {t('community:noAccess.bulletpoints.four')}
                </Typography>
              </li>
              <li>
                <Typography variant={'bodyStrong'}>
                  {t('community:noAccess.bulletpoints.five')}
                </Typography>
              </li>
            </ul>
          </Box>

          <Button
            onClick={() => {
              // @ts-ignore
              // Ignoring error because of it doesn't recognize fcWidget
              window?.fcWidget?.open();
            }}
          >
            {t('community:noAccess.contactUs')}
          </Button>
        </VStack>
      </Center>
    </ContentLayout>
  );
};

export const Community = () => {
  const [
    hasAcceptedCommunityTerms,
    setHasAcceptedCommunityTerms,
    { loading: isLoadingHasAcceptedCommunityTerms },
  ] = useUserSetting<string | null>('has-accepted-community-terms', null);
  const { company } = useCurrentCompany();
  const flags = useFeatureFlags({ company });
  const [communityRes, setCommunityRes] = useState<{
    data: { ssoToken: string } | null | undefined;
    loading: boolean;
    error: Error | null | undefined;
  }>({ data: null, loading: false, error: null });

  const { companyId } = useCurrentCompanyId();

  useEffect(() => {
    if (!!hasAcceptedCommunityTerms && !communityRes.loading && !communityRes.data) {
      setCommunityRes({ ...communityRes, loading: true });
      nhost.functions
        .call<{ ssoToken: string }>('community/get-sso-token', {})
        .then(({ res }) => setCommunityRes({ data: res?.data, loading: false, error: null }))
        .catch((error) => setCommunityRes({ data: null, loading: false, error }));
    }
  }, [hasAcceptedCommunityTerms]);

  const embedURL = '/?layout=no-header';

  const iframeSource = useMemo(
    () =>
      communityRes.data?.ssoToken
        ? `https://community.celsia.io/api/auth/sso?jwt=${
            communityRes.data?.ssoToken
          }&redirect_uri=${encodeURIComponent(embedURL)}`
        : undefined,
    [communityRes.data?.ssoToken]
  );

  const hasCommunityAccess = useMemo(() => flags.community === true, [flags]);

  if (!hasCommunityAccess) {
    return (
      <CommunityNoAccessPage
        isLoading={communityRes.loading || isLoadingHasAcceptedCommunityTerms}
      />
    );
  }

  return (
    <ContentLayout
      variant="inline.nopad"
      isLoading={communityRes.loading || isLoadingHasAcceptedCommunityTerms}
      header={null}
    >
      <VisibleIf condition={!hasAcceptedCommunityTerms}>
        <CommunityTermsAndConditions
          onConfirm={() => setHasAcceptedCommunityTerms(companyId)}
          padding="16px"
        />
      </VisibleIf>
      <VisibleIf condition={!!hasAcceptedCommunityTerms && !!iframeSource}>
        <iframe
          src={iframeSource}
          width="100%"
          style={{ height: `calc(100vh - ${TOP_MENU_HEIGHT})` }}
        ></iframe>
      </VisibleIf>
    </ContentLayout>
  );
};
