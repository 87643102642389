import { useUserData } from '@nhost/react';
import { useUnshareCompanyAssessmentWithGroup } from 'containers/Groups';
import { useUnshareCompanyAssessment } from 'containers/Investors';
import { useCreateInitialCompany } from 'containers/Navigation';
import {
  GroupAssessmentDocument_,
  GroupAssessmentsDocument_,
  GroupsDocument_,
  useDeleteGroupAssessmentMutation,
  useGroupAssessmentsQuery,
  useGroupsQuery,
  useInvestorsQuery,
  UserCompaniesDocument_,
  useUpsertSubsidiaryMutation,
} from 'models';
import { useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { useCurrentCompany, useCurrentCompanyId } from 'utils/hooks';

export const groupOwnerContext = {
  headers: {
    'x-hasura-role': 'group-owner',
  },
};

export function useDeleteGroupAssessment() {
  const { companyId } = useCurrentCompanyId();
  const [deleteCompanyAssessment] = useDeleteGroupAssessmentMutation();

  const { unshareCompanyAssessment: unshareAssessmentWithPortfolio } =
    useUnshareCompanyAssessment();
  const { unshareCompanyAssessment: unshareAssessmentWithGroup } =
    useUnshareCompanyAssessmentWithGroup();

  const { data: groups } = useGroupsQuery({
    variables: {
      companyId,
    },
    skip: !companyId,
  });

  const { data: investors } = useInvestorsQuery({
    variables: {
      companyId,
    },
    skip: !companyId,
  });

  const { data: groupAssessments } = useGroupAssessmentsQuery({
    variables: {
      companyId,
    },
    skip: !companyId,
  });

  return useCallback(
    async (id: string) => {
      await deleteCompanyAssessment({
        variables: {
          id,
          deletedAt: 'now()',
        },
        refetchQueries: [GroupAssessmentsDocument_],
        awaitRefetchQueries: true,
      });
      const groupAssessment = groupAssessments?.assessments?.find(
        (assessment) => assessment.id === id
      );
      const portfoliosSharedWith = investors?.investors.filter(
        (p) => p.sharedCompanyAssessmentId === groupAssessment?.consolidatedCompanyAssessmentId
      );
      const groupsSharedWith = groups?.groups.filter(
        (g) => g.sharedCompanyAssessmentId === groupAssessment?.consolidatedCompanyAssessmentId
      );
      if (portfoliosSharedWith?.length)
        await Promise.all(
          portfoliosSharedWith.map((p_1) => unshareAssessmentWithPortfolio(p_1.id, p_1.portfolioId))
        );
      if (groupsSharedWith?.length)
        await Promise.all(
          groupsSharedWith.map((g_1) =>
            unshareAssessmentWithGroup(g_1.id, g_1.ownerGroupAssessmentId)
          )
        );
    },
    [deleteCompanyAssessment]
  );
}

export function useAddSubsidiary() {
  const [upsertSubsidiary] = useUpsertSubsidiaryMutation();
  const createInitialCompany = useCreateInitialCompany();
  const { company: currentCompany } = useCurrentCompany();
  const { gAssessmentId } = useParams();
  const user = useUserData();

  return useCallback(
    async (
      selectedCompany: { id?: string; name: string },
      contactPersonName: string,
      contactPersonEmail: string
    ) => {
      if (selectedCompany?.id === undefined) {
        const companyId = await createInitialCompany(
          user?.id ?? '',
          selectedCompany?.name,
          false,
          false,
          currentCompany?.currency ?? 'NOK'
        );
        return await upsertSubsidiary({
          variables: {
            subsidiary: {
              companyId: companyId,
              ownerGroupAssessmentId: gAssessmentId,
              contactPersonName: contactPersonName,
              contactPersonEmail: contactPersonEmail,
            },
          },
          refetchQueries: [GroupAssessmentDocument_, UserCompaniesDocument_, GroupsDocument_],
          awaitRefetchQueries: true,
        });
      } else
        return upsertSubsidiary({
          variables: {
            subsidiary: {
              companyId: selectedCompany?.id,
              ownerGroupAssessmentId: gAssessmentId,
              contactPersonName: contactPersonName,
              contactPersonEmail: contactPersonEmail,
            },
          },
          refetchQueries: [GroupAssessmentDocument_, UserCompaniesDocument_],
          awaitRefetchQueries: true,
        });
    },
    [upsertSubsidiary]
  );
}
