import { Loader } from 'Molecules';
import { Center, VStack, ExpandedIndex, useAccordion, Box } from '@chakra-ui/react';
import { Dictionary, groupBy } from 'lodash';
import { Activity, AttachmentBox, BAssessment } from 'models';
import { ActivityCard } from 'Features/Screening/ManageActivities/ActivityCard/ActivityCard';
import React, { useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDebounce, useToast } from 'utils/hooks';
import { useActivitySearch } from 'utils/hooks/useActivitySearch';
import { useSmartMemo, useSmartCallback } from 'utils/smartMerge';
import { useToggleActivity } from '../Screening.hooks';
import { ActivityDetails } from './ActivityDetails';
import { ActivitiesScrollViewWithSearchHeader } from './ActivitiesScrollView';
import { Accordion, EmptyState, Tag } from 'Atoms';
import { RefreshIcon } from 'Tokens/Icons/Function';
import { activitySort } from 'utils/strings';

const NoSearchResults = ({ setSearch }: { setSearch: (s: string) => void }) => {
  const { t } = useTranslation(['common', 'bUnits']);

  return (
    <Box w="100%" h="100%" mb="16px">
      <EmptyState
        title={t('common:search.filter.emptyTitle')}
        description={t('common:search.filter.emptyDescription')}
        callToAction={{
          text: t('common:search.filter.emptyBtn'),
          variant: 'secondary',
          onClick: () => {
            setSearch('');
          },
          leftIcon: <RefreshIcon color="inherit" />,
        }}
        component={true}
      />
    </Box>
  );
};

const ActivitiesSectorList = ({
  expandedItems,
  setExpandedItems,
  openAttachmentDrawer,
  activitiesBySector,
  handleCheckboxChange,
  setSelectedPreviewActivity,
  bAssessment,
}: {
  expandedItems: ExpandedIndex;
  setExpandedItems: ReturnType<typeof useAccordion>['setIndex'];
  openAttachmentDrawer: (box?: AttachmentBox) => void;
  activitiesBySector: Dictionary<
    {
      activity: Activity;
      isChecked: boolean;
    }[]
  >;
  handleCheckboxChange: (activityRef: string, checked: boolean, versionNumber?: number) => void;
  setSelectedPreviewActivity: (a: Activity) => void;
  bAssessment: BAssessment;
}) => {
  const sectorPanels: {
    label?: string | undefined;
    icon?: JSX.Element | undefined;
    accordionNb?: number | undefined;
    tag?: JSX.Element[] | undefined;
    content?: React.ReactNode;
    onItemClick?: (() => void) | undefined;
  }[] = useMemo(() => {
    return Object.entries(activitiesBySector).map(([sector, activities], index) => {
      activities.sort((a, b) => activitySort(a.activity.name, b.activity.name));
      return {
        label: sector,
        tag: [
          <Tag>
            {activities.filter((activity) => activity.isChecked).length + '/' + activities.length}
          </Tag>,
        ],
        onItemClick: () =>
          setExpandedItems((expanded) =>
            (expanded as number[]).includes(index)
              ? (expanded as number[]).filter((item) => item !== index)
              : [...(expanded as number[]), index]
          ),
        content: (
          <VStack bg="bg.muted" spacing="8px" width="100%">
            {activities.map(({ activity, isChecked }) => (
              <ActivityCard
                key={activity.reference}
                isSelected={isChecked}
                activity={activity}
                activityReportId={
                  bAssessment.activityReports.find(
                    (report) => report.activity.reference == activity.reference
                  )?.id ?? undefined
                }
                openAttachmentDrawer={openAttachmentDrawer}
                variant="selectable"
                onChange={(checked: boolean, versionNumber?: number) =>
                  handleCheckboxChange(activity.reference, checked, versionNumber)
                }
                onLearnClicked={() => setSelectedPreviewActivity(activity)}
              />
            ))}
          </VStack>
        ),
      };
    });
  }, [activitiesBySector, expandedItems, bAssessment]);

  return (
    <Accordion
      width="100%"
      index={expandedItems}
      variant="solid"
      lazyLoadContent={true}
      items={sectorPanels}
    />
  );
};

export const BrowseActivities = ({
  bAssessment,
  openAttachmentDrawer,
}: {
  bAssessment: BAssessment;
  openAttachmentDrawer: (box?: AttachmentBox) => void;
}) => {
  const [searchValue, setSearchValue] = useState('');
  const [selectedCodes, setSelectedCodes] = useState<string[]>([]);
  const [selectedPreviewActivity, setSelectedPreviewActivity] = useState<Activity | undefined>();
  const [toggleActivity] = useToggleActivity(bAssessment);
  const toast = useToast();
  const { t } = useTranslation('bUnits');

  const debouncedQuery = useDebounce(searchValue, 1000);

  const { searchResults = [], naceCodeList } = useActivitySearch(debouncedQuery, selectedCodes);

  const activitiesBySector: Dictionary<
    {
      activity: Activity;
      isChecked: boolean;
    }[]
  > = useSmartMemo(
    () =>
      groupBy(
        searchResults.map((activity) => ({
          activity,
          isChecked: !!bAssessment?.activityReports.find(
            (a) => a.activity.reference === activity.reference
          ),
        })),
        'activity.sector.name'
      ),
    [searchResults, selectedCodes, bAssessment]
  );

  const { index: expandedItems, setIndex: setExpandedItems } = useAccordion({
    allowMultiple: true,
  });

  useEffect(() => {
    if (searchResults && searchValue !== '' && selectedCodes.length !== 0) {
      setExpandedItems(Object.keys(activitiesBySector).map((_sector, index) => index));
    }
  }, [searchResults, selectedCodes]);

  const handleCheckboxChange = useSmartCallback(
    (activityRef: string, checked: boolean, versionNumber?: number) => {
      toggleActivity(checked, activityRef, versionNumber)
        .then(() => {
          toast({
            text: checked ? t('bUnits:toast.activities.add') : t('bUnits:toast.activities.remove'),
          });
        })
        .catch(() => {
          toast({
            text: checked
              ? t('bUnits:toast.activities.failed.add')
              : t('bUnits:toast.activities.failed.remove'),
            variant: 'danger',
          });
        });
    }
  );

  const activitiesList = (() => {
    if (!Object.keys(activitiesBySector).length && searchValue === '') {
      return (
        <Center border="1px solid #8F8F8F" borderRadius="4px" flex="1" minH="200px" width="100%">
          <Loader />
        </Center>
      );
    } else if (!Object.keys(activitiesBySector).length && searchValue !== '') {
      return <NoSearchResults setSearch={setSearchValue} />;
    }
    return (
      <ActivitiesSectorList
        expandedItems={expandedItems}
        setExpandedItems={setExpandedItems}
        openAttachmentDrawer={openAttachmentDrawer}
        activitiesBySector={activitiesBySector}
        handleCheckboxChange={handleCheckboxChange}
        setSelectedPreviewActivity={setSelectedPreviewActivity}
        bAssessment={bAssessment}
      />
    );
  })();

  return (
    <VStack width="100%" justifyContent="flex-start" alignContent="flex-start" spacing="md">
      <ActivitiesScrollViewWithSearchHeader
        searchValue={searchValue}
        setSearchValue={setSearchValue}
        naceCodeList={naceCodeList}
        selectedCodes={selectedCodes}
        setSelectedCodes={setSelectedCodes}
      >
        {activitiesList}
        <ActivityDetails
          isOpen={!!selectedPreviewActivity}
          activity={selectedPreviewActivity}
          onClose={() => setSelectedPreviewActivity(undefined)}
        />
      </ActivitiesScrollViewWithSearchHeader>
    </VStack>
  );
};
