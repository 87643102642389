import { useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useSetCurrentCompanyId } from 'utils/hooks';

export const useSwitchCompany = () => {
  const { companyId: urlCompanyId } = useParams();
  const navigate = useNavigate();
  const setCompanyId = useSetCurrentCompanyId();

  const switchCompany = useCallback(
    (newCompanyId: string) => {
      if (newCompanyId === urlCompanyId) {
        return;
      }
      setCompanyId(newCompanyId);
      navigate(`/${newCompanyId}`);
    },
    [urlCompanyId, setCompanyId]
  );

  return switchCompany;
};
