import { MDRQuestionType } from '../..';
import { TargetFieldsEnum, ActionFieldsEnums } from '../Requirement';
import { sustainabilityMattersEnums } from './MDREnums';

type ChildlessMDRType = {
  title: string;
  type?: MDRQuestionType;
  field: TargetFieldsEnum | ActionFieldsEnums | 'none';
  secondField?: TargetFieldsEnum | ActionFieldsEnums;
  learnMore?: string;
  standardSpecificLearnMores?: { standardRef: string; learnMore: string }[];
  standardSpecificOptions?: { [key: string]: { value: string; label: string }[] };
  options?: { value: string; label: string }[];
  standards?: string[];
};

export type MDRType = ChildlessMDRType & { children?: ChildlessMDRType[] };

export const targetsMDR: MDRType[] = [
  {
    title: 'Name of related impacts, risks and opportunities',
    type: 'LongText',
    field: TargetFieldsEnum.relatedIRO,
    learnMore: 'Describe impacts, risks and opportunities the target is related to.',
  },
  {
    title: 'Name of related policy/policies',
    type: 'LongText',
    field: TargetFieldsEnum.relatedPolicy,
    learnMore: '80. (a) a description of the relationship of the target to the policy objectives.',
  },
  {
    title: 'Sustainability matter(s) addressed by target',
    type: 'Enum',
    field: TargetFieldsEnum.sustainabilityMatter,
    secondField: TargetFieldsEnum.sustainabilityMatterExplanation,
    standardSpecificOptions: sustainabilityMattersEnums,
  },
  {
    title: 'Description of relationship of target to policy objectives',
    type: 'LongText',
    field: TargetFieldsEnum.relationshipToPolicy,
    standardSpecificLearnMores: [
      {
        standardRef: 'ESRS-E2',
        learnMore:
          '<div><p><strong>Related disclosure requirements from E2-3 Targets related to pollution</strong></p><br><p>23. The disclosure required by paragraph 20 shall indicate whether and how its targets relate to the prevention and control of:</p<br><p>(a) air pollutants and respective specific loads;</p><br><p>(b) emissions to water and respective specific loads;</p><br><p>(c) pollution to soil and respective specific loads;</p><br><p>(d) substances of concern and substances of very high concern.</p></div>',
      },
      {
        standardRef: 'ESRS-E3',
        learnMore:
          '<div><p><strong>Related disclosure requirements from E3-3 Targets related to water and marine resources<br></strong><br>23. The disclosure required by paragraph 20 shall indicate whether and how its targets relate to:</p><br><p>(a) the management of material impacts, risks and opportunitiesrelated to areas at water risk, including improvement of thewater quality;</p><br><p>(b) the responsible management of marine resources impacts, risks andopportunities including the nature and quantity of marine resources related commodities (such as gravels, deep-sea minerals, seafood)used by the undertaking;</p><br><p>(c) the reduction of water consumption, including an explanation ofhow those targets relate to areas at water risk, including areas ofhigh water-stress.</p></div>',
      },
      {
        standardRef: 'ESRS-E4',
        learnMore:
          '<div><p><strong>Related disclosure requirements from E4-4 Targets related to biodiversity and ecosystems</strong></p><br><p>32. The disclosure required by paragraph 29 shall include the followinginformation:</p><br><p>(c) how the targets relate to the biodiversity and ecosystem impacts,dependencies, risks and opportunities identified by the undertakingin relation to its own operations and its upstream and downstreamvalue chain;</p></div>',
      },
      {
        standardRef: 'ESRS-E5',
        learnMore:
          '<div><p><strong>Related disclosure requirements from E5-3 Targets related to resource use and circular economy</strong></p><br><p>24. The disclosure required by paragraph 21 shall indicate whether and howthe undertaking&rsquo;s targets relate to resource inflows and resourceoutflows, including waste and products and materials, and, morespecifically to:</p><br><p>(a) the increase of circular product design (including for instancedesign for durability, dismantling, reparability, recyclability etc);<br>(b) the increase of circular material use rate;<br>(c) the minimisation of primary raw material;<br>(d) sustainable sourcing and use (in line with the cascading principle)of renewable resources;<br>(e) the waste management, including preparation for proper treatment;<br>(f) other matters related to resource use or circular economy.</p></div>',
      },
    ],
  },
  {
    title: 'Period to which target applies (start year)',
    type: 'LongText',
    field: TargetFieldsEnum.periodExplanation,
  },
  {
    title:
      'Scope of the target (including activities in own operations, upstream/downstream value chain, and geographical boundaries)',
    type: 'LongText',
    field: TargetFieldsEnum.scope,
    learnMore:
      '80. (c) the scope of the target, including the undertaking’s activities and/or its upstream and/or downstream value chain where applicable and geographical boundaries',
    standardSpecificLearnMores: [
      {
        standardRef: 'ESRS-E4',
        learnMore:
          '<div><p><strong>Related disclosure requirements from E4-4 Targets related to biodiversity and ecosystems</strong></p><br><p>32. The disclosure required by paragraph 29 shall include the followinginformation:</p><br><p>(d) the geographical scope of the targets, if relevant;</p></div>',
      },
    ],
  },
  {
    title: 'Target coverage',
    type: 'Enum',
    field: TargetFieldsEnum.coverage,
    secondField: TargetFieldsEnum.coverageExplanation,
    options: [
      {
        value: 'undertakingsActivitiesMember',
        label: "Undertaking's activities",
      },
      {
        value: 'upstreamValueChainMember',
        label: 'Upstream value chain',
      },
      {
        value: 'downstreamValueChainMember',
        label: 'Downstream value chain',
      },
      {
        value: 'geographiesMember',
        label: 'Geographies',
      },
      {
        value: 'siteLevelMember',
        label: 'Site-level',
      },
    ],
  },
  {
    title:
      'Methodologies and significant assumptions used to define the target, including where applicable, the selected scenario, data sources, alignment with national, EU or international policy goals and how the target considers the wider context of sustainable development and/or local situation in which impacts take place',
    type: 'LongText',
    field: TargetFieldsEnum.methodologies,
    learnMore:
      '80. (f) the methodologies and significant assumptions used to define targets, including where applicable, the selected scenario, data sources, alignment with national, EU or international policy goals and how the targets consider the wider context of sustainable development and/or local situation in which impacts take place',
  },
  {
    title: 'Target related to environmental matters is based on conclusive scientific evidence',
    type: 'Boolean',
    field: TargetFieldsEnum.isEvidenceScientific,
    secondField: TargetFieldsEnum.isEvidenceScientificExplanation,
    standards: ['ESRS-E1', 'ESRS-E2', 'ESRS-E3', 'ESRS-E4', 'ESRS-E5'],
    learnMore:
      '80. (g) whether the undertaking’s targets related to environmental matters are based on conclusive scientific evidence',
  },
  {
    title:
      'Stakeholders have been involved in target setting for each material sustainability matter',
    type: 'Boolean',
    field: TargetFieldsEnum.withStakeholders,
    secondField: TargetFieldsEnum.withStakeholdersExplanation,
    children: [
      {
        title:
          'Description of how stakeholders have been involved in target setting for each material sustainability matter',
        type: 'LongText',
        field: TargetFieldsEnum.withStakeholdersDescription,
        learnMore:
          '80. (h) whether and how stakeholders have been involved in target setting for each material sustainability matter.',
      },
    ],
    learnMore:
      '80. (h) whether and how stakeholders have been involved in target setting for each material sustainability matter. Celsia’s guidance: Here, it is sufficient with a short disclosure, e.g on the form “Multiple stakeholders have been involved in target setting”, while the next input field can be used to describe more in depth how these stakeholders have been involved.',
  },
  {
    title:
      'Description of any changes in the target and corresponding metrics or underlying measurement methodologies, significant assumptions, limitations, sources and adopted processes to collect data',
    type: 'LongText',
    field: TargetFieldsEnum.targetChangeDescription,
    learnMore:
      '80.(i) any changes in targets and corresponding metrics or underlying measurement methodologies, significant assumptions, limitations, sources and processes to collect data adopted within the defined time horizon. This includes an explanation of the rationale for those changes and their effect on comparability (see Disclosure Requirement BP-2 Disclosures in relation to specific circumstances of this Standard)',
  },
  {
    title: 'Performance against the disclosed target (explanation)',
    type: 'LongText',
    field: TargetFieldsEnum.performanceText,
    learnMore:
      '80. (j) description the performance against its disclosed targets, including information on how the target is monitored and reviewed and the metrics used, whether the progress is in line with what had been initially planned, and an analysis of trends or significant changes in the performance of the undertaking towards achieving the target.',
  },
  {
    title: 'Progress is in line with initial plan',
    type: 'Boolean',
    field: TargetFieldsEnum.isProgressAligned,
    secondField: TargetFieldsEnum.isProgressAlignedExplanation,
    learnMore:
      'Celsia’s guidance: Indicate whether or not the progress is following that of the initial plan.',
  },
];

export const actionsScopeMDR: MDRType[] = [
  {
    title: 'Related impacts, risks and opportunities',
    type: 'LongText',
    field: ActionFieldsEnums.relatedIRO,
    learnMore:
      'Describe how the action (plan) addresses impacts, risks and opportunities identified.',
  },
  {
    title: 'Related policies',
    type: 'LongText',
    field: ActionFieldsEnums.relatedPolicy,
    learnMore: 'Describe how the action is linked to policy objectives.',
  },
  {
    title: 'Sustainability matter(s) addressed by action',
    type: 'Enum',
    field: ActionFieldsEnums.sustainabilityMatter,
    standardSpecificOptions: sustainabilityMattersEnums,
    secondField: ActionFieldsEnums.sustainabilityMatterExplanation,
  },
  {
    title:
      'Description of scope of key action (coverage in terms of activities, upstream and (or) downstream value chain, geographies and affected stakeholder groups)',
    field: 'none',
    learnMore:
      'The scope of the key actions (i.e., coverage in terms of activities, upstream and/or downstream value chain,geographies and, where applicable, affected stakeholder groups)',
    standardSpecificLearnMores: [
      {
        standardRef: 'ESRS-E5',
        learnMore:
          '<div><strong>Related application requirements from E5-2 Actions and resources related to Resource Use and Circular Economy</strong><br><br>AR 12. In particular, the undertaking may specify the following:<br>(a) how it contributes to circular economy, including for instance smart<br>waste collection systems;<br>(b) the other stakeholders involved in the collective actions:<br>competitors, suppliers, retailers, customers, other business<br>partners, local communities and authorities, government agencies;<br>(c) a description of the organisation of the collaboration or initiative,<br>including the specific contribution of the undertaking and the roles<br>of the different stakeholders in the project.<br><br>AR 13. The actions may cover the undertaking&rsquo;s own operations and/or its<br>upstream and downstream value chain.<br></div>',
      },
    ],
    children: [
      {
        title: 'Description of scope of key action in own operations',
        type: 'LongText',
        field: ActionFieldsEnums.scope,
        standardSpecificLearnMores: [
          {
            standardRef: 'ESRS-E5',
            learnMore:
              '<div><strong>Related application requirements from E5-2 Actions and resources related to Resource Use and Circular Economy</strong><br><br>AR 12. In particular, the undertaking may specify the following:<br>(a) how it contributes to circular economy, including for instance smart<br>waste collection systems;<br>(b) the other stakeholders involved in the collective actions:<br>competitors, suppliers, retailers, customers, other business<br>partners, local communities and authorities, government agencies;<br>(c) a description of the organisation of the collaboration or initiative,<br>including the specific contribution of the undertaking and the roles<br>of the different stakeholders in the project.<br><br>AR 13. The actions may cover the undertaking&rsquo;s own operations and/or its<br>upstream and downstream value chain.<br></div>',
          },
        ],
      },
      {
        title: 'Description of scope of key action in upstream and (or) downstream value chain',
        type: 'LongText',
        field: ActionFieldsEnums.scopeValueChain,
        standardSpecificLearnMores: [
          {
            standardRef: 'ESRS-E5',
            learnMore:
              '<div><strong>Related application requirements from E5-2 Actions and resources related to Resource Use and Circular Economy</strong><br><br>AR 12. In particular, the undertaking may specify the following:<br>(a) how it contributes to circular economy, including for instance smart<br>waste collection systems;<br>(b) the other stakeholders involved in the collective actions:<br>competitors, suppliers, retailers, customers, other business<br>partners, local communities and authorities, government agencies;<br>(c) a description of the organisation of the collaboration or initiative,<br>including the specific contribution of the undertaking and the roles<br>of the different stakeholders in the project.<br><br>AR 13. The actions may cover the undertaking&rsquo;s own operations and/or its<br>upstream and downstream value chain.<br></div>',
          },
        ],
      },
      {
        title: 'Key action coverage',
        type: 'Enum',
        field: ActionFieldsEnums.coverage,
        secondField: ActionFieldsEnums.coverageExplanation,
        options: [
          {
            value: 'upstreamValueChainMember',
            label: 'Upstream value chain',
          },
          {
            value: 'geographiesMember',
            label: 'Geographies',
          },
          {
            value: 'AffectedStakeholderGroupsMember',
            label: 'Affected stakeholder groups',
          },
          {
            value: 'downstreamValueChainMember',
            label: 'Downstream value chain',
          },
          {
            value: 'undertakingsActivitiesMember',
            label: "Undertaking's activities",
          },
        ],
      },
    ],
  },
  {
    title: 'Status',
    type: 'Enum',
    options: [
      {
        value: 'takenMember',
        label: 'Taken',
      },
      {
        value: 'plannedMember',
        label: 'Planned',
      },
    ],
    field: ActionFieldsEnums.statusOfKeyAction,
  },
  {
    title:
      'Expected outcomes of the action and how its implementation contributes to achievement of policy objectives and targets',
    type: 'LongText',
    field: ActionFieldsEnums.expectedOutcome,
  },
  {
    title:
      'The action provides for, or supports the provision of remedy for those harmed by actual material impacts',
    type: 'Boolean',
    field: ActionFieldsEnums.provisionOfRemedy,
    secondField: ActionFieldsEnums.provisionOfRemedyExplanation,
    learnMore:
      'Description of key action taken, and its results, to provide for and cooperate in or support provision of remedy for those harmed by actual material impacts.',
  },
  {
    title:
      'Quantitative and qualitative information regarding progress of the action disclosed in prior periods',
    type: 'LongText',
    field: ActionFieldsEnums.progressInfo,
  },
];

export const actionsFinancialMDR: MDRType[] = [
  {
    title:
      'Where the implementation of an action requires significant operational expenditures (Opex) and/or capital expenditures (Capex) the undertaking shall provide:',
    field: 'none',
  },
  {
    title:
      'Description of the type of current and future financial investments and resources allocated to the action',
    type: 'LongText',
    field: ActionFieldsEnums.investmentsAndResources,
    learnMore:
      'Describe the type of current and future financial and other resources allocated to the action plan, including if applicable, the relevant terms of sustainable finance instruments, such as green bonds, social bonds and green loans, the environmental or social objectives.',
  },
  {
    title: 'The ability to implement the action depends on specific preconditions',
    type: 'Boolean',
    field: ActionFieldsEnums.hasPreconditions,
    secondField: ActionFieldsEnums.hasPreconditionsExplanation,
    learnMore:
      'Disclosure on whether the ability to implement the actions or action plan depends on specific preconditions, e.g., granting of financial support or public policy and market developments.',
  },
  {
    title: 'Current OpEx allocated to the action',
    type: 'Number',
    field: ActionFieldsEnums.capex,
  },
  {
    title: 'Current CapEx allocated to the action',
    type: 'Number',
    field: ActionFieldsEnums.opex,
  },
  {
    title:
      'Explanation of how the current OpEx and CapEx disclosed relate to the most relevant amounts presented in the financial statements',
    type: 'LongText',
    field: ActionFieldsEnums.noteReference,
  },
  {
    title: 'Future OpEx allocated to the action',
    field: 'none',
    children: [
      {
        title: 'Short-term',
        type: 'Number',
        field: ActionFieldsEnums.opexShortTerm,
      },
      {
        title: 'Medium-term',
        type: 'Number',
        field: ActionFieldsEnums.opexMediumTerm,
      },
      {
        title: 'Long-term',
        type: 'Number',
        field: ActionFieldsEnums.opexLongTerm,
      },
    ],
  },
  {
    title: 'Future CapEx allocated to the action',
    field: 'none',
    children: [
      {
        title: 'Short-term',
        type: 'Number',
        field: ActionFieldsEnums.capexShortTerm,
      },
      {
        title: 'Medium-term',
        type: 'Number',
        field: ActionFieldsEnums.capexMediumTerm,
      },
      {
        title: 'Long-term',
        type: 'Number',
        field: ActionFieldsEnums.capexLongTerm,
      },
    ],
  },
];
