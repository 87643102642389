import { Box, HStack, VStack } from '@chakra-ui/react';
import { IconButton, TruncatableText } from 'Atoms';
import { Typography } from 'Tokens';
import { DocumentationFile } from 'models';
import { getNameExtension } from 'utils/files';
import { useTranslation } from 'utils/translation';
import { FileIcon, defaultStyles } from 'react-file-icon';
import { Menu, MenuAction } from 'Molecules/Menu';
import { RemoveIcon } from 'Tokens/Icons/Function';

export type FileStatus = 'default' | 'uploading' | 'uploaded' | 'failed';
export type FileWidth = {
  boxWidth?: string;
  titleMinWidth?: string;
  titleMaxWidth?: string;
};

export type FileProps = {
  file: DocumentationFile;
  status?: FileStatus;
  actions?: MenuAction[];
  onCancelClick?: () => void;
  hideActionsButton?: boolean;
  fileWidth?: FileWidth;
  size?: 'sm' | 'md';
  onRemove?: () => void;
};

export function File({
  file,
  status = 'default',
  actions,
  onCancelClick,
  fileWidth,
  size = 'md',
  onRemove,
}: FileProps) {
  const { extension } = getNameExtension(file.storageFile?.name ?? '');
  const { t } = useTranslation('common');

  let iconButton;
  const dateAttached = new Date(file.updatedAt)
    .toLocaleDateString('en-GB', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    })
    .replace(/\//g, '.');
  if (status === 'default') {
    iconButton = (
      <Menu
        size="md"
        padding="8px"
        description={`Attached by ${
          file.uploadedBy?.displayName ?? t('common:user.deletedUserName')
        } ${dateAttached}`}
        sections={[
          {
            actions: actions ?? [],
          },
        ]}
      />
    );
  } else {
    iconButton = (
      <IconButton
        aria-label={t('common:actions.delete')}
        size="md"
        variant="ghost"
        icon={<RemoveIcon color="inherit" />}
        onClick={onCancelClick}
        tooltipLabel={t('common:actions.delete')}
      />
    );
  }

  if (size === 'sm')
    return (
      <HStack
        h="28px"
        justifyContent="center"
        alignItems="center"
        spacing="6px"
        p="4px"
        border="1px solid"
        borderColor="border.decorative"
        borderRadius="5px"
      >
        <Box w="16.67px">
          <FileIcon
            extension={extension}
            {...defaultStyles[extension as keyof typeof defaultStyles]}
            labelUppercase
          />
        </Box>

        <TruncatableText variant="bodyStrong" text={file.title}></TruncatableText>

        <IconButton
          variant="ghost"
          aria-label="CloseButton"
          size="xs"
          icon={<RemoveIcon color="inherit" />}
          onClick={onRemove}
        />
      </HStack>
    );

  return (
    <Box
      width={fileWidth?.boxWidth ?? '100%'}
      height="54px"
      padding="8px"
      border="1px"
      borderColor="border.decorative"
      borderRadius="8px"
      bgColor="#FFFFFF"
    >
      <HStack justifyContent="space-between">
        <HStack gap="8px">
          <Box width="32px" padding="4px">
            <FileIcon
              extension={extension}
              {...defaultStyles[extension as keyof typeof defaultStyles]}
              labelUppercase
            />
          </Box>
          <VStack
            minW={fileWidth?.titleMinWidth ?? '120px'}
            maxW={fileWidth?.titleMaxWidth ?? '140px'}
            alignItems="start"
            gap="2px"
          >
            <Typography noOfLines={1} variant="bodyStrong" textOverflow="ellipsis" width="100%">
              {file.title}
            </Typography>
            <TruncatableText
              variant="detail"
              color="text.muted"
              text={file.storageFile?.mimeType?.split('/')[1].toLocaleUpperCase() ?? ''}
            />
          </VStack>
        </HStack>
        <Box data-testid={file.id + '-menu'}>{iconButton}</Box>
      </HStack>
    </Box>
  );
}
