import { Box, HStack, VStack } from '@chakra-ui/react';
import { useMaterialStandardId } from 'containers/Esrs/EsrsAssessment.hooks';
import { useGetBuDisclosureRequirementMetricsQuery, useGetShortEsrsStandardQuery } from 'models';
import { ContentLayout, Loader } from 'Molecules';
import { TOP_MENU_HEIGHT } from 'containers/Navigation/pieces';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Typography } from 'Tokens';
import { MetricViewEnums, UNKONWN_ROUTE } from '..';
import { Structure } from '../../Assessment/Structure';
import { Metrics } from './Metrics';
import { Breadcrumbs } from 'Molecules/Breadcrumbs';

export const MetricsDisclosureRequirements = () => {
  const {
    companyId = '',
    standardRef = '',
    esrsAssessmentId = '',
    reportingUnitId = '',
    disclosureRequirementRef,
    view,
  } = useParams();
  const navigate = useNavigate();
  const [isMetricSidebarOpen, setMetricSidebarOpen] = useState(false);
  const [selectedNodeKey, setSelectedNodeKey] = useState<string>('');

  const { data: standardData, loading: standardLoading } = useGetShortEsrsStandardQuery({
    variables: { reference: standardRef },
    skip: !standardRef,
  });

  const { companyAssessmentId: companyStandardId, parentAssessmentId: parentStandardId } =
    useMaterialStandardId(standardRef, esrsAssessmentId);

  const { data, loading: metricLoading } = useGetBuDisclosureRequirementMetricsQuery({
    variables: {
      standardRef,
      companyStandardId: companyStandardId,
      parentStandardId: parentStandardId || companyStandardId,
    },
    skip: !companyStandardId,
  });

  const standard = useMemo(() => standardData?.esrsStandard, [standardData]);
  const disclosureRequirements = useMemo(
    () =>
      [...(data?.requirements ?? [])].sort((a, b) => {
        if (a.order !== b.order) {
          return a.order - b.order;
        }
        return a.reference.localeCompare(b.reference);
      }),
    [data]
  );

  const handleNodeSelect = (nodeKey: string) => {
    navigate(
      `/${companyId}/esrs/${esrsAssessmentId}/bu-standard/${standardRef}/bu/${reportingUnitId}/disclosure-requirement/${nodeKey}/${
        view ?? MetricViewEnums.dataInput
      }`
    );
    setSelectedNodeKey(nodeKey);
  };

  useEffect(() => {
    if (!disclosureRequirementRef || disclosureRequirementRef === UNKONWN_ROUTE) {
      setSelectedNodeKey(disclosureRequirements[0]?.reference);
    } else {
      setSelectedNodeKey(disclosureRequirementRef);
    }
  }, [disclosureRequirements, disclosureRequirementRef]);

  if (standardLoading || metricLoading) {
    return <Loader />;
  }

  return (
    <Box flexGrow="1" width="100%" display="flex" alignItems="flex-start">
      <HStack alignItems="stretch" flexGrow="1" gap="0" w="100%">
        <VStack
          height={`calc(100vh - ${TOP_MENU_HEIGHT})`}
          borderRight="1px solid"
          borderRightColor="border.decorative"
          minWidth="288px"
          maxWidth="288px"
          alignItems="start"
        >
          <Breadcrumbs truncateMiddleCrumbs />
          <Structure
            overflow="auto"
            w="100%"
            header={
              <VStack p="10px 16px 8px" spacing="24px" alignItems="start">
                <VStack spacing="4px" alignItems="start">
                  <Typography variant="h2">{standard?.title}</Typography>
                  <Typography variant="body" color="text.muted">
                    {standard?.reference}
                  </Typography>
                </VStack>
                <Typography variant="overline" color="text.muted" textTransform="uppercase">
                  Disclosure requirements
                </Typography>
              </VStack>
            }
            nodes={[
              ...disclosureRequirements?.map((dr) => ({
                title: dr.title,
                key: dr.reference,
                reference: dr.reference,
              })),
            ]}
            selectedNodeKey={selectedNodeKey}
            onSelectNode={handleNodeSelect}
            size="sm"
          />
        </VStack>
        <VStack height={`calc(100vh - ${TOP_MENU_HEIGHT})`} width="100%" overflow="hidden auto">
          <ContentLayout variant="inline" header={false}>
            <HStack gap="16px" position="relative">
              <Box
                height={`calc(calc(100vh - ${TOP_MENU_HEIGHT}) - 32px)`}
                overflowY="auto"
                marginRight="-16px"
                paddingRight="16px"
                w="calc(100% + 16px)"
              >
                <Metrics
                  disclosureRequirementRef={selectedNodeKey}
                  setMetricSidebarOpen={setMetricSidebarOpen}
                  disclosureRequirement={
                    disclosureRequirements?.find((dr) => dr.reference === selectedNodeKey) ??
                    undefined
                  }
                />
              </Box>
              {isMetricSidebarOpen && <Box minW="336px"></Box>}
            </HStack>
          </ContentLayout>
        </VStack>
      </HStack>
    </Box>
  );
};
