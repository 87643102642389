import { SearchInput } from 'Molecules';
import mixpanel from 'mixpanel-browser';
import React from 'react';
import { useCurrentCompanyId } from 'utils/hooks';
import { TRACKING_EVENTS } from 'utils/mixpanel';
import { useTranslation } from 'utils/translation';

export const SearchBar = React.forwardRef(function SearchBar(
  {
    search,
    setSearch,
  }: {
    search: string;
    setSearch: (s: string) => void;
  },
  ref: React.ForwardedRef<HTMLInputElement>
) {
  const { t } = useTranslation('files');
  const { companyId } = useCurrentCompanyId();
  return (
    <SearchInput
      width="200px"
      search={search}
      setSearch={(val) => {
        setSearch(val);
        mixpanel.track(TRACKING_EVENTS.DRIVE.SEARCH, { companyId, search: val });
      }}
      placeholder={t('files:searchPlaceholder')}
      ref={ref}
    />
  );
});
