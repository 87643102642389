import { Box, HStack, VStack } from '@chakra-ui/react';
import { IconButton } from 'Atoms';
import { Typography } from 'Tokens';
import { ArrowLeftIcon, ArrowRightIcon } from 'Tokens/Icons/Direction';
import { EsrsReportStandardSwitch } from '../Report.types';

export const BottomStandardSwitcher = ({
  standardIndex,
  setStandardIndex,
  standards,
}: {
  standardIndex: number;
  setStandardIndex: (val: number) => void;
  standards?: EsrsReportStandardSwitch[];
}) => {
  return (
    <HStack justifyContent="space-between" w="100%" pt="32px" pb="48px">
      <Box>
        {standardIndex > 0 && (
          <HStack gap="4px">
            <IconButton
              aria-label="next-standard"
              variant="ghost"
              size="sm"
              icon={<ArrowLeftIcon />}
              onClick={() => setStandardIndex(standardIndex - 1)}
            />
            <VStack alignItems="flex-start" gap="0px">
              <Typography variant="detail">Previous section</Typography>
              <Typography variant="bodyStrong">{standards?.[standardIndex - 1]?.title}</Typography>
            </VStack>
          </HStack>
        )}
      </Box>
      <Box>
        {standardIndex < (standards?.length ?? 1) - 1 && (
          <HStack gap="4px">
            <VStack alignItems="flex-end" gap="0px">
              <Typography variant="detail">Next section</Typography>
              <Typography variant="bodyStrong">{standards?.[standardIndex + 1]?.title}</Typography>
            </VStack>
            <IconButton
              aria-label="next-standard"
              variant="ghost"
              size="sm"
              icon={<ArrowRightIcon />}
              onClick={() => setStandardIndex(standardIndex + 1)}
            />
          </HStack>
        )}
      </Box>
    </HStack>
  );
};
