import { captureException } from '@sentry/browser';
import ExcelJS, { Worksheet } from 'exceljs';
import FileSaver from 'file-saver';
import { isNumber } from 'lodash';
import { formatDigits } from 'utils/numbers';

export enum SCEligibility {
  contributing = 'Y',
  notContributing = 'N',
  eligible = 'EL',
  nonEligible = 'N/EL',
}
export interface RowFormat {
  [key: string]: any;
  activityName?: string;
  code?: string;
  absolute: number;
  proportion: number;
  mitigationSC?: { financial: number; eligibility?: SCEligibility } | number | string;
  adaptationSC?: { financial: number; eligibility?: SCEligibility } | number | string;
  waterSC?: { financial: number; eligibility?: SCEligibility } | number | string;
  pollutionSC?: { financial: number; eligibility?: SCEligibility } | number | string;
  circularSC?: { financial: number; eligibility?: SCEligibility } | number | string;
  biodiversitySC?: { financial: number; eligibility?: SCEligibility } | number | string;
  mitigationDNSH?: boolean;
  adaptationDNSH?: boolean;
  waterDNSH?: boolean;
  circularDNSH?: boolean;
  pollutionDNSH?: boolean;
  biodiversityDNSH?: boolean;
  minimumSafeguards?: boolean;
  alignedN?: number;
  enabling?: number | 'E';
  transitional?: number | 'T';
}

export interface SmallTableData {
  mitigationAligned: number;
  mitigationEligible: number;
  adaptationAligned: number;
  adaptationEligible: number;
  waterAligned: number;
  waterEligible: number;
  circularAligned: number;
  circularEligible: number;
  pollutionAligned: number;
  pollutionEligible: number;
  biodiversityAligned: number;
  biodiversityEligible: number;
}

export interface SheetFormat {
  [key: string]: RowFormat | RowFormat[];
  totalEligibleAligned: RowFormat;
  alignedEnabling: RowFormat;
  alignedTransitional: RowFormat;
  totalEligibleNotAligned: RowFormat;
  totalEligible: RowFormat;
  nonEligible: RowFormat;
  total: RowFormat;
}

export interface TaxonomyTableData {
  companyName: string;
  reportingYear: number;
  currency: string;
  turnover: SheetFormat;
  capex: SheetFormat;
  opex: SheetFormat;
  smallTableDataTurnover: SmallTableData;
  smallTableDataCapex: SmallTableData;
  smallTableDataOpex: SmallTableData;
}

const formatToPercentage = (value: number) => {
  return (value * 100).toFixed(2) + '%';
};

const formatRow = (rowData: RowFormat) => {
  const formattedRow = { ...rowData };

  Object.keys(rowData).forEach((key) => {
    const value = rowData[key as keyof RowFormat];

    if (typeof value === 'object') {
      if (value?.eligibility) {
        if (value?.financial && value?.eligibility === SCEligibility.contributing) {
          // The contributing objective with financials should be bold. Temporarily add "B" to indicate this
          (formattedRow[key as keyof RowFormat] as string) = value?.eligibility + 'B';
        } else if (
          !value?.financial &&
          value?.eligibility === SCEligibility.contributing &&
          key === 'adaptationSC'
        ) {
          (formattedRow[key as keyof RowFormat] as string) = SCEligibility.notContributing;
        } else {
          (formattedRow[key as keyof RowFormat] as string) = value?.eligibility;
        }
      } else {
        (formattedRow[key as keyof RowFormat] as string) = formatToPercentage(value?.financial);
      }
    } else if (key === 'absolute') {
      formattedRow[key as keyof RowFormat] = formatDigits(value, 0)?.toString();
    } else if (isNumber(value)) {
      (formattedRow[key as keyof RowFormat] as string) = formatToPercentage(value);
    } else if (typeof value === 'boolean') {
      (formattedRow[key as keyof RowFormat] as string) = value ? 'Y' : 'N';
    }
  });

  return formattedRow;
};

function setFinancialYear(sheet: ExcelJS.Worksheet, reportingYear: number) {
  sheet.getRow(2).getCell('B').value = reportingYear;
}

function setCurrency(sheet: ExcelJS.Worksheet, currency: string) {
  sheet.getRow(4).getCell('D').value = currency;
}

function updateRow(sheet: ExcelJS.Worksheet, rowNumber: number, rowData: RowFormat) {
  const targetRow = sheet.getRow(rowNumber);
  const formattedRow = formatRow(rowData);

  Object.keys(formattedRow).forEach((key) => {
    targetRow.getCell(key).value = formattedRow[key];
  });
}

function boldCorrectObjective(row: ExcelJS.Row) {
  for (let i = 6; i <= 11; i++) {
    const cell = row.getCell(i);
    if (cell.value === 'YB') {
      // The B indicates it should be formatted bold. Fix it, and remove the B
      cell.style = { ...cell.style, font: { ...cell.style.font, bold: true, size: 11 } };
      cell.value = 'Y';
    }
  }
}

const generateRegularTaxonomyTable = async (data: TaxonomyTableData, sheet: Worksheet) => {
  const COLUMN_HEADERS = [
    { key: 'activityName', letter: 'B' },
    { key: 'code', letter: 'C' },
    { key: 'absolute', letter: 'D' },
    { key: 'proportion', letter: 'E' },
    { key: 'mitigationSC', letter: 'F' },
    { key: 'adaptationSC', letter: 'G' },
    { key: 'waterSC', letter: 'H' },
    { key: 'pollutionSC', letter: 'I' },
    { key: 'circularSC', letter: 'J' },
    { key: 'biodiversitySC', letter: 'K' },
    { key: 'mitigationDNSH', letter: 'L' },
    { key: 'adaptationDNSH', letter: 'M' },
    { key: 'waterDNSH', letter: 'N' },
    { key: 'pollutionDNSH', letter: 'O' },
    { key: 'circularDNSH', letter: 'P' },
    { key: 'biodiversityDNSH', letter: 'Q' },
    { key: 'minimumSafeguards', letter: 'R' },
    { key: 'alignedN', letter: 'S' },
    { key: 'enabling', letter: 'T' },
    { key: 'transitional', letter: 'U' },
  ];

  const sheetData: SheetFormat = (data as any)[sheet.name.toLowerCase()];

  // Set column header keys
  COLUMN_HEADERS.forEach((header) => {
    sheet.getColumn(header.letter).key = header.key;
  });

  setFinancialYear(sheet, data.reportingYear);
  setCurrency(sheet, data.currency);

  //   // ''' Dynamic rows ''' //

  // Insert eligible activities rows
  const eligibleActivityRowIndex = 6;
  sheet.insertRows(
    eligibleActivityRowIndex + 2,
    sheetData.eligibleAlignedActivities.map((row: RowFormat) => formatRow(row)),
    'i'
  ); // Delete the sample row
  // Note: This row is provided in the template to inherit styling to added rows below it.
  // It is then removed after the rows are inserted.
  sheet.spliceRows(7, 1);

  // Insert non-eligible activities rows
  let nonEligibleActivityRowIndex = 0;
  sheet.eachRow((row, rowIndex) => {
    if (row.getCell(2).value?.toString().startsWith('A.2')) {
      nonEligibleActivityRowIndex = rowIndex + 1; //Add one to account for the units row
    }
  });
  sheet.insertRows(
    nonEligibleActivityRowIndex + 2,
    sheetData.eligibleNotAlignedActivities.map((row: RowFormat) => formatRow(row)),
    'i'
  );
  // Delete the sample row
  sheet.spliceRows(nonEligibleActivityRowIndex + 1, 1);

  let totalRowIndex = 0;
  //Insert/splicing messes up the formatting, so we need to reapply it
  sheet.eachRow((row, rowNumber) => {
    const isEligibleRow =
      rowNumber > eligibleActivityRowIndex &&
      rowNumber <= eligibleActivityRowIndex + sheetData.eligibleAlignedActivities.length;
    if (isEligibleRow) {
      boldCorrectObjective(row);
    }
    if (row.getCell('B').value?.toString().includes('A+B')) {
      row.getCell('C').value = '';
      row.getCell('C').merge(row.getCell('B'));
      updateRow(sheet, rowNumber, sheetData.total);
    }
    if (row.getCell('B').value?.toString().includes('of Taxonomy-non-eligible activities')) {
      row.getCell('C').value = '';
      row.getCell('C').merge(row.getCell('B'));
      updateRow(sheet, rowNumber, sheetData.nonEligible);
    }
    if (row.getCell('B').value === 'B. TAXONOMY-NON-ELIGIBLE ACTIVITIES') {
      row.eachCell((cell, colNumber) => {
        if (colNumber !== 2) {
          cell.value = '';
        }
      });
      sheet.unMergeCells(`B${rowNumber}:T${rowNumber}`);
      sheet.mergeCells(`B${rowNumber}:T${rowNumber}`);
    }
    if (row.getCell('B').value?.toString().includes('of Taxonomy-eligible activities  (A.1+A.2)')) {
      row.getCell('C').value = '';
      row.getCell('C').merge(row.getCell('B'));
      updateRow(sheet, rowNumber, sheetData.totalEligible);
    }
    if (
      row
        .getCell('B')
        .value?.toString()
        .includes(
          'of Taxonomy-eligible but not environmentally sustainable activities (not Taxonomy-aligned activities) (A.2)'
        )
    ) {
      row.getCell('C').value = '';
      row.getCell('C').merge(row.getCell('B'));
      updateRow(sheet, rowNumber, sheetData.totalEligibleNotAligned);
    }
    if (
      row.getCell('B').value ===
      'A.2 Taxonomy-Eligible but not environmentally sustainable activities (not Taxonomy-aligned activities)'
    ) {
      row.eachCell((cell, colNumber) => {
        if (colNumber !== 2) {
          cell.value = '';
        }
      });
      sheet.unMergeCells(`B${rowNumber}:T${rowNumber}`);
      sheet.mergeCells(`B${rowNumber}:T${rowNumber}`);
    }
    if (row.getCell('B').value === 'Of which transitional') {
      row.getCell('C').value = '';
      row.getCell('C').merge(row.getCell('B'));
      updateRow(sheet, rowNumber, sheetData.alignedTransitional);
    }
    if (row.getCell('B').value === 'Of which enabling') {
      row.getCell('C').value = '';
      row.getCell('C').merge(row.getCell('B'));
      updateRow(sheet, rowNumber, sheetData.alignedEnabling);
    }
    if (
      row
        .getCell('B')
        .value?.toString()
        .includes('of environmentally sustainable activities (Taxonomy-aligned) (A.1)')
    ) {
      row.getCell('C').value = '';
      row.getCell('C').merge(row.getCell('B'));
      updateRow(sheet, rowNumber, sheetData.totalEligibleAligned);
    }
    if (row.getCell('B').value === 'Total (A+B)') {
      totalRowIndex = rowNumber;
    }
  });
  if (sheet.name === 'CapEx' || sheet.name === 'OpEx') {
    const footnoteRow = totalRowIndex + 2;
    sheet.unMergeCells(`B${footnoteRow}:U${footnoteRow}`);
    sheet.mergeCells(`B${footnoteRow}:U${footnoteRow}`);
    sheet.getRow(footnoteRow).getCell('B').value =
      'As a conservative approach, activities which can contribute both to climate change mitigation and climate change adaptation but which do not have any adaptation financials allocated to them are marked with N for the climate change adaptation objective. This conservative approach follows the Comission Notice on the interpretation of certain legal provisions of the Disclosures Delegated Act under Article 8 of EU Taxonomy Regulation on the reporting of eligible economic activities and assets (2022/C 385/01) which states that activities contributing to adaptation and are not enabling should only count CapEx and OpEx associated with climate change adaptation measures as eligible (and potentially aligned).';
    sheet.getRow(footnoteRow).height = 50;
  }
};

const generateSmallTable = (tableData: TaxonomyTableData, sheet: Worksheet) => {
  const smallTableData = [
    { row: 4, data: tableData.smallTableDataTurnover },
    { row: 13, data: tableData.smallTableDataCapex },
    { row: 22, data: tableData.smallTableDataOpex },
  ];
  smallTableData.forEach(({ row, data }) => {
    sheet.getRow(row).getCell('C').value = formatToPercentage(data.mitigationAligned);
    sheet.getRow(row).getCell('D').value = formatToPercentage(data.mitigationEligible);
    sheet.getRow(row + 1).getCell('C').value = formatToPercentage(data.adaptationAligned);
    sheet.getRow(row + 1).getCell('D').value = formatToPercentage(data.adaptationEligible);
    sheet.getRow(row + 2).getCell('C').value = formatToPercentage(data.waterAligned);
    sheet.getRow(row + 2).getCell('D').value = formatToPercentage(data.waterEligible);
    sheet.getRow(row + 3).getCell('C').value = formatToPercentage(data.circularAligned);
    sheet.getRow(row + 3).getCell('D').value = formatToPercentage(data.circularEligible);
    sheet.getRow(row + 4).getCell('C').value = formatToPercentage(data.pollutionAligned);
    sheet.getRow(row + 4).getCell('D').value = formatToPercentage(data.pollutionEligible);
    sheet.getRow(row + 5).getCell('C').value = formatToPercentage(data.biodiversityAligned);
    sheet.getRow(row + 5).getCell('D').value = formatToPercentage(data.biodiversityEligible);
  });

  const isNanInSmallTable = smallTableData.some((data) =>
    Object.values(data.data).some((value) => isNaN(value))
  );
  if (isNanInSmallTable) {
    sheet.getRow(28).getCell('B').value =
      'If any numbers show as NaN, please press the refresh button on the results page to recalculate the numbers. If the issue persists, please contact the support team.';
    sheet.getRow(28).height = 50;
    sheet.getRow(28).getCell('B').alignment = { wrapText: true };
    sheet.unMergeCells('B28:D28');
    sheet.mergeCells('B28:D28');
  }
};

export const generateTaxonomyTable = async (data: TaxonomyTableData) => {
  try {
    const workbook = new ExcelJS.Workbook();
    const templateFile = await fetch('/assets/taxonomy_table/template.xlsx');
    const templateBuffer = await templateFile.arrayBuffer();

    await workbook.xlsx.load(templateBuffer);

    workbook.eachSheet((sheet) => {
      const s = sheet.name.toLowerCase();
      if (s === 'small tables turnovercapexopex') {
        generateSmallTable(data, sheet);
      } else if (s === 'turnover' || s === 'capex' || s === 'opex') {
        generateRegularTaxonomyTable(data, sheet);
      }
    });

    // exports workbook
    workbook.xlsx.writeBuffer().then((buffer) => {
      const blob = new Blob([buffer], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });
      FileSaver.saveAs(blob, `${data?.companyName}_${data.reportingYear}_Taxonomy_Table.xlsx`);
    });
  } catch (error) {
    captureException(error);
  }
};
