import { Center, HStack, VStack, Box, Stack } from '@chakra-ui/react';
import { Button, ButtonProps } from 'Atoms/Buttons';
import { NothingFoundIllustration, Typography } from 'Tokens';
import { RefreshIcon } from 'Tokens/Icons/Function';
import { useTranslation } from 'utils/translation';

export type EmptyStateProps = {
  title?: string;
  description?: string | JSX.Element;
  callToAction?: {
    text: string;
    onClick: () => void;
    variant: 'primary' | 'secondary' | 'ghost' | 'destructive';
    buttonProps?: ButtonProps;
    leftIcon?: JSX.Element;
    isDisabled?: boolean;
  };
  errorCode?: string;
  icon?: JSX.Element;
  component?: boolean;
  extra?: JSX.Element;
  variant?: 'gray' | 'white';
  iconBoxHeight?: string;
};

export const EmptyState = ({
  title,
  description,
  callToAction,
  errorCode,
  icon,
  component = false,
  extra,
  variant = 'gray',
  iconBoxHeight,
  ...props
}: EmptyStateProps) => {
  const variantStyles = {
    gray: {
      bg: 'bg.muted',
      border: 'none',
      borderColor: '',
      pt: component ? '96px' : '',
      pb: component ? '96px' : '',
      description: component ? 'detail' : 'body',
      stackSpacing: component ? '16px' : '48px',
      VStackMinH: '140px',
      buttonMarginTop: component ? '12px !important' : '16px !important',
    },
    white: {
      bg: 'bg.default',
      border: '1px dashed',
      borderColor: 'border.default',
      pt: component ? '24px' : '',
      pb: component ? '16px' : '',
      description: 'body',
      stackSpacing: '4px',
      VStackMinH: 'fit-content',
      buttonMarginTop: '0px',
    },
  };

  const style = variantStyles[variant];

  return (
    <Center
      width="100%"
      height="100%"
      {...props}
      bg={style.bg}
      border={style.border}
      borderColor={style.borderColor}
      borderRadius="12px"
      alignItems={component ? 'start' : 'center'}
      pt={style.pt}
      pb={style.pb}
    >
      <Stack
        spacing={style.stackSpacing}
        w={component ? '320px' : '588px'}
        direction={component ? 'column-reverse' : 'row'}
      >
        <VStack
          alignItems={component ? 'center' : 'start'}
          w={component ? '320px' : '400px'}
          minH={style.VStackMinH}
          spacing={component ? '4px' : '8px'}
        >
          <Typography variant={component ? 'h4' : 'h2'}>{title}</Typography>
          <Typography
            variant={style.description as 'body' | 'detail'}
            textAlign={component ? 'center' : undefined}
          >
            {description}
          </Typography>
          {errorCode && (
            <Box
              h="36px"
              w="100%"
              mt={component ? '16px !important' : '24px !important'}
              bg="bg.unknown.muted"
              borderRadius="8px"
              p="8px"
            >
              <Typography variant="body" color="text.muted">
                {errorCode}
              </Typography>
            </Box>
          )}
          {callToAction && (
            <HStack mt={style.buttonMarginTop}>
              <Button
                variant={callToAction?.variant}
                onClick={callToAction?.onClick}
                leftIcon={callToAction?.leftIcon}
                isDisabled={callToAction?.isDisabled}
                {...callToAction?.buttonProps}
              >
                {callToAction?.text}
              </Button>
              <>{extra}</>
            </HStack>
          )}
        </VStack>
        {icon && (
          <Box alignSelf={component ? 'center' : 'start'} height={iconBoxHeight}>
            {icon}
          </Box>
        )}
      </Stack>
    </Center>
  );
};

export const SearchEmptyState = ({ setSearch }: { setSearch: (search: string) => void }) => {
  const { t } = useTranslation();
  return (
    <EmptyState
      title={t('common:search.filter.emptyTitle')}
      description={t('common:search.filter.emptyDescription')}
      icon={<NothingFoundIllustration boxSize="120px" />}
      callToAction={{
        text: t('common:search.filter.emptyBtn'),
        variant: 'secondary',
        onClick: () => {
          setSearch('');
        },
        leftIcon: <RefreshIcon color="inherit" />,
      }}
      component={true}
    />
  );
};
