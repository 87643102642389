import { HStack } from '@chakra-ui/react';
import { TruncatableText } from 'Atoms';
import { MetricTypes, MetricTypeIcon } from 'Molecules/MetricTypeIcon';
import { UserAvatar } from 'Organisms';
import { Typography } from 'Tokens';
import { QuestionType_Enum_, ShortUser } from 'models';
import { useMemo } from 'react';
import { AssessableMetrics } from '../../../Metrics';
import { getMetricRefNumber } from '../../../Metrics.hooks';

export const AnswerPopover = ({
  metric,
  isVisible,
  padding,
  owner,
}: {
  metric: AssessableMetrics[number];
  isVisible: boolean;
  padding?: number;
  owner?: ShortUser;
}) => {
  const metricType = useMemo(
    () =>
      metric.metricType === QuestionType_Enum_.Decimal_ ? MetricTypes.number : MetricTypes.text,
    [metric]
  );
  return (
    <HStack
      position="absolute"
      w="100%"
      style={{ transform: 'translateY(calc(-100% - 4px))' }}
      shadow="interactive.accent"
      borderRadius="8px"
      padding="8px"
      bg="bg.default"
      gap="8px"
      ml={`-${padding}px`}
      opacity={isVisible ? 1 : 0}
      transition="all 0.4s"
      pointerEvents="none"
    >
      <MetricTypeIcon type={metricType} />
      <Typography variant="body" whiteSpace="nowrap">
        {getMetricRefNumber(metric)}
      </Typography>
      <UserAvatar user={owner} size="xs" />
      <TruncatableText variant="body" text={metric.title} />
    </HStack>
  );
};
