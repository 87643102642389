import {
  Box,
  Collapse,
  HStack,
  IconButton,
  Skeleton,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import { useUserData } from '@nhost/react';
import { Tabs, Tag, Tooltip } from 'Atoms';
import { CriteriaInput } from 'Features/Screening/QuestionInput';
import { uniqBy } from 'lodash';
import {
  AttachmentBox,
  GetPaiAnswerDocumentationByIdDocument_,
  InvesteeMetric,
  NoteHistory,
  PaiIndicator,
  ShortUser,
  useGetPaiAnswerDocumentationByIdQuery,
} from 'models';
import { Modal, ModalProps } from 'Molecules';
import { StateStatus } from 'Molecules/CardStatus';
import { InputCard } from 'Molecules/InputCard';
import { InputCardDocumentationProps } from 'Molecules/InputCard/InputCardDocumentation';
import React, { Suspense, useMemo } from 'react';
import { Typography } from 'Tokens';
import { ResourceIcon } from 'Tokens/Icons/Data';
import { HelpIcon } from 'Tokens/Icons/Status';
import { useCurrentCompanyId } from 'utils/hooks';
import { useTranslation } from 'utils/translation';
import { useIsAuditor, usePaiAnswer } from '../CompanyPai.hooks';
import { PaiIndicatorStatusFilter } from './CompanyPaiReport';
import { InsightPreview } from './InsighPreview';

export const PaiQuestionInput = ({
  paiReportId,
  metric,
  isAuditor,
  isLoading,
  companyId,
}: {
  paiReportId: string;
  metric: InvesteeMetric;
  isAuditor: boolean;
  isLoading?: boolean;
  companyId: string;
}) => {
  const { answer, loading, onAnswerChange } = usePaiAnswer(
    companyId,
    paiReportId,
    metric.reference
  );
  return (
    <Skeleton isLoaded={!loading && !isLoading}>
      <Tooltip label={isAuditor ? "Answers cannot be edited in 'Auditor View'" : undefined}>
        <CriteriaInput
          isEditable={!isAuditor}
          readOnly={isAuditor}
          type={metric?.type}
          value={answer?.data}
          onChange={(v) => {
            onAnswerChange(v?.data ?? undefined);
          }}
          unit={metric?.unit}
        />
      </Tooltip>
    </Skeleton>
  );
};

function isHTML(str: string) {
  const doc = new DOMParser().parseFromString(str, 'text/html');
  return Array.from(doc.body.childNodes).some((node) => node.nodeType === 1);
}

const EMPTY_HTML_TAG = '<p><br></p>';

export function PaiLearnMoreModal({
  isOpen,
  onClose,
  investeeMetric,
}: Pick<ModalProps, 'isOpen' | 'onClose'> & { investeeMetric: InvesteeMetric }) {
  const hasDescription = investeeMetric.description || investeeMetric.methodology;
  const description = investeeMetric.description;
  const dependencies = investeeMetric.dependencies;
  const additionalResources = investeeMetric.additionalResources;
  const { t } = useTranslation('question');

  const tabs = useMemo(() => {
    const content = [];
    if (hasDescription && description !== EMPTY_HTML_TAG) {
      content.push({
        id: 'description',
        title: t('question:learnMore.description'),
        content: (
          <VStack paddingY="24px" spacing="16px">
            {isHTML(investeeMetric.description ?? '') ? (
              <Box
                className="extended-description-table custom-html"
                dangerouslySetInnerHTML={{ __html: investeeMetric.description ?? '' }}
              />
            ) : (
              <Typography textAlign="left" alignSelf="start">
                {investeeMetric.description || investeeMetric.methodology}
              </Typography>
            )}
          </VStack>
        ),
      });
    }
    if (dependencies && dependencies !== EMPTY_HTML_TAG) {
      content.push({
        id: 'dependencies',
        title: t('question:learnMore.dependencies'),
        content: (
          <Box
            className="extended-description-table custom-html"
            dangerouslySetInnerHTML={{ __html: dependencies }}
          />
        ),
      });
    }
    if (additionalResources && additionalResources !== EMPTY_HTML_TAG) {
      content.push({
        id: 'resources',
        title: t('question:learnMore.resources'),
        leftElement: (
          <Box mt="0.5em">
            <ResourceIcon color="inherit" />
          </Box>
        ),
        content: (
          <Box
            className="extended-description-table custom-html"
            dangerouslySetInnerHTML={{ __html: additionalResources }}
          />
        ),
      });
    }
    return content;
  }, [dependencies, additionalResources, description]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size="lg"
      title={`${investeeMetric.title}`}
      hasFooter={false}
    >
      <Suspense>
        <VStack spacing="12px" width="100%">
          {tabs?.length ? (
            <Tabs items={tabs} />
          ) : (
            <VStack width="100%" textAlign="center" spacing="16px">
              <Typography variant="bodyStrong" textAlign="left">
                Learn more content coming soon...
              </Typography>
              <Typography variant="body" textAlign="left">
                If you have any questions, please contact us in the support chat!
              </Typography>
            </VStack>
          )}
        </VStack>
      </Suspense>
    </Modal>
  );
}

export function PaiIndicatorLearnMoreButton({
  investeeMetric,
}: {
  investeeMetric: InvesteeMetric;
}) {
  const { isOpen, onClose, onOpen } = useDisclosure();
  return (
    <>
      <IconButton
        variant="secondary"
        icon={<HelpIcon color="inherit" />}
        onClick={() => {
          onOpen();
        }}
        aria-label="Learn more"
      />
      <PaiLearnMoreModal isOpen={isOpen} onClose={onClose} investeeMetric={investeeMetric} />
    </>
  );
}

export const PaiInvesteeMetricInput = React.memo(
  ({
    investeeMetric,
    paiReportId,
    statusFilter,
    newMetrics,
    openAttachmentDrawer,
  }: {
    investeeMetric: InvesteeMetric;
    statusFilter: PaiIndicatorStatusFilter;
    paiReportId: string;
    newMetrics: {
      investee: string;
      requestedBy: string[];
      category: string;
    }[];
    openAttachmentDrawer: (box?: AttachmentBox) => void;
  }) => {
    const { companyId } = useCurrentCompanyId();
    const user: ShortUser | null = useUserData();

    const { isAuditor, loading } = useIsAuditor(companyId || undefined);
    const { answer } = usePaiAnswer(companyId ?? '', paiReportId, investeeMetric.reference);
    const { data: documentationData, loading: documentationLoading } =
      useGetPaiAnswerDocumentationByIdQuery({
        variables: {
          answerId: answer?.id,
        },
        skip: !answer?.id,
      });

    const attachmentBox: AttachmentBox | undefined = useMemo(
      () => documentationData?.PaiAnswers_by_pk?.attachmentBox ?? undefined,
      [documentationData]
    );

    const noteHistory: NoteHistory | undefined = useMemo(
      () => documentationData?.PaiAnswers_by_pk?.noteHistory ?? undefined,
      [documentationData]
    );

    const isVisible = useMemo(() => {
      if (statusFilter === PaiIndicatorStatusFilter.all) {
        return true;
      }
      return statusFilter === PaiIndicatorStatusFilter.completed ? !!answer?.data : !answer?.data;
    }, [answer, statusFilter]);

    const showDocumentation: InputCardDocumentationProps = useMemo(() => {
      return {
        currentAuthor: user ?? undefined,
        attachmentBox: attachmentBox,
        noteHistory: noteHistory,
        openAttachmentDrawer: () => openAttachmentDrawer(attachmentBox),
        refetchQueries: [GetPaiAnswerDocumentationByIdDocument_],
        isDisabled: !answer?.id,
        isAuditor,
        isLoading: documentationLoading,
      };
    }, [answer, attachmentBox, noteHistory, user, documentationLoading]);

    const isNew = useMemo(() => {
      return newMetrics
        .map((nm) => nm.investee)
        .some((metric) => metric === investeeMetric.reference);
    }, [newMetrics, investeeMetric.reference]);

    return (
      <Box width="650px">
        <Collapse in={isVisible}>
          <InputCard
            documentation={showDocumentation}
            header={{
              title: investeeMetric.title,
              status: answer?.data ? StateStatus.done : StateStatus.todo,
              actions: (
                <>
                  {isNew && (
                    <Tag minWidth="fit-content" variant="info">
                      <Typography variant="bodyStrong" color="text.info">
                        New
                      </Typography>
                    </Tag>
                  )}
                  <PaiIndicatorLearnMoreButton investeeMetric={investeeMetric} />
                </>
              ),
            }}
            key={investeeMetric.reference}
          >
            <VStack spacing="12px" width="100%" alignItems="stretch">
              <PaiQuestionInput
                paiReportId={paiReportId}
                metric={investeeMetric}
                isAuditor={isAuditor}
                isLoading={loading}
                companyId={companyId ?? ''}
              />
              <HStack justifyContent="flex-start">
                <InsightPreview
                  investeeMetricRef={investeeMetric.reference}
                  paiReportId={paiReportId}
                  investeeMetricTitle={investeeMetric.title ?? ''}
                  companyId={companyId ?? ''}
                />
              </HStack>
            </VStack>
          </InputCard>
        </Collapse>
      </Box>
    );
  }
);

export const PaiCategoryContent = React.memo(
  ({
    indicators,
    paiReportId,
    newMetrics,
    statusFilter,
    openAttachmentDrawer,
  }: {
    indicators: PaiIndicator[];
    statusFilter: PaiIndicatorStatusFilter;
    paiReportId: string;
    newMetrics: {
      investee: string;
      requestedBy: string[];
      category: string;
    }[];
    openAttachmentDrawer: (box?: AttachmentBox) => void;
  }) => {
    const investeeMetrics = useMemo(() => {
      const allInvesteeMetrics = indicators
        .map((i) =>
          i.investorMetrics.map((im) => im.investorMetric.investeeMetrics.map((m) => m.metric))
        )
        .flat(3)
        .sort((a, b) => a.order - b.order);
      return uniqBy(allInvesteeMetrics, 'reference');
    }, [indicators]);
    return (
      <VStack spacing="12px" bg="bg.muted">
        {investeeMetrics.map((metric) => (
          <PaiInvesteeMetricInput
            key={metric.reference}
            investeeMetric={metric}
            statusFilter={statusFilter}
            paiReportId={paiReportId}
            newMetrics={newMetrics}
            openAttachmentDrawer={openAttachmentDrawer}
          />
        ))}
      </VStack>
    );
  }
);
