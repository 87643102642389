import { Thead, Td, HStack, Tr, Table as ChakraTable, Box, Tbody, Th } from '@chakra-ui/react';
import { TruncatableText } from 'Atoms';
import { HelpTooltip } from 'Molecules';
import { Typography } from 'Tokens';
import { ArrowNarrowRightIcon } from 'Tokens/Icons/Direction';
import { GetParentMaterialityAssessmentQuery_ } from 'models';
import { useTranslation } from 'react-i18next';
import { MappedDisclosureRequirements } from './DoubleMaterialityAssessment.d';
import { BOX_STATES, MaterialityStatusBox } from './MaterialityUtils';

export const DisclosureRequirementsTable = ({
  isGroupOwner,
  hasParent,
  disclosureRequirements,
  handleOpenModal,
  isStandardMandatory,
  parentData,
}: {
  isGroupOwner: boolean;
  hasParent: boolean;
  disclosureRequirements: MappedDisclosureRequirements;
  parentData?: GetParentMaterialityAssessmentQuery_;
  handleOpenModal: (reference: string) => void;
  isStandardMandatory: boolean;
}) => {
  const { t } = useTranslation('esrs');
  return (
    <Box width="100%" border="1px solid" borderColor="border.decorative" borderRadius="8px">
      <ChakraTable width="100%" sx={{ tableLayout: 'fixed' }} variant="unstyled">
        <Thead borderBottom="1px solid" borderColor="border.decorative">
          <Tr>
            <Th alignSelf="flex-start" p="13px 16px 8px" textTransform="none">
              <Typography variant="bodyStrong">Disclosure requirement</Typography>
            </Th>
            <Th p="13px 8px" width="14%" textTransform="none">
              <Typography variant="bodyStrong" align="left">
                Ref.
              </Typography>
            </Th>
            {!isGroupOwner && hasParent && !isStandardMandatory && (
              <Th p="13px 8px" width="122px" textTransform="none">
                <HStack>
                  <Typography variant="bodyStrong">Group</Typography>
                  <HelpTooltip label={t('assessment.materialityAssessment.table.tooltip.group')} />
                </HStack>
              </Th>
            )}
            <Th p="13px 8px" width={isGroupOwner ? '18%' : '160px'} textTransform="none">
              <HStack>
                <Typography variant="bodyStrong">
                  {isGroupOwner || isStandardMandatory ? 'Status' : 'Your company'}
                </Typography>
                {!isStandardMandatory && (
                  <HelpTooltip
                    label={
                      isGroupOwner || !hasParent
                        ? t('assessment.materialityAssessment.table.tooltip.company')
                        : t('assessment.materialityAssessment.table.tooltip.subsidiary')
                    }
                  />
                )}
              </HStack>
            </Th>
            <Th width="48px"></Th>
          </Tr>
        </Thead>
        <Tbody>
          {disclosureRequirements
            .sort((a, b) => a.order - b.order)
            .map((requirement, key) => (
              <Tr
                key={key}
                borderBottom={key < disclosureRequirements.length - 1 ? '1px solid' : 'none'}
                cursor="pointer"
                _hover={{ backgroundColor: 'bg.hover' }}
                onClick={() => handleOpenModal(requirement.drRef)}
                borderColor="border.decorative"
              >
                <Td pl="16px" w="400px">
                  <TruncatableText
                    variant="body"
                    tooltipMaxWidth="400px"
                    text={requirement.title}
                  />
                </Td>
                <Td p="13px 8px">
                  <TruncatableText variant="body" text={requirement.drRef} />
                </Td>
                {!isGroupOwner &&
                  parentData?.EsrsAssessment_by_pk?.parentAssessment &&
                  !isStandardMandatory && (
                    <Td p="13px 8px" width="122px">
                      <Typography variant="body">
                        {BOX_STATES[requirement.parentMateriality].text}
                      </Typography>
                    </Td>
                  )}
                <Td width="18%" align="left" p="0px">
                  <MaterialityStatusBox materiality={requirement.materialityStatus} />
                </Td>
                <Td textAlign="right" p="0px 24px 0px 0px">
                  <ArrowNarrowRightIcon color="text.muted" width="16px" />
                </Td>
              </Tr>
            ))}
        </Tbody>
      </ChakraTable>
    </Box>
  );
};
