import { useCompanyAssessmentQuery } from 'models';
import { ContentHeader, ContentLayout } from 'Molecules';
import { useTranslation } from 'utils/translation';

import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { AssessmentEditor } from './AssessmentEditor';

export const AssessmentForm = () => {
  const { cAssessmentId, reportingPeriod } = useParams();
  const { t } = useTranslation(['assessment', 'common']);
  const { data, loading } = useCompanyAssessmentQuery({
    variables: {
      cAssessmentId,
    },
    skip: !cAssessmentId,
  });

  const cAssessment = useMemo(() => {
    return data?.companyAssessment;
  }, [data]);

  return (
    <ContentLayout
      backButton={true}
      variant="narrow"
      header={
        <ContentHeader
          title={
            cAssessment
              ? t('assessment:form.editAssessment')
              : t('assessment:form.createAssessment')
          }
        />
      }
      isLoading={loading}
    >
      <AssessmentEditor cAssessment={cAssessment} reportingPeriod={reportingPeriod} />
    </ContentLayout>
  );
};
