import { VStack, HStack, useDisclosure } from '@chakra-ui/react';
import { IconButton, Infobox, Radio, Tag } from 'Atoms';
import { SearchInput, HelpTooltip, Table, LearnMoreDrawer } from 'Molecules';
import { Typography } from 'Tokens';
import { HashIcon } from 'Tokens/Icons/Data';
import { HelpIcon } from 'Tokens/Icons/Status';
import {
  GetParentTargetsDrQuery_,
  GetTargetMetricsQuery_,
  GetTargetsDrQuery_,
  GetTargetsSubsidiariesQuery_,
} from 'models';
import { useMemo, useState } from 'react';

type LearnMoreType =
  | {
      title: string;
      description: string | null | undefined;
    }
  | undefined;

export const MetricsSection = ({
  metrics,
  selectedMetric,
  setSelectedMetric,
  subsidiaries,
  parentTargets,
  targets,
  isGroup,
}: {
  metrics: GetTargetMetricsQuery_['metrics'];
  selectedMetric: { title: string; reference: string } | undefined;
  setSelectedMetric: (param: { title: string; reference: string }) => void;
  subsidiaries: NonNullable<GetTargetsSubsidiariesQuery_['esrs']>['subsidiaries'] | undefined;
  parentTargets: GetParentTargetsDrQuery_['esrs_Target'] | undefined;
  targets: NonNullable<GetTargetsDrQuery_['DisclosureRequirement_by_pk']>['targets'] | undefined;
  isGroup: boolean;
}) => {
  const [search, setSearch] = useState('');
  const [learnMore, setLearnMore] = useState<LearnMoreType>();
  const { isOpen, onClose, onOpen } = useDisclosure();

  const unUsedMetrics = useMemo(
    () =>
      metrics.filter(
        (m) =>
          !(
            targets?.some((t) => t.metricRef === m.reference) &&
            m.reference !== selectedMetric?.reference
          )
      ) ?? [],
    [metrics, targets, selectedMetric]
  );

  const usedMetricsRefs = useMemo(
    () =>
      metrics
        .filter(
          (m) =>
            targets?.some((t) => t.metricRef === m.reference) &&
            m.reference !== selectedMetric?.reference
        )
        ?.map((m) => m.reference) ?? [],
    [metrics, targets, selectedMetric]
  );

  const filteredMetric = useMemo(
    () =>
      search === ''
        ? unUsedMetrics
        : metrics.filter((metric) =>
            metric.title.toLocaleLowerCase().includes(search.toLocaleLowerCase())
          ),
    [metrics, search]
  );

  const subsidiaryTargets = useMemo(
    () => subsidiaries?.flatMap((s) => s.materialStandards.flatMap((ms) => ms.targets)) ?? [],
    [subsidiaries]
  );

  return (
    <VStack alignItems="start" width="100%" spacing="16px">
      <Infobox
        status="neutral"
        description="Below you can see the full list of metrics available to set a target for. All targets are linked to the ESRS metrics in this standard, and you can not create several targets for one metric."
        closable={false}
        withIcon={false}
      />
      <SearchInput
        search={search}
        setSearch={setSearch}
        placeholder="Search"
        withLeftIcon={false}
        width="100%"
      />
      <Table
        headerPadding="8px"
        cellPadding="0px 8px"
        allowSorting={false}
        pageSize={7}
        conditionalRowProps={(row) =>
          usedMetricsRefs.includes(row.reference)
            ? {
                bg: 'bg.disabled',
                cursor: 'not-allowed',
              }
            : {
                _hover: {
                  cursor: 'pointer',
                  bg: 'bg.hover',
                },
              }
        }
        onRowClick={(row) =>
          !usedMetricsRefs.includes(row.reference) &&
          setSelectedMetric({ title: row.title, reference: row.reference })
        }
        columns={[
          {
            header: '',
            id: 'radio',
            meta: {
              width: '5%',
            },
            cell: ({ row }) => (
              <Radio
                isChecked={selectedMetric?.reference === row.original.reference}
                onChange={() => {
                  setSelectedMetric({
                    title: row.original.title,
                    reference: row.original.reference,
                  });
                }}
                isDisabled={usedMetricsRefs.includes(row.original.reference)}
              />
            ),
          },
          {
            header: 'Metrics available',
            id: 'metric',
            meta: {
              width: '69%',
            },
            cell: ({ row }) => (
              <HStack
                color={usedMetricsRefs.includes(row.original.reference) ? 'text.disabled' : ''}
              >
                <HashIcon color="inherit" />
                <VStack alignItems="start" w="100%" spacing="0px">
                  <Typography variant="body" color="inherit">
                    {row.original.title}
                  </Typography>
                  {usedMetricsRefs.includes(row.original.reference) && (
                    <Typography variant="micro" color="inherit">
                      Already used to set a target
                    </Typography>
                  )}
                </VStack>
              </HStack>
            ),
          },
          {
            header: () => (
              <HStack spacing="4px">
                <Typography variant="bodyStrong">
                  {isGroup ? 'Subsidiaries' : 'Parent company'}
                </Typography>
                <HelpTooltip />
              </HStack>
            ),
            id: 'subsidiaries',
            meta: {
              width: '26%',
            },
            cell: ({ row }) => {
              const subsidiaryLength =
                subsidiaryTargets?.filter((s) => s.metricRef === row.original.reference)?.length ??
                0;
              const parentTarget = parentTargets?.find(
                (t) => t.metricRef === row.original.reference
              );
              if (!!parentTarget && parentTarget?.isBottomUp === false) {
                return (
                  <Tag variant="info" size="xs">
                    <HStack spacing="4px">
                      <Typography variant="detailStrong" color="text.info">
                        Set, but not required
                      </Typography>
                      <HelpTooltip />
                    </HStack>
                  </Tag>
                );
              }
              if (subsidiaryLength > 0)
                return (
                  <Tag variant="info" size="xs" onClick={(e) => e.stopPropagation()}>
                    <HStack spacing="4px">
                      <Typography variant="detailStrong" color="text.info">
                        {subsidiaryLength} have target{subsidiaryLength === 1 ? '' : 's'}
                      </Typography>
                      <HelpTooltip
                        label={`${subsidiaryLength} ${subsidiaryLength === 1 ? 'subsidiary' : 'subsidiaries'} have already set targets for this metric`}
                        placement="bottom-end"
                      />
                    </HStack>
                  </Tag>
                );
              return (
                <Tag
                  variant="undefined"
                  title={parentTarget?.isBottomUp ? 'Required' : 'No target'}
                  size="xs"
                  color={usedMetricsRefs.includes(row.original.reference) ? 'text.disabled' : ''}
                />
              );
            },
          },
          {
            header: '',
            id: 'learnMore',
            cell: ({ row }) => (
              <IconButton
                aria-label="side-bar"
                variant="ghost"
                icon={<HelpIcon />}
                onClick={() => {
                  setLearnMore({
                    title: row.original.title,
                    description: row.original.description,
                  });
                  onOpen();
                }}
              />
            ),
          },
        ]}
        data={filteredMetric}
      />
      <LearnMoreDrawer
        header={learnMore?.title}
        isOpen={isOpen}
        onClose={onClose}
        description={learnMore?.description ?? ''}
      />
    </VStack>
  );
};
