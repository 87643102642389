import ExcelJS from 'exceljs';
import { nhost } from 'utils/nhost';
import FileSaver from 'file-saver';
import { createSubsidiariesSheet } from './subsidiary-sheet-generator';
import { numberOrZero } from 'utils/financials';

export type TransformedCompany = {
  id: string;
  name: string;
  currency: string;
  reportingUnits: {
    name: string;
    id: string;
    mssAligned: boolean;
    activities: {
      activity: string;
      turnover: number;
      capex: number;
      opex: number;
      adaptationCapex: number;
      adaptationOpex: number;
      isAligned: boolean;
      activityTag: string | null;
      objectivesState: {
        significantHarmObjectives: string[];
        substantialContributionObjectives: string[];
        possibleSubstantialContributionObjectives: string[];
        hasClimateRiskAssessment: boolean;
      };
      inProgress: boolean;
      isMssInProgess: boolean;
    }[];
    nonEligible: {
      activity: string;
      total: number;
      turnover: number;
      totalCapex: number;
      capex: number;
      totalOpex: number;
      opex: number;
      adaptationCapex: number;
      adaptationOpex: number;
    };
  }[];
  nonEligible: {
    activity: string;
    turnover: number;
    capex: number;
    opex: number;
    adaptationCapex: number;
    adaptationOpex: number;
  };
  total: {
    activity: string;
    turnover: number;
    capex: number;
    opex: number;
    adaptationCapex: number;
    adaptationOpex: number;
  };
  totalFinancials: {
    activity: string;
    turnover: number;
    capex: number;
    opex: number;
    adaptationCapex: number;
    adaptationOpex: number;
    notes: string;
  };
};

type FinancialsResponse = {
  activity: string;
  revenue: number;
  capex: number;
  opex: number;
  adaptationCapex: number;
  adaptationOpex: number;
  noteHistory: {
    notes: {
      body: string;
    }[];
  };
};

type SubsidiaryResults = {
  subsidiaryResults: {
    data: {
      GroupAssessment: Array<{
        name: string;
        period: Date;
        subsidiaries: Array<{
          id: string;
          company: {
            name: string;
            currency: string;
          };
          sharedCompanyAssessment: CompanyResults;
        }>;
      }>;
    };
  };
};

export type CompanyResults = {
  id: any;
  cachedResult?: {
    id: any;
    score: any;
    progress: any;
    activityRef?: string | null;
    activity: {
      name: string | null;
    };
    financials: any;
    activityTag?: string | null;
    isAligned?: boolean | null;
    isCompleted: boolean;
    isDirty?: boolean | null;
    objectivesState: any;
    status?: string | null;
  } | null;
  totalFinancials: FinancialsResponse;
  generalBusinessUnitResult: Array<{
    generalAssessmentResults: Array<{
      bAssessmentId: any;
      activityAssessmentId: any;
      cachedResult?: {
        id: any;
        score: any;
        progress: any;
        activityRef?: string | null;
        activity: {
          name: string | null;
        };
        financials: any;
        activityTag?: string | null;
        isAligned?: boolean | null;
        isCompleted: boolean;
        isDirty?: boolean | null;
        objectivesState: any;
        status?: string | null;
      } | null;
      financials: FinancialsResponse;
    }>;
  }>;
  businessUnitResults: Array<{
    hasGeneralAssessment: boolean;
    bAssessmentId: any;
    businessUnit?: { name: string; id: any } | null;
    cachedResult?: {
      id: any;
      score: any;
      progress: any;
      activityRef?: string | null;
      activity: {
        name: string | null;
      };
      financials: any;
      activityTag?: string | null;
      isAligned?: boolean | null;
      isCompleted: boolean;
      isDirty?: boolean | null;
      objectivesState: any;
      status?: string | null;
    } | null;
    generalAssessmentResults: Array<{
      bAssessmentId: any;
      activityAssessmentId: any;
      cachedResult?: {
        id: any;
        score: any;
        progress: any;
        activityRef?: string | null;
        activity: {
          name: string | null;
        };
        financials: any;
        activityTag?: string | null;
        isAligned?: boolean | null;
        isCompleted: boolean;
        isDirty?: boolean | null;
        objectivesState: any;
        status?: string | null;
      } | null;
    }>;
    activityResults: Array<{
      activityRef: string;
      activityAssessmentId: any;
      cachedResult?: {
        id: any;
        score: any;
        progress: any;
        activityRef?: string | null;
        activity: {
          name: string | null;
        };
        financials: any;
        activityTag?: string | null;
        isAligned?: boolean | null;
        isCompleted: boolean;
        isDirty?: boolean | null;
        objectivesState: any;
        status?: string | null;
      } | null;
      financials: FinancialsResponse;
    }>;
  }>;
};

type Financials = {
  turnover: number;
  capex: number;
  opex: number;
  adaptationCapex: number;
  adaptationOpex: number;
};

export const ALL_FINANCIAL_SECTIONS = ['turnover', 'capex', 'opex'] as const;

export const ActivityTag = {
  ENABLING: 'ENABLING',
  TRANSITIONAL: 'TRANSITIONAL',
};

const defaultFinancials: Financials = {
  turnover: 0,
  capex: 0,
  opex: 0,
  adaptationCapex: 0,
  adaptationOpex: 0,
};

export const capitalise = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export function incrementString(str: string): string {
  str = str.toUpperCase();
  const chars = str.split('');

  for (let i = chars.length - 1; i >= 0; i--) {
    if (chars[i] === 'Z') {
      chars[i] = 'A';
    } else {
      chars[i] = String.fromCharCode(chars[i].charCodeAt(0) + 1);
      break;
    }
  }
  if (chars.every((char) => char === 'A')) {
    chars.unshift('A');
  }
  return chars.join('');
}

const sumWithUndefined = (...addends: any[]) =>
  addends.reduce((sum, addend) => sum + numberOrZero(addend), 0);

const aggregateFinancialsReducer = (
  aggregatedFinancials: Financials,
  currentFinancials: Partial<Financials>
): Financials =>
  ({
    ...ALL_FINANCIAL_SECTIONS.reduce(
      (agg, section) => ({
        ...agg,
        [section]: sumWithUndefined(aggregatedFinancials?.[section], currentFinancials?.[section]),
      }),
      defaultFinancials
    ),
  }) as Financials;

export const aggregateFinancials = (financials: Partial<Financials>[]) =>
  financials.reduce(aggregateFinancialsReducer, defaultFinancials);

export const getOtherFinancials = (
  children: Array<Financials | undefined>,
  parent?: Financials
) => {
  const activitiesTotal = aggregateFinancials(children.filter(Boolean) as Financials[]);
  const notEligible = Object.fromEntries(
    ALL_FINANCIAL_SECTIONS.map((section) => [
      section,
      (parent?.[section] ?? 0) - activitiesTotal[section],
    ])
  );
  return notEligible as unknown as Financials;
};

export const getSubsidiaryResults = async ({ gAssessmentId }: { gAssessmentId: string }) => {
  const res = await nhost.functions.call('get-subsidiary-results', {
    gAssessmentId,
  });

  if (res.error) {
    console.error(res.error);
    return;
  }

  const subsidiaryResults = res.res.data as SubsidiaryResults;
  const groupAssessment = subsidiaryResults.subsidiaryResults.data.GroupAssessment[0];
  return groupAssessment;
};

export const generateSubsidiaryTable = async ({ gAssessmentId }: { gAssessmentId: string }) => {
  const groupAssessment = await getSubsidiaryResults({ gAssessmentId });

  if (!groupAssessment) {
    throw new Error('Failed to fetch data');
  }

  const subsidiaryList = groupAssessment.subsidiaries;
  const companyMap = subsidiaryList.reduce(
    (map: Record<string, (typeof subsidiaryList)[number]>, c) => ({
      ...map,
      [c.id]: c,
    }),
    {}
  );

  const companyList: TransformedCompany[] = Object.values(companyMap).map(
    (c): TransformedCompany => {
      const nonEligibleFinancials = c.sharedCompanyAssessment
        ? getOtherFinancials(
            c.sharedCompanyAssessment?.businessUnitResults.map((br) => ({
              turnover: br.cachedResult?.financials.revenue.total,
              capex: br.cachedResult?.financials.capex.total,
              opex: br.cachedResult?.financials.opex.total,
              adaptationCapex: 0,
              adaptationOpex: 0,
            })),
            {
              turnover: c.sharedCompanyAssessment?.cachedResult?.financials.revenue.total,
              capex: c.sharedCompanyAssessment?.cachedResult?.financials.capex.total,
              opex: c.sharedCompanyAssessment?.cachedResult?.financials.opex.total,
              adaptationCapex: 0,
              adaptationOpex: 0,
            }
          )
        : { turnover: 0, capex: 0, opex: 0, adaptationCapex: 0, adaptationOpex: 0 };
      return {
        id: c.id,
        name: c.company.name,
        currency: c.company.currency,
        reportingUnits:
          c.sharedCompanyAssessment?.businessUnitResults.map((reportingUnit) => {
            const generalAssessment = reportingUnit.hasGeneralAssessment
              ? reportingUnit.generalAssessmentResults.filter(
                  (genAssessment) => genAssessment !== null
                )[0]
              : c.sharedCompanyAssessment?.generalBusinessUnitResult[0].generalAssessmentResults.filter(
                  (genAssessment) => genAssessment !== null
                )[0];
            const mssAligned = generalAssessment.cachedResult?.isAligned;
            return {
              name: reportingUnit.businessUnit?.name ?? 'MISSING NAME',
              id: reportingUnit.businessUnit?.id ?? 'MISSING ID',
              mssAligned: mssAligned ?? false,
              activities: reportingUnit.activityResults.map((activity) => {
                return {
                  activity: activity?.cachedResult?.activity?.name || activity.activityRef,
                  turnover:
                    activity?.financials?.revenue ??
                    activity?.cachedResult?.financials?.revenue?.total ??
                    0,
                  capex:
                    activity?.financials?.capex ??
                    activity?.cachedResult?.financials?.capex?.total ??
                    0,
                  opex:
                    activity?.financials?.opex ??
                    activity?.cachedResult?.financials?.opex?.total ??
                    0,
                  adaptationCapex:
                    activity?.financials?.adaptationCapex ??
                    activity?.cachedResult?.financials?.capex.adaptation ??
                    0,
                  adaptationOpex:
                    activity?.financials?.adaptationOpex ??
                    activity?.cachedResult?.financials?.opex.adaptation ??
                    0,
                  isAligned: !!(mssAligned && activity?.cachedResult?.isAligned),
                  activityTag: activity?.cachedResult?.activityTag ?? null,
                  objectivesState: activity?.cachedResult?.objectivesState ?? null,
                  isMssInProgess: generalAssessment?.cachedResult?.isCompleted ?? false,
                  inProgress: !activity?.cachedResult?.isCompleted,
                };
              }),
              nonEligible: {
                activity: 'Non-eligible activities',
                total: reportingUnit.cachedResult?.financials.revenue.total ?? 0,
                turnover: reportingUnit.cachedResult?.financials.revenue.notEligible ?? 0,
                totalCapex: reportingUnit.cachedResult?.financials.capex.total ?? 0,
                capex: reportingUnit.cachedResult?.financials.capex.notEligible ?? 0,
                totalOpex: reportingUnit.cachedResult?.financials.opex.total ?? 0,
                opex: reportingUnit.cachedResult?.financials.opex.notEligible ?? 0,
                adaptationCapex: reportingUnit.cachedResult?.financials.capex.adaptation ?? 0,
                adaptationOpex: reportingUnit.cachedResult?.financials.opex.adaptation ?? 0,
              },
            };
          }) ?? [],
        nonEligible: {
          activity: 'Non-eligible activities',
          turnover: nonEligibleFinancials.turnover,
          capex: nonEligibleFinancials.capex,
          opex: nonEligibleFinancials.opex,
          adaptationCapex: nonEligibleFinancials.adaptationCapex,
          adaptationOpex: nonEligibleFinancials.adaptationOpex,
        },
        total: {
          activity: 'Total',
          turnover: c.sharedCompanyAssessment?.cachedResult?.financials.revenue.total ?? 0,
          capex: c.sharedCompanyAssessment?.cachedResult?.financials.capex.total ?? 0,
          opex: c.sharedCompanyAssessment?.cachedResult?.financials.opex.total ?? 0,
          adaptationCapex:
            c.sharedCompanyAssessment?.cachedResult?.financials.capex.adaptation ?? 0,
          adaptationOpex: c.sharedCompanyAssessment?.cachedResult?.financials.opex.adaptation ?? 0,
        },
        totalFinancials: {
          activity: 'Company total',
          turnover: c.sharedCompanyAssessment?.totalFinancials?.revenue ?? 0,
          capex: c.sharedCompanyAssessment?.totalFinancials?.capex ?? 0,
          opex: c.sharedCompanyAssessment?.totalFinancials?.opex ?? 0,
          adaptationCapex: c.sharedCompanyAssessment?.totalFinancials?.adaptationCapex ?? 0,
          adaptationOpex: c.sharedCompanyAssessment?.totalFinancials?.adaptationOpex ?? 0,
          notes: c.sharedCompanyAssessment?.totalFinancials?.noteHistory?.notes?.[0]?.body,
        },
      };
    }
  );

  const workbook = new ExcelJS.Workbook();
  createSubsidiariesSheet({ workbook, companyList });

  workbook.xlsx.writeBuffer().then((buffer) => {
    const blob = new Blob([buffer], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });
    FileSaver.saveAs(
      blob,
      `${groupAssessment?.name}_${groupAssessment.period}_unconsolidated_results.xlsx`
    );
  });
};
