import {
  Button,
  IconButton,
  Tab,
  Box,
  Text,
  VStack,
  HStack,
  StackProps,
  ButtonProps,
  Popover,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger,
  Skeleton,
  SkeletonCircle,
  SkeletonText,
  Stack,
} from '@chakra-ui/react';
import React, { useState, useCallback, useMemo, Suspense, useContext } from 'react';
import dayjs from 'dayjs';
import mixpanel from 'mixpanel-browser';
import { AvatarWithName, TextareaWithMentions, TextWithMentions } from 'Organisms';
import { Comment, Thread } from 'models';
import { useTranslation } from 'utils/translation';
import { useCurrentCompanyId } from 'utils/hooks';
import { Typography } from 'Tokens';
import { TRACKING_EVENTS } from 'utils/mixpanel';
import { formatDateTime } from 'utils/date';
import { useCommentThread } from './Screening.hooks';
import { useUserData } from '@nhost/react';
import { useCompanyUsersQuery, useUpsertThreadMutation } from '../../models/__generated__/graphql';
import { CurrentQuestionContext } from 'context';
import { CommentIcon, DeleteIcon } from 'Tokens/Icons/Function';

export const IconTab = ({
  icon,
  children,
}: { icon: JSX.Element } & JSX.ElementChildrenAttribute) => (
  <Tab>
    <Text mr="0.5em">{icon}</Text>
    <>{children}</>
  </Tab>
);

export const CommentItem = React.memo(function CommentItem({
  comment,
  onDelete,
  ...rest
}: {
  comment: Comment;
  onDelete?: () => void;
} & StackProps) {
  const { t } = useTranslation('common');
  const { companyId } = useCurrentCompanyId();
  return (
    <VStack align="start" {...rest}>
      <HStack justify="space-between" width="100%">
        <AvatarWithName user={comment.author} size="sm" />
        {!!onDelete && (
          <IconButton
            aria-label={t('common:comments.delete')}
            variant="ghost"
            width="24px"
            icon={<DeleteIcon color="inherit" />}
            onClick={() => {
              onDelete();
              mixpanel.track(TRACKING_EVENTS.QUESTION.COMMENTS.DELETE, {
                companyId,
              });
            }}
          />
        )}
      </HStack>
      <VStack pl="40px" align="start">
        <Typography fontSize="sm" color="secondary.dark">
          {formatDateTime(comment.createdAt)}
        </Typography>
        <TextWithMentions value={comment.data} />
      </VStack>
    </VStack>
  );
});
type ThreadManagerType = ReturnType<typeof useCommentThread>;
export type ThreadPopoverContentPropsType = Pick<
  ThreadManagerType,
  'addComment' | 'deleteComment'
> &
  NonNullable<Pick<NonNullable<ThreadManagerType['thread']>, 'comments'>>;

export function ThreadPopoverBody({
  addComment,
  deleteComment,
  comments,
}: ThreadPopoverContentPropsType) {
  const [comment, setComment] = useState<string>('');
  const { companyId } = useCurrentCompanyId();
  const user = useUserData();
  const { data } = useCompanyUsersQuery({
    variables: {
      id: companyId,
    },
    skip: !companyId,
  });
  const users = useMemo(() => data?.users ?? [], [data]);
  const { t } = useTranslation('common');

  const resetData = () => {
    setComment('');
  };

  const handleAddComment = () => {
    if (comment) {
      addComment(comment).then(resetData);
    }
  };
  return (
    <PopoverBody p="md">
      <PopoverCloseButton top="20px" right="16px" fontSize="14px" />
      <VStack spacing="md" align="start">
        {user && <AvatarWithName user={user} size="sm" />}
        <TextareaWithMentions
          value={comment}
          users={users}
          placeholder={t('common:comments.placeholder')}
          onChange={(_, newValue) => {
            setComment(newValue);
          }}
        />
        <Button onClick={handleAddComment} disabled={!comment}>
          {t('common:comments.postComment')}
        </Button>
      </VStack>
      {comments.length > 0 && (
        <VStack overflowY="auto" maxHeight="50vh" align="start" marginTop="lg" paddingRight="3px">
          {comments.map((c) => (
            <CommentItem
              width="100%"
              key={c.id}
              comment={c}
              onDelete={c.authorId === user?.id ? () => deleteComment(c.id) : undefined}
            />
          ))}
        </VStack>
      )}
    </PopoverBody>
  );
}

type ThreadIdType = Thread['id'];
const ThreadBodyWrapper = React.memo(function ThreadBodyWrapper({
  threadId,
  createThread,
  count,
}: {
  threadId: ThreadIdType;
  createThread: ThreadManagerType['addComment'];
  count: number;
}) {
  const skeletons = Array(count + 1).fill(0);
  const { addComment, deleteComment, thread, loading } = useCommentThread({ id: threadId });
  const handleAddComment = useCallback(
    (comment: string) => {
      if (comment) {
        if (thread) {
          return addComment(comment);
        } else {
          return createThread(comment);
        }
      }
      return Promise.resolve({});
    },
    [addComment, createThread]
  );
  const comments = useMemo(
    () => thread?.comments?.slice().sort((a, b) => dayjs(b.createdAt).diff(a.createdAt)) || [],
    [thread?.comments]
  );

  if (loading) {
    return (
      <Stack spacing="12px">
        {skeletons.map((_, i) => (
          <Box key={i} padding="6" boxShadow="lg" bg="white">
            <SkeletonCircle size="10" />
            <SkeletonText mt="4" noOfLines={4} spacing="4" />
          </Box>
        ))}
      </Stack>
    );
  }
  return (
    <Skeleton isLoaded={!loading}>
      <ThreadPopoverBody
        addComment={handleAddComment}
        deleteComment={deleteComment}
        comments={comments}
      />
    </Skeleton>
  );
});
const ThreadButton = React.memo(function ThreadButton({
  threadId,
  createThread,
  count,
  isLocked,
  ...props
}: {
  threadId: ThreadIdType;
  count: number;
  createThread: ThreadManagerType['addComment'];
  isLocked: boolean;
} & ButtonProps) {
  const { t } = useTranslation('common');

  return (
    <Popover placement="bottom-end" isLazy>
      <Box>
        <PopoverTrigger>
          <Button
            aria-label={t('common:comments.addComment')}
            leftIcon={<CommentIcon color="inherit" />}
            {...props}
            isDisabled={isLocked}
            variant="ghost"
            size="md"
          >
            <Typography variant="bodyStrong" color="text.muted">
              {count ?? 0}
            </Typography>
          </Button>
        </PopoverTrigger>
        <PopoverContent width="395px">
          <Suspense>
            <ThreadBodyWrapper threadId={threadId} createThread={createThread} count={count} />
          </Suspense>
        </PopoverContent>
      </Box>
    </Popover>
  );
});

export function CommentsButton({ isLocked, ...props }: ButtonProps & { isLocked: boolean }) {
  const { onAnswerChange, answer, uniqueId, questionSetRef } = useContext(CurrentQuestionContext);
  const [upsertThread] = useUpsertThreadMutation();
  const { companyId } = useCurrentCompanyId();
  const user = useUserData();
  const createThread = useCallback(
    (comment: string, mentions?: { userId: string }[]) => {
      mixpanel.track(TRACKING_EVENTS.QUESTION.COMMENTS.ADD, {
        companyId: companyId,
        questionId: uniqueId,
        questionSetRef: questionSetRef,
        withMentions: !!mentions,
      });
      if (!!answer?.id) {
        return upsertThread({
          variables: {
            thread: {
              companyId,
              answerId: answer.id,
              comments: {
                data: [
                  {
                    data: comment,
                    ...(mentions ? { notifications: { data: mentions } } : {}),
                    authorId: user?.id,
                  },
                ],
              },
            },
          },
        });
      } else {
        return onAnswerChange(
          {
            thread: {
              companyId,
              comments: [
                {
                  data: comment,
                  ...(mentions ? { notifications: { data: mentions } } : {}),
                  authorId: user?.id,
                },
              ],
            },
          },
          true
        );
      }
    },
    [companyId, onAnswerChange, answer, upsertThread, user]
  );

  return (
    <ThreadButton
      threadId={answer?.thread?.id}
      createThread={createThread}
      count={answer?.thread?.commentsAggregate.aggregate?.count ?? 0}
      isLocked={isLocked && (answer?.id === null || answer?.id === undefined)}
      {...props}
    />
  );
}
