import React, { useMemo } from 'react';
import { MetricsTableData } from '../../../..';
import {
  AggregatedMetricsTableData,
  AggregatedQualitativeAnswers,
} from '../../../../AggregatedMetrics';
import { GeneratedAnswer } from '../../../../MetricAI';
import { QuestionType_Enum_ } from 'models';
import { BooleanMetricInput } from './InputTypes/BooleanInput';
import { Box } from '@chakra-ui/react';
import { AdditionalRowInput } from './InputTypes/AdditionalRowInput';
import { ChoiceMetricInput } from './InputTypes/ChoiceInput';
import { LongTextMetricInput } from './InputTypes/LongTextMetricInput';

export const NarrativeMetricsInput = ({
  metric,
  setRowData,
  rowData,
  companyReportingUnit,
  isReadOnly,
  generatedAnswers,
  answersData,
  isGeneratingAnswers,
  isAI,
  isAdditionalInfo,
  additionalInfoPlacement,
}: {
  metric: AggregatedMetricsTableData['metric'];
  rowData?: MetricsTableData;
  setRowData: (param: MetricsTableData | undefined) => void;
  isReadOnly: boolean;
  companyReportingUnit: string;
  answersData?: AggregatedQualitativeAnswers;
  generatedAnswers?: GeneratedAnswer[];
  isGeneratingAnswers?: boolean;
  isAI: boolean;
  isAdditionalInfo?: boolean;
  additionalInfoPlacement?: string;
}) => {
  const isBooleanMetric = useMemo(() => metric?.metricType === QuestionType_Enum_.YesNo_, [metric]);
  const hasChoice = useMemo(
    () =>
      metric?.metricType === QuestionType_Enum_.SingleChoice_ ||
      metric?.metricType === QuestionType_Enum_.MultipleChoice_,
    [metric]
  );

  const canInputAnswer = useMemo(
    () => !metric?.childrenMetrics?.length || isBooleanMetric || isAdditionalInfo,
    [metric, isBooleanMetric, isAdditionalInfo]
  );
  const generatedAnswer = useMemo(
    () => generatedAnswers?.find((a) => a.metricRef === metric.reference),
    [generatedAnswers]
  );
  return (
    <Box height="100%">
      {canInputAnswer &&
        (isBooleanMetric ? (
          <BooleanMetricInput
            metric={metric}
            setRowData={setRowData}
            companyReportingUnitId={companyReportingUnit}
            isReadOnly={isReadOnly}
          />
        ) : hasChoice ? (
          <ChoiceMetricInput metric={metric} companyReportingUnitId={companyReportingUnit} />
        ) : isAdditionalInfo ? (
          <AdditionalRowInput
            metric={metric}
            companyReportingUnit={companyReportingUnit}
            placement={additionalInfoPlacement ?? 'before'}
          />
        ) : (
          <LongTextMetricInput
            metric={metric}
            companyReportingUnit={companyReportingUnit}
            rowData={rowData}
            setRowData={setRowData}
            hasRef={false}
            isGeneratingAnswers={isGeneratingAnswers}
            answersData={answersData}
            generatedAnswer={generatedAnswer}
            isReadOnly={isReadOnly}
            isAI={isAI}
          />
        ))}
    </Box>
  );
};
