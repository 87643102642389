import { Box, VStack, useDisclosure } from '@chakra-ui/react';
import { ActiveStepLabels } from 'containers/Esrs/EsrsUtilComponents';
import { Button } from 'Atoms';
import { ArrowNarrowRightIcon } from 'Tokens/Icons/Direction';
import {
  MappedDisclosureRequirements,
  ParentStandardMaterialityData,
  StandardMaterialityState,
} from '../DoubleMaterialityAssessment.d';
import { useMemo, useState } from 'react';
import {
  GetEsrsStandardQuery_,
  GetMaterialStandardQuery_,
  GetParentMaterialityAssessmentQuery_,
} from 'models';
import { Typography } from 'Tokens';
import { DisclosureRequirementsTable } from '../DisclosureRequirementsTable';
import { DrMaterialityModal } from '../DrMaterialityModal/DrMaterialityModal';
import { useMapDisclosureRequirements } from '../DoubleMaterialityAssessment.hooks';

export const AssessDisclosureRequirements = ({
  step,
  standard,
  standardMateriality,
  hasParent,
  onDoneClick,
  parentStandardMaterialityData,
  isCollectOnly,
  parentData,
  materialStandard,
  isGroup,
}: {
  step: number;
  standard?: GetEsrsStandardQuery_['esrsStandard'];
  standardMateriality: StandardMaterialityState;
  hasParent: boolean;
  isCollectOnly: boolean;
  parentStandardMaterialityData?: ParentStandardMaterialityData;
  onDoneClick: () => void;
  parentData?: GetParentMaterialityAssessmentQuery_;
  materialStandard?: GetMaterialStandardQuery_['materialityAssessment'][number];
  isGroup: boolean;
}) => {
  const [selectedDr, setSelectedDr] = useState<MappedDisclosureRequirements[number]>();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const mapDisclosureRequirements = useMapDisclosureRequirements();

  const parentMaterialMetrics = useMemo(() => {
    return parentStandardMaterialityData?.materialMetrics ?? [];
  }, [parentStandardMaterialityData]);

  const [isStandardMandatory, isCollectOnlyMandatory] = useMemo(() => {
    return [
      standardMateriality === StandardMaterialityState.mandatory,
      standardMateriality === StandardMaterialityState.collectDataMandatory,
    ];
  }, [standardMateriality]);

  const isStandardSpecial = useMemo(
    () => isCollectOnlyMandatory || isStandardMandatory,
    [isCollectOnlyMandatory, isStandardMandatory]
  );

  const disclosureRequirements = useMemo(
    () =>
      mapDisclosureRequirements({
        materialMetrics: materialStandard?.materialMetrics ?? [],
        parentMaterialMetrics: parentMaterialMetrics,
        isCollectOnly: isCollectOnly ?? false,
        isGroupOwner: isGroup,
        isStandardMandatory: isStandardMandatory,
        isStandardMaterial: standardMateriality === StandardMaterialityState.material,
        isParentStandardMaterial: parentStandardMaterialityData?.isMaterial,
        requirementGroups: standard?.disclosureRequirementGroups ?? [],
      }),
    [
      standard,
      parentMaterialMetrics,
      parentStandardMaterialityData,
      isStandardMandatory,
      isGroup,
      isCollectOnly,
      materialStandard,
    ]
  );

  const drMaterialMetrics = useMemo(
    () =>
      materialStandard?.materialMetrics.filter(
        (metric) => metric.metric?.disclosureRequirementRef === selectedDr?.drRef
      ) ?? [],
    [selectedDr]
  );

  const handleOpenModal = (drRef: string) => {
    setSelectedDr(
      disclosureRequirements.find(
        (dr) => dr.drRef === drRef
      ) as MappedDisclosureRequirements[number]
    );
    onOpen();
  };

  return (
    <>
      <Box
        position="relative"
        ml={isStandardSpecial ? '0px' : '16px'}
        mt={isStandardSpecial ? '32px' : '0px'}
      >
        <Box pl={isStandardSpecial ? '0px' : '32px'} pb="24px">
          {isStandardMandatory ? (
            <>
              <Typography variant="h3" mb="2px">
                Review and assess disclosure requirements and metric
              </Typography>
              <Typography variant="body" color="text.muted" mb="16px">
                Review and assess what is material for your company
              </Typography>
            </>
          ) : isCollectOnlyMandatory ? (
            <Typography variant="h3" mb="16px">
              Review disclosure requirements and metric
            </Typography>
          ) : (
            <ActiveStepLabels
              title="Review and assess disclosure requirements and metrics"
              description="Review and assess what is material for your company"
              active={step === 2}
              done={false}
              ml="33px"
            />
          )}
          {(step === 2 || isStandardMandatory || isCollectOnlyMandatory) && (
            <VStack spacing="8px" alignItems="start">
              <DisclosureRequirementsTable
                disclosureRequirements={disclosureRequirements as MappedDisclosureRequirements}
                handleOpenModal={handleOpenModal}
                hasParent={hasParent}
                isStandardMandatory={isStandardMandatory}
                parentData={parentData}
                isGroupOwner={isGroup}
              />
              <Button
                variant="primary"
                type="submit"
                alignSelf="start"
                mt="16px"
                onClick={onDoneClick}
                rightIcon={<ArrowNarrowRightIcon color="text.onAccent" />}
              >
                Done
              </Button>
            </VStack>
          )}
        </Box>
      </Box>
      {isOpen && (
        <DrMaterialityModal
          hasParent={hasParent}
          isCollectOnly={isCollectOnly}
          isOpen={isOpen}
          isGroup={isGroup}
          drMaterialMetrics={drMaterialMetrics}
          parentMetrics={parentMaterialMetrics}
          isStandardMandatory={isStandardMandatory}
          standardMateriality={standardMateriality}
          materialStandardId={materialStandard?.id}
          disclosureRequirement={selectedDr}
          onClose={onClose}
        />
      )}
    </>
  );
};
