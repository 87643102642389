import { useUserData } from '@nhost/react';
import { useCurrentCompanyId } from 'utils/hooks';
import {
  DocumentationFile_Insert_Input_,
  useAddFileMutation,
  useRemoveFileMutation,
  useCompanyFilesQuery,
  CompanyFilesDocument_,
  ActivityAssessmentDocument_,
  QuestionFieldsFragmentDoc_,
} from 'models';
import { useCallback, useMemo } from 'react';
import { omitMetaOther } from 'utils';
import { nhost } from 'utils/nhost';
import mixpanel from 'mixpanel-browser';
import { TRACKING_EVENTS } from 'utils/mixpanel';
import db from 'mime-db';
import { useTranslation } from 'utils/translation';

export type UpsertFileProps = DocumentationFile_Insert_Input_ & {
  file?: File;
};

const uploadFileToNhost = async (file: File) => {
  const res = await nhost.storage.upload({ file, bucketId: 'default' });
  return res;
};

const handleUpsertBinaryFile = async (updateObj: UpsertFileProps & { file: File }) => {
  const uploadedFile = await uploadFileToNhost(updateObj.file);
  return uploadedFile;
};

export function useUpsertFile() {
  const [saveFile] = useAddFileMutation();
  const { companyId } = useCurrentCompanyId();
  const { id: userId } = useUserData() ?? { id: undefined };
  const { t } = useTranslation('common');

  return useCallback(
    async (updateObj: UpsertFileProps) => {
      let storageFile;

      if (!!updateObj.file) {
        const { fileMetadata, error } = await handleUpsertBinaryFile(
          updateObj as UpsertFileProps & { file: File }
        );
        storageFile = fileMetadata;
        if (error) {
          throw new Error(error.message);
        }
      }

      const { data } = await saveFile({
        variables: {
          input: {
            title: storageFile?.name ?? t('common:unknown'),
            tags: [],
            ...(storageFile?.id ? { storageFileId: storageFile?.id } : {}),
            ...omitMetaOther(updateObj, 'uploadedBy', 'updatedAt', 'file'),
            companyId,
            uploadedById: userId,
          },
        },
        refetchQueries: [CompanyFilesDocument_],
      });

      return data?.file;
    },
    [saveFile, companyId, userId]
  );
}

export function useDeleteFile() {
  const { companyId } = useCurrentCompanyId();
  const [deleteDocumentationFile] = useRemoveFileMutation();
  return useCallback(
    async ({ id, storageFileId }: { id: string; storageFileId: string }) => {
      mixpanel.track(TRACKING_EVENTS.DRIVE.DELETE, {
        companyId,
        fileId: id,
      });
      await nhost.storage.delete({ fileId: storageFileId });
      const { data: deletedFileData } = await deleteDocumentationFile({
        variables: { id },
        refetchQueries: [
          CompanyFilesDocument_,
          ActivityAssessmentDocument_,
          QuestionFieldsFragmentDoc_,
        ],
      });

      return deletedFileData?.file?.id;
    },
    [deleteDocumentationFile]
  );
}

export const useCompanyFiles = () => {
  const { companyId } = useCurrentCompanyId();
  const { data, loading } = useCompanyFilesQuery({
    variables: {
      companyId,
    },
    skip: !companyId,
  });

  const files = useMemo(() => {
    return data?.files ?? [];
  }, [data?.files]);

  return { files, loading };
};

export const useFileExtension = (mimeType?: string) => {
  const extension = useMemo(() => {
    return db[mimeType ?? '']?.extensions?.[0];
  }, [mimeType]);
  return extension;
};
