import { Radio, RadioGroup, VStack, HStack, ChakraTheme } from '@chakra-ui/react';
import React, { MouseEventHandler } from 'react';
import { Typography } from 'Tokens';
import { IconComponentType } from 'Tokens/Icons/IconWrapper';

export type RadioCardProps = {
  title: string;
  subtitle: string | React.ReactNode;
  icon?: IconComponentType;
  titleLeftIcon?: IconComponentType;
  content?: React.ReactNode;
  value: string;
  isSelected: boolean;
  onClick?: MouseEventHandler<HTMLDivElement>;
  width?: string;
  withRadioBtn?: boolean;
  disabled?: boolean;
  isNullable?: boolean;
  fontColor?: string;
};

export const RadioCardGroupTheme: ChakraTheme['components']['Radio'] = {
  variants: {
    radioCardGroup: {
      control: {
        display: 'none',
        _checked: {
          border: '2px',
        },
      },
      label: {
        marginInlineStart: '0px',
        height: '100%',
      },
    },
    radioCardGroupWithBtn: {
      control: {
        position: 'absolute',
        left: '12px',
        top: '16px',
        _checked: {
          border: '2px solid border.selected.accent',
        },
      },
      label: {
        marginInlineStart: '0px',
        height: '100%',
      },
    },
  },
};

export const RadioCard = ({
  title,
  subtitle,
  icon: IconComponent,
  titleLeftIcon: TitleLeftIcon,
  content,
  value,
  isSelected,
  onClick,
  width,
  withRadioBtn,
  fontColor,
  disabled = false,
  isNullable,
}: RadioCardProps) => {
  return (
    <Radio
      role="radio"
      value={value}
      variant={withRadioBtn ? 'radioCardGroupWithBtn' : 'radioCardGroup'}
      boxSizing="border-box"
      isDisabled={disabled}
      onClick={onClick}
    >
      <VStack
        width={width}
        transition="ease-in 0.1s"
        boxSizing="border-box"
        minW="200px"
        maxW="680px"
        height="100%"
        padding="12px"
        spacing={content ? '16px' : '4px'}
        cursor={isSelected && !isNullable ? 'auto' : disabled ? 'not-allowed' : 'pointer'}
        _hover={{
          outlineWidth: '1px',
          outlineColor: isSelected ? 'transparent' : 'border.hover',
        }}
        borderWidth="1px"
        borderColor={isSelected ? 'border.selected.accent' : 'transparent'}
        outline={isSelected || disabled ? '0px' : '1px solid'}
        outlineColor={isSelected ? 'transparent' : 'border.decorative'}
        borderRadius="8px"
        alignItems="flex-start"
        onClick={onClick}
        bg={disabled ? 'bg.disabled' : 'bg.default'}
      >
        <VStack spacing="4px" paddingLeft={withRadioBtn ? 6 : 0} alignItems="flex-start">
          {IconComponent && <IconComponent boxSize="16px" mb="12px" />}
          <HStack spacing="4px">
            {TitleLeftIcon && <TitleLeftIcon boxSize="16px" />}
            <Typography
              variant={withRadioBtn ? 'h4' : 'h3'}
              color={fontColor ?? disabled ? 'text.disabled' : 'text.default'}
            >
              {title}
            </Typography>
          </HStack>
          {typeof subtitle === 'string' ? (
            <Typography
              variant="body"
              color={fontColor ?? disabled ? 'text.disabled' : 'text.muted'}
            >
              {subtitle}
            </Typography>
          ) : (
            subtitle
          )}
        </VStack>
        <VStack display={isSelected ? 'hidden' : 'none'}>{content}</VStack>
      </VStack>
    </Radio>
  );
};

export type RadioCardGroupProps = {
  options: Omit<RadioCardProps, 'isSelected'>[];
  value: string;
  onChange: (value: string | null) => void;
  direction?: 'vertical' | 'horizontal';
  cardWidth?: string;
  isNullable?: boolean;
  defaultValue?: string;
  withRadioBtn?: boolean;
};

export const RadioCardGroup = ({
  options,
  value,
  onChange,
  isNullable,
  direction = 'vertical',
  cardWidth,
  defaultValue,
  withRadioBtn = false,
}: RadioCardGroupProps) => {
  return (
    <RadioGroup
      value={value}
      onChange={isNullable ? undefined : onChange}
      as={direction === 'horizontal' ? HStack : VStack}
      alignItems="stretch"
      spacing="8px"
      isFocusable
    >
      {options.map((option, index) => {
        let handleOnClick;
        if (!option.disabled) {
          if (isNullable) {
            handleOnClick = () =>
              value === option.value ? onChange(null) : onChange(option.value);
          } else if (value !== option.value) {
            handleOnClick = () => onChange(option.value);
          }
        }
        return (
          <RadioCard
            {...option}
            key={index}
            onClick={handleOnClick}
            isSelected={value ? value === option.value : defaultValue == option.value}
            width={cardWidth}
            isNullable={isNullable}
            withRadioBtn={withRadioBtn}
          />
        );
      })}
    </RadioGroup>
  );
};
