import { HStack } from '@chakra-ui/react';
import { IconHelpCircle } from '@tabler/icons-react';
import { Button } from 'Atoms';
import { Typography } from 'Tokens';
import { FC } from 'react';

type MaterialityAssessmentHeaderProps = {
  title: string;
};

export const MaterialityAssessmentHeader: FC<MaterialityAssessmentHeaderProps> = ({
  title
}) => {
  return (
    <HStack justifyContent="space-between" w="100%">
      <Typography variant="h2">{title}</Typography>
      <HStack>
        <Button variant="ghost" leftIcon={<IconHelpCircle size="16px" />} fontWeight={500}>
          Learn more
        </Button>
      </HStack>
    </HStack>
  );
};
