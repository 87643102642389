import readXlsxFile from 'read-excel-file';
import { Uploader } from 'Features/CSVUploader';
import { Modal } from 'Molecules';
import { useState } from 'react';
import { getFlattenedMetricRows, MetricsTableData } from '../MetricAnswers.hooks';
import { getUniqueMetricsRefs, useExportEsrsMetricTable } from './MetricTableExport.hooks';
import {
  Esrs_Datapoint_Constraint_,
  Esrs_Datapoint_Update_Column_,
  GetEsrsMetricsDatapointsDocument_,
  GetEsrsMetricsDatapointsQueryVariables_,
  GetEsrsMetricsDatapointsQuery_,
  ShortUser,
  useUpsertMetricsAnswerMutation,
} from 'models';
import { useParams } from 'react-router-dom';
import { useUserData } from '@nhost/react';
import { ExcelDataType, getDatapoints, matchHeaderToColumnKeys } from './MetricsExcelUpload.hooks';
import { useToast } from 'utils/hooks';
import { nhost } from 'utils/nhost';

const METRIC_DESCRIPTIONS = [
  {
    title: 'Download our template',
    description:
      'This template is already pre-populated with information about your company structure and metrics.',
    button: 'Download',
  },
  {
    title: 'Fill in the file',
    description:
      'Input your data to the corresponding fields of your template. Note that you can input only numbers or leave cells empty in this file. Other symbols are not supported.',
  },
  {
    title: 'Export Excel file',
    description: 'Save your file in the correct format (Excel, .xlsx)',
  },
  {
    title: 'Upload your file here',
  },
];

export const MetricsExcelUploadModal = ({
  isOpen,
  onClose,
  metrics,
  disclosureRequirement,
  businessUnit,
  areTableMetricsQuarterly,
  areTableMetricsYearly,
  companyStandardId,
  companyReportingUnitId,
}: {
  isOpen: boolean;
  onClose: () => void;
  metrics: MetricsTableData[];
  disclosureRequirement: string;
  businessUnit: string;
  areTableMetricsQuarterly: boolean;
  areTableMetricsYearly: boolean;
  companyStandardId: string;
  companyReportingUnitId?: string;
}) => {
  const { reportingUnitId, esrsAssessmentId = '' } = useParams();
  const [isFileUploaded, setIsFileUploaded] = useState(false);
  const [fileName, setFileName] = useState<string>();
  const [excelData, setExcelData] = useState<ExcelDataType>();
  const user: ShortUser | null = useUserData();
  const toast = useToast();

  const [upsertAnswer] = useUpsertMetricsAnswerMutation();
  const exportMetrics = useExportEsrsMetricTable();

  const allMetricsRefs = getUniqueMetricsRefs(metrics);

  const handleFileUpload = (file: File) => {
    readXlsxFile(file).then((rows) => {
      const fileHeader = rows[0];
      const headerKeys = matchHeaderToColumnKeys(fileHeader);
      const fileRows = rows.slice(1);
      const definedRows: ExcelDataType = [];

      fileRows.forEach((row) => {
        const mappedRow = {} as { [key: string]: any };
        headerKeys.forEach((key, index) => {
          mappedRow[key] = row[index];
        });
        definedRows.push(mappedRow as ExcelDataType[number]);
      });

      setExcelData(definedRows);
      setIsFileUploaded(true);
      setFileName(file.name);
    });
  };

  const onConfirm = async () => {
    toast({
      text: 'Uploading...',
      duration: null,
    });
    const { data: metricsAnswers } = await nhost.graphql.request<
      GetEsrsMetricsDatapointsQuery_,
      GetEsrsMetricsDatapointsQueryVariables_
    >(GetEsrsMetricsDatapointsDocument_, {
      reportingUnitId: reportingUnitId ?? companyReportingUnitId ?? '',
      assessmentId: esrsAssessmentId,
      metricRefs: allMetricsRefs,
    });

    upsertAnswer({
      variables: {
        objects:
          excelData?.map((row) => {
            const datapoints =
              metricsAnswers?.answers.find((answer) => answer.metricRef === row.metricRef)
                ?.datapoints ?? [];
            const flattenedMetrics = metrics.flatMap((metric) =>
              getFlattenedMetricRows(metric).filter((r) => !r.subRows?.length)
            );
            return {
              reportingUnitId: reportingUnitId ?? companyReportingUnitId ?? '',
              assessmentId: esrsAssessmentId,
              metricRef: row.metricRef,
              datapoints: {
                data: getDatapoints(row, user, datapoints, flattenedMetrics, companyStandardId),
                on_conflict: {
                  constraint: Esrs_Datapoint_Constraint_.DatapointPkey_,
                  update_columns: [
                    Esrs_Datapoint_Update_Column_.Value_,
                    Esrs_Datapoint_Update_Column_.AuthorId_,
                  ],
                },
              },
            };
          }) ?? [],
      },
    }).then(() => {
      toast({
        text: '',
        duration: 0,
        destroyAll: true,
      });
      setIsFileUploaded(false);
      setFileName('');
      onClose();
    });
  };

  return (
    <Modal
      title="Import metrics values"
      confirmText="Upload file"
      isOpen={isOpen}
      onClose={onClose}
      onConfirm={onConfirm}
      size="md"
    >
      <Uploader
        description={METRIC_DESCRIPTIONS}
        onUpload={handleFileUpload}
        fileName={fileName}
        isFileUploaded={isFileUploaded}
        setIsFileUploaded={setIsFileUploaded}
        rejectionMessages={[]}
        acceptedTypes={['.xlsx']}
        titleWidth="500px"
        onDownloadClick={() => {
          exportMetrics(
            metrics,
            disclosureRequirement,
            businessUnit,
            areTableMetricsQuarterly,
            areTableMetricsYearly,
            companyStandardId,
            companyReportingUnitId
          );
        }}
      />
    </Modal>
  );
};
