import { HStack, VStack } from '@chakra-ui/react';
import { Button } from 'Atoms';
import { ActivitiesIllustration, PortfolioIllustration, Typography } from 'Tokens';
import { AddAssessmentModal } from 'containers/Assessments/pieces/AddAssessmentModal';
import { CompanyAssessmentDetailsFieldsFragment_, InvestorsQuery_ } from 'models';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'utils/translation';

export const AssessmentEmptyState = ({
  isOther,
  investors,
  reportingPeriod,
  allAssessments,
}: {
  isOther?: boolean;
  investors: InvestorsQuery_['investors'];
  reportingPeriod?: string;
  allAssessments: CompanyAssessmentDetailsFieldsFragment_[] | undefined;
}) => {
  const { t } = useTranslation('assessment');
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const investorsNames = investors
    .map((investor) => investor?.portfolio?.ownerCompany.name)
    .join(', ');
  const handleButtonClick = () => {
    if (allAssessments?.length) {
      setIsOpen(true);
    } else {
      navigate(`new/${isOther ? '' : reportingPeriod ?? ''}`);
    }
  };

  return (
    <>
      <VStack width="100%" alignItems="center" justifyContent="center" paddingBottom="20px">
        <HStack gap="24px">
          {isOther ? (
            <PortfolioIllustration boxSize="104" color="border.illustration" />
          ) : (
            <ActivitiesIllustration boxSize="104" color="border.illustration" />
          )}
          <VStack gap="16px" width="536px" alignItems="start">
            <VStack gap="4px" alignItems="start">
              <Typography variant="h3">
                {isOther
                  ? t('assessment:reportingPeriodTiles.emptyState.otherAssessments')
                  : t('assessment:reportingPeriodTiles.emptyState.firstAssessment')}
              </Typography>
              <Typography variant="body" color="text.muted">
                {isOther
                  ? t('assessment:reportingPeriodTiles.emptyState.otherAssessmentsDescription')
                  : t('assessment:reportingPeriodTiles.emptyState.firstAssessmentDescription', {
                      investorsNames: investorsNames,
                    })}
              </Typography>
            </VStack>
            <Button variant="secondary" size="sm" onClick={handleButtonClick}>
              {t('assessment:form.createAssessment')}
            </Button>
          </VStack>
        </HStack>
      </VStack>
      <AddAssessmentModal
        isOpen={isOpen}
        isOther={isOther}
        onClose={() => setIsOpen(false)}
        assessments={allAssessments}
        reportingPeriod={reportingPeriod ?? `Q1-${new Date().getFullYear()}`}
      />
    </>
  );
};
