import { Table, Thead, Tr, Td, Th, Tbody, TypographyProps } from '@chakra-ui/react';
import { Tabs } from 'Atoms';
import { Typography } from 'Tokens';
import { useCurrentQuestion } from 'context';
import { useTranslation } from 'utils/translation';
import { QuestionsList } from './QuestionsList';
import { RemoveIcon } from 'Tokens/Icons/Function';
import { CheckIcon } from 'Tokens/Icons/Status';

export const ProgressLabel = ({ progress, ...props }: { progress: number } & TypographyProps) => {
  const { t } = useTranslation('assessment');
  return (
    <Typography
      borderRadius="12px"
      px="8px"
      py="4px"
      backgroundColor={`bg.${progress < 1 ? 'unknown' : 'success'}`}
      textTransform="uppercase"
      whiteSpace="nowrap"
      display="inline-block"
      {...props}
      variant="body"
    >
      <Typography variant="detail" color={`text.${progress < 1 ? 'muted' : 'success'}`}>
        {progress < 1 ? t('assessment:progress.todo') : t('assessment:progress.done')}
      </Typography>
    </Typography>
  );
};

export function CriteriaTable() {
  const { subQuestions, dependencies } = useCurrentQuestion();
  const questionsetStatus = Object.fromEntries(
    (subQuestions ?? [])?.map((sq) => {
      const { progress, notAligned } = sq.progress;
      const component = notAligned ? (
        <RemoveIcon color="text.notCompliant" />
      ) : progress == 1.0 ? (
        <CheckIcon color="text.compliant" />
      ) : (
        <ProgressLabel progress={progress} />
      );
      return [sq.questionSet, component];
    })
  );
  return (
    <Table>
      <Thead>
        <Tr>
          <Th />
          {subQuestions?.map((sq) => (
            <Th key={sq.title} textAlign="center">
              {sq.title}
            </Th>
          ))}
        </Tr>
      </Thead>
      <Tbody>
        {Object.entries(dependencies?.table ?? []).map(([option, questionsets]) => (
          <Tr key={option}>
            <Td pl="xs">{option}</Td>
            {(dependencies?.questionSets ?? []).map((qs) => (
              <Td key={qs} textAlign="center">
                {questionsets.includes(qs) && questionsetStatus[qs]}
              </Td>
            ))}
          </Tr>
        ))}
      </Tbody>
    </Table>
  );
}
export const SubquestionTabs = ({ isLocked }: { isLocked: boolean }) => {
  const { subQuestions, number, isAligned, activityAssessmentId } = useCurrentQuestion();
  let offset = 0;
  return (
    <Tabs
      items={(subQuestions ?? []).map((sq) => {
        offset += sq.questions.length;
        return {
          title: sq.title,
          rightElement:
            !isAligned || sq.progress.progress == 1.0 ? (
              <ProgressLabel progress={sq.progress.progress} />
            ) : undefined,
          content: (
            <QuestionsList
              activityAssessmentId={activityAssessmentId}
              questions={sq.questions}
              prefix={number}
              offset={offset - sq.questions.length}
              isLocked={isLocked}
            />
          ),
          id: sq.title,
        };
      })}
    />
  );
};
