import { Center, Box, useDisclosure, VStack } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useDropzone, DropzoneOptions } from 'react-dropzone';
import { useTranslation } from 'utils/translation';
import { Alert } from 'Atoms';
import { Typography } from 'Tokens';
import { FileStatus } from 'Molecules/File/File';
import { Modal, File as FileViewer } from 'Molecules';
import { FileItemRef, useFileUploadItem, useMultipleFilesUpload, useUserData } from '@nhost/react';
import {
  AddFileMutation_,
  CompanyFilesDocument_,
  DocumentationFile,
  useAddFileMutation,
} from 'models';
import { useCurrentCompanyId, useToast } from 'utils/hooks';
import { useDeleteFile } from 'containers/Drive/File.hooks';
import { uniq } from 'lodash';
import { getNameExtension } from 'utils/files';
import { DocumentIcon } from 'Tokens/Icons/Data';
import { captureException } from '@sentry/browser';

type ReplaceProps = {
  setFileId: (argument: string) => void;
  setDocumentId: (argument: string) => void;
  updateSelected?: (updater: (current: DocumentationFile[]) => DocumentationFile[]) => void;
  displayToast?: boolean;
};

const FileItem: React.FC<
  React.PropsWithChildren<
    {
      fileRef: FileItemRef;
    } & ReplaceProps
  >
> = ({ fileRef, setFileId, updateSelected, displayToast = false, setDocumentId }) => {
  const { isUploaded, isUploading, name, isError, destroy, id } = useFileUploadItem(fileRef);
  const [status, setStatus] = useState<FileStatus | undefined>();
  const { companyId } = useCurrentCompanyId();
  const [saveFile] = useAddFileMutation();
  const { t } = useTranslation('common');
  const { id: userId } = useUserData() ?? { id: undefined };
  const deleteFile = useDeleteFile();
  const [fileData, setFileData] = useState<AddFileMutation_ | null | undefined>();
  const user = useUserData();

  useEffect(() => {
    if (isError) {
      setStatus('failed');
    }
    if (isUploaded) {
      setStatus('uploaded');
    }
    if (isUploading) {
      setStatus('uploading');
    }
  }, [isError, isUploaded, isUploading, status]);

  useEffect(() => {
    if (isUploaded && id) {
      setFileId(id);
    }
    const setUpFileUrl = async () => {
      const { data } = await saveFile({
        variables: {
          input: {
            title: getNameExtension(name ?? '').name ?? t('common:unknown'),
            tags: [],
            description: '',
            source: '',
            ...(id ? { storageFileId: id } : {}),
            companyId,
            uploadedById: userId,
          },
        },
        refetchQueries: [CompanyFilesDocument_],
      });
      setFileData(data);
      setDocumentId(data?.file?.id);
      const updater = (current: DocumentationFile[]) => {
        const uploadedFile = data?.file as DocumentationFile;
        return uniq([...current, uploadedFile]);
      };
      updateSelected?.(updater);
    };
    if (isUploaded) {
      setUpFileUrl();
    }
  }, [id, isUploaded]);
  return (
    <>
      {status && !displayToast && (
        <FileViewer
          file={{
            description: '',
            id: id,
            source: '',
            storageFile: {
              id: id,
              name: name,
            },
            tags: undefined,
            title: getNameExtension(name ?? '').name ?? '',
            updatedAt: new Date(),
            uploadedBy: {
              avatarUrl: user?.avatarUrl ?? '',
              displayName: user?.displayName ?? '',
              email: user?.email,
              id: user?.id,
            },
          }}
          status={isUploading ? 'uploading' : isUploaded ? 'uploaded' : 'default'}
          onCancelClick={async () => {
            if (isUploading) {
              destroy();
            } else if (isUploaded) {
              destroy();
              deleteFile({ id: fileData?.file?.id, storageFileId: id ?? '' });
            }
          }}
        />
      )}
    </>
  );
};

export type FileUploaderProps = {
  text?: string;
  multiple: boolean;
  updateSelected?: (updater: (current: DocumentationFile[]) => DocumentationFile[]) => void;
  displayToast?: boolean;
  shouldUpload?: boolean;
  onUpload?: (files: File[]) => void;
  isUploadDone?: boolean;
  setIsUploadDone?: (param: boolean) => void;
  fileName?: string;
  onDone?: (files: File[]) => void;
  titleWidth?: string;
} & DropzoneOptions;

export const FileUploader = ({
  text,
  multiple,
  updateSelected,
  displayToast,
  shouldUpload = true,
  onUpload,
  isUploadDone,
  setIsUploadDone,
  onDone,
  fileName,
  titleWidth,
  ...props
}: FileUploaderProps) => {
  const [fileId, setFileId] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string[]>([]);
  const [hover, setHover] = useState<boolean>(false);
  const [singleFile, setSingleFile] = useState<File[]>([]);
  const [currentFile, setCurrentFile] = useState<File[]>([]);
  const [documentId, setDocumentId] = useState<string>();
  const [status, setStatus] = useState<FileStatus | undefined>(undefined);
  const { onOpen, onClose, isOpen } = useDisclosure();
  const { t } = useTranslation(['common', 'files']);
  const { upload, files, isUploading, isUploaded, isError } = useMultipleFilesUpload();
  const toast = useToast();
  const deleteFile = useDeleteFile();

  useEffect(() => {
    if (displayToast) {
      if (isUploading) {
        toast({
          text: t('files:uploadingFiles', { count: files.length }),
        });
      }
      if (isUploaded) {
        toast({ text: t('files:uploaded'), destroyAll: true });
      }
      if (isError) {
        captureException('file_upload_failed');
        toast({
          text: t('files:uploadFailed'),
          variant: 'danger',
          destroyAll: true,
        });
      }
    }
  }, [isUploaded, isUploading, isError]);

  useEffect(() => {
    if (isUploadDone) {
      setStatus('uploaded');
    }
  }, [isUploadDone]);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    multiple: multiple,
    onDropAccepted: (acceptedFiles) => {
      const workingFiles = acceptedFiles.filter((file) => file);
      if (workingFiles.length === 0) {
        captureException('file_upload_failed - no working files');
        toast({
          text: t('files:uploadFailed'),
          variant: 'danger',
          destroyAll: true,
        });
      }
      if (!shouldUpload) {
        if (singleFile.length) setCurrentFile(workingFiles);
        else setSingleFile(workingFiles);
        if (isUploadDone) {
          onOpen();
        } else {
          setStatus('uploading');
          onUpload?.(workingFiles);
        }
      } else {
        if (files?.length == 1 && !multiple) {
          onOpen();
          setSingleFile(workingFiles);
          onDone?.(workingFiles);
        } else {
          upload({ files: workingFiles });
          onDone?.(workingFiles);
        }
      }
    },
    onDropRejected: (fileRejections) => {
      fileRejections.forEach((file) => {
        setErrorMessage(uniq([...errorMessage, file.errors[0].message]));
      });
    },
    ...props,
  });
  const user = useUserData();
  return (
    <VStack width="100%" height="100%">
      <Center
        {...getRootProps({
          borderStyle: 'dashed',
          width: '100%',
          minHeight: '100%',
          borderColor: isDragActive ? 'border.drop' : 'border.decorative',
          borderWidth: '2px',
          borderRadius: '10px',
          cursor: 'pointer',
          _hover: {
            bg: 'bg.hover',
          },
        })}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
      >
        <Box padding="40px 56px" textAlign="center">
          <input {...getInputProps()} />
          <Box
            boxSize="48px"
            bg={isDragActive ? 'bg.drop' : hover ? 'bg.hover' : 'bg.muted'}
            padding="10px"
            margin="0 auto"
            marginBottom="16px"
            borderRadius="50%"
          >
            <DocumentIcon
              boxSize="28px"
              color={isDragActive ? 'text.action' : hover ? 'text.muted' : 'text.hint'}
            />
          </Box>
          <Typography variant="h3" marginBottom="2px">
            {text ? text : t('common:fields.file.selectFile')}
          </Typography>
          <Typography variant="detail" color="text.muted">
            {multiple ? t('common:fields.file.multipleFiles') : t('common:fields.file.oneFile')}
          </Typography>
        </Box>
      </Center>
      {files?.map((ref) => (
        <FileItem
          key={ref.id}
          fileRef={ref}
          setFileId={setFileId}
          updateSelected={updateSelected}
          displayToast={displayToast}
          setDocumentId={setDocumentId}
        />
      ))}
      {!shouldUpload && !displayToast && status && (
        <FileViewer
          file={{
            description: '',
            id: '',
            source: '',
            storageFile: {
              id: '',
              name: singleFile[0]?.name ?? fileName,
            },
            tags: undefined,
            title: singleFile[0]?.name ?? fileName ?? '',
            updatedAt: new Date(),
            uploadedBy: {
              avatarUrl: user?.avatarUrl ?? '',
              displayName: user?.displayName ?? '',
              email: user?.email,
              id: user?.id,
            },
          }}
          status={status}
          onCancelClick={() => {
            setStatus(undefined);
            if (!shouldUpload) {
              if (setIsUploadDone) {
                setIsUploadDone(false);
                setSingleFile([]);
              }
            }
          }}
          fileWidth={{
            titleMaxWidth: titleWidth,
            titleMinWidth: titleWidth,
          }}
        />
      )}
      {errorMessage.map((error, index) => (
        <Box marginBottom="8px" key={index} width="100%">
          <Alert
            status="warning"
            title={error}
            closable={true}
            width="100%"
            onCloseClick={() => {
              setErrorMessage((curErrors) => curErrors.filter((curE) => curE !== error));
            }}
          />
        </Box>
      ))}
      <Modal
        size="xs"
        isOpen={isOpen}
        onClose={onClose}
        title={t('common:fields.file.replaceFile')}
        confirmText={t('common:actions.replace')}
        onConfirm={async () => {
          if (!shouldUpload) {
            setSingleFile(currentFile);
            onUpload?.(currentFile.length ? currentFile : singleFile);
          } else {
            await deleteFile({ id: documentId ?? '', storageFileId: fileId ?? '' }).then(() => {
              files.pop();
              upload({ files: singleFile });
            });
          }
          onClose();
        }}
        onCancel={() => {
          onClose();
        }}
      >
        <></>
      </Modal>
    </VStack>
  );
};
