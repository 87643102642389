import { CompanyAssessmentDetails, InvestorsQuery_ } from 'models';
import React, { useMemo, useState } from 'react';
import { useCurrentCompany, useToast } from 'utils/hooks';
import { useTranslation } from 'utils/translation';
import { useDeleteCompanyAssessment } from '../Assessments.hooks';
import { useNavigate } from 'react-router-dom';
import { ColumnDef } from '@tanstack/react-table';
import { Button, Checkbox, Tag, Tooltip, TruncatableText } from 'Atoms';
import { Menu } from 'Molecules/Menu';
import { AddIcon, CopyIcon, DeleteIcon, EditIcon } from 'Tokens/Icons/Function';
import { TRACKING_EVENTS } from 'utils/mixpanel';
import mixpanel from 'mixpanel-browser';
import { AssessmentStatusTag } from 'Organisms/AssessmentStatusTag';
import {
  dateToYYYYMMDD,
  formatDisplayQuarterYear,
  formatQuarterYear,
  getQuarterFromDate,
  stringToYear,
} from 'utils/date';
import { noop, uniqBy } from 'lodash';
import { DuplicateAssessmentModal } from './DuplicateAssessmentModal';
import { AlertDialog, Table } from 'Molecules';
import { ShareButton } from 'Molecules/ShareButton';
import { HStack, VStack } from '@chakra-ui/react';
import { CompanyAvatarWithName, UserAvatar } from 'Organisms';
import { LabelText, Typography } from 'Tokens';
import { getLatestTaxonomyDate } from 'containers/Home/Home.hooks';
import { WarningIcon } from 'Tokens/Icons/Status';
import { AddAssessmentModal } from './AddAssessmentModal';
import { useUnshareCompanyAssessment } from 'containers/Investors';
import { useDeleteAndUnshareAssessmentWithInvestors } from './Assessment.hooks';

type AssessmentsTableProps = {
  withSelection?: {
    selected: string[];
    setSelected: (val: string[]) => void;
  };
  hideActions?: boolean;
  hideSharedWith?: boolean;
  assessments: CompanyAssessmentDetails[];
  allAssessments?: CompanyAssessmentDetails[];
  openModal?: () => void;
  investors?: InvestorsQuery_['investors'];
  setCompanyAssessment?: (val: CompanyAssessmentDetails) => void;
  isOther?: boolean;
  setSelectedReportingPeriod?: (val?: string) => void;
  reportingPeriod?: string;
};

export const AssessmentsTable = ({
  assessments,
  allAssessments,
  hideActions = false,
  hideSharedWith = false,
  withSelection = undefined,
  openModal,
  investors,
  setCompanyAssessment,
  isOther,
  setSelectedReportingPeriod,
  reportingPeriod,
}: AssessmentsTableProps) => {
  const { t } = useTranslation(['assessment', 'common']);
  const { company } = useCurrentCompany();
  const [toDelete, setToDelete] = useState<string>();
  const showSelectors = withSelection !== undefined;
  const deleteAndUnshareAssessment = useDeleteAndUnshareAssessmentWithInvestors();
  const [assessmentToDuplicate, setAssessmentToDuplicate] = useState<{
    id: string;
    startDate: string;
  }>();

  const newReportingPeriod = useMemo(() => {
    if (assessmentToDuplicate) {
      const quarter = getQuarterFromDate(new Date(assessmentToDuplicate?.startDate));
      const year = stringToYear(assessmentToDuplicate?.startDate);
      return formatQuarterYear(quarter, year);
    }
    return `Q1-${new Date().getFullYear()}`;
  }, [assessmentToDuplicate]);

  const showSharedWithColumn = useMemo(
    () => hideSharedWith || !investors?.length,
    [hideSharedWith, investors]
  );

  const navigate = useNavigate();
  const toast = useToast();

  const [isOpen, setIsOpen] = useState(false);

  const columns = useMemo(() => {
    const { selected = [], setSelected } = withSelection ?? {};
    const checkboxColumn: ColumnDef<CompanyAssessmentDetails> = {
      header: '',
      id: 'checkbox',
      cell: ({ row }) => (
        <Checkbox
          isChecked={selected?.includes(row.original.id)}
          onChange={() => {
            if (selected?.includes(row.original.id)) {
              setSelected?.(selected?.filter((id) => id !== row.original.id));
            } else {
              setSelected?.([...(selected ?? []), row.original.id]);
            }
          }}
        />
      ),
    };
    const actionsColumn: ColumnDef<CompanyAssessmentDetails>[] = [
      {
        header: '',
        id: 'actions',
        meta: {
          width: '2%',
        },
        cell: ({ row }) => {
          const startDate = new Date(row.original.aggregate.startDate);
          const quarter = getQuarterFromDate(startDate);
          const year = startDate.getFullYear();
          const formattedPeriod = formatQuarterYear(quarter, year);
          const hasInvestor = !!investors?.find(
            (investor) => formatQuarterYear(investor.quarter, investor.year) === formattedPeriod
          );

          return (
            <Menu
              key={row.id}
              sections={[
                {
                  actions: [
                    {
                      id: `edit-${row.id}`,
                      title: t('common:actions.edit'),
                      onClick: () => {
                        navigate(`${row.original.id}/edit/${hasInvestor ? formattedPeriod : ''}`);
                      },
                      isDisabled: row.original.isLocked,
                      leftElement: <EditIcon color="inherit" />,
                    },
                    {
                      id: `duplicate-${row.id}`,
                      title: t('common:actions.duplicate'),
                      onClick: () =>
                        setAssessmentToDuplicate({
                          id: row.original.id,
                          startDate: row.original.startDate,
                        }),
                      leftElement: <CopyIcon color="inherit" />,
                    },
                  ],
                },
                {
                  actions: [
                    {
                      id: `delete-${row.id}`,
                      title: t('common:actions.delete'),
                      variant: 'destructive',
                      onClick: () => {
                        mixpanel.track(TRACKING_EVENTS.ASSESSMENTS.DELETE, {
                          companyId: company?.id,
                        });
                        setToDelete(row.original.id);
                      },
                      isDisabled: row.original.isLocked,
                      leftElement: <DeleteIcon color="inherit" />,
                    },
                  ],
                },
              ]}
            />
          );
        },
      },
    ];

    const nameAndPeriodColumns: ColumnDef<CompanyAssessmentDetails>[] = [
      {
        header: t('common:assessmentTable.name'),
        accessorKey: 'aggregateTitle',
        cell: ({ row }) => (
          <TruncatableText
            variant="bodyStrong"
            text={row.original.aggregate.title}
            color="text.default"
          />
        ),
      },
      {
        header: t('common:assessmentTable.fiscalPeriod'),
        id: 'fiscalPeriod',
        cell: ({ row }) => {
          const assessment = row.original;
          const assessmentStartDate = new Date(assessment.startDate);
          const assessmentQuarter = getQuarterFromDate(assessmentStartDate);
          const assessmentYear = assessmentStartDate.getFullYear();
          const assessmentFormattedPeriod = formatQuarterYear(assessmentQuarter, assessmentYear);

          const portfolioFormattedPeriod = formatQuarterYear(
            assessment.portfoliosWithAccess[0]?.quarter,
            assessment.portfoliosWithAccess[0]?.year
          );

          const quarter = getQuarterFromDate(new Date(assessment.startDate));
          const year = stringToYear(assessment.startDate);
          const formattedPeriod = formatDisplayQuarterYear(`${quarter}-${year}`);

          const areMatchingPeriods = assessmentFormattedPeriod === portfolioFormattedPeriod;

          return !areMatchingPeriods && !!assessment.portfoliosWithAccess[0] ? (
            <Tooltip label={t('assessment:differentReportingPeriods')}>
              <Tag size="xs" variant="warning" leftIcon={<WarningIcon color="inherit" />}>
                {formattedPeriod}
              </Tag>
            </Tooltip>
          ) : (
            <Tag size="xs">{formattedPeriod}</Tag>
          );
        },
      },
    ];

    const statusColumn: ColumnDef<CompanyAssessmentDetails> = {
      header: t('common:assessmentTable.status'),
      id: 'status',
      cell: ({ row }) => <AssessmentStatusTag isLocked={row.original.isLocked} />,
    };

    const stakeHoldersAccessColumn: ColumnDef<CompanyAssessmentDetails> = {
      header: t('common:assessmentTable.stakeholders'),
      id: 'status',
      cell: ({ row }) => {
        const companiesSharedWith = uniqBy(
          investors
            ?.filter((c) => c.sharedAssessment?.id === row.original.id)
            .map((investor) => investor?.portfolio?.ownerCompany),
          'name'
        );

        return (
          <ShareButton
            isShared={!!row.original.portfoliosWithAccess.length}
            companiesSharedWith={companiesSharedWith}
            onClick={() => {
              if (openModal) openModal();
              if (setCompanyAssessment) setCompanyAssessment(row.original);
              if (setSelectedReportingPeriod) setSelectedReportingPeriod(reportingPeriod);
            }}
            size="sm"
            width="180px"
          />
        );
      },
    };

    const contactColumn: ColumnDef<CompanyAssessmentDetails>[] = [
      {
        header: t('common:assessmentTable.assignedTo'),
        id: 'contactPerson',
        cell: ({ row }) => {
          const { contactPerson } = row.original.aggregate;
          if (contactPerson)
            return (
              <HStack>
                <UserAvatar user={contactPerson} size="sm" />
                <LabelText fontSize="md">{contactPerson.displayName}</LabelText>
              </HStack>
            );
          return <CompanyAvatarWithName company={company} />;
        },
      },
    ];

    const lastEditedColumn: ColumnDef<CompanyAssessmentDetails> = {
      header: t('common:assessmentTable.lastEdited'),
      id: 'lastEdited',
      cell: ({ row }) => {
        const date = getLatestTaxonomyDate(row.original);
        const newDate = dateToYYYYMMDD(new Date(date));
        return <Typography>{newDate}</Typography>;
      },
    };

    return [
      ...(showSelectors ? [checkboxColumn] : []),
      ...nameAndPeriodColumns,
      statusColumn,
      ...(showSharedWithColumn ? [] : [stakeHoldersAccessColumn]),
      ...contactColumn,
      lastEditedColumn,
      ...(hideActions ? [] : actionsColumn),
    ];
  }, [showSelectors, hideActions, withSelection, investors, hideSharedWith]);
  return (
    <>
      <VStack width="100%" alignItems="start">
        <Table<CompanyAssessmentDetails>
          bottomRowBorder={false}
          headerBorderColor={'border.decorative'}
          data={assessments}
          columns={columns}
          onRowClick={(original: any) =>
            withSelection ? noop() : navigate(`${(original as CompanyAssessmentDetails).id}`)
          }
          rowProps={{
            _hover: {
              cursor: 'pointer',
              bg: 'bg.hover',
            },
          }}
          cellProps={{
            padding: '0 0 0 8px',
          }}
          headerPadding="14px 8px"
        />
        {!!isOther && (
          <Button
            variant="ghost"
            onClick={() => setIsOpen(true)}
            size="sm"
            leftIcon={<AddIcon color="text.muted" />}
          >
            {t('assessment:form.createAssessment')}
          </Button>
        )}
      </VStack>
      <AlertDialog
        isOpen={!!toDelete}
        onClose={() => setToDelete(undefined)}
        onConfirm={() => {
          deleteAndUnshareAssessment(toDelete, assessments, investors).then(() => {
            toast({
              text: t('assessment:list.delete.success'),
            });
            setToDelete(undefined);
          });
        }}
        title={t('assessment:list.title')}
        confirmLabel={t('assessment:list.confirm')}
      >
        <Typography variant="body">{t('assessment:list.alert')}</Typography>
      </AlertDialog>
      {assessmentToDuplicate && (
        <DuplicateAssessmentModal
          isOpen={!!assessmentToDuplicate}
          onClose={() => setAssessmentToDuplicate(undefined)}
          id={assessmentToDuplicate.id}
          startDate={newReportingPeriod}
        />
      )}
      <AddAssessmentModal
        isOpen={isOpen}
        isOther={isOther}
        onClose={() => setIsOpen(false)}
        assessments={allAssessments}
      />
    </>
  );
};
