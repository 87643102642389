import { useState, useMemo, SetStateAction, Dispatch } from 'react';
import { Tabs, Button, IconButton, Checkbox, VisibleIf, Alert, Tooltip } from 'Atoms';
import { Modal, SearchInput } from 'Molecules';
import { Typography } from 'Tokens';
import {
  Box,
  BoxProps,
  HStack,
  VStack,
  useDisclosure,
  Skeleton,
  Collapse,
  Divider,
} from '@chakra-ui/react';
import { PaiCategory, PaiIndicator, PaiTable, usePaiTablesQuery } from 'models';
import { useCurrentCompany } from 'utils/hooks';
import { ChevronDownIcon, ChevronRightIcon } from 'Tokens/Icons/Direction';
import { isEqual, keyBy, uniqBy } from 'lodash';
import { DeleteIcon } from 'Tokens/Icons/Function';
import { PaiDataCollectionSelector } from './PaiDataCollectionSelector';
import { useParams } from 'react-router-dom';
import usePortfolios from 'containers/Portfolios/Portfolios.hooks';
import { PaiDataCollectionFrequencySelector } from './PaiDataCollectionFrequencySelector';
import { IndicatorDataCollectionSelector } from './IndicatorDataCollectionSelector';
import { IndicatorFrequencySelector } from './IndicatorFrequencySelector';

export enum DataCollectionOption {
  allCompanies = 'All companies',
  selectCompanies = 'Select companies',
  decidePerIndicator = 'Decide per indicator',
}

export type DataCollection = {
  option: DataCollectionOption;
  selectedPortfolioCompanyIds: string[];
};

export enum ReportingFrequency {
  yearly = 'Yearly',
  quarterly = 'Quarterly',
  customPerIndicator = 'Custom per indicator',
}

export type IndicatorFrequency = {
  q1?: boolean;
  q2?: boolean;
  q3?: boolean;
  q4?: boolean;
  year?: boolean;
};

export interface IndicatorConfiguration {
  paiTableIndicatorReference: string;
  isForAllCompanies: boolean;
  selectedPortfolioCompanyIds: string[];
  periods: IndicatorFrequency;
}

const IndicatorListItem = ({
  indicator,
  isSelected,
  onSelect,
}: {
  indicator: PaiIndicator;
  isSelected?: boolean;
  onSelect?: (isSelected: boolean) => void;
}) => {
  return (
    <HStack
      _hover={{
        bg: 'bg.hover',
        cursor: !!onSelect ? 'pointer' : 'default',
      }}
      bg="bg.default"
      border="1px solid"
      borderColor="border.decorative"
      p="16px"
      borderRadius="10px"
      width="100%"
      alignItems="flex-start"
      userSelect="none"
      onClick={() => onSelect && onSelect(!isSelected)}
    >
      <VisibleIf condition={!!onSelect}>
        <Checkbox
          as={Box}
          pt="4px"
          isChecked={isSelected}
          onChange={(e) => {
            onSelect?.(e.target.checked);
          }}
        />
      </VisibleIf>
      <VStack alignItems="flex-start" justifyContent="flex-start">
        <Typography variant="h3" userSelect="none">
          {indicator?.title}
        </Typography>
        {indicator?.investorMetrics.map((metric) => (
          <Typography key={metric.investorMetric.reference} variant="body" userSelect="none">
            {metric.investorMetric.title}
          </Typography>
        ))}
      </VStack>
    </HStack>
  );
};

const CategoryListItem = ({
  category,
  selectedIndicators,
  setSelectedIndicators,
  selectable,
  query = '',
}: {
  category: PaiCategory;
  selectedIndicators: IndicatorConfiguration[];
  setSelectedIndicators: (selection: IndicatorConfiguration[]) => void;
  selectable: boolean;
  query?: string;
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure({ defaultIsOpen: true });

  const filteredIndicators = useMemo(() => {
    return category.indicators
      .filter((indicator) => !indicator.isHidden)
      .filter((indicator) =>
        !selectable
          ? selectedIndicators.map((i) =>
              i.paiTableIndicatorReference.includes(indicator.reference)
            )
          : true
      )
      .filter((indicator) =>
        query ? indicator.title.toLowerCase().includes(query.toLowerCase()) : true
      );
  }, [category.indicators, selectedIndicators, selectable, query]);

  const filteredIndicatorCount = filteredIndicators.length;

  if (filteredIndicatorCount === 0) {
    return null;
  }

  return (
    <VStack
      bg="bg.muted"
      padding="16px"
      borderRadius="12px"
      width="100%"
      justifyContent="flex-start"
      alignItems="stretch"
      spacing="16px"
    >
      <HStack width="100%" justifyContent="space-between" onClick={isOpen ? onClose : onOpen}>
        <HStack>
          <IconButton
            variant="ghost"
            aria-label="toggle"
            size="sm"
            icon={
              isOpen ? <ChevronDownIcon color="inherit" /> : <ChevronRightIcon color="inherit" />
            }
            onClick={isOpen ? onClose : onOpen}
          />
          <HStack spacing="8px" userSelect="none">
            <Typography variant="h3">{category.title} </Typography>
            <Typography variant="body" color="text.muted">
              {filteredIndicatorCount}
            </Typography>
          </HStack>
        </HStack>
      </HStack>
      <Collapse in={isOpen}>
        <VStack spacing="12px" alignItems="flex-start" width="100%">
          {filteredIndicators.map((indicator) => (
            <IndicatorListItem
              key={indicator.reference}
              indicator={indicator}
              isSelected={selectedIndicators
                .map((i) => i.paiTableIndicatorReference)
                .includes(indicator.reference)}
              onSelect={
                selectable
                  ? (isSelected) =>
                      setSelectedIndicators(
                        isSelected
                          ? [
                              ...selectedIndicators,
                              {
                                paiTableIndicatorReference: indicator.reference,
                                periods: { year: true },
                                isForAllCompanies: true,
                                selectedPortfolioCompanyIds: [],
                              },
                            ]
                          : selectedIndicators.filter(
                              (i) => i.paiTableIndicatorReference !== indicator.reference
                            )
                      )
                  : undefined
              }
            />
          ))}
        </VStack>
      </Collapse>
    </VStack>
  );
};

const PeriodicIndicators = ({
  categories = [],
  selectedIndicators,
  setSelectedIndicators,
  reportingYear,
}: {
  categories?: Array<PaiCategory>;
  selectedIndicators: IndicatorConfiguration[];
  setSelectedIndicators: Dispatch<SetStateAction<IndicatorConfiguration[]>>;
  reportingYear: string;
} & BoxProps) => {
  const indicators = useMemo(() => {
    return keyBy(
      categories.flatMap((cat) => cat.indicators),
      'reference'
    );
  }, [categories]);

  const overallCompaniesOption: DataCollection = useMemo(() => {
    const allCompanies = selectedIndicators.every((i) => i.isForAllCompanies === true);
    const isSameCustomCompanies =
      selectedIndicators?.[0]?.selectedPortfolioCompanyIds?.length > 0 &&
      selectedIndicators?.every(
        (i) =>
          i.selectedPortfolioCompanyIds?.every((id) =>
            selectedIndicators[0].selectedPortfolioCompanyIds.includes(id)
          ) &&
          selectedIndicators[0].selectedPortfolioCompanyIds?.every((id) =>
            i.selectedPortfolioCompanyIds.includes(id)
          )
      );
    if (allCompanies) {
      return { option: DataCollectionOption.allCompanies, selectedPortfolioCompanyIds: [] };
    }
    if (isSameCustomCompanies) {
      return {
        option: DataCollectionOption.selectCompanies,
        selectedPortfolioCompanyIds: selectedIndicators[0]?.selectedPortfolioCompanyIds,
      };
    }
    return { option: DataCollectionOption.decidePerIndicator, selectedPortfolioCompanyIds: [] };
  }, [selectedIndicators]);

  const overallFrequencyOption = useMemo(() => {
    const sameFrequency = selectedIndicators?.every((i) =>
      isEqual(i?.periods, selectedIndicators?.[0]?.periods)
    );
    if (sameFrequency) {
      return selectedIndicators?.[0]?.periods?.year === true
        ? ReportingFrequency.yearly
        : ReportingFrequency.quarterly;
    }
    return ReportingFrequency.customPerIndicator;
  }, [selectedIndicators]);

  const { getPortfolio } = usePortfolios();
  const { portfolioId } = useParams();
  const portfolio = getPortfolio(portfolioId ?? '', reportingYear);
  const portfolioCompanies = useMemo(
    () => uniqBy(portfolio?.allCompaniesInPortfolio ?? [], 'company.id'),
    [portfolio]
  );

  const aggregateFrequency = (
    freq: Omit<ReportingFrequency, ReportingFrequency.customPerIndicator>
  ): IndicatorConfiguration['periods'] => {
    if (freq === ReportingFrequency.quarterly)
      return {
        q1: true,
        q2: true,
        q3: true,
        q4: true,
        year: false,
      };
    return {
      q1: false,
      q2: false,
      q3: false,
      q4: false,
      year: true,
    };
  };

  const handleOverAllCompaniesChange = (option: DataCollection) => {
    if (option.option === DataCollectionOption.allCompanies) {
      setSelectedIndicators((prev) =>
        prev.map((i) => ({ ...i, isForAllCompanies: true, selectedPortfolioCompanyIds: [] }))
      );
    } else if (option.option === DataCollectionOption.selectCompanies) {
      setSelectedIndicators((prev) =>
        prev.map((i) => ({
          ...i,
          isForAllCompanies: false,
          selectedPortfolioCompanyIds: option.selectedPortfolioCompanyIds ?? [],
        }))
      );
    } else {
      setSelectedIndicators((prev) =>
        prev.map((i) => ({
          ...i,
          isForAllCompanies: false,
          selectedPortfolioCompanyIds: [],
        }))
      );
    }
  };

  const handleFrequencyChange = (freq: ReportingFrequency) => {
    if (freq === ReportingFrequency.customPerIndicator) {
      return;
    }
    setSelectedIndicators((prev) =>
      prev.map((i) => ({
        ...i,
        periods: aggregateFrequency(freq),
      }))
    );
  };

  const handleIndicatorOptionChange = (
    option: DataCollection,
    indicator: IndicatorConfiguration
  ) => {
    const currentOption: DataCollection = {
      option: indicator.isForAllCompanies
        ? DataCollectionOption.allCompanies
        : DataCollectionOption.selectCompanies,
      selectedPortfolioCompanyIds: indicator.selectedPortfolioCompanyIds ?? [],
    };
    const isAllCompanies = option.option === DataCollectionOption.allCompanies;
    if (
      option.option !== currentOption.option ||
      !isEqual(option.selectedPortfolioCompanyIds, currentOption.selectedPortfolioCompanyIds)
    ) {
      setSelectedIndicators((prev) =>
        prev.map((i) =>
          i.paiTableIndicatorReference === indicator.paiTableIndicatorReference
            ? {
                ...i,
                isForAllCompanies: isAllCompanies,
                selectedPortfolioCompanyIds: isAllCompanies
                  ? []
                  : option.selectedPortfolioCompanyIds ?? [],
              }
            : i
        )
      );
    }
  };

  const handleIndicatorFrequencyChange = (
    freq: ReportingFrequency,
    indicator: IndicatorConfiguration
  ) => {
    const curFreq =
      indicator.periods?.year === true ? ReportingFrequency.yearly : ReportingFrequency.quarterly;
    if (freq !== curFreq) {
      setSelectedIndicators((prev) =>
        prev.map((i) =>
          i.paiTableIndicatorReference === indicator.paiTableIndicatorReference
            ? {
                ...i,
                periods: aggregateFrequency(freq),
              }
            : i
        )
      );
    }
  };

  return (
    <VStack spacing="16px">
      <HStack width="100%" justifyContent="space-between" mt="24px">
        <Typography width="50%" variant="bodyStrong">
          Indicator
        </Typography>
        <HStack width="50%" justifyContent="flex-end" pr="32px">
          <Typography width="200px" variant="bodyStrong">
            Collect data from
          </Typography>
          <Typography width="200px" variant="bodyStrong">
            Frequency
          </Typography>
        </HStack>
      </HStack>
      <HStack width="100%" justifyContent="space-between">
        <Typography width="50%" variant="body">
          All indicators
        </Typography>
        <HStack width="50%" spacing="8px" justifyContent="flex-end" pr="32px">
          <Tooltip
            label="There are no companies added to the portfolio yet. Please, add at least one company to be able to change this setting"
            isDisabled={portfolioCompanies.length > 0}
          >
            <Box width="200px">
              <PaiDataCollectionSelector
                selectedOption={overallCompaniesOption}
                setSelectedOption={handleOverAllCompaniesChange}
                portfolioCompanies={portfolioCompanies}
              />
            </Box>
          </Tooltip>
          <Box width="200px">
            <PaiDataCollectionFrequencySelector
              selectedFrequency={overallFrequencyOption}
              setSelectedFrequency={handleFrequencyChange}
            />
          </Box>
        </HStack>
      </HStack>

      <Divider border="1px" borderColor="border.decorative" />
      <VStack width="100%" spacing="16px" height="400px" overflowY="auto">
        {selectedIndicators.map((ind) => (
          <HStack width="100%" justifyContent="space-between">
            <Typography width="50%" variant="body">
              {indicators[ind?.paiTableIndicatorReference]?.title}
            </Typography>
            <HStack spacing="16px" justifyContent="flex-end">
              <HStack spacing="8px">
                <Tooltip
                  closeOnScroll={true}
                  label="There are no companies added to the portfolio yet. Please, add at least one company to be able to change this setting"
                  isDisabled={portfolioCompanies.length > 0}
                >
                  <Box width="182px">
                    <IndicatorDataCollectionSelector
                      selectedOption={{
                        option: ind.isForAllCompanies
                          ? DataCollectionOption.allCompanies
                          : DataCollectionOption.selectCompanies,
                        selectedPortfolioCompanyIds: ind.selectedPortfolioCompanyIds,
                      }}
                      setSelectedOption={(option) => handleIndicatorOptionChange(option, ind)}
                      portfolioCompanies={portfolioCompanies}
                    />
                  </Box>
                </Tooltip>
                <Box width="182px">
                  <IndicatorFrequencySelector
                    selectedFrequency={
                      ind.periods.year ? ReportingFrequency.yearly : ReportingFrequency.quarterly
                    }
                    setSelectedFrequency={(freq) => handleIndicatorFrequencyChange(freq, ind)}
                    overallFrequencyOption={overallFrequencyOption}
                  />
                </Box>
              </HStack>
              <DeleteIcon
                cursor="pointer"
                boxSize="16px"
                onClick={() => {
                  setSelectedIndicators((prev) =>
                    prev.filter(
                      (i) => i.paiTableIndicatorReference !== ind.paiTableIndicatorReference
                    )
                  );
                }}
              />
            </HStack>
          </HStack>
        ))}
      </VStack>
    </VStack>
  );
};

const IndicatorList = ({
  categories = [],
  selectable = false,
  selectedIndicators,
  setSelectedIndicators,
  ...props
}: {
  categories?: Array<PaiCategory>;
  selectable?: boolean;
  selectedIndicators: IndicatorConfiguration[];
  setSelectedIndicators: Dispatch<SetStateAction<IndicatorConfiguration[]>>;
} & BoxProps) => {
  const [query, setQuery] = useState<string>('');

  const allSelected = useMemo(
    () =>
      categories
        .flatMap((category) => category.indicators)
        .every((indicator) =>
          selectedIndicators.map((i) => i.paiTableIndicatorReference).includes(indicator.reference)
        ),
    [categories, selectedIndicators]
  );

  return (
    <VStack spacing="16px" width="628px" m="0 auto" {...props}>
      <HStack width="100%" spacing="8px">
        <SearchInput search={query} setSearch={setQuery} width="100%" placeholder={'Filter'} />
        {selectable && (
          <Button
            onClick={() => {
              if (allSelected) {
                setSelectedIndicators((prev) =>
                  prev.filter(
                    (i) =>
                      !categories
                        .flatMap((category) => category.indicators)
                        .map((indicator) => indicator.reference)
                        .includes(i.paiTableIndicatorReference)
                  )
                );
              } else {
                setSelectedIndicators(
                  uniqBy(
                    [
                      ...selectedIndicators,
                      ...categories.flatMap((category) =>
                        category.indicators
                          .filter((i) => !i.isHidden)
                          .map((i) => {
                            return {
                              paiTableIndicatorReference: i.reference,
                              periods: { year: true },
                              isForAllCompanies: true,
                              selectedPortfolioCompanyIds: [],
                            };
                          })
                      ),
                    ],
                    (i) => i.paiTableIndicatorReference
                  ) as IndicatorConfiguration[]
                );
              }
            }}
          >
            {!allSelected ? 'Select all' : 'Deselect all'}
          </Button>
        )}
      </HStack>
      <VStack width="100%" alignItems="stretch" spacing="16px" height="470px" overflowY="auto">
        {categories.map((category) => (
          <CategoryListItem
            key={category.reference}
            query={query}
            category={category}
            selectable={selectable}
            selectedIndicators={selectedIndicators}
            setSelectedIndicators={setSelectedIndicators}
          />
        ))}
      </VStack>
    </VStack>
  );
};

const PaiTableListItem = ({
  paiTable,
  onClick,
  isSelected,
}: {
  paiTable: PaiTable;
  isSelected: boolean;
  onClick: (paiTableReference: string) => void;
}) => {
  const filteredIndicatorCount = useMemo(() => {
    return paiTable.categories
      .flatMap((c) => c.indicators)
      .filter((indicator) => !indicator.isHidden).length;
  }, [paiTable.categories]);

  return (
    <HStack
      onClick={() => onClick(paiTable.reference)}
      _hover={{
        bg: isSelected ? 'bg.selected.hover' : 'bg.hover',
        cursor: 'pointer',
      }}
      borderBottom="solid 1px"
      borderColor="border.decorative"
      bg={isSelected ? 'bg.selected' : 'bg.default'}
      paddingY="12px"
      paddingX="16px"
      width="100%"
      justifyContent="stretch"
    >
      <VStack alignItems="stretch" width="100%" spacing="2px">
        <HStack justifyContent="space-between" width="100%">
          <Typography variant="bodyStrong">{paiTable.title}</Typography>
          <Typography variant="body" color="text.hint">
            {filteredIndicatorCount}
          </Typography>
        </HStack>
        <Typography variant="detail" color="text.muted">
          {paiTable.subtitle}
        </Typography>
      </VStack>
    </HStack>
  );
};

const NoCustomQuestionsAccess = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <>
      <HStack padding="16px">
        <VStack
          bg="linear-gradient(74.73deg, #EBEEFF 0%, #E4F4FF 97.75%)"
          padding="16px"
          borderRadius="8px"
          justifyContent="flex-start"
          alignItems="flex-start"
          spacing="16px"
        >
          <VStack spacing="4px" alignItems="flex-start">
            <Typography variant="h4">More sustainability indicators </Typography>
            <Typography variant="detail">
              Introducing question bank with additional sustainability indicators. This module can
              be useful for investors who want to track metrics not covered by the taxonomy or PAI
              indicators.
            </Typography>
          </VStack>
          <Button variant="secondary" onClick={() => onOpen()}>
            Learn more
          </Button>
        </VStack>
      </HStack>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        size="md"
        title="More sustainability indicators"
        hasFooter={false}
      >
        <VStack spacing="32px" width="100%">
          <Typography variant="body">
            The bank of other indicators is structured so it is easy for you as an investor to find
            the right metrics to track for each portfolio. Please reach out in the chat or in an
            email to one of our customer success managers if you want to know more about the module
            and how you can tailor the indicators towards your sustainability objectives.{' '}
          </Typography>
        </VStack>
      </Modal>
    </>
  );
};
const IndicatorBrowser = ({
  paiTables,
  selectedIndicators = [],
  setSelectedIndicators,
}: {
  paiTables: Array<PaiTable>;
  selectedIndicators: IndicatorConfiguration[];
  setSelectedIndicators: Dispatch<SetStateAction<IndicatorConfiguration[]>>;
}) => {
  const { company } = useCurrentCompany();
  const [query, setQuery] = useState<string>('');
  const [selectedPaiTableReference, setSelectedPaiTableReference] = useState<string>(
    paiTables?.[0]?.reference || ''
  );

  const { paiOnlyTables, additionalIndicators } = useMemo(() => {
    return {
      paiOnlyTables: paiTables.filter((pt) => !pt.isCustom),
      additionalIndicators: paiTables.filter((pt) => pt.isCustom),
    };
  }, paiTables);

  const selectedPaiTable = useMemo(
    () => paiTables.find((pt) => pt.reference === selectedPaiTableReference),
    [selectedPaiTableReference]
  );

  return (
    <HStack
      height="100%"
      width="100%"
      justifyContent="stretch"
      alignItems="stretch"
      spacing="0px"
      padding="0px"
    >
      <VStack
        width="35%"
        height="100%"
        flexDir="column"
        alignItems="flex-start"
        spacing="0px"
        marginLeft="-16px"
      >
        <VStack padding="16px" width="100%">
          <SearchInput search={query} setSearch={setQuery} width="100%" placeholder={'Filter'} />
        </VStack>
        <VStack alignItems="flex-start" spacing="0px">
          <HStack
            justifyContent="stretch"
            paddingTop="8px"
            paddingLeft="16px"
            paddingBottom="8px"
            borderBottom="solid 1px"
            borderColor="border.decorative"
            width="100%"
          >
            <Typography variant="overline" color="text.muted">
              PAI indicators
            </Typography>
          </HStack>
          {paiOnlyTables
            .filter((p) => p.title.includes(query) || p.subtitle?.includes(query))
            .map((paiTable) => (
              <PaiTableListItem
                key={paiTable.reference}
                paiTable={paiTable}
                onClick={setSelectedPaiTableReference}
                isSelected={selectedPaiTableReference === paiTable.reference}
              />
            ))}
        </VStack>
        <VStack alignItems="flex-start" paddingLeft="-16px" spacing="0px">
          <HStack
            justifyContent="stretch"
            width="100%"
            paddingTop="32px"
            paddingLeft="16px"
            paddingBottom="8px"
            borderBottom="solid 1px"
            borderColor="border.decorative"
          >
            <Typography textAlign="start" variant="overline" color="text.muted">
              Other sustainability indicators
            </Typography>
          </HStack>

          {company?.hasCustomQuestionsAccess ? (
            <>
              {additionalIndicators
                .filter((p) => p.title.includes(query) || p.subtitle?.includes(query))
                .map((paiTable) => (
                  <PaiTableListItem
                    key={paiTable.reference}
                    paiTable={paiTable}
                    onClick={setSelectedPaiTableReference}
                    isSelected={selectedPaiTableReference === paiTable.reference}
                  />
                ))}
            </>
          ) : (
            <NoCustomQuestionsAccess />
          )}
        </VStack>
      </VStack>
      <IndicatorList
        width="70%"
        paddingY="16px"
        paddingLeft="16px"
        marginLeft="16px"
        borderLeft="solid 1px"
        borderColor="border.decorative"
        categories={selectedPaiTable?.categories}
        selectedIndicators={selectedIndicators}
        setSelectedIndicators={setSelectedIndicators}
        selectable
      />
    </HStack>
  );
};

export const PaiIndicatorsModal = ({
  isOpen,
  onClose,
  selected = [],
  setSelected,
  reportingYear,
}: {
  isOpen: boolean;
  onClose: () => void;
  selected: IndicatorConfiguration[];
  setSelected: Dispatch<SetStateAction<IndicatorConfiguration[]>>;
  reportingYear: string;
}) => {
  const { data, loading } = usePaiTablesQuery();

  const paiTables = useMemo(() => {
    return data?.tables ?? [];
  }, [data]);

  const allCategories = useMemo(() => paiTables.flatMap((pt) => pt.categories), [paiTables]);

  const [activeTabIndex, setActiveTabIndex] = useState<number>(0);

  const handleConfirm = () => {
    if (activeTabIndex === 0) {
      setActiveTabIndex(1);
    } else {
      setSelected(selected);
      onClose();
    }
  };
  const handleCancel = () => {
    if (activeTabIndex === 0) {
      onClose();
    } else {
      setActiveTabIndex(0);
    }
  };

  return (
    <Modal
      size="lg"
      isOpen={isOpen}
      onClose={onClose}
      title="Select indicators"
      onCancel={handleCancel}
      onConfirm={handleConfirm}
      confirmText={activeTabIndex === 0 ? 'Next step' : 'Confirm'}
      cancelText={activeTabIndex === 0 ? 'Cancel' : 'Prev. step'}
      confirmButtonProps={{ isDisabled: selected.length === 0 }}
    >
      <Skeleton isLoaded={!loading} width="100%">
        <Tabs
          defaultIndex={0}
          padList="0px"
          items={[
            {
              id: 'browse-indicators',
              title: 'Browse indicators',
              content: (
                <IndicatorBrowser
                  paiTables={paiTables}
                  selectedIndicators={selected}
                  setSelectedIndicators={setSelected}
                />
              ),
            },
            {
              id: 'selected-periodic-indicators',
              title: `Set up selected (${selected.length})`,
              content: (
                <PeriodicIndicators
                  categories={allCategories}
                  selectedIndicators={selected}
                  setSelectedIndicators={setSelected}
                  reportingYear={reportingYear}
                />
              ),
            },
          ]}
          currentTab={activeTabIndex === 0 ? 'browse-indicators' : 'selected-periodic-indicators'}
          onChange={(id) => setActiveTabIndex(id === 'browse-indicators' ? 0 : 1)}
        />
      </Skeleton>
      {selected.length < 1 && (
        <Box mt="16px">
          <Alert
            status="warning"
            title="At least one indicator is required to be selected"
            closable={false}
          />
        </Box>
      )}
    </Modal>
  );
};
