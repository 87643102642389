import { SkeletonCircle } from '@chakra-ui/react';
import { UserAvatar } from 'Organisms';
import { CompanyIcon } from 'Tokens/Icons/Data';
import { CircleIconWrapper } from 'Tokens/Icons/IconWrapper';
import { isArray, isEqual, isObject } from 'lodash';
import { ShortUser } from 'models';

function isDataDifferent(oldData: any, newData: any) {
  return !isEqual(oldData, newData) && (isObject(oldData) || isArray(oldData));
}

export const getDifferentData = (oldData: any, newData: any, columnsToDisplay: string[]) => {
  const oldDataToDisplay: any = {};
  const newDataToDisplay: any = {};

  columnsToDisplay.forEach((column) => {
    const oldColumnData = oldData?.[column];
    const newColumnData = newData?.[column];

    if (!oldData) {
      oldDataToDisplay[column] = 'Empty';
      newDataToDisplay[column] = newColumnData;
    } else if (isDataDifferent(oldColumnData, newColumnData)) {
      oldDataToDisplay[column] = oldColumnData;
      newDataToDisplay[column] = newColumnData;
    }
  });

  return { oldDataToDisplay, newDataToDisplay };
};

export const getAvatarComponent = (user?: ShortUser | null) => {
  if (user) {
    return (
      <SkeletonCircle isLoaded boxSize="28px">
        <UserAvatar size="sm" user={user} />
      </SkeletonCircle>
    );
  } else {
    return <CircleIconWrapper icon={CompanyIcon} size="28px" color="white" bg="bg.accent" />;
  }
};

export const formatTimestamp = (timestamp: string) => {
  return new Date(timestamp).toLocaleString('en-GB', {
    day: '2-digit',
    month: '2-digit',
    year: '2-digit',
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  });
};

export const auditedColumns: {
  [key: string]: string[];
} = {
  TaxonomyPortfolioReport: ['data', 'paiCompanyReportId', 'investeeMetricReference'],
  Subsidiary: ['contactPersonName', 'companyId', 'ownerGroupAssessmentId'],
  ReportingGroup: ['name', 'parentId', 'companyAssessmentId'],
  Portfolio: ['name', 'totalAmount', 'description'],
  PaiReportIndicatorPortfolioCompany: ['portfolioCompanyId', 'paiReportIndicatorId'],
  PaiReportIndicator: ['isForAllCompanies', 'periods'],
  PaiPortfolioReport: ['year', 'sendOutDates', 'dueDates', 'contactPersonId'],
  PaiCompanyReport: ['year', 'period', 'contactPersonId'],
  PaiAnswers: ['data', 'paiCompanyReportId'],
  Note: ['isAIGenerated', 'body', 'noteHistoryId'],
  GroupAssessment: ['name', 'period', 'uploadedAt', 'companyId'],
  Financials: ['revenue', 'capex', 'opex', 'isEstimate', 'adaptationCapex', 'adaptationOpex', 'id'],
  DocumentationFile: ['url', 'title', 'description', 'creationDate'],
  CompanyUser: ['role', 'companyId'],
  CompanyInvitation: ['userEmail', 'status', 'companyId', 'createdById'],
  CompanyAssessment: ['endDate', 'startDate', 'companyId', 'isLocked', 'deletedAt', 'createdAt'],
  Comment: ['data', 'authorId', 'threadId', 'deletedAt'],
  Attachment: ['attachmentBoxId', 'field', 'createdAt', 'deletedAt'],
  Answer: ['createdAt', 'updatedAt', 'questionId', 'reportId', 'data'],
  ActivityReport: [
    'id',
    'activityRef',
    'createdAt',
    'deletedAt',
    'activityVersionNumber',
    'activityVersionConfig',
  ],
  BusinessUnit: ['name', 'description', 'contactPersonId', 'deletedAt', 'labels'],
  PortfolioCompany: [
    'amount',
    'scores',
    'contactPersonName',
    'marketValue',
    'eligibilityStatus',
    'year',
    'valueOfInvestments',
  ],
  Company: [
    'name',
    'settings',
    'currency',
    'isPortfolioOwner',
    'isGroupOwner',
    'hasCommunityAccess',
    'createdById',
  ],
  CachedResult: [
    'activityRef',
    'score',
    'progress',
    'financials',
    'activityTag',
    'activityVersionNumber',
    'status',
  ],
};
