import { Box, HStack, StackProps, useDisclosure, VStack } from '@chakra-ui/react';
import { User, useUserCompaniesQuery, CompanyDetails, UserCompaniesDocument_ } from 'models';
import { useSwitchCompany } from './CompanySwitcher.hooks';
import { useCurrentCompany, useToast } from 'utils/hooks';
import { useTranslation } from 'utils/translation';
import { CompanyLogo, CompanyPresentation } from 'Organisms';
import { Alert, Button, EmptyState, Input, VisibleIf } from 'Atoms';
import { Typography } from 'Tokens';
import { Drawer } from 'Molecules';
import { CompanyRegisterModal } from 'Features/CompanyForm';
import { useMemo, useState } from 'react';
import { useUserData } from '@nhost/react';
import {
  hasSalesOrSupportRole,
  hasSupportRole,
  isCelsiaSupport,
  isUserTwoWeeksOld,
} from 'utils/users';
import { RefreshIcon } from 'Tokens/Icons/Function';
import { CompanyIcon } from 'Tokens/Icons/Data';
import { ChevronDownIcon } from 'Tokens/Icons/Direction';
import { CircleIconWrapper } from 'Tokens/Icons/IconWrapper';
import { hotjar } from 'react-hotjar';
import { nhost } from 'utils/nhost';
import { useApolloClient } from '@apollo/client';

interface CompanySwitcherProps extends StackProps {
  user: User;
}

const CompanyButton = ({
  company,
  select,
}: {
  company: CompanyDetails;
  select: (c: CompanyDetails) => void;
}) => {
  return (
    <Button
      _hover={{ bg: 'bg.hover' }}
      _focus={{ bg: 'bg.hover' }}
      onClick={() => select(company)}
      tabIndex={0}
      textTransform="none"
      justifyContent="flex-start"
      variant="ghost"
      borderRadius="lg"
      width="100%"
      height="auto"
      max-height="40px"
      p="12px 8px"
    >
      <CompanyPresentation company={company} />
    </Button>
  );
};
const CompanySwitcher = ({ user, ...rest }: CompanySwitcherProps) => {
  const { company: currentCompany } = useCurrentCompany();
  const [search, setSearch] = useState('');
  const currentUser = useUserData();
  const { data: userCompanies } = useUserCompaniesQuery({
    variables: {
      id: user.id,
    },
  });
  const switchCompany = useSwitchCompany();
  const toast = useToast();
  const client = useApolloClient();

  const { isOpen, onOpen, onClose } = useDisclosure();
  const { onOpen: onCreateOpen, isOpen: isCreateOpen, onClose: onCreateClose } = useDisclosure();

  const filteredCompanies = useMemo(() => {
    return userCompanies?.data?.companies
      ?.filter((c) => c.company.name.toLowerCase().includes(search.toLowerCase()))
      ?.sort((a, b) => a.company.name.localeCompare(b.company.name));
  }, [userCompanies, search]);

  const handleChange = (company: CompanyDetails) => {
    switchCompany(company.id);
    hotjar.identify(null, {
      isCelsia: isCelsiaSupport(currentUser?.email),
      isReal: company?.isReal,
      isTwoWeeksOld: isUserTwoWeeksOld(user?.createdAt),
      environment: import.meta.env.REACT_APP_NHOST_SUBDOMAIN,
    });
    onClose();
  };
  const { t } = useTranslation('common');

  const onCreateEsrsDemoCompany = async () => {
    try {
      const res = await nhost.functions.call<{
        company: CompanyDetails;
        esrsAssessment: any;
      }>('esrs/generate-demo-company', {
        userId: user?.id,
      });
      if (res.error) {
        console.error(res.error);
        throw new Error('Error creating demo company: ' + JSON.stringify(res.error.message));
      }
      if (!res.res?.data?.company) {
        throw new Error('Error creating demo company');
      }
      toast({
        text: 'Demo company created.',
      });
      await nhost.auth.refreshSession();
      await client.refetchQueries({ include: [UserCompaniesDocument_] });
    } catch (error) {
      console.error(error);
      toast({
        variant: 'danger',
        text: 'Unable to create demo company.',
      });
    }
  };

  if (!currentCompany) return null;
  return (
    <HStack
      {...rest}
      as={Button}
      onClick={onOpen}
      gap="0px"
      maxW="240px"
      variant="ghost"
      size="sm"
      alignVertically
      rightIcon={<ChevronDownIcon />}
    >
      {currentCompany.logoUrl ? (
        <CompanyLogo url={currentCompany.logoUrl} size="20px" />
      ) : (
        <CircleIconWrapper icon={CompanyIcon} size="20px" color="bg.default" />
      )}
      <Typography
        title={currentCompany.name}
        whiteSpace="nowrap"
        variant="bodyStrong"
        overflow="hidden"
        textOverflow="ellipsis"
        pl="8px"
      >
        {currentCompany.name}
      </Typography>

      <Drawer header={t('common:companyDrawer.header')} isOpen={isOpen} onClose={onClose} size="md">
        <VStack width="100%" paddingTop="16px" h="100%">
          <VisibleIf
            condition={
              !!(
                hasSalesOrSupportRole(currentUser?.roles) &&
                filteredCompanies?.length &&
                filteredCompanies.length >= 100
              )
            }
          >
            <Box width="100%">
              <Alert
                status="warning"
                closable={false}
                title={`${filteredCompanies?.length}/140 companies`}
              ></Alert>
            </Box>
          </VisibleIf>
          <Input
            placeholder={t('common:companyDrawer.search')}
            width="100%"
            onChange={(e) => setSearch(e.currentTarget.value)}
            value={search}
          />
          {filteredCompanies?.length ? (
            filteredCompanies.map(({ company }) => (
              <CompanyButton key={company.id} company={company} select={handleChange} />
            ))
          ) : (
            <Box w="100%" py="16px">
              <EmptyState
                title={t('common:search.filter.emptyTitle')}
                description={t('common:search.filter.emptyDescription')}
                callToAction={{
                  text: t('common:search.filter.emptyBtn'),
                  variant: 'secondary',
                  onClick: () => {
                    setSearch('');
                  },
                  leftIcon: <RefreshIcon color="inherit" />,
                }}
                component={true}
              />
            </Box>
          )}

          {hasSupportRole(currentUser?.roles) && (
            <Button width="full" mt="24px" onClick={onCreateOpen}>
              {t('common:companyDrawer.createCompany')}
            </Button>
          )}

          {hasSalesOrSupportRole(currentUser?.roles) && (
            <Button variant="ghost" width="full" onClick={onCreateEsrsDemoCompany}>
              Create ESRS demo company
            </Button>
          )}
        </VStack>
      </Drawer>
      <CompanyRegisterModal
        isOpen={isCreateOpen}
        onClose={() => {
          onCreateClose();
          onClose();
        }}
      />
    </HStack>
  );
};

export default CompanySwitcher;
