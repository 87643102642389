import { useUserId } from '@nhost/react';
import differenceInDays from 'date-fns/differenceInDays';
import { useUserProvidersQuery } from 'models';
import { useMemo } from 'react';

export const hasSupportRole = (roles: string[] | undefined): boolean => {
  if (!roles) return false;
  return roles.includes('support');
};

export const hasSalesRole = (roles: string[] | undefined): boolean => {
  if (!roles) return false;
  return roles.includes('sales');
};

export const hasSalesOrSupportRole = (roles: string[] | undefined): boolean => {
  if (!roles) return false;
  return hasSupportRole(roles) || hasSalesRole(roles);
};

export const isCelsiaSupport = (email?: string | null): boolean => {
  if (!email) return false;
  return email.includes('@celsia');
};

export const isIssSales = (email?: string | null): boolean => {
  if (!email) return false;
  return email.includes('@iss-corporate') || email.includes('@iss-stoxx');
};

export const isServiceAccount = (email?: string | null): boolean => {
  if (!email) return false;
  return email.includes('@serviceaccount.celsia');
};

export const isUserTwoWeeksOld = (createdAt?: string | null): boolean => {
  if (!createdAt) return false;
  return differenceInDays(new Date(), new Date(createdAt)) > 13;
};

const SSO_PROVIDER_ID = 'workos';
export const isLoggedInWithSSO = () => {
  const userId = useUserId();
  const { data: userProviders, ...rest } = useUserProvidersQuery({
    variables: { userId },
    skip: !userId,
  });

  const isSSO = useMemo(
    () => !!userProviders?.providers.find((p) => p.providerId === SSO_PROVIDER_ID),
    [userProviders]
  );

  return { ...rest, data: isSSO };
};

export const PENDING_USER_TAG = '--';
export const CELSIA_SUPPORT_TAG = 'Celsia Support';
export const SERVICE_ACCCOUNT_TAG = 'Service Account';
export const CELSIA_CONTACT_EMAIL = 'contact@celsia.io';
