import { ContentLayout, Loader } from 'Molecules';
import { useParams } from 'react-router-dom';
import {
  GetMaterialStandardDrStatusesDocument_,
  useEsrsAssessmentQuery,
  useGetBuDisclosureRequirementMetricsQuery,
  useGetMaterialStandardDrStatusesQuery,
} from 'models';
import { HStack, VStack } from '@chakra-ui/react';
import { useMaterialStandardId } from 'containers/Esrs/EsrsAssessment.hooks';
import { useEffect, useMemo } from 'react';
import { useGetDRGroupData } from '../DisclosureRequirements/DisclosureRequirements.hooks';
import { DisclosureRequirementTable } from '../DisclosureRequirements/StandardPage/StandardPageComponents/DisclosureRequirementsTable';
import { Typography } from 'Tokens';
import { BottomNavigation } from 'Molecules/BottomNavigation';
import { useGetMaterialityAssessment } from '../MaterialityAssessment/MaterialityAssessment.hooks';
import { getStandardConfigLink } from 'utils/links';
import {
  ConfigurationBanner,
  DataCollectionSetupStatusTag,
  getMaterialStandards,
} from './DataCollectionSetup';
import { useApolloClient } from '@apollo/client';

export const StandardConfigPage = ({ isOnboarding }: { isOnboarding?: boolean }) => {
  const { standard, sortedRequirements, loading } = useGetDRGroupData();
  const { esrsAssessmentId, reportingUnitId } = useParams();
  const {
    companyAssessmentId: companyStandardId,
    parentAssessmentId: parentStandardId,
    loading: materialStandardIdLoading,
  } = useMaterialStandardId(standard?.reference ?? '', esrsAssessmentId ?? '');

  const { materialityAssessments } = useGetMaterialityAssessment(esrsAssessmentId);

  const materialStandards = useMemo(() => {
    return getMaterialStandards(materialityAssessments);
  }, [materialityAssessments]);

  const client = useApolloClient();

  const isBusinessUnit = useMemo(() => !!reportingUnitId, [reportingUnitId]);

  const { data: esrsAssessmentData } = useEsrsAssessmentQuery({
    variables: { esrsAssessmentId },
    skip: !esrsAssessmentId,
  });
  const { data: buData } = useGetBuDisclosureRequirementMetricsQuery({
    variables: {
      standardRef: standard?.reference ?? '',
      companyStandardId,
      parentStandardId: parentStandardId || companyStandardId,
    },
    skip: !isBusinessUnit,
  });
  const { data: drStatusData } = useGetMaterialStandardDrStatusesQuery({
    variables: {
      materialStandardId: companyStandardId,
    },
    skip: !companyStandardId,
  });

  const drStatuses = useMemo(() => {
    if (isBusinessUnit) {
      const requirements = [...(buData?.requirements ?? [])];
      return (
        drStatusData?.DisclosureRequirementStatus.filter((dr) =>
          requirements.map((r) => r.reference).includes(dr.disclosureRequirementRef)
        ) ?? []
      );
    } else {
      return (
        drStatusData?.DisclosureRequirementStatus.filter((dr) =>
          sortedRequirements
            .flatMap((sr) => sr.map((r) => r.reference))
            .includes(dr.disclosureRequirementRef)
        ) ?? []
      );
    }
  }, [drStatusData, sortedRequirements, buData, isBusinessUnit]);

  const buDisclosureRequirements = useMemo(
    () =>
      [...(buData?.requirements ?? [])]
        .sort((a, b) => {
          if (a.order !== b.order) {
            return a.order - b.order;
          }
          return a.reference.localeCompare(b.reference);
        })
        // Add status
        .map((req) => ({
          ...req,
          isConfigured:
            drStatuses?.find((s) => s?.disclosureRequirementRef === req.reference)?.isConfigured ??
            false,
        })),
    [buData, drStatuses]
  );

  const currentIndex = useMemo(
    () => materialStandards?.findIndex((std) => std.standardRef === standard?.reference),
    [standard, materialStandards]
  );

  const requirementsCount = useMemo(() => {
    if (isBusinessUnit) return buDisclosureRequirements.length;

    return sortedRequirements.reduce((acc, curr) => {
      // Discard the requirement group title
      return acc + (curr.length - 1);
    }, 0);
  }, [isBusinessUnit, buDisclosureRequirements, sortedRequirements]);

  const configuredRequirementsCount = useMemo(() => {
    return drStatuses?.filter((s) => s?.isConfigured).length ?? 0;
  }, [drStatuses]);

  const [configuredRatio, configuredRatioString] = useMemo(() => {
    return [
      configuredRequirementsCount / requirementsCount,
      `${configuredRequirementsCount}/${requirementsCount}`,
    ];
  }, [requirementsCount, configuredRequirementsCount]);

  useEffect(() => {
    client.refetchQueries({ include: [GetMaterialStandardDrStatusesDocument_] });
  }, []);

  const [prevStandard, nextStandard] = useMemo(() => {
    const prevStd = materialStandards?.[currentIndex - 1];
    const nextStd = materialStandards?.[currentIndex + 1];

    return [
      prevStd
        ? {
            title: prevStd.standardName,
            reference: prevStd.standardRef,
            label: 'Previous Standard',
            link: getStandardConfigLink(
              {
                companyId: esrsAssessmentData?.esrsAssessment?.companyId,
                assessmentId: esrsAssessmentId ?? '',
                standardRef: prevStd?.standardRef,
              },
              isOnboarding
            ),
          }
        : undefined,
      nextStd
        ? {
            title: nextStd.standardName,
            reference: nextStd.standardRef,
            label: 'Next Standard',
            link: getStandardConfigLink(
              {
                companyId: esrsAssessmentData?.esrsAssessment?.companyId,
                assessmentId: esrsAssessmentId ?? '',
                standardRef: nextStd?.standardRef,
              },
              isOnboarding
            ),
          }
        : undefined,
    ];
  }, [materialStandards, currentIndex]);

  const isDataCollected = useMemo(
    () =>
      (drStatuses?.every((s) => s?.isConfigured) && drStatuses.length === requirementsCount) ??
      false,
    [drStatuses, requirementsCount]
  );

  if (loading || materialStandardIdLoading) return <Loader />;

  return (
    <ContentLayout
      backButton
      header={
        <HStack alignItems="center" justifyContent="space-between" pb="16px">
          <Typography variant="h1">{`${standard?.title} configuration`}</Typography>
          <DataCollectionSetupStatusTag isDataCollected={isDataCollected} />
        </HStack>
      }
      variant="narrow"
    >
      <VStack gap="56px" alignItems="stretch">
        <ConfigurationBanner
          type="disclosureRequirement"
          ratio={configuredRatio}
          ratioString={configuredRatioString}
        />
        <VStack alignItems="stretch" gap="28px">
          <HStack w="100%" justifyContent="space-between">
            <Typography variant="h2">Disclosure requirements to configure</Typography>
          </HStack>
          {isBusinessUnit ? (
            <DisclosureRequirementTable
              title={'Metrics'}
              requirements={buDisclosureRequirements}
              owner={esrsAssessmentData?.esrsAssessment?.projectLeader}
              isGroup={esrsAssessmentData?.esrsAssessment?.company.isGroupOwner ?? false}
              isConfig
            />
          ) : (
            sortedRequirements.map((requirements) => {
              const requirementsWithStatus = requirements.map((r) => ({
                ...r,
                isConfigured:
                  drStatuses?.find((s) => s?.disclosureRequirementRef === r.reference)
                    ?.isConfigured ?? false,
              }));
              return (
                <DisclosureRequirementTable
                  title={requirementsWithStatus[0].title}
                  requirements={requirementsWithStatus.slice(1)}
                  owner={esrsAssessmentData?.esrsAssessment?.projectLeader}
                  isGroup={esrsAssessmentData?.esrsAssessment?.company.isGroupOwner ?? false}
                  isConfig
                />
              );
            })
          )}
          <BottomNavigation prevElement={prevStandard} nextElement={nextStandard} />
        </VStack>
      </VStack>
    </ContentLayout>
  );
};
