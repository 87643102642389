export const INTEGRATIONS_CATEGORIES = ['ATS', 'Accounting', 'CRM', 'HR & Payroll'];

type IntegrationsList = {
  [category: string]: {
    title: string;
    src: string;
    size?: string;
  }[];
};

export const INTEGRATIONS_LIST: IntegrationsList = {
  ATS: [
    {
      title: 'MoreScope',
      src: '/assets/moreScope.svg',
      size: '24px',
    },
    {
      title: 'ApplicantStack',
      src: 'https://merge-api-production.s3.amazonaws.com/media/applicantstack_TH2d7xT.svg',
    },
    {
      title: 'Ashby',
      src: 'https://merge-api-production.s3.amazonaws.com/media/ashby_Lbx0U2f.svg',
    },
    {
      title: 'BambooHR',
      src: 'https://merge-api-production.s3.amazonaws.com/media/bamboohr_wc6G7kj.svg',
    },
    {
      title: 'Breezy',
      src: 'https://merge-api-production.s3.amazonaws.com/media/breezy_IvHZ2n7.svg',
    },
    {
      title: 'CATS',
      src: 'https://merge-api-production.s3.amazonaws.com/media/cats_light.svg',
    },
    {
      title: 'ClayHR',
      src: 'https://merge-api-production.s3.amazonaws.com/media/ClayHR_lightgrey.svg',
    },
    {
      title: 'Clockwork',
      src: 'https://merge-api-production.s3.amazonaws.com/media/clockwork_ojUDtrc.svg',
    },
    {
      title: 'Comeet',
      src: 'https://merge-api-production.s3.amazonaws.com/media/comeet_mmTt7s4.svg',
    },
    {
      title: 'Cornerstone TalentLink',
      src: 'https://merge-api-production.s3.amazonaws.com/media/cornerstone_lightgrey.svg',
    },
    {
      title: 'EngageATS',
      src: 'https://merge-api-production.s3.amazonaws.com/media/Engage_ATS_lightgrey.svg',
    },
    {
      title: 'Eploy',
      src: 'https://merge-api-production.s3.amazonaws.com/media/eploy_lightgray_W1r9r7c.svg',
    },
    {
      title: 'Fountain',
      src: 'https://merge-api-production.s3.amazonaws.com/media/fountain_light.svg',
    },
    {
      title: 'Freshteam',
      src: 'https://merge-api-production.s3.amazonaws.com/media/Freshteam_lightgray.svg',
    },
    {
      title: 'Greenhouse',
      src: 'https://merge-api-production.s3.amazonaws.com/media/greenhouse_x0C8JIC.svg',
    },
    {
      title: 'Greenhouse - Job Boards API',
      src: 'https://merge-api-production.s3.amazonaws.com/media/greenhouse_light.svg',
    },
    {
      title: 'HR Cloud',
      src: 'https://merge-api-production.s3.amazonaws.com/media/hr-cloud_AM88IM6.svg',
    },
    {
      title: 'Harbour ATS',
      src: 'https://merge-api-production.s3.amazonaws.com/media/Harbour_lightgrey_CBs0IxJ.svg',
    },
    {
      title: 'Homerun',
      src: 'https://merge-api-production.s3.amazonaws.com/media/homerun_yyznbXO.svg',
    },
    {
      title: 'Infinite BrassRing',
      src: 'https://merge-api-production.s3.amazonaws.com/media/infinite-2.svg',
    },
    {
      title: 'JazzHR',
      src: 'https://merge-api-production.s3.amazonaws.com/media/jazzhr_cytesU4.svg',
    },
    {
      title: 'JobAdder',
      src: 'https://merge-api-production.s3.amazonaws.com/media/jobadder_lightgrey.svg',
    },
    {
      title: 'Jobsoid',
      src: 'https://merge-api-production.s3.amazonaws.com/media/jobsoid_RZ3FnNP.svg',
    },
    {
      title: 'Jobvite',
      src: 'https://merge-api-production.s3.amazonaws.com/media/jobvite_light.svg',
    },
    {
      title: 'Lano',
      src: 'https://merge-api-production.s3.amazonaws.com/media/lano_8Du0k8Y.svg',
    },
    {
      title: 'Lever',
      src: 'https://merge-api-production.s3.amazonaws.com/media/lever_4BKOtjo.svg',
    },
    {
      title: 'Oracle Fusion - Recruiting Cloud',
      src: 'https://merge-api-production.s3.amazonaws.com/media/light_grey_oracle_recruiting.svg',
    },
    {
      title: 'Oracle Taleo',
      src: 'https://merge-api-production.s3.amazonaws.com/media/taleo_3jKOO04.svg',
    },
    {
      title: 'Personio Recruiting',
      src: 'https://merge-api-production.s3.amazonaws.com/media/4.svg',
    },
    {
      title: 'Polymer',
      src: 'https://merge-api-production.s3.amazonaws.com/media/Polymer_lightgrey.svg',
    },
    {
      title: 'Recruitee',
      src: 'https://merge-api-production.s3.amazonaws.com/media/recruitee_0taScR4.svg',
    },
    {
      title: 'Recruiterflow',
      src: 'https://merge-api-production.s3.amazonaws.com/media/recruiterflow_xxzB9Rc.svg',
    },
    {
      title: 'Recruitive',
      src: 'https://merge-api-production.s3.amazonaws.com/media/Recruitive_lightgrey.svg',
    },
    {
      title: 'SAP SuccessFactors',
      src: 'https://merge-api-production.s3.amazonaws.com/media/successfactors_MsDRdz1.svg',
    },
    {
      title: 'Sage HR',
      src: 'https://merge-api-production.s3.amazonaws.com/media/sage_ey5lMX1.svg',
    },
    {
      title: 'SmartRecruiters',
      src: 'https://merge-api-production.s3.amazonaws.com/media/smartrecruiters_gTCH8QY.svg',
    },
    {
      title: 'TalentLyft',
      src: 'https://merge-api-production.s3.amazonaws.com/media/talentlyft_ysvUCUB.svg',
    },
    {
      title: 'TalentReef',
      src: 'https://merge-api-production.s3.amazonaws.com/media/TalentReef_lightgrey.svg',
    },
    {
      title: 'Teamtailor',
      src: 'https://merge-api-production.s3.amazonaws.com/media/teamtailor_Sq7Wuxs.svg',
    },
    {
      title: 'UKG Pro Recruiting',
      src: 'https://merge-api-production.s3.amazonaws.com/media/ukg_lightgrey_s6zyuwv.svg',
    },
    {
      title: 'Workable',
      src: 'https://merge-api-production.s3.amazonaws.com/media/workable_0oLixkc.svg',
    },
    {
      title: 'Workday',
      src: 'https://merge-api-production.s3.amazonaws.com/media/workday_RD5lwK9.svg',
    },
    {
      title: 'iCIMS',
      src: 'https://merge-api-production.s3.amazonaws.com/media/ICIMS_lightgrey.svg',
    },
  ],
  Accounting: [
    {
      title: 'Clear Books',
      src: 'https://merge-api-production.s3.amazonaws.com/media/clearbooks-svg-light-grey.svg',
    },
    {
      title: 'FreeAgent',
      src: 'https://merge-api-production.s3.amazonaws.com/media/freeagent_accounting_lightgrey.svg',
    },
    {
      title: 'FreshBooks',
      src: 'https://merge-api-production.s3.amazonaws.com/media/freshbooks-logo-light-gray.svg',
    },
    {
      title: 'Microsoft Dynamics 365 Business Central',
      src: 'https://merge-api-production.s3.amazonaws.com/media/365businesscentral_lightgrey.svg',
    },
    {
      title: 'Moneybird',
      src: 'https://merge-api-production.s3.amazonaws.com/media/moneybird-2.svg',
    },
    {
      title: 'NetSuite',
      src: 'https://merge-api-production.s3.amazonaws.com/media/netsuite_lightgrey.svg',
    },
    {
      title: 'QuickBooks Online',
      src: 'https://merge-api-production.s3.amazonaws.com/media/Quickbooks_lightgrey.svg',
    },
    {
      title: 'Sage Business Cloud Accounting',
      src: 'https://merge-api-production.s3.amazonaws.com/media/sage-1.svg',
    },
    {
      title: 'Sage Intacct',
      src: 'https://merge-api-production.s3.amazonaws.com/media/intacct-light-gray.svg',
    },
    {
      title: 'Wave Financial',
      src: 'https://merge-api-production.s3.amazonaws.com/media/wave_lightgrey.svg',
    },
    {
      title: 'Workday',
      src: 'https://merge-api-production.s3.amazonaws.com/media/workday_RD5lwK9.svg',
    },
    {
      title: 'Xero',
      src: 'https://merge-api-production.s3.amazonaws.com/media/xero-2.svg',
    },
    {
      title: 'Zoho Books',
      src: 'https://merge-api-production.s3.amazonaws.com/media/zoho_light_AyhvvIi.svg',
    },
  ],
  CRM: [
    {
      title: 'Accelo',
      src: 'https://merge-api-production.s3.amazonaws.com/media/accelo_lightgrey.svg',
    },
    {
      title: 'ActiveCampaign',
      src: 'https://merge-api-production.s3.amazonaws.com/media/ActiveCampaign_lightgrey.svg',
    },
    {
      title: 'Affinity',
      src: 'https://merge-api-production.s3.amazonaws.com/media/affinity_lightgrey.svg',
    },
    {
      title: 'Capsule',
      src: 'https://merge-api-production.s3.amazonaws.com/media/Capsule_2.svg',
    },
    {
      title: 'Close',
      src: 'https://merge-api-production.s3.amazonaws.com/media/Close_2.svg',
    },
    {
      title: 'Copper',
      src: 'https://merge-api-production.s3.amazonaws.com/media/Copper_2.svg',
    },
    {
      title: 'HubSpot',
      src: 'https://merge-api-production.s3.amazonaws.com/media/Hubspot_lightgrey.svg',
    },
    {
      title: 'Insightly',
      src: 'https://merge-api-production.s3.amazonaws.com/media/Insightly_lightgrey.svg',
    },
    {
      title: 'Keap',
      src: 'https://merge-api-production.s3.amazonaws.com/media/keap_3_lightgrey.svg',
    },
    {
      title: 'Microsoft Dynamics 365 Sales',
      src: 'https://merge-api-production.s3.amazonaws.com/media/Dynamics_365_Sales_lightgrey.svg',
    },
    {
      title: 'Nutshell',
      src: 'https://merge-api-production.s3.amazonaws.com/media/Nutshell_lightgrey.svg',
    },
    {
      title: 'Pipedrive',
      src: 'https://merge-api-production.s3.amazonaws.com/media/Pipedrive_2.svg',
    },
    {
      title: 'Pipeliner',
      src: 'https://merge-api-production.s3.amazonaws.com/media/Pipeliner_lightgrey.svg',
    },
    {
      title: 'Salesflare',
      src: 'https://merge-api-production.s3.amazonaws.com/media/Salesflare_2.svg',
    },
    {
      title: 'Salesforce',
      src: 'https://merge-api-production.s3.amazonaws.com/media/Salesforce_lightgrey.svg',
    },
    {
      title: 'SugarCRM',
      src: 'https://merge-api-production.s3.amazonaws.com/media/sugarcrm_lightgrey.svg',
    },
    {
      title: 'Teamleader',
      src: 'https://merge-api-production.s3.amazonaws.com/media/Teamleader_lightgrey.svg',
    },
    {
      title: 'Teamwork CRM',
      src: 'https://merge-api-production.s3.amazonaws.com/media/Teamwork_lightgrey_cdGTrRF.svg',
    },
    {
      title: 'Vtiger',
      src: 'https://merge-api-production.s3.amazonaws.com/media/vtiger_lightgrey.svg',
    },
    {
      title: 'Zendesk Sell',
      src: 'https://merge-api-production.s3.amazonaws.com/media/Zendesk_lightgray.svg',
    },
    {
      title: 'Zoho CRM',
      src: 'https://merge-api-production.s3.amazonaws.com/media/zoho_crm_lightgrey.svg',
    },
  ],
  'HR & Payroll': [
    {
      title: '7Shifts',
      src: 'https://merge-api-production.s3.amazonaws.com/media/7shifts_lightgrey.svg',
    },
    {
      title: 'ADP Workforce Now',
      src: 'https://merge-api-production.s3.amazonaws.com/media/adp_generic_light_gray.svg',
    },
    {
      title: 'AlexisHR',
      src: 'https://merge-api-production.s3.amazonaws.com/media/alexishr_light.svg',
    },
    {
      title: 'Altera Payroll',
      src: 'https://merge-api-production.s3.amazonaws.com/media/altera-light-gray_oH4HvTj.svg',
    },
    {
      title: 'Azure Active Directory',
      src: 'https://merge-api-production.s3.amazonaws.com/media/Azure_lightgrey_aoiH5k1.svg',
    },
    {
      title: 'BambooHR',
      src: 'https://merge-api-production.s3.amazonaws.com/media/bamboohr_wc6G7kj.svg',
    },
    {
      title: 'Breathe',
      src: 'https://merge-api-production.s3.amazonaws.com/media/breathehr_light.svg',
    },
    {
      title: 'Ceridian Dayforce',
      src: 'https://merge-api-production.s3.amazonaws.com/media/ceridian_light.svg',
    },
    {
      title: 'Charlie',
      src: 'https://merge-api-production.s3.amazonaws.com/media/CharlieHR_lightgrey.svg',
    },
    {
      title: 'ChartHop',
      src: 'https://merge-api-production.s3.amazonaws.com/media/Charthop-light-grey.svg',
    },
    {
      title: 'ClayHR',
      src: 'https://merge-api-production.s3.amazonaws.com/media/ClayHR_lightgrey.svg',
    },
    {
      title: 'Deel',
      src: 'https://merge-api-production.s3.amazonaws.com/media/deel_lightgrey.svg',
    },
    {
      title: 'Factorial',
      src: 'https://merge-api-production.s3.amazonaws.com/media/factorial_light.svg',
    },
    {
      title: 'Freshteam',
      src: 'https://merge-api-production.s3.amazonaws.com/media/Freshteam_lightgray.svg',
    },
    {
      title: 'Google Workspace',
      src: 'https://merge-api-production.s3.amazonaws.com/media/Google_Workspace_lightgrey.svg',
    },
    {
      title: 'Gusto',
      src: 'https://merge-api-production.s3.amazonaws.com/media/gusto_GfgdcDW.svg',
    },
    {
      title: 'HR Cloud',
      src: 'https://merge-api-production.s3.amazonaws.com/media/hr-cloud_AM88IM6.svg',
    },
    {
      title: 'HR Partner',
      src: 'https://merge-api-production.s3.amazonaws.com/media/hrpartner_82NdRa1.svg',
    },
    {
      title: 'HRAlliance',
      src: 'https://merge-api-production.s3.amazonaws.com/media/HRAlliance_lightgrey.svg',
    },
    {
      title: 'Hibob',
      src: 'https://merge-api-production.s3.amazonaws.com/media/hibob_BtWcYI3.svg',
    },
    {
      title: 'Humaans',
      src: 'https://merge-api-production.s3.amazonaws.com/media/humaans_F1ZxVda.svg',
    },
    {
      title: 'Insperity Premier',
      src: 'https://merge-api-production.s3.amazonaws.com/media/insperity_lightgrey.svg',
    },
    {
      title: 'IntelliHR',
      src: 'https://merge-api-production.s3.amazonaws.com/media/IntelliHR-lightgrey.svg',
    },
    {
      title: 'JumpCloud',
      src: 'https://merge-api-production.s3.amazonaws.com/media/Jumpcloud_lightgrey.svg',
    },
    {
      title: 'Justworks',
      src: 'https://merge-api-production.s3.amazonaws.com/media/justworks_tH2XkXf.svg',
    },
    {
      title: 'Keka',
      src: 'https://merge-api-production.s3.amazonaws.com/media/keka_lightgrey.svg',
    },
    {
      title: 'Lano',
      src: 'https://merge-api-production.s3.amazonaws.com/media/lano_8Du0k8Y.svg',
    },
    {
      title: 'Lucca',
      src: 'https://merge-api-production.s3.amazonaws.com/media/Lucca_lightgrey.svg',
    },
    {
      title: 'titlely',
      src: 'https://merge-api-production.s3.amazonaws.com/media/titlely_light.svg',
    },
    {
      title: 'Nmbrs',
      src: 'https://merge-api-production.s3.amazonaws.com/media/nmbrs_dm4ksBw.svg',
    },
    {
      title: 'Officient',
      src: 'https://merge-api-production.s3.amazonaws.com/media/Officient_light_grey.svg',
    },
    {
      title: 'Okta',
      src: 'https://merge-api-production.s3.amazonaws.com/media/Okta_lightgrey.svg',
    },
    {
      title: 'OneLogin',
      src: 'https://merge-api-production.s3.amazonaws.com/media/OneLogin-2.svg',
    },
    {
      title: 'OysterHR',
      src: 'https://merge-api-production.s3.amazonaws.com/media/Oyster_light_grey.svg',
    },
    {
      title: 'PayCaptain',
      src: 'https://merge-api-production.s3.amazonaws.com/media/paycaptain_lightgrey.svg',
    },
    {
      title: 'PayFit',
      src: 'https://merge-api-production.s3.amazonaws.com/media/payfit_lightgrey.svg',
    },
    {
      title: 'Paychex',
      src: 'https://merge-api-production.s3.amazonaws.com/media/paychex_yWjPDKv.svg',
    },
    {
      title: 'Paycor',
      src: 'https://merge-api-production.s3.amazonaws.com/media/paycor_lightgrey.svg',
    },
    {
      title: 'Paylocity',
      src: 'https://merge-api-production.s3.amazonaws.com/media/paylocity-3.svg',
    },
    {
      title: 'PeopleHR',
      src: 'https://merge-api-production.s3.amazonaws.com/media/PeopleHR-2.svg',
    },
    {
      title: 'Personio',
      src: 'https://merge-api-production.s3.amazonaws.com/media/personio_OO0JaZh.svg',
    },
    {
      title: 'PingOne',
      src: 'https://merge-api-production.s3.amazonaws.com/media/PingIdentity_lightgrey.svg',
    },
    {
      title: 'Proliant',
      src: 'https://merge-api-production.s3.amazonaws.com/media/proliant-light-grey-svg.svg',
    },
    {
      title: 'Rippling',
      src: 'https://merge-api-production.s3.amazonaws.com/media/rippling_generic_light_gray.svg',
    },
    {
      title: 'SAP SuccessFactors',
      src: 'https://merge-api-production.s3.amazonaws.com/media/successfactors_MsDRdz1.svg',
    },
    {
      title: 'Sage HR',
      src: 'https://merge-api-production.s3.amazonaws.com/media/sage_ey5lMX1.svg',
    },
    {
      title: 'Sapling',
      src: 'https://merge-api-production.s3.amazonaws.com/media/Kallidus_LightGrey.svg',
    },
    {
      title: 'Sesame',
      src: 'https://merge-api-production.s3.amazonaws.com/media/sesame-light-gray.svg',
    },
    {
      title: 'Square Payroll',
      src: 'https://merge-api-production.s3.amazonaws.com/media/square_BxsDbZ9.svg',
    },
    {
      title: 'TriNet',
      src: 'https://merge-api-production.s3.amazonaws.com/media/TriNet-2.svg',
    },
    {
      title: 'UKG Dimensions',
      src: 'https://merge-api-production.s3.amazonaws.com/media/ukg_lightgrey.svg',
    },
    {
      title: 'UKG Pro',
      src: 'https://merge-api-production.s3.amazonaws.com/media/ukg_vsAkRrU.svg',
    },
    {
      title: 'UKG Ready',
      src: 'https://merge-api-production.s3.amazonaws.com/media/ukg_lightgrey_QJ0SKev.svg',
    },
    {
      title: 'Workday',
      src: 'https://merge-api-production.s3.amazonaws.com/media/workday_RD5lwK9.svg',
    },
    {
      title: 'Zenefits',
      src: 'https://merge-api-production.s3.amazonaws.com/media/zenefits_a9NRMXn.svg',
    },
  ],
};
