import { HStack, Skeleton, VStack } from '@chakra-ui/react';
import { FormField } from 'Atoms';
import { Modal, TagSelector } from 'Molecules';
import { Controller, useForm, useFormState } from 'react-hook-form';
import { useTranslation } from 'utils/translation';
import { useBusinessUnitLabels, useSimpleBusinessUnit } from '../BusinessUnits.hooks';
import { CompanyLevelSelector, UserAssigner } from 'Organisms';

import {
  ActivityReport_Constraint_,
  ActivityReport_Update_Column_,
  BusinessUnitAssessment_Constraint_,
  BusinessUnitAssessment_Update_Column_,
  GetFinancialsDocument_,
  BusinessUnit,
  useCompanyAssessmentsQuery,
  BusinessUnitsDocument_,
  useUpsertBusinessUnitMutation,
  useToggleBusinessUnitInCAssessmentMutation,
  CompanyAssessmentDocument_,
  useGetBusinessUnitReportingGroupQuery,
  useUpsertBusinessUnitReportingGroupMutation,
  GetBusinessUnitReportingGroupDocument_,
} from 'models';
import mixpanel from 'mixpanel-browser';
import { TRACKING_EVENTS } from 'utils/mixpanel';
import { useCurrentCompanyId } from 'utils/hooks';
import { AssessmentsTable } from 'containers/Assessments/pieces';
import { useEffect, useMemo, useState } from 'react';
import { ReportingUnitController } from './ReportingUnitNameController';
import { useGetNewestValidActivityVersion } from 'containers/Assessments/VersioningOfQuestions.hooks';
import { GENERAL_ACTIVITY_REF } from 'containers/Assessments/Assessments.hooks';
import { dateToString } from 'utils/date';
import { captureException } from '@sentry/browser';

export type BusinessUnitFields = {
  name: string;
  labels: string[];
  contactPersonId: string;
  cAssessmentIds: string[];
  reportingGroupId?: string;
};

type BusinessUnitFormProps = {
  businessUnit?: BusinessUnit;
  withAssessments?: boolean;
  currentAssessmentId?: string;
  businessUnitId?: string;
  isOpen: boolean;
  onClose: () => void;
  selectedParentGroup?: string | undefined;
};
export const BusinessUnitFormModal = ({
  businessUnit,
  withAssessments = false,
  currentAssessmentId,
  businessUnitId,
  isOpen,
  onClose,
  selectedParentGroup,
}: BusinessUnitFormProps) => {
  const { companyId } = useCurrentCompanyId();
  const { unitData } = useSimpleBusinessUnit(businessUnitId);
  const { data: reportingGroupData } = useGetBusinessUnitReportingGroupQuery({
    variables: {
      cAssessmentId: currentAssessmentId,
      businessUnitId: businessUnitId,
    },
    skip: !businessUnitId || !currentAssessmentId,
    fetchPolicy: 'network-only',
  });

  const currentReportingGroupId = useMemo(() => {
    return reportingGroupData?.group?.[0]?.id ?? null;
  }, [reportingGroupData, businessUnit, isOpen]);
  const [isLoading, setIsLoading] = useState(false);

  const buById = useMemo(() => {
    return unitData?.businessUnit ?? undefined;
  }, [unitData]);
  businessUnit = businessUnit ?? buById;
  const [toggleBUInCAssessment] = useToggleBusinessUnitInCAssessmentMutation();
  const { data, loading } = useCompanyAssessmentsQuery({
    variables: {
      companyId,
    },
    skip: !companyId,
  });
  const assessments = useMemo(() => {
    return data?.assessments ?? [];
  }, [data]);

  const { t } = useTranslation(['bUnits', 'assessment', 'common']);
  const [upsertBusinessUnit] = useUpsertBusinessUnitMutation({
    refetchQueries: [
      BusinessUnitsDocument_,
      CompanyAssessmentDocument_,
      GetFinancialsDocument_,
      GetBusinessUnitReportingGroupDocument_,
    ],
    awaitRefetchQueries: true,
  });
  const [upsertBuinessUnitReportingGroup] = useUpsertBusinessUnitReportingGroupMutation({
    refetchQueries: [
      BusinessUnitsDocument_,
      CompanyAssessmentDocument_,
      GetFinancialsDocument_,
      GetBusinessUnitReportingGroupDocument_,
    ],
    awaitRefetchQueries: true,
  });
  const { reset, handleSubmit, control } = useForm<BusinessUnitFields>({
    mode: 'all',
    reValidateMode: 'onBlur',
    criteriaMode: 'all',
    shouldFocusError: true,
    defaultValues: {
      name: businessUnit?.name ?? '',
      labels: businessUnit?.labels ?? [],
      contactPersonId: businessUnit?.contactPerson?.id ?? '',
      cAssessmentIds: (businessUnit?.cAssessmentIds ?? []).map(
        ({ cAssessmentId }) => cAssessmentId
      ),
      reportingGroupId: selectedParentGroup || currentReportingGroupId,
    },
  });

  const { getNewestValidActivityVersion } = useGetNewestValidActivityVersion();

  const { isValid, isDirty, errors } = useFormState({ control });
  const allLabels = useBusinessUnitLabels();
  const buLabelOptions = allLabels.map((label) => ({ value: label, label: label }));

  useEffect(() => {
    reset({
      name: businessUnit?.name ?? '',
      labels: businessUnit?.labels ?? [],
      contactPersonId: businessUnit?.contactPerson.id ?? '',
      cAssessmentIds: (businessUnit?.cAssessmentIds ?? []).map(
        ({ cAssessmentId }) => cAssessmentId
      ),
      reportingGroupId: selectedParentGroup || currentReportingGroupId,
    });
  }, [businessUnit, isOpen, onClose, currentReportingGroupId, selectedParentGroup]);

  const onSubmit = async (values: BusinessUnitFields) => {
    setIsLoading(true);

    try {
      const { name, labels, contactPersonId, cAssessmentIds, reportingGroupId } = values;

      const businessUnitDetails = {
        id: businessUnit?.id,
        companyId: companyId,
        name,
        labels,
        contactPersonId,
      };

      const assessmentsToRevive = cAssessmentIds.filter((id) => {
        businessUnit?.deletedCAssessmentIds.some(({ cAssessmentId }) => cAssessmentId === id);
      });
      const assessmentsToRemove = businessUnit?.cAssessmentIds
        .filter(({ cAssessmentId }) => !cAssessmentIds.includes(cAssessmentId))
        .map(({ cAssessmentId }) => cAssessmentId as string);

      if (assessmentsToRevive.length) {
        await toggleBUInCAssessment({
          variables: {
            cAssessmentId: assessmentsToRevive[0],
            businessUnitId: businessUnit?.id,
            deletedAt: null,
          },
        });
      }
      if (assessmentsToRemove?.length) {
        await toggleBUInCAssessment({
          variables: {
            cAssessmentId: assessmentsToRemove[0],
            businessUnitId: businessUnit?.id,
            deletedAt: 'now()',
          },
        });
      }
      const newCAssessments = await Promise.all(
        (currentAssessmentId ? [...cAssessmentIds, currentAssessmentId] : cAssessmentIds)
          .filter(
            (cAssessmentId) =>
              !assessmentsToRevive.includes(cAssessmentId) &&
              !businessUnit?.cAssessmentIds.find(
                (assessment) => assessment.cAssessmentId === cAssessmentId
              )
          )
          .map(async (cAssessmentId) => {
            const generalActivityVersionNumber = await getNewestValidActivityVersion(
              GENERAL_ACTIVITY_REF,
              dateToString(new Date()),
              cAssessmentId
            );
            return {
              cAssessmentId,
              reportingGroupId,
              activityReports: {
                data: [
                  {
                    activityRef: '0.0',
                    activityVersionNumber: generalActivityVersionNumber,
                    financials: {
                      data: {
                        capex: 0,
                        opex: 0,
                        revenue: 0,
                        adaptationOpex: 0,
                        adaptationCapex: 0,
                        isEstimate: true,
                        noteHistory: { data: {} },
                        attachmentBox: { data: {} },
                      },
                    },
                    deletedAt: null,
                  },
                ],
                on_conflict: {
                  constraint:
                    ActivityReport_Constraint_.ActivityReportBAssessmentIdActivityRef_614b7372Uniq_,
                  update_columns: [ActivityReport_Update_Column_.DeletedAt_],
                },
              },
              deletedAt: null,
            };
          })
      );
      if (!!businessUnit) {
        await upsertBuinessUnitReportingGroup({
          variables: {
            businessUnitId: businessUnit?.id,
            cAssessmentId: currentAssessmentId,
            reportingGroupId: reportingGroupId,
          },
        });
      }
      await upsertBusinessUnit({
        variables: {
          businessUnit: {
            ...businessUnitDetails,
            bAssessments: {
              data: newCAssessments,
              on_conflict: {
                constraint:
                  BusinessUnitAssessment_Constraint_.BusinessUnitAssessmentCAssessmentIdBusinessUnitIdN_8db61b88U_,
                update_columns: [BusinessUnitAssessment_Update_Column_.DeletedAt_],
              },
            },
          },
        },
      });
      mixpanel.track(
        businessUnit
          ? TRACKING_EVENTS.BUSINESS_UNITS.UPDATE
          : TRACKING_EVENTS.BUSINESS_UNITS.CREATE,
        {
          companyId,
          location: window.location.pathname,
        }
      );

      setIsLoading(false);
      onClose();
    } catch (error) {
      captureException(error, {
        extra: { errorMessage: 'Error creating reporting unit' },
      });
      setIsLoading(false);
    }
  };

  return (
    <Skeleton isLoaded={!loading}>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        size={!withAssessments ? 'sm' : 'md'}
        title={businessUnit ? t('bUnits:editButtonLabel') : t(`bUnits:addButtonLabel`)}
        confirmText={
          businessUnit
            ? t('assessment:structure.department.form.save')
            : t('assessment:structure.department.form.create')
        }
        confirmButtonProps={{
          isDisabled: !isDirty || !isValid || isLoading,
          type: 'submit',
          form: 'buForm',
          isLoading,
        }}
      >
        <form onSubmit={handleSubmit(onSubmit)} id="buForm">
          <VStack spacing="md" alignItems="flex-start" width="100%">
            <ReportingUnitController control={control} existingBU={businessUnit} />

            <FormField id="labels" label={t('bUnits:table.labels')}>
              <Controller
                name="labels"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TagSelector
                    width="500px"
                    values={value ?? []}
                    options={buLabelOptions.map((o) => o.label)}
                    placeholder={t('bUnits:selectLabels')}
                    getNewOptionData={(input: any, label: any) => ({
                      label: label as string,
                      value: input as string,
                      isNew: true,
                    })}
                    onChange={(labels: any) => onChange(labels ?? [])}
                    formatCreateLabel={(input: any) => t('bUnits:createNewLabel', { name: input })}
                    size="md"
                  />
                )}
              />
            </FormField>

            <Controller
              name="contactPersonId"
              control={control}
              rules={{ required: t('bUnits:reportingUnit.owner') }}
              render={({ field: { onChange, value } }) => (
                <FormField
                  id="owner"
                  label={t('assessment:structure.department.form.contactPerson')}
                  error={errors.contactPersonId?.message}
                  isInvalid={!!errors.contactPersonId}
                  isRequired
                >
                  <UserAssigner
                    assignedTo={value}
                    setAssignedTo={onChange}
                    placeholder={t('bUnits:reportingUnit.select')}
                  />
                </FormField>
              )}
            />

            {!!currentAssessmentId && (
              <Controller
                name="reportingGroupId"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <FormField
                    id="reporting-unit-location"
                    label={t('bUnits:reportingUnit.location')}
                    error={errors.reportingGroupId?.message}
                    isInvalid={!!errors.reportingGroupId}
                    isRequired
                  >
                    <CompanyLevelSelector
                      parentReportingGroup={value}
                      setParentReportingGroup={onChange}
                      currentReportingGroupId={selectedParentGroup || currentReportingGroupId}
                    />
                  </FormField>
                )}
              />
            )}

            {withAssessments && (
              <Controller
                name="cAssessmentIds"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <FormField id="cAssessmentIds" label={t('common:fields.cAssessmentIds.label')}>
                    <>
                      <AssessmentsTable
                        assessments={assessments}
                        withSelection={{
                          selected: value ?? [],
                          setSelected: onChange,
                        }}
                        hideActions
                        hideSharedWith
                      />
                    </>
                  </FormField>
                )}
              />
            )}
            <HStack width="100%" justifyContent="flex-end"></HStack>
          </VStack>
        </form>
      </Modal>
    </Skeleton>
  );
};
