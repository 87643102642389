import './Home.css';
import { useMemo } from 'react';
import { hotjar } from 'react-hotjar';
import { ContentLayout } from 'Molecules';
import { useUserData } from '@nhost/react';
import { isCelsiaSupport, isUserTwoWeeksOld } from 'utils/users';
import { useCurrentCompany } from 'utils/hooks';
import { User, useGetReportsQuery } from 'models';
import { HomeReportingFrameworks, HomeReportsCards, HomeWelcomeSection } from '.';

export const Home = () => {
  const { company } = useCurrentCompany();
  const user: User | null = useUserData();
  const companyId = useMemo(() => company?.id, [company]);

  const { data, loading } = useGetReportsQuery({
    variables: {
      companyId,
    },
    skip: !companyId,
  });

  hotjar.identify(null, {
    isCelsia: isCelsiaSupport(user?.email),
    isReal: company?.isReal,
    isTwoWeeksOld: isUserTwoWeeksOld(user?.createdAt),
    environment: import.meta.env.REACT_APP_NHOST_SUBDOMAIN,
  });

  const hasAssessments = useMemo(
    () =>
      !!data?.esrsAssessments.length ||
      !!data?.groupTaxonomyReports.length ||
      !!data?.paiCompanyReports.length ||
      !!data?.taxonomyReports.length,
    [data]
  );

  return (
    <ContentLayout header={''} isLoading={loading} variant="narrow">
      <HomeWelcomeSection />
      <HomeReportingFrameworks />
      {hasAssessments && <HomeReportsCards data={data} companyId={companyId} />}
    </ContentLayout>
  );
};
