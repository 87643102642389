import { AutoResizeTextarea } from 'Atoms';
import { MetricsTableData } from 'containers/Esrs/pieces/DisclosureRequirements/Metrics';
import { TimePeriodsEnums } from 'containers/Esrs/pieces/DisclosureRequirements/Requirement';
import { debounce } from 'lodash';
import { useState, useMemo, useEffect, useCallback } from 'react';
import { useGetDatapointValues } from '../../QuantitativeInputs';

export const AdditionalRowInput = ({
  metric,
  companyReportingUnit,
  placement,
}: {
  metric: MetricsTableData['metric'];
  companyReportingUnit: string | undefined;
  placement: string;
}) => {
  const [inputValue, setInputValue] = useState<string | null>();
  const [hasStartedTyping, setHasStartedTyping] = useState<boolean>(false);

  const { onDatapointChange, answer, dataPointPerYear } = useGetDatapointValues(
    {
      metric,
    },
    companyReportingUnit ?? ''
  );

  const data = useMemo(
    () =>
      placement === 'before'
        ? dataPointPerYear?.metadata?.before
        : dataPointPerYear?.metadata?.after,
    [placement, dataPointPerYear]
  );

  useEffect(() => {
    if (!hasStartedTyping) setInputValue(data?.text ?? null);
  }, [data]);

  const debounceSave = useCallback(
    debounce((newValue) => {
      const metadata = {
        ...dataPointPerYear?.metadata,
        [placement]: {
          ...(data ?? {}),
          text: newValue,
        },
      };
      onDatapointChange({
        value: dataPointPerYear?.value ?? '',
        factValue: dataPointPerYear?.factValue,
        hasOptedOut: answer?.hasOptedOut ?? false,
        optOutReason: answer?.optOutReason ?? '',
        dp: dataPointPerYear ?? { timeframe: TimePeriodsEnums.year },
        metadata: metadata,
      });
    }, 1000),
    [onDatapointChange, dataPointPerYear]
  );

  const handleInputChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newValue = event.target.value === '' ? null : event.target.value;
    setInputValue(newValue);
    debounceSave(newValue);
    setHasStartedTyping(true);
  };

  if (data?.isVisible !== true) {
    return <></>;
  }

  return (
    <AutoResizeTextarea
      value={inputValue ?? ''}
      onChange={handleInputChange}
      minH="56px"
      variant="ghost"
      borderRadius="6px"
      lineHeight="20px"
      size="md"
      placeholder="Write additional information (optional)"
      opacity={answer?.hasOptedOut ? 0.4 : 1}
      onClick={(e) => e.stopPropagation()}
    />
  );
};
