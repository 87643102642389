const sustainabilityMatterE1Enums = [
  { value: 'climateChangeAdaptationMember', label: 'Climate change adaptation' },
  { value: 'climateChangeMitigationMember', label: 'Climate change mitigation' },
  { value: 'energyEfficiencyMember', label: 'Energy efficiency' },
  { value: 'renewableEnergyDeploymentMember', label: 'Renewable energy deployment' },
  { value: 'otherClimateChangeSubtopicMember', label: 'Other climate change subtopic' },
];

const sustainabilityMatterE2Enums = [
  { value: 'pollutionOfAirMember', label: 'Pollution of air' },
  { value: 'pollutionOfWaterMember', label: 'Pollution of water' },
  { value: 'pollutionOfSoilMember', label: 'Pollution of soil' },
  {
    value: 'pollutionOfLivingOrganismsAndFoodResourcesMember',
    label: 'Pollution of living organisms and food resources',
  },
  { value: 'substancesOfConcernMember', label: 'Substances of concern' },
  { value: 'substancesOfVeryHighConcernMember', label: 'Substances of very high concern' },
  { value: 'microplasticsMember', label: 'Microplastics' },
  { value: 'otherPollutionSubtopicMember', label: 'Other pollution subtopic' },
];

const sustainabilityMatterE3Enums = [
  { value: 'waterConsumptionMember', label: 'Water consumption' },
  { value: 'waterWithdrawalsMember', label: 'Water withdrawals' },
  { value: 'waterDischargesMember', label: 'Water discharges' },
  { value: 'waterDischargesInOceansMember', label: 'Water discharges in oceans' },
  {
    value: 'extractionAndUseOfMarineResourcesMember',
    label: 'Extraction and use of marine resources',
  },
  {
    value: 'otherWaterAndMarineResourcesSubtopicMember',
    label: 'Other water and marine resources subtopic',
  },
];

const sustainabilityMatterE4Enums = [
  {
    value: 'climateChangeMember',
    label: 'Climate change (direct impact drivers of biodiversity loss)',
  },
  {
    value: 'landUseChangeMember',
    label: 'Land-use change (direct impact drivers of biodiversity loss)',
  },
  {
    value: 'freshWaterUseChangeMember',
    label: 'Fresh water-use change (direct impact drivers of biodiversity loss)',
  },
  {
    value: 'seaUseChangeMember',
    label: 'Sea-use change (direct impact drivers of biodiversity loss)',
  },
  {
    value: 'directExploitationMember',
    label: 'Direct exploitation (direct impact drivers of biodiversity loss)',
  },
  {
    value: 'invasiveAlienSpeciesMember',
    label: 'Invasive alien species (direct impact drivers of biodiversity loss)',
  },
  { value: 'pollutionMember', label: 'Pollution (direct impact drivers of biodiversity loss)' },
  {
    value: 'otherDirectImpactDriversOfBiodiversityLossMember',
    label: 'Other direct impact drivers of biodiversity loss',
  },
  { value: 'impactsOnStateOfSpeciesMember', label: 'Impacts on state of species' },
  {
    value: 'impactsOnExtentAndConditionOfEcosystemsMember',
    label: 'Impacts on extent and condition of ecosystems',
  },
  {
    value: 'impactsAndDependenciesOnEcosystemServicesMember',
    label: 'Impacts and dependencies on ecosystem services',
  },
  {
    value: 'productionSourcingOrConsumptionOfRawMaterialsMember',
    label: 'Production, sourcing or consumption of raw materials',
  },
  {
    value: 'otherBiodiversityAndEcosystemsSubtopicMember',
    label: 'Other biodiversity and ecosystems subtopic',
  },
];

const sustainabilityMatterE5Enums = [
  { value: 'resourceInflowsMember', label: 'Resource inflows' },
  {
    value: 'resourceOutflowsRelatedToProductsAndServicesMember',
    label: 'Resource outflows related to products and services',
  },
  { value: 'wasteMember', label: 'Waste' },
  {
    value: 'circularityOfProductsAndMaterialsMember',
    label: 'Circularity of products and materials',
  },
  {
    value: 'otherResourceUseAndCircularEconomySubtopicMember',
    label: 'Other resource use and circular economy subtopic',
  },
];

const sustainabilityMatterS1Enums = [
  { value: 'secureEmploymentForOwnWorkforceMember2', label: 'Secure employment for own workforce' },
  { value: 'workingTimeForOwnWorkforceMember2', label: 'Working time for own workforce' },
  { value: 'adequateWagesForOwnWorkforceMember2', label: 'Adequate wages for own workforce' },
  { value: 'socialDialogueForOwnWorkforceMember2', label: 'Social dialogue for own workforce' },
  {
    value: 'freedomOfAssociationForOwnWorkforceMember2',
    label: 'Freedom of association for own workforce',
  },
  {
    value: 'existenceOfWorksCouncilsForOwnWorkforceMember2',
    label: 'Existence of works councils for own workforce',
  },
  {
    value: 'informationConsultationAndParticipationRightsOfOwnWorkforceMember2',
    label: 'Information, consultation and participation rights of own workforce',
  },
  {
    value: 'collectiveBargainingForOwnWorkforceMember2',
    label: 'Collective bargaining for own workforce',
  },
  { value: 'worklifeBalanceForOwnWorkforceMember2', label: 'Work-life balance for own workforce' },
  { value: 'healthAndSafetyForOwnWorkforceMember2', label: 'Health and safety for own workforce' },
  {
    value: 'genderEqualityAndEqualPayForWorkOfEqualValueForOwnWorkforceMember2',
    label: 'Gender equality and equal pay for work of equal value for own workforce',
  },
  {
    value: 'trainingAndSkillsDevelopmentForOwnWorkforceMember2',
    label: 'Training and skills development for own workforce',
  },
  {
    value: 'employmentAndInclusionOfPersonsWithDisabilitiesForOwnWorkforceMember2',
    label: 'Employment and inclusion of persons with disabilities for own workforce',
  },
  {
    value: 'measuresAgainstViolenceAndHarassmentInWorkplaceForOwnWorkforceMember2',
    label: 'Measures against violence and harassment in workplace for own workforce',
  },
  { value: 'diversityForOwnWorkforceMember2', label: 'Diversity for own workforce' },
  { value: 'childLabourForOwnWorkforceMember2', label: 'Child labour for own workforce' },
  { value: 'forcedLabourForOwnWorkforceMember2', label: 'Forced labour for own workforce' },
  { value: 'adequateHousingForOwnWorkforceMember2', label: 'Adequate housing for own workforce' },
  { value: 'privacyForOwnWorkforceMember2', label: 'Privacy for own workforce' },
  { value: 'otherOwnWorkforceSubtopicMember2', label: 'Other own workforce subtopic' },
];

const sustainabilityMatterS2Enums = [
  {
    value: 'secureEmploymentForValueChainWorkersMember2',
    label: 'Secure employment for value chain workers',
  },
  {
    value: 'workingTimeForValueChainWorkersMember2',
    label: 'Working time for value chain workers',
  },
  {
    value: 'adequateWagesForValueChainWorkersMember2',
    label: 'Adequate wages for value chain workers',
  },
  {
    value: 'socialDialogueForValueChainWorkersMember2',
    label: 'Social dialogue for value chain workers',
  },
  {
    value: 'freedomOfAssociationForValueChainWorkersMember2',
    label: 'Freedom of association for value chain workers',
  },
  {
    value: 'collectiveBargainingForValueChainWorkersMember2',
    label: 'Collective bargaining for value chain workers',
  },
  {
    value: 'worklifeBalanceForValueChainWorkersMember2',
    label: 'Work-life balance for value chain workers',
  },
  {
    value: 'healthAndSafetyForValueChainWorkersMember2',
    label: 'Health and safety for value chain workers',
  },
  {
    value: 'genderEqualityAndEqualPayForWorkOfEqualValueForValueChainWorkersMember2',
    label: 'Gender equality and equal pay for work of equal value for value chain workers',
  },
  {
    value: 'trainingAndSkillsDevelopmentForValueChainWorkersMember2',
    label: 'Training and skills development for value chain workers',
  },
  {
    value: 'employmentAndInclusionOfPersonsWithDisabilitiesForValueChainWorkersMember2',
    label: 'Employment and inclusion of persons with disabilities for value chain workers',
  },
  {
    value: 'measuresAgainstViolenceAndHarassmentInWorkplaceForValueChainWorkersMember2',
    label: 'Measures against violence and harassment in workplace for value chain workers',
  },
  { value: 'diversityForValueChainWorkersMember2', label: 'Diversity for value chain workers' },
  {
    value: 'childLabourForValueChainWorkersMember2',
    label: 'Child labour for value chain workers',
  },
  {
    value: 'forcedLabourForValueChainWorkersMember2',
    label: 'Forced labour for value chain workers',
  },
  {
    value: 'adequateHousingForValueChainWorkersMember2',
    label: 'Adequate housing for value chain workers',
  },
  {
    value: 'waterAndSanitationForValueChainWorkersMember2',
    label: 'Water and sanitation for value chain workers',
  },
  { value: 'privacyForValueChainWorkersMember2', label: 'Privacy for value chain workers' },
  {
    value: 'otherWorkersInValueChainSubtopicMember2',
    label: 'Other workers in value chain subtopic',
  },
];

const sustainabilityMatterS3Enums = [
  {
    value: 'adequateHousingForAffectedCommunitiesMember2',
    label: 'Adequate housing for affected communities',
  },
  { value: 'adequateFoodMember2', label: 'Adequate food' },
  {
    value: 'waterAndSanitationForAffectedCommunitiesMember2',
    label: 'Water and sanitation for affected communities',
  },
  { value: 'landrelatedImpactsMember2', label: 'Land-related impacts' },
  { value: 'securityrelatedImpactsMember2', label: 'Security-related impacts' },
  {
    value: 'freedomOfExpressionForAffectedCommunitiesMember2',
    label: 'Freedom of expression for affected communities',
  },
  { value: 'freedomOfAssemblyMember2', label: 'Freedom of assembly' },
  { value: 'impactsOnHumanRightsDefendersMember2', label: 'Impacts on human rights defenders' },
  {
    value: 'freePriorAndInformedConsentMember2',
    label: 'Free, prior and informed consent (Rights of indigenous people)',
  },
  { value: 'selfdeterminationMember2', label: 'Self-determination (Rights of indigenous people)' },
  { value: 'culturalRightsMember2', label: 'Cultural rights (Rights of indigenous people)' },
  {
    value: 'impactsOnIndigenousPeoplesMember2',
    label: 'Impacts on indigenous peoples (Rights of indigenous people)',
  },
  {
    value: 'otherAffectedCommunitiesSubtopicMember2',
    label: 'Other affected communities subtopic (Rights of indigenous people)',
  },
];

const sustainabilityMatterS4Enums = [
  {
    value: 'privacyForConsumersAndOrEndUsersMember2',
    label: 'Privacy for consumers and (or) end-users',
  },
  {
    value: 'freedomOfExpressionForConsumersAndOrEndusersMember2',
    label: 'Freedom of expression for consumers and (or) end-users',
  },
  { value: 'accessToQualityInformationMember2', label: 'Access to (quality) information' },
  {
    value: 'healthAndSafetyForConsumersAndOrEndUsersMember2',
    label: 'Health and safety for consumers and (or) end-users',
  },
  { value: 'securityOfPersonMember2', label: 'Security of person' },
  { value: 'protectionOfChildrenMember2', label: 'Protection of children' },
  { value: 'nondiscriminationMember2', label: 'Non-discrimination' },
  { value: 'accessToProductsAndServicesMember2', label: 'Access to products and services' },
  { value: 'responsibleMarketingPracticesMember2', label: 'Responsible marketing practices' },
  {
    value: 'otherConsumersAndOrEndusersSubtopicMember2',
    label: 'Other consumers and (or) end-users subtopic',
  },
];

const sustainabilityMatterG1Enums = [
  { value: 'corporateCultureMember', label: 'Corporate culture' },
  { value: 'protectionOfWhistleblowersMember', label: 'Protection of whistle-blowers' },
  { value: 'animalWelfareMember', label: 'Animal welfare' },
  {
    value: 'politicalEngagementAndLobbyingActivitiesMember',
    label: 'Political engagement and lobbying activities',
  },
  {
    value: 'managementOfRelationshipsWithSuppliersIncludingPaymentPracticesMember',
    label: 'Management of relationships with suppliers including payment practices',
  },
  { value: 'corruptionAndBriberyMember', label: 'Corruption and bribery' },
  { value: 'otherBusinessConductSubtopicMember', label: 'Other business conduct subtopic' },
];

export const sustainabilityMattersEnums = {
  'ESRS-E1': sustainabilityMatterE1Enums,
  'ESRS-E2': sustainabilityMatterE2Enums,
  'ESRS-E3': sustainabilityMatterE3Enums,
  'ESRS-E4': sustainabilityMatterE4Enums,
  'ESRS-E5': sustainabilityMatterE5Enums,
  'ESRS-S1': sustainabilityMatterS1Enums,
  'ESRS-S2': sustainabilityMatterS2Enums,
  'ESRS-S3': sustainabilityMatterS3Enums,
  'ESRS-S4': sustainabilityMatterS4Enums,
  'ESRS-G1': sustainabilityMatterG1Enums,
};
