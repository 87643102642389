import { Button } from 'Atoms';
import { ContentHeader } from 'Molecules/ContentHeader';
import { ContentLayout } from 'Molecules/ContentLayout';
import { DownloadIcon } from 'Tokens/Icons/Function';
import { useNavigate, useParams } from 'react-router-dom';
import { FiltersType, useMetricBreakdown } from './MetricChart.hooks';
import { Box, HStack, VStack } from '@chakra-ui/react';
import { useEffect, useMemo, useState } from 'react';
import { Loader } from 'Molecules';
import {
  MetricBarGraph,
  MetricChartFilterSelector,
  MetricChartTable,
  MetricChartTagsBreakdown,
} from './pieces';

type FilterValueTypes =
  | string
  | string[]
  | {
      tagType: string;
      tagValue: string;
    };

export type FilterChange = (key: keyof FiltersType, value?: FilterValueTypes) => void;

export const MetricChart = () => {
  const { reportingUnitId } = useParams();
  const navigate = useNavigate();
  const [selectedTagValues, setSelectedTagValues] = useState<string[]>([]);
  const [filters, setFilters] = useState<FiltersType>({
    ruFilter: undefined,
    subFilter: undefined,
  });

  const {
    result,
    chartData,
    tagValues,
    businessUnitOptions,
    subsidiaryOptions,
    tagOptions,
    metric,
    isGroup,
    isYearly,
    loading,
    singleTag,
    tagGroup,
    tagChild,
    title,
  } = useMetricBreakdown(filters);

  useEffect(() => setSelectedTagValues(tagValues), [tagValues]);

  const handleFilterChange = (key: keyof FiltersType, value?: FilterValueTypes) => {
    setFilters((prev) => ({ ...prev, [key]: value }));
  };

  const isBreakdownAvailable = useMemo(
    () => !!tagOptions.length && !singleTag && !tagGroup && !tagChild,
    [tagOptions, singleTag, tagGroup, tagChild]
  );

  if (loading) return <Loader />;

  return (
    <ContentLayout
      backButton
      header={
        <ContentHeader
          title={title}
          subtitle={metric.unitOfMeasurement ?? ''}
          size="md"
          actions={
            <Button variant="ghost" leftIcon={<DownloadIcon color="inherit" />} isDisabled>
              Export as CSV
            </Button>
          }
        />
      }
      onBackNavigate={() => navigate(-1)}
    >
      <HStack width="100%" spacing="16px" alignItems="flex-start" flexGrow="1">
        <Box w="100%" h="100%" maxHeight="50vh" flexGrow="1">
          <MetricBarGraph
            chartData={chartData}
            title={title}
            filters={filters}
            selectedTagValues={selectedTagValues}
            isYearly={isYearly}
          />
          <MetricChartTable
            tableData={result}
            filters={filters}
            selectedTagValues={selectedTagValues}
            tagValues={tagValues}
            setSelectedTagValues={setSelectedTagValues}
          />
        </Box>
        <VStack w="320px" minW="200px">
          <MetricChartTagsBreakdown
            tagOptions={tagOptions}
            isBreakdownAvailable={isBreakdownAvailable}
            handleFilterChange={handleFilterChange}
          />
          {!reportingUnitId && (
            <MetricChartFilterSelector
              isGroup={isGroup}
              subsidiaryOptions={subsidiaryOptions}
              businessUnitOptions={businessUnitOptions}
              handleFilterChange={handleFilterChange}
            />
          )}
        </VStack>
      </HStack>
    </ContentLayout>
  );
};
