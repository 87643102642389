import { Box, HStack, VStack, useDisclosure } from '@chakra-ui/react';
import { useFileUpload, useUserData } from '@nhost/react';
import { Button, ProgressBar, Tooltip } from 'Atoms';
import { Drawer, FileStatus, FileUploader, File as FileViewer } from 'Molecules';
import { Typography } from 'Tokens';
import { DiagnosticIcon } from 'Tokens/Icons/Custom';
import { AIIcon, AddIcon } from 'Tokens/Icons/Function';
import { CheckIcon } from 'Tokens/Icons/Status';
import { CompanyFilesDocument_, useAddFileMutation, useGetJobProgressSubscription } from 'models';
import { useEffect, useState } from 'react';
import { getNameExtension } from 'utils/files';
import { useCurrentCompany, useCurrentCompanyId } from 'utils/hooks';
import { nhost } from 'utils/nhost';
import axios from 'axios';
import { useFeatureFlags } from 'containers/Navigation';
import { useTranslation } from 'utils/translation';

const DESCRIPTIONS = [
  {
    id: 'uploadStep',
    title: 'Select document',
    description:
      'Find a document that might have relevant information. You can use another document second time.',
  },
  {
    id: 'screening',
    title: 'Screening process',
    description:
      'At this step, will be searching for relevant insights in the document that you provided.',
  },
  {
    id: 'review',
    title: 'Review results',
    description: 'Return to the assessment to see the results.',
  },
];

export const InsightExtractorButton = ({ reportId }: { reportId?: string }) => {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const { companyId } = useCurrentCompanyId();
  const { t } = useTranslation(['common']);

  const { company } = useCurrentCompany();
  const { hasAiAccess } = useFeatureFlags({ company });

  const user = useUserData();
  const [uploadedFile, setUploadedFile] = useState<File>();
  const [isAttaching, setIsAttaching] = useState<boolean>(false);

  const [isFileUploaded, setIsFileUploaded] = useState<boolean>(false);
  const [fileName, setFileName] = useState<string>('');

  const { upload, id, isUploaded, isUploading, isError } = useFileUpload();
  const [saveFile] = useAddFileMutation();

  const [documentId, setDocumentId] = useState<string | undefined>(undefined);
  const [fileId, setFileId] = useState<string | undefined>(undefined);
  const [status, setStatus] = useState<FileStatus | undefined>();

  const [jobId, setJobId] = useState<string | undefined>(undefined);

  const { data } = useGetJobProgressSubscription({
    variables: {
      jobId: jobId ?? '',
    },
    skip: !jobId,
  });

  useEffect(() => {
    if (isError) {
      setStatus('failed');
    }
    if (isUploaded) {
      setStatus('uploaded');
    }
    if (isUploading) {
      setStatus('uploading');
    }
  }, [isError, isUploaded, isUploading, status]);

  useEffect(() => {
    if (isUploaded && id) {
      setFileId(id);
    }
    const setUpFileUrl = async () => {
      const { data: saveFileData } = await saveFile({
        variables: {
          input: {
            title: getNameExtension(fileName ?? '').name ?? 'Unknown',
            tags: [],
            description: '',
            source: '',
            ...(id ? { storageFileId: id } : {}),
            companyId,
            uploadedById: user?.id,
          },
        },
        refetchQueries: [CompanyFilesDocument_],
      });
      setDocumentId(saveFileData?.file?.id);
    };
    if (isUploaded) {
      setUpFileUrl();
    }
  }, [id, isUploaded]);

  const handleUpload = (fileToUpload: File) => {
    upload({ file: fileToUpload }).then(() => {
      setIsFileUploaded(true);
      setIsAttaching(false);
    });
  };

  const startScreening = async () => {
    if (!documentId || !fileId || !reportId) return;
    const result = await axios.post(
      `${import.meta.env.REACT_APP_AI_BACKEND_URL}/extract`,
      {
        fileId,
        documentationFileId: documentId,
        reportId,
        type: 'pai',
      },
      {
        headers: {
          'secret-key': 'secretKey',
          authorization: `Bearer ${nhost.auth.getAccessToken()}`,
        },
      }
    );
    setJobId((result?.data as { jobId?: string })?.jobId);
  };
  const ActionPreview = ({ actionId }: { actionId: string }) => {
    if (actionId === 'uploadStep') {
      if (uploadedFile) {
        return (
          <FileViewer
            file={{
              description: '',
              id: '',
              source: '',
              storageFile: {
                id: '',
                name: uploadedFile.name,
              },
              tags: undefined,
              title: uploadedFile.name ?? '',
              updatedAt: new Date(),
              uploadedBy: {
                avatarUrl: user?.avatarUrl ?? '',
                displayName: user?.displayName ?? '',
                email: user?.email,
                id: user?.id,
              },
            }}
            status={status}
            onCancelClick={() => {
              setUploadedFile(undefined);
            }}
          />
        );
      }
      return (
        <Button
          leftIcon={<AddIcon color="inherit" />}
          onClick={() => setIsAttaching(true)}
          variant="ghost"
        >
          Attach file
        </Button>
      );
    }
    if (actionId === 'screening')
      return (
        <VStack
          spacing="16px"
          justifyContent="flex-start"
          alignItems="center"
          padding="24px 120px"
          bg="bg.muted"
          borderRadius="8px"
        >
          <DiagnosticIcon boxSize="80px" color="border.default" />
          <VStack spacing="4px" justifyContent="flex-start" alignItems="center">
            <Typography variant="h4">AI screening</Typography>
          </VStack>
          {jobId ? (
            <ProgressBar completed={data?.ExtractionJob_by_pk?.progress ?? 0} />
          ) : (
            <Button isDisabled={!fileId || !documentId} variant="primary" onClick={startScreening}>
              Start screening
            </Button>
          )}

          {jobId ? (
            <>
              {data?.ExtractionJob_by_pk?.progress === 100 ? (
                <Typography variant="detail" textAlign="center">
                  {data?.ExtractionJob_by_pk?.insights_aggregate?.aggregate?.count} insights found
                  with the AI screener. You can now close the tab
                </Typography>
              ) : (
                <Typography variant="detail" textAlign="center">
                  Document screening might take up to 10 minutes. <br /> Please, don’t close the
                  window
                </Typography>
              )}
            </>
          ) : (
            <Typography variant="detail">Upload a document to start</Typography>
          )}
        </VStack>
      );
    return <></>;
  };
  const StepsView = () => (
    <VStack spacing="16px">
      <VStack
        spacing="24px"
        width="100%"
        alignItems="flex-start"
        borderLeft="1px dashed"
        borderColor="text.hint"
        pl="32px"
        pr="16px"
        ml="33px"
      >
        {DESCRIPTIONS.map((desc) => (
          <Box position="relative" key={desc.id}>
            <Box
              border="1px dashed"
              borderColor="text.hint"
              borderRadius="50%"
              w="fit-content"
              px="5px"
              position="absolute"
              left="-47px"
              bg="white"
            >
              <CheckIcon boxSize="18px" mt="5px" color="text.hint" />
            </Box>
            <Typography variant="h3" color="text.default" mb="4px">
              {desc.title}
            </Typography>
            {desc.description && (
              <Typography variant="body" color="text.muted" mb="12px">
                {desc.description}
              </Typography>
            )}
            <ActionPreview actionId={desc.id} />
          </Box>
        ))}
      </VStack>
    </VStack>
  );
  return (
    <>
      <Drawer
        size="lg"
        header={
          <VStack spacing="2px" justifyContent="flex-start" alignItems="flex-start" width="100%">
            <Typography variant="h2">Populate with AI</Typography>
            <Typography variant="detail" color="text.muted">
              Use our AI screener to help populate answers and extract relevant documentation. We
              will search uploaded document for relevant insights and show them for you to review.
              Upload relevant documents to get answers suggestions.
            </Typography>
          </VStack>
        }
        footer={
          <HStack justifyContent="left" width="100%" spacing="16px">
            <Button
              variant="primary"
              isDisabled={data?.ExtractionJob_by_pk?.progress !== 100}
              onClick={() => onClose()}
            >
              Done
            </Button>
            <Button variant="ghost" onClick={() => onClose()}>
              Cancel
            </Button>
          </HStack>
        }
        isOpen={isOpen}
        onClose={onClose}
      >
        {isAttaching ? (
          <FileUploader
            multiple={false}
            isUploadDone={isFileUploaded}
            setIsUploadDone={setIsFileUploaded}
            shouldUpload={false}
            fileName={fileName}
            onUpload={(files) => {
              setFileName(files[0]?.name);
              setUploadedFile(files[0]);
              handleUpload(files[0]);
            }}
            // onDone={(files) => {
            //   console.log('do i not run?');
            //   setFileName(files[0]?.name);
            //   setUploadedFile(files[0]);
            //   setIsAttaching(false);
            // }}
          />
        ) : (
          <StepsView />
        )}
      </Drawer>
      <Tooltip label={t('common:aiConsent')} isDisabled={hasAiAccess}>
        <Button
          bg="bg.info"
          color="text.info"
          borderColor="bg.info"
          leftIcon={<AIIcon color="inherited" />}
          onClick={onOpen}
          isDisabled={!hasAiAccess}
        >
          Populate with AI
        </Button>
      </Tooltip>
    </>
  );
};
