import { HStack, Image, useDisclosure } from '@chakra-ui/react';
import { ImageCropper } from 'Molecules/ImageCropper';
import { EditIcon, UploadIcon } from 'Tokens/Icons/Function';
import React, { ChangeEvent, useEffect, useMemo, useRef, useState } from 'react';

export const ImageButton = ({
  imageUrl,
  defaultImage,
  isRound,
  onSaveImage,
  onDelete,
}: {
  imageUrl?: string;
  defaultImage: React.ReactNode;
  isRound?: boolean;
  onSaveImage: (val: File | null) => void;
  onDelete: () => void;
}) => {
  const [imgSrc, setImgSrc] = useState(imageUrl);
  const [isNewImage, setIsNewImage] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [imageName, setImageName] = useState('');
  const hasImage = useMemo(() => imgSrc && !imgSrc.includes('default=blank'), [imgSrc]);

  const { isOpen, onOpen, onClose } = useDisclosure();

  const fileInputRef = useRef<HTMLInputElement>(null);
  const cropperRef = useRef(null);

  const onSelectFile = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (!file) return;

    setImageName(file.name.split('.').slice(0, -1).join('.'));
    const reader = new FileReader();
    reader.addEventListener('load', () => {
      const fileImageUrl = reader.result?.toString() ?? '';
      setImgSrc(fileImageUrl);
    });
    reader.readAsDataURL(file);
    setIsNewImage(true);
  };

  useEffect(() => {
    if (imgSrc && isNewImage) {
      onOpen();
    }
  }, [imgSrc]);

  return (
    <label>
      <input
        ref={fileInputRef}
        type="file"
        accept="image/*"
        onChange={onSelectFile}
        style={{ display: 'none' }}
        disabled={!!hasImage}
      />
      <HStack
        borderRadius={isRound ? '50%' : '14px'}
        boxSize="56px"
        padding="4px"
        bg="bg.muted"
        border="2px solid"
        borderColor="border.decorative"
        boxSizing="border-box"
        onMouseOver={() => setIsHovered(true)}
        onMouseOut={() => setIsHovered(false)}
        position="relative"
        cursor="pointer"
        justifyContent="center"
        onClick={() => imageUrl && onOpen()}
      >
        {isHovered ? (
          hasImage ? (
            <EditIcon
              position="absolute"
              top="50%"
              left="50%"
              transform="translate(-50%, -50%)"
              boxSize="20px"
            />
          ) : (
            <UploadIcon
              position="absolute"
              top="50%"
              left="50%"
              transform="translate(-50%, -50%)"
              boxSize="20px"
            />
          )
        ) : hasImage ? (
          <Image borderRadius={isRound ? '50%' : '10px'} src={imageUrl} />
        ) : (
          defaultImage
        )}
      </HStack>

      {hasImage && imgSrc && (
        <ImageCropper
          imageSrc={imgSrc}
          imageName={imageName}
          isOpen={isOpen}
          onClose={onClose}
          onConfirm={onSaveImage}
          onDelete={onDelete}
          cropperRef={cropperRef}
          fileInputRef={fileInputRef}
        />
      )}
    </label>
  );
};
