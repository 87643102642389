import { ChevronDownIcon, ChevronRightIcon } from '@chakra-ui/icons';
import {
  Accordion as ChakraAccordion,
  AccordionItem,
  AccordionButton,
  VStack,
  AccordionPanel,
  Box,
  CircularProgress,
  CircularProgressLabel,
} from '@chakra-ui/react';
import { IconButton } from 'Atoms';
import { Typography } from 'Tokens';
import { ReactNode } from 'react';

const ACCORDION_STATUS = {
  default: {
    borderColor: 'border.decorative',
  },
  warning: {
    borderColor: 'bg.warning.accent',
  },
  success: {
    borderColor: 'bg.info.accent',
  },
};

export const StatusAccordion = ({
  title,
  subtitle,
  completedNumber,
  totalNumber,
  content,
  status = 'default',
}: {
  title: string;
  subtitle?: string;
  completedNumber?: number;
  totalNumber?: number;
  content?: ReactNode;
  status?: 'default' | 'warning' | 'success';
}) => {
  return (
    <ChakraAccordion variant="outlined" allowToggle w="100%">
      <AccordionItem borderColor={ACCORDION_STATUS[status].borderColor}>
        {({ isExpanded }) => (
          <>
            <AccordionButton padding="16px" gap="8px" justifyContent="space-between">
              <IconButton
                size="sm"
                variant="ghost"
                icon={isExpanded ? <ChevronDownIcon /> : <ChevronRightIcon />}
                aria-label="chevron icon"
              />
              <VStack alignItems="start" gap="0px" w="100%">
                <Typography variant="h3">{title}</Typography>
                <Typography variant="body">{subtitle}</Typography>
              </VStack>
              <CircularProgress
                size="48px"
                thickness="4px"
                color="text.muted"
                value={totalNumber === 0 ? 0 : ((completedNumber ?? 0) / (totalNumber ?? 1)) * 100}
              >
                <CircularProgressLabel as={Typography} variant="bodyStrong" fontSize="14px">
                  {completedNumber}/{totalNumber}
                </CircularProgressLabel>
              </CircularProgress>
            </AccordionButton>

            <AccordionPanel>
              <Box minH="20px">{content}</Box>
            </AccordionPanel>
          </>
        )}
      </AccordionItem>
    </ChakraAccordion>
  );
};
