import { FinancialResult } from 'Features/TaxonomyResultsTable/TaxonomyResultsTableTypes';
import { groupBy, mapValues } from 'lodash';
import {
  CompanyAssessmentResults,
  ObjectiveKeyEnum,
  BusinessUnitAssessmentResults,
  CalculatedActivityResult,
  CachedResultFieldsFragment_,
  ScoreSectionsEnum,
} from 'models';
import { ObjectiveId } from 'utils/objectives/objectivesOrder';
import { scoreSections } from '../../../backend/functions/_utils/portfolio';
import { allNonAdaptationObjectives, numberOrZero } from './taxonomyScore';

export const isNotAdaptationObjective = (objective?: string) =>
  !!objective && objective !== ObjectiveKeyEnum.adaptation;

export const getActivitiesListWithMSSAlignment = (
  filteredBusinessUnitResults: BusinessUnitAssessmentResults[],
  companyResults?: CompanyAssessmentResults
): Array<{
  activityResultsId: string;
  activityRef: string;
  isMSSAligned: boolean | undefined | null;
  isMSSCompleted: boolean | undefined;
}> => {
  const companyLevelMSSAlignment = companyResults?.generalAssessmentResult?.cachedResult?.isAligned;
  const companyLevelMSSCompleted =
    companyResults?.generalAssessmentResult?.cachedResult?.isCompleted;

  return filteredBusinessUnitResults.flatMap((businessUnitResult) => {
    let businessUnitLevelMSSAlignment = companyLevelMSSAlignment;
    let businessUnitLevelMSSCompleted = companyLevelMSSCompleted;
    if (businessUnitResult.hasGeneralAssessment) {
      businessUnitLevelMSSAlignment =
        businessUnitResult.generalAssessmentResult.cachedResult?.isAligned;
      businessUnitLevelMSSCompleted =
        businessUnitResult.generalAssessmentResult.cachedResult?.isCompleted;
    }
    return businessUnitResult.activityResults.map((activityResults) => {
      return {
        activityResultsId: activityResults.cachedResult?.id,
        activityRef: activityResults.activityRef,
        isMSSAligned: businessUnitLevelMSSAlignment,
        isMSSCompleted: businessUnitLevelMSSCompleted,
      };
    });
  });
};

export const getAlignment = (
  activities: CachedResultFieldsFragment_[],
  scObjectiveKey?: ObjectiveId,
  totalOverride?: CachedResultFieldsFragment_['financials'],
  activitiesMSSAlignment?: {
    activityResultsId: string;
    activityRef: string;
    isMSSAligned: boolean | null | undefined;
    isMSSCompleted: boolean | undefined;
  }[],
  eligible = false
): FinancialResult => {
  if (activities.find((activity) => !activity?.isCompleted) && !totalOverride) {
    return {
      revenue: { result: 'inProgress' },
      capex: { result: 'inProgress' },
      opex: { result: 'inProgress' },
    };
  }
  return scoreSections.reduce(
    (scorePerSection, section) => {
      const { total, aligned } = activities.reduce(
        (agg, activityResult) => {
          const countAligned = scObjectiveKey
            ? activityResult?.objectivesState?.substantialContributionObjectives?.includes(
                scObjectiveKey
              )
            : true;

          if (scObjectiveKey === 'mitigation') {
          }
          const isMSSNotCompliant = activitiesMSSAlignment?.find(
            (compliance) =>
              compliance.activityResultsId === activityResult.id &&
              (compliance.isMSSAligned === false || compliance.isMSSCompleted === false)
          );
          if (!!isMSSNotCompliant) {
            return {
              total: agg.total + activityResult.financials[section].total,
              aligned: agg.aligned,
            };
          }
          const containsNonAdaptationObjective =
            activityResult?.objectivesState?.substantialContributionObjectives?.some(
              (objective: ObjectiveKeyEnum) => allNonAdaptationObjectives.includes(objective)
            );
          if (
            isNotAdaptationObjective(scObjectiveKey) &&
            activityResult?.objectivesState?.substantialContributionObjectives?.includes(
              ObjectiveKeyEnum.adaptation
            ) &&
            (section === ScoreSectionsEnum.capex || section === ScoreSectionsEnum.opex)
          ) {
            return {
              total: agg.total + activityResult?.financials[section].total,
              aligned: countAligned
                ? agg.aligned +
                  (activityResult?.financials[section].aligned -
                    activityResult?.financials[section].adaptation)
                : agg.aligned,
            };
          }
          if (
            scObjectiveKey == ObjectiveKeyEnum.adaptation &&
            activityResult?.objectivesState?.substantialContributionObjectives?.includes(
              ObjectiveKeyEnum.adaptation
            ) &&
            containsNonAdaptationObjective
          ) {
            return {
              total: agg.total + activityResult?.financials[section].total,
              aligned: agg.aligned + activityResult?.financials[section].adaptation,
            };
          }
          return {
            total: agg.total + activityResult?.financials[section].total,
            aligned: countAligned
              ? agg.aligned + activityResult?.financials[section].aligned
              : agg.aligned,
          };
        },
        { total: 0, aligned: 0 }
      );
      return {
        ...scorePerSection,
        [section]: {
          result: numberOrZero(
            (aligned /
              (totalOverride?.[section]
                ? eligible
                  ? totalOverride?.[section]?.eligible
                  : totalOverride?.[section]?.total
                : total)) *
              100
          ),
        },
      };
    },
    { revenue: { result: 0 }, capex: { result: 0 }, opex: { result: 0 } }
  );
};

export const getSubstantialContributionColumn = (
  filteredBusinessUnitResults: BusinessUnitAssessmentResults[],
  companyResults?: CompanyAssessmentResults,
  aggregatedFinancials?: CalculatedActivityResult['financials'],
  scKey?: ObjectiveId | undefined
) => {
  if (typeof scKey == undefined) {
    return {
      total: null,
      eligible: null,
      nonEligible: null,
    };
  }
  const activities = filteredBusinessUnitResults.flatMap((businessUnitResults) =>
    businessUnitResults.activityResults
      .map((activityResult) => activityResult.cachedResult)
      .filter((activityResult): activityResult is CachedResultFieldsFragment_ => !!activityResult)
  );

  if (activities.length === 0) {
    return {
      total: { revenue: { result: 0 }, capex: { result: 0 }, opex: { result: 0 } },
      eligible: { revenue: { result: 0 }, capex: { result: 0 }, opex: { result: 0 } },
      nonEligible: null,
    };
  }

  const activitiesByReference = groupBy(activities, 'activityRef');

  const mssAlignments = getActivitiesListWithMSSAlignment(
    filteredBusinessUnitResults,
    companyResults
  );

  const activityScScores = mapValues(activitiesByReference, (activityResult) =>
    getAlignment(activityResult, scKey, null, mssAlignments)
  );

  const eligibleScScore = getAlignment(
    activities,
    scKey,
    aggregatedFinancials,
    mssAlignments,
    true
  );

  const totalScScore = getAlignment(activities, scKey, aggregatedFinancials, mssAlignments);

  return {
    total: totalScScore,
    eligible: eligibleScScore,
    nonEligible: null,
    ...activityScScores,
  };
};

export const transformFinancialsProportions = (
  proportions:
    | {
        total: null;
        eligible: null;
        nonEligible: null;
      }
    | {
        total: FinancialResult;
        eligible: FinancialResult;
        nonEligible: null;
      }
) => ({
  revenue: Number(proportions.total?.revenue.result).toFixed(2),
  capex: Number(proportions.total?.capex.result).toFixed(2),
  opex: Number(proportions.total?.opex.result).toFixed(2),
});

export const getSubstantialContributionProportion = (result: CompanyAssessmentResults) => {
  const substantialContributionProportion = Object.values(ObjectiveKeyEnum).reduce(
    (objectiveSCs, currentObjective) => {
      const proportions = getSubstantialContributionColumn(
        result.businessUnitResults,
        result,
        result.cachedResult?.financials,
        currentObjective
      );

      return { ...objectiveSCs, [currentObjective]: transformFinancialsProportions(proportions) };
    },
    {} as Record<ObjectiveKeyEnum, { revenue: number; opex: number; capex: number }>
  );
  return substantialContributionProportion;
};
