import { extendTheme } from '@chakra-ui/react';
import '@fontsource/open-sans/700.css';
import '@fontsource/open-sans/600.css';
import '@fontsource/open-sans/500.css';
import '@fontsource/open-sans/400.css';
import '@fontsource/open-sans/300.css';
import '@fontsource/inter/700.css';
import '@fontsource/inter/600.css';
import '@fontsource/inter/500.css';
import '@fontsource/inter/400.css';
import '@fontsource/inter/300.css';
import {
  CardTheme,
  InputTheme,
  NumberInputTheme,
  RadioTheme,
  TagTheme,
  CheckboxTheme,
  TabsTheme,
  ButtonTheme,
  AvatarTheme,
  AlertTheme,
  AccordionTheme,
  TextareaTheme,
} from 'Atoms';
import { NoteTheme, ModalTheme } from 'Molecules';
import { TypographyTheme, fonts, fontSizes, lineHeights, colors } from 'Tokens';
import { MenuTheme } from 'Molecules/Menu';
import { TooltipTheme } from 'Atoms/Tooltip';
import { RadioCardGroupTheme } from 'Molecules/RadioCardGroup';
import { InputCardTheme } from 'Molecules/InputCard';

export const spacing: { [key: string]: string } = {
  xxs: '4px',
  xs: '8px',
  sm: '16px',
  md: '24px',
  lg: '48px',
  xl: '96px',
};

export const breakpoints: { [key: string]: string } = {
  base: '0',
  lg: '1040px',
};

export const shadows: { [key: string]: string } = {
  xs: '0px 0px 0px 1px #0000000D',
  sm: '0px 1px 2px 0px #0000000F, 0px 0px 3px 0px #0000001A ',
  md: '0px 2px 4px -1px #0000000F, 0px 0px 6px -1px #0000001A',
  lg: '0px 4px 6px -2px #0000000D, 0px 0px 15px -3px #0000001A',
  outline: '0 0 0 2px #8787FF',
  'non.interactive': '0px 0px 0px 1px var(--chakra-colors-misc-shadow-border) inset',
  interactive:
    '0px 6px 8px -2px var(--chakra-colors-misc-shadow-default), 0px 0px 0px 1px var(--chakra-colors-misc-shadow-border) inset',
  'interactive.accent':
    '0px 2px 15px -3px var(--chakra-colors-misc-shadow-accent), 0px 6px 8px -2px var(--chakra-colors-misc-shadow-default), 0px 0px 0px 1px var(--chakra-colors-misc-shadow-border) inset',
  'interactive.hover':
    '0px 4px 24px -4px var(--chakra-colors-misc-shadow-hover), 0px 0px 0px 1px var(--chakra-colors-misc-shadow-border) inset',
  overlay:
    '0px 0px 24px -2px var(--chakra-colors-misc-shadow-accent), 0px 6px 8px -2px var(--chakra-colors-misc-shadow-default), 0px 0px 0px 1px var(--chakra-colors-misc-shadow-border) inset',
};

export const theme = extendTheme({
  semanticTokens: {
    colors: colors,
  },
  space: spacing,
  config: {
    disableTransitionOnChange: false,
    useSystemColorMode: true,
    initialColorMode: 'light',
  },
  lineHeights: lineHeights,
  fonts: fonts,
  fontSizes: fontSizes,
  shadows: shadows,
  breakpoints: breakpoints,
  components: {
    Alert: AlertTheme,
    Text: TypographyTheme,
    Button: ButtonTheme,
    Checkbox: CheckboxTheme,
    Radio: { ...RadioTheme, variants: { ...RadioCardGroupTheme.variants } },
    Tag: TagTheme,
    Input: InputTheme,
    NumberInput: NumberInputTheme,
    Card: CardTheme,
    Tabs: TabsTheme,
    Tags: TagTheme,
    Avatar: AvatarTheme,
    Modal: ModalTheme,
    Note: NoteTheme,
    Menu: MenuTheme,
    Accordion: AccordionTheme,
    Tooltip: TooltipTheme,
    InputCard: InputCardTheme,
    Textarea: TextareaTheme,
  },
});
