import { ActivityResults, ObjectiveKeyEnum } from 'models';
import { RowFormat, SCEligibility } from './table-generator';
import { ResultsByActivityAndSC, getProportion } from './TaxonomyTableGenerator.hooks';
import {
  calculateEnablingAdaptationSubstantialContribution,
  getEligibleAlignedAbsolute,
  getActivityName,
  getCode,
  getEligibleNotAlignedAbsolute,
  SubstantialContribution,
  calculateAdaptationOnlySubstantialContribution,
  calculateNonAlignedSubstantialContribution,
  calculateAlignedSubstantialContribution,
  checkEnabling,
  checkTransitional,
  getContribution,
} from './TaxonomyTableActivitiesUtils';

const calculateSubstantialContribution = (
  results: ActivityResults[],
  type: string,
  aligned: boolean,
  total: number,
  rowObjective: string,
  activityRef: string
): SubstantialContribution => {
  if (aligned === false) {
    return calculateNonAlignedSubstantialContribution(results, type, total);
  } else if (rowObjective === 'adaptationOnlyEnabling') {
    return calculateEnablingAdaptationSubstantialContribution(results, type, total);
  } else if (rowObjective === 'adaptationOnly') {
    return calculateAdaptationOnlySubstantialContribution(results, type, total);
  } else {
    return calculateAlignedSubstantialContribution(results, type, total, rowObjective, activityRef);
  }
};

export const getEligibleAlignedActivities = (
  results: ResultsByActivityAndSC,
  type: string,
  activities: any,
  total?: number
): RowFormat[] => {
  const eligibleAlignedActivities: RowFormat[] = [];

  Object.entries(results).forEach(([activityRef, resultsBySC]) => {
    Object.entries(resultsBySC).forEach(([objective, activityResults]) => {
      if (
        ((objective === ObjectiveKeyEnum.adaptation || objective === 'adaptationEnabling') &&
          type === 'revenue') ||
        objective === 'notAligned'
      ) {
        return;
      }
      const absolute = getEligibleAlignedAbsolute(activityResults, type, objective);
      const proportion = getProportion(absolute, total);
      if (absolute !== 0) {
        const substantialContributions = calculateSubstantialContribution(
          activityResults,
          type,
          true,
          total ?? 0,
          objective,
          activityRef
        );

        const nonAlignedSubstantialContributions = calculateSubstantialContribution(
          activityResults,
          type,
          false,
          total ?? 0,
          objective,
          activityRef
        );

        const newRow: RowFormat = {
          activityName: getActivityName(activityRef, activities),
          code: getCode(activityRef, activities, substantialContributions, type, activityResults),
          absolute,
          proportion,
          mitigationSC: getContribution(
            substantialContributions.mitigation,
            nonAlignedSubstantialContributions.mitigation
          ),
          adaptationSC: getContribution(
            substantialContributions.adaptation,
            nonAlignedSubstantialContributions.adaptation
          ),
          waterSC: getContribution(
            substantialContributions.water,
            nonAlignedSubstantialContributions.water
          ),
          circularSC: getContribution(
            substantialContributions.circular,
            nonAlignedSubstantialContributions.circular
          ),
          pollutionSC: getContribution(
            substantialContributions.pollution,
            nonAlignedSubstantialContributions.pollution
          ),
          biodiversitySC: getContribution(
            substantialContributions.biodiversity,
            nonAlignedSubstantialContributions.biodiversity
          ),
          mitigationDNSH: true, // aligned activities never do significant harm
          adaptationDNSH: true,
          waterDNSH: true,
          circularDNSH: true,
          pollutionDNSH: true,
          biodiversityDNSH: true,
          minimumSafeguards: true,
          enabling: checkEnabling(objective),
          transitional: checkTransitional(objective),
        };
        eligibleAlignedActivities.push(newRow);
      }
    });
  });
  return eligibleAlignedActivities;
};

export const getEligibleNotAlignedActivities = (
  results: ResultsByActivityAndSC,
  type: string,
  activities: any,
  total?: number
): RowFormat[] => {
  const eligibleNotAlignedActivities: RowFormat[] = [];
  Object.entries(results).forEach(([activityRef, resultsBySC]) => {
    Object.entries(resultsBySC).forEach(([objective, activityResults]) => {
      const absolute = getEligibleNotAlignedAbsolute(activityResults, type);

      if (absolute > 0) {
        const substantialContributions = calculateSubstantialContribution(
          activityResults,
          type,
          false,
          total ?? 0,
          objective,
          activityRef
        );
        const newRow: RowFormat = {
          activityName: getActivityName(activityRef, activities),
          absolute,
          code: getCode(activityRef, activities, substantialContributions, type, activityResults),
          proportion: getProportion(absolute, total),
          mitigationSC: substantialContributions.mitigation ?? SCEligibility.nonEligible,
          adaptationSC: substantialContributions.adaptation ?? SCEligibility.nonEligible,
          waterSC: substantialContributions.water ?? SCEligibility.nonEligible,
          circularSC: substantialContributions.circular ?? SCEligibility.nonEligible,
          pollutionSC: substantialContributions.pollution ?? SCEligibility.nonEligible,
          biodiversitySC: substantialContributions.biodiversity ?? SCEligibility.nonEligible,
        };
        const existingRow = eligibleNotAlignedActivities.find(
          (activity) => activity.activityName === newRow.activityName
        );
        if (!!existingRow) {
          existingRow.absolute += newRow.absolute;
          existingRow.proportion += newRow.proportion;
        } else {
          eligibleNotAlignedActivities.push(newRow);
        }
      }
    });
  });
  return eligibleNotAlignedActivities;
};
