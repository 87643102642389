import { Circle, createIcon, IconProps } from '@chakra-ui/react';

export type IconComponentType = ReturnType<typeof createIcon>;
export function SquareIconWrapper({
  icon: IconComponent,
  color = 'white',
  size = '28px',
  borderRadius = '6px',
  bg = '#161625;',
  scale = 12 / 22,
}: {
  icon: IconComponentType;
  size?: string;
  color?: IconProps['color'];
  borderRadius?: string;
  bg?: IconProps['bg'];
  scale?: number;
}) {
  return (
    <Circle bg={bg} color={color} borderRadius={borderRadius} size={size}>
      <IconComponent boxSize={`${scale * 100}%`} color="white" />
    </Circle>
  );
}
