import { Checkbox, TruncatableText } from 'Atoms';
import { HStack } from '@chakra-ui/react';
import { Typography } from 'Tokens';
import { Table } from 'Molecules';
import { ColumnDef } from '@tanstack/react-table';
import { stringToColor } from 'utils/generateRandomColors';
import { ChartTableType, FiltersType } from '../MetricChart.hooks';
import { Dispatch, SetStateAction } from 'react';

export const MetricChartTable = ({
  tableData,
  filters,
  selectedTagValues,
  tagValues,
  setSelectedTagValues,
}: {
  tableData: ChartTableType[] | undefined;
  filters: FiltersType;
  selectedTagValues: string[];
  tagValues: string[];
  setSelectedTagValues: Dispatch<SetStateAction<string[]>>;
}) => {
  const handleSelectCheckbox = (isSelected: boolean, item: string) => {
    if (isSelected) setSelectedTagValues((cur) => [...cur, item]);
    else setSelectedTagValues((cur) => cur.filter((tag) => tag !== item));
  };

  return (
    <Table
      columns={
        [
          {
            header: () => (
              <HStack>
                {filters.tagBreakdown && (
                  <Checkbox
                    isChecked={selectedTagValues.length === tagValues?.length}
                    onChange={() => {
                      if (selectedTagValues.length === tagValues?.length) setSelectedTagValues([]);
                      else setSelectedTagValues(tagValues);
                    }}
                  />
                )}
                <Typography variant="bodyStrong">Name</Typography>
              </HStack>
            ),
            accessorKey: 'name',
            cell: ({ row }) => (
              <HStack>
                {filters.tagBreakdown && (
                  <Checkbox
                    isChecked={!!selectedTagValues.find((tag) => row.original.name === tag)}
                    onChange={(e) =>
                      handleSelectCheckbox(e.currentTarget.checked, row.original.name)
                    }
                    variant={stringToColor(row.original.name)}
                  />
                )}
                <TruncatableText variant="bodyStrong" text={row.original.name} w="100%" />
              </HStack>
            ),
          },
          {
            header: 'unit',
            accessorKey: 'unit',
            cell: ({ row }) => <Typography variant="body">{row.original.unit}</Typography>,
          },
          {
            header: 'total',
            accessorKey: 'value',
            cell: ({ row }) => <Typography variant="body">{row.original.value}</Typography>,
          },
        ] as ColumnDef<ChartTableType>[]
      }
      data={tableData ?? []}
      headerPadding="8px"
      cellPadding="12px 8px"
      mt="16px"
    />
  );
};
