import { useMemo } from 'react';
import {
  AggregatedMetricsTableData,
  AggregatedQualitativeAnswers,
  isFullMetricOnBULevel,
  isFullMetricOnSubLevel,
  isFullMetricQuarterlyLevel,
} from '../../../AggregatedMetrics';

export const useCheckProgress = (
  row: AggregatedMetricsTableData,
  answersData?: AggregatedQualitativeAnswers,
  companyReportingUnit?: string
) => {
  const isAggregated = useMemo(() => {
    return !!row?.subRows?.length;
  }, [row]);
  const materialMetric = useMemo(() => row.metric.materialMetrics?.[0], [row]);
  const isSubsidiaries = useMemo(() => isFullMetricOnSubLevel(row.metric), [materialMetric]);
  const isBusinessUnits = useMemo(() => isFullMetricOnBULevel(row.metric), [materialMetric]);
  const isYearly = useMemo(() => !isFullMetricQuarterlyLevel(row.metric), [materialMetric]);

  const numberOfBUs = useMemo(
    () =>
      answersData?.find((data) => data.metricRef === row.metric?.reference)?.reportingUnits
        ?.length ?? 1 - 1,
    [answersData]
  );

  const numberOfAnsweredBUs = useMemo(
    () =>
      answersData
        ?.find((data) => data.metricRef === row.metric?.reference)
        ?.reportingUnits?.filter((bu) => !!bu.answer).length ?? 1 - 1,
    [answersData]
  );

  const numberOfSubs = useMemo(
    () =>
      answersData?.find((data) => data.metricRef === row.metric?.reference)?.subsidiaries?.length ??
      0,
    [answersData]
  );

  const numberOfAnsweredSubs = useMemo(
    () =>
      answersData
        ?.find((data) => data.metricRef === row.metric?.reference)
        ?.subsidiaries?.filter(
          (sub) => !!sub.answer || sub.reportingUnits?.some((ru) => !!ru.answer)
        ).length ?? 0,
    [answersData]
  );

  const dataPointsPerQuarter = useMemo(
    () =>
      Object.entries(row.result ?? {})
        .filter(([key]) => key !== 'Year')
        .map(([key, value]) => ({
          field: key.toUpperCase(),
          value: value,
        })),
    [row]
  );

  if (isAggregated) {
    let total = 0;
    if (!!row.subRows) {
      // This should be fixed asap!!
      row.subRows.forEach((subRow) => {
        total += useCheckProgress(subRow, answersData, companyReportingUnit);
      });
      return Math.floor(total / row.subRows.length);
    }
  }

  if (isBusinessUnits) {
    if (isYearly) {
      if (row.result?.Year !== 0) return (numberOfAnsweredBUs / numberOfBUs) * 100;
      else return 0;
    }
  }

  if (isSubsidiaries) {
    if (isYearly) {
      if (row.result?.Year !== 0) return Math.floor((numberOfAnsweredSubs / numberOfSubs) * 100);
      else return 0;
    }
  }
  const isAssessedYearly = row.result?.Year ? true : false;
  const assessedQuarters = isAggregated
    ? []
    : dataPointsPerQuarter.filter((item) => item.value !== 0);
  return isYearly ? (isAssessedYearly ? 100 : 0) : (assessedQuarters.length * 100) / 4;
};
