import { Box, Center, HStack, Image, RadioGroup, VStack } from '@chakra-ui/react';
import { Radio, Tag, Tooltip } from 'Atoms';
import {
  GetAssessmentEsrs2MaterialMetricsQuery_,
  GetOnboardingEsrsAssessmentDocument_,
  GetOnboardingEsrsAssessmentQuery_,
  useUpdateEsrs2MaterialMetricsMutation,
  useUpdateEsrs2MaterialStandardMutation,
  useUpdateSubsidiaryDataCollectionTypeMutation,
} from 'models';
import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Typography } from 'Tokens';
import {
  BusinessUnitsIcon,
  ChartHistogramIcon,
  CompanyIcon,
  FinancesIcon,
  Hierarchy3Icon,
  ListDetailsIcon,
} from 'Tokens/Icons/Data';
import { CompanyType, getCompanyType, useCurrentCompany } from '../../../../utils/hooks';

export enum SubsidiaryCollectionType {
  dependent = 'dependent',
  independent = 'independent',
}

const parentOnboardingSteps = [
  {
    title: 'Set company structure',
    description: 'Define the company structure by adding subsidiaries.',
    icon: <Hierarchy3Icon />,
  },
  {
    title: 'Materiality assessment',
    description:
      'It is possible to perform a materiality assessment on group level, on subsidiary levels or both. If the group is to perform a materiality assessment, we normally advise this to be the first step of the process.',
    icon: <ListDetailsIcon />,
  },
  {
    title: 'Data collection setup',
    description:
      'Decide which data points to collect from different parts of the company, and choose the data collection frequency and disaggregation of data points.',
    icon: <BusinessUnitsIcon />,
  },
  {
    title: 'Collect data',
    description:
      'Populate the selected data points in the report to ensure comprehensive and accurate reporting.',
    icon: <ChartHistogramIcon />,
  },
  {
    title: 'Use results',
    description:
      'Download your report following the CSRD requirements to use in external reporting.',
    icon: <FinancesIcon />,
  },
];

const companyOnboardingSteps = [
  {
    title: 'Set company structure',
    description: 'Define the company structure by adding business units if needed. ',
    icon: <Hierarchy3Icon />,
  },
  {
    title: 'Materiality assessment',
    description:
      'Materiality assessment is performed on company level. We normally advise this to be the first step of the process.',
    icon: <ListDetailsIcon />,
  },
  {
    title: 'Data collection setup',
    description:
      'Decide which data points to collect from different parts of the company, and choose the data collection frequency and disaggregation of data points.',
    icon: <BusinessUnitsIcon />,
  },
  {
    title: 'Collect data',
    description:
      'Populate the selected data points in the report to ensure comprehensive and accurate reporting.',
    icon: <ChartHistogramIcon />,
  },
  {
    title: 'Use results',
    description:
      'Download your report following the CSRD requirements to use in external reporting.',
    icon: <FinancesIcon />,
  },
];

const subsidiaryIndependentOnboardingSteps = [
  {
    title: 'Conduct double materiality assessment',
    description:
      'Perform a materiality assessment to be able to define material ESRS standards, disclosure requirements and metrics',
  },
  {
    title: 'Define how you want to gather reporting data',
    description:
      'Decide which data points to collect from different parts of the company and choose the data collection frequency.',
  },
  {
    title: 'Assign responsibilities and collect data',
    description:
      'Delegate responsibilities by assigning metrics to other departments and people. Define targets and action plans using relevant sustainability indicators to track progress. Track report completion and identify data gaps.',
  },
  {
    title: 'Review your report and use results',
    description:
      'Download your report following the CSRD requirements to use in external reporting.',
  },
];

const subsidiaryDependentOnboardingSteps = [
  subsidiaryIndependentOnboardingSteps[1],
  subsidiaryIndependentOnboardingSteps[2],
];

const ParentStep = ({
  title,
  description,
  icon,
}: {
  title: string;
  description: string;
  icon: JSX.Element;
}) => (
  <HStack spacing="16px" alignItems="flex-start">
    <Box p="16px 16px 12px" bg="bg.info.muted" borderRadius="12px" height="fit-content">
      {icon}
    </Box>
    <VStack spacing="2px" alignItems="start">
      <Typography variant="h3">{title}</Typography>
      <Typography variant="body" color="text.muted">
        {description}
      </Typography>
    </VStack>
  </HStack>
);

const SubsidiaryStep = ({
  title,
  description,
  index,
}: {
  title: string;
  description: string;
  index: number;
}) => (
  <VStack spacing="4px" alignItems="start">
    <Typography variant="h4">
      {index}. {title}
    </Typography>
    <Typography variant="body" color="text.muted">
      {description}
    </Typography>
  </VStack>
);

export const AboutEsrs = ({
  esrsAssessment,
  setNextDisabled,
  esrs2MaterialMetrics,
}: {
  esrsAssessment: GetOnboardingEsrsAssessmentQuery_['esrsAssessment'];
  setNextDisabled: (param: boolean) => void;
  esrs2MaterialMetrics: GetAssessmentEsrs2MaterialMetricsQuery_ | undefined;
}) => {
  const { company } = useCurrentCompany();
  const companyType = getCompanyType(company);

  const { esrsAssessmentId = '' } = useParams();
  const [showCollectData, setShowCollectData] = useState(true);
  const [subsidiaryType, setSubsidiaryType] = useState<SubsidiaryCollectionType | undefined>();
  const [updateType] = useUpdateSubsidiaryDataCollectionTypeMutation();
  const [updateEsrs2Metrics] = useUpdateEsrs2MaterialMetricsMutation();
  const [updateESRS2MaterialStandard] = useUpdateEsrs2MaterialStandardMutation();

  const parentAssessment = useMemo(() => esrsAssessment?.parentAssessment, [esrsAssessment]);

  useEffect(() => {
    if (!esrsAssessment?.onboardingStep && !!parentAssessment) setNextDisabled(true);
  }, []);

  useEffect(() => {
    if (!!esrsAssessment?.onboardingStep)
      setSubsidiaryType(
        esrsAssessment?.collectOnly
          ? SubsidiaryCollectionType.dependent
          : SubsidiaryCollectionType.independent
      );
  }, [esrsAssessment]);

  const handleSwitchType = (type: SubsidiaryCollectionType) => {
    const materialStandardId =
      esrs2MaterialMetrics?.EsrsAssessment_by_pk?.materialStandards?.[0]?.id;
    const materialMetrics =
      esrs2MaterialMetrics?.EsrsAssessment_by_pk?.materialStandards?.[0]?.materialMetrics?.map(
        (mm) => mm.id
      );
    updateType({
      variables: {
        id: esrsAssessmentId,
        collectOnly: type === SubsidiaryCollectionType.dependent,
      },
      refetchQueries: [GetOnboardingEsrsAssessmentDocument_],
    }).then(() => {
      if (!!materialMetrics?.length) {
        const isMaterial = type === SubsidiaryCollectionType.dependent ? null : true;
        updateESRS2MaterialStandard({
          variables: {
            materialStandardId: materialStandardId,
            isMaterial,
          },
        });
        updateEsrs2Metrics({
          variables: {
            materialMetricsIds: materialMetrics,
            isMaterial,
          },
        });
      }
      setNextDisabled(false);
      setSubsidiaryType(type);
    });
  };

  return (
    <VStack spacing="0px" pb="24px">
      <VStack spacing="32px" alignItems="start">
        <Typography variant="h1">Learn about ESRS</Typography>
        <VStack alignItems="start">
          {!!parentAssessment ? (
            <Typography
              variant="h2"
              borderBottom="1px solid"
              borderColor="border.decorative"
              width="100%"
              pb="4px"
            >
              General info
            </Typography>
          ) : (
            <Typography variant="h2">General info</Typography>
          )}
          <Typography variant="body" color="text.muted">
            Welcome to the Celsia ESRS reporting process. The onboarding flow will take you through
            the first steps of setting up your company structure and choosing material topics.
          </Typography>
        </VStack>
        {!!parentAssessment ? (
          <VStack spacing="32px" alignItems="start">
            <VStack alignItems="start">
              <VStack
                alignItems="start"
                spacing="0px"
                borderBottom="1px solid"
                borderColor="border.decorative"
                width="100%"
                pb="8px"
              >
                <HStack w="100%" justifyContent="space-between">
                  <VStack alignItems="start" spacing="0px">
                    <Typography variant="h2">
                      {parentAssessment.company.name} sustainability report
                    </Typography>
                    <Typography variant="body">Parent company</Typography>
                  </VStack>
                  {parentAssessment.company.logoUrl ? (
                    <Tooltip label={parentAssessment.company.name}>
                      <Image
                        src={parentAssessment.company.logoUrl}
                        w="40px"
                        h="40px"
                        borderRadius="6px"
                      />
                    </Tooltip>
                  ) : (
                    <Tooltip label={parentAssessment.company.name}>
                      <Center h="40px" w="40px" borderRadius="6px" bgColor="bg.accent">
                        <CompanyIcon color="white" boxSize="16px" />
                      </Center>
                    </Tooltip>
                  )}
                </HStack>
              </VStack>

              <Typography variant="body">
                Your company will report as part of the group. This means you would need to collect
                data needed for your parent company's report.
              </Typography>
              <Typography variant="body">
                Additionally, you can choose to report on your own. Learn more about your options
                below:
              </Typography>
            </VStack>
            <VStack alignItems="start" spacing="16px" width="100%">
              <Typography
                variant="h2"
                borderBottom="1px solid"
                borderColor="border.decorative"
                width="100%"
                pb="4px"
              >
                Your steps
              </Typography>
              <Typography variant="body">
                See how your further steps would be affected if you wish to prepare your own report
                (switch between options below):
              </Typography>
              <HStack>
                <Tag
                  title="Only collect data"
                  isSelected={showCollectData ? true : false}
                  size="md"
                  onClick={() => setShowCollectData(true)}
                  clickable
                />
                <Tag
                  title="Prepare own report"
                  isSelected={showCollectData ? false : true}
                  size="md"
                  onClick={() => setShowCollectData(false)}
                  clickable
                />
              </HStack>
              <VStack alignItems="start" marginTop="8px" spacing="16px">
                {(showCollectData
                  ? subsidiaryDependentOnboardingSteps
                  : subsidiaryIndependentOnboardingSteps
                ).map((step, index) => (
                  <SubsidiaryStep
                    title={step.title}
                    description={step.description}
                    index={index + 1}
                  />
                ))}
              </VStack>
            </VStack>
            <VStack alignItems="start" spacing="16px" width="100%">
              <Typography
                variant="h2"
                borderBottom="1px solid"
                borderColor="border.decorative"
                width="100%"
                pb="4px"
              >
                {esrsAssessment?.company.name} own report
              </Typography>
              <VStack alignItems="start" spacing="10px" width="100%">
                <Typography variant="bodyStrong" width="100%">
                  Would your company prepare own report?
                </Typography>
                <RadioGroup onChange={handleSwitchType} value={subsidiaryType}>
                  <VStack alignItems="start" spacing="12px">
                    <Radio value={SubsidiaryCollectionType.dependent}>
                      <Typography variant="body">No, only collect data</Typography>
                    </Radio>
                    <Radio value={SubsidiaryCollectionType.independent}>
                      <Typography variant="body">Prepare own report</Typography>
                    </Radio>
                  </VStack>
                </RadioGroup>
              </VStack>
            </VStack>
          </VStack>
        ) : (
          <VStack spacing="18px" alignItems="start">
            <Typography variant="h2">Steps</Typography>
            {(companyType === CompanyType.regular
              ? companyOnboardingSteps
              : parentOnboardingSteps
            ).map((step, index) => (
              <ParentStep
                key={index}
                title={step.title}
                description={step.description}
                icon={step.icon}
              />
            ))}
          </VStack>
        )}
      </VStack>
    </VStack>
  );
};
