import { HStack, VStack } from '@chakra-ui/react';
import { useEffect, useMemo } from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';
import { DateInput } from 'Atoms';
import { CheckIcon } from '@chakra-ui/icons';
import { Typography } from 'Tokens';
import { format } from 'date-fns';
import { useTranslation } from 'utils/translation';

type QuarterDateInputProps = Partial<UseFormReturn<any>> & {
  startDateFieldName: string;
  endDateFieldName: string;
  disabled?: boolean;
};
const QuarterPicker = ({
  startDateFieldName,
  endDateFieldName,
  control,
  resetField,
  watch,
  disabled,
}: QuarterDateInputProps) => {
  const startDate = watch?.(startDateFieldName) as Date;
  const { t } = useTranslation(['common']);

  const endDate = useMemo(() => {
    if (!startDate) return new Date();
    const tempDate = new Date(startDate);
    tempDate.setFullYear(tempDate.getFullYear() + 1);
    tempDate.setDate(tempDate.getDate() - 1);
    return tempDate;
  }, [startDate]);

  useEffect(() => {
    if (endDate) {
      resetField?.(endDateFieldName, { defaultValue: endDate });
    }
  }, [endDate]);

  return (
    <HStack>
      <Controller
        name={startDateFieldName}
        control={control}
        defaultValue={new Date()}
        render={({ field }) => {
          return (
            <VStack gap="4px" alignItems="start">
              <Typography variant="body">{t('common:starts')}</Typography>
              <DateInput
                disabled={disabled}
                calendarClassName="custom-quarter-calendar"
                width="220px"
                onYearChange={(date) => field.onChange(date)}
                value={field.value}
                setValue={(date) => {
                  if (date) field.onChange(date);
                }}
                showQuarterYearPicker
                renderQuarterContent={(quarter) => {
                  const date = new Date();
                  date.setFullYear(field.value.getFullYear());
                  date.setMonth(3 * (quarter - 1));
                  date.setDate(1);
                  const formattedDate = format(date, 'dd.MM.yyyy');
                  const formattedSelectedDate = format(field.value, 'dd.MM.yyyy');
                  const isSelected = formattedDate === formattedSelectedDate;
                  return (
                    <HStack w="100%" justifyContent="space-between" p="8px">
                      <Typography
                        color={isSelected ? 'text.selected' : 'text.muted'}
                        fontWeight={500}
                      >
                        {formattedDate}
                      </Typography>
                      {isSelected && <CheckIcon color="inherit" />}
                    </HStack>
                  );
                }}
                dateFormat="dd.MM.yyyy"
              />
            </VStack>
          );
        }}
      />
      <Controller
        name={endDateFieldName}
        control={control}
        defaultValue={endDate}
        render={({ field }) => (
          <VStack gap="4px" alignItems="start">
            <Typography variant="body">{t('common:ends')}</Typography>
            <DateInput
              disabled
              readOnly
              width="220px"
              value={field.value}
              setValue={(date) => date && field.onChange(date)}
              dateFormat="dd.MM.yyyy"
            />
          </VStack>
        )}
      />
    </HStack>
  );
};

export default QuarterPicker;
