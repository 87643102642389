import {
  AttachmentBox_Constraint_,
  AttachmentBox_Update_Column_,
  EsrsAssessmentDocument_,
  Esrs_MaterialMetric_Constraint_,
  Esrs_MaterialMetric_Update_Column_,
  GetMaterialStandardDocument_,
  NoteHistory_Constraint_,
  NoteHistory_Update_Column_,
  useEsrsAssessmentQuery,
  useGetEsrsCategoriesQuery,
  useGetParentMaterialityAssessmentQuery,
  MaterialStandardMaterialRequirementsDocument_,
  GetDisclosureRequirementGroupsDocument_,
  GetBuDisclosureRequirementMetricsDocument_,
  ReportingUnitsMetricsPerDisclosureDocument_,
  GetMetricsDrDocument_,
  useUpsertMultipleMaterialStandardsMutation,
  Esrs_MaterialTagValue_Constraint_,
  Esrs_MaterialMetricTag_Constraint_,
  Esrs_MaterialMetricTag_Update_Column_,
  Esrs_MaterialTagValue_Update_Column_,
  GetRequiredBuMaterialMetricsDocument_,
  GetRequiredMaterialMetricsDocument_,
} from 'models';
import { useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useCompanyType } from 'utils/hooks';
import { DataCollectionLevel } from '../DataCollection';
import { MaterialMap } from './MaterialityAssessment.d';
import { orderCategories } from 'containers/Esrs/utils';
import { ParentMaterialMetricsType } from './DoubleMaterialityAssessment/DoubleMaterialityAssessment.d';

export const isParentMaterial = (parentMetrics: ParentMaterialMetricsType) => {
  return parentMetrics?.some(
    (pm) =>
      pm.isMaterial &&
      pm.dataCollection === DataCollectionLevel.subsidiaries &&
      (pm.metric.childrenMetrics_aggregate.aggregate?.count ?? 0) === 0
  );
};

export const isParentMetricMaterial = (parentMetric: ParentMaterialMetricsType[number]) => {
  return (
    parentMetric.isMaterial &&
    parentMetric.dataCollection === DataCollectionLevel.subsidiaries &&
    (parentMetric.metric.childrenMetrics_aggregate.aggregate?.count ?? 0) === 0
  );
};

export const useGetMaterialityAssessment = (esrsAssessmentId?: string) => {
  const { data, loading: loadingAssessment } = useEsrsAssessmentQuery({
    variables: { esrsAssessmentId },
    fetchPolicy: 'network-only',
    skip: !esrsAssessmentId,
  });

  const { data: parentMaterialityAssessmentData, loading: loadingParentMaterialityAssessment } =
    useGetParentMaterialityAssessmentQuery({
      variables: { childAssessmentId: esrsAssessmentId },
      skip: !esrsAssessmentId,
    });

  const { data: esrsCategoriesData, loading: loadingCategories } = useGetEsrsCategoriesQuery();

  const categories = useMemo(() => {
    return orderCategories(esrsCategoriesData?.EsrsCategory ?? []);
  }, [esrsCategoriesData]);

  const materialityAssessments = useMemo(() => {
    const hasParentMaterialityAssessment =
      !!parentMaterialityAssessmentData?.EsrsAssessment_by_pk?.parentAssessment
        ?.materialStandards &&
      parentMaterialityAssessmentData?.EsrsAssessment_by_pk?.parentAssessment?.materialStandards
        ?.length > 0;
    if (data?.esrsAssessment?.materialStandards) {
      const mappedCategories: MaterialMap[] = categories.map((category) => {
        return {
          categoryName: category.title,
          categoryRef: category.reference,
          materialStandards:
            category.standards
              ?.map((standard) => {
                const materialStandard = data?.esrsAssessment?.materialStandards?.find((ms) => {
                  return ms.standardRef === standard.reference;
                });

                const parentMaterialMetrics =
                  parentMaterialityAssessmentData?.EsrsAssessment_by_pk?.parentAssessment?.materialStandards.find(
                    (ms) => {
                      return ms.standardRef === standard.reference;
                    }
                  )?.materialMetrics ?? [];

                const materialRequirements = [
                  ...new Set(
                    [
                      ...(materialStandard?.materialMetrics?.filter((mm) => mm.isMaterial) ?? []),
                      ...parentMaterialMetrics.filter((mm) => isParentMetricMaterial(mm)),
                    ].map((mm) => mm.metric.disclosureRequirementRef)
                  ),
                ];

                const drStatuses = (materialStandard?.disclosureRequirementStatuses ?? []).filter(
                  (dr) => {
                    return materialRequirements.includes(dr.disclosureRequirementRef);
                  }
                );

                const isDataCollected =
                  drStatuses.every((dr) => dr.isConfigured) &&
                  materialRequirements.length === drStatuses.length;

                const parentStandard =
                  parentMaterialityAssessmentData?.EsrsAssessment_by_pk?.parentAssessment?.materialStandards.find(
                    (ms) => {
                      return ms.standardRef === standard.reference;
                    }
                  );

                return {
                  id: materialStandard?.id,
                  standardRef: standard.reference,
                  standardName: standard.title,
                  description: standard.description,
                  isTopical: standard.isTopical,
                  disclosureRequirementGroups: standard.disclosureRequirementGroups,
                  isMaterial: materialStandard?.isMaterial,
                  isDataGatheringOnly: materialStandard?.isDataGatheringOnly,
                  isParentMaterial: parentStandard?.isMaterial,
                  isParentDataGathering: parentStandard?.isDataGatheringOnly,
                  metrics: materialStandard?.materialMetrics ?? [],
                  parentCompanyMetrics: parentMaterialMetrics,
                  isDataCollected: isDataCollected ?? false,
                };
              })
              ?.sort((a, b) => a.standardRef.localeCompare(b.standardRef)) ?? [],
          hasParentMaterialityAssessment,
        };
      });
      return mappedCategories;
    } else return [];
  }, [data, parentMaterialityAssessmentData, esrsCategoriesData]);

  const loading = useMemo(() => {
    return loadingAssessment || loadingCategories || loadingParentMaterialityAssessment;
  }, [loadingAssessment, loadingCategories, loadingParentMaterialityAssessment]);

  return {
    materialityAssessments,
    loading,
  };
};

export const useAddMandatoryMaterialityAssessment = () => {
  const { esrsAssessmentId } = useParams();
  const [addAssessment] = useUpsertMultipleMaterialStandardsMutation();
  const { companyType } = useCompanyType();
  const isGroupOwner = useMemo(() => companyType === 'group-company', [companyType]);

  return useCallback(
    (
      mandatoryStandards: {
        standardRef: string;
        metrics: MaterialMap['materialStandards'][number]['disclosureRequirementGroups'][number]['requirements'][number]['metrics'];
      }[]
    ) => {
      addAssessment({
        variables: {
          materialStandards: mandatoryStandards.map((standard) => ({
            assessmentId: esrsAssessmentId,
            standardRef: standard.standardRef,
            isMaterial: true,
            materialMetrics: {
              data:
                standard.metrics
                  ?.filter((m) => m.reference !== '')
                  .map((metric) => {
                    const metricTags =
                      metric?.tags.filter((tag) => tag.tagType.alwaysAll && !tag.isOptional) ?? [];
                    return {
                      isMaterial: true,
                      metricRef: metric.reference,
                      dataCollection: isGroupOwner
                        ? DataCollectionLevel.group
                        : DataCollectionLevel.company,
                      materialMetricTags: {
                        data: metricTags.map((tag) => ({
                          tagType: tag.type,
                          materialTagValues: {
                            data: tag.tagType.tagValues.map((val) => ({
                              tagType: tag.type,
                              tagValue: val.value,
                            })),
                            on_conflict: {
                              constraint:
                                Esrs_MaterialTagValue_Constraint_.MaterialTagValueMaterialMetricTagIdTagValueTagTypeKey_,
                              update_columns: [Esrs_MaterialTagValue_Update_Column_.TagValue_],
                            },
                          },
                        })),
                        on_conflict: {
                          constraint:
                            Esrs_MaterialMetricTag_Constraint_.MaterialMetricTagMaterialMetricIdTagTypeKey_,
                          update_columns: [Esrs_MaterialMetricTag_Update_Column_.TagType_],
                        },
                      },
                    };
                  }) ?? [],
              on_conflict: {
                constraint:
                  Esrs_MaterialMetric_Constraint_.MaterialMetricMaterialStandardIdMetricRefKey_,
                update_columns: [
                  Esrs_MaterialMetric_Update_Column_.Metadata_,
                  Esrs_MaterialMetric_Update_Column_.IsMaterial_,
                ],
              },
            },
            attachmentBox: {
              data: {},
              on_conflict: {
                constraint: AttachmentBox_Constraint_.AttachmentBoxMaterialityAssessmentIdKey_,
                update_columns: [AttachmentBox_Update_Column_.MaterialStandardId_],
              },
            },
            noteHistory: {
              data: {},
              on_conflict: {
                constraint: NoteHistory_Constraint_.NoteHistoryMaterialityAssessmentIdKey_,
                update_columns: [NoteHistory_Update_Column_.MaterialStandardId_],
              },
            },
          })),
        },
        refetchQueries: [
          EsrsAssessmentDocument_,
          GetMaterialStandardDocument_,
          MaterialStandardMaterialRequirementsDocument_,
          GetDisclosureRequirementGroupsDocument_,
          GetMetricsDrDocument_,
          GetBuDisclosureRequirementMetricsDocument_,
          GetMetricsDrDocument_,
          ReportingUnitsMetricsPerDisclosureDocument_,
          GetRequiredMaterialMetricsDocument_,
          GetRequiredBuMaterialMetricsDocument_,
        ],
      });
    },
    [addAssessment]
  );
};

export const getMandatoryStandardData = (materialityAssessments: MaterialMap[]) => {
  const mandatoryStandards =
    materialityAssessments?.flatMap((assessment) =>
      assessment.materialStandards?.filter((standard) => !standard.isTopical)
    ) ?? [];
  const standardDisclosures = mandatoryStandards?.map((standard) => {
    const disclosureRequirements = standard?.disclosureRequirementGroups?.flatMap(
      (drg) => drg?.requirements
    );

    const metrics = disclosureRequirements?.flatMap((req) => req.metrics) ?? [];

    return {
      standardRef: standard.standardRef,
      metrics: metrics,
    };
  });
  return standardDisclosures;
};
