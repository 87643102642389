import { Box, HStack, VStack } from '@chakra-ui/react';
import { ContentLayout, Loader } from 'Molecules';
import { useParams } from 'react-router-dom';
import { useLocalStorage } from 'usehooks-ts';
import { useMemo, useState } from 'react';
import { DRTypes, SidebarModes } from '../../Requirement';
import { TOP_MENU_HEIGHT } from 'containers/Navigation/pieces';
import { DisclosureRequirement } from './DisclosureRequirement';
import { DisclosureRequirementSidebar } from './DRPageComponents/DisclosureRequirementSidebar';
import { useGetDisclosureRequirementQuery } from 'models';
import { DRViews } from './DisclosureRequirement.utils';
import { EditableReport } from './DRPageComponents/EditableReport';
import { useGetAggregatedMetricsData } from '../AggregatedMetrics';
import { AssessableMetrics } from '../Metrics';
import { useESRSReportStructure } from 'containers/Esrs/pieces/Report/Report.hooks';

export const DisclosureRequirementPage = () => {
  const [sidebarMode, setSidebarMode] = useLocalStorage('metric-sidebar-mode', SidebarModes.open);
  const [selectedView, setSelectedView] = useState(DRViews.inputData);
  const [isMetricSidebarOpen, setMetricSidebarOpen] = useState(false);

  const {
    esrsAssessmentId = '',
    standardRef = '',
    disclosureRequirementRef = '',
    drType,
  } = useParams();

  const { standards, loading } = useESRSReportStructure(esrsAssessmentId);

  const { data: drData } = useGetDisclosureRequirementQuery({
    variables: {
      reference: disclosureRequirementRef,
    },
    skip: !disclosureRequirementRef,
  });
  const {
    metricDR,
    metrics,
    companyAssessmentId,
    companyLevelReportingUnitId,
    projectLeader,
    isGroup,
    dataLoading,
  } = useGetAggregatedMetricsData(false, true);

  const showReportPreview = useMemo(
    () =>
      standards
        .find((std) => std.reference === standardRef)
        ?.disclosureRequirementRefs.some((dr) => dr.reference === disclosureRequirementRef) ??
      false,
    [standards, standardRef, disclosureRequirementRef]
  );

  const disclosureRequirement = useMemo(() => drData?.DisclosureRequirement, [drData]);

  const isSidebarOpen = useMemo(() => sidebarMode === SidebarModes.open, [sidebarMode]);

  if (loading) return <Loader />;

  return (
    <HStack
      borderTopColor="border.decorative"
      height={`calc(100vh - ${TOP_MENU_HEIGHT})`}
      spacing="0px"
      bg="bg.default"
      position="relative"
      w="100%"
    >
      <DisclosureRequirementSidebar
        disclosureRequirement={disclosureRequirement}
        sidebarMode={sidebarMode}
        showReportPreview={showReportPreview}
        setSidebarMode={setSidebarMode}
        selectedView={selectedView}
        setSelectedView={setSelectedView}
      />
      <VStack
        height={`calc(100vh - ${TOP_MENU_HEIGHT})`}
        width={isSidebarOpen ? 'calc(100% - 288px)' : '100%'}
        overflow="hidden auto"
        boxSizing="border-box"
        transition="width 0.2s"
        ml={!isSidebarOpen ? '36px' : '0px'}
      >
        <ContentLayout
          header={false}
          variant="inline"
          paddingTop={selectedView === DRViews.previewReport ? '0px' : undefined}
          paddingBottom={selectedView === DRViews.previewReport ? '0px' : undefined}
          noPadding={selectedView === DRViews.previewReport}
        >
          <HStack gap="16px" position="relative" w="100%" h="100%">
            <Box
              height={
                selectedView !== DRViews.previewReport
                  ? `calc(calc(100vh - ${TOP_MENU_HEIGHT}) - 32px)`
                  : '100%'
              }
              overflowY="auto"
              marginRight="-16px"
              paddingRight={selectedView !== DRViews.previewReport ? '16px' : ''}
              w={selectedView !== DRViews.previewReport ? 'calc(100% + 16px)' : '100%'}
            >
              {selectedView === DRViews.inputData ? (
                <DisclosureRequirement
                  type={drType as DRTypes}
                  setMetricSidebarOpen={setMetricSidebarOpen}
                />
              ) : (
                <EditableReport
                  isLoading={dataLoading}
                  companyAssessmentId={companyAssessmentId}
                  companyLevelReportingUnitId={companyLevelReportingUnitId}
                  projectLeader={projectLeader}
                  isGroup={isGroup}
                  metricDR={metricDR}
                  standards={standards}
                  metrics={metrics as AssessableMetrics}
                />
              )}
            </Box>
            {isMetricSidebarOpen && <Box minW="336px"></Box>}
          </HStack>
        </ContentLayout>
      </VStack>
    </HStack>
  );
};
