import { useToast as useChakraToast } from '@chakra-ui/react';
import { Dispatch, SetStateAction, useMemo } from 'react';
import { getNameExtension } from 'utils/files';
import { useToast } from 'utils/hooks';
import { MetricsTableData } from '..';
import { AssessableMetrics } from '../Metrics';
import {
  useGenerateNumericAnswers,
  useGenerateNarrativeAnswers,
  useReadPdfText,
} from './AnswersGenerator.hooks';
import { GeneratedAnswer } from './MetricsAITypes';
import { nhost } from 'utils/nhost';
import { useTranslation } from 'utils/translation';
import { Attachment } from 'models';
import * as Excel from 'exceljs';
import { Button } from 'Atoms';

const ALLOWED_FILE_TYPES = ['pdf', 'xlsx'];

const readExcelFile = async (fileUrl: string, fileName: string): Promise<string> => {
  return new Promise(async (resolve, reject) => {
    const response = await fetch(fileUrl);
    if (!response.ok) {
      console.error(`Error fetching Excel file: ${response.status} - ${response.statusText}`);
      reject("Couldn't read excel file");
    }

    const blob = await response.blob();
    const xFile = new File([blob], fileName ?? '');

    const fileReader = new FileReader();

    fileReader.readAsArrayBuffer(xFile);

    fileReader.onload = async (e) => {
      const bufferArray = e.target?.result;

      if (bufferArray) {
        const workbook = new Excel.Workbook();
        await workbook.xlsx.load(bufferArray as ArrayBuffer);

        const worksheet = workbook.worksheets[0];

        const sheetValues = worksheet.getSheetValues();
        const excelData = sheetValues
          .filter((value) => value?.length ?? 0 > 0)
          .map((row) => {
            if (Array.isArray(row)) {
              const filteredRow = row.filter((v) => !!v);
              return filteredRow.join(': ');
            }
          });

        const excelDataString = excelData.join(', ');

        resolve(excelDataString);
      } else {
        reject("Couldn't read excel file");
      }
    };

    fileReader.onerror = (error) => {
      console.error(error);
      reject("Couldn't read excel file");
    };
  });
};

export const AnswersGeneratorButton = ({
  isDisabled,
  setGeneratedAnswers,
  numericMetrics,
  narrativeMetrics,
  abortControllerRef,
  attachment,
  isGeneratingAnswers,
  setIsGeneratingAnswers,
  initializeAbortController,
}: {
  isDisabled: boolean;
  setGeneratedAnswers: Dispatch<SetStateAction<GeneratedAnswer[]>>;
  numericMetrics: MetricsTableData[];
  narrativeMetrics: AssessableMetrics;
  abortControllerRef: React.RefObject<AbortController | null>;
  attachment?: Attachment;
  isGeneratingAnswers: boolean;
  setIsGeneratingAnswers: Dispatch<SetStateAction<boolean>>;
  initializeAbortController: () => void;
}) => {
  const { t } = useTranslation('ai');
  const toast = useToast();
  const chakraToast = useChakraToast();

  const file = useMemo(() => attachment?.file, [attachment]);
  const fileName = useMemo(() => file?.title ?? '', [file]);

  const fileType = useMemo(
    () => getNameExtension(file?.storageFile?.name ?? '').extension,
    [file?.storageFile?.name]
  );

  const { generateNumericAnswers } = useGenerateNumericAnswers({
    quantitativeMetrics: numericMetrics,
    abortControllerRef,
  });

  const { generateNarrativeAnswers } = useGenerateNarrativeAnswers({
    narrativeMetrics,
    abortControllerRef,
  });

  const { readPdfText } = useReadPdfText({
    abortControllerRef,
  });

  const extractAndGenerateAnswers = async () => {
    const preSignedUrl = await nhost.storage.getPresignedUrl({
      fileId: attachment?.file.storageFile?.id,
    });
    const fileUrl = preSignedUrl.presignedUrl?.url ?? '';

    if (!ALLOWED_FILE_TYPES.includes(fileType)) {
      return toast({
        text: t('pdfRestriction'),
        variant: 'danger',
      });
    }

    const isExcelFile = fileType === 'xlsx';

    const toastId = toast({
      text: t('esrs.answers.progress'),
      closable: true,
      duration: null,
    });

    try {
      setIsGeneratingAnswers(true);

      const getText = async () => {
        if (isExcelFile) {
          const excelResult = await readExcelFile(fileUrl, fileName);
          return excelResult;
        } else {
          const pdfResult = await readPdfText(fileUrl, fileName);
          return pdfResult;
        }
      };

      const result = await getText();

      if (result) {
        const narrativeAnswers = await generateNarrativeAnswers(result);
        const quantitativeAnswers = await generateNumericAnswers(result);

        setGeneratedAnswers(
          [...quantitativeAnswers, ...narrativeAnswers]
            .filter((answer) => !!answer)
            .map((answer) => ({
              ...answer,
              status: null,
            }))
        );

        setIsGeneratingAnswers(false);
        chakraToast.close(toastId);
      } else {
        setIsGeneratingAnswers(false);
        chakraToast.close(toastId);
        toast({
          variant: 'danger',
          text: t('esrs.errors.readFile'),
        });
      }
    } catch (error) {
      setIsGeneratingAnswers(false);
      setGeneratedAnswers([]);
      chakraToast.close(toastId);

      console.error('Error during answer generation', error);
      toast({
        variant: 'danger',
        text: t('esrs.errors.general'),
      });
    }
  };

  const handleGenerate = async () => {
    initializeAbortController();
    await extractAndGenerateAnswers();
  };

  return (
    <Button
      onClick={handleGenerate}
      isDisabled={isDisabled}
      variant="primary"
      size="md"
      isLoading={isGeneratingAnswers}
    >
      Run the AI
    </Button>
  );
};
