import { Box, HStack, VStack } from '@chakra-ui/react';
import { useUserData } from '@nhost/react';
import { IconButton, Button, Tooltip, VisibleIf } from 'Atoms';
import {
  Activity,
  AttachmentBox,
  GetActivityDocumentationDocument_,
  NoteHistory,
  useGetActivityAssessmentVersionQuery,
  useGetActivityDocumentationQuery,
} from 'models';
import { AlertDialog, Select } from 'Molecules';
import { InputCard, InputCardVariant } from 'Molecules/InputCard';
import { InputCardDocumentationProps } from 'Molecules/InputCard/InputCardDocumentation';
import { OBJECTIVE_VARIANTS, ObjectivesTiles, VariantType } from 'Molecules/ObjectivesTiles';
import { TruncatedTagList } from 'Molecules/TruncatedTagList';
import React, { useEffect, useMemo, useState } from 'react';
import { Typography } from 'Tokens';
import { DeleteIcon } from 'Tokens/Icons/Function';
import { CheckIcon } from 'Tokens/Icons/Status';
import { useTranslation } from 'utils/translation';
import {
  objectiveExpressionTag,
  useActivityTagObjectives,
  useObjectiveStatusHelpers,
} from '../Activities.hooks';
import { captureException } from '@sentry/browser';
import { useToast } from 'utils/hooks';
import { hasSupportRole } from 'utils/users.js';

export function ActivityNaceCodes({
  activity,
  truncate = false,
}: {
  activity: Activity;
  truncate?: boolean;
}) {
  const codes = activity.naceCodes.map((code) => code.code);
  return <TruncatedTagList tags={codes} lines={truncate ? 1 : 3} size="xs" />;
}

export type VariantEntries = [keyof VariantType, VariantType[keyof VariantType]][];

type VersionOptionType = {
  label: string;
  value: number;
};

export function ActivityCard({
  activity,
  activityReportId,
  isSelected = false,
  onChange,
  onLearnClicked,
  variant = 'default',
  removeActivity,
  openAttachmentDrawer,
}: React.PropsWithChildren<{
  activity: Activity;
  activityReportId?: string;
  isSelected?: boolean;
  onChange?: (checked: boolean, versionNumber?: number) => void;
  onLearnClicked: () => void;
  removeActivity?: () => void;
  variant?: InputCardVariant;
  openAttachmentDrawer: (box?: AttachmentBox) => void;
}>) {
  const { t } = useTranslation(['bUnits', 'common', 'assessment']);
  const user = useUserData();
  const isSupportUser = hasSupportRole(user?.roles);
  const [selected, setSelected] = useState(isSelected);
  const [showAlert, setShowAlert] = useState(false);
  const [selectedVersion, setSelectedVersion] = useState<number>();
  const toast = useToast();

  const { data: documentationData } = useGetActivityDocumentationQuery({
    variables: {
      activityReportId: activityReportId,
    },
    skip: !activityReportId,
  });

  const { data: versionData, loading: versionLoading } = useGetActivityAssessmentVersionQuery({
    variables: {
      activityAssessmentId: activityReportId,
    },
    skip: !isSupportUser || (isSupportUser && !activityReportId),
  });

  useEffect(() => {
    if (versionData?.activityAssessment?.activityVersionNumber) {
      setSelectedVersion(versionData.activityAssessment.activityVersionNumber);
    }
  }, [versionData]);

  if (!activity?.referenceNumber || !activity?.currentQuestionSetVersion) {
    captureException({
      extra: { errorMessage: 'Reference number or version number missing in activities' },
    });
    toast({
      text: t('assessment:activities.activitiesError'),
      variant: 'danger',
    });
    return null;
  }

  const { data } = useActivityTagObjectives(activity.reference, activity.currentQuestionSetVersion);

  const { getObjectiveStatusKey, getObjectiveStatusStyles } = useObjectiveStatusHelpers(activity);

  const attachmentBox: AttachmentBox | undefined = useMemo(
    () => documentationData?.ActivityReport_by_pk?.attachmentBox ?? undefined,
    [documentationData]
  );

  const noteHistory: NoteHistory | undefined = useMemo(
    () => documentationData?.ActivityReport_by_pk?.noteHistory ?? undefined,
    [documentationData]
  );

  function toggleSelected() {
    if (variant === 'selectable') {
      const selectedDebounce = setTimeout(() => {
        setSelected(!selected);
        if (onChange !== undefined) {
          onChange(!selected, selectedVersion);
        }
      }, 10);

      return () => clearTimeout(selectedDebounce);
    }
  }

  const showDocumentation: InputCardDocumentationProps | undefined = useMemo(() => {
    if (((variant === 'selectable' && selected) || variant === 'default') && activityReportId) {
      return {
        currentAuthor: user ?? undefined,
        attachmentBox: attachmentBox,
        noteHistory: noteHistory,
        openAttachmentDrawer: () => openAttachmentDrawer(attachmentBox),
        refetchQueries: [GetActivityDocumentationDocument_],
      };
    }
  }, [variant, selected, documentationData, attachmentBox, noteHistory, activityReportId]);

  return (
    <InputCard
      documentation={showDocumentation}
      variant={variant}
      {...{
        onClick: () => toggleSelected(),
        borderColor: selected ? 'border.selected.accent' : 'border.default-decorative',
        borderWidth: selected ? '2px' : '1px',
        _hover: {
          borderColor: selected
            ? 'border.selected.accent'
            : variant === 'selectable'
              ? 'border.hover'
              : 'border.default',
        },
        cursor: 'pointer',
      }}
    >
      <HStack alignItems="start" justifyContent="space-between" width="100%">
        {variant == 'selectable' && (
          <CheckIcon
            color={selected ? 'white' : 'transparent'}
            boxSize="16px"
            padding="2px"
            bg={selected ? 'bg.selected.accent' : 'bg.default'}
            borderRadius="3px"
            borderWidth="2px"
            borderColor="border.default"
            border={selected ? 'none' : ''}
            _hover={
              selected
                ? {
                    bg: 'bg.selected.accent',
                  }
                : {
                    bg: 'bg.default',
                    borderColor: 'border.hover',
                  }
            }
          />
        )}
        <VStack spacing="12px" width="100%" alignItems="start">
          <Typography>{activity.name}</Typography>
          <ActivityNaceCodes activity={activity} />
          <Box position="relative">
            <Tooltip
              bg="unset"
              padding="0px"
              boxShadow="0"
              label={
                <VStack
                  width="300px"
                  borderRadius="10px"
                  padding="8px"
                  gap="4px"
                  background="bg.elevated.overlay"
                  boxShadow="md"
                >
                  {(Object.entries(OBJECTIVE_VARIANTS) as VariantEntries).map(([key]) => {
                    return (
                      <ObjectivesTiles
                        size="sm"
                        variant={key}
                        objectiveStatus={getObjectiveStatusKey(key)}
                        tagStatus={
                          key === data.tagObjective
                            ? objectiveExpressionTag(data.tagExpression)
                            : ''
                        }
                      />
                    );
                  })}
                </VStack>
              }
            >
              <HStack margin="8px 0px">
                {(Object.entries(OBJECTIVE_VARIANTS) as VariantEntries).map(([key, obj]) => {
                  return key === data.tagObjective ? (
                    <HStack
                      background={getObjectiveStatusStyles(key).iconBgColor}
                      borderRadius="32px"
                      padding="4px 8px"
                      spacing="4px"
                    >
                      <obj.icon boxSize="16px" color={getObjectiveStatusStyles(key).iconColor} />
                      <Typography variant="detailStrong" color="text.info" fontWeight="500">
                        {objectiveExpressionTag(data.tagExpression)}
                      </Typography>
                    </HStack>
                  ) : (
                    <obj.icon
                      boxSize="16px"
                      background={getObjectiveStatusStyles(key).iconBgColor}
                      padding="4px"
                      borderRadius="50%"
                      w="24px"
                      h="24px"
                      color={getObjectiveStatusStyles(key).iconColor}
                    />
                  );
                })}
              </HStack>
            </Tooltip>
          </Box>
          <Button
            aria-label={''}
            onClick={(clickEvent) => {
              onLearnClicked();
              clickEvent.stopPropagation();
            }}
            size="sm"
            variant="secondary"
          >
            {t('common:showDescription')}
          </Button>
          <VisibleIf condition={isSupportUser}>
            <div onClick={(clickEvent) => clickEvent.stopPropagation()}>
              <Select<VersionOptionType>
                isLoading={versionLoading}
                isDisabled={activityReportId !== undefined}
                options={activity?.versions.map((version) => ({
                  label: `${version.versionNumber}`,
                  value: version.versionNumber,
                }))}
                placeholder={'Version'}
                onChange={(newValue) => setSelectedVersion(newValue?.value)}
                value={
                  selectedVersion !== undefined
                    ? { label: `${selectedVersion}`, value: selectedVersion }
                    : undefined
                }
              />
            </div>
          </VisibleIf>
        </VStack>
        {variant == 'default' && (
          <IconButton
            icon={<DeleteIcon color="inherit" />}
            aria-label={''}
            onClick={(clickEvent) => {
              setShowAlert(true);
              clickEvent.stopPropagation();
            }}
            tooltipLabel={t('common:actions.removeActivity')}
            variant="ghost"
          />
        )}

        <AlertDialog
          title={<Box> {t('bUnits:activities.removeActivityConfirm')} </Box>}
          onConfirm={() => {
            if (!!removeActivity) {
              removeActivity();
            }
            setShowAlert(false);
          }}
          isOpen={showAlert}
          onClose={() => setShowAlert(false)}
          confirmLabel={t('bUnits:activities.confirm')}
        >
          <VStack spacing="md" alignItems="flex-start">
            <Typography>
              {t('bUnits:activities.removeConfirmation')}
              <b>&quot;{activity.name}&quot;</b>?
            </Typography>
            <Typography>{t('bUnits:activities.cantUndo')}</Typography>
          </VStack>
        </AlertDialog>
      </HStack>
    </InputCard>
  );
}
