import { Box } from '@chakra-ui/react';
import { Button } from 'Atoms/Buttons';
import { ArrowNarrowUpIcon } from 'Tokens/Icons/Direction';

export const BackToTopButton = ({
  containerRef,
}: {
  containerRef: React.RefObject<HTMLDivElement>;
}) => {
  const scrollToTop = () => {
    if (containerRef.current) {
      containerRef.current.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }
  };

  return (
    <Box
      bg="bg.default"
      position="fixed"
      bottom="20px"
      left="50%"
      boxShadow="0px 0px 24px -2px #0F0F2E1F"
      borderRadius="8px"
      border="1px solid"
      padding="4px"
      transform="translateX(-50%)"
      zIndex="9999"
      borderColor="border.decorative"
    >
      <Button
        variant="ghost"
        size="sm"
        padding="4px 8px"
        leftIcon={<ArrowNarrowUpIcon />}
        onClick={scrollToTop}
      >
        Back to top
      </Button>
    </Box>
  );
};
