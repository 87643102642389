export const TRACKING_EVENTS = {
  LOGIN: 'login',
  LOGOUT: 'logout',
  ASSESSMENTS: {
    CREATE: 'create assessment',
    UPDATE: 'update assessment',
    DELETE: 'delete assessment',
    CLICK_COMPLETED: 'click assessment completed',
    REPORT_DOWNLOAD: 'taxonomy report download',
    TABLE_DOWNLOAD: 'taxonomy table download',
    TAB_ENTER: 'Enter tab: ',
    TAB_TIME_SPENT: 'Time spent in tab: ',
  },
  BUSINESS_UNITS: {
    CREATE: 'create business unit',
    UPDATE: 'update business unit',
    DELETE: 'delete business unit',
    CHANGE_ORDER: 'change business unit order',
    DUPLICATE: 'duplicate business unit',
    MANAGE_ACTIVITIES: 'manage activities',
  },
  KNOWLEDGE_BASE: {
    OPEN: 'open knowledge base',
    SEARCH: 'search knowledge base',
    ARTICLE_CLICK: 'article click',
  },
  LEARN_MORE: {
    OPEN: 'open learn more',
  },
  SETTINGS: {
    CHANGE_COMPANY_NAME: 'change company name',
    CHANGE_COMPANY_LOGO: 'change company logo',
    CHANGE_COMPANY_CURRENCY: 'change company currency',
  },
  TEAM_MEMBERS: {
    INVITE: 'invite team member',
  },
  FINANCIALS: {
    CLICK_SAVE: 'click save financials',
    AUTO_SAVE: 'auto save financials',
  },
  DOWNLOAD: {
    COMPNAY_REPORT: 'download company report',
    EU_TABLE: 'download eu table',
  },
  DRIVE: {
    UPLOAD: 'upload drive',
    DELETE: 'delete file',
    SEARCH: 'search drive',
    DOWNLOAD: 'download file',
  },
  QUESTION: {
    ANSWER: {
      CREATE: 'create answer',
      UPDATE: 'update answer',
    },
    COMMENTS: {
      ADD: 'add comment',
      DELETE: 'delete comment',
    },
    DOCUMENTS: {
      ASSIGN: 'assign document',
    },
    NOTES: {
      ADD: 'add note',
    },
  },
  ESRS: {
    REPORT: {
      WORD_REPORT_DOWNLOAD: 'ESRS report download (word)',
      XHTML_REPORT_DOWNLOAD: 'ESRS report download (xhtml)',
    },
  },
  SIDE_BAR: {
    ESG_PORTAL: 'ESG portal clicked',
  },
};
