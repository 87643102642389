import { Modal, Table } from 'Molecules';
import { useUserSessions } from './UserSessions.hooks';
import { ColumnDef } from '@tanstack/react-table';
import { UserSessionMetadata_ } from 'models';
import { Button, VisibleIf } from 'Atoms';
import { useMemo, useState } from 'react';
import { Typography } from 'Tokens';
import { useTranslation } from 'utils/translation';

export const UserSessionsTable = () => {
  const [sessionIdToLogOut, setSessionIdToLogOut] = useState<string>();
  const { t } = useTranslation('settings');
  const { activeSessions, inactiveSessions, logOutFromSession, isLoadingLogOut } =
    useUserSessions();

  const sessionColumns: ColumnDef<UserSessionMetadata_>[] = [
    {
      header: `${t('settings:sessions.browser')}`,
      accessorKey: 'browser',
    },
    {
      header: `${t('settings:sessions.os')}`,
      accessorKey: 'operatingSystem',
    },
    {
      header: `${t('settings:sessions.device')}`,
      accessorKey: 'device',
    },
    {
      header: `${t('settings:sessions.IPaddress')}`,
      accessorKey: 'ipAddress',
    },
    {
      header: `${t('settings:sessions.loginTime')}`,
      accessorKey: 'createdAt',
      cell: ({ row }) => new Date(row.original.createdAt).toLocaleString(),
    },
  ];
  const inActiveSessionsColumns: ColumnDef<UserSessionMetadata_>[] = [
    ...sessionColumns,
    {
      header: `${t('settings:sessions.logoutTime')}`,
      accessorKey: 'updatedAt',
      cell: ({ row }) => new Date(row.original.updatedAt).toLocaleString(),
    },
  ];
  const activeSessionsColumns: ColumnDef<UserSessionMetadata_>[] = useMemo(
    () => [
      ...sessionColumns,
      {
        header: ' ',
        cell: ({ row }) => (
          <Button variant="destructive" onClick={() => setSessionIdToLogOut(row.original.id)}>
            {t('settings:sessions.logout')}
          </Button>
        ),
      },
    ],
    [setSessionIdToLogOut]
  );

  return (
    <>
      <Typography variant="h2">{t('settings:sessions.activeSessions')}</Typography>
      <Table<UserSessionMetadata_>
        title={t('settings:sessions.activeSessions')}
        columns={activeSessionsColumns}
        data={activeSessions}
        cellProps={{ fontWeight: 'normal' }}
      />
      <VisibleIf condition={inactiveSessions.length > 0}>
        <Typography variant="h2" mt="48px">
          {t('settings:sessions.previousSessions')}
        </Typography>
        <Table<UserSessionMetadata_>
          columns={inActiveSessionsColumns}
          data={inactiveSessions}
          cellProps={{ fontWeight: 'normal' }}
        />
      </VisibleIf>
      <Modal
        isOpen={!!sessionIdToLogOut}
        onClose={() => setSessionIdToLogOut(undefined)}
        onCancel={() => setSessionIdToLogOut(undefined)}
        onConfirm={() => {
          logOutFromSession({ variables: { sessionId: sessionIdToLogOut } }).then((res) => {
            if (res.data?.deleteAuthRefreshToken?.id === sessionIdToLogOut) {
              setSessionIdToLogOut(undefined);
            }
          });
        }}
        loading={isLoadingLogOut}
        confirmVariant="destructive"
        title={t('settings:sessions.logoutSession')}
      >
        <br />
        <Typography variant="body">{t('settings:sessions.logoutConfirmation')}</Typography>
        <br />
        <Typography variant="bodyStrong">{t('settings:sessions.logoutNotice')}</Typography>
      </Modal>
    </>
  );
};
