import { MetricsTableData } from '../../..';

export const searchForParentMetric = (metrics: MetricsTableData[], ref: string) => {
  const searchMetric = (metric: MetricsTableData): boolean => {
    if (metric.metric.reference === ref && !metric.subRows?.some((row) => row.referenceToSource)) {
      return true;
    }

    if (metric.subRows) {
      return metric.subRows.some((row) => searchMetric(row));
    }

    return false;
  };

  return metrics?.find((metric) => searchMetric(metric));
};

export const goToSource = (
  ref: string,
  filteredMetrics: MetricsTableData[],
  setNumberOfRows: React.Dispatch<React.SetStateAction<Record<string, number>>>
) => {
  const refElement = document.getElementById(ref);
  if (refElement) {
    setTimeout(() => {
      refElement.scrollIntoView({
        behavior: 'smooth',
        inline: 'center',
      });
    });
  } else {
    const mainParent = searchForParentMetric(filteredMetrics ?? [], ref);
    const element = document.getElementById(mainParent?.metric.reference ?? '');
    if (element) {
      setTimeout(() => {
        element.scrollIntoView({
          behavior: 'smooth',
          inline: 'center',
        });
      });
    }
    // expand main parent
    setNumberOfRows((prevStates) => ({
      ...prevStates,
      [mainParent?.metric.reference ?? '']:
        (prevStates[mainParent?.metric.reference ?? ''] || 5) + 100,
    }));
  }
};
