import { useTranslation } from 'utils/translation';
import { MetricsTableData } from '..';
import { useCurrentCompany, useToast } from 'utils/hooks';
import { AssessableMetrics } from '../Metrics';
import {
  extractNarrativeAnswer,
  extractNumericAnswer,
  extractTextFromPdf,
} from './AIRequestFunctions';
import { GeneratedAnswer } from './MetricsAITypes';
import { useMemo } from 'react';
import { Language } from 'utils/language';

export const useGenerateNumericAnswers = ({
  quantitativeMetrics,
  abortControllerRef,
}: {
  quantitativeMetrics: MetricsTableData[];
  abortControllerRef: React.RefObject<AbortController | null>;
}) => {
  const { t } = useTranslation('ai');
  const toast = useToast();

  const generateNumericAnswers = async (text: string): Promise<GeneratedAnswer[]> => {
    const requestSignal = abortControllerRef.current?.signal;

    const availableDataPoints = quantitativeMetrics.map((metric) => {
      const resolveTagsString = () => {
        const metricStandaloneTitle = metric.metric.title;
        const tags = metric.tags;
        const tagsString = tags?.map((tag) => `by ${tag.tagType} for ${tag.tagValue}`).join(', ');
        if (metric.tags?.length) {
          return `${metricStandaloneTitle} ${tagsString}`;
        }
        return metricStandaloneTitle;
      };

      return {
        reference: metric.metric.reference,
        metricTitleWithTags: resolveTagsString(),
        unit: metric.metric.unitOfMeasurement,
        title: metric.metric.title,
        tags: metric.tags,
      };
    });

    const extractedAnswers = await Promise.all(
      availableDataPoints.map(async (dp) => {
        try {
          const populatedAnswer = await extractNumericAnswer(
            dp.metricTitleWithTags,
            dp.unit ?? 'NA',
            text,
            requestSignal
          );

          const metricAnswerData = populatedAnswer.data;

          return {
            ...metricAnswerData,
            metricRef: dp.reference,
            metricTitle: dp.title,
            tags: dp.tags,
          };
        } catch (error) {
          if (requestSignal?.aborted) {
            toast({
              text: 'Answers generation cancelled',
            });
          } else {
            toast({
              variant: 'danger',
              text: t('esrs.errors.generateNumericAnswers'),
            });
          }
        }
      })
    );

    return extractedAnswers;
  };

  return { generateNumericAnswers };
};

export const useGenerateNarrativeAnswers = ({
  narrativeMetrics,
  abortControllerRef,
}: {
  narrativeMetrics: AssessableMetrics;
  abortControllerRef: React.RefObject<AbortController | null>;
}) => {
  const { t } = useTranslation('ai');
  const toast = useToast();
  const { company } = useCurrentCompany();

  const aiConfig = useMemo(() => {
    return {
      preferredLanguage: company?.aiConfig?.preferredLanguage ?? Language.EnglishUK,
      toneOfVoice: company?.aiConfig?.toneOfVoice ?? '',
    };
  }, [company?.aiConfig]);

  const { preferredLanguage, toneOfVoice } = aiConfig;

  const generateNarrativeAnswers = async (text: string): Promise<GeneratedAnswer[]> => {
    const requestSignal = abortControllerRef?.current?.signal;

    const extractedAnswers = await Promise.all(
      narrativeMetrics.map(async (metric) => {
        try {
          const populatedAnswer = await extractNarrativeAnswer(
            metric.title,
            text,
            preferredLanguage,
            toneOfVoice,
            requestSignal
          );

          const metricAnswerData = populatedAnswer.data;

          return { ...metricAnswerData, metricRef: metric.reference };
        } catch (error) {
          if (requestSignal?.aborted) {
            toast({
              text: 'Answers generation cancelled',
            });
          } else {
            toast({
              variant: 'danger',
              text: t('esrs.errors.generateNarrativeAnswers'),
            });
          }
        }
      })
    );

    return extractedAnswers;
  };

  return { generateNarrativeAnswers };
};

export const useReadPdfText = ({
  abortControllerRef,
}: {
  abortControllerRef: React.RefObject<AbortController | null>;
}) => {
  const { t } = useTranslation('ai');
  const toast = useToast();

  const readPdfText = async (fileUrl: string, fileName: string) => {
    const requestSignal = abortControllerRef?.current?.signal;

    try {
      const result = await extractTextFromPdf(fileUrl, fileName, requestSignal);
      const combinedContent = result.content.reduce((acc: string, curr: any) => {
        return acc + curr.text;
      }, '');
      return combinedContent;
    } catch (error) {
      if (requestSignal?.aborted) {
        toast({
          text: 'Answers generation cancelled',
        });
      } else {
        toast({
          variant: 'danger',
          text: t('pdfRestriction'),
        });
      }
    }
  };

  return { readPdfText };
};
