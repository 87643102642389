import { ColumnDef } from '@tanstack/react-table';
import { Table } from 'Molecules';
import { HStack, MenuButton, VStack } from '@chakra-ui/react';
import { Typography } from 'Tokens';
import {
  EsrsAssessmentTargetsDocument_,
  GetDisclosureRequirementGroupsDocument_,
  GetTargetsDrDocument_,
  useDeleteTargetMutation,
} from 'models';
import { useNavigate } from 'react-router-dom';
import { IconButton, Tag, TruncatableText } from 'Atoms';
import { Menu, MenuAction } from 'Molecules/Menu';
import { DeleteIcon, EditIcon } from 'Tokens/Icons/Function';
import { ViewTabs } from './Targets';
import { useMemo } from 'react';
import { CombinedTargetsData } from './Targets.hooks';
import { UserAvatar } from 'Organisms';
import { ArrowUpRightIcon } from 'Tokens/Icons/Direction';
import { getDisclosureRequirementLink } from 'utils/links';
import { DREnums } from '../Requirement';
import { TargetIcon } from 'Tokens/Icons/Data';
export const TargetsTable = ({
  data,
  currentTab,
  isSearch,
  setAction,
  openAction,
  isOverview,
  setTargetId,
  isGroup,
  hasParentAssessment,
}: {
  data: CombinedTargetsData[];
  currentTab: string;
  isSearch: boolean;
  setAction: (id: number) => void;
  openAction: () => void;
  isOverview?: boolean;
  setTargetId: (param: string) => void;
  isGroup: boolean;
  hasParentAssessment: boolean;
}) => {
  const navigate = useNavigate();
  const [deleteTarget] = useDeleteTargetMutation();

  const navigateToTargets = (row: CombinedTargetsData) => {
    if (isOverview)
      navigate(
        `standard/${row.standard?.reference}/disclosure-requirement/target/${row.category?.reference}/target-form/${row.targetId}/edit`
      );
    else navigate(`target-form/${row.targetId}/edit`);
  };

  const columns: ColumnDef<CombinedTargetsData>[] = [
    {
      header: 'Title',
      accessorKey: 'title',
      meta: {
        width: isGroup ? '45%' : '30%',
        padding: '0px',
      },
      cell: ({ row }) => (
        <HStack>
          <TargetIcon />
          <VStack alignItems="start" spacing="0px">
            <TruncatableText variant="bodyStrong" text={row.original.title} />
            <TruncatableText variant="micro" text={row.original.metricTitle ?? ''} />
          </VStack>
        </HStack>
      ),
    },
    {
      header: 'Target',
      accessorKey: 'target',
      meta: {
        width: '10%',
        padding: '0px',
      },
      cell: ({ row }) => {
        const val = row.original.targetValue;
        return (
          val && (
            <VStack alignItems="start" spacing="2px">
              <Typography variant="body">
                {val ? (val > 0 ? '+' : '') + val.toFixed(2) + '%' : ''}
              </Typography>
              <Typography variant="micro">by {row.original.latestMilestone}</Typography>
            </VStack>
          )
        );
      },
    },
    {
      header: 'Company',
      accessorKey: 'company',
      meta: {
        width: '15%',
        padding: '0px',
      },
      cell: ({ row }) => {
        return (
          <VStack alignItems="start">
            <TruncatableText variant="body" text={row.original.companyName ?? ''} />
          </VStack>
        );
      },
    },
    {
      header: isOverview ? 'Related actions' : 'Actions',
      accessorKey: 'actions',
      meta: {
        width: '15%',
        padding: '0px',
      },
      cell: ({ row }) => {
        const sections = row.original.actions.map((action, key) => ({
          actions: [
            {
              id: String(key),
              title: action.title,
              isDisabled: row.original.isSubOrParent,
              onClick: () => {
                setAction(action.id);
                setTargetId(String(row.original.targetId));
                openAction();
              },
            },
          ],
        }));
        return (
          <Menu
            customMenuButton={
              <MenuButton
                onClick={(e) => e.stopPropagation()}
                _hover={{ cursor: row.original.numberOfActions ? 'pointer' : 'default' }}
              >
                <Tag
                  variant={row.original.numberOfActions ? 'info' : 'default'}
                  size="xs"
                  clickable={row.original.numberOfActions ? true : false}
                  title={String(row.original.numberOfActions)}
                />
              </MenuButton>
            }
            sections={sections}
            size="sm"
          />
        );
      },
    },
    {
      header: isOverview ? 'Owners' : 'Owner',
      accessorKey: 'owner',
      meta: {
        width: '10%',
        padding: '0px',
      },
      cell: ({ row }) => {
        return (
          <VStack alignItems="start">
            {row.original?.owner && (
              <UserAvatar user={row.original?.owner ?? undefined} size="xs" boxSize="28px" />
            )}
          </VStack>
        );
      },
    },
    {
      header: 'Parent company status',
      accessorKey: 'parent',
      meta: {
        width: '15%',
        padding: '0px',
      },
      cell: ({ row }) => {
        if (!!row.original.parentTargetId) {
          if (row.original.isFlagged)
            return <Tag title="Update requested" variant="warning" size="xs" />;
          return <Tag title="Required" variant="info" size="xs" />;
        }
        if (!row.original.parentTargetId && row.original.isRequired)
          return <Tag title="No longer required" variant="warning" size="xs" />;
        return <Tag title="Not required" variant="undefined" size="xs" />;
      },
    },
    {
      header: '',
      id: 'settings',
      accessorKey: 'settings',
      meta: {
        width: '5%',
        padding: '0px',
      },
      cell: ({ row }) => {
        return row.original.isSubOrParent && isGroup ? (
          <IconButton
            className="metricArrow"
            aria-label="side-bar"
            variant="ghost"
            icon={<ArrowUpRightIcon />}
            onClick={() =>
              window.open(
                `${getDisclosureRequirementLink({
                  companyId: row.original.companyId ?? '',
                  assessmentId: row.original.esrsAssessment,
                  standardRef: row.original.standard?.reference,
                  disclosureRequirement: {
                    reference: row.original.disclosureRequirementRef ?? '',
                    type: DREnums.target,
                  },
                })}/target-form/${row.original.targetId}/edit`
              )
            }
          />
        ) : !row.original.isSubOrParent ? (
          <HStack justifyContent="flex-end">
            <Menu
              sections={[
                {
                  actions: [
                    {
                      id: 'edit',
                      title: 'Edit',
                      onClick: () => navigateToTargets(row.original),
                      leftElement: <EditIcon color="inherit" />,
                    },
                    {
                      id: 'delete',
                      title: 'Delete',
                      variant: 'destructive',
                      onClick: () => {
                        deleteTarget({
                          variables: {
                            targetId: row.original.targetId,
                          },
                          refetchQueries: [
                            GetTargetsDrDocument_,
                            GetDisclosureRequirementGroupsDocument_,
                            EsrsAssessmentTargetsDocument_,
                          ],
                        });
                      },
                      leftElement: <DeleteIcon color="inherit" />,
                    },
                  ].filter((a) =>
                    !!row.original.parentTargetId ? a.id !== 'delete' : true
                  ) as MenuAction[],
                },
              ]}
              size="sm"
            />
          </HStack>
        ) : (
          <></>
        );
      },
    },
  ];

  const emptyStateText = useMemo(() => {
    if (isSearch) {
      return 'No targets found';
    } else {
      let message = 'No targets added by ';

      if (currentTab === ViewTabs.subsidiaries) {
        message += 'subsidiaries';
      } else if (currentTab === ViewTabs.parentCompany) {
        message += 'parent company';
      } else {
        message += 'your company';
      }

      return message;
    }
  }, [currentTab]);

  return (
    <Table
      columns={
        !hasParentAssessment ? columns.filter((c) => c.header !== 'Parent company status') : columns
      }
      data={data}
      withBorder={true}
      headerPadding="8px"
      cellPadding="8px"
      pageSize={20}
      bottomRowBorder={false}
      emptyStateText={emptyStateText}
      onRowClick={(row) => {
        if (!row.isSubOrParent) {
          navigateToTargets(row);
        }
      }}
      conditionalRowProps={(row) => ({
        _hover: {
          cursor: row.isSubOrParent ? 'default' : 'pointer',
          bg: row.isSubOrParent ? '' : 'bg.hover',
        },
      })}
    />
  );
};
