import {
  GetAnswersForMetricsOnCompanyLevelQuery_,
  GetAnswersForMetricsOnGroupLevelQuery_,
  GetCompanyLevelMdrmMetricAnswersQuery_,
} from 'models';
import {
  getAggregatedMetricAnswers,
  getMetricAnswers,
  MetricsTableData,
  MaybeHasChild,
  MetricType,
} from '../DisclosureRequirements';
import { AssessableMetrics } from '../DisclosureRequirements/Metrics/Metrics';
import { ReportChildMetrics, ReportMetric } from './Report.types';

export const getMetricResult = (data: {
  metric: AssessableMetrics[number];
  nestedMetric: MetricsTableData;
  isGroup: boolean;
  materialStandardId: string;
  reportingUnitsAnswers?: GetAnswersForMetricsOnCompanyLevelQuery_;
  groupAnswers?: GetAnswersForMetricsOnGroupLevelQuery_;
  standardRef?: string;
}) => {
  const {
    metric,
    nestedMetric,
    isGroup,
    materialStandardId,
    reportingUnitsAnswers,
    groupAnswers,
    standardRef,
  } = data;

  const subsidiaries = groupAnswers?.EsrsAssessment_by_pk?.subsidiaryAssessments ?? [];
  const reportingUnits = reportingUnitsAnswers?.EsrsAssessment_by_pk?.reportingUnits ?? [];

  const answers = getMetricAnswers({
    metric,
    companyAnswersData: reportingUnits,
    groupAnswersData: subsidiaries,
    isGroup,
    standardRef,
    materialStandardId,
  });

  const result = getAggregatedMetricAnswers(nestedMetric, answers);
  return result;
};

export type MdrmMetricAnswers = {
  metric: MetricType;
  answers: {
    metricUsed: string | null;
    methodology: string | null;
    validation: string | null;
  };
};

const getReportFullMetricWithChildren = (metric: ReportMetric) => {
  const childrenMetricRefs = [metric];

  const recurseChildren = (childrenMetrics: MaybeHasChild['childrenMetrics']) => {
    if (!!childrenMetrics?.length) {
      childrenMetrics.forEach((child) => {
        if (!!child.childMetric) {
          childrenMetricRefs.push(child.childMetric as ReportChildMetrics[number]['childMetric']);
          recurseChildren(child?.childMetric.childrenMetrics as MaybeHasChild['childrenMetrics']);
        }
      });
    }
  };

  if (metric?.childrenMetrics) {
    recurseChildren(metric?.childrenMetrics as MaybeHasChild['childrenMetrics']);
  }
  return childrenMetricRefs.filter((m) => m !== undefined) ?? [];
};

export const getMetricMdrmAnswers = (
  metric: ReportMetric,
  mdrmAnswers?: GetCompanyLevelMdrmMetricAnswersQuery_['esrs_EsrsMDRM']
): MdrmMetricAnswers[] => {
  const metricWithChildren = getReportFullMetricWithChildren(metric);
  const metricAnswers = metricWithChildren.map((m) => {
    const answers = mdrmAnswers?.find((mdrmA) => mdrmA.metricRef === m?.reference);
    return {
      metric: m as MetricType,
      answers: {
        metricUsed: answers?.metricUsed ?? '',
        methodology: answers?.methodology ?? '',
        validation: answers?.validation ?? '',
      },
    };
  });
  return metricAnswers;
};
