import { Box, HStack, ListItem, UnorderedList, VStack } from '@chakra-ui/react';
import { Alert, Button } from 'Atoms';
import { Typography } from 'Tokens';
import { useTranslation } from 'utils/translation';
import { CSVLink } from 'react-csv';
import { FileUploader } from 'Molecules/FileUploader';
import { CheckIcon } from 'Tokens/Icons/Status';
import { DownloadIcon } from 'Tokens/Icons/Function';

export const Uploader = ({
  onUpload,
  isFileUploaded,
  rejectionMessages,
  dataToCSV,
  setIsFileUploaded,
  fileName,
  description,
  onDownloadClick,
  acceptedTypes = ['.csv'],
  titleWidth,
}: {
  onUpload: (param: File) => void;
  isFileUploaded: boolean;
  setIsFileUploaded: (param: boolean) => void;
  rejectionMessages: string[];
  dataToCSV?: string[][];
  fileName?: string;
  description: {
    title?: string;
    description?: string;
    button?: string;
    Component?: React.ReactElement;
    bulletPoints?: string[];
  }[];
  onDownloadClick?: () => void;
  acceptedTypes?: string[];
  titleWidth?: string;
}) => {
  const { t } = useTranslation('csv');

  return (
    <VStack spacing="md" width="100%">
      <VStack
        spacing="24px"
        width="100%"
        alignItems="flex-start"
        borderLeft="1px dashed"
        borderColor="text.hint"
        pl="32px"
        pr="16px"
        ml="33px"
      >
        {description.map((desc) => (
          <Box position="relative">
            <Box
              border="1px dashed"
              borderColor="text.hint"
              borderRadius="50%"
              w="fit-content"
              px="5px"
              position="absolute"
              left="-47px"
              bg="bg.default"
            >
              <CheckIcon boxSize="18px" mt="5px" color="text.hint" />
            </Box>
            <Typography variant="h3" color="text.default" mb="4px">
              {desc.title}
            </Typography>
            {desc.description && (
              <Box>
                <Typography variant="body" color="text.muted" mb="12px">
                  {desc.description}
                </Typography>
                {!!desc.bulletPoints?.length ? (
                  <UnorderedList>
                    {desc.bulletPoints.map((bp) => (
                      <ListItem>
                        <Typography variant="body" color="text.muted">
                          {bp}
                        </Typography>
                      </ListItem>
                    ))}
                  </UnorderedList>
                ) : undefined}
              </Box>
            )}
            {desc.button && onDownloadClick && (
              <Button
                variant="secondary"
                leftIcon={<DownloadIcon color="inherit" />}
                onClick={onDownloadClick}
              >
                {desc?.button}
              </Button>
            )}
            {desc?.button && dataToCSV && (
              <CSVLink
                data={dataToCSV}
                filename={'Celsia_Financials.csv'}
                rel="noopener noreferrer"
                target="_blank"
              >
                <Button variant="secondary" leftIcon={<DownloadIcon color="inherit" />}>
                  {desc.button}
                </Button>
              </CSVLink>
            )}
            {desc?.Component && desc.Component}
          </Box>
        ))}
      </VStack>

      <VStack spacing="8px" width="100%">
        <HStack justifyContent="center" width="100%">
          <FileUploader
            multiple={false}
            text={t('csv:text.selectCSV')}
            accept={{ type: acceptedTypes }}
            shouldUpload={false}
            onUpload={(files: File[]) => {
              onUpload(files[0]);
            }}
            isUploadDone={isFileUploaded}
            setIsUploadDone={setIsFileUploaded}
            fileName={fileName}
            titleWidth={titleWidth}
          />
        </HStack>

        {rejectionMessages.map((message: string, index) => (
          <Alert status="warning" closable={false} withIcon={true} title={message} key={index} />
        ))}
      </VStack>
    </VStack>
  );
};
