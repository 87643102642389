import { VStack, useDisclosure } from '@chakra-ui/react';
import { Modal } from 'Molecules/Modal';
import { RefObject, useState } from 'react';
import Cropper, { Area } from 'react-easy-crop';
import { ImageControlsToolbar } from './ImageControlsToolbar';
import { Typography } from 'Tokens';
import { Button } from 'Atoms';
import { DeleteIcon, UploadIcon } from 'Tokens/Icons/Function';
import getCroppedImg from './cropImage';

const DEFAULT_HEIGHT = 224;

export const ImageCropper = ({
  imageSrc,
  imageName,
  fileInputRef,
  cropperRef,
  isOpen,
  onClose,
  onConfirm,
  onDelete,
}: {
  imageSrc: string;
  imageName: string;
  fileInputRef: RefObject<HTMLInputElement>;
  cropperRef: React.MutableRefObject<null>;
  isOpen: boolean;
  onClose: () => void;
  onConfirm: (val: File | null) => void;
  onDelete: () => void;
}) => {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [rotation, setRotation] = useState(0);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<Area>({
    x: 0,
    y: 0,
    width: 100,
    height: 100,
  });
  const {
    isOpen: isDeleteModalOpen,
    onOpen: onDeleteModalOpen,
    onClose: onDeleteModalClose,
  } = useDisclosure();

  const onCropComplete = (_croppedArea: Area, croppedPixels: Area) => {
    setCroppedAreaPixels(croppedPixels);
  };
  const getCroppedImage = async () => {
    const croppedImage = await getCroppedImg(imageSrc, croppedAreaPixels, rotation, imageName);
    onConfirm(croppedImage);
    onClose();
  };
  const handleDelete = () => {
    onDelete();
    onDeleteModalClose();
    onClose();
  };

  return (
    <Modal
      size="md"
      title="Edit image"
      onClose={onClose}
      onConfirm={getCroppedImage}
      isOpen={isOpen}
      confirmText="Save"
      actions={[
        <Button
          size="md"
          variant="ghost"
          leftIcon={<UploadIcon />}
          onClick={() => {
            if (fileInputRef.current) {
              fileInputRef.current.removeAttribute('disabled');
              fileInputRef?.current?.click();
            }
          }}
        >
          Replace
        </Button>,
        <Button size="md" variant="ghost" leftIcon={<DeleteIcon />} onClick={onDeleteModalOpen}>
          Delete
        </Button>,
      ]}
    >
      <VStack alignItems="start" position="relative">
        <ImageControlsToolbar
          zoom={zoom}
          rotation={rotation}
          setZoom={setZoom}
          setRotation={setRotation}
        />
        <VStack
          bg="bg.backdrop"
          w="100%"
          className="new"
          position="relative"
          height={DEFAULT_HEIGHT}
        >
          <Cropper
            image={imageSrc}
            aspect={1}
            crop={crop}
            ref={cropperRef}
            zoom={zoom}
            rotation={rotation}
            style={{
              containerStyle: { height: DEFAULT_HEIGHT, maxHeight: DEFAULT_HEIGHT },
              mediaStyle: { height: DEFAULT_HEIGHT, maxHeight: DEFAULT_HEIGHT },
              cropAreaStyle: { width: '100%' },
            }}
            onCropChange={setCrop}
            onCropComplete={onCropComplete}
            onZoomChange={setZoom}
            cropShape="round"
          />
        </VStack>
      </VStack>
      <Modal
        confirmButtonProps={{ variant: 'ghost', color: 'text.critical' }}
        hasHeader={false}
        size="xs"
        onClose={onDeleteModalClose}
        isOpen={isDeleteModalOpen}
        confirmText="Delete"
        onConfirm={handleDelete}
      >
        <Typography variant="bodyStrong">Do you want to delete this image?</Typography>
      </Modal>
    </Modal>
  );
};
