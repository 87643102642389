import { VStack, HStack, Button, useDisclosure } from '@chakra-ui/react';
import { IconArrowNarrowDown } from '@tabler/icons-react';
import { LinkAnchor } from 'Molecules/LinkAnchor';
import { Typography } from 'Tokens';
import { Dispatch, SetStateAction, useState } from 'react';
import { useCurrentCompany } from 'utils/hooks';
import { AggregatedMetricsTableData, MetricsTableData } from '..';
import { AggregatedQualitativeAnswers } from '../AggregatedMetrics';
import { AssessableMetrics } from '../Metrics';
import { GeneratedAnswer } from './MetricsAITypes';
import { TimePeriods } from 'containers/Esrs';
import { GetRequiredMaterialMetricsQuery_, User } from 'models';
import { MetricsTable } from '../MetricsTable/MetricsTable';
import { AnswersApprovalWarningModal } from './AIUtils';
import { isEqual } from 'lodash';

export const MetricsAITable = ({
  allFilteredMetrics,
  selectedQuarter,
  materialStandardId,
  isGroup,
  companyLevelReportingUnitId,
  rowData,
  setRowData,
  esrsAssessmentProjectLeader,
  selectedNumericMetrics,
  setSelectedNumericMetrics,
  selectedNarrativeMetrics,
  setSelectedNarrativeMetrics,
  qualitativeMetrics,
  aggregatedMetricsAnswers,
  generatedAnswers,
  setGeneratedAnswers,
  setIsComments,
  populateQuantitativeAnswers,
  populateNarrativeAnswers,
  checkboxesDisabled,
  parentStandardId,
  requiredMaterialMetrics,
}: {
  allFilteredMetrics: MetricsTableData[];
  selectedQuarter: TimePeriods;
  materialStandardId: string;
  isGroup: boolean;
  companyLevelReportingUnitId?: string;
  rowData?: MetricsTableData;
  setRowData: (
    param: (MetricsTableData & { sourceData?: AggregatedQualitativeAnswers[number] }) | undefined
  ) => void;
  esrsAssessmentProjectLeader?: Partial<User>;
  selectedNumericMetrics?: MetricsTableData[];
  setSelectedNumericMetrics?: Dispatch<SetStateAction<MetricsTableData[]>>;
  selectedNarrativeMetrics?: AssessableMetrics;
  setSelectedNarrativeMetrics?: Dispatch<SetStateAction<AssessableMetrics>>;
  qualitativeMetrics: AggregatedMetricsTableData[];
  summaryQualitativeMetrics: AssessableMetrics;
  aggregatedMetricsAnswers: AggregatedQualitativeAnswers;
  generatedAnswers: GeneratedAnswer[];
  setIsComments: (val: boolean) => void;
  setGeneratedAnswers: Dispatch<SetStateAction<GeneratedAnswer[]>>;
  populateQuantitativeAnswers?: (
    generatedAnswers: GeneratedAnswer[],
    numericMetrics: MetricsTableData[]
  ) => void;
  populateNarrativeAnswers: (
    generatedAnswers: GeneratedAnswer[],
    numericMetrics: AssessableMetrics
  ) => void;
  checkboxesDisabled: boolean;
  parentStandardId: string | undefined;
  requiredMaterialMetrics: GetRequiredMaterialMetricsQuery_['esrs_MaterialMetric'];
}) => {
  const { company } = useCurrentCompany();
  const [selectedRow, setSelectedRow] = useState('');
  const {
    isOpen: isApprovalWarningModalOpen,
    onOpen: onApprovalWarningModalOpen,
    onClose: onApprovalWarningModalClose,
  } = useDisclosure();

  const [numberOfRows, setNumberOfRows] = useState<Record<string, number>>({});

  const toggleShowMore = (category: string) => {
    setNumberOfRows((prevStates) => ({
      ...prevStates,
      [category]: (prevStates[category] || 5) + 10,
    }));
  };

  const getTotalRows = (data: MetricsTableData[]): number => {
    let totalRows = data.length;
    for (const row of data) {
      if (row.subRows && row.subRows.length > 0) {
        totalRows += getTotalRows(row.subRows);
      }
    }
    return totalRows;
  };

  return (
    <VStack w="100%" spacing="32px" alignItems="start">
      {allFilteredMetrics.map((metric) => {
        const rowsCount = getTotalRows([metric]);
        const remainingCount =
          rowsCount - (numberOfRows[metric.metric?.reference ?? ''] ?? 5) > 10
            ? 10
            : rowsCount - (numberOfRows[metric.metric?.reference ?? ''] ?? 5);
        const showMoreButton = remainingCount > 0;

        return (
          <VStack
            key={metric.metric.reference}
            w="100%"
            alignItems="start"
            id={metric.metric.reference}
            spacing="6px"
          >
            <HStack justifyContent="space-between" w="100%">
              <LinkAnchor
                id={metric.metric.reference}
                text={`${metric.metric.title} ${(metric.tagType ?? '')?.toLowerCase()}`}
                marginLeft="-20px"
              />
            </HStack>
            <MetricsTable
              setIsComments={setIsComments}
              metrics={[metric]}
              selectedQuarter={selectedQuarter}
              materialStandardId={materialStandardId}
              companyReportingUnit={companyLevelReportingUnitId}
              currency={company?.currency ?? ''}
              isGroup={isGroup}
              esrsAssessmentProjectLeader={esrsAssessmentProjectLeader}
              rowData={rowData}
              setRowData={setRowData}
              pageSize={numberOfRows[metric.metric.reference]}
              allMetrics={allFilteredMetrics}
              setNumberOfRows={setNumberOfRows}
              isMetricDr={true}
              setSelectedRow={setSelectedRow}
              selectedNumericMetrics={selectedNumericMetrics}
              setSelectedNumericMetrics={setSelectedNumericMetrics}
              isAI={true}
              generatedAnswers={generatedAnswers}
              setGeneratedAnswers={setGeneratedAnswers}
              populateQuantitativeAnswers={populateQuantitativeAnswers}
              checkboxesDisabled={checkboxesDisabled}
              parentStandardId={parentStandardId}
              requiredMaterialMetrics={requiredMaterialMetrics}
            />
            {showMoreButton && (
              <Button
                mt="2px"
                variant="ghost"
                size="sm"
                onClick={() => toggleShowMore(metric.metric.reference)}
              >
                <HStack spacing="8px">
                  <IconArrowNarrowDown size="16px" />
                  <Typography variant="bodyStrong" size="sm">
                    Load {remainingCount} more
                  </Typography>
                </HStack>
              </Button>
            )}
          </VStack>
        );
      })}

      {qualitativeMetrics?.length !== 0 && (
        <VStack w="100%" alignItems="start" spacing="6px">
          <LinkAnchor
            id={qualitativeMetrics?.[0].metric.reference}
            text="Narrative data points"
            marginLeft="-20px"
          />
          <MetricsTable
            metrics={qualitativeMetrics}
            selectedQuarter={selectedQuarter}
            esrsAssessmentProjectLeader={esrsAssessmentProjectLeader ?? undefined}
            rowData={rowData}
            setRowData={setRowData}
            isGeneratingAnswers={false}
            companyReportingUnit={companyLevelReportingUnitId}
            isAI={true}
            isGroup={isGroup}
            selectedNumericMetrics={selectedNumericMetrics}
            selectedNarrativeMetrics={selectedNarrativeMetrics}
            setSelectedNumericMetrics={setSelectedNumericMetrics}
            setSelectedNarrativeMetrics={setSelectedNarrativeMetrics}
            populateNarrativeAnswers={populateNarrativeAnswers}
            onApprovalWarningModalOpen={onApprovalWarningModalOpen}
            generatedAnswers={generatedAnswers}
            setSelectedRow={setSelectedRow}
            setGeneratedAnswers={setGeneratedAnswers}
            isReadOnly={true}
            isNarrative
            checkboxesDisabled={checkboxesDisabled}
            setIsComments={setIsComments}
            materialStandardId={materialStandardId}
            currency={company?.currency ?? ''}
            allMetrics={allFilteredMetrics}
            setNumberOfRows={setNumberOfRows}
            isMetricDr={true}
            populateQuantitativeAnswers={populateQuantitativeAnswers}
            parentStandardId={parentStandardId}
            requiredMaterialMetrics={requiredMaterialMetrics}
          />
        </VStack>
      )}

      <AnswersApprovalWarningModal
        isOpen={isApprovalWarningModalOpen}
        onClose={onApprovalWarningModalClose}
        onConfirm={() => {
          const metric = qualitativeMetrics.find((m) => m.metric.reference === selectedRow)?.metric;

          const answerData = aggregatedMetricsAnswers?.find((a) => a.metricRef === selectedRow);

          if (isEqual(rowData?.metric, metric)) {
            setRowData(undefined);
          } else
            setRowData(
              !!answerData?.reportingUnits?.length || !!answerData?.subsidiaries?.length
                ? { metric: metric as MetricsTableData['metric'], sourceData: answerData }
                : { metric: metric as MetricsTableData['metric'] }
            );
        }}
      />
    </VStack>
  );
};
