export enum ActionViewTabs {
  currentCompanySub = 'currentCompany',
  currentCompanyParent = 'currentParentCompany',
  parentCompany = 'parentCompany',
  subsidiaries = 'subsidiaries',
}

export const ACTIONS_TABS_LABEL = {
  [ActionViewTabs.currentCompanySub]: 'Current company',
  [ActionViewTabs.currentCompanyParent]: 'Parent company',
  [ActionViewTabs.parentCompany]: 'Parent company',
  [ActionViewTabs.subsidiaries]: 'Subsidiaries',
};

export const TAB_INFO = {
  [ActionViewTabs.currentCompanyParent]:
    'In this list you can see the list of all group company actions that were set on this parent company level. Only actions from this list will be added to your ESRS report. If you wish to add any actions from subsidiaries’ list, create a new action here.',
  [ActionViewTabs.currentCompanySub]:
    'In this list you can see the list of all actions added by CompanyName (your company). Only actions from this list will be added to your ESRS report.',
  [ActionViewTabs.parentCompany]:
    'In this list you can see the list of actions created by ParentName. Contact them directly if you wish to work together on your common action plan.',
  [ActionViewTabs.subsidiaries]:
    'In this list you can see the aggregated list of all actions created by your subsidiaries. Contact your subsidiaries directly if you wish to work together on your common action plan.',
};
