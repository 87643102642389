import { Center, Box, keyframes, SpinnerProps, Image, VisuallyHidden } from '@chakra-ui/react';
import React, { useEffect } from 'react';
import { useTranslation } from 'utils/translation';

export type LoaderProps = React.PropsWithChildren<{
  height?: string | number;
  width?: string | number;
  label?: string;
  debugName?: string;
  isLoaded?: boolean;
}>;

const fadeInOut = keyframes`
  0%, 100% { opacity: 0; }
  50% { opacity: 1; }
`;

export function CelsiaSpinner({ label }: SpinnerProps) {
  return (
    <Box position="relative" w="100%" h="100%">
      <Center position="absolute" top="50%" left="50%" transform="translate(-50%, -50%)">
        <Image
          src="/assets/loader_shape_1.svg"
          animation={`${fadeInOut} 2.5s infinite`}
          style={{ animationDelay: '0s', opacity: 0 }}
          width="100px"
          height="100px"
        />
      </Center>
      <Center position="absolute" top="50%" left="50%" transform="translate(-50%, -50%)">
        <Image
          src="/assets/loader_shape_2.svg"
          animation={`${fadeInOut} 2.5s infinite`}
          style={{ animationDelay: '0.83s', opacity: 0 }}
          width="100px"
          height="100px"
        />
      </Center>
      <Center position="absolute" top="50%" left="50%" transform="translate(-50%, -50%)">
        <Image
          src="/assets/loader_shape_3.svg"
          animation={`${fadeInOut} 2.5s infinite`}
          style={{ animationDelay: '1.67s', opacity: 0 }}
          width="100px"
          height="100px"
        />
      </Center>
      <Center position="absolute" top="50%" left="50%" transform="translate(-50%, -50%)">
        <Image src="/assets/loader_logo.svg" width="100px" height="100px" />
      </Center>
      <VisuallyHidden>{label}</VisuallyHidden>
    </Box>
  );
}

export const Loader = React.forwardRef(function Loader(
  { height = '100%', width = '100%', label, debugName, children, isLoaded = false }: LoaderProps,
  ref: React.ForwardedRef<HTMLDivElement>
) {
  const { t, ready } = useTranslation(undefined, { useSuspense: false });
  if (import.meta.env?.NODE_ENV == 'development')
    useEffect(() => {
      if (debugName) {
        console.debug('Loader ', debugName, 'loaded');
        return () => console.debug('Loader', debugName, 'destroyed');
      }
    }, [debugName]);
  return isLoaded && ready ? (
    <>{children}</>
  ) : (
    <Center height={height} width={width} ref={ref} bg="transparent">
      {!!height && <CelsiaSpinner label={label ?? ready ? t('loading') : 'Loading...'} />}
    </Center>
  );
});
