export const getTargetInfoBoxText = (data: {
  isGroup: boolean;
  isBottomUp: boolean;
  companyName: string;
}) => {
  const { isGroup, isBottomUp, companyName } = data;

  const milestoneInfoBox = {
    groupTopDown: `This target is using the “top-down” approach. This means that all information about this target shall be gathered from ${companyName} users. If you wish to aggregate data from subsidiaries, switch to the “bottom-up” approach using the “Edit” button at the top-right corner.`,
    groupBottomUp: `This target is using the "bottom-up" approach. This means that information about the baseline-, target- and milestone values are gathered from the subsidiaries. If you do not wish to aggregate data from the subsidiaries and rather input the data on the group level, switch to the "top-down" approach using the "Edit" button at the top-right corner.`,
    subsidiary: `You can input baseline and milestones values either by adding one value for ${companyName} (this is a default setting) or by adding separate values for each business unit.`,
  };

  if (isGroup) {
    if (isBottomUp) return milestoneInfoBox.groupBottomUp;
    else return milestoneInfoBox.groupTopDown;
  }
  return milestoneInfoBox.subsidiary;
};

export const getMilestoneInfoBoxText = (data: {
  isGroup: boolean;
  isBottomUp: boolean;
  companyName: string;
}) => {
  const { isGroup, isBottomUp, companyName } = data;

  const milestoneInfoBox = {
    groupTopDown: `You can only set a milestones for ${companyName} since this target is using the “top-down” approach.`,
    groupBottomUp: `${companyName}'s milestone for this year is calculated from values added by subsidiaries, since this target is using the “bottom-up” approach. You can only select a milestone year as a guidance for your subsidiaries.`,
    subTopDown: `You can only set a milestones for ${companyName} since this target is using the “top-down” approach.`,
    subBottomUp: `Since this target is set using a bottom-up approach, the aggregated milestone value for ${companyName} is calculated based on the input information for each business unit's milestone.`,
  };

  if (isGroup) {
    if (isBottomUp) return milestoneInfoBox.groupBottomUp;
    else return milestoneInfoBox.groupTopDown;
  }
  if (isBottomUp) return milestoneInfoBox.subBottomUp;
  return milestoneInfoBox.subTopDown;
};

export const getMilestoneTableTitle = (data: {
  isGroup: boolean;
  isBottomUp: boolean;
  companyName: string;
  year: number | undefined;
}) => {
  const { isGroup, isBottomUp, companyName, year } = data;

  const titles = {
    groupTopDown: `Subsidiaries goals for ${year}`,
    groupBottomUp: `${companyName} goal (aggregated from subsidiaries)`,
    subBottomUp: `${companyName} goal (aggregated from business units)`,
  };

  if (isGroup) {
    if (isBottomUp) return titles.groupBottomUp;
    else return titles.groupTopDown;
  }
  return titles.subBottomUp;
};

export const getBaselineInfoBoxText = (data: {
  isGroup: boolean;
  isBottomUp: boolean;
  companyName: string;
}) => {
  const { isGroup, isBottomUp, companyName } = data;

  const baselineInfoBox = {
    groupTopDown: `You can only set a baseline for ${companyName} since this target is using the “top-down” approach.`,
    groupBottomUp: `${companyName} baseline is calculated from values added by subsidiaries, since this target is using the “bottom-up” approach.
    You can only select a baseline year for which the subsidiaries will be prompted to input data.`,
    subTopDown: `You can only set a baseline for ${companyName} since this target is using the “top-down” approach.`,
    subBottomUp: `Since this target is set using a bottom-up approach, the aggregated baseline for ${companyName} will be calculated based on the input information for each business unit baseline.`,
  };

  if (isGroup) {
    if (isBottomUp) return baselineInfoBox.groupBottomUp;
    else return baselineInfoBox.groupTopDown;
  }
  if (isBottomUp) return baselineInfoBox.subBottomUp;
  return baselineInfoBox.subTopDown;
};
