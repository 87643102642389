import { Box, HStack, IconButton, VStack, useDisclosure } from '@chakra-ui/react';
import { Button, Infobox } from 'Atoms';
import { GetFlaggedTargetsQuery_ } from 'models';
import { useNavigate, useParams } from 'react-router-dom';
import { getDisclosureRequirementLink } from 'utils/links';
import { DREnums } from '../../DisclosureRequirements';
import { Modal, Table } from 'Molecules';
import { TargetIcon } from 'Tokens/Icons/Data';
import { Typography } from 'Tokens';
import { ArrowUpRightIcon } from 'Tokens/Icons/Direction';

export const FlaggedTargets = ({ targets }: { targets?: GetFlaggedTargetsQuery_['targets'] }) => {
  const { companyId = '', esrsAssessmentId = '' } = useParams();
  const navigate = useNavigate();
  const { onOpen, isOpen, onClose } = useDisclosure();

  const infoText = {
    single: {
      title: 'Review a target updates',
      description: `The parent company updated baseline year in a target ${targets?.[0]?.name}. Please review and update the baseline and milestones values to make sure a correct reporting.`,
      button: 'Open target',
    },
    multiple: {
      title: 'Review targets updates',
      description:
        'The parent company updated baseline year in several targets. Please review and update the baseline and milestones values to make sure a correct reporting.',
      button: 'See targets',
    },
  };

  const state = targets?.length === 1 ? 'single' : 'multiple';

  const handleButton = () => {
    if (state === 'single') {
      const target = targets?.[0];
      navigate(
        `${getDisclosureRequirementLink({
          companyId,
          assessmentId: esrsAssessmentId,
          standardRef: target?.materialStandard.standardRef,
          disclosureRequirement: {
            reference: target?.disclosureRequirementRef ?? '',
            type: DREnums.target,
          },
        })}/target-form/${target?.id}/edit`
      );
    } else {
      onOpen();
    }
  };

  return (
    <Box width="100%" mt="16px">
      <Infobox
        status="warning"
        title={infoText[state].title}
        description={infoText[state].description}
        withIcon={false}
        closable={false}
        rightElement={
          <Button variant="secondary" onClick={handleButton}>
            {infoText[state].button}
          </Button>
        }
      />
      <Modal isOpen={isOpen} onClose={onClose} title="Review targets updates" size="md">
        <VStack spacing="16px" alignItems="start" width="100%">
          <Infobox
            status="neutral"
            description="Open each target to review and update the baseline and milestones values to make sure a correct reporting"
            withIcon={false}
            closable={false}
          />
          <Table<GetFlaggedTargetsQuery_['targets'][number]>
            rowProps={{
              _hover: {
                cursor: 'pointer',
                bg: 'bg.hover',
              },
            }}
            cellPadding="0px 8px"
            headerPadding="0px"
            headerBorderColor="border.decorative"
            onRowClick={(row) => {
              navigate(
                `${getDisclosureRequirementLink({
                  companyId,
                  assessmentId: esrsAssessmentId,
                  standardRef: row?.materialStandard.standardRef,
                  disclosureRequirement: {
                    reference: row?.disclosureRequirementRef ?? '',
                    type: DREnums.target,
                  },
                })}/target-form/${row?.id}/edit`
              );
            }}
            columns={[
              {
                id: 'name',
                cell: ({ row }) => (
                  <HStack>
                    <TargetIcon />
                    <Typography>{row.original.name}</Typography>
                  </HStack>
                ),
              },
              {
                id: 'standard',
                cell: ({ row }) => (
                  <Typography>{row.original.materialStandard.standard.title}</Typography>
                ),
              },
              {
                id: 'link',
                meta: {
                  width: '5%',
                },
                cell: () => (
                  <IconButton
                    aria-label="open sub"
                    variant="ghost"
                    size="sm"
                    icon={<ArrowUpRightIcon />}
                  />
                ),
              },
            ]}
            data={targets ?? []}
          />
        </VStack>
      </Modal>
    </Box>
  );
};
