import { Box, VStack } from '@chakra-ui/react';
import { EmptyState, Tabs } from 'Atoms';
import { Loader } from 'Molecules';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { ActivitiesIllustration, Typography } from 'Tokens';
import { ActionsOverview } from './ActionsOverview';
import { useGetGroupStandardsOverview } from './GroupAssessmentOverview.hooks';
import { TargetsOverview } from './TargetsOverview';
import { GroupTopicsOverview } from './TopicsOverview';

export const GroupAssessmentOverview = ({
  esrsAssessmentId,
  companyName,
}: {
  esrsAssessmentId: string;
  companyName: string;
}) => {
  const { categories, loading } = useGetGroupStandardsOverview('all', esrsAssessmentId);
  const navigate = useNavigate();

  const noStandardsToShow = useMemo(
    () => categories.filter((c) => c.materialStandards.length).length === 0,
    [categories]
  );

  if (loading) return <Loader />;

  return (
    <>
      {noStandardsToShow ? (
        <Box w="100%" h="100%" p="16px">
          <EmptyState
            title="Start with materiality assessment"
            description="Add material standards, disclosure requirements and metrics to get started"
            icon={<ActivitiesIllustration boxSize="120px" />}
            callToAction={{
              text: 'Begin assessment',
              variant: 'primary',
              onClick: () => navigate('settings'),
            }}
          />
        </Box>
      ) : (
        <VStack spacing="8px" w="100%" alignItems="start" p="32px 16px 56px">
          <Typography variant="h2">{`${companyName} overview`}</Typography>
          <Tabs
            defaultIndex={0}
            items={[
              {
                title: 'Topics',
                id: 'topics',
                content: <GroupTopicsOverview esrsAssessmentId={esrsAssessmentId} />,
              },
              {
                title: 'Targets',
                id: 'targets',
                content: <TargetsOverview esrsAssessmentId={esrsAssessmentId} />,
              },
              {
                title: 'Actions',
                id: 'actions',
                content: <ActionsOverview esrsAssessmentId={esrsAssessmentId} />,
              },
            ]}
          />
        </VStack>
      )}
    </>
  );
};
