import { Tooltip as ChakraTooltip, TooltipProps } from '@chakra-ui/react';
import { cssVar } from '@chakra-ui/theme-tools';

const arrowBg = cssVar('popper-arrow-bg');

export const TooltipTheme = {
  baseStyle: {
    bg: 'bg.accent',
    color: 'text.onAccent',
    fontSize: 'md',
    fontWeight: 'normal',
    borderRadius: '8px',
    padding: '8px',
    maxW: '164px',
    mt: '-4px',
    [arrowBg.variable]: 'bg.accent',
  },
};

export const Tooltip = ({ ...props }: TooltipProps) => {
  return <ChakraTooltip {...props}>{props.children}</ChakraTooltip>;
};
