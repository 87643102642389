import { Box, HStack, Input, VStack } from '@chakra-ui/react';
import { useUserData } from '@nhost/react';
import { Button, ExternalLink, FormField, Infobox, Tabs } from 'Atoms';
import { UserSessionsTable } from 'Features/UserSessions';
import { ContentLayout, Select } from 'Molecules';
import { Typography } from 'Tokens';
import { CompanyIcon, GroupIcon } from 'Tokens/Icons/Data';
import mixpanel from 'mixpanel-browser';
import {
  AiConfig_Constraint_,
  AiConfig_Update_Column_,
  CompanyDetails,
  CompanyDetailsDocument_,
  User,
  UserDetailsDocument_,
  useUpdateCompanyMutation,
  useUpdateUserProfileMutation,
} from 'models';
import { Controller, useForm } from 'react-hook-form';
import { useCurrentCompany, useToast } from 'utils/hooks';
import { TRACKING_EVENTS } from 'utils/mixpanel';
import { nhost } from 'utils/nhost';
import { Trans, getLanguageMap, useTranslation } from 'utils/translation';
import { CurrencyField } from './pieces';
import { UserAvatar } from 'Organisms';
import { DarkModeSwitch } from 'Atoms/DarkModeSwitch';
import { SectionHeader } from './pieces/SectionHeader';
import { ImageButton } from 'Atoms/ImageButton';
import { SquareIconWrapper } from 'Tokens/Icons/SquareIconWrapper';
import { AnswersLanguageField } from './pieces/AnswersLanguageField';
import { ToneOfVoiceField } from './pieces/ToneOfVoiceField';
import { Language } from 'utils/language';

type CompanyFields = {
  name: string;
  isPortfolioOwner: boolean;
  currency: string;
  logoUrl: string | null;
  aiPreferredLanguage: string;
  aiToneOfVoice: string | null;
};

const LOGO_MAX_SIZE = 1310720;
const CompanyDetailsForm = ({
  company,
  onSave,
}: {
  company: CompanyDetails;
  onSave?: (Company: CompanyFields) => Promise<any>;
}) => {
  const [updateCompany] = useUpdateCompanyMutation();
  const toast = useToast();
  const { t } = useTranslation('common');
  const {
    control,
    register,
    handleSubmit,
    setValue,
    formState: { isDirty },
  } = useForm<CompanyFields>({
    defaultValues: {
      name: company.name,
      isPortfolioOwner: company.isPortfolioOwner,
      currency: company.currency,
      logoUrl: company.logoUrl ?? '',
      aiPreferredLanguage: company.aiConfig?.preferredLanguage ?? Language.EnglishUK,
      aiToneOfVoice: company.aiConfig?.toneOfVoice,
    },
  });
  const handleSaveCompanySettings = async (values: CompanyFields) => {
    const { name, isPortfolioOwner, currency, logoUrl, aiPreferredLanguage, aiToneOfVoice } =
      values;

    if (company.name !== name) {
      mixpanel.track(TRACKING_EVENTS.SETTINGS.CHANGE_COMPANY_NAME, {
        companyId: company.id,
        oldName: company.name,
        newName: name,
      });
    }
    if (company.currency !== currency) {
      mixpanel.track(TRACKING_EVENTS.SETTINGS.CHANGE_COMPANY_CURRENCY, {
        companyId: company.id,
        oldCurrency: company.currency,
        newCurrency: currency,
      });
    }

    return updateCompany({
      variables: {
        company: {
          id: company.id,
          name,
          logoUrl: logoUrl === '' ? undefined : logoUrl,
          currency,
          isPortfolioOwner,
          aiConfig: {
            data: {
              preferredLanguage: aiPreferredLanguage,
              toneOfVoice: aiToneOfVoice,
            },
            on_conflict: {
              constraint: AiConfig_Constraint_.AiConfigPkey_,
              update_columns: [
                AiConfig_Update_Column_.PreferredLanguage_,
                AiConfig_Update_Column_.ToneOfVoice_,
              ],
            },
          },
        },
      },
      refetchQueries: [CompanyDetailsDocument_],
    })
      .then(() => {
        toast({
          text: `${t('common:toast.success')} ${t('common:toast.companyUpdated.description')}`,
        });
        if (onSave) {
          onSave(values);
        }
      })
      .catch(() =>
        toast({
          text: `${t('common:toast.companyUpdateFailed.title')} 
          ${t('common:toast.companyUpdateFailed.description')}`,
          variant: 'danger',
        })
      );
  };

  const deleteLogo = () => {
    setValue('logoUrl', null);
    handleSubmit(handleSaveCompanySettings)();
  };

  const handleSaveImage = async (imgFile: File | null) => {
    if (!imgFile) {
      return;
    }
    if (imgFile?.size > LOGO_MAX_SIZE) {
      return toast({
        text: "Logo file size can't be greater than 10MB. Please upload a smaller file.",
        variant: 'danger',
      });
    }
    const { fileMetadata, error } = await nhost.storage.upload({
      file: imgFile,
      bucketId: 'logos',
    });
    if (error) {
      return toast({
        text: `${t('common:toast.error')}, ${error?.message}`,
        variant: 'danger',
      });
    }
    const publicUrl = nhost.storage.getPublicUrl({ fileId: fileMetadata.id });
    setValue('logoUrl', publicUrl);
    handleSubmit(handleSaveCompanySettings)();
  };
  return (
    <form onSubmit={handleSubmit(handleSaveCompanySettings)} style={{ width: '100%' }}>
      <VStack alignItems="start" spacing="48px" w="100%">
        <VStack w="100%" alignItems="start" spacing="24px">
          {' '}
          <SectionHeader title="Company details" />
          <HStack justifyContent="space-between" w="100%" spacing="48px" alignItems="start">
            <VStack maxWidth="42em" spacing="md" alignItems="flex-start">
              <FormField id="name" isRequired label={t('common:fields.companyName.label')}>
                <Input size="md" width="100%" {...register('name')} />
              </FormField>

              <FormField id="type" label={t('common:fields.type.label')}>
                {company.isPortfolioOwner ? (
                  <Infobox
                    status="neutral"
                    closable={false}
                    icon={
                      <Box pt="3px" color="text.muted">
                        <CompanyIcon boxSize="20px" />
                      </Box>
                    }
                    title={t('common:fields.type.portfolioLabel')}
                    description={
                      <Trans>
                        <Typography variant="body" color="text.primary">
                          {t('common:fields.type.descriptionSelected')}
                          <span style={{ fontStyle: 'italic' }}>
                            {t('common:fields.type.IFtype')}
                          </span>
                          {t('common:fields.type.IFdescription')}
                          <ExternalLink
                            href="https://www.celsia.io/use-cases/companies"
                            target="_blank"
                            rel="noopener noreferrer"
                            fontSize="body"
                            textDecoration="none"
                          >
                            {t('common:fields.type.IFlink')}
                          </ExternalLink>
                          .
                        </Typography>
                      </Trans>
                    }
                  />
                ) : company.isGroupOwner ? (
                  <Infobox
                    status="neutral"
                    closable={false}
                    icon={
                      <Box pt="3px" color="text.muted">
                        <GroupIcon boxSize="20px" />
                      </Box>
                    }
                    title={t('common:fields.companyType.groupLabel')}
                    description={
                      <Trans>
                        <Typography variant="body" color="text.primary">
                          {t('common:fields.type.descriptionSelected')}
                          <span style={{ fontStyle: 'italic' }}>
                            {t('common:fields.companyType.groupLabel')}
                          </span>
                          {t('common:fields.type.GPdescription')}
                          <ExternalLink
                            href="https://www.celsia.io/use-cases/companies"
                            target="_blank"
                            rel="noopener noreferrer"
                            fontSize="body"
                            textDecoration="none"
                          >
                            {t('common:fields.type.GP1link')}
                          </ExternalLink>
                          {t('words.and')}
                          <ExternalLink
                            href="https://www.celsia.io/use-cases/investment-funds"
                            target="_blank"
                            rel="noopener noreferrer"
                            fontSize="body"
                            textDecoration="none"
                          >
                            {t('common:fields.type.GP2link')}
                          </ExternalLink>
                          .
                        </Typography>
                      </Trans>
                    }
                  />
                ) : (
                  <Infobox
                    status="neutral"
                    closable={false}
                    icon={
                      <Box pt="3px" color="text.muted">
                        <CompanyIcon boxSize="20px" />
                      </Box>
                    }
                    title={t('common:fields.type.regularLabel')}
                    description={
                      <Trans>
                        <Typography variant="body" color="text.primary">
                          {t('common:fields.type.descriptionSelected')}
                          <span style={{ fontStyle: 'italic' }}>
                            {t('common:fields.type.BEtype')}
                          </span>
                          {t('common:fields.type.BEdescription')}
                          <ExternalLink
                            href="https://www.celsia.io/use-cases/investment-funds"
                            target="_blank"
                            rel="noopener noreferrer"
                            fontSize="body"
                            textDecoration="none"
                          >
                            {t('common:fields.type.BElink')}
                          </ExternalLink>
                          .
                        </Typography>
                      </Trans>
                    }
                  />
                )}

                <Typography variant="detail" color="text.muted" mt="8px">
                  {t('common:fields.type.changeType')}
                </Typography>
              </FormField>
            </VStack>
            <VStack w="56px">
              <FormField id="photo" label={'Logo'}>
                <ImageButton
                  imageUrl={company.logoUrl ?? ''}
                  onSaveImage={handleSaveImage}
                  defaultImage={
                    <SquareIconWrapper
                      size="44px"
                      borderRadius="10px"
                      color="text.muted"
                      icon={CompanyIcon}
                    />
                  }
                  onDelete={deleteLogo}
                />
              </FormField>
            </VStack>
          </HStack>
        </VStack>

        <VStack w="100%" alignItems="start" spacing="24px">
          <SectionHeader title="Units" />
          <VStack alignItems="start" w="100%" gap="24px">
            <Controller
              name="currency"
              control={control}
              render={({ field: { onChange, value } }) => (
                <CurrencyField value={value} setValue={onChange} />
              )}
            />
          </VStack>
        </VStack>

        <VStack w="100%" alignItems="start" spacing="24px">
          <SectionHeader title="AI settings" />
          <Controller
            name="aiPreferredLanguage"
            control={control}
            render={({ field: { onChange, value } }) => (
              <AnswersLanguageField value={value} setValue={onChange} />
            )}
          />
          <Controller
            name="aiToneOfVoice"
            control={control}
            render={({ field: { onChange, value } }) => (
              <ToneOfVoiceField value={value ?? ''} onChange={onChange} />
            )}
          />
        </VStack>

        <Button width="auto" type="submit" variant="primary" isDisabled={!isDirty}>
          {t('common:settings.saveDetails')}
        </Button>
      </VStack>
    </form>
  );
};

type UserFields = {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  avatarUrl: string | null;
  displayName: string;
};

const UserAccountForm = ({
  user,
  onSave,
}: {
  user: User;
  onSave?: (user: UserFields) => Promise<any>;
}) => {
  const [updateUser] = useUpdateUserProfileMutation();
  const toast = useToast();
  const { t } = useTranslation(['common', 'settings']);
  const langMap = getLanguageMap();
  const languageOptions = Object.keys(langMap).map((key) => ({
    value: key,
    label: `${langMap[key]} ${key !== 'en' ? `(Coming soon!)` : ''}`,
  }));
  const {
    register,
    handleSubmit,
    setValue,
    formState: { isDirty },
  } = useForm<UserFields>({
    defaultValues: {
      displayName: user.displayName,
      avatarUrl: user.avatarUrl ?? '',
    },
  });
  const { handleSubmit: handleLanguageSubmit, control: languageControl } = useForm<{
    language: string;
  }>({
    defaultValues: {
      language: user.locale,
    },
  });
  const changeUser = ({ language }: any) => {
    const triggerRefresh = user.locale === language ? false : true;
    updateUser({
      variables: {
        id: user.id,
        user: {
          locale: language,
        },
      },
    }).then(() => {
      toast({ text: t('common:changeLanguage.succeeded') });
      if (triggerRefresh) {
        location.reload();
      }
    });
  };
  const handleSaveUserSettings = async (values: UserFields) => {
    const { displayName, avatarUrl } = values;

    try {
      await updateUser({
        variables: {
          id: user.id,
          user: {
            displayName,
            avatarUrl,
          },
        },
        refetchQueries: [UserDetailsDocument_],
      });

      await nhost.auth.refreshSession();
      toast({
        text: `${t('common:toast.success')} ${t('common:toast.userUpdated.description')}`,
      });
      if (onSave) {
        onSave(values);
      }
    } catch (e) {
      toast({
        text: `${t('common:toast.userUpdateFailed.title')}
            ${t('common:toast.userUpdateFailed.description')}`,
        variant: 'danger',
      });
    }
  };

  const handleSaveAvatar = async (imgFile: File | null) => {
    if (!imgFile) {
      return;
    }
    if (imgFile?.size > LOGO_MAX_SIZE) {
      return toast({
        text: "Logo file size can't be greater than 2MB. Please upload a smaller file.",
        variant: 'danger',
      });
    }
    const { fileMetadata, error } = await nhost.storage.upload({
      file: imgFile,
      bucketId: 'avatars',
    });
    if (error) {
      return toast({
        text: `${t('common:toast.error')}, ${error?.message}`,
        variant: 'danger',
      });
    }
    const publicUrl = nhost.storage.getPublicUrl({ fileId: fileMetadata.id });
    setValue('avatarUrl', publicUrl);
    handleSubmit(handleSaveUserSettings)();
  };
  const handleDeleteAvatar = () => {
    setValue('avatarUrl', 'default=blank');
    handleSubmit(handleSaveUserSettings)();
  };
  return (
    <form onSubmit={handleSubmit(handleSaveUserSettings)} style={{ width: '100%' }}>
      <VStack alignItems="flex-start" w="100%" spacing="48px">
        <VStack alignItems="start" spacing="24px" w="100%">
          <SectionHeader title={t('settings:account.details')} />
          <HStack justifyContent="space-between" alignItems="top" w="100%">
            <VStack maxWidth="42em" spacing="md" alignItems="flex-start">
              <FormField id="name" label={'Name'}>
                <Input size="md" width="100%" {...register('displayName')} />
              </FormField>

              <FormField id="email" label={'Email'}>
                <Typography variant="body">{user.email}</Typography>
                <Typography variant="detail" color="text.muted" mt="8px">
                  {t('settings:account.changeEmail')}
                </Typography>
              </FormField>
            </VStack>
            <VStack alignItems="start">
              <FormField id="photo" label={'Photo'}>
                <ImageButton
                  imageUrl={user.avatarUrl}
                  defaultImage={<UserAvatar user={user} boxSize="44px" />}
                  isRound
                  onSaveImage={handleSaveAvatar}
                  onDelete={handleDeleteAvatar}
                />
              </FormField>
            </VStack>
          </HStack>
        </VStack>
        <VStack alignItems="start" spacing="24px" w="100%">
          <SectionHeader title={t('settings:account.appPersonalization')} />
          <VStack alignItems="start" w="100%" spacing="8px">
            <Typography variant="bodyStrong">Language</Typography>

            <form onSubmit={handleLanguageSubmit(changeUser)} id="language-editor-form">
              <VStack spacing="md" alignItems="flex-start">
                <Box width="26em">
                  <Controller
                    control={languageControl}
                    defaultValue={user.locale}
                    name="language"
                    render={({ field: { onChange, value } }) => (
                      <Select<{ label: string; value: string }>
                        value={languageOptions.filter((c) => value.includes(c.value))}
                        onChange={(val) => val?.value && onChange(val.value)}
                        options={languageOptions}
                        isOptionDisabled={(option) => option.value !== 'en'}
                      />
                    )}
                  />
                </Box>
              </VStack>
            </form>
          </VStack>
          <VStack alignItems="start" w="100%" spacing="8px">
            <Typography variant="bodyStrong">Color mode</Typography>
            <DarkModeSwitch />
          </VStack>
          <Button width="auto" type="submit" variant="primary" isDisabled={!isDirty}>
            {t('common:settings.saveDetails')}
          </Button>
        </VStack>
      </VStack>
    </form>
  );
};

export function Settings() {
  const { company } = useCurrentCompany();
  const user = useUserData();
  // const session = nhost.auth.getSession()?.refreshTokenId; // Use this to show which sessions is the current
  const { t } = useTranslation('common');

  return (
    <ContentLayout variant="narrow" header={t('common:menu.settings')}>
      <Tabs
        defaultIndex={0}
        items={[
          {
            title: 'Company details',
            id: 'Company',
            content: (
              <VStack alignItems="flex-start" mt="32px" w="100%">
                {company && <CompanyDetailsForm company={company} />}
              </VStack>
            ),
          },
          {
            title: 'My account',
            id: 'User',
            content: (
              <VStack alignItems="flex-start" mt="32px" w="100%">
                {user && <UserAccountForm user={user} />}
              </VStack>
            ),
          },
          {
            title: 'Sessions',
            id: 'Sessions',
            content: (
              <VStack alignItems="flex-start" m="24px 0px">
                <UserSessionsTable />
              </VStack>
            ),
          },
        ]}
      />
    </ContentLayout>
  );
}
