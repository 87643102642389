import { HStack, VStack } from '@chakra-ui/react';
import { useMemo } from 'react';
import { Typography } from 'Tokens';
import { MetricsTableData } from '../MetricAnswers.hooks';
import { mapUnitToCompanyCurrency } from 'containers/Esrs/utils';
import { getMetricRefNumber } from '../Metrics.hooks';

export const MetricOverview = ({
  row,
  metric,
  materialStandardId,
  companyCurrency,
}: {
  row?: MetricsTableData;
  metric: MetricsTableData['metric'];
  materialStandardId: string;
  companyCurrency: string;
}) => {
  const frequency = useMemo(
    () =>
      metric.materialMetrics.find((mm) => mm.materialStandardId === materialStandardId)?.frequency,
    [metric, materialStandardId]
  );

  const metricRef = useMemo(() => getMetricRefNumber(metric), [metric]);

  return (
    <VStack alignItems="start" width="100%" p="24px 20px" spacing="12px">
      <HStack w="100%" py="4px" alignItems="start">
        <Typography variant="body" width="120px">
          Collected
        </Typography>
        <Typography variant="body" width="120px">
          {frequency}
        </Typography>
      </HStack>

      {row && (
        <HStack w="100%" py="4px" alignItems="start">
          <Typography variant="body" width="120px">
            Unit
          </Typography>
          <Typography variant="body" width="120px">
            {mapUnitToCompanyCurrency(metric.unitOfMeasurement, companyCurrency)}
          </Typography>
        </HStack>
      )}

      <HStack w="100%" py="4px" alignItems="start">
        <Typography variant="body" width="120px">
          ESRS name
        </Typography>
        <Typography variant="body" width="120px">
          {metric.title}
        </Typography>
      </HStack>

      <HStack w="100%" py="4px" alignItems="start">
        <Typography variant="body" width="120px">
          ESRS reference
        </Typography>
        <Typography variant="body" width="120px">
          {metricRef}
        </Typography>
      </HStack>

      {row?.parentMetric && (
        <HStack w="100%" py="4px" alignItems="start">
          <Typography variant="body" width="120px">
            Parent metric
          </Typography>
          <Typography variant="body" width="120px">
            {row?.parentMetric?.title}
          </Typography>
        </HStack>
      )}
    </VStack>
  );
};
