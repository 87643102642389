import { Box, ChakraProps, Flex, HStack, SystemProps, VStack } from '@chakra-ui/react';
import { Button } from 'Atoms';
import { Typography } from 'Tokens';
import { FC, PropsWithChildren, useMemo } from 'react';
import { CardinalOrientation, WalktourLogic } from 'walktour';

const ORIENTATION_SETUP: {
  [key: string]: {
    direction: SystemProps['flexDirection'];
    inset: SystemProps['inset'];
  };
} = {
  [CardinalOrientation.EASTNORTH]: {
    direction: 'row',
    inset: '33% 0 0 0',
  },
  [CardinalOrientation.EASTSOUTH]: {
    direction: 'row',
    inset: '0 0 33% 0',
  },
  [CardinalOrientation.EAST]: {
    direction: 'row',
    inset: '0',
  },
  [CardinalOrientation.WESTNORTH]: {
    direction: 'row-reverse',
    inset: '-33% 0 0 0',
  },
  [CardinalOrientation.WESTSOUTH]: {
    direction: 'row-reverse',
    inset: '0 0 -33% 0',
  },
  [CardinalOrientation.WEST]: {
    direction: 'row-reverse',
    inset: '0',
  },
  [CardinalOrientation.NORTHEAST]: {
    direction: 'column-reverse',
    inset: '0 -33% 0 0',
  },
  [CardinalOrientation.NORTHWEST]: {
    direction: 'column-reverse',
    inset: '0 0 0 -33%',
  },
  [CardinalOrientation.NORTH]: {
    direction: 'column-reverse',
    inset: '0',
  },
  [CardinalOrientation.SOUTHEAST]: {
    direction: 'column',
    inset: '0 0 0 33%',
  },
  [CardinalOrientation.SOUTHWEST]: {
    direction: 'column',
    inset: '0 33% 0 0',
  },
  [CardinalOrientation.SOUTH]: {
    direction: 'column',
    inset: '0',
  },
  [CardinalOrientation.CENTER]: {
    direction: 'row',
    inset: '0',
  },
};

const TooltipStack: FC<
  PropsWithChildren<{ orientation: CardinalOrientation; props: ChakraProps }>
> = ({ orientation, props, children }) => {
  const { direction, inset } = useMemo(() => ORIENTATION_SETUP[orientation], [orientation]);
  return (
    <Flex
      {...props}
      direction={direction}
      justifyContent={direction === 'row' || direction === 'row-reverse' ? 'center' : 'flex-start'}
      alignItems="center"
      height={direction === 'row' || direction === 'row-reverse' ? '150px' : '300px'}
    >
      <Box
        backgroundColor="bg.promo"
        borderRadius="50%"
        w="10px"
        h="10px"
        zIndex="1"
        position="relative"
        inset={inset}
      />
      <Box
        borderColor="text.info"
        position="relative"
        inset={inset}
        {...(direction === 'row' || direction === 'row-reverse'
          ? { borderTop: '1px solid', width: '30px', height: '1px' }
          : {
              borderLeft: '1px solid',
              width: '1px',
              height: '30px',
            })}
      />
      {children}
    </Flex>
  );
};

export const WalktourComponent = (
  props: WalktourLogic | undefined,
  setWalktouStatus: (value: string) => void
) => {
  const handleClose = () => {
    setWalktouStatus('finished');
    props?.close();
  };

  const handleNext = (index: number) => {
    setWalktouStatus(String(index + 1));
    props?.next();
  };

  return (
    <TooltipStack
      orientation={props?.tooltipPosition?.orientation ?? CardinalOrientation.CENTER}
      props={{ gap: 0 }}
    >
      <VStack
        backgroundColor="bg.promo"
        w="285px"
        p="16px"
        borderRadius="8px"
        alignItems="start"
        gap="16px"
      >
        <VStack w="100%" alignItems="start">
          <Typography variant="h2" color="text.onAccent">
            {props?.stepContent.title}
          </Typography>
          <Typography variant="body" color="text.onAccent">
            {props?.stepContent.description}
          </Typography>
        </VStack>

        <HStack w="100%">
          <Typography variant="body" color="text.onAccent" flexGrow="1">
            {(props?.stepIndex ?? 0) + 1}/{props?.allSteps.length}
          </Typography>
          <Button
            variant="ghost"
            color="text.onAccent"
            size="sm"
            onClick={props?.stepIndex === 0 ? handleClose : props?.prev}
          >
            {props?.stepIndex === 0 ? 'Skip' : 'Back'}
          </Button>
          <Button
            variant="secondary"
            size="sm"
            onClick={() =>
              props?.stepIndex === (props?.allSteps?.length ?? 0) - 1
                ? handleClose()
                : handleNext(props?.stepIndex ?? 0)
            }
          >
            {props?.stepIndex === (props?.allSteps?.length ?? 0) - 1 ? 'Done' : 'Next'}
          </Button>
        </HStack>
      </VStack>
    </TooltipStack>
  );
};
