import {
  VStack,
  HStack,
  useDisclosure,
  Box,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Skeleton,
} from '@chakra-ui/react';
import { Button, Checkbox, EmptyState, Tag } from 'Atoms';
import { ActivitiesIllustration, Typography } from 'Tokens';
import {
  GENERAL_ACTIVITY_REF,
  useActivityAssessment,
} from 'containers/Assessments/Assessments.hooks';
import { mapValues } from 'lodash';
import { BAssessment, ResolvedQuestion } from 'models';
import { useMemo } from 'react';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { filterSubquestions } from 'utils/scores/multipleCriteriaQuestion';
import {
  isQuestionApplicable,
  isQuestionAnswered,
  isQuestionAligned,
  isQuestionNotAligned,
} from 'utils/scores/questions';
import { tenum, useTranslation } from 'utils/translation';

import { ActivitiesList, ActivityAssessmentQuestions } from './ActivityAssessment';
import { ActivitiesDrawer } from './ManageActivities/ActivitiesDrawer';
import { Loader } from 'Molecules';
import { AdjustIcon } from 'Tokens/Icons/Function';

const FILTER_DEFS = {
  [tenum('question:filter.all')]: (_q: ResolvedQuestion) => true,
  [tenum('question:filter.inProgress')]: filterSubquestions(
    (q: ResolvedQuestion) => isQuestionApplicable(q) && !isQuestionAnswered(q)
  ),
  [tenum('question:filter.aligned')]: filterSubquestions((q: ResolvedQuestion) =>
    isQuestionAligned(q)
  ),
  [tenum('question:filter.notAligned')]: filterSubquestions((q: ResolvedQuestion) =>
    isQuestionNotAligned(q)
  ),
};

export type QuestionFilter = {
  name: keyof typeof FILTER_DEFS;
  label: string;
  filter: (q: ResolvedQuestion) => boolean;
};
export const QUESTION_FILTERS = mapValues(
  FILTER_DEFS,
  (filter, name) =>
    ({
      filter,
      name,
      label: 'question:filter.' + name,
    }) as QuestionFilter
);

const QuestionsFilterSelector = ({ activityAssessmentId }: { activityAssessmentId: string }) => {
  const { t } = useTranslation('question');
  const { data, loading = true } = useActivityAssessment(activityAssessmentId);
  const questions = useMemo(() => {
    return data?.objectives.map((o) => o.questions).flat() ?? [];
  }, [data]);

  const [searchParams, setSearchParams] = useSearchParams();

  const selectedFilters = useMemo(() => {
    const filters = searchParams.get('filter');
    return filters ? filters.split('_') : [];
  }, [searchParams, questions]);

  const onChangeFilters = (newFilters: string[]) => {
    setSearchParams({ filter: newFilters.join('_') });
  };

  return (
    <Skeleton isLoaded={!loading} width="fit-content" justifyContent="stretch" height="32px">
      <Popover closeOnBlur={true} placement="bottom-start">
        <PopoverTrigger>
          <Button leftIcon={<AdjustIcon color="inherit" />} variant="secondary" size="md">
            {t('common:words.filter')}
          </Button>
        </PopoverTrigger>
        <PopoverContent as={VStack} spacing="8px" width="276px" padding="12px 8px">
          <HStack width="100%" justifyContent="space-between">
            <Typography variant="h4">Show: </Typography>
            <Button
              variant="ghost"
              size="sm"
              onClick={() =>
                setSearchParams({
                  filter: '',
                })
              }
            >
              {t('common:button.clearAll')}
            </Button>
          </HStack>
          {Object.values(QUESTION_FILTERS).map((questionFilter) => (
            <HStack
              key={questionFilter.label}
              width="100%"
              spacing="8px"
              paddingX="4px"
              height="36px"
              cursor="pointer"
              _hover={{
                bg: 'bg.hover',
              }}
              bg={selectedFilters.includes(questionFilter.name) ? 'bg.selected' : 'transparent'}
              onClick={() =>
                selectedFilters.includes(questionFilter.name)
                  ? onChangeFilters(selectedFilters.filter((f) => f !== questionFilter.name))
                  : onChangeFilters([...selectedFilters, questionFilter.name])
              }
            >
              <Checkbox
                isChecked={selectedFilters.includes(questionFilter.name)}
                onChange={(e) =>
                  e.currentTarget.checked
                    ? onChangeFilters(selectedFilters.filter((f) => f !== questionFilter.name))
                    : onChangeFilters([...selectedFilters, questionFilter.name])
                }
              />
              <Typography variant="bodyStrong">
                {t(questionFilter?.label)} ({questions.filter(questionFilter.filter).length ?? 0})
              </Typography>
            </HStack>
          ))}
        </PopoverContent>
      </Popover>
    </Skeleton>
  );
};

export const BusinessUnitAssessment = ({
  businessUnitAssessment,
  isLocked,
}: {
  businessUnitAssessment?: BAssessment;
  isLocked: boolean;
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { isOpen, onClose, onOpen } = useDisclosure();
  const { t } = useTranslation(['common', 'bUnits']);

  const { activityRef } = useParams();

  const activityAssessmentId = useMemo(() => {
    if (
      !activityRef &&
      businessUnitAssessment?.hasGeneralAssessment &&
      businessUnitAssessment?.generalAssessment[0]?.id
    ) {
      navigate(`${location.pathname}/${GENERAL_ACTIVITY_REF}`);
    }
    const firstGoodActivity = businessUnitAssessment?.activityReports.find(
      (act) => !!act.activity?.reference
    );
    if (!activityRef && firstGoodActivity) {
      navigate(`${location.pathname}/${firstGoodActivity.activity?.reference}`);
    }
    if (activityRef === GENERAL_ACTIVITY_REF) {
      return businessUnitAssessment?.generalAssessment[0]?.id;
    }
    return businessUnitAssessment?.activityReports.find(
      (ar) => ar.activity.reference === activityRef
    )?.id;
  }, [activityRef, businessUnitAssessment]);

  const activityAssessments = useMemo(() => {
    let activities = businessUnitAssessment?.activityReports ?? [];

    if (businessUnitAssessment?.hasGeneralAssessment) {
      activities = [businessUnitAssessment.generalAssessment[0], ...activities];
    }
    return activities;
  }, [businessUnitAssessment]);

  if (!businessUnitAssessment) {
    return <Loader />;
  }

  return (
    <HStack alignItems="stretch" padding="16px" width="100%">
      {activityAssessments.length ? (
        <>
          <ActivitiesList
            onClickManageActivities={onOpen}
            activities={activityAssessments}
            isEditable={!!businessUnitAssessment.businessUnit?.id && !isLocked} // TODO: Check if company level assessment
          />
          <VStack width="100%" alignItems="stretch" paddingX="16px">
            <HStack>
              <Typography variant="h2">{t('common:assessment.screening')}</Typography>
            </HStack>
            <HStack width="100%" justifyContent="space-between" paddingY="8px">
              <HStack>
                <Typography variant="body">{t('common:assessment.reportFor')}</Typography>
                <Tag>{businessUnitAssessment.businessUnit?.name || t('common:company')}</Tag>
              </HStack>
              <QuestionsFilterSelector activityAssessmentId={activityAssessmentId} />
            </HStack>
            <HStack width="100%" justifyContent="stretch">
              <ActivityAssessmentQuestions
                activityAssessmentId={activityAssessmentId}
                isLocked={isLocked}
              />
            </HStack>
          </VStack>
        </>
      ) : (
        <Box w="100%" flexGrow="1">
          <EmptyState
            title={t('bUnits:activities.noActivities')}
            description={t('bUnits:noSelectedActivities')}
            callToAction={
              isLocked
                ? undefined
                : {
                    text: t('bUnits:browseActivities'),
                    variant: 'secondary',
                    onClick: () => onOpen(),
                  }
            }
            icon={<ActivitiesIllustration boxSize="80px" />}
            component={true}
          />
        </Box>
      )}
      <ActivitiesDrawer isOpen={isOpen} onClose={onClose} bAssessment={businessUnitAssessment} />
    </HStack>
  );
};
