import { onError } from '@apollo/client/link/error';
import { captureException, withScope } from '@sentry/browser';
import axios from 'axios';
import { useToast } from 'utils/hooks/useToast';

const addAxiosInterceptors = () => {
  axios.interceptors.response.use(
    (response) => response,
    (error) => {
      withScope((scope) => {
        scope.setContext('axiosError', {
          response: error.response,
          request: error.request,
          config: error.config,
        });
        captureException(error);
      });
      return Promise.reject(error);
    }
  );
};

const ignoreErrorCodes = ['invalid-jwt'];

const addGraphlQLErrorHandling = () => {
  const toast = useToast();

  const errorLink = onError(({ graphQLErrors, networkError, operation }) => {
    if (graphQLErrors) {
      graphQLErrors.forEach((graphQLError) => {
        withScope((scope) => {
          scope.setContext('graphQLError', {
            message: graphQLError.message,
            locations: graphQLError.locations,
            path: graphQLError.path,
            extensions: graphQLError.extensions,
          });
          scope.setFingerprint([operation.operationName]);
          if (
            !!graphQLError.extensions?.code &&
            ignoreErrorCodes.includes(graphQLError.extensions?.code as string)
          ) {
            toast({
              text: 'Your session has expired. Please log in again.',
              variant: 'danger',
            });
          } else {
            captureException(new Error(`GraphQL Error: ${operation.operationName}`), {
              fingerprint: [operation.operationName],
              extra: {
                graphQLError,
              },
            });
          }
        });
      });
    }
    if (networkError) {
      toast({
        text: 'Could not connect to server. Please try again later.',
        variant: 'danger',
      });
    }
  });

  return { apolloErrorLink: errorLink };
};

export const useInitErrorHandling = () => {
  addAxiosInterceptors();
  const { apolloErrorLink } = addGraphlQLErrorHandling();
  return { apolloErrorLink };
};
