import React, { useMemo } from 'react';
import { Box, ChakraTheme, HStack, useStyleConfig } from '@chakra-ui/react';
import { Typography } from 'Tokens';
import { AutoResizeTextarea, Avatar } from 'Atoms';
import { useTranslation } from 'utils/translation';
import { timeSinceDate } from 'utils/date';
import { NoteHistory, ShortUser } from 'models';
import { AvatarGroup, NameWithLogo } from 'Molecules/AvatarGroup';
import { isEqual, uniqBy } from 'lodash';

export const NoteTheme: ChakraTheme['components']['Note'] = {
  baseStyle: {
    bg: 'bg.muted',
    minHeight: '44px',
    paddingX: '16px',
    borderRadius: '8px',
    border: '2px',
    width: '100%',
    borderColor: 'transparent',
    _focusWithin: {
      border: '2px',
      borderColor: 'border.selected.accent',
      bg: 'bg.default',
      _hover: {
        bg: 'bg.default',
      },
    },
    _hover: {
      bg: 'bg.hover',
    },
  },
};

export type NoteProps = {
  noteHistory?: NoteHistory;
  placeholder?: string;
  currentAuthor?: ShortUser;
  handleInputChange: (newNote: string | undefined | null) => void;
  isDisabled?: boolean;
  isAuditor?: boolean;
  isGenerating?: boolean;
  width?: string;
  opacity?: number;
};

const NoteStatus = ({
  authorList,
  updateText,
  isGenerating,
  isAIGenerated,
}: {
  authorList: NameWithLogo[];
  updateText: string;
  isGenerating: boolean;
  isAIGenerated: boolean;
}) => {
  const { t } = useTranslation('common');
  const AIuser = {
    logoUrl: '',
    name: 'AI',
  };
  if (isGenerating) {
    return (
      <HStack paddingBottom="12px" paddingTop="8px" justifyContent="start">
        <Avatar name="AI" size="xs" />
        <Typography variant="detail" color="text.muted">
          {t('common:note.aiWriting')}
        </Typography>
      </HStack>
    );
  }
  return (
    <HStack paddingBottom="12px" paddingTop="8px" justifyContent="start">
      {authorList &&
        (isAIGenerated ? (
          <AvatarGroup size="xs" namesOrLogos={[...authorList, AIuser]} />
        ) : (
          <AvatarGroup size="xs" namesOrLogos={authorList} />
        ))}
      <Typography variant="detail" color="text.muted">
        {updateText}
      </Typography>
    </HStack>
  );
};

export const Note = ({
  noteHistory,
  placeholder,
  handleInputChange,
  currentAuthor,
  isDisabled = false,
  isAuditor = false,
  isGenerating = false,
  width,
  opacity,
}: NoteProps) => {
  const translations = useTranslation('common');
  const { t } = translations;
  const NOTE_SAVE_DELAY_MS = 1000;
  const boxStyle = useStyleConfig('Note', {});

  const [text, setText] = React.useState<string>();
  const [lastUpdated, setLastUpdated] = React.useState<Date>();
  const [authorList, setAuthorList] = React.useState<NameWithLogo[]>();
  const [hasFocus, setFocus] = React.useState(false);
  const [initialLoad, setInitialLoad] = React.useState(true);

  const latestNote = useMemo(() => noteHistory?.notes[0], [noteHistory]);
  const [localLatestNote, setLocalLatestNote] = React.useState<string>();
  const currentUser = useMemo(
    () => ({
      name: currentAuthor?.displayName ?? t('common:user.deletedUserName'),
      logoUrl: currentAuthor?.avatarUrl,
    }),
    [currentAuthor]
  );

  React.useEffect(() => {
    if (initialLoad) {
      if (!!latestNote) {
        setLocalLatestNote(latestNote?.body ?? '');
        setText(latestNote?.body ?? '');
        setInitialLoad(false);
      }
    }
    setLastUpdated(new Date(latestNote?.updatedAt));
    setAuthorList(
      uniqBy(
        noteHistory?.notes.map((note) => ({
          name: note.author?.displayName ?? t('common:user.deletedUserName'),
          logoUrl: note.author?.avatarUrl,
        })),
        'name'
      ).slice(0, 3)
    );
  }, [latestNote]);

  React.useEffect(() => {
    if (text !== localLatestNote) {
      const debounceSave = setTimeout(() => {
        setLocalLatestNote(text);
        handleInputChange(text);
      }, NOTE_SAVE_DELAY_MS);

      return () => clearTimeout(debounceSave);
    }
  }, [text]);

  const setTextOnChange = (textArea: React.ChangeEvent<HTMLTextAreaElement>) => {
    textArea.stopPropagation();
    const inputValue = textArea.target.value;
    setLastUpdated(new Date());
    if (!isEqual(authorList?.[0], currentUser)) {
      const currentAuthorList = authorList;
      if (currentAuthorList?.length === 3) {
        currentAuthorList?.pop();
      }
      if (currentUser) {
        currentAuthorList?.unshift(currentUser);
      }
      setAuthorList(currentAuthorList);
    }
    setText(inputValue);
  };

  const updateText = useMemo(() => {
    if (hasFocus) {
      return t('common:note.changesSaved');
    } else if (lastUpdated) {
      const time = timeSinceDate(lastUpdated, translations);
      if (latestNote?.isAIGenerated) return t('common:note.aiGenerated', { time: time });
      return t('common:note.lastUpdated', { time: time });
    }
  }, [hasFocus, lastUpdated]);

  const hasAIGeneratedNotes = useMemo(() => {
    return noteHistory?.notes.some((note) => note.isAIGenerated);
  }, [noteHistory]);

  return (
    <Box __css={boxStyle} w={width} opacity={opacity} data-testid={noteHistory?.id}>
      <AutoResizeTextarea
        isDisabled={isDisabled || isAuditor || isGenerating}
        placeholder={placeholder ? placeholder : t('common:note.documentOptional')}
        value={text ?? undefined}
        noOfLines={isDisabled ? undefined : 5}
        onChange={(textArea) => setTextOnChange(textArea)}
        onClick={() => {
          setFocus(true);
        }}
        onBlur={() => {
          if (text !== localLatestNote) {
            handleInputChange(text);
          }
          setFocus(false);
        }}
        paddingTop="12px"
        variant="unstyled"
        maxHeight={isDisabled ? '100%' : '112px'}
        textOverflow="ellipsis"
        textColor="text.muted"
        _placeholder={{
          opacity: '1',
          color: 'text.muted',
        }}
        _focus={{
          maxHeight: '400px',
          noOfLines: -1,
          _placeholder: {
            opacity: '1',
            color: 'text.hint',
          },
        }}
      />
      {updateText && text && (
        <NoteStatus
          authorList={authorList ?? []}
          isGenerating={isGenerating}
          isAIGenerated={(latestNote?.isAIGenerated || hasAIGeneratedNotes) ?? false}
          updateText={updateText}
        />
      )}
    </Box>
  );
};
