import { PortfolioCompanyScore } from 'containers/Portfolios/Portfolios.hooks';
import { EntityAlignmentStatus } from 'Organisms/AlignmentStatus';
import {
  Answer_Insert_Input_,
  Comment_Insert_Input_,
  Thread_Insert_Input_,
  FinancialsFieldsFragment_,
  ShortUserFragment_,
  CommentFieldsFragment_,
  ThreadFieldsFragment_,
  BusinessUnitAssessmentFieldsFragment_,
  SimpleBusinessUnitFragment_,
  CompanyDetailsFieldsFragment_,
  UserFragment_,
  NotificationFieldsFragment_,
  GetInvitationQuery_,
  DocumentationFileDetailsFragment_,
  AnswerFieldsFragment_,
  ActivityFieldsFragment_,
  CompanyAssessmentDetailsFieldsFragment_,
  ReportingGroupFieldsFragment_,
  CompanyAssessmentQuery_,
  QuestionFieldsFragment_,
  ActivityReportFieldsFragment_,
  ActivityAssessmentQuery_,
  PortfolioFieldsFragment_,
  CachedResult_,
  CachedResultFieldsFragment_,
  CompanyAssessmentCachedResultsSubscription_,
  InvestorFieldsFragment_,
  CompaniesFromAllPortfoliosQuery_,
  PaiCategoryFieldsFragment_,
  PaiIndicatorFieldsFragment_,
  InvesteeMetricFieldsFragment_,
  InvestorMetricFieldsFragment_,
  PaiTableFieldsFragment_,
  PaiPortfolioReportFieldsFragment_,
  PaiReportRequestsQuery_,
  PaiCompanyReportsQuery_,
  GetPaiAnswerSubscription_,
  ShortAnswerFieldsFragment_,
  NoteHistoryFragment_,
  AttachmentBoxFragment_,
  AttachmentFragment_,
  AllActivityAssessmentsWithVersionQuery_,
} from './__generated__/graphql';
import { ObjectiveId } from 'utils/objectives/objectivesOrder';

export type Proportion = {
  revenue: number;
  capex: number;
  opex: number;
};

export type SubstantialContributionProportion = Record<EnvironmentalObjectiveKeyEnum, Proportion>;

export enum ActivityTagEnum {
  enabling = 'ENABLING',
  transitional = 'TRANSITIONAL',
  green = 'GREEN',
}

export enum ScoreSectionsEnum {
  revenue = 'revenue',
  capex = 'capex',
  opex = 'opex',
}
export enum AdaptationSectionsEnum {
  adaptationCapex = 'adaptationCapex',
  adaptationOpex = 'adaptationOpex',
}

export type ScoreSection = ScoreSectionsEnum;
export type FinancialSection = ScoreSectionsEnum | AdaptationSectionsEnum;

export type CompanyDetails = CompanyDetailsFieldsFragment_;
export type User = Omit<UserFragment_, 'updatedAt'>;

export type PortfolioCompany = PortfolioFieldsFragment_['portfolioCompanies'][number] & {
  scores?: PortfolioCompanyScore['scores'];
  progress?: PortfolioCompanyScore['progress'];
  substantialContributionProportion?: SubstantialContributionProportion;
  valueOfInvestments?: ValueOfInvestments;
};
export type Portfolio = Omit<PortfolioFieldsFragment_, 'portfolioCompanies'> & {
  portfolioCompanies: PortfolioCompany[];
};
export type Notification = NotificationFieldsFragment_;
export type BusinessUnit = SimpleBusinessUnitFragment_;
export type CompanyAssessment = CompanyAssessmentQuery_['companyAssessment'];
export type DocumentationFile = DocumentationFileDetailsFragment_;
export type Activity = ActivityFieldsFragment_;
export type ActivityRef = Activity['reference'];
export type ActivityAssessment = ActivityReportFieldsFragment_ & { activityTag: ActivityTagEnum };
export type CompanyAssessmentDetails = CompanyAssessmentDetailsFieldsFragment_;
export type ReportingGroup = ReportingGroupFieldsFragment_;
export type BAssessment = BusinessUnitAssessmentFieldsFragment_;
export type Question = QuestionFieldsFragment_;
export type Answer = AnswerFieldsFragment_;
export type ShortAnswer = ShortAnswerFieldsFragment_;
export type Comment = CommentFieldsFragment_;
export type Thread = ThreadFieldsFragment_;
export type ShortUser = ShortUserFragment_;
export type Financials = FinancialsFieldsFragment_;
export type NoteHistory = NoteHistoryFragment_;
export type AttachmentBox = AttachmentBoxFragment_;
export type Attachment = AttachmentFragment_;
export type BareFinancials = Omit<Financials, 'id'>;
export type CompanyAssessmentResult = CachedResult_;
export type RawCompanyAssessmentResults = NonNullable<
  CompanyAssessmentCachedResultsSubscription_['companyResults']
>;
export type Investor = InvestorFieldsFragment_;
export type InvestorOwnedCompany = CompaniesFromAllPortfoliosQuery_['allCompanies'][number];

export type ActivityCachedResult = CachedResultFieldsFragment_;
export type GeneralActivityCachedResult = Omit<ActivityCachedResult, 'activityTag'>;
export type BusinessUnitCachedResult = Omit<
  ActivityCachedResult,
  'activityTag' | 'activityRef' | 'isAligned' | 'activityVersionNumber'
>;
export type CompanyCachedResult = BusinessUnitCachedResult;

export type ActivityResults = {
  activityAssessmentId: ActivityAssessment['id'];
  activityVersionNumber: ActivityAssessment['activityVersionNumber'];
  cachedResult?: ActivityCachedResult | null;
  activityRef: string;
  isMSSGAligned?: boolean | null;
};

export type BusinessUnitAssessmentResults = {
  bAssessmentId: string;
  businessUnit: {
    name: string;
    id: string;
  };
  hasGeneralAssessment: boolean;
  cachedResult?: BusinessUnitCachedResult | null;
  generalAssessmentResult: {
    activityAssessmentId: ActivityAssessment['id'];
    activityVersionNumber: ActivityAssessment['activityVersionNumber'];
    bAssessmentId: string;
    cachedResult?: GeneralActivityCachedResult | null;
  };
  activityResults: ActivityResults[];
};

export interface CompanyAssessmentResults {
  companyAssessmentId: string;
  aggregateId: string;
  startDate: string;
  endDate: string;
  cachedResult?: CompanyCachedResult | null;
  generalAssessmentResult: {
    activityAssessmentId: ActivityAssessment['id'];
    activityVersionNumber: ActivityAssessment['activityVersionNumber'];
    bAssessmentId: string;
    cachedResult?: GeneralActivityCachedResult | null;
  };
  businessUnitResults: BusinessUnitAssessmentResults[];
}

export interface ActivityReport
  extends Omit<ActivityReportFieldsFragment_, 'financials' | 'questions'> {
  financials: Financials;
  supportedFinancials: Financials;
  questions: Question[];
  activityRef: Activity['reference'];
  activityVersionNumber: number;
  name: Activity['name'];
  businessUnitId: string;
  tag: ActivityTagEnum;
}

export type ObjectiveWithQuestions = Omit<
  ActivityAssessmentQuery_['objectives'][number],
  'questions' | 'key'
> & {
  questions: ResolvedQuestion[];
  key: ObjectiveId;
};

export type AnswerUpdate = Omit<
  Answer_Insert_Input_,
  'attachments' | 'activityReport' | 'thread' | 'notes' | 'report'
> & {
  attachments?: DocumentationFile[];
  thread?: Omit<Thread_Insert_Input_, 'comments' | 'subscribers'> & {
    comments: Comment_Insert_Input_[];
  };
  note?: string;
};

export interface ResolvedQuestion
  extends Omit<Question, 'isAligned' | 'isRequired' | 'isVisible' | 'isEditable'>,
    Partial<MultipleCriteriaProps> {
  answer?: Answer;
  isAligned: boolean | null;
  isRequired: boolean | null;
  isVisible: boolean | null;
  isEditable: boolean | null;
  isAnswered: boolean;
  isSecondarySubstantialContributionObjective?: boolean;
  newerRevisions?: Array<{ newQuestion: { id: string }; description?: string }>;
  olderRevisions?: Array<{ oldQuestion: { id: string }; description?: string }>;
}

export type MultipleCriteriaProps = {
  dependencies: { table: { [option: string]: string[] }; questionSets: string[] };
  subQuestions: {
    title: string;
    questions: ResolvedQuestion[];
    questionSet: string;
    progress: { progress: number; notAligned: number };
  }[];
};

export type QuestionWithAnswer = Question & { answers: Answer[] };
export type QuestionWithShortAnswer = Question & { answers: ShortAnswer[] };

export type MultipleCriteriaQuestion = ResolvedQuestion & MultipleCriteriaProps;

export type Note = {
  body: string;
  updatedAt: string;
  authors: (User & { modifiedAt: string })[];
};

export interface AnswerSet {
  [questionUniqueId: string]: Answer['data'];
}

export type Invitation = Omit<
  NonNullable<GetInvitationQuery_['invitation']>,
  'companyId' | 'companyName' | 'companyLogoUrl'
> & {
  company: Pick<CompanyDetails, 'id' | 'name' | 'logoUrl'>;
};

export type PortfolioUpsert = {
  id?: Portfolio['id'];
  name: string;
  description: string;
  portfolioCompanies?: PortfolioCompany[];
};

export type ActivityAssessmentDetails = ActivityAssessmentQuery_;

export enum ObjectiveKeyEnum {
  general = 'general',
  social = 'social',
  mitigation = 'mitigation',
  adaptation = 'adaptation',
  biodiversity = 'biodiversity',
  circular = 'circular',
  pollution = 'pollution',
  water = 'water',
}
export enum EnvironmentalObjectiveKeyEnum {
  mitigation = 'mitigation',
  adaptation = 'adaptation',
  biodiversity = 'biodiversity',
  circular = 'circular',
  pollution = 'pollution',
  water = 'water',
}

export type AssessmentFlags = {
  isAdaptationOnlyActivity: boolean;
  isAdaptationOnlySC: boolean;
  isTransitional: boolean;
  isEnabling: boolean;
  canBeEnabling: boolean;
  isCompleted: boolean;
  isAligned: boolean;
  hasClimateRiskAssessment: boolean;
  isAlwaysEnablingActivity: boolean;
};

export type TaxonomyScore = {
  total: number;
  eligible: number;
  aligned: number;
  inProgress?: number;
  enabling?: number;
  transitional?: number;
};
export type TaxonomyScores = {
  revenue: TaxonomyScore;
  capex: TaxonomyScore;
  opex: TaxonomyScore;
  balance?: TaxonomyScore;
  isEstimate?: boolean;
};

export type BasicTaxonomyScores = Omit<TaxonomyScores, 'isEstimate'>;

export type ScreenedFinancials = {
  total: number;
  inProgress: number;
  aligned: number;
  notAligned: number;
  eligible: number;
  notEligible: number;
  enabling: number;
  transitional: number;
  adaptation: number;
};

export type CalculatedActivityResult = {
  activityRef: Activity['reference'];
  activityTag: ActivityTagEnum;
  activityVersionNumber: number;
  progress: {
    financials: number;
    screening: number;
  };
  financials: {
    capex: ScreenedFinancials;
    opex: ScreenedFinancials;
    revenue: ScreenedFinancials;
  };
  score: {
    capex: TaxonomyScore;
    opex: TaxonomyScore;
    revenue: TaxonomyScore;
  };
  isAligned: boolean;
  isCompleted: boolean;
  objectivesState: {
    possibleSubstantialContributionObjectives: string[];
    substantialContributionObjectives: string[];
    significantHarmObjectives: string[];
    hasClimateRiskAssessment: boolean;
  };
  status: EntityAlignmentStatus;
};

// PAI
export type PaiTable = PaiTableFieldsFragment_;
export type PaiCategory = PaiCategoryFieldsFragment_;
export type PaiIndicator = PaiIndicatorFieldsFragment_;
export type InvestorMetric = InvestorMetricFieldsFragment_;
export type InvesteeMetric = InvesteeMetricFieldsFragment_;

// PAI for portfolios
export type PaiPortfolioReport = PaiPortfolioReportFieldsFragment_;

// PAI for companies
export type PaiRequest = PaiReportRequestsQuery_['paiReportRequests'][number];
export type PaiCompanyReport = PaiCompanyReportsQuery_['paiCompanyReports'][number];
export type PaiAnswer = GetPaiAnswerSubscription_['answers'][number];

export type ValueOfInvestments = {
  q1: number;
  q2: number;
  q3: number;
  q4: number;
};

export enum ActivityVersionMigrationStatus {
  inProgress = 'inProgress',
  completed = 'completed',
}

export type ActivityVersionConfig = {
  previousVersion?: number;
  skipVersion?: number | null;
  dismissedRemovedQuestionsObjectives?: Array<ObjectiveId>;
  migrationStatus?: ActivityVersionMigrationStatus;
  migrationStartDate?: Date;
};

export type ActivityReportWithVersion = Omit<
  AllActivityAssessmentsWithVersionQuery_['ActivityReport'][number],
  'activityVersionConfig'
> & { activityVersionConfig?: ActivityVersionConfig };
