import { HStack, TextProps, Tooltip } from '@chakra-ui/react';
import React, { useCallback } from 'react';
import { Typography, TypographyVariant } from 'Tokens';

export const TruncatableText = ({
  text,
  tooltipText,
  tooltipMaxWidth,
  variant = 'body',
  maxTextWidth,
  noOfLines = 1,
  ...rest
}: {
  text: string | string[];
  tooltipText?: string;
  tooltipMaxWidth?: string;
  variant?: TypographyVariant;
  maxTextWidth?: number;
  noOfLines?: number;
} & TextProps) => {
  const [showTooltip, setShowTooltip] = React.useState(false);

  const mouseEnterHandler = useCallback(
    (e: any) => {
      if (
        e.target.scrollWidth === e.target.parentElement.scrollWidth &&
        !showTooltip &&
        (!!maxTextWidth ? e.target.parentElement.scrollWidth >= maxTextWidth : true)
      ) {
        setShowTooltip(true);
      } else if (e.target.offsetWidth < e.target.parentElement.offsetWidth) {
        setShowTooltip(false);
      }
    },
    [showTooltip, setShowTooltip]
  );

  if (showTooltip)
    return (
      <Tooltip
        placement={'bottom-start'}
        label={tooltipText ?? text}
        isDisabled={!showTooltip}
        maxWidth={tooltipMaxWidth}
      >
        <HStack w={rest.width ?? rest.w ?? '100%'}>
          <Typography
            {...rest}
            noOfLines={noOfLines}
            w="fit-content"
            wordBreak="break-all"
            onMouseEnter={mouseEnterHandler}
            variant={variant}
          >
            {text}
          </Typography>
        </HStack>
      </Tooltip>
    );

  return (
    <HStack w={rest.width ?? rest.w ?? '100%'}>
      <Typography
        {...rest}
        noOfLines={1}
        w="fit-content"
        wordBreak="break-all"
        onMouseEnter={mouseEnterHandler}
        variant={variant}
      >
        {text}
      </Typography>
    </HStack>
  );
};
