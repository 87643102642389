import { Box, VStack } from '@chakra-ui/react';
import { ActiveStepLabels } from 'containers/Esrs/EsrsUtilComponents';
import { Control, Controller } from 'react-hook-form';
import { useMemo } from 'react';
import {
  MappedDisclosureRequirements,
  MaterialityFields,
  MaterialityState,
} from '../DoubleMaterialityAssessment.d';
import { MaterialityRadioGroup } from '../MaterialityRadioGroup';
import { Typography } from 'Tokens';

export const AssessDisclosureRequirement = ({
  disclosureRequirement,
  control,
  showTable,
  isStandardMandatory,
  disableMaterial,
  isCollectOnly,
  updateAllMateriality,
  isParentMaterial,
}: {
  disclosureRequirement?: MappedDisclosureRequirements[number];
  isStandardMandatory: boolean;
  control: Control<MaterialityFields, any>;
  showTable: boolean;
  disableMaterial: boolean;
  isCollectOnly: boolean;
  updateAllMateriality: (val: MaterialityState | null) => void;
  isParentMaterial: boolean;
}) => {
  const drMateriality = useMemo(
    () => disclosureRequirement?.materialityStatus,
    [disclosureRequirement]
  );
  const status = useMemo(
    () =>
      isStandardMandatory
        ? MaterialityState.mandatory
        : (disclosureRequirement?.materialityStatus as MaterialityState),
    [disclosureRequirement, isStandardMandatory]
  );
  const canCollectDisclosure = useMemo(
    () =>
      drMateriality === MaterialityState.collectData ||
      drMateriality === MaterialityState.doNotCollect,
    [disclosureRequirement]
  );

  return (
    <Box
      pl="32px"
      ml="14px"
      pb="32px"
      borderLeft={showTable ? '1px dashed' : ''}
      borderColor="border.hover"
    >
      <ActiveStepLabels
        title={'Assess Disclosure Requirement'}
        hasSteps={false}
        clickable={false}
        ml="63px"
      />
      <Controller
        name="material"
        control={control}
        render={({ field: { onChange, value } }) => {
          return (
            <VStack gap="8px" alignItems="start">
              <MaterialityRadioGroup
                value={value ?? MaterialityState.toAssess}
                defaultValue={status}
                isMaterialDisabled={disableMaterial}
                onChange={(val) => {
                  onChange(val);
                  updateAllMateriality(val);
                }}
                isCollectOnly={isCollectOnly ? canCollectDisclosure : false}
                isDr={true}
                width="613px"
                isParentMaterial={isParentMaterial}
              />
              {isParentMaterial && (
                <Typography variant="detail" width="613px">
                  This DR can not be set to "Not material" as your parent company requested to
                  collect data for it.
                </Typography>
              )}
            </VStack>
          );
        }}
      />
    </Box>
  );
};
