import { VStack, Image } from '@chakra-ui/react';
import { Button, ButtonLink, EmptyState, FormField, Input } from 'Atoms';
import { LinkExpiredIllustration, NoAccessIllustration, Typography } from 'Tokens';
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import PasswordStrengthBar from 'react-password-strength-bar';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useToast } from 'utils/hooks';
import { nhost } from 'utils/nhost';
import { useTranslation } from 'utils/translation';
import { LoginPage } from './Login';
import { ArrowLeftIcon } from 'Tokens/Icons/Direction';
import { captureException } from '@sentry/browser';

type RequestChangeFields = {
  email: string;
};

type ChangePasswordFields = {
  newPassword: string;
  ticket: string;
};

export const RequestChangeForm = () => {
  const toast = useToast();
  const { t } = useTranslation(['common', 'login']);

  const {
    handleSubmit,
    register,
    formState: { isDirty, isValid, isSubmitting },
  } = useForm<RequestChangeFields>({
    mode: 'all',
    reValidateMode: 'onBlur',
    criteriaMode: 'all',
  });

  const onSubmit = (data: RequestChangeFields) => {
    nhost.auth
      .resetPassword({
        email: data.email,
        options: {
          redirectTo: '/changePassword',
        },
      })
      .then((res) => {
        if (res.error) {
          toast({
            text: `${t('login:toast.passwordRecoveryFailed.title')} ${res.error?.message}`,
            variant: 'danger',
          });
        } else {
          toast({
            text: `${t('login:toast.passwordInstructionSent.title')}. ${t(
              'login:toast.passwordInstructionSent.description',
              {
                email: data.email,
              }
            )}`,
            duration: 10000,
          });
        }
      })
      .catch((err) => {
        toast({
          text: `${t('login:toast.passwordRecoveryFailed.title')} ${err.response?.data?.message}`,
          variant: 'danger',
        });
      });
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <VStack spacing="16px" width="100%" alignItems="stretch">
        <FormField id="email" label={t('common:fields.email.label')}>
          <Input
            width="100%"
            {...register('email', {
              required: t('login:inputs.email.anEmail'),
              validate: (v) => (v.includes('@') ? true : t('login:inputs.email.validate')),
            })}
          />
        </FormField>
        <Button
          width="full"
          isDisabled={!isDirty || !isValid}
          isLoading={isSubmitting}
          variant="primary"
          type="submit"
        >
          {t('login:changePassword.changePassword')}
        </Button>
      </VStack>
    </form>
  );
};

const ChangePasswordForm = ({ ticket }: { ticket: string }) => {
  const toast = useToast();
  const navigate = useNavigate();
  const { t } = useTranslation(['common', 'login']);
  const [passwordStrength, setPasswordStrength] = useState<number>(0);

  const {
    handleSubmit,
    control,
    formState: { isDirty, isValid, isSubmitting },
  } = useForm<ChangePasswordFields>({
    mode: 'all',
    reValidateMode: 'onBlur',
    criteriaMode: 'all',
    defaultValues: {
      ticket: ticket ?? '',
    },
  });

  const onSubmit = (data: ChangePasswordFields) => {
    // We dont want to use the ticket because the url that nhost generates in the email already containes the ticket and will sign the user in automatically
    nhost.auth
      .changePassword({ newPassword: data.newPassword })
      .then((res) => {
        if (res.error) {
          toast({
            text: `${res.error.message ?? t('login:toast.invalidToken.title')}.
            ${t('login:pleaseTryAgain')}`,
            variant: 'danger',
          });
        } else {
          toast({
            text: `${t('login:toast.passwordChanged.title')}. 
            ${t('login:toast.passwordChanged.description')}`,
          });
          navigate('/login');
        }
      })
      .catch(() => {
        toast({
          text: `${t('login:toast.invalidToken.title')} ${t('login:pleaseTryAgain')}`,
          variant: 'danger',
        });
      });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <VStack spacing="16px">
        <Controller
          name="newPassword"
          control={control}
          rules={{ required: true, validate: (_v) => passwordStrength >= 2 }}
          render={({ field, fieldState }) => {
            return (
              <FormField
                label={t('login:fields.password.label')}
                id="newPassword"
                isRequired
                isInvalid={!!fieldState.error || fieldState.invalid}
                error={passwordStrength < 2 ? t('login:fields.password.strongerRequired') : ''}
              >
                <VStack spacing="2px" width="100%" alignItems="stretch">
                  <PasswordStrengthBar
                    password={field.value}
                    onChangeScore={(score) => setPasswordStrength(score)}
                    scoreWords={[
                      // t('common:fields.passwordStrength.weak'),
                      t('common:fields.passwordStrength.weak'),
                      t('common:fields.passwordStrength.okay'),
                      t('common:fields.passwordStrength.good'),
                      t('common:fields.passwordStrength.strong'),
                    ]}
                    shortScoreWord={
                      !!field.value ? t('common:fields.passwordStrength.tooShort') : ''
                    }
                    style={{
                      display: 'flex',
                      flexDirection: 'column-reverse',
                      height: '2px',
                      overflow: 'visible',
                      marginBottom: '4px',
                    }}
                  />
                  <Input
                    value={field.value}
                    onChange={(e) => field.onChange(e.target.value)}
                    placeholder={t('login:fields.password.label')}
                    type="password"
                    autoComplete="new-password"
                    width="100%"
                  />
                </VStack>
              </FormField>
            );
          }}
        />
        <Button
          width="full"
          isDisabled={!isDirty || !isValid}
          isLoading={isSubmitting}
          type="submit"
        >
          {t('login:changePassword.changePassword')}
        </Button>
      </VStack>
    </form>
  );
};

export default function ChangePassword() {
  const [searchParams] = useSearchParams();
  const ticket = searchParams.get('ticket');
  const { t } = useTranslation('login');
  const navigate = useNavigate();
  const errorDescription = searchParams.get('errorDescription');
  return (
    <>
      {!errorDescription ? (
        <LoginPage
          heading=""
          header={
            <Typography variant="bodyStrong" textAlign="center" width="100%">
              {ticket ? t('login:setNewPassword') : t('login:requestPassword')}
            </Typography>
          }
        >
          <VStack width="100%" alignItems="stretch">
            {ticket ? <ChangePasswordForm ticket={ticket} /> : <RequestChangeForm />}
            <ButtonLink to="/login" variant="ghost" leftIcon={<ArrowLeftIcon />}>
              {t('login:backToLogin')}
            </ButtonLink>
          </VStack>
        </LoginPage>
      ) : (
        <VStack w="100%" h="100vh" p="16px" spacing="16px">
          <Image src="/assets/celsia_logo.svg" h="32px" w="100px" />
          <EmptyState
            title={
              errorDescription.includes('expired')
                ? t('login:changePassword.linkExpiredTitle')
                : errorDescription.replace('+', '')
            }
            description={
              errorDescription.includes('expired')
                ? t('login:changePassword.linkExpiredDescription')
                : ''
            }
            icon={<LinkExpiredIllustration boxSize="120px" />}
            callToAction={{
              text: t('login:changePassword.sendLink'),
              variant: 'primary',
              onClick: () => {
                navigate('/changePassword');
              },
            }}
            extra={
              <Button variant="ghost" onClick={() => navigate('/login')}>
                {t('login:changePassword.toLogin')}
              </Button>
            }
          />
        </VStack>
      )}
    </>
  );
}

export const VerifyLinkPage = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const ticket = searchParams.get('ticket');
  const redirectTo = searchParams.get('redirectTo');
  const type = searchParams.get('type');

  return (
    <VStack w="100%" h="100vh" p="16px" spacing="16px">
      <Image src="/assets/celsia_logo.svg" h="32px" w="100px" />
      <EmptyState
        title="Reset your password"
        description="The password reset link will be active for 1 hour after request. You can only enter it once. Please contact us if you have any questions at contact@celsia.io"
        icon={<NoAccessIllustration boxSize="120px" />}
        callToAction={{
          text: 'Reset password',
          variant: 'primary',
          onClick: () => {
            if (!!ticket && !!redirectTo && !!type) {
              window.location.href = `${nhost.auth.url}/verify?ticket=${ticket}&type=${type}&redirectTo=${redirectTo}`;
            } else {
              captureException('error in reset password link');
              navigate('/changePassword?errorDescription=expired+link');
            }
          },
        }}
        extra={
          <Button variant="ghost" onClick={() => navigate('/login')}>
            Go to login page
          </Button>
        }
      />
    </VStack>
  );
};
