import { VStack, PopoverAnchor, HStack } from '@chakra-ui/react';
import { ChevronsRightIcon, ArrowBarToLeftIcon, ArrowBarToRightIcon } from 'Tokens/Icons/Direction';
import { TOP_MENU_HEIGHT } from 'containers/Navigation/pieces';
import { SideBarPopover } from '../../../DisclosureRequirementsUtilsComponents';
import { PopoverTypes, SidebarModes } from '../../../Requirement';
import { IconButton } from 'Atoms';
import { Breadcrumbs } from 'Molecules/Breadcrumbs';
import { useEffect, useMemo, useRef } from 'react';
import { useUserSetting } from 'containers/Navigation';
import { Typography } from 'Tokens';
import { GetDisclosureRequirementQuery_ } from 'models';
import { SidebarMenu } from './SidebarMenu';
import { DRViews, getAvailableTabs } from '../DisclosureRequirement.utils';

export const DisclosureRequirementSidebar = ({
  disclosureRequirement,
  sidebarMode,
  setSidebarMode,
  showReportPreview,
  selectedView,
  setSelectedView,
}: {
  disclosureRequirement: GetDisclosureRequirementQuery_['DisclosureRequirement'];
  sidebarMode: SidebarModes;
  setSidebarMode: (val: SidebarModes) => void;
  showReportPreview: boolean;
  selectedView: DRViews;
  setSelectedView: (val: DRViews) => void;
}) => {
  const sidebarRef = useRef<HTMLDivElement>(null);
  const [isOpenedTooltipDismissed, setOpenedTooltipDismissed] = useUserSetting(
    'sidebar-opened-tootlip-dismissed',
    false
  );
  const [isClosedTooltipDismissed, setClosedTooltipDismissed] = useUserSetting(
    'sidebar-closed-tootlip-dismissed',
    false
  );

  useEffect(() => {
    if (!isOpenedTooltipDismissed && !isClosedTooltipDismissed) setSidebarMode(SidebarModes.open);
  }, [isOpenedTooltipDismissed, isClosedTooltipDismissed]);

  const [isSidebarClosed, isSidebarOverlay, isSidebarOpen] = useMemo(
    () => [
      sidebarMode === SidebarModes.closed,
      sidebarMode === SidebarModes.overlay,
      sidebarMode === SidebarModes.open,
    ],
    [sidebarMode]
  );

  const availableTabs = useMemo(
    () => getAvailableTabs(showReportPreview, selectedView, setSelectedView),
    [showReportPreview, selectedView, setSelectedView]
  );

  const handleSidebar = () => {
    if (isSidebarClosed) setSidebarMode(SidebarModes.overlay);
    else if (isSidebarOverlay) setSidebarMode(SidebarModes.open);
    else setSidebarMode(SidebarModes.closed);
  };

  return (
    <SideBarPopover
      popoverType={PopoverTypes.closed}
      isSidebarClosed={isSidebarClosed}
      isOpenedTooltipDismissed={isOpenedTooltipDismissed}
      isClosedTooltipDismissed={isClosedTooltipDismissed}
      setClosedTooltipDismissed={setClosedTooltipDismissed}
      setOpenedTooltipDismissed={setOpenedTooltipDismissed}
    >
      <VStack
        height={`calc(100vh - ${TOP_MENU_HEIGHT})`}
        ref={sidebarRef}
        w={isSidebarClosed ? '36px' : '288px'}
        maxW="288px"
        minW={isSidebarOpen ? '288px' : '0px'}
        borderRight="1px solid"
        borderRightColor="border.decorative"
        overflow="hidden"
        bg="bg.default"
        alignItems="start"
        justifyContent={isSidebarClosed ? 'center' : 'unset'}
        position={isSidebarOpen ? 'relative' : 'absolute'}
        gap="0px"
        onMouseEnter={() => {
          const sidebarWidth = sidebarRef?.current?.offsetWidth;
          if (sidebarWidth === 36 && isSidebarClosed) {
            setSidebarMode(SidebarModes.overlay);
            if (!isClosedTooltipDismissed) {
              setClosedTooltipDismissed(true);
            }
          }
        }}
        onMouseLeave={() => (isSidebarOverlay ? setSidebarMode(SidebarModes.closed) : {})}
        zIndex="5"
        transition="all 0.15s ease-in-out"
      >
        {isSidebarClosed && (
          <PopoverAnchor>
            <IconButton
              opacity={isSidebarClosed ? 1 : 0}
              marginInline="auto"
              variant="ghost"
              icon={<ChevronsRightIcon color="text.default" />}
              aria-label="open"
              transition="all 0.15s"
              position="absolute"
              left="0px"
            />
          </PopoverAnchor>
        )}
        <VStack
          opacity={isSidebarClosed ? 0 : 1}
          pointerEvents={isSidebarClosed ? 'none' : 'auto'}
          transition="all 0.15s ease-in-out"
          alignItems="start"
          gap="0px"
          height="100%"
          w="100%"
          position={isSidebarClosed ? 'absolute' : 'unset'}
        >
          <HStack justifyContent="space-between" w="100%">
            <Breadcrumbs truncateMiddleCrumbs />
            <SideBarPopover
              popoverType={PopoverTypes.open}
              isSidebarClosed={isSidebarClosed}
              isOpenedTooltipDismissed={isOpenedTooltipDismissed}
              isClosedTooltipDismissed={isClosedTooltipDismissed}
              setClosedTooltipDismissed={setClosedTooltipDismissed}
              setOpenedTooltipDismissed={setOpenedTooltipDismissed}
            >
              <PopoverAnchor>
                <IconButton
                  variant="ghost"
                  size="sm"
                  icon={isSidebarOpen ? <ArrowBarToLeftIcon /> : <ArrowBarToRightIcon />}
                  aria-label="hide"
                  mt="8px"
                  mr="8px"
                  onClick={(e) => {
                    handleSidebar();
                    if (!isOpenedTooltipDismissed) {
                      setOpenedTooltipDismissed(true);
                    }
                    e.stopPropagation();
                  }}
                />
              </PopoverAnchor>
            </SideBarPopover>
          </HStack>
          <VStack spacing="16px" alignItems="start" w="100%">
            <VStack spacing="16px" alignItems="stretch" w="100%">
              <VStack p="24px 16px" pb="16px" spacing="4px" alignItems="start">
                <Typography variant="h2">{disclosureRequirement?.title}</Typography>
                <Typography variant="body" color="text.muted">
                  {disclosureRequirement?.reference}
                </Typography>
              </VStack>
              <SidebarMenu options={availableTabs} />
            </VStack>
          </VStack>
        </VStack>
      </VStack>
    </SideBarPopover>
  );
};
