import { ChakraTheme, HStack, useStyleConfig, VStack } from '@chakra-ui/react';
import { Typography } from 'Tokens';
import { CardStatus, StateStatus } from 'Molecules/CardStatus';
import { InputCardWarning, InputCardWarningProps } from './InputCardWarnings';
import { InputCardDocumentation, InputCardDocumentationProps } from './InputCardDocumentation';
import { VisibleIf } from 'Atoms';

export const InputCardTheme: ChakraTheme['components']['InputCard'] = {
  baseStyle: {
    spacing: '0px',
    borderStyle: 'solid',
    alignItems: 'flex-start',
    width: '100%',
    borderRadius: '10px',
    borderWidth: '1px',
    borderColor: 'border.decorative',
    bg: 'bg.default',
    color: 'text.default',
  },
  variants: {
    default: {},
    selectable: {
      _hover: {
        borderColor: 'border.hover',
      },
    },
    disabled: {
      bg: 'bg.disabled',
      color: 'text.disabled',
    },
  },
};

export const INPUT_CARD_VARIANTS = Object.keys(InputCardTheme.variants ?? {});
export type InputCardVariant = (typeof INPUT_CARD_VARIANTS)[number];

export type InputCardHeaderProps = {
  status: StateStatus;
  title: string;
  subtitle?: string;
  extra?: React.ReactNode;
  actions?: React.ReactNode[] | React.ReactNode;
  tooltipContentOverride?: JSX.Element;
  onHeaderClick?: () => void;
};

const InputCardHeader = ({
  status,
  title,
  subtitle,
  extra,
  actions,
  tooltipContentOverride,
  onHeaderClick,
}: InputCardHeaderProps) => {
  return (
    <HStack
      padding="16px"
      justifyContent="space-between"
      width="100%"
      alignItems="center"
      onClick={() => onHeaderClick?.()}
      cursor={onHeaderClick ? 'pointer' : 'default'}
    >
      <HStack spacing="12px" alignItems="center">
        <CardStatus status={status} tooltipContentOverride={tooltipContentOverride} />
        <VStack spacing="0px" alignItems="start">
          <Typography variant="h3" alignSelf="center" justifyContent="center">
            {title}
          </Typography>
          <VisibleIf condition={!!subtitle}>
            <Typography variant="detail">{subtitle}</Typography>
          </VisibleIf>
        </VStack>
        {extra}
      </HStack>
      <HStack spacing="12px">{actions}</HStack>
    </HStack>
  );
};

export const InputCard = ({
  header,
  warning = { status: 'none' },
  documentation,
  children,
  variant = 'default',
  tooltipContentOverride,
  padding = '16px',
  ...props
}: {
  header?: InputCardHeaderProps;
  children: React.ReactNode;
  warning?: InputCardWarningProps;
  documentation?: InputCardDocumentationProps;
  variant?: InputCardVariant;
  tooltipContentOverride?: JSX.Element;
  padding?: string;
}) => {
  const style = useStyleConfig('InputCard', { variant });
  return (
    <VStack __css={style} {...props} spacing="0px">
      {header && <InputCardHeader tooltipContentOverride={tooltipContentOverride} {...header} />}
      <HStack padding={padding} justifyContent="flex-start" width="100%">
        {children}
      </HStack>
      {warning.status != 'none' && <InputCardWarning {...warning} />}
      {documentation && <InputCardDocumentation {...documentation} />}
    </VStack>
  );
};
