import { Box, FormLabel, HStack, VStack } from '@chakra-ui/react';
import { FormField } from 'Atoms';
import { Select } from 'Molecules';
import { Typography } from 'Tokens';
import { LANGUAGES } from 'utils/language';

export const AnswersLanguageField = ({
  value,
  setValue,
}: {
  value: string;
  setValue: (newVal: string) => void;
}) => {
  return (
    <FormField id="preferredLanguage">
      <HStack w="100%" justifyContent="space-between">
        <VStack alignItems="start" gap="2px">
          <FormLabel mb="0px">Response language</FormLabel>
          <Typography variant="detail">
            Select the language in which AI should generate answers for your report
          </Typography>
        </VStack>
        <Box width="30%">
          <Select
            value={LANGUAGES.find((l) => l.value === value)}
            onChange={(v: any) => v && setValue(v.value)}
            isSearchable={true}
            isMulti={false}
            options={LANGUAGES}
            size="md"
            menuPlacement="auto"
          />
        </Box>
      </HStack>
    </FormField>
  );
};
