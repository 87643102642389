import {
  Document,
  Paragraph,
  Table,
  BorderStyle,
  Header,
  AlignmentType,
  TableRow,
  TableCell,
  WidthType,
  HeightRule,
  VerticalAlign,
  Footer,
  ImageRun,
  TextRun,
  PageNumber,
  SectionType,
  LevelFormat,
  convertInchesToTwip,
  ShadingType,
} from 'docx';
import {
  ActivityTagStatus,
  FinancialResult,
} from 'Features/TaxonomyResultsTable/TaxonomyResultsTableTypes';
import { TFunction } from 'i18next';
import { ScoreSectionsEnum, TaxonomyScore } from 'models';
import { formatNum } from 'utils/numbers';

const INTRO_TEXT =
  'The EU Taxonomy Regulation (Regulation 2020/852) entered into force on 12 July 2020. Since then, the EU has implemented Delegated Acts to further expand on the taxonomy framework. The Delegated Acts currently in force include the Climate Delegated Act (Regulation 2021/2139), the Disclosures Delegated Act (Regulation 2021/2178), and the Complementary Climate Delegated Act (Regulation 2022/1214). In addition, another delegated act, the Environmental Delegated Act (Regulation 2023/2486), and amendments to the Climate Delegated Act (Regulation 2023/2485) were adopted in June 2023 and entered into force on the 1st of January 2024. As of now, large, public-interest undertakings are required to report under the EU Taxonomy Regulation.';

const ABOUT_EU_TAXONOMY = 'About the EU Taxonomy [This section is optional]';
const ABOUT_TAXONOMY_DESCRIPTION_1 =
  'The EU taxonomy is a classification system that sets out a list of environmentally sustainable economic activities. It forms part of the EU’s plan to scale up sustainable investment and implement the European Green Deal.';
const ABOUT_TAXONOMY_DESCRIPTION_2 =
  'The taxonomy was developed in order to provide well-defined, harmonised criteria for when economic activities can be considered to be sustainable. It sets out robust, science-based technical screening criteria that activities need to comply with to be seen as green. By providing this harmonised standard, the taxonomy aims to increase transparency, create security for investors, prevent greenwashing, help companies become more climate-friendly, mitigate market fragmentation, and help investors compare investments across Member States. By directing investments towards sustainable projects and activities across the EU, the taxonomy should help to meet the EU’s 2030 and 2050 climate and energy targets.';
const ABOUT_TAXONOMY_DESCRIPTION_3 =
  'The Climate Delegated Act, the Complementary Climate Delegated Act, and the Environmental Delegated Act set out a list of eligible activities along with technical screening criteria for when the activities can be considered sustainable. A taxonomy-eligible economic activity is an economic activity that is described and has technical screening criteria set out in the taxonomy. ';

const STEPS_ALIGNMENT =
  'For an eligible activity to be considered aligned, it has to satisfy the following conditions: ';
const STEP1 =
  'The activity must make a substantial contribution to one or more of the climate and environmental objectives relevant to that activity.';
const STEP2 = 'The activity should not do significant harm to the other remaining objectives.';
const STEP3 =
  'The company should fulfill the minimum safeguard standards based on OECD and UN guidelines.';

const GENERAL_COMMENTS_1 =
  'This taxonomy assessment is completed with best intention, focused on transparency, and providing explanation for choices made when interpreting the criteria. The interpretation of the criteria is based on both the explicit information available at the time of the assessment and the understanding of the purpose of the requirement.';
const GENERAL_COMMENTS_2 =
  'The taxonomy regulation is being continually updated and clarified, and best practices in reporting are still emerging. {{company}} is closely following any clarifications from the EU Commission or any changes in industry best-practice when it comes to interpreting the activity descriptions or technical screening criteria.';

const REPORTING_REQUIREMENTS = 'Reporting requirements for {{company}}';

const METHODOLOGY_TITLE = 'Taxonomy assessment methodology';
const METHODOLOGY_DESCRIPTION =
  '{{company}} has performed the taxonomy assessment using Celsia Taxonomy software solution. The methodology of taxonomy assessment included the following steps: ';

const REQUIREMENT1 = 'Reporting boundaries';
const REQUIREMENT1_DESCRIPTION =
  '{{company}} has performed a taxonomy assessment for all of the company’s economic activities. The scope of the taxonomy assessment follows that of the ';
const REQUIREMENT1_DESCRIPTION2 = 'company’s/consolidated group’s';
const REQUIREMENT1_DESCRIPTION3 = ' financial reporting.';
const REQUIREMENT1_DESCRIPTION4 =
  '[Add information about whether joint ventures and additional or voluntary KPIs have been included in the report]';
const REQUIREMENT1_DESCRIPTION5 =
  '[If you have used a different scope of assessment you should adjust the information above accordingly]';

const REQUIREMENT2 = 'Defining eligibility and relevant activities';
const REQUIREMENT2_DESCRIPTION1 =
  'A taxonomy-eligible activity means an economic activity that is included in the taxonomy regulation. All {{company}}’s activities have been mapped out according to the activities defined in the ';
const REQUIREMENT2_DESCRIPTION2 =
  'Climate Delegated Act, Complementary Climate Delegated Act, and Environmental Delegated Act';
const REQUIREMENT2_DESCRIPTION3 =
  ' and categorized as either eligible or non-eligible following the description stated in the regulation. The eligible and non-eligible activities are listed in the table below:';

const REQUIREMENT3 = 'Defining relevant reporting units';
const REQUIREMENT3_DESCRIPTION =
  'In order to conduct the assessment as accurately as possible, {{company}} ’s operations were split into reporting units corresponding to the above-mentioned reporting boundaries (see point 1).';

const REQUIREMENT4 = 'Assessment of criteria and defining alignment';
const REQUIREMENT4_DESCRIPTION1P1 =
  'Each of the activities under each of {{company}} ’s defined reporting units have been assessed against the technical screening criteria for the respective activities defined in the ';
const REQUIREMENT4_DESCRIPTION1P2 =
  'Climate Delegated Act, Complementary Climate Delegated Act, and Environmental Delegated Act. ';
const REQUIREMENT4_DESCRIPTION1P3 =
  'As the taxonomy regulation is still in an early phase of adoption, the focus has been on transparency, best intention, and providing explanation for choices made when interpreting the criteria. The interpretation of the criteria is based on both the explicit information available and the understanding of the purpose of the requirement.';
const REQUIREMENT4_DESCRIPTION1P4 =
  'The taxonomy regulation has not yet adopted explicit criteria for the minimum safeguards beyond the references to OECD guidelines and UN Guiding Principles. Still, {{company}}’s understanding is that defined requirements on minimum safeguards need to be placed on the company and the activities in question in order to assess activity-alignment. {{company}} has therefore based compliance with minimum safeguards on an assessment of several requirements derived from the process of due diligence on responsible business conduct as described in OECD’s Guidelines for Multinational Companies and the UN Guiding Principles for Business and Human Rights.';

const REQUIREMENT5 = 'Adding financial data and calculating the three KPIs';
const REQUIREMENT5_DESCRIPTION =
  'Finally, by adding financial data to each activity in the reporting unit, the proportion of {{company}}’s taxonomy-eligible and taxonomy-aligned activities were calculated. This is done by calculating the three key performance indicators (KPIs): turnover, capital expenditures (CapEx), and operational expenditures (OpEx). The results were calculated for each reporting unit and activity and then aggregated for the company level.';

const ACCOUNTING_PRINCIPLES = 'Accounting principles and Calculation of KPIs';
const ACCOUNTING_PRINCIPLES_DESCRIPTION1 =
  'The definitions of the turnover, CapEx, and OpEx KPIs are set out in Annex I to the Disclosures Delegated Act. The proportion of taxonomy-eligible and taxonomy-aligned turnover, CapEx, and OpEx are calculated by dividing a numerator by a denominator. The following sections provide further information on how the denominators and numerators were derived for each KPI.';

const MINIMUM_SAFEGUARDS = 'Criteria related to minimum safeguards';
const MINIMUM_SAFEGUARDS_DESCRIPTION =
  'Please note: The taxonomy regulation has not yet adopted explicit criteria for the minimum safeguards beyond the references to OECD guidelines and UN Guiding Principles. Still, Celsia’s understanding is that defined requirements on minimum safeguards need to be placed on the company and the activities in question in order to assess activity alignment. Celsia has therefore based compliance with minimum safeguards on an assessment of several requirements derived from the process of due diligence on responsible business conduct as described in OECD’s Guidelines for Multinational Companies and the UN Guiding Principles for Business and Human Rights.';
const DISCLOSURE_ON_NUCLEAR_AND_FOSSIL = 'Disclosures on nuclear and fossil gas related activities';

const EXPLANATORY_TEXT = 'Please note the following on the KPIs: ';
const EXPLANATORY_STEP_1 =
  '1. Any activities that are in progress (so the parts of the assessment not completed yet) are counted as non-aligned. ';
const EXPLANATORY_STEP_2 =
  '2. Turnover for activities that can only make a substantial contribution to climate change adaptation and which are not enabling is counted as non-eligible following the taxonomy definition in the Disclosures Delegated Act. ';
const EXPLANATORY_STEP_3 =
  '3. For activities making a substantial contribution only to climate change adaptation and which are not enabling, only the Adaptation CapEx and Adaptation OpEx input in Celsia for that activity has the potential to be counted as aligned. This follows the guidance given in ';
const EXPLANATORY_STEP_3_ITALICS =
  'Commission Notice on the interpretation of certain legal provisions of the Disclosures Delegated Act under Article 8 of EU Taxonomy Regulation on the reporting of eligible economic activities and assets 2022/C 385/01.';

enum TextStyle {
  firstPageHeader = 'firstPageHeader',
  preTitle = 'preTitle',
  title = 'title',
  introText = 'introText',
  tableTitle = 'tableTitle',
  columnTitle = 'columnTitle',
  columnRow = 'columnRow',
  description1 = 'description1',
  pageTitle = 'pageTitle',
  documentTitle = 'documentTitle',
  smallTitle = 'smallTitle',
  about = 'about',
  stepsTitle = 'stepsTitle',
  steps = 'steps',
  heading2 = 'heading2',
  paragraph = 'paragraph',
  smallParagraph = 'smallParagraph',
  subTitle = 'subTitle',
  section = 'section',
  footnotes = 'footnotes',
  greenHighlight = 'greenHighlight',
  columnCell = 'columnCell',
  biggerGreenHighlight = 'biggerGreenHighlight',
  celsiaTitle = 'celsiaTitle',
}

type DocumentResult = {
  name: string;
  score?: { capex: TaxonomyScore; opex: TaxonomyScore; revenue: TaxonomyScore };
  financials?: { capex: TaxonomyScore; opex: TaxonomyScore; revenue: TaxonomyScore };
};

type DocumentInput = {
  period: string;
  t: TFunction;
  company: DocumentResult & { currency?: string; numberOfReportingUnits?: number };
  activities: Array<{
    rowKey: string;
    name: string;
    financials?: FinancialResult;
    alignment?: FinancialResult;
    tag?: ActivityTagStatus;
  }>;
};

export class DocumentCreator {
  public async create(input: DocumentInput): Promise<Document> {
    const celsiaLogo = await fetch('/assets/Logo.png').then(function (response) {
      return response.arrayBuffer();
    });
    const celsiaGreyLogo = await fetch('/assets/grey-logo.png').then(function (response) {
      return response.arrayBuffer();
    });

    const borders = {
      top: {
        style: BorderStyle.THICK,
        size: 1,
        color: 'e1e1e6',
      },
      left: {
        style: BorderStyle.THICK,
        size: 1,
        color: 'e1e1e6',
      },
      right: {
        style: BorderStyle.THICK,
        size: 1,
        color: 'e1e1e6',
      },
      bottom: {
        style: BorderStyle.THICK,
        size: 5,
        color: 'e1e1e6',
      },
    };

    const document = new Document({
      creator: 'Celsia.io',
      revision: 1,
      subject: 'Eu taxonomy report',
      title: `${input.company?.name} - EU Taxonomy report ${input.period}`,
      features: {
        updateFields: true,
      },
      numbering: {
        config: [
          {
            reference: 'number-system',
            levels: [
              {
                level: 0,
                format: LevelFormat.UPPER_ROMAN,
                text: '%1',
                alignment: AlignmentType.START,
                style: {
                  paragraph: {
                    indent: { left: convertInchesToTwip(0.5), hanging: convertInchesToTwip(0.18) },
                  },
                },
              },
              {
                level: 1,
                format: LevelFormat.DECIMAL,
                text: '%2.',
                alignment: AlignmentType.START,
                style: {
                  paragraph: {
                    indent: { left: convertInchesToTwip(0.5), hanging: convertInchesToTwip(0.18) },
                  },
                  run: {
                    size: 24,
                    font: 'Manrope',
                  },
                },
              },
              {
                level: 2,
                format: LevelFormat.LOWER_LETTER,
                text: '%3)',
                alignment: AlignmentType.START,
                style: {
                  paragraph: {
                    indent: { left: convertInchesToTwip(1.5), hanging: convertInchesToTwip(1.18) },
                  },
                },
              },
              {
                level: 3,
                format: LevelFormat.UPPER_LETTER,
                text: '%4)',
                alignment: AlignmentType.START,
                style: {
                  paragraph: {
                    indent: { left: 2880, hanging: 2420 },
                  },
                },
              },
              {
                level: 4,
                format: LevelFormat.DECIMAL,
                text: '%5.',
                alignment: AlignmentType.START,
                style: {
                  paragraph: {
                    indent: { left: convertInchesToTwip(0.5), hanging: convertInchesToTwip(0.18) },
                  },
                  run: {
                    size: 24,
                    font: 'Manrope',
                    bold: true,
                    color: '#1C1C57',
                  },
                },
              },
              {
                level: 5,
                format: LevelFormat.ORDINAL_TEXT,
                text: '',
                alignment: AlignmentType.START,
                style: {
                  paragraph: {
                    indent: { left: convertInchesToTwip(0.5), hanging: convertInchesToTwip(0.18) },
                  },
                  run: {
                    size: 24,
                    font: 'Manrope',
                  },
                },
              },
              {
                level: 6,
                format: LevelFormat.DECIMAL,
                text: '%7.',
                alignment: AlignmentType.START,
                style: {
                  paragraph: {
                    indent: { left: convertInchesToTwip(0.5), hanging: convertInchesToTwip(0.18) },
                  },
                  run: {
                    size: 24,
                    font: 'Manrope',
                  },
                },
              },
            ],
          },
        ],
      },
      background: {
        color: '#FFFFFF',
      },
      sections: [
        // First page
        {
          properties: {
            titlePage: true,
          },
          headers: {
            default: new Header({
              children: [
                new Paragraph({
                  alignment: AlignmentType.CENTER,
                  children: [
                    new ImageRun({
                      data: celsiaGreyLogo,
                      transformation: {
                        width: 61,
                        height: 22,
                      },
                    }),
                  ],
                }),
              ],
            }),
          },
          footers: {
            first: this.createLogoFooter(celsiaLogo),
            default: new Footer({
              children: [
                new Paragraph({
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                      children: [PageNumber.CURRENT],
                      font: 'Manrope',
                    }),
                  ],
                }),
              ],
            }),
          },
          children: [
            new Paragraph({
              text: 'Celsia’s EU Taxonomy report for',
              style: TextStyle.smallTitle,
              spacing: {
                before: 100,
                after: 150,
                line: 150,
              },
            }),
            new Paragraph({
              text: input.company.name,
              style: TextStyle.documentTitle,
              spacing: {
                after: 300,
                line: 300,
              },
            }),
            new Paragraph({
              children: [
                new TextRun({
                  text: 'Reporting period: ',
                  color: '#212121',
                  size: 22,
                  font: 'Manrope',
                }),
                new TextRun({
                  text: input.period,
                  color: '#212121',
                  size: 22,
                  font: 'Manrope',
                }),
              ],
              spacing: {
                line: 300,
                after: 300,
              },
            }),
            new Paragraph({
              text: this.fillInVariable(INTRO_TEXT, 'company', input.company.name),
              style: TextStyle.introText,
              spacing: {
                line: 300,
              },
            }),
            new Paragraph({
              children: [
                new TextRun({
                  text: 'Important: ',
                  font: 'Manrope',
                  color: '#212121',
                  bold: true,
                  size: 24,
                }),
                new TextRun({
                  text: 'This document is an automatically generated report downloaded from the Celsia tool. The Celsia tool is based on self-assessments and Celsia has no responsibility for the results. Places where additional data and descriptions may be needed from the companies, where the content is optional, or where the content may not be relevant for all company types, are ',
                  font: 'Manrope',
                  color: '#212121',
                }),
                new TextRun({
                  text: 'highlighted in green in the document.',
                  font: 'Manrope',
                  color: '#212121',
                  shading: {
                    type: ShadingType.SOLID,
                    color: '#DAF7ED',
                  },
                }),
                new TextRun({
                  text: ' The template provides suggestions for content to include in the report. It does not constitute an exhaustive list and companies are advised to consider if other topics should be included or if any of the content in the template should be excluded.',
                  font: 'Manrope',
                  color: '#212121',
                }),
              ],
              style: TextStyle.introText,
              shading: {
                type: ShadingType.HORIZONTAL_CROSS,
                color: 'efefef',
                fill: 'efefef',
              },
              spacing: {
                after: 500,
                before: 400,
                line: 300,
              },
              border: {
                top: {
                  color: 'e1e1e6',
                  space: 1,
                  size: 6,
                  style: BorderStyle.SINGLE,
                },
                bottom: {
                  color: 'e1e1e6',
                  space: 1,
                  style: BorderStyle.SINGLE,
                  size: 6,
                },
                left: {
                  color: 'e1e1e6',
                  space: 1,
                  style: BorderStyle.SINGLE,
                  size: 6,
                },
                right: {
                  color: 'e1e1e6',
                  space: 1,
                  style: BorderStyle.SINGLE,
                  size: 6,
                },
              },
            }),
            // Reporting requirements
            new Paragraph({
              text: this.fillInVariable(REPORTING_REQUIREMENTS, 'company', input.company.name),
              style: TextStyle.pageTitle,
              spacing: {
                after: 480,
              },
            }),
            new Paragraph({
              text: 'Under the Non-financial reporting directive (NFRD) (replaced by the Corporate Sustainability Reporting Directive (CSRD) from FY2024), non-financial undertakings which are public-interest entities (i.e. listed) with more than 500 employees, in the case of a group on a consolidated basis, are required to report on the taxonomy. Non-financial undertakings in the scope of the NFRD/CSRD are required to report on the proportion of their taxonomy-eligible and taxonomy-aligned activities.',
              style: TextStyle.description1,
              spacing: {
                after: 320,
                line: 300,
              },
            }),
            new Paragraph({
              children: [
                new TextRun({
                  text: 'Alt 1 (covered by the regulation): ',
                  bold: true,
                  size: 24,
                  font: 'Manrope',
                  color: '#212121',
                  shading: {
                    type: ShadingType.SOLID,
                    color: '#DAF7ED',
                  },
                }),
                new TextRun({
                  text: input.company.name,
                  size: 24,
                  font: 'Manrope',
                  color: '#212121',
                }),
                new TextRun({
                  text: ' is covered by the taxonomy regulation being a listed company with more than 500 employees.',
                  size: 24,
                  font: 'Manrope',
                  color: '#212121',
                }),
              ],
              spacing: {
                after: 320,
                line: 300,
              },
            }),
            new Paragraph({
              children: [
                new TextRun({
                  text: 'Alt 2 (not covered by the regulation): ',
                  bold: true,
                  size: 24,
                  font: 'Manrope',
                  shading: {
                    type: ShadingType.SOLID,
                    color: '#DAF7ED',
                  },
                  color: '#212121',
                }),
                new TextRun({
                  text: input.company.name,
                  size: 24,
                  font: 'Manrope',
                  color: '#212121',
                }),
                new TextRun({
                  text: ' is not yet covered by the taxonomy regulation being a company with less than ',
                  size: 24,
                  font: 'Manrope',
                  color: '#212121',
                }),
                new TextRun({
                  text: '500 employees/not being',
                  size: 24,
                  font: 'Manrope',
                  shading: {
                    type: ShadingType.SOLID,
                    color: '#DAF7ED',
                  },
                  color: '#212121',
                }),
                new TextRun({
                  text: this.fillInVariable(
                    ' a listed company. This report therefore represents {{company}} voluntary taxonomy report',
                    'company',
                    input.company.name
                  ),
                  size: 24,
                  font: 'Manrope',
                  color: '#212121',
                }),
              ],
              spacing: {
                after: 880,
                line: 300,
              },
            }),
            //scores table
            new Paragraph({
              text: 'Summary of results',
              style: TextStyle.pageTitle,
              spacing: {
                after: 480,
              },
            }),
            new Paragraph({
              text: 'Please note that all relative numbers in the table below refer to the company total.',
              style: TextStyle.introText,
              spacing: { after: 120 },
            }),
            new Table({
              columnWidths: [2200, 2200, 2200, 2200],
              rows: this.getScoresTable(
                input.company?.score,
                input.company?.financials,
                input.company?.currency ?? 'NOK'
              ),
              width: {
                size: '100%',
                type: WidthType.PERCENTAGE,
              },
              borders: {
                top: {
                  style: BorderStyle.DASH_DOT_STROKED,
                  size: 1,
                  color: 'e1e1e6',
                },
                left: {
                  style: BorderStyle.DASH_DOT_STROKED,
                  size: 1,
                  color: 'e1e1e6',
                },
                right: {
                  style: BorderStyle.DASH_DOT_STROKED,
                  size: 1,
                  color: 'e1e1e6',
                },
                bottom: {
                  style: BorderStyle.THICK_THIN_MEDIUM_GAP,
                  size: 5,
                  color: 'e1e1e6',
                },
              },
            }),
          ],
        },
        // KPI notes box
        {
          properties: {
            type: SectionType.CONTINUOUS,
          },
          children: [
            new Paragraph({
              children: [
                new TextRun({
                  text: EXPLANATORY_TEXT,
                  font: 'Manrope',
                  color: '#212121',
                  bold: true,
                  size: 20,
                }),
              ],
              style: TextStyle.introText,
              shading: {
                type: ShadingType.HORIZONTAL_CROSS,
                color: 'efefef',
                fill: 'efefef',
              },
              spacing: {
                after: 100,
                before: 200,
                line: 300,
              },
              border: {
                top: {
                  color: 'e1e1e6',
                  space: 1,
                  size: 6,
                  style: BorderStyle.SINGLE,
                },
                bottom: {
                  color: 'e1e1e6',
                  space: 1,
                  style: BorderStyle.SINGLE,
                  size: 6,
                },
                left: {
                  color: 'e1e1e6',
                  space: 1,
                  style: BorderStyle.SINGLE,
                  size: 6,
                },
                right: {
                  color: 'e1e1e6',
                  space: 1,
                  style: BorderStyle.SINGLE,
                  size: 6,
                },
              },
            }),
            new Paragraph({
              children: [
                new TextRun({
                  text: EXPLANATORY_STEP_1,
                  font: 'Manrope',
                  color: '#212121',
                  size: 20,
                }),
              ],
              style: TextStyle.introText,
              shading: {
                type: ShadingType.HORIZONTAL_CROSS,
                color: 'efefef',
                fill: 'efefef',
              },
              spacing: {
                after: 100,
                before: 100,
                line: 300,
              },
              border: {
                top: {
                  color: 'e1e1e6',
                  space: 1,
                  size: 6,
                  style: BorderStyle.SINGLE,
                },
                bottom: {
                  color: 'e1e1e6',
                  space: 1,
                  style: BorderStyle.SINGLE,
                  size: 6,
                },
                left: {
                  color: 'e1e1e6',
                  space: 1,
                  style: BorderStyle.SINGLE,
                  size: 6,
                },
                right: {
                  color: 'e1e1e6',
                  space: 1,
                  style: BorderStyle.SINGLE,
                  size: 6,
                },
              },
            }),
            new Paragraph({
              children: [
                new TextRun({
                  text: EXPLANATORY_STEP_2,
                  font: 'Manrope',
                  color: '#212121',
                  size: 20,
                }),
              ],
              style: TextStyle.introText,
              shading: {
                type: ShadingType.HORIZONTAL_CROSS,
                color: 'efefef',
                fill: 'efefef',
              },
              spacing: {
                after: 100,
                before: 100,
                line: 300,
              },
              border: {
                top: {
                  color: 'e1e1e6',
                  space: 1,
                  size: 6,
                  style: BorderStyle.SINGLE,
                },
                bottom: {
                  color: 'e1e1e6',
                  space: 1,
                  style: BorderStyle.SINGLE,
                  size: 6,
                },
                left: {
                  color: 'e1e1e6',
                  space: 1,
                  style: BorderStyle.SINGLE,
                  size: 6,
                },
                right: {
                  color: 'e1e1e6',
                  space: 1,
                  style: BorderStyle.SINGLE,
                  size: 6,
                },
              },
            }),
            new Paragraph({
              children: [
                new TextRun({
                  text: EXPLANATORY_STEP_3,
                  font: 'Manrope',
                  color: '#212121',
                  size: 20,
                }),
                new TextRun({
                  text: EXPLANATORY_STEP_3_ITALICS,
                  font: 'Manrope',
                  color: '#212121',
                  size: 20,
                  italics: true,
                }),
              ],
              style: TextStyle.introText,
              shading: {
                type: ShadingType.HORIZONTAL_CROSS,
                color: 'efefef',
                fill: 'efefef',
              },
              spacing: {
                after: 100,
                before: 100,
                line: 300,
              },
              border: {
                top: {
                  color: 'e1e1e6',
                  space: 1,
                  size: 6,
                  style: BorderStyle.SINGLE,
                },
                bottom: {
                  color: 'e1e1e6',
                  space: 1,
                  style: BorderStyle.SINGLE,
                  size: 6,
                },
                left: {
                  color: 'e1e1e6',
                  space: 1,
                  style: BorderStyle.SINGLE,
                  size: 6,
                },
                right: {
                  color: 'e1e1e6',
                  space: 1,
                  style: BorderStyle.SINGLE,
                  size: 6,
                },
              },
            }),
          ],
        },
        //Page containing the second table
        {
          properties: {
            type: SectionType.NEXT_PAGE,
          },
          children: [
            new Paragraph({
              text: 'Results per activity',
              style: TextStyle.pageTitle,
              spacing: {
                after: 120,
                before: 480,
              },
            }),
            new Paragraph({
              text: 'Here we recommend including the EU taxonomy tables that can be downloaded in excel format from the results page.',
              style: TextStyle.greenHighlight,
              spacing: {
                after: 120,
              },
            }),
          ],
        },
        // About the taxonomy
        {
          properties: {
            type: SectionType.NEXT_PAGE,
          },
          children: [
            new Paragraph({
              text: ABOUT_EU_TAXONOMY,
              style: TextStyle.pageTitle,
              spacing: {
                after: 480,
              },
              shading: {
                type: ShadingType.SOLID,
                color: '#DAF7ED',
              },
            }),
            new Paragraph({
              text: ABOUT_TAXONOMY_DESCRIPTION_1,
              style: TextStyle.about,
              spacing: {
                after: 320,
                line: 300,
              },
              shading: {
                type: ShadingType.SOLID,
                color: '#DAF7ED',
              },
            }),
            new Paragraph({
              text: ABOUT_TAXONOMY_DESCRIPTION_2,
              style: TextStyle.about,
              spacing: {
                after: 320,
                line: 300,
              },
              shading: {
                type: ShadingType.SOLID,
                color: '#DAF7ED',
              },
            }),
            new Paragraph({
              text: ABOUT_TAXONOMY_DESCRIPTION_3,
              style: TextStyle.about,
              spacing: {
                after: 320,
                line: 300,
              },
              shading: {
                type: ShadingType.SOLID,
                color: '#DAF7ED',
              },
            }),
          ],
        },
        // Taxonomy steps
        {
          properties: {
            type: SectionType.CONTINUOUS,
          },
          children: [
            new Paragraph({
              text: STEPS_ALIGNMENT,
              style: TextStyle.stepsTitle,
              spacing: {
                after: 320,
              },
              shading: {
                type: ShadingType.SOLID,
                color: '#DAF7ED',
              },
            }),
            new Paragraph({
              text: STEP1,
              style: TextStyle.steps,
              spacing: {
                line: 300,
              },
              shading: {
                type: ShadingType.SOLID,
                color: '#DAF7ED',
              },
              numbering: {
                reference: 'number-system',
                level: 1,
              },
            }),
            new Paragraph({
              text: STEP2,
              style: TextStyle.steps,
              spacing: {
                line: 300,
              },
              shading: {
                type: ShadingType.SOLID,
                color: '#DAF7ED',
              },
              numbering: {
                reference: 'number-system',
                level: 1,
              },
            }),
            new Paragraph({
              text: STEP3,
              style: TextStyle.steps,
              spacing: {
                line: 300,
              },
              shading: {
                type: ShadingType.SOLID,
                color: '#DAF7ED',
              },
              numbering: {
                reference: 'number-system',
                level: 1,
              },
            }),
          ],
        },
        // Taxonomy assessment methodology
        {
          properties: {
            type: SectionType.NEXT_PAGE,
          },
          children: [
            new Paragraph({
              text: METHODOLOGY_TITLE,
              style: TextStyle.pageTitle,
              spacing: {
                after: 480,
              },
            }),
            new Paragraph({
              text: this.fillInVariable(METHODOLOGY_DESCRIPTION, 'company', input.company.name),
              style: TextStyle.smallParagraph,
              spacing: {
                after: 480,
                line: 300,
              },
            }),
            new Paragraph({
              text: REQUIREMENT1,
              style: TextStyle.subTitle,
              spacing: {
                after: 160,
              },
              numbering: {
                reference: 'number-system',
                level: 4,
              },
            }),
            new Paragraph({
              children: [
                new TextRun({
                  text: this.fillInVariable(
                    REQUIREMENT1_DESCRIPTION,
                    'company',
                    input.company.name
                  ),
                  size: 24,
                  font: 'Manrope',
                  color: '#212121',
                }),
                new TextRun({
                  text: REQUIREMENT1_DESCRIPTION2,
                  size: 24,
                  font: 'Manrope',
                  shading: {
                    type: ShadingType.SOLID,
                    color: '#DAF7ED',
                  },
                  color: '#212121',
                }),
                new TextRun({
                  text: REQUIREMENT1_DESCRIPTION3,
                  size: 24,
                  font: 'Manrope',
                  color: '#212121',
                }),
              ],
              spacing: {
                after: 100,
                line: 300,
              },
              numbering: {
                reference: 'number-system',
                level: 5,
              },
            }),
            new Paragraph({
              text: REQUIREMENT1_DESCRIPTION4,
              style: TextStyle.smallParagraph,
              spacing: {
                after: 100,
                line: 300,
              },
              shading: {
                type: ShadingType.SOLID,
                color: '#DAF7ED',
              },
              numbering: {
                reference: 'number-system',
                level: 5,
              },
            }),
            new Paragraph({
              text: REQUIREMENT1_DESCRIPTION5,
              style: TextStyle.smallParagraph,
              spacing: {
                line: 300,
              },
              shading: {
                type: ShadingType.SOLID,
                color: '#DAF7ED',
              },
              numbering: {
                reference: 'number-system',
                level: 5,
              },
            }),
            //Req2
            new Paragraph({
              text: REQUIREMENT2,
              style: TextStyle.subTitle,
              spacing: {
                before: 480,
                after: 160,
              },
              numbering: {
                reference: 'number-system',
                level: 4,
              },
            }),
            new Paragraph({
              children: [
                new TextRun({
                  text: this.fillInVariable(
                    REQUIREMENT2_DESCRIPTION1,
                    'company',
                    input.company.name
                  ),
                  size: 24,
                  font: 'Manrope',
                  color: '#212121',
                }),
                new TextRun({
                  text: REQUIREMENT2_DESCRIPTION2,
                  size: 24,
                  font: 'Manrope',
                  shading: {
                    type: ShadingType.SOLID,
                    color: '#DAF7ED',
                  },
                  color: '#212121',
                }),
                new TextRun({
                  text: this.fillInVariable(
                    REQUIREMENT2_DESCRIPTION3,
                    'company',
                    input.company.name
                  ),
                  size: 24,
                  font: 'Manrope',
                  color: '#212121',
                }),
              ],
              spacing: {
                after: 300,
                line: 300,
              },
              numbering: {
                reference: 'number-system',
                level: 5,
              },
            }),
            new Table({
              columnWidths: [4000, 4000],
              rows: this.getActivitiesCommentsTable(input.activities),
              borders,
              width: {
                size: '100%',
                type: WidthType.PERCENTAGE,
              },
              indent: {
                size: 600,
                type: WidthType.AUTO,
              },
            }),
            //Req3
            new Paragraph({
              text: REQUIREMENT3,
              style: TextStyle.subTitle,
              spacing: {
                before: 480,
                after: 160,
              },
              numbering: {
                reference: 'number-system',
                level: 4,
              },
            }),
            new Paragraph({
              text: this.fillInVariable(REQUIREMENT3_DESCRIPTION, 'company', input.company.name),
              style: TextStyle.smallParagraph,
              spacing: {
                after: 480,
                line: 300,
              },
              numbering: {
                reference: 'number-system',
                level: 5,
              },
            }),
            //Req4
            new Paragraph({
              text: REQUIREMENT4,
              style: TextStyle.subTitle,
              spacing: {
                after: 160,
              },
              numbering: {
                reference: 'number-system',
                level: 4,
              },
            }),
            new Paragraph({
              children: [
                new TextRun({
                  text: this.fillInVariable(
                    REQUIREMENT4_DESCRIPTION1P1,
                    'company',
                    input.company.name
                  ),
                  size: 24,
                  font: 'Manrope',
                  color: '#212121',
                }),
                new TextRun({
                  text: REQUIREMENT4_DESCRIPTION1P2,
                  size: 24,
                  font: 'Manrope',
                  shading: {
                    type: ShadingType.SOLID,
                    color: '#DAF7ED',
                  },
                  color: '#212121',
                }),
                new TextRun({
                  text: this.fillInVariable(
                    REQUIREMENT4_DESCRIPTION1P3,
                    'company',
                    input.company.name
                  ),
                  size: 24,
                  font: 'Manrope',
                  color: '#212121',
                }),
              ],
              spacing: {
                line: 300,
                after: 160,
              },
              numbering: {
                reference: 'number-system',
                level: 5,
              },
            }),
            new Paragraph({
              text: 'Assessing substantial contribution criteria',
              style: TextStyle.subTitle,
              spacing: {
                line: 300,
              },
              numbering: {
                reference: 'number-system',
                level: 5,
              },
            }),
            new Paragraph({
              text: '[Add information about how the company’s activities meet the SC criteria for each of the taxonomy activities. This can be done by including a section for each activity.]',
              style: TextStyle.smallParagraph,
              spacing: {
                line: 300,
              },
              shading: {
                type: ShadingType.SOLID,
                color: '#DAF7ED',
              },
              numbering: {
                reference: 'number-system',
                level: 5,
              },
            }),
            new Paragraph({
              text: 'Assessing DNSH criteria',
              style: TextStyle.subTitle,
              spacing: {
                line: 300,
              },
              numbering: {
                reference: 'number-system',
                level: 5,
              },
            }),
            new Paragraph({
              text: '[Add information about how the company’s activities meet the DNSH criteria for each of the taxonomy activities. This can be done by including a section for each activity]',
              style: TextStyle.smallParagraph,
              spacing: {
                line: 300,
              },
              shading: {
                type: ShadingType.SOLID,
                color: '#DAF7ED',
              },
              numbering: {
                reference: 'number-system',
                level: 5,
              },
            }),
            new Paragraph({
              text: 'Assessing minimum safeguards',
              style: TextStyle.subTitle,
              spacing: {
                line: 300,
              },
              numbering: {
                reference: 'number-system',
                level: 5,
              },
            }),
            new Paragraph({
              text: this.fillInVariable(REQUIREMENT4_DESCRIPTION1P4, 'company', input.company.name),
              style: TextStyle.smallParagraph,
              spacing: {
                line: 300,
              },
              numbering: {
                reference: 'number-system',
                level: 5,
              },
            }),
            new Paragraph({
              text: '[Add information about minimum safeguards, including links to relevant policies and reports]',
              style: TextStyle.smallParagraph,
              spacing: {
                line: 300,
              },
              shading: {
                type: ShadingType.SOLID,
                color: '#DAF7ED',
              },
              numbering: {
                reference: 'number-system',
                level: 5,
              },
            }),
            //Req5
            new Paragraph({
              text: REQUIREMENT5,
              style: TextStyle.subTitle,
              spacing: {
                before: 480,
                after: 160,
              },
              numbering: {
                reference: 'number-system',
                level: 4,
              },
            }),
            new Paragraph({
              text: this.fillInVariable(REQUIREMENT5_DESCRIPTION, 'company', input.company.name),
              style: TextStyle.smallParagraph,
              spacing: {
                after: 100,
                line: 300,
              },
              numbering: {
                reference: 'number-system',
                level: 5,
              },
            }),
            new Paragraph({
              text: 'Avoiding double counting',
              style: TextStyle.subTitle,
              spacing: {
                line: 300,
              },
              numbering: {
                reference: 'number-system',
                level: 5,
              },
            }),
            new Paragraph({
              text: '[Add information on how double counting is avoided between activities]',
              style: TextStyle.smallParagraph,
              spacing: {
                line: 300,
              },
              shading: {
                type: ShadingType.SOLID,
                color: '#DAF7ED',
              },
              numbering: {
                reference: 'number-system',
                level: 5,
              },
            }),
          ],
        },
        // Accounting principles
        {
          properties: {
            type: SectionType.NEXT_PAGE,
          },
          children: [
            new Paragraph({
              text: ACCOUNTING_PRINCIPLES,
              style: TextStyle.pageTitle,
              spacing: {
                after: 480,
              },
            }),
            new Paragraph({
              text: ACCOUNTING_PRINCIPLES_DESCRIPTION1,
              style: TextStyle.smallParagraph,
              spacing: {
                after: 480,
                line: 300,
              },
            }),
            // Turnover KPI
            new Paragraph({
              text: 'Turnover KPI',
              style: TextStyle.subTitle,
              spacing: {
                after: 160,
              },
            }),
            new Paragraph({
              text: 'The turnover KPI includes the revenue recognised pursuant to International Accounting Standard (IAS) 1, paragraph 82(a).',
              style: TextStyle.smallParagraph,
              spacing: {
                after: 100,
              },
            }),
            new Paragraph({
              text: '[Add additional explanation about what you have included/not included in the turnover KPI, the accounting standards used and how it relates to the financial reporting notes].',
              style: TextStyle.smallParagraph,
              spacing: {
                after: 100,
              },
              shading: {
                type: ShadingType.SOLID,
                color: '#DAF7ED',
              },
            }),
            new Paragraph({
              text: '[Add additional explanation about which projects are considered aligned and not on a high-level and how turnover has been allocated between the activities]',
              style: TextStyle.smallParagraph,
              shading: {
                type: ShadingType.SOLID,
                color: '#DAF7ED',
              },
            }),
            // CapEx KPI
            new Paragraph({
              text: 'CapEx KPI',
              style: TextStyle.subTitle,
              spacing: {
                before: 480,
                after: 160,
              },
            }),
            new Paragraph({
              text: 'The CapEx KPI covers additions to tangible and intangible assets during the financial year considered before depreciation, amortisation and any re-measurements, including those resulting from revaluations and impairments, for the relevant financial year and excluding fair value changes.',
              style: TextStyle.smallParagraph,
              spacing: {
                after: 100,
              },
            }),
            new Paragraph({
              text: '[Add additional explanation about what you have included/not included in the CapEx KPI, accounting standard used and how it relates to the financial reporting notes]',
              style: TextStyle.smallParagraph,
              spacing: {
                after: 100,
              },
              shading: {
                type: ShadingType.SOLID,
                color: '#DAF7ED',
              },
            }),
            new Paragraph({
              text: '[Please note that this section should be adapted depending on if a CapEx plan has been used or not and whether or not the company has purchased the output of taxonomy-aligned activities].',
              style: TextStyle.smallParagraph,
              spacing: {
                after: 100,
              },
              shading: {
                type: ShadingType.SOLID,
                color: '#DAF7ED',
              },
            }),
            new Paragraph({
              text: '[If CapEx from a CapEx plan has been included in the numerator, information on the CapEx plan should be added here. This could include for example: information about the timeline of the CapEx plan; specific CapEx investments; how the plan has been approved by the Board of Directors; and how the plan will expand a taxonomy aligned activity or bring the activity into alignment. If a CapEx plan has not been used, this should also be specified.]',
              style: TextStyle.smallParagraph,
              spacing: {
                after: 100,
              },
              shading: {
                type: ShadingType.SOLID,
                color: '#DAF7ED',
              },
            }),
            new Paragraph({
              text: '[If the numerator includes CapEx related to the purchase of output from Taxonomy-aligned economic activities and investments in individual measures enabling the target activities to become low-carbon or to lead to greenhouse gas reductions information on these purchases and investments should be added here.]',
              style: TextStyle.smallParagraph,
              spacing: {
                after: 100,
              },
              shading: {
                type: ShadingType.SOLID,
                color: '#DAF7ED',
              },
            }),
            new Paragraph({
              text: '[Add additional explanation about which projects are considered aligned and not on a high-level and how the CapEx has been allocated between activities]',
              style: TextStyle.smallParagraph,
              shading: {
                type: ShadingType.SOLID,
                color: '#DAF7ED',
              },
            }),
            // OpEx KPI
            new Paragraph({
              text: 'OpEx KPI',
              style: TextStyle.subTitle,
              spacing: {
                before: 480,
                after: 160,
              },
            }),
            new Paragraph({
              text: 'The OpEx KPI covers direct non-capitalised costs that relate to research and development, building renovation measures, short-term lease, maintenance and repair, and any other direct expenditures relating to the day-to-day servicing of assets of property, plant and equipment by the undertaking or third party to whom activities are outsourced that are necessary to ensure the continued and effective functioning of such assets.',
              style: TextStyle.smallParagraph,
              spacing: {
                after: 100,
              },
            }),
            new Paragraph({
              text: '[Add additional explanation about what you have included/not included in the OpEx KPI, accounting standard used and how it relates to the financial reporting notes]',
              style: TextStyle.smallParagraph,
              spacing: {
                after: 100,
              },
              shading: {
                type: ShadingType.SOLID,
                color: '#DAF7ED',
              },
            }),
            new Paragraph({
              text: '[Please note that this section should be adapted depending on if a CapEx plan has been used or not and whether the company has purchased the output of taxonomy-aligned activities].',
              style: TextStyle.smallParagraph,
              spacing: {
                after: 100,
              },
              shading: {
                type: ShadingType.SOLID,
                color: '#DAF7ED',
              },
            }),
            new Paragraph({
              text: '[If OpEx related to a CapEx plan has been included in the numerator, information on the CapEx plan should be added here. This could include for example: information about the timeline of the CapEx plan; specific OpEx investments; how the plan has been approved by the Board of Directors; and how the plan will expand a taxonomy aligned activity or bring the activity into alignment. If a CapEx plan has not been used, this should also be specified.]',
              style: TextStyle.smallParagraph,
              spacing: {
                after: 100,
              },
              shading: {
                type: ShadingType.SOLID,
                color: '#DAF7ED',
              },
            }),
            new Paragraph({
              text: '[If the numerator includes OpEx related to the purchase of output from Taxonomy-aligned economic activities and investments in individual measures enabling the target activities to become low-carbon or to lead to greenhouse gas reductions information on these purchases and investments should be added here.]',
              style: TextStyle.smallParagraph,
              spacing: {
                after: 100,
              },
              shading: {
                type: ShadingType.SOLID,
                color: '#DAF7ED',
              },
            }),
            new Paragraph({
              text: '[Add additional explanation about which projects are considered aligned and not on a high-level and how the OpEx has been allocated between activities]',
              style: TextStyle.smallParagraph,
              shading: {
                type: ShadingType.SOLID,
                color: '#DAF7ED',
              },
            }),
          ],
        },
        // General comments
        {
          properties: {
            type: SectionType.NEXT_PAGE,
          },
          children: [
            new Paragraph({
              text: 'General comments',
              style: TextStyle.pageTitle,
              spacing: {
                after: 480,
              },
            }),
            new Paragraph({
              text: GENERAL_COMMENTS_1,
              style: TextStyle.smallParagraph,
              spacing: {
                after: 160,
                line: 300,
              },
            }),
            new Paragraph({
              text: this.fillInVariable(GENERAL_COMMENTS_2, 'company', input.company.name),
              style: TextStyle.smallParagraph,
              spacing: {
                line: 300,
              },
            }),
          ],
        },
        // Appendix 1
        {
          properties: {
            type: SectionType.NEXT_PAGE,
          },
          children: [
            new Paragraph({
              text: MINIMUM_SAFEGUARDS,
              style: TextStyle.pageTitle,
              spacing: {
                line: 300,
                after: 360,
              },
            }),
            new Paragraph({
              text: MINIMUM_SAFEGUARDS_DESCRIPTION,
              style: TextStyle.smallParagraph,
              spacing: {
                line: 300,
                after: 220,
              },
            }),
            new Paragraph({
              text: DISCLOSURE_ON_NUCLEAR_AND_FOSSIL,
              style: TextStyle.pageTitle,
              spacing: {
                line: 300,
                after: 220,
              },
            }),
            new Table({
              columnWidths: [1500, 5500, 1500],
              rows: this.getDisclosuresOnNuclearEnergyTable(),
              borders,
              width: {
                size: '100%',
                type: WidthType.PERCENTAGE,
              },
            }),
          ],
        },
      ],
      styles: {
        paragraphStyles: [
          {
            id: TextStyle.greenHighlight,
            name: 'Green Highlight',
            basedOn: 'Normal',
            next: 'Normal',
            quickFormat: true,
            run: {
              size: 22,
              color: '000000',
              font: 'Manrope',
              shading: {
                type: ShadingType.SOLID,
                color: '#DAF7ED',
              },
            },
          },
          {
            id: TextStyle.columnCell,
            name: 'Column Cell',
            basedOn: 'Normal',
            next: 'Normal',
            quickFormat: true,
            run: {
              size: 22,
              color: '000000',
              font: 'Manrope',
            },
          },
          {
            id: TextStyle.biggerGreenHighlight,
            name: 'Bigger Green Highlight',
            basedOn: 'Normal',
            next: 'Normal',
            quickFormat: true,
            run: {
              size: 24,
              color: '#212121',
              font: 'Manrope',
              shading: {
                type: ShadingType.SOLID,
                color: '#DAF7ED',
              },
            },
          },
          {
            id: TextStyle.introText,
            name: 'Intro text',
            basedOn: 'Normal',
            next: 'Normal',
            run: {
              color: '#212121',
              size: 22,
              font: 'Manrope',
              bold: false,
            },
          },
          {
            id: TextStyle.tableTitle,
            name: 'Table title',
            basedOn: 'Normal',
            next: 'Normal',
            run: {
              color: '#1a1a1a',
              size: 24,
              font: 'Manrope',
              bold: true,
            },
          },
          {
            id: TextStyle.columnTitle,
            name: 'Column Title',
            basedOn: 'Normal',
            next: 'Normal',
            run: {
              color: '#1a1a1a',
              size: 22,
              font: 'Manrope',
              bold: true,
            },
          },
          {
            id: TextStyle.columnRow,
            name: 'Column Title',
            basedOn: 'Normal',
            next: 'Normal',
            run: {
              color: '#1a1a1a',
              size: 22,
              font: 'Manrope',
            },
          },
          {
            id: TextStyle.description1,
            name: 'Description 1',
            basedOn: 'Normal',
            next: 'Normal',
            run: {
              color: '#212121',
              size: 24,
              font: 'Manrope',
              bold: false,
            },
          },
          {
            id: TextStyle.pageTitle,
            name: 'Page title',
            basedOn: 'Normal',
            next: 'Normal',
            run: {
              color: '#1C1C57',
              size: 36,
              font: 'Manrope',
              bold: true,
            },
          },
          {
            id: TextStyle.smallTitle,
            name: 'Small title',
            basedOn: 'Normal',
            next: 'Normal',
            run: {
              color: '#1C1C57',
              size: 22,
              font: 'Manrope',
              bold: true,
            },
          },
          {
            id: TextStyle.documentTitle,
            name: 'Document title',
            basedOn: 'Normal',
            next: 'Normal',
            run: {
              color: '#1C1C57',
              size: 44,
              font: 'Manrope',
              bold: true,
            },
          },
          {
            id: TextStyle.about,
            name: 'About',
            basedOn: 'Normal',
            next: 'Normal',
            run: {
              color: '#212121',
              size: 24,
              font: 'Manrope',
              bold: false,
            },
          },
          {
            id: TextStyle.stepsTitle,
            name: 'Steps title',
            basedOn: 'Normal',
            next: 'Normal',
            run: {
              color: '#1C1C57',
              size: 24,
              font: 'Manrope',
              bold: false,
            },
          },
          {
            id: TextStyle.steps,
            name: 'Steps',
            basedOn: 'Normal',
            next: 'Normal',
            run: {
              color: '#212121',
              size: 24,
              font: 'Manrope',
              bold: false,
            },
          },
          {
            id: TextStyle.heading2,
            name: 'Heading 2',
            basedOn: 'Normal',
            next: 'Normal',
            run: {
              color: '#1a1a1a',
              size: 24,
              font: 'Manrope',
              bold: true,
            },
          },
          {
            id: TextStyle.smallParagraph,
            name: 'Small Paragraph',
            basedOn: 'Normal',
            next: 'Normal',
            run: {
              color: '#212121',
              size: 24,
              font: 'Manrope',
              bold: false,
            },
          },
          {
            id: TextStyle.subTitle,
            name: 'Subtitle',
            basedOn: 'Normal',
            next: 'Normal',
            run: {
              color: '#1C1C57',
              size: 24,
              font: 'Manrope',
              bold: true,
            },
          },
          {
            id: TextStyle.section,
            name: 'Section',
            basedOn: 'Normal',
            next: 'Normal',
            run: {
              color: '#212121',
              size: 24,
              font: 'Manrope',
              bold: true,
            },
          },
        ],
      },
    });
    return document;
  }

  public getOneScoreRow(
    score: DocumentResult['score'],
    title: string,
    getValue: (section?: TaxonomyScore) => string,
    lowerCase = false
  ) {
    const borders = {
      top: {
        style: BorderStyle.THICK,
        size: 1,
        color: 'e1e1e6',
      },
      left: {
        style: BorderStyle.THICK,
        size: 1,
        color: 'e1e1e6',
      },
      right: {
        style: BorderStyle.THICK,
        size: 1,
        color: 'e1e1e6',
      },
      bottom: {
        style: BorderStyle.THICK,
        size: 5,
        color: 'e1e1e6',
      },
    };
    return new TableRow({
      children: [
        new TableCell({
          children: [
            new Paragraph({
              text: title,
              style: lowerCase ? TextStyle.columnRow : TextStyle.columnTitle,
            }),
          ],
          verticalAlign: VerticalAlign.CENTER,
          borders,
        }),
        new TableCell({
          children: [
            new Paragraph({ text: `${getValue(score?.revenue)}`, style: TextStyle.columnRow }),
          ],
          verticalAlign: VerticalAlign.CENTER,
          borders,
        }),
        new TableCell({
          children: [
            new Paragraph({ text: `${getValue(score?.capex)}`, style: TextStyle.columnRow }),
          ],
          verticalAlign: VerticalAlign.CENTER,
          borders,
        }),
        new TableCell({
          children: [
            new Paragraph({ text: `${getValue(score?.opex)}`, style: TextStyle.columnRow }),
          ],
          verticalAlign: VerticalAlign.CENTER,
          borders,
        }),
      ],
      height: {
        value: 500,
        rule: HeightRule.AUTO,
      },
    });
  }

  public createLogoFooter(logoImage: ArrayBuffer) {
    return new Footer({
      children: [
        new Paragraph({
          alignment: AlignmentType.CENTER,
          children: [
            new ImageRun({
              data: logoImage,
              transformation: {
                width: 61,
                height: 22,
              },
            }),
          ],
        }),
      ],
    });
  }

  public getActivitiesCommentsTable(activities: DocumentInput['activities']) {
    const borders = {
      top: {
        style: BorderStyle.THICK,
        size: 1,
        color: 'e1e1e6',
      },
      left: {
        style: BorderStyle.THICK,
        size: 1,
        color: 'e1e1e6',
      },
      right: {
        style: BorderStyle.THICK,
        size: 1,
        color: 'e1e1e6',
      },
      bottom: {
        style: BorderStyle.THICK,
        size: 5,
        color: 'e1e1e6',
      },
    };
    const headersRow = new TableRow({
      children: [
        new TableCell({
          children: [new Paragraph({ text: 'Activity', style: TextStyle.columnTitle })],
          borders,
          verticalAlign: VerticalAlign.CENTER,
        }),
        new TableCell({
          children: [new Paragraph({ text: 'Comments', style: TextStyle.columnTitle })],
          verticalAlign: VerticalAlign.CENTER,
          borders,
        }),
      ],
      height: {
        value: 400,
        rule: HeightRule.AUTO,
      },
    });

    const activitiesRows = activities
      .filter((act) => act.name !== 'Taxonomy-eligible activities' && act.name !== 'Total')
      .map((activity) => {
        return new TableRow({
          children: [
            new TableCell({
              children: [
                new Paragraph({
                  text: activity.name,
                  style: TextStyle.columnCell,
                }),
              ],
              borders,
              verticalAlign: VerticalAlign.CENTER,
            }),
            new TableCell({
              children: [
                new Paragraph({
                  text:
                    activity.name === 'Taxonomy-non-eligible activities'
                      ? '[Explain which parts of the company’s activities are not eligible for the taxonomy]'
                      : '[Explain reasoning for choice of activity]',
                  style: TextStyle.greenHighlight,
                }),
              ],
              verticalAlign: VerticalAlign.CENTER,
              borders,
            }),
          ],
          height: {
            value: 400,
            rule: HeightRule.AUTO,
          },
        });
      });
    return [headersRow, ...activitiesRows];
  }

  public getDisclosuresOnNuclearEnergyTable() {
    const borders = {
      top: {
        style: BorderStyle.THICK,
        size: 1,
        color: 'e1e1e6',
      },
      left: {
        style: BorderStyle.THICK,
        size: 1,
        color: 'e1e1e6',
      },
      right: {
        style: BorderStyle.THICK,
        size: 1,
        color: 'e1e1e6',
      },
      bottom: {
        style: BorderStyle.THICK,
        size: 5,
        color: 'e1e1e6',
      },
    };
    const headersRow = new TableRow({
      children: [
        new TableCell({
          children: [new Paragraph({ text: 'Row', style: TextStyle.columnTitle })],
          borders,
          verticalAlign: VerticalAlign.CENTER,
        }),
        new TableCell({
          children: [
            new Paragraph({
              text: 'Nuclear energy related activities',
              style: TextStyle.columnTitle,
            }),
          ],
          verticalAlign: VerticalAlign.CENTER,
          borders,
        }),
      ],
      height: {
        value: 700,
        rule: HeightRule.AUTO,
      },
    });
    const activitiesRow1 = new TableRow({
      children: [
        new TableCell({
          children: [new Paragraph({ text: '1.', style: TextStyle.columnCell })],
          borders,
          verticalAlign: VerticalAlign.CENTER,
        }),
        new TableCell({
          children: [
            new Paragraph({
              text: 'The undertaking carries out, funds or has exposures to research, development, demonstration and deployment of innovative electricity generation facilities that produce energy from nuclear processes with minimal waste from the fuel cycle',
              style: TextStyle.columnCell,
            }),
          ],
          verticalAlign: VerticalAlign.CENTER,
          borders,
        }),
        new TableCell({
          children: [
            new Paragraph({
              text: 'YES/NO',
              style: TextStyle.greenHighlight,
            }),
          ],
          verticalAlign: VerticalAlign.CENTER,
          borders,
        }),
      ],
      height: {
        value: 500,
        rule: HeightRule.AUTO,
      },
    });
    const activitiesRow2 = new TableRow({
      children: [
        new TableCell({
          children: [new Paragraph({ text: '2.', style: TextStyle.columnCell })],
          borders,
          verticalAlign: VerticalAlign.CENTER,
        }),
        new TableCell({
          children: [
            new Paragraph({
              text: 'The undertaking carries out, funds or has exposures to construction and safe operation of new nuclear installations to produce electricity or process heat, including for the purposes of district heating or industrial processes such as hydrogen production, as well as their safety upgrades, using best available technologies',
              style: TextStyle.columnCell,
            }),
          ],
          verticalAlign: VerticalAlign.CENTER,
          borders,
        }),
        new TableCell({
          children: [
            new Paragraph({
              text: 'YES/NO',
              style: TextStyle.greenHighlight,
            }),
          ],
          verticalAlign: VerticalAlign.CENTER,
          borders,
        }),
      ],
      height: {
        value: 500,
        rule: HeightRule.AUTO,
      },
    });
    const activitiesRow3 = new TableRow({
      children: [
        new TableCell({
          children: [new Paragraph({ text: '3.', style: TextStyle.columnCell })],
          borders,
          verticalAlign: VerticalAlign.CENTER,
        }),
        new TableCell({
          children: [
            new Paragraph({
              text: 'The undertaking carries out, funds or has exposures to safe operation of existing nuclear installations that produce electricity or process heat, including for the purposes of district heating or industrial processes such as hydrogen production from nuclear energy, as well as their safety upgrades.',
              style: TextStyle.columnCell,
            }),
          ],
          verticalAlign: VerticalAlign.CENTER,
          borders,
        }),
        new TableCell({
          children: [
            new Paragraph({
              text: 'YES/NO',
              style: TextStyle.greenHighlight,
            }),
          ],
          verticalAlign: VerticalAlign.CENTER,
          borders,
        }),
      ],
      height: {
        value: 500,
        rule: HeightRule.AUTO,
      },
    });
    const headersRow2 = new TableRow({
      children: [
        new TableCell({
          children: [new Paragraph({ text: '', style: TextStyle.columnTitle })],
          borders,
          verticalAlign: VerticalAlign.CENTER,
        }),
        new TableCell({
          children: [
            new Paragraph({
              text: 'Fossil gas related activities',
              style: TextStyle.columnTitle,
            }),
          ],
          verticalAlign: VerticalAlign.CENTER,
          borders,
        }),
      ],
      height: {
        value: 700,
        rule: HeightRule.AUTO,
      },
    });
    const activitiesRow4 = new TableRow({
      children: [
        new TableCell({
          children: [new Paragraph({ text: '4.', style: TextStyle.columnCell })],
          borders,
          verticalAlign: VerticalAlign.CENTER,
        }),
        new TableCell({
          children: [
            new Paragraph({
              text: 'The undertaking carries out, funds or has exposures to construction or operation of electricity generation facilities that produce electricity using fossil gaseous fuels.',
              style: TextStyle.columnCell,
            }),
          ],
          verticalAlign: VerticalAlign.CENTER,
          borders,
        }),
        new TableCell({
          children: [
            new Paragraph({
              text: 'YES/NO',
              style: TextStyle.greenHighlight,
            }),
          ],
          verticalAlign: VerticalAlign.CENTER,
          borders,
        }),
      ],
      height: {
        value: 500,
        rule: HeightRule.AUTO,
      },
    });
    const activitiesRow5 = new TableRow({
      children: [
        new TableCell({
          children: [new Paragraph({ text: '5.', style: TextStyle.columnCell })],
          borders,
          verticalAlign: VerticalAlign.CENTER,
        }),
        new TableCell({
          children: [
            new Paragraph({
              text: 'The undertaking carries out, funds or has exposures to construction, refurbishment, and operation of combined heat/cool and power generation facilities using fossil gaseous fuels.',
              style: TextStyle.columnCell,
            }),
          ],
          verticalAlign: VerticalAlign.CENTER,
          borders,
        }),
        new TableCell({
          children: [
            new Paragraph({
              text: 'YES/NO',
              style: TextStyle.greenHighlight,
            }),
          ],
          verticalAlign: VerticalAlign.CENTER,
          borders,
        }),
      ],
      height: {
        value: 500,
        rule: HeightRule.AUTO,
      },
    });
    const activitiesRow6 = new TableRow({
      children: [
        new TableCell({
          children: [new Paragraph({ text: '6.', style: TextStyle.columnCell })],
          borders,
          verticalAlign: VerticalAlign.CENTER,
        }),
        new TableCell({
          children: [
            new Paragraph({
              text: 'The undertaking carries out, funds or has exposures to construction, refurbishment and operation of heat generation facilities that produce heat/cool using fossil gaseous fuels.',
              style: TextStyle.columnCell,
            }),
          ],
          verticalAlign: VerticalAlign.CENTER,
          borders,
        }),
        new TableCell({
          children: [
            new Paragraph({
              text: 'YES/NO',
              style: TextStyle.greenHighlight,
            }),
          ],
          verticalAlign: VerticalAlign.CENTER,
          borders,
        }),
      ],
      height: {
        value: 500,
        rule: HeightRule.AUTO,
      },
    });
    return [
      headersRow,
      activitiesRow1,
      activitiesRow2,
      activitiesRow3,
      headersRow2,
      activitiesRow4,
      activitiesRow5,
      activitiesRow6,
    ];
  }

  public getScoresTable(
    score: DocumentResult['score'],
    financials: DocumentResult['financials'],
    currency: string
  ) {
    const borders = {
      top: {
        style: BorderStyle.THICK,
        size: 1,
        color: 'e1e1e6',
      },
      left: {
        style: BorderStyle.THICK,
        size: 1,
        color: 'e1e1e6',
      },
      right: {
        style: BorderStyle.THICK,
        size: 1,
        color: 'e1e1e6',
      },
      bottom: {
        style: BorderStyle.THICK,
        size: 5,
        color: 'e1e1e6',
      },
    };
    const headersRow = new TableRow({
      children: [
        new TableCell({
          children: [new Paragraph('')],
          verticalAlign: VerticalAlign.CENTER,
          borders,
        }),
        new TableCell({
          children: [new Paragraph({ text: 'Turnover', style: TextStyle.columnTitle })],
          verticalAlign: VerticalAlign.CENTER,
          borders,
        }),
        new TableCell({
          children: [new Paragraph({ text: 'CapEx', style: TextStyle.columnTitle })],
          verticalAlign: VerticalAlign.CENTER,
          borders,
        }),
        new TableCell({
          children: [new Paragraph({ text: 'OpEx', style: TextStyle.columnTitle })],
          verticalAlign: VerticalAlign.CENTER,
          borders,
        }),
      ],
      height: {
        value: 500,
        rule: HeightRule.AUTO,
      },
    });

    const formatNumberToThousands = (x?: number) => {
      if (x === undefined) {
        return '-';
      } else if (x < 1000) {
        return x.toString();
      } else {
        const thousands = Math.round(x / 1000);
        return thousands.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') + 'k';
      }
    };

    const totalRow = this.getOneScoreRow(financials, 'Total (absolute value)', (section) =>
      section?.total !== null && !Number.isNaN(section?.total)
        ? `${formatNumberToThousands(section?.total)} ${currency}`
        : '-'
    );
    const eligibleRow = this.getOneScoreRow(score, 'Eligible', (section) =>
      section?.eligible !== null && !Number.isNaN(section?.eligible)
        ? `${formatNum(section?.eligible ?? 0, 1)}%`
        : '-'
    );
    const alignedRow = this.getOneScoreRow(
      score,
      'Eligible, aligned',
      (section) =>
        section?.aligned !== null && !Number.isNaN(section?.aligned)
          ? `${formatNum(section?.aligned, 1)}%`
          : '-',
      true
    );
    const notAlignedRow = this.getOneScoreRow(
      score,
      'Eligible, not aligned',
      (section) =>
        section?.aligned !== null &&
        !Number.isNaN(section?.aligned) &&
        !Number.isNaN(section?.eligible)
          ? `${formatNum(100 - ((section?.aligned ?? 0) + (100 - (section?.eligible ?? 0))), 1)}%`
          : '-',
      true
    );
    const notEligibleRow = this.getOneScoreRow(score, 'Not eligible', (section) =>
      section?.eligible !== null && !Number.isNaN(section?.eligible)
        ? `${formatNum(100 - (section?.eligible ?? 0), 1)}%`
        : '-'
    );

    return [headersRow, totalRow, eligibleRow, alignedRow, notAlignedRow, notEligibleRow];
  }

  public fillInVariable(text: string, variableName: string, value: string): string {
    return text.replaceAll(`{{${variableName}}}`, value);
  }

  public makeActivitiesTable(
    t: DocumentInput['t'],
    activities: DocumentInput['activities'],
    currency: string,
    scoreSection: ScoreSectionsEnum
  ) {
    const borders = {
      top: {
        style: BorderStyle.THICK,
        size: 1,
        color: 'e1e1e6',
      },
      left: {
        style: BorderStyle.THICK,
        size: 1,
        color: 'e1e1e6',
      },
      right: {
        style: BorderStyle.THICK,
        size: 1,
        color: 'e1e1e6',
      },
      bottom: {
        style: BorderStyle.THICK,
        size: 5,
        color: 'e1e1e6',
      },
    };
    const headersRow = new TableRow({
      children: [
        new TableCell({
          children: [
            new Paragraph({
              text: 'Activities',
              style: TextStyle.columnTitle,
            }),
          ],
          verticalAlign: VerticalAlign.CENTER,
          borders,
        }),
        new TableCell({
          children: [
            new Paragraph({
              text: `${t(`common:financials.${scoreSection}`) ?? ''}, ${currency}`,
              style: TextStyle.columnTitle,
            }),
          ],
          verticalAlign: VerticalAlign.CENTER,
          borders,
        }),
        new TableCell({
          children: [new Paragraph({ text: 'Alignment', style: TextStyle.columnTitle })],
          verticalAlign: VerticalAlign.CENTER,
          borders,
        }),
        new TableCell({
          children: [
            new Paragraph({
              text: 'Transitional or enabling activity',
              style: TextStyle.columnTitle,
            }),
          ],
          verticalAlign: VerticalAlign.CENTER,
          borders,
        }),
      ],
      height: {
        value: 500,
        rule: HeightRule.AUTO,
      },
    });

    const activitiesRows = activities.map((activity) => {
      const checkAlignmentVal = (val: string) => {
        if (val === 'inProgress') {
          return 'Assessment not finished';
        } else if (val === 'undefined') {
          return '--';
        } else if (val === 'null') {
          return 'null';
        } else {
          const formattedVal = Number(val).toFixed(1);
          return `${formattedVal?.includes('.0') ? Math.floor(Number(val)) : formattedVal}%`;
        }
      };

      const formatNumberToThousands = (x?: number) => {
        if (x === undefined) {
          return '-';
        } else if (x < 1000) {
          return x.toString();
        } else {
          const thousands = Math.round(x / 1000);
          return thousands.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') + 'k';
        }
      };

      const checkActivityType = (val: string) => {
        return val === 'GREEN' ? 'Neither' : val === 'undefined' || val === 'null' ? '--' : val;
      };

      return new TableRow({
        children: [
          new TableCell({
            children: [
              new Paragraph({
                text: activity.name,
                style: ['eligible', 'nonEligible', 'total'].includes(activity.rowKey)
                  ? TextStyle.columnTitle
                  : TextStyle.columnRow,
              }),
            ],
            verticalAlign: VerticalAlign.CENTER,
            borders,
          }),
          new TableCell({
            children: [
              new Paragraph({
                text: `${
                  formatNumberToThousands(Number(activity?.financials?.[scoreSection].result)) ?? 0
                }`,
                style: TextStyle.columnRow,
              }),
            ],
            verticalAlign: VerticalAlign.CENTER,
            borders,
          }),
          new TableCell({
            children: [
              new Paragraph({
                text: checkAlignmentVal(`${activity?.alignment?.[scoreSection]?.result}`),
                style: TextStyle.columnRow,
              }),
            ],
            verticalAlign: VerticalAlign.CENTER,
            borders,
          }),
          new TableCell({
            children: [
              new Paragraph({
                text: checkActivityType(`${activity?.tag?.toLocaleUpperCase()}`),
                style: TextStyle.columnRow,
              }),
            ],
            verticalAlign: VerticalAlign.CENTER,
            borders,
          }),
        ],
        height: {
          value: 500,
          rule: HeightRule.AUTO,
        },
      });
    });
    return [headersRow, ...activitiesRows];
  }
}
