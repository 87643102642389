import { Box, HStack, Skeleton, VStack } from '@chakra-ui/react';
import { EsrsReportStandardSwitch } from '../Report.types';
import { IconButton } from 'Atoms';
import { ArrowLeftIcon, ArrowRightIcon } from 'Tokens/Icons/Direction';
import { Typography } from 'Tokens';

export const StandardSwitcher = ({
  standardIndex,
  setStandardIndex,
  isLoading,
  standards,
}: {
  standardIndex: number;
  setStandardIndex: (val: number) => void;
  isLoading: boolean;
  standards: EsrsReportStandardSwitch[];
}) => {
  const handlePrev = () => {
    if (standardIndex > 0) {
      setStandardIndex(standardIndex - 1);
    }
  };

  const handleNext = () => {
    if (standardIndex < standards.length - 1) {
      setStandardIndex(standardIndex + 1);
    }
  };

  return (
    <HStack w="300px" justifyContent="space-between" gap="0px" position="relative">
      <IconButton
        variant="ghost"
        size="sm"
        aria-label="previous-standard"
        isDisabled={standardIndex === 0}
        onClick={handlePrev}
        icon={<ArrowLeftIcon color={standardIndex === 0 ? 'text.disabled' : undefined} />}
      />
      <Box position="relative" w="244px" height="40px" overflow="hidden" m="auto">
        <Box
          position="absolute"
          w="15px"
          zIndex={10}
          height="40px"
          bg="linear-gradient(to right, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0))"
        />
        <HStack
          w="fit-content"
          height="40px"
          ml="14px"
          position="absolute"
          left={standardIndex * -240}
          wordBreak="break-all"
          top="0px"
          transition="0.3s ease-in-out"
          gap="20px"
        >
          {standards.map((std) => (
            <Box w="220px">
              <Skeleton isLoaded={!isLoading}>
                <VStack key={std?.title} gap="0px" justifyContent="center">
                  <Typography maxW="250px" variant="h3" noOfLines={1}>
                    {std.title}
                  </Typography>
                  <Typography variant="detail">{std?.category}</Typography>
                </VStack>
              </Skeleton>
            </Box>
          ))}
        </HStack>
        <Box
          position="absolute"
          w="15px"
          right="0px"
          zIndex={10}
          height="40px"
          bg="linear-gradient(to left, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0))"
        />
      </Box>
      <IconButton
        variant="ghost"
        size="sm"
        aria-label="next-standard"
        isDisabled={standardIndex === standards.length - 1}
        onClick={handleNext}
        icon={
          <ArrowRightIcon
            color={standardIndex === standards.length - 1 ? 'text.disabled' : undefined}
          />
        }
      />
    </HStack>
  );
};
