import { ListItem, UnorderedList, VStack } from "@chakra-ui/react";
import { Tooltip } from "Atoms";
import { Typography } from "Tokens";
import {
  CalculatorIcon,
  DescriptionIcon,
  EditIcon,
  HashIcon,
  LinkedIcon,
  SumIcon,
} from "Tokens/Icons/Data";
import { ArrowUpRightIcon } from "Tokens/Icons/Direction";
import { LockedIcon } from "Tokens/Icons/Function";
import { useMemo } from "react";

export enum MetricTypes {
  number = "number",
  text = "text",
  boolean = "boolean",
  aggregated = "aggregated",
  calculated = "calculated",
  locked = "locked",
  edit = "edit",
  linked = "linked",
  internallyAggregated = "internallyAggregated",
}

export const METRIC_ICONS = {
  [MetricTypes.text]: <DescriptionIcon />,
  [MetricTypes.boolean]: <DescriptionIcon />,
  [MetricTypes.number]: <HashIcon />,
  [MetricTypes.aggregated]: <SumIcon />,
  [MetricTypes.calculated]: <CalculatorIcon />,
  [MetricTypes.locked]: <LockedIcon color="text.disabled" />,
  [MetricTypes.edit]: <EditIcon />,
  [MetricTypes.linked]: <LinkedIcon color="text.hint" />,
  [MetricTypes.internallyAggregated]: <ArrowUpRightIcon color="text.hint" />,
};

export const CalculatedMetric = ({
                                   subMetrics,
                                   calculation,
                                   isBusinessUnits,
                                   isSubsidiaries,
                                   isQuarterly,
                                 }: {
  calculation?: string | null;
  subMetrics?: { name: string; reference: string }[];
  isBusinessUnits?: boolean;
  isSubsidiaries?: boolean;
  isQuarterly?: boolean;
}) => {
  const calculationString = useMemo(
      () =>
          calculation?.replace(/[a-zA-Z_]\w*/g, (match, index) => {
            const foundReference = subMetrics?.find(
                (item) => item.reference === match
            );
            const replacement = foundReference ? foundReference.name : match;
            return index > 1 ? ` ${replacement}` : replacement;
          }),
      [calculation]
  );
  const aggregatedLabel = useMemo(() => {
    if (isBusinessUnits)
      return "This data point is aggregated from business units";
    if (isSubsidiaries)
      return "This data point is aggregated from subsidiaries";
    if (isQuarterly)
      return "This data point is aggregated from other data points";
    return "This data point is aggregated from other data points";
  }, [isBusinessUnits, isSubsidiaries, isQuarterly]);

  return (
      <VStack alignItems="start">
        <Typography variant="body" color="text.onAccent">
          {aggregatedLabel}
        </Typography>
        <UnorderedList>
          {subMetrics?.slice(0, 5).map((metric) => (
              <ListItem>
                <Typography variant="body" color="text.onAccent">
                  {metric.name}
                </Typography>
              </ListItem>
          ))}
        </UnorderedList>
        {subMetrics && subMetrics.length > 5 && (
            <Typography variant="body" color="text.onAccent">
              and {subMetrics.length - 5} more metric{subMetrics.length > 6 && "s"}
            </Typography>
        )}
        {calculation && (
            <Typography variant="body" color="text.onAccent" maxW="330px">
              Formula: {calculationString}
            </Typography>
        )}
      </VStack>
  );
};

export const AggregatedMetric = ({
                                   subMetrics,
                                   isBusinessUnits,
                                   isSubsidiaries,
                                   isQuarterly,
                                 }: {
  subMetrics?: { name: string; reference: string }[];
  isBusinessUnits?: boolean;
  isSubsidiaries?: boolean;
  isQuarterly?: boolean;
}) => {
  const aggregatedLabel = useMemo(() => {
    if (isBusinessUnits)
      return "This data point is aggregated from business units";
    if (isSubsidiaries)
      return "This data point is aggregated from subsidiaries";
    if (isQuarterly)
      return "This data point is aggregated from other data points";
    return "This data point is aggregated from other data points";
  }, [isBusinessUnits, isSubsidiaries, isQuarterly]);
  return (
      <VStack alignItems="start">
        <Typography variant="body" color="text.onAccent">
          {aggregatedLabel}
        </Typography>
        {!!subMetrics?.length && (
            <UnorderedList>
              {subMetrics?.map((metric) => (
                  <ListItem>
                    <Typography variant="body" color="text.onAccent">
                      {metric.name}
                    </Typography>
                  </ListItem>
              ))}
            </UnorderedList>
        )}
      </VStack>
  );
};

export const MetricTypeIcon = ({
                                 type,
                                 calculation,
                                 subMetrics,
                                 isBusinessUnits,
                                 isSubsidiaries,
                                 isQuarterly,
                                 color,
                               }: {
  type: MetricTypes;
  calculation?: string | null;
  subMetrics?: { name: string; reference: string }[];
  isBusinessUnits?: boolean;
  isSubsidiaries?: boolean;
  isQuarterly?: boolean;
  color?: string;
}) => {
  const iconLabel = useMemo(() => {
    if (type === MetricTypes.text) return "Narrative data point";
    if (type === MetricTypes.boolean) return "Boolean data point";
    if (type === MetricTypes.edit) return "Editable metric";

    if (type === MetricTypes.linked) return "Integrated data";
    if (type === MetricTypes.internallyAggregated && isSubsidiaries)
      return "Aggregated from subsidiaries";
    if (type === MetricTypes.internallyAggregated && isBusinessUnits)
      return "Aggregated from business units";
    if (type === MetricTypes.internallyAggregated && isQuarterly)
      return "Aggregated from quarters";
    if (type === MetricTypes.calculated)
      return (
          <CalculatedMetric
              calculation={calculation}
              subMetrics={subMetrics}
              isBusinessUnits={isBusinessUnits}
              isQuarterly={isQuarterly}
              isSubsidiaries={isSubsidiaries}
          />
      );
    if (type === MetricTypes.aggregated)
      return (
          <AggregatedMetric
              isBusinessUnits={isBusinessUnits}
              isQuarterly={isQuarterly}
              isSubsidiaries={isSubsidiaries}
              subMetrics={subMetrics}
          />
      );
  }, [type]);

  return (
      <Tooltip label={iconLabel} maxW="340px" placement="left">
        <VStack alignItems="center" justifyContent="center" h="20px">
          {type === MetricTypes.edit ? (
              <EditIcon color={color} />
          ) : (
              METRIC_ICONS[type]
          )}
        </VStack>
      </Tooltip>
  );
};
