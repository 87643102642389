import { HStack, Stack, VStack } from '@chakra-ui/react';
import { Button, ExternalLink, FormField, Input } from 'Atoms';
import { Typography } from 'Tokens';
import { Logo } from 'Molecules';
import React, { useEffect, useState } from 'react';
import { Controller, useForm, useFormState } from 'react-hook-form';
import PasswordStrengthBar from 'react-password-strength-bar';
import { useNavigate } from 'react-router-dom';
import { useToast } from 'utils/hooks';
import { nhost } from 'utils/nhost';
import { isBusinessEmail } from 'utils/strings';
import { useTranslation } from 'utils/translation';

type UserFields = {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
};
export const SignUpForm = ({ onSuccess, email }: { onSuccess?: () => void; email?: string }) => {
  const [, setErrorMessage] = React.useState<string>();

  const toast = useToast();
  const navigate = useNavigate();
  const { t } = useTranslation(['login', 'common']);
  const { control, handleSubmit, watch, resetField } = useForm<UserFields>({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    criteriaMode: 'firstError',
    defaultValues: {
      firstName: '',
      lastName: '',
      email: email ?? '',
      password: '',
    },
  });

  const { errors, isSubmitting } = useFormState({ control });

  useEffect(() => {
    if (email) resetField('email', { defaultValue: email });
  }, [email]);

  const [passwordStrength, setPasswordStrength] = useState<number>(0);

  const onSubmit = (data: UserFields) => {
    nhost.auth
      .signUp({
        email: data.email,
        password: data.password,
        options: {
          displayName: `${data.firstName} ${data.lastName}`,
          allowedRoles: ['user', 'me', 'portfolio-owner', 'group-owner'],
          defaultRole: 'user',
          locale: 'en',
        },
      })
      .then((res) => {
        const { error } = res;
        if (error) {
          setErrorMessage(error.message);
          toast({
            text: error.message,
            variant: 'danger',
          });
        } else if (onSuccess) {
          onSuccess();
        } else {
          toast({
            text: t('login:toast.signup.title'),
          });
          navigate('/');
        }
      });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <VStack spacing="16px" width="320px">
        <HStack spacing="8px" alignItems="flex-start">
          <Controller
            name="firstName"
            control={control}
            rules={{ required: t('login:fields.firstName.required') }}
            render={({ field }) => (
              <FormField
                id="firstName"
                label={t('login:form.firstName')}
                isRequired
                isInvalid={!!errors.firstName}
                error={errors.firstName?.message ?? ''}
              >
                <Input
                  width="100%"
                  value={field.value}
                  onChange={(e) => field.onChange(e.target.value)}
                  placeholder={t('login:form.placeholder.firstName')}
                  autoComplete="given-name"
                  onBlur={field.onBlur}
                />
              </FormField>
            )}
          />
          <Controller
            name="lastName"
            control={control}
            rules={{ required: t('login:fields.lastName.required') }}
            render={({ field }) => (
              <FormField
                id="lastName"
                label={t('login:form.lastName')}
                isRequired
                isInvalid={!!errors.lastName}
                error={errors.lastName?.message ?? ''}
              >
                <Input
                  width="100%"
                  value={field.value}
                  onChange={(e) => field.onChange(e.target.value)}
                  placeholder={t('login:form.placeholder.lastName')}
                  autoComplete="family-name"
                  onBlur={field.onBlur}
                />
              </FormField>
            )}
          />
        </HStack>
        <Controller
          name="email"
          control={control}
          rules={{
            required: true,
            validate: (value) => value.includes('@') && isBusinessEmail.test(value),
          }}
          render={({ field }) => {
            return (
              <FormField
                id="email"
                label={t('common:fields.email.label')}
                isRequired
                isInvalid={!!errors.email}
                error={t('login:fields.email.businessEmail')}
              >
                <Input
                  value={field.value}
                  onChange={(e) => field.onChange(e.target.value)}
                  placeholder={t('common:fields.email.label')}
                  autoComplete="email"
                  width="100%"
                  onBlur={field.onBlur}
                />
              </FormField>
            );
          }}
        />
        <Controller
          name="password"
          control={control}
          rules={{
            required: true,
            validate: (_v) => passwordStrength >= 2,
          }}
          render={({ field }) => {
            return (
              <FormField
                id="password"
                label={t('login:fields.password.label')}
                isRequired
                isInvalid={!!errors.password}
                error={passwordStrength < 2 ? t('login:fields.password.strongerRequired') : ''}
              >
                <VStack spacing="2px" width="100%" alignItems="stretch">
                  <PasswordStrengthBar
                    password={field.value}
                    onChangeScore={(score) => setPasswordStrength(score)}
                    scoreWords={[
                      t('common:fields.passwordStrength.weak'),
                      t('common:fields.passwordStrength.weak'),
                      t('common:fields.passwordStrength.okay'),
                      t('common:fields.passwordStrength.good'),
                      t('common:fields.passwordStrength.strong'),
                    ]}
                    shortScoreWord={
                      !!field.value ? t('common:fields.passwordStrength.tooShort') : ''
                    }
                    style={{
                      display: 'flex',
                      flexDirection: 'column-reverse',
                      height: '2px',
                      overflow: 'visible',
                      marginBottom: '4px',
                    }}
                    userInputs={watch(['firstName', 'lastName', 'email'])}
                  />
                  <Input
                    value={field.value}
                    onChange={(e) => field.onChange(e.target.value)}
                    placeholder={t('login:fields.password.label')}
                    type="password"
                    autoComplete="new-password"
                    width="100%"
                    onBlur={field.onBlur}
                  />
                </VStack>
              </FormField>
            );
          }}
        />
        <Button type="submit" width="full" variant="primary" mt="24px" isLoading={isSubmitting}>
          {email ? t('common:invitations.acceptButton') : t('login:signUp')}
        </Button>
      </VStack>
    </form>
  );
};
export const SignUpPageWrapper = ({ children }: { children: React.ReactNode }) => {
  const { t } = useTranslation(['login', 'common']);
  return (
    <Stack minHeight="100vh" gap="0px" p="10%" background="bg.decorative">
      <VStack spacing="0px">
        <Logo size="110px" />
        <Typography variant="bodyStrong">{t('login:createAccount')}</Typography>
        <Typography variant="detail" mt="2px">
          {t('login:haveAccount')}{' '}
          <ExternalLink fontSize="detail" href="/login" style={{ color: 'text.action' }}>
            {t('login:logIn')}
          </ExternalLink>
        </Typography>
        <VStack width="100%" mt="56px">
          {children}
        </VStack>
        <HStack width="320px">
          <Typography variant="detail" textAlign="center" mt="16px">
            {t('login:confirmation.text')}{' '}
            <ExternalLink
              fontSize="detail"
              lineHeight="s"
              rel="noopener noreferrer"
              target="_blank"
              href="https://www.celsia.io/terms-of-use"
            >
              {t('login:confirmation.terms')}
            </ExternalLink>{' '}
            {t('common:words.and')}{' '}
            <ExternalLink
              fontSize="detail"
              lineHeight="s"
              rel="noopener noreferrer"
              target="_blank"
              href="https://www.celsia.io/privacy-policy"
            >
              {t('login:confirmation.policy')}
            </ExternalLink>
          </Typography>
        </HStack>
      </VStack>
    </Stack>
  );
};

export const SignUp = () => {
  return (
    <SignUpPageWrapper>
      <SignUpForm />
    </SignUpPageWrapper>
  );
};
