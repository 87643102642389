import {
  Box,
  FormControl,
  FormControlProps,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  VStack,
} from '@chakra-ui/react';

export const FormItem = ({
  isRequired,
  title,
  children,
  description,
  error,
  isInvalid,
  ...rest
}: {
  isRequired: boolean;
  title: string;
  description: string;
  children: React.ReactNode;
  error?: string;
} & FormControlProps) => {
  return (
    <FormControl isRequired={isRequired} error={error} isInvalid={isInvalid} {...rest}>
      <VStack alignItems="flex-start" justifyContent="flex-start">
        <VStack spacing="0px" alignItems="flex-start">
          <FormLabel m="0px" color="text.default">
            {title}
          </FormLabel>
          <FormHelperText mt="2px" color="text.muted">
            {description}
          </FormHelperText>
        </VStack>
        {children}
        {isInvalid && error && (
          <Box height={error ? '20px' : '0'}>
            <FormErrorMessage mt="0">{error}</FormErrorMessage>
          </Box>
        )}
      </VStack>
    </FormControl>
  );
};
