import { Infobox, InfoboxProps } from 'Atoms/Infobox';
import { useTranslation } from 'utils/translation';

export const NegativeNumbersWarningBox = ({ ...props }: Omit<InfoboxProps, 'status'>) => {
  const { t } = useTranslation('common');
  return (
    <Infobox
      width="100%"
      status="warning"
      closable={false}
      title={t('common:financials.negativeNumbersWarning.title')}
      description={t('common:financials.negativeNumbersWarning.description')}
      {...props}
    />
  );
};
