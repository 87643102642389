import {
  DatapointFieldsFragment_,
  QuestionType_Enum_,
  ShortUser,
  useGetChildrenMetricsMetadataQuery,
} from 'models';
import {
  MetricsTableData,
  isFrequencyYearly,
  useAggregatedRowValue,
} from '../../../MetricAnswers.hooks';
import { useMemo } from 'react';
import { useGetDatapointValues } from '../InputFields/QuantitativeInputs';

export const getLastEditedData = (date?: Date, user?: ShortUser | null, source?: string | null) => {
  if (!date) return { date: '', user: undefined };
  const formattedDate = new Date(date)
    .toLocaleDateString('en-GB', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    })
    .replace(/\//g, '.');
  return { date: formattedDate, user: user, source: source };
};

export const useLastEdited = (
  esrsAssessmentId: string,
  row: MetricsTableData,
  selectedQuarter: string,
  metricLevel?: boolean,
  reportingUnitId?: string
) => {
  const { data: childrenMetadata } = useGetChildrenMetricsMetadataQuery({
    variables: {
      reportingUnitId,
      assessmentId: esrsAssessmentId,
      parentMetricRef: row.metric?.reference,
    },
    skip: !reportingUnitId || !esrsAssessmentId || !row.metric?.reference,
  });
  const { dataPointsPerQuarter, dataPointPerYear, datapointText, isAggregated } =
    useGetDatapointValues(row, reportingUnitId);
  const isYearly = useMemo(() => isFrequencyYearly(row.metric), [row]);
  const { result: aggregatedValues } = useAggregatedRowValue(row, isYearly, reportingUnitId);

  if (row.metric?.metricType === QuestionType_Enum_.LongText_) {
    if (isAggregated && !!childrenMetadata?.esrs_Answer[0]?.datapoints?.length) {
      const latestChild = childrenMetadata?.esrs_Answer[0]?.datapoints[0];
      return getLastEditedData(
        new Date(latestChild.updatedAt),
        latestChild.author,
        latestChild.source
      );
    }
    return getLastEditedData(
      datapointText?.updatedAt,
      datapointText?.author,
      dataPointPerYear?.source
    );
  }

  if ((!isAggregated && aggregatedValues.latestAuthor) || isAggregated) {
    return getLastEditedData(
      aggregatedValues.latestDate,
      aggregatedValues.latestAuthor,
      aggregatedValues.latestSource
    );
  }

  if (isYearly) {
    return getLastEditedData(
      dataPointPerYear?.updatedAt,
      dataPointPerYear?.author,
      dataPointPerYear?.source
    );
  }

  const getQuarterlyData = () => {
    if (metricLevel) {
      return dataPointsPerQuarter.reduce((a, b) =>
        a?.value?.updatedAt > b?.value?.updatedAt ? a : b
      );
    }
    return dataPointsPerQuarter.find((dp) => dp.field === selectedQuarter);
  };

  const date = isAggregated
    ? aggregatedValues.latestDate
    : isYearly
      ? dataPointPerYear?.updatedAt
      : getQuarterlyData()?.value?.updatedAt;

  return getLastEditedData(date, getQuarterlyData()?.value?.author);
};

const getLastEditedDataForQuarter = (
  quarter: string,
  dataPointsPerQuarter: {
    field: 'Q1' | 'Q2' | 'Q3' | 'Q4';
    value: DatapointFieldsFragment_ | undefined;
  }[]
) => {
  const dp = dataPointsPerQuarter.find((d) => d.field === quarter)?.value;
  return getLastEditedData(dp?.updatedAt, dp?.author);
};

export const useLastEditedPerQuarter = (row: MetricsTableData, reportingUnitId?: string) => {
  const { dataPointsPerQuarter } = useGetDatapointValues(row, reportingUnitId);

  return {
    Q1: getLastEditedDataForQuarter('Q1', dataPointsPerQuarter),
    Q2: getLastEditedDataForQuarter('Q2', dataPointsPerQuarter),
    Q3: getLastEditedDataForQuarter('Q3', dataPointsPerQuarter),
    Q4: getLastEditedDataForQuarter('Q4', dataPointsPerQuarter),
  };
};
