import {
  HStack,
  Menu,
  MenuButton,
  MenuList,
  MenuItem as ChakraMenuItem,
  VStack,
} from '@chakra-ui/react';
import { Button } from 'Atoms';
import { Typography } from 'Tokens';
import { CheckIcon } from 'Tokens/Icons/Status';
import { AIGenerationOptions } from './MetricsAIModal';
import { ChevronDownIcon } from 'Tokens/Icons/Direction';

type MenuItemProps = {
  option: AIGenerationOptions;
  isSelected: boolean;
  handleSelectOption: (option: AIGenerationOptions) => void;
  isDisabled?: boolean;
  description?: string;
};

const MenuItem = ({
  option,
  isSelected,
  handleSelectOption,
  description,
  isDisabled = false,
}: MenuItemProps) => {
  return (
    <ChakraMenuItem isDisabled={isDisabled} onClick={() => handleSelectOption(option)}>
      <HStack justifyContent="space-between" w="100%">
        <VStack spacing={0} alignItems="start">
          <Typography variant="bodyStrong" color={isSelected ? 'text.selected' : 'text.muted'}>
            {option}
          </Typography>
          {description && <Typography variant="detail">{description}</Typography>}
        </VStack>

        {isSelected && <CheckIcon color="text.selected" />}
      </HStack>
    </ChakraMenuItem>
  );
};

type AISelectorMenuProps = {
  selectedOption: AIGenerationOptions;
  setSelectedOption: (option: AIGenerationOptions) => void;
  hasEmptyDatapoints: boolean;
};

export const AISelectorMenu = ({
  selectedOption,
  setSelectedOption,
  hasEmptyDatapoints,
}: AISelectorMenuProps) => {
  return (
    <Menu>
      <MenuButton
        as={Button}
        variant="ghost"
        color="text.selected"
        _hover={{ bg: 'bg.hover', color: 'text.selected' }}
        _active={{ bg: 'bg.pressed' }}
        rightIcon={<ChevronDownIcon boxSize="16px" color="inherit" />}
        size="sm"
        px={0}
      >
        {selectedOption}
      </MenuButton>
      <MenuList padding="8px" width="156px">
        <MenuItem
          option={AIGenerationOptions.empty}
          isSelected={selectedOption === AIGenerationOptions.empty}
          handleSelectOption={setSelectedOption}
          isDisabled={!hasEmptyDatapoints}
          description={!hasEmptyDatapoints ? 'No empty datapoints' : undefined}
        />
        <MenuItem
          option={AIGenerationOptions.all}
          isSelected={selectedOption === AIGenerationOptions.all}
          handleSelectOption={setSelectedOption}
        />
        <MenuItem
          option={AIGenerationOptions.selected}
          isSelected={selectedOption === AIGenerationOptions.selected}
          handleSelectOption={setSelectedOption}
        />
      </MenuList>
    </Menu>
  );
};
