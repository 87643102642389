import { Select } from 'Molecules';
import { MATERIAL_TOPIC_FILTER_OPTIONS } from '../Assessment/EsrsOverview';
import { HStack, VStack } from '@chakra-ui/react';
import { Typography } from 'Tokens';

export const ViewSelector = ({
  selectedView,
  setSelectedView,
  width,
}: {
  selectedView: string;
  setSelectedView: (val: string) => void;
  width?: string;
}) => {
  const currentValue = MATERIAL_TOPIC_FILTER_OPTIONS.find((v) => v.value === selectedView);
  return (
    <Select
      isSearchable={false}
      width={width ?? '250px'}
      value={{
        label: (
          <HStack gap="8px">
            {currentValue?.icon}
            <Typography variant="body">{currentValue?.label}</Typography>
          </HStack>
        ),
        value: selectedView,
      }}
      onChange={(val) => setSelectedView(val?.value ?? '')}
      options={MATERIAL_TOPIC_FILTER_OPTIONS.map((option) => {
        return {
          label: (
            <HStack gap="8px">
              {option.icon}
              <VStack alignItems="start" gap="0px">
                <Typography variant="bodyStrong">{option.label}</Typography>
                <Typography variant="detail">{option.description}</Typography>
              </VStack>
            </HStack>
          ),
          value: option.value,
        };
      })}
    />
  );
};
