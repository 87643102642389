import { useUserSetting } from 'containers/Navigation/Navigation.hooks';
import { formatQuarterYear, getQuarterFromDate } from 'utils/date';
import { getInvestorsByReportingPeriods, useDeleteCompanyAssessment } from '../Assessments.hooks';
import {
  CompanyAssessment,
  CompanyAssessmentDetailsFieldsFragment_,
  CompanyAssessmentsQuery_,
  InvestorsQuery_,
} from 'models';
import { useShareCompanyAssessment, useUnshareCompanyAssessment } from 'containers/Investors';
import { useCallback } from 'react';

export type BusinessUnitsSortingType = {
  sortAlphabetically: boolean;
  groupsFirst: boolean;
  setSortAlphabetically: (value: boolean) => void;
  setGroupsFirst: (value: boolean) => void;
  toggleSortingAlphabetically: () => void;
  toggleSortingGroupsFirst: () => void;
};

export const businessUnitsSorter = (a: string, b: string) => {
  return a.localeCompare(b);
};

export const useBusinessUnitsSorting = (): BusinessUnitsSortingType => {
  const [sortAlphabetically, setSortAlphabetically] = useUserSetting(
    'companyStructureSortAlphabetically',
    false
  );

  const [groupsFirst, setGroupsFirst] = useUserSetting('companyStructureGroupsFirst', false);

  const toggleSortingAlphabetically = () => {
    setSortAlphabetically(!sortAlphabetically);
  };

  const toggleSortingGroupsFirst = () => {
    setGroupsFirst(!groupsFirst);
  };

  return {
    sortAlphabetically,
    groupsFirst,
    setSortAlphabetically,
    setGroupsFirst,
    toggleSortingAlphabetically,
    toggleSortingGroupsFirst,
  };
};

export const useShareAssessmentWithInvestors = () => {
  const { shareCompanyAssessment } = useShareCompanyAssessment();

  return useCallback(
    (
      assessmentDate: Date,
      investors: InvestorsQuery_['investors'],
      assessmentsMap: Map<string, CompanyAssessmentsQuery_['assessments'] | undefined>,
      cAssessmentId: string
    ) => {
      const newYear = assessmentDate.getFullYear();
      const newQuarter = getQuarterFromDate(assessmentDate);
      const newReportingPeriod = formatQuarterYear(newQuarter, newYear);

      const investorsToShareWith = getInvestorsByReportingPeriods(investors, newReportingPeriod);

      const assessmentsForReportingPeriod = assessmentsMap.get(newReportingPeriod);
      if (!assessmentsForReportingPeriod || assessmentsForReportingPeriod.length === 0) {
        return Promise.all(
          investorsToShareWith.map((investor) => {
            return shareCompanyAssessment(cAssessmentId, investor.id ?? '', investor.portfolioId);
          })
        );
      }
    },
    []
  );
};

export const useDeleteAndUnshareAssessmentWithInvestors = () => {
  const deleteCompanyAssessment = useDeleteCompanyAssessment();
  const { unshareCompanyAssessment } = useUnshareCompanyAssessment();

  return useCallback(
    async (
      id: string | undefined,
      assessments: CompanyAssessmentDetailsFieldsFragment_[],
      investors: InvestorsQuery_['investors'] | undefined
    ) => {
      try {
        if (!!id) {
          const assessment = assessments.find((a) => a.id === id);
          const investorsSharedWith = investors?.filter(
            (investor) => investor.sharedAssessment?.id === assessment?.id
          );

          await deleteCompanyAssessment(id);

          if (investorsSharedWith?.length) {
            await Promise.all(
              investorsSharedWith?.map((investor) => {
                return unshareCompanyAssessment(investor.id, assessment?.id);
              })
            );
          }
        }
      } catch (err) {
        throw err;
      }
    },
    []
  );
};
