import React from 'react';
import { Link as ChakraLink, LinkProps as ChakraLinkProps } from '@chakra-ui/react';
import { Link as ReactRouterLink, LinkProps as ReactRouterLinkProps } from 'react-router-dom';

export type LinkProps = ChakraLinkProps & ReactRouterLinkProps;
export const Link = (combinedProps: ChakraLinkProps & ReactRouterLinkProps) => {
  return <ChakraLink {...combinedProps} as={ReactRouterLink} />;
};

export const ExternalLink = (props: ChakraLinkProps) => {
  return (
    <ChakraLink
      as="a"
      color="text.action"
      fontSize="md"
      lineHeight="md"
      fontFamily="celsiaFont"
      {...props}
    />
  );
};
