import { VStack } from '@chakra-ui/react';
import { ReportingPeriodTile } from 'Features/ReportingPeriodTile';
import {
  CompanyAssessmentDetails,
  CompanyAssessmentDetailsFieldsFragment_,
  InvestorsQuery_,
} from 'models';
import React, { useMemo } from 'react';
import { useCompanyAssessmentsMapByReportingPeriod } from '../Assessments.hooks';

export const AssessmentsViewForPortfolioCompanies = ({
  assessments,
  allAssessments,
  openModal,
  investors,
  setCompanyAssessment,
  setSelectedReportingPeriod,
}: {
  assessments: CompanyAssessmentDetailsFieldsFragment_[];
  allAssessments: CompanyAssessmentDetailsFieldsFragment_[];
  openModal?: () => void;
  investors: InvestorsQuery_['investors'];
  setCompanyAssessment?: (val: CompanyAssessmentDetails) => void;
  setSelectedReportingPeriod?: (val?: string) => void;
}) => {
  const { assessments: filteredAssessmentsMapByReportingPeriod, reportingPeriods } =
    useCompanyAssessmentsMapByReportingPeriod(assessments, investors);

  const otherAssessments = useMemo(
    () => filteredAssessmentsMapByReportingPeriod.get('other'),
    [filteredAssessmentsMapByReportingPeriod]
  );

  return (
    <VStack pb="20px">
      {reportingPeriods.map((reportingPeriod) => (
        <ReportingPeriodTile
          assessments={filteredAssessmentsMapByReportingPeriod.get(reportingPeriod.value)}
          openModal={openModal}
          investors={investors}
          setCompanyAssessment={setCompanyAssessment}
          reportingPeriod={reportingPeriod}
          allAssessments={allAssessments}
          setSelectedReportingPeriod={setSelectedReportingPeriod}
        />
      ))}
      {!!assessments.length && (
        <ReportingPeriodTile
          assessments={otherAssessments}
          openModal={openModal}
          investors={investors}
          setCompanyAssessment={setCompanyAssessment}
          isOther
          allAssessments={allAssessments}
          setSelectedReportingPeriod={setSelectedReportingPeriod}
        />
      )}
    </VStack>
  );
};
