import { Box, HStack, SimpleGrid, VStack } from '@chakra-ui/react';
import { Button, Checkbox } from 'Atoms';
import { Modal } from 'Molecules';
import { Typography } from 'Tokens';
import { useState } from 'react';
import { Link } from 'react-router-dom';

export const MetricsAIDisclaimer = ({
  isOpen,
  onClose,
  onAIModalOpen,
  setAIDisclaimerDismissed,
}: {
  isOpen: boolean;
  onClose: () => void;
  onAIModalOpen: () => void;
  setAIDisclaimerDismissed: (val: boolean) => void;
}) => {
  const [isConfirmed, setIsConfirmed] = useState(false);
  return (
    <Modal size="xxl" isOpen={isOpen} onClose={onClose} hasFooter={false}>
      <VStack height="100%">
        <VStack gap="24px" w="529px" alignItems="start" height="100%" justifyContent="center">
          <VStack alignItems="start" gap="8px">
            <Typography variant="h1">Generate answers with AI</Typography>
            <Typography variant="body">
              Before proceeding, please read the following information regarding the use of the AI
              assistant:
            </Typography>
          </VStack>
          <SimpleGrid columns={[1, 2, 3]} spacing="8px">
            <Box bg="bg.interactive" p="16px" borderRadius="8px">
              <Typography variant="detail">
                Always check with your company's policies and security team before uploading any
                confidential or sensitive information.
              </Typography>
            </Box>
            <Box bg="bg.interactive" p="16px" borderRadius="8px">
              <Typography variant="detail">
                AI-generated content can contain errors and Celsia does not guarantee that the AI
                generated content is accurate or error-free.
              </Typography>
            </Box>
            <Box bg="bg.interactive" p="16px" borderRadius="8px">
              <Typography variant="detail">
                The content generated by the AI assistant is based on the documentation uploaded by
                the user.
              </Typography>
            </Box>
            <Box bg="bg.interactive" p="16px" borderRadius="8px">
              <Typography variant="detail">
                Celsia is using the Azure OpenAI Service behind the scenes for improved privacy and
                security - please familiarize yourself with the{' '}
                <Link
                  to="https://learn.microsoft.com/en-us/legal/cognitive-services/openai/data-privacy?tabs=azure-portal"
                  target="_blank"
                >
                  <Typography variant="detail" color="text.action" as="span">
                    Azure OpenAI Data, privacy, and security documentation
                  </Typography>
                </Link>{' '}
                for more insights in how your data is processed and used.
              </Typography>
            </Box>
            <Box bg="bg.interactive" p="16px" borderRadius="8px">
              <Typography variant="detail">
                The content populated by the AI needs to be checked and approved by the user before
                it is submitted to ensure fields are correctly filled out with accurate information.
                The approving user is responsible for the submitted content.
              </Typography>
            </Box>
            <Box bg="bg.interactive" p="16px" borderRadius="8px">
              <Typography variant="detail">
                Celsia is not responsible or liable for any issues, consequences, damages, or losses
                resulting from the use of the AI tool.
              </Typography>
            </Box>
          </SimpleGrid>
          <HStack>
            <Checkbox isChecked={isConfirmed} onChange={(e) => setIsConfirmed(e.target.checked)} />
            <Typography
              variant="body"
              cursor="pointer"
              userSelect="none"
              onClick={() => setIsConfirmed(!isConfirmed)}
            >
              I confirm that I have read and understand the above information.
            </Typography>
          </HStack>
          <Button
            variant="primary"
            isDisabled={!isConfirmed}
            onClick={() => {
              onClose();
              onAIModalOpen();
              setAIDisclaimerDismissed(true);
            }}
          >
            Use AI
          </Button>
        </VStack>
      </VStack>
    </Modal>
  );
};
