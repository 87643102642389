import { Table as ChakraTable, Thead, Th, Tr, Td, Box, VStack } from '@chakra-ui/react';
import { TruncatableText } from 'Atoms';
import { ActionFieldsFragment_, GetActionsQuery_, User } from 'models';
import { Menu } from 'Molecules/Menu';
import { UserAvatar } from 'Organisms';
import { Typography } from 'Tokens';
import { EditIcon, PublicIcon, RemoveIcon } from 'Tokens/Icons/Function';
import { TargetFields } from '../Requirement';

export const ActionsList = ({
  actions,
  localActions,
  isGroup,
  onOpen,
  setSelectedAction,
  isReadOnly = false,
  selectRef,
  allRUIds,
  allSubsidiariesIds,
  unit,
}: {
  actions: GetActionsQuery_['actions'];
  localActions: TargetFields['actions'];
  isGroup: boolean;
  onOpen: () => void;
  setSelectedAction: (param: ActionFieldsFragment_ | undefined) => void;
  isReadOnly?: boolean;
  selectRef?: any;
  allRUIds: string[];
  allSubsidiariesIds: string[];
  unit: string;
}) => {
  return (
    <Box border="1px solid" borderColor="border.decorative" borderRadius="8px">
      <ChakraTable sx={{ tableLayout: 'fixed' }}>
        <Thead>
          <Th padding="16px 8px" textTransform="none" letterSpacing="normal">
            <Typography variant="bodyStrong">Title</Typography>
          </Th>
          <Th padding="16px 8px" textTransform="none" letterSpacing="normal">
            <Typography variant="bodyStrong">
              {isGroup || isReadOnly ? 'Subsidiaries' : 'Business units'}
            </Typography>
          </Th>
          <Th padding="16px 8px" textTransform="none" letterSpacing="normal">
            <Typography variant="bodyStrong">Estimate, {unit}</Typography>
          </Th>
          <Th padding="16px 8px" textTransform="none" letterSpacing="normal">
            <Typography variant="bodyStrong">Owner</Typography>
          </Th>
          <Th padding="16px 8px" textTransform="none" width="50px"></Th>
        </Thead>
        {actions.map((action, index) => {
          const isAllSubsidiaries = allSubsidiariesIds.every((id) =>
            action.actionSubsidiaries.some((s) => s.subsidiaryAssessment.id === id)
          );
          const isAllReportingUnits = allRUIds.every((id) =>
            action.actionReportingUnits.some((ru) => ru.reportingUnit.id === id)
          );
          const subList = action.actionSubsidiaries
            .map((as) => as.subsidiaryAssessment?.company?.name)
            .join(', ');
          const ruList = action.actionReportingUnits.map((ru) => ru.reportingUnit.name).join(', ');

          return (
            <Tr
              key={index}
              onClick={() => {
                setSelectedAction(action);
                onOpen();
              }}
              _hover={{
                cursor: 'pointer',
                bg: 'bg.hover',
              }}
            >
              <Td padding="8px" border={index === actions.length - 1 ? 'none' : ''}>
                <VStack spacing="0px" alignItems="start">
                  <TruncatableText text={action.title} variant="bodyStrong" />
                  <Typography variant="micro" color="color.muted">
                    {new Date(action.startDate).getFullYear()} -{' '}
                    {new Date(action.deadline).getFullYear()}
                  </Typography>
                </VStack>
              </Td>
              <Td padding="8px" border={index === actions.length - 1 ? 'none' : ''}>
                <TruncatableText
                  text={
                    isGroup || isReadOnly
                      ? isReadOnly
                        ? subList
                        : isAllSubsidiaries
                          ? subList
                          : 'All'
                      : isAllReportingUnits
                        ? 'All'
                        : ruList
                  }
                  variant="body"
                />
              </Td>
              <Td padding="8px" border={index === actions.length - 1 ? 'none' : ''}>
                <Typography variant="body">
                  {localActions.find((a) => a.actionId === action.id)?.estimate ?? 0}
                </Typography>
              </Td>
              <Td padding="8px" border={index === actions.length - 1 ? 'none' : ''}>
                <UserAvatar user={action.owner as User} />
              </Td>
              <Td
                padding="8px"
                textAlign="right"
                border={index === actions.length - 1 ? 'none' : ''}
                width="50px"
              >
                <Menu
                  sections={[
                    {
                      actions: isReadOnly
                        ? [
                            {
                              id: 'view',
                              title: 'View',
                              onClick: () => {
                                setSelectedAction(action);
                                onOpen();
                              },
                              leftElement: <PublicIcon />,
                            },
                          ]
                        : [
                            {
                              id: 'edit',
                              title: 'Edit',
                              onClick: () => {
                                setSelectedAction(action);
                                onOpen();
                              },
                              leftElement: <EditIcon color="inherit" />,
                            },
                            {
                              id: 'remove',
                              title: 'Remove',
                              onClick: () => {
                                selectRef?.current?.removeValue({
                                  value: action?.id,
                                  label: action?.title,
                                });
                              },
                              leftElement: <RemoveIcon color="inherit" />,
                            },
                          ],
                    },
                  ]}
                />
              </Td>
            </Tr>
          );
        })}
      </ChakraTable>
    </Box>
  );
};
