import { useMemo } from 'react';
import { DRQAnswersType, DRQuestionsType } from './DRQuestionTable';
import { ESRSNumberInput } from 'Atoms';

export const NumberInputAnswer = ({
  answers,
  setAnswers,
  question,
}: {
  answers: DRQAnswersType;
  setAnswers: (params: DRQAnswersType) => void;
  question: DRQuestionsType[number];
}) => {
  const answer = useMemo(() => answers?.[question.reference], [answers]);
  const handleChangeAnswer = (val: number | null) => {
    setAnswers({
      ...answers,
      [question.reference]: { value: val, factValue: undefined },
    });
  };

  return (
    <ESRSNumberInput
      value={!!answer?.value ? Number(answer.value) : undefined}
      onChange={(val) => handleChangeAnswer(val)}
      unit={question.unit ?? ''}
      variant="ghost"
    />
  );
};
