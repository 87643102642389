import { useState } from 'react';
import { DREnums, DRTypes } from '../../Requirement';
import { AggregatedMetrics, AggregatedQualitativeAnswers, MetricsTableData } from '..';
import { Actions } from '../../Actions/Actions';
import { Targets } from '../../Targets/Targets';
import { Policies } from '../../Policies/Policies';

export const DisclosureRequirement = ({
  type,
  setMetricSidebarOpen,
}: {
  type: DRTypes;
  setMetricSidebarOpen: (val: boolean) => void;
}) => {
  const [rowData, setRowData] = useState<
    MetricsTableData & { sourceData?: AggregatedQualitativeAnswers[number] }
  >();

  if (type === DREnums.policy) {
    return <Policies setMetricSidebarOpen={setMetricSidebarOpen} />;
  }
  if (type === DREnums.target) {
    return <Targets setMetricSidebarOpen={setMetricSidebarOpen} />;
  }
  if (type === DREnums.metric) {
    return (
      <AggregatedMetrics
        setMetricSidebarOpen={setMetricSidebarOpen}
        rowData={rowData}
        setRowData={setRowData}
      />
    );
  }
  if (type === DREnums.action) return <Actions setMetricSidebarOpen={setMetricSidebarOpen} />;
  return (
    <AggregatedMetrics
      setMetricSidebarOpen={setMetricSidebarOpen}
      rowData={rowData}
      setRowData={setRowData}
    />
  );
};
