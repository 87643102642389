import { Tooltip, useColorMode } from '@chakra-ui/react';
import { colors } from 'Tokens';
import { formatNum } from 'utils/numbers';

export const ProgressBar = ({
  bg,
  completed,
  noTooltip = false,
  isGroup,
}: {
  bg?: string;
  completed: number;
  noTooltip?: boolean;
  isGroup?: boolean;
}) => {
  const { colorMode } = useColorMode();
  const containerStyles = {
    height: '3px',
    width: '100%',
    backgroundColor: '#CACED6',
    borderRadius: 50,
    marginLeft: isGroup ? '32px' : '0px',
  };

  const fillerStyles = {
    height: '100%',
    width: `${completed}%`,
    backgroundColor:
      bg ?? colorMode === 'dark' ? colors['bg.progress']._dark : colors['bg.progress'].default,
    borderRadius: 'inherit',
  };

  const labelStyles = {
    padding: 5,
    color: 'text.onAccent',
    fontWeight: 'bold',
  };

  if (noTooltip) {
    return (
      <div style={containerStyles}>
        <div style={fillerStyles}>
          <span style={labelStyles} />
        </div>
      </div>
    );
  }
  return (
    <Tooltip label={`Completed: ${formatNum(completed, 0)}%`}>
      <div style={containerStyles}>
        <div style={fillerStyles}>
          <span style={labelStyles} />
        </div>
      </div>
    </Tooltip>
  );
};
