import { Box, HStack, Spinner, VStack } from '@chakra-ui/react';
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import { useGetDmaDataQuery } from 'models';
import { useParams } from 'react-router-dom';
import { Typography } from 'Tokens';
import { ExcelFileIcon } from 'Tokens/Icons/FileIcons';

export const DMAExcelDownloader = () => {
  const { esrsAssessmentId = '' } = useParams();

  const { data, error, loading } = useGetDmaDataQuery({
    variables: {
      assessmentId: esrsAssessmentId,
    },
  });

  const handleDownload = async () => {
    if (!data || !data.esrs_MaterialMetric || data.esrs_MaterialMetric.length === 0) {
      alert('No data available to download.');
      return;
    }

    // Create a new workbook and add a worksheet
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Data');

    // Add header row
    worksheet.columns = [
      {
        header: 'IsMaterial',
        key: 'isMaterial',
        width: 15,
      },
      {
        header: 'Global Order',
        key: 'globalOrder',
        width: 30,
      },

      {
        header: 'Frequency',
        key: 'frequency',
        width: 20,
      },
      {
        header: 'Metric Title',
        key: 'metricTitle',
        width: 30,
      },
      {
        header: 'Metric Order',
        key: 'order',
        width: 25,
      },
      {
        header: 'Disclosure Requirement Reference',
        key: 'drRef',
        width: 30,
      },
      {
        header: 'Disclosure Requirement Title',
        key: 'drTitle',
        width: 30,
      },
      {
        header: 'Disclosure Requirement Order',
        key: 'drOrder',
        width: 30,
      },
      {
        header: 'Standard Title',
        key: 'standardTitle',
        width: 30,
      },
      {
        header: 'Standard Reference',
        key: 'standardRef',
        width: 25,
      },
      {
        header: 'Additional Types',
        key: 'additionalTypes',
        width: 30,
      },
    ];

    const sortedArray = ([...data.esrs_MaterialMetric] || []).sort((a, b) => {
      // sort first by standard then by requirement order then finally by global order
      const standardOrder =
        a.metric.requirement.group.standard.reference.localeCompare(
          b.metric.requirement.group.standard.reference
        ) || 0;
      const requirementOrder =
        a.metric?.requirement?.order - (b.metric?.requirement?.order || 0) || 0;
      const globalOrder = a.metric?.globalOrder?.localeCompare(b.metric?.globalOrder || '') || 0;
      return standardOrder || requirementOrder || globalOrder;
    });
    sortedArray.forEach((item) => {
      const newObj = {
        drOrder: item?.metric?.requirement.order,
        globalOrder: item?.metric?.globalOrder,
        isMaterial:
          item?.materialStandard?.isDataGatheringOnly || item?.isDataGatheringOnly
            ? 'Collect data only'
            : item?.materialStandard?.isMaterial && item.isMaterial
              ? 'Yes'
              : 'No',
        frequency: item.frequency || 'N/A',
        metricTitle: item.metric?.title || 'N/A',
        order: item.metric
          ? `${item.metric.order || 'N/A'} ${item.metric.firstLevel || ''} ${item.metric.secondLevel || ''}`
          : 'N/A',
        drRef: item.metric?.requirement?.reference || 'N/A',
        drTitle: item.metric?.requirement?.title || 'N/A',
        standardTitle: item.metric?.requirement?.group?.standard?.title || 'N/A',
        standardRef: item.metric?.requirement?.group?.standard?.reference || 'N/A',
        additionalTypes: item.metric?.additionalTypes
          ? item.metric.additionalTypes.map((type) => type.additionalType.title).join(', ')
          : 'N/A',
      };
      worksheet.addRow(newObj);
    });

    try {
      const buffer = await workbook.xlsx.writeBuffer();

      // Create a Blob from the buffer with the correct MIME type for Excel files
      const blob = new Blob([buffer], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });

      // Save the file using file-saver with a more descriptive filename
      saveAs(blob, `DMA_Data_${esrsAssessmentId}-${new Date().toISOString()}.xlsx`);
    } catch (error) {
      console.error('Error generating Excel file:', error);
      alert('Failed to generate Excel file. Please try again later.');
    }
  };

  if (error) {
    return <Typography variant="detail">Error loading data.</Typography>;
  }
  if (loading) {
    return (
      <HStack w="100%" spacing="10.33px" cursor="default">
        <Box position="relative">
          <Spinner boxSize="16px" position="absolute" opacity={0.46} top="22%" left="15%" />
          <Box opacity={0.1}>
            <ExcelFileIcon />
          </Box>
        </Box>

        <VStack alignItems="start" spacing="0px" opacity={0.46}>
          Generating:
          <Typography variant="bodyStrong">DMA export</Typography>
          <Typography variant="detail">Excel document</Typography>
        </VStack>
      </HStack>
    );
  }

  return (
    <HStack
      w="100%"
      spacing="10.33px"
      onClick={handleDownload}
      cursor="pointer"
      _hover={{ opacity: 0.8 }}
    >
      <ExcelFileIcon />
      <VStack alignItems="start" spacing="0px">
        <Typography variant="bodyStrong">DMA export</Typography>
        <Typography variant="detail">Excel document</Typography>
      </VStack>
    </HStack>
  );
};
