import React from 'react';
import { AssessmentsTable } from './AssessmentsTable';
import {
  CompanyAssessmentDetails,
  CompanyAssessmentDetailsFieldsFragment_,
  InvestorsQuery_,
} from 'models';

export const AssessmentsViewForRegularCompanies = ({
  assessments,
  investors,
  setCompanyAssessment,
}: {
  assessments: CompanyAssessmentDetailsFieldsFragment_[];
  investors: InvestorsQuery_['investors'];
  setCompanyAssessment?: (val: CompanyAssessmentDetails) => void;
}) => {
  return (
    <AssessmentsTable
      assessments={assessments}
      investors={investors}
      setCompanyAssessment={setCompanyAssessment}
    />
  );
};
