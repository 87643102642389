import { VStack, Center } from '@chakra-ui/react';
import React from 'react';
import clsx from 'clsx';
import { ResolvedQuestion } from 'models';
import { getQuestionId } from 'utils/scores/questions';

export const QuestionCard = ({
  children,
  question,
}: {
  children: React.ReactNode;
  question?: ResolvedQuestion;
}) => {
  return (
    <VStack
      className={clsx('question', `question-${question?.uniqueId}`, {
        disabled: !question?.isEditable,
      })}
      id={question ? getQuestionId(question) : undefined}
      width="calc(100vw - 794px)"
      alignItems="stretch"
      spacing="16px"
      padding="24px 16px"
      shadow="lg"
      borderRadius="8px"
      bg="bg.default"
      sx={{
        '&.disabled': {
          backgroundColor: '#F5F4F4',
          boxShadow: 'sm',
        },
      }}
    >
      {children}
    </VStack>
  );
};

export const QuestionMessage = ({ children }: React.PropsWithChildren<unknown>) => (
  <QuestionCard>
    <Center alignItems="center" width="100%" fontSize="lg">
      {children}
    </Center>
  </QuestionCard>
);
