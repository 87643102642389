import { HStack } from '@chakra-ui/react';
import { Typography } from 'Tokens';

// the noInTables array is used to control the inclusion of the word "in" when displaying contextual information, based on the table names listed.
const noInTables = [
  'BusinessUnitAssessment',
  'ActivityReport',
  'ReportDownloads',
  'PortfolioCompany',
];

export const UserInformation = ({
  displayName,
  operationText,
  tableDisplayName,
  contextualInfo,
  table,
}: {
  displayName: string;
  operationText: string;
  tableDisplayName: string;
  contextualInfo: string;
  table: string;
}) => (
  <HStack spacing="4px" paddingTop="5px">
    <Typography variant="bodyStrong">{displayName}</Typography>
    <Typography color="text.muted" variant="body">
      {operationText} {tableDisplayName}{' '}
      {!!contextualInfo && !noInTables.includes(table) ? 'in' : ''}
    </Typography>
  </HStack>
);
