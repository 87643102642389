import { Box, VStack } from '@chakra-ui/react';
import { ActiveStepLabels } from 'containers/Esrs/EsrsUtilComponents';
import { Button } from 'Atoms';
import { ArrowNarrowRightIcon } from 'Tokens/Icons/Direction';
import { Controller, useForm } from 'react-hook-form';
import {
  StandardMaterialityFields,
  StandardMaterialityState,
} from '../DoubleMaterialityAssessment.d';
import { useEffect } from 'react';
import { useGetStandardMandatoryMetricsQuery } from 'models';
import { useParams } from 'react-router-dom';
import { useAddMateriality } from '../DoubleMaterialityAssessment.hooks';
import { StandardMaterialityRadioGroup } from '../MaterialityRadioGroup';
import { Typography } from 'Tokens';

export const AssessStandardMateriality = ({
  step,
  standardMateriality,
  isCollectOnly,
  isGroup,
  setStep,
  isParentMaterial,
  setIsInitialLoad,
}: {
  step: number;
  standardMateriality: StandardMaterialityState;
  isCollectOnly?: boolean;
  isGroup: boolean;
  setStep: (val: number) => void;
  isParentMaterial: boolean;
  setIsInitialLoad: (val: boolean) => void;
}) => {
  const { standardRef = '' } = useParams();
  const addMateriality = useAddMateriality();
  const { data: mandatoryMetrics } = useGetStandardMandatoryMetricsQuery({
    variables: {
      standardRef,
    },
  });
  const { control, watch, setValue } = useForm<StandardMaterialityFields>();

  useEffect(() => {
    setValue('material', standardMateriality);
  }, [standardMateriality]);

  const saveMateriality = (isMaterial: StandardMaterialityState | null) => {
    setIsInitialLoad(false);
    addMateriality(
      isMaterial ?? StandardMaterialityState.toAssess,
      mandatoryMetrics?.EsrsMetric ?? [],
      isGroup
    );
  };

  return (
    <Box
      borderLeft="1px dashed"
      mt="28px"
      ml="16px"
      pl="32px"
      borderColor="border.hover"
      pb="24px"
      position="relative"
    >
      <ActiveStepLabels
        title="Assess materiality for this topic"
        description="Based on your materiality assessment, is this topic material for your company?"
        mb={step === 0 ? '24px' : '0px'}
        active={step === 0}
        done={step > 0}
        ml="33px"
        goBack={() => setStep(0)}
      />
      {step === 0 && (
        <>
          <Controller
            name="material"
            control={control}
            render={({ field: { onChange, value } }) => {
              return (
                <VStack gap="8px" alignItems="start">
                  <StandardMaterialityRadioGroup
                    value={value ?? StandardMaterialityState.toAssess}
                    onChange={(val) => {
                      onChange(val);
                      saveMateriality(val);
                    }}
                    defaultValue={standardMateriality}
                    isCollectOnly={isCollectOnly}
                    isParentMaterial={isParentMaterial}
                    width="490px"
                  />
                  {isParentMaterial && (
                    <Typography variant="detail" width="490px">
                      This topic can not be set to "Not material" as your parent company requested
                      to collect data for it.
                    </Typography>
                  )}
                </VStack>
              );
            }}
          />

          <Button
            variant="primary"
            alignSelf="start"
            rightIcon={<ArrowNarrowRightIcon color="text.onAccent" />}
            onClick={() => setStep(step + 1)}
            isDisabled={watch('material') === StandardMaterialityState.toAssess}
            mb="24px"
            mt="24px"
          >
            Next step
          </Button>
        </>
      )}
    </Box>
  );
};
