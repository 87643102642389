import { HStack, VStack } from '@chakra-ui/react';
import { FormField, Input } from 'Atoms';
import { useUpsertReportingGroupsMutation, ReportingGroup, ReportingGroupsDocument_ } from 'models';
import { Modal } from 'Molecules';
import { UserAssigner } from 'Organisms';
import { useForm, Controller, useFormState } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'utils/translation';
import { useEffect, useState } from 'react';
import { CompanyLevelSelector } from 'Organisms/CompanyLevelSelector';
import { captureException } from '@sentry/browser';

type ReportingGroupFields = {
  name: string;
  ownerId: string;
  parentId?: string;
};

export const ReportingGroupFormModal = ({
  reportingGroup,
  isOpen,
  onClose,
  selectedParentGroup,
}: {
  reportingGroup?: ReportingGroup;
  isOpen: boolean;
  onClose: () => void;
  selectedParentGroup: string | undefined;
}) => {
  const { cAssessmentId } = useParams();
  const { t } = useTranslation('assessment');
  const { control, handleSubmit, reset } = useForm<ReportingGroupFields>({
    mode: 'all',
    reValidateMode: 'onBlur',
    criteriaMode: 'all',
    shouldFocusError: true,
    defaultValues: {
      name: reportingGroup?.name,
      ownerId: reportingGroup?.owner?.id,
      parentId: reportingGroup?.parentId,
    },
  });
  const { isValid, errors, isDirty } = useFormState({ control });
  const [isLoading, setIsLoading] = useState(false);

  const [upsertReportingGroup] = useUpsertReportingGroupsMutation({
    refetchQueries: [ReportingGroupsDocument_],
  });

  const onSubmit = (values: ReportingGroupFields) => {
    setIsLoading(true);
    upsertReportingGroup({
      variables: {
        input: [
          {
            id: reportingGroup?.id ?? undefined,
            companyAssessmentId: cAssessmentId,
            orderIndex: reportingGroup?.orderIndex ?? 0,
            ...values,
          },
        ],
      },
    })
      .then(() => {
        setIsLoading(false);
        onClose();
      })
      .catch((err) => {
        captureException(err, {
          extra: { errorMessage: 'Error creating reporting group' },
        });
        setIsLoading(false);
      });
  };

  useEffect(() => {
    reset({
      name: reportingGroup?.name,
      ownerId: reportingGroup?.owner?.id,
      parentId: selectedParentGroup || reportingGroup?.parentId,
    });
  }, [reportingGroup, isOpen, selectedParentGroup]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={
        reportingGroup
          ? `${t('common:actions.edit')} ${reportingGroup.name}`
          : t('assessment:structure.department.create')
      }
      confirmText={
        reportingGroup
          ? t('assessment:structure.department.form.save')
          : t('assessment:structure.department.form.create')
      }
      confirmButtonProps={{
        type: 'submit',
        form: 'company-structure-form',
        isDisabled: !isValid || !isDirty || isLoading,
        isLoading,
      }}
    >
      <form onSubmit={handleSubmit(onSubmit)} id="company-structure-form">
        <VStack spacing="md">
          <Controller
            name="name"
            rules={{
              required: true,
              validate: (value) => value?.trim().length !== 0,
            }}
            control={control}
            render={({ field }) => (
              <FormField
                id="name"
                label={t('assessment:structure.department.form.name')}
                isRequired
                isInvalid={!!errors.name}
                error={t('assessment:structure.businessUnit.name')}
              >
                <Input
                  width="100%"
                  value={field.value}
                  onChange={field.onChange}
                  ref={field.ref}
                  onBlur={field.onBlur}
                />
              </FormField>
            )}
          />
          <Controller
            name="ownerId"
            control={control}
            rules={{ required: t('assessment:structure.businessUnit.owner') }}
            render={({ field: { onChange, value } }) => (
              <FormField
                id="owner"
                label={t('assessment:structure.department.form.contactPerson')}
                error={errors.ownerId?.message}
                isInvalid={!!errors.ownerId}
                isRequired
              >
                <UserAssigner
                  assignedTo={value}
                  setAssignedTo={onChange}
                  placeholder={t('assessment:form.selectOwner')}
                />
              </FormField>
            )}
          />

          <Controller
            name="parentId"
            control={control}
            render={({ field: { onChange, value } }) => (
              <FormField
                id="reporting-unit-location"
                label={t('assessment:structure.department.form.location')}
                error={errors.parentId?.message}
                isInvalid={!!errors.parentId}
                isRequired
              >
                <CompanyLevelSelector
                  parentReportingGroup={value}
                  setParentReportingGroup={onChange}
                  currentReportingGroupId={selectedParentGroup || reportingGroup?.parentId}
                  groupId={reportingGroup?.id}
                />
              </FormField>
            )}
          />
          <HStack width="100%" justifyContent="flex-end"></HStack>
        </VStack>
      </form>
    </Modal>
  );
};
