import { FinancesIcon } from 'Tokens/Icons/Data';
import { EditIcon } from 'Tokens/Icons/Function';
import { StructureNode } from 'containers/Esrs/pieces/Assessment/Structure';
import { DatapointFieldsFragment_ } from 'models';

export enum DRViews {
  inputData = 'Input data',
  previewReport = 'Preview report',
}

export type EsrsStructure = {
  structure: (StructureNode & {
    type?: string | undefined;
  })[][][];
  standards: {
    title: string;
    reference: string | undefined;
    disclosureRequirementRefs: (StructureNode & {
      type?: string | undefined;
    })[];
    category: string;
  }[];
  loading: boolean;
};

export type DatapointInput = {
  value: string | null;
  factValue?: string | null;
  hasOptedOut: boolean;
  optOutReason: string;
  dp: Partial<DatapointFieldsFragment_>;
  tags?: {
    tagType: string;
    tagValue: string;
  }[];
  assessmentProjectLeaderId?: string;
  choices?: string[];
  metadata?: {
    [key: string]: any;
  };
};

export const getAvailableTabs = (
  showReportPreview: boolean,
  selectedView: DRViews,
  setSelectedView: (val: DRViews) => void
) =>
  [
    {
      icon: <EditIcon />,
      title: 'Input data',
      selected: selectedView === DRViews.inputData,
      onClick: () => setSelectedView(DRViews.inputData),
    },
    {
      icon: <FinancesIcon />,
      title: 'Preview report',
      selected: selectedView === DRViews.previewReport,
      onClick: () => setSelectedView(DRViews.previewReport),
    },
  ].filter((opt) => (showReportPreview ? true : opt.title !== 'Preview report'));
