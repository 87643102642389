import { MetricsTableData, areArraysOfObjectsEqual } from '../../../MetricAnswers.hooks';

export const recursivelyCheckSubRows = (
  subRows: MetricsTableData[],
  selectedRows: MetricsTableData[]
): boolean => {
  return subRows.every((subRow) => {
    const isSubRowSelected = selectedRows.some(
      (r) =>
        r.metric.reference === subRow.metric.reference &&
        areArraysOfObjectsEqual(r.tags, subRow.tags)
    );

    if (!isSubRowSelected) {
      return false;
    }

    if (subRow.subRows && subRow.subRows.length > 0) {
      return recursivelyCheckSubRows(subRow.subRows, selectedRows);
    }

    return true;
  });
};

// Recursively get the indeterminate state for subRows
export const recursivelyGetIndeterminateState = (
  subRows: MetricsTableData[],
  selectedRows: MetricsTableData[]
): boolean => {
  return subRows.some((subRow) => {
    const isSubRowSelected = selectedRows.some(
      (r) =>
        r.metric.reference === subRow.metric.reference &&
        areArraysOfObjectsEqual(r.tags, subRow.tags)
    );

    if (subRow.subRows && subRow.subRows.length > 0) {
      return recursivelyGetIndeterminateState(subRow.subRows, selectedRows) || isSubRowSelected;
    }

    return isSubRowSelected;
  });
};

// Updates the selected rows recursively based on the checked state
export const updateSelectedRows = (
  subRows: MetricsTableData[],
  checked: boolean,
  selectedRows: MetricsTableData[]
): MetricsTableData[] => {
  return subRows.flatMap((subRow) => {
    if (subRow.subRows && subRow.subRows?.length > 0) {
      const updatedChildren = updateSelectedRows(subRow.subRows, checked, selectedRows);
      if (checked) {
        return [subRow, ...updatedChildren];
      } else {
        return updatedChildren;
      }
    }

    if (checked) {
      if (
        !selectedRows.some(
          (r) =>
            r.metric.reference === subRow.metric.reference &&
            areArraysOfObjectsEqual(r.tags, subRow.tags)
        )
      ) {
        return [subRow];
      }
    } else {
      return selectedRows.filter(
        (item) =>
          item.metric.reference === subRow.metric.reference &&
          areArraysOfObjectsEqual(item.tags, subRow.tags)
      );
    }
    return [];
  });
};
