import {
  HStack,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  Box,
} from '@chakra-ui/react';
import { Tag } from 'Atoms';
import { LabelText, Typography } from 'Tokens';
import { AvatarGroup, Table } from 'Molecules';
import { AvatarWithName } from 'Organisms';
import { useTranslation } from 'utils/translation';
import { InvestorOwnedCompany, useUserCompaniesQuery } from 'models';
import { stringToYear } from 'utils/date';
import { useNavigate } from 'react-router-dom';
import { useUserData } from '@nhost/react';
import { Tooltip } from 'Atoms/Tooltip';
import { uniqBy, map as lodashMap } from 'lodash';
import { ArrowUpRightIcon, ChevronDownIcon } from 'Tokens/Icons/Direction';
import { LockedIcon } from 'Tokens/Icons/Function';

type AllCompaniesTableProps = {
  allCompaniesData: InvestorOwnedCompany[];
};

export const AllCompaniesTable = ({ allCompaniesData }: AllCompaniesTableProps) => {
  const { t } = useTranslation('common');
  const navigate = useNavigate();
  const user = useUserData();
  const { data: userCompaniesData } = useUserCompaniesQuery({
    variables: {
      id: user?.id,
    },
    skip: !user,
  });

  return (
    <Table<InvestorOwnedCompany>
      columns={[
        {
          header: () => <Typography variant="bodyStrong">{t('common:company')}</Typography>,
          accessorKey: 'name',
          meta: {
            colSpan: 1,
          },
          cell: ({ row }) => (
            <HStack>
              <LabelText>{row.original.name}</LabelText>
            </HStack>
          ),
        },
        {
          header: () => (
            <Typography variant="bodyStrong">{t('common:module.portfolio')}</Typography>
          ),
          accessorKey: 'portfolio',
          meta: {
            colSpan: 1,
          },
          cell: ({ row }) => (
            <HStack spacing="4px">
              <Tag title={row.original.portfolioMemberships.at(0)?.portfolio.name} />
              {row.original.portfolioMemberships.length > 1 && (
                <Popover>
                  <PopoverTrigger>
                    <Tag
                      title={'+ ' + (row.original.portfolioMemberships.length - 1)}
                      rightIcon={<ChevronDownIcon color="inherit" />}
                      clickable
                    />
                  </PopoverTrigger>
                  <PopoverContent width="200px" _focusVisible={{ outline: 0, boxShadow: 'sm' }}>
                    <PopoverBody padding="6px 16px">
                      {row.original.portfolioMemberships.slice(1).map((item) => (
                        <Box padding="8px 0" key={item.portfolio.id}>
                          <Typography variant="bodyStrong" color="text.muted">
                            {item.portfolio.name}
                          </Typography>
                        </Box>
                      ))}
                    </PopoverBody>
                  </PopoverContent>
                </Popover>
              )}
            </HStack>
          ),
        },
        {
          header: () => (
            <Typography variant="bodyStrong">
              {t('common:fields.allCompaniesReport.label')}
            </Typography>
          ),
          accessorKey: 'sharedAssessment',
          meta: {
            colSpan: 1,
          },
          cell: ({ row }) => {
            const hasAccessToCompany = userCompaniesData?.data?.companies?.find(
              (c) => c.company?.id === row.original.id
            );
            const taxonomyReport = row.original.portfolioMemberships.find(
              (item) => item.sharedAssessment != null
            );
            return (
              <>
                {taxonomyReport ? (
                  <Tooltip
                    label={taxonomyReport?.sharedAssessment?.aggregate.title}
                    placement="bottom-start"
                  >
                    <HStack
                      backgroundColor="bg.muted"
                      borderRadius="6px"
                      w="177px"
                      height="36px"
                      padding="4px 8px"
                      cursor={hasAccessToCompany ? 'pointer' : ''}
                      onClick={() => {
                        if (hasAccessToCompany) navigate(`/${row.original.id}`);
                      }}
                    >
                      <Box w="50%" overflow="hidden">
                        <Typography
                          variant="bodyStrong"
                          whiteSpace="nowrap"
                          overflow="hidden"
                          textOverflow="ellipsis"
                        >
                          {taxonomyReport?.sharedAssessment?.aggregate.title}
                        </Typography>
                      </Box>
                      <Box>
                        <Tag
                          title={
                            '' + stringToYear(taxonomyReport?.sharedAssessment?.aggregate.startDate)
                          }
                        />
                      </Box>
                      <Box>
                        {hasAccessToCompany ? (
                          <ArrowUpRightIcon marginTop="5px" color="text.hint" cursor="pointer" />
                        ) : (
                          <LockedIcon marginTop="5px" color="text.muted" />
                        )}
                      </Box>
                    </HStack>
                  </Tooltip>
                ) : (
                  <Typography color="text.hint">
                    {t('common:allCompanies.noTaxonomyReport')}
                  </Typography>
                )}
              </>
            );
          },
        },
        {
          header: () => <Typography variant="bodyStrong">{t('common:contactPerson')}</Typography>,
          accessorKey: 'contactPersonName',
          meta: {
            colSpan: 1,
          },
          cell: ({ row }) => {
            const avatars = uniqBy(row.original.portfolioMemberships, 'contactPersonEmail');
            const avatarNames = lodashMap(avatars, 'contactPersonName').filter(
              (name) => name !== null
            );
            return (
              <>
                {row.original.portfolioMemberships.length <= 1 ? (
                  row.original.portfolioMemberships.map((item, index) => (
                    <div key={item?.contactPersonEmail ?? index}>
                      {item.contactPersonName ? (
                        <AvatarWithName
                          user={{ displayName: item.contactPersonName, avatarUrl: '' }}
                          size="md"
                        />
                      ) : (
                        <Typography color="text.hint">
                          {t('common:allCompanies.noContactPerson')}
                        </Typography>
                      )}
                    </div>
                  ))
                ) : (
                  <>
                    {avatars.length === 1 ? (
                      <AvatarWithName
                        user={{
                          displayName:
                            row.original.portfolioMemberships.at(0)?.contactPersonName || '',
                          avatarUrl: '',
                        }}
                      />
                    ) : (
                      <AvatarGroup names={avatarNames as string[]} />
                    )}
                  </>
                )}
              </>
            );
          },
        },
      ]}
      data={allCompaniesData}
    ></Table>
  );
};
