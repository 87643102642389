import { Box, HStack, VStack } from '@chakra-ui/react';
import { Infobox, Alert } from 'Atoms';
import { Select } from 'Molecules';
import { useEffect, useState } from 'react';
import { Typography } from 'Tokens';
import { ArrowNarrowRightIcon } from 'Tokens/Icons/Direction';
import { useTranslation } from 'utils/translation';
import { CSVFile } from './CSVUploader';

export const ColumnMatcher = ({
  columns,
  csvFile,
  columnMappings,
  setColumnMappings,
  resetSelect,
  setResetSelect,
}: {
  columns: { title: string; key: string; required: boolean; type: string }[];
  csvFile: CSVFile | null;
  columnMappings: { [key: string]: string };
  setColumnMappings: (param: any) => void;
  resetSelect: boolean;
  setResetSelect: (param: boolean) => void;
}) => {
  const { t } = useTranslation('csv');
  const [isUsed, setIsUsed] = useState<boolean>(false);
  const [hideInfobox, setHideInfobox] = useState<boolean>(false);
  const [invalid, setInvalid] = useState(Array(columns.length));
  useEffect(() => {
    if (resetSelect) {
      setColumnMappings([]);
      setResetSelect(false);
    }
  }, [resetSelect]);
  useEffect(() => {
    const duplicates = Object.values(columnMappings).some((element) => {
      if (
        Object.values(columnMappings).indexOf(element) !==
        Object.values(columnMappings).lastIndexOf(element)
      ) {
        return true;
      }

      return false;
    });
    if (!duplicates) {
      setInvalid(Array(columns.length).fill(false));
      setIsUsed(false);
    }
  }, [columnMappings]);
  return (
    <VStack justifyContent="flex-start" alignItems="flex-start" width="100%" spacing="lg">
      <VStack justifyContent="flex-start" alignItems="flex-start" width="100%" spacing="md">
        {!hideInfobox && (
          <Infobox
            status="neutral"
            title={t('csv:text.mapQuestion')}
            description={t('csv:text.mapExplanation')}
            onClose={() => setHideInfobox(true)}
          />
        )}
        {columns.map((column, index) => (
          <HStack key={column.key} justifyContent="space-between" width="100%">
            <Box bg="bg.muted" borderRadius="8px" width="270px" p="8px">
              <Typography variant="body" color="text.default">
                {column.title}
              </Typography>
            </Box>
            <ArrowNarrowRightIcon />
            <Box width="270px">
              <Select<{ value: string; label: string; type: string }>
                isInvalid={invalid[index]}
                options={
                  csvFile?.header.map((c: string, i: number) => ({
                    value: c,
                    label: c,
                    type: csvFile.data.every(
                      (data) => typeof data[i] === 'number' || data[i] === null
                    )
                      ? 'number'
                      : csvFile.data.every(
                            (data) => typeof data[i] === 'string' || data[i] === null
                          )
                        ? 'string'
                        : 'object',
                  })) ?? []
                }
                onChange={(v) => {
                  if (v?.value) {
                    setColumnMappings((curValue: { [key: string]: string }) => ({
                      ...curValue,
                      [column.key]: v.value,
                    }));
                    if (Object.values(columnMappings).includes(v.value)) {
                      invalid[index] = true;
                      setIsUsed(true);
                    }
                  }
                }}
                isOptionDisabled={(opt) => {
                  return opt.type != column.type;
                }}
                placeholder="Select column"
                value={
                  resetSelect
                    ? undefined
                    : columnMappings[column.key]
                      ? {
                          label: columnMappings[column.key],
                          value: columnMappings[column.key],
                          type: columnMappings[column.key],
                        }
                      : undefined
                }
              />
            </Box>
          </HStack>
        ))}
        {isUsed && (
          <Alert
            status="warning"
            withIcon={true}
            closable={false}
            title={t('csv:error.uniqueColumns')}
          />
        )}
      </VStack>
    </VStack>
  );
};
