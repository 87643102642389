import { AssessmentsList, Assessment } from './pieces';
import { Route, Routes } from 'react-router-dom';
import { AssessmentForm } from './pieces/AssessmentForm';
import { ErrorElement } from 'App';

export const Assessments = () => {
  return (
    <Routes>
      <Route index element={<AssessmentsList />} errorElement={<ErrorElement />} />
      <Route path="new/*" element={<AssessmentForm />} errorElement={<ErrorElement />}>
        <Route
          path=":reportingPeriod"
          element={<AssessmentForm />}
          errorElement={<ErrorElement />}
        />
      </Route>
      <Route
        path=":cAssessmentId/edit/*"
        element={<AssessmentForm />}
        errorElement={<ErrorElement />}
      >
        <Route
          path=":reportingPeriod"
          element={<AssessmentForm />}
          errorElement={<ErrorElement />}
        />
      </Route>
      <Route path=":cAssessmentId/*" element={<Assessment />} errorElement={<ErrorElement />}>
        <Route path=":currentTab" element={<Assessment />} errorElement={<ErrorElement />}>
          <Route path=":businessUnitId" element={<Assessment />} errorElement={<ErrorElement />}>
            <Route
              path=":activityRef/:questionId?"
              element={<Assessment />}
              errorElement={<ErrorElement />}
            />
          </Route>
        </Route>
      </Route>
    </Routes>
  );
};
