import { Box, HStack, VStack } from '@chakra-ui/react';
import { Button, Infobox } from 'Atoms';
import {
  GetOnboardingEsrsAssessmentDocument_,
  useGetAssessmentEsrs2MaterialMetricsQuery,
  useGetOnboardingEsrsAssessmentQuery,
  useGetParentAssessmentToCopyQuery,
  useUpdateAssessmentOnboardingMutation,
} from 'models';
import { ContentLayout, Loader } from 'Molecules';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Typography } from 'Tokens';
import { EsrsAssessmentConfig } from '../Assessment';
import { MaterialityAssessment } from '../MaterialityAssessment';
import { AboutEsrs } from './AboutEsrs';
import { ActiveStepLabels } from 'containers/Esrs/EsrsUtilComponents';
import { DataCollectionSetup } from '../DataCollection/DataCollectionSetup';
import { useCurrentCompanyId, useToast } from 'utils/hooks';
import { useCopyParentAssessment } from './Onboadring.hooks';
import { DoubleMaterialityAssessment } from '../MaterialityAssessment/DoubleMaterialityAssessment/DoubleMaterialityAssessment';

export const GUIDE_DOCUMENT_ID = '1NwuUz3RxG26BmcN3CPF1-26F7VH01bbFbgDWxPa5a6E';
export const SHEET_NUMBER = '606737876';

const AssessmentPath = ({ step }: { step: number }) => {
  const [updateOnboardingStep] = useUpdateAssessmentOnboardingMutation();
  const { esrsAssessmentId } = useParams();

  const goToStep = (curStep: number) => {
    if (curStep <= 1) return;
    updateOnboardingStep({
      variables: {
        id: esrsAssessmentId,
        onboardingStep: curStep,
      },
    });
  };

  return (
    <VStack width="20%" p="20px 0px" h="100%" alignItems="flex-start">
      <Box position="relative">
        <Box borderLeft="1px dashed" pl="32px" borderColor="border.hover" pb="24px" ml="14px">
          <ActiveStepLabels
            left="0"
            bgColor="bg.muted"
            title="Learn about ESRS"
            active={step === 1}
            done={step > 1}
            goBack={() => goToStep(1)}
          />
        </Box>
        <Box borderLeft="1px dashed" pl="32px" borderColor="border.hover" pb="24px" ml="14px">
          <ActiveStepLabels
            left="0"
            title="Set company structure"
            active={step === 2}
            bgColor="bg.muted"
            done={step > 2}
            goBack={() => goToStep(2)}
          />
        </Box>
        <Box borderLeft="1px dashed" pl="32px" borderColor="border.hover" pb="24px" ml="14px">
          <ActiveStepLabels
            left="0"
            title="Materiality assessment"
            active={step === 3}
            bgColor="bg.muted"
            done={step > 3}
            goBack={() => goToStep(3)}
          />
        </Box>
        <Box pl="32px" pb="24px" ml="14px">
          <ActiveStepLabels
            left="0"
            bgColor="bg.muted"
            title="Data collection setup"
            active={step === 4}
            done={step > 4}
            goBack={() => goToStep(4)}
          />
        </Box>
      </Box>
    </VStack>
  );
};

const MaterialityAssessmentStep = ({
  setStep,
  isOnboarding = false,
  isCollectOnly = false,
}: {
  setStep: (step: number) => void;
  isOnboarding?: boolean;
  isCollectOnly?: boolean;
}) => (
  <VStack w="100%" alignItems="start" spacing={isCollectOnly ? '16px' : '32px'}>
    <Typography variant="h1">Materiality assessment</Typography>
    {!isCollectOnly && (
      <VStack alignItems="start">
        <VStack gap="2px" alignItems="start">
          <Typography variant="h2">Step 1: Perform assessment</Typography>
          <Typography
            variant="body"
            color="text.muted"
            fontSize="14px"
            fontWeight="400"
            lineHeight="20px"
          >
            Understand and perform materiality assessment
          </Typography>
        </VStack>

        <Infobox
          title="Use a materiality assessment template"
          description="You should have received the DMA template from your Customer Success Manager. If not, please contact your manager to gain access to the template."
          status="info"
          w="100%"
          titleProps={{ variant: 'h3' }}
          closable={false}
          withIcon={false}
          backgroundColor="inherit"
          border="1px solid"
          borderRadius="8px"
          borderColor="#8787FF"
        />
      </VStack>
    )}
    <MaterialityAssessment setStep={setStep} isOnboarding={isOnboarding} />
  </VStack>
);

export const Onboarding = () => {
  const navigate = useNavigate();
  const { esrsAssessmentId = '' } = useParams();
  const [step, setStep] = useState(1);
  const [nextDisabled, setNextDisabled] = useState(false);
  const [nextButtonDisabled, setNextButtonDisabled] = useState(false);
  const [showAlertMessage, setShowAlertMessage] = useState(false);
  const [lastSavedStep, setLastSavedStep] = useState(0);
  const { companyId } = useCurrentCompanyId();
  const toast = useToast();

  const [updateOnboardingStep] = useUpdateAssessmentOnboardingMutation();
  const copyAssessment = useCopyParentAssessment();

  const { data, loading } = useGetOnboardingEsrsAssessmentQuery({
    variables: { esrsAssessmentId },
    skip: !esrsAssessmentId,
  });
  const { data: parentAssessmentData, loading: loadingParent } = useGetParentAssessmentToCopyQuery({
    variables: { childAssessmentId: esrsAssessmentId },
    skip: !esrsAssessmentId,
  });
  const { data: esrs2MaterialMetrics, loading: loadingEsrs2Metrics } =
    useGetAssessmentEsrs2MaterialMetricsQuery({
      variables: { esrsAssessmentId: esrsAssessmentId },
      skip: !esrsAssessmentId,
    });

  const esrsAssessment = useMemo(() => data?.esrsAssessment, [data]);
  const parentStandards = useMemo(
    () => parentAssessmentData?.EsrsAssessment_by_pk?.parentAssessment?.materialStandards,
    [parentAssessmentData]
  );

  const assessmentStep = useMemo(() => esrsAssessment?.onboardingStep, [esrsAssessment]);

  useEffect(() => {
    if (assessmentStep) {
      setStep(assessmentStep);
      setLastSavedStep(assessmentStep);
    }
  }, [assessmentStep]);

  if (loading || loadingParent || loadingEsrs2Metrics) {
    return <Loader />;
  }

  const handleNextStep = () => {
    if (nextDisabled) {
      setShowAlertMessage(true);
    } else {
      if (step === 1 && esrsAssessment?.collectOnly)
        copyAssessment(parentStandards, esrsAssessmentId).catch(() => {
          toast({ text: "Failed to copy parent's assessment", variant: 'danger' });
        });
      if (step + 1 > lastSavedStep) {
        updateOnboardingStep({
          variables: {
            id: esrsAssessmentId,
            onboardingStep: step + 1,
          },
          refetchQueries: [GetOnboardingEsrsAssessmentDocument_],
        }).then((res) =>
          setLastSavedStep(res.data?.update_EsrsAssessment_by_pk?.onboardingStep ?? 0)
        );
      }
      setShowAlertMessage(false);
    }
  };

  const handlePrevStep = () => {
    if (step <= 1) return;
    updateOnboardingStep({
      variables: {
        id: esrsAssessmentId,
        onboardingStep: step - 1,
      },
      refetchQueries: [GetOnboardingEsrsAssessmentDocument_],
    }).then((res) => setLastSavedStep(res.data?.update_EsrsAssessment_by_pk?.onboardingStep ?? 0));

    setStep(step - 1);
    setNextDisabled(false);
    setShowAlertMessage(false);
  };

  return (
    <VStack bg="bg.defaultToDecorative" minW="800px" flexGrow="1">
      <ContentLayout backButton={true} header={false} paddingBottom="0px !important">
        <HStack alignSelf="center" flexGrow="1" w="100%" alignItems="stretch">
          {step < 5 ? <AssessmentPath step={step} /> : <Box w="20%" />}
          <VStack
            width="60%"
            bg="bg.default"
            border="1px solid"
            borderColor="border.decorative"
            borderRadius="8px"
            alignItems="start"
            justifyContent="space-between"
            p="24px 24px 0"
            position="relative"
          >
            {step === 1 && (
              <AboutEsrs
                esrsAssessment={esrsAssessment}
                setNextDisabled={setNextButtonDisabled}
                esrs2MaterialMetrics={esrs2MaterialMetrics}
              />
            )}
            {step === 2 && (
              <EsrsAssessmentConfig
                isOnboarding={true}
                nextDisabled={nextDisabled}
                setNextDisabled={setNextDisabled}
                showAlertMessage={showAlertMessage}
              />
            )}
            {step === 3 && (
              <MaterialityAssessmentStep
                setStep={setStep}
                isOnboarding={true}
                isCollectOnly={esrsAssessment?.collectOnly ?? false}
              />
            )}
            {step === 4 && <DataCollectionSetup setStep={setStep} isOnboarding={true} />}
            {step === 5 && <DoubleMaterialityAssessment onboarding={true} />}
            {step < 5 && (
              <VStack
                position="sticky"
                bottom="0"
                left="0"
                right="0"
                w="calc(100% + 48px)"
                backgroundColor="inherit"
                borderTop="1px solid"
                borderColor="border.decorative"
                borderBottomRadius="8px"
                ml="-24px"
              >
                <HStack justifyContent="flex-end" p="16px" alignSelf="flex-end">
                  {step > 1 && (
                    <Button variant="ghost" onClick={() => handlePrevStep()}>
                      Prev. step
                    </Button>
                  )}
                  {step < 4 && (
                    <Button
                      variant="primary"
                      onClick={handleNextStep}
                      isDisabled={nextButtonDisabled}
                    >
                      Next step
                    </Button>
                  )}
                  {step === 4 && (
                    <Button
                      variant="primary"
                      onClick={() => {
                        updateOnboardingStep({
                          variables: {
                            id: esrsAssessmentId,
                            onboardingStep: 0,
                          },
                          refetchQueries: [GetOnboardingEsrsAssessmentDocument_],
                        }).then(() => {
                          if (history.length > 1) {
                            navigate(`/${companyId}/esrs/${esrsAssessmentId}`);
                          } else {
                            const currentUrl = window.location.href;
                            window.location.href = currentUrl.replace(/\/[^/]+\/?$/, '');
                          }
                        });
                      }}
                    >
                      Done
                    </Button>
                  )}
                </HStack>
              </VStack>
            )}
          </VStack>
        </HStack>
      </ContentLayout>
    </VStack>
  );
};
