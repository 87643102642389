export const auditLogsTablesMap = {
  general: ['Note', 'Comment', 'Attachment'],
  taxonomy: [
    'Answer',
    'BusinessUnit',
    'BusinessUnitAssessment',
    'ReportingGroup',
    'CompanyAssessmentAggregate',
    'ActivityReport',
    'ReportDownloads',
    'Financials',
    'PortfolioCompany',
  ],
  pai: [],
  esrs: [],
};
