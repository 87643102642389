import { HStack, VStack } from '@chakra-ui/react';
import { Typography } from 'Tokens';
import { ReactNode } from 'react';

type SidebarMenuOption = {
  icon: ReactNode;
  title: string;
  selected: boolean;
  onClick: () => void;
};

const SidebarButton = ({
  icon,
  title,
  selected,
  onClick,
}: {
  icon: ReactNode;
  title: string;
  selected: boolean;
  onClick: () => void;
}) => {
  return (
    <HStack
      padding="18px"
      w="100%"
      borderTop="1px solid"
      borderColor="border.decorative"
      cursor="pointer"
      bg={selected ? 'bg.selected' : 'unset'}
      _hover={{ background: 'bg.hover' }}
      transition="all 0.15s"
      gap="8px"
      onClick={() => onClick()}
    >
      {icon}
      <Typography variant="bodyStrong">{title}</Typography>
    </HStack>
  );
};

export const SidebarMenu = ({ options }: { options: SidebarMenuOption[] }) => {
  return (
    <VStack gap="0px">
      {options.map((option) => (
        <SidebarButton
          key={option.title}
          icon={option.icon}
          title={option.title}
          selected={option.selected}
          onClick={option.onClick}
        />
      ))}
    </VStack>
  );
};
