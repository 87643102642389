import { HStack, VStack } from '@chakra-ui/react';
import { IconButton } from 'Atoms';
import { SearchInput } from 'Molecules';
import { Typography } from 'Tokens';
import { FilterIcon, HistoryIcon, RefreshIcon } from 'Tokens/Icons/Function';
import { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';
import { HistoryItem } from 'Molecules/HistoryItem';
import { AuditLog } from './History.hooks';
import { useDebounce } from 'utils/hooks';
import { useTranslation } from 'utils/translation';

export enum HistoryContext {
  all,
  taxonomy,
  esrs,
  pai,
}

export const HistorySidebarHeader = () => {
  const { t } = useTranslation('audit');
  return (
    <HStack pt="8px" w="100%" justify="space-between">
      <HStack>
        <HistoryIcon color="text.muted" />
        <Typography variant="bodyStrong" color="text.muted">
          {t('audit:common.history')}
        </Typography>
      </HStack>
    </HStack>
  );
};

export const HistorySideBarFiltering = ({
  title,
  searchValue,
  setSearchValue,
  refetchAuditLogs,
}: {
  title: string;
  searchValue: string;
  setSearchValue: Dispatch<SetStateAction<string>>;
  refetchAuditLogs: () => void;
}) => {
  const { t } = useTranslation('files');
  return (
    <VStack alignItems="flex-start" pt="8px" w="100%">
      <Typography variant="h2">{title}</Typography>
      <HStack width="100%" spacing="8px">
        <SearchInput
          search={searchValue}
          setSearch={(input) => setSearchValue(input)}
          placeholder={t('files:searchPlaceholder')}
          width="100%"
          size="md"
        />
        <IconButton
          size="md"
          variant="secondary"
          icon={<FilterIcon color="inherit" />}
          aria-label="filter through history"
        />
        <IconButton
          aria-label="Refresh"
          size="md"
          variant="secondary"
          icon={<RefreshIcon color="inherit" />}
          onClick={refetchAuditLogs}
        />
      </HStack>
    </VStack>
  );
};

export const HistorySidebar = ({
  auditLogs,
  loading,
  searchValue,
}: {
  auditLogs: AuditLog[];
  loading: boolean;
  searchValue: string;
}) => {
  const nonSkippedAuditLogs = useMemo(
    () => auditLogs.filter((auditLog) => !auditLog?.isSkipped),
    [auditLogs]
  );
  const [filteredAuditLogs, setFilteredAuditLogs] = useState(nonSkippedAuditLogs);
  const debouncedValue = useDebounce(searchValue, 500);
  useEffect(() => {
    setFilteredAuditLogs(
      nonSkippedAuditLogs.filter((auditLog) =>
        JSON.stringify(auditLog).toLowerCase().includes(debouncedValue.toLowerCase())
      )
    );
  }, [debouncedValue, nonSkippedAuditLogs]);

  return (
    <VStack
      backgroundColor="red"
      width="100%"
      bg="bg.default"
      spacing="4px"
      borderBottom="1px  solid"
      borderBottomColor="border.decorative"
      borderRadius="8px"
    >
      {!loading &&
        filteredAuditLogs?.map((auditLog) => <HistoryItem key={auditLog.id} auditLog={auditLog} />)}
    </VStack>
  );
};
