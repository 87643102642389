import { WarningIcon } from '@chakra-ui/icons';
import { HStack } from '@chakra-ui/react';
import { Typography } from 'Tokens';
import { AIIcon } from 'Tokens/Icons/Function';
import { useSearchParams, useLocation, Link } from 'react-router-dom';
import { AggregatedQualitativeAnswers } from '../../../../AggregatedMetrics';
import { GeneratedAnswer } from '../../../../MetricAI';
import { MetricsTableData } from '../../../../MetricAnswers.hooks';
import { useTranslation } from 'utils/translation';

export type AnswerObject =
  | {
      [key: string]: boolean | null;
    }
  | undefined;

export type TextMetricProps = {
  metric: MetricsTableData['metric'];
  companyReportingUnit?: string;
  autoExpandHeight?: boolean;
  isGeneratingAnswers?: boolean;
  rowData?: MetricsTableData;
  setRowData: (
    param: (MetricsTableData & { sourceData?: AggregatedQualitativeAnswers[number] }) | undefined
  ) => void;
  answersData?: AggregatedQualitativeAnswers;
  hasRef?: boolean;
  generatedAnswer?: GeneratedAnswer;
  isReadOnly?: boolean;
  isAI?: boolean;
  placeholder?: string;
};

export const AIInfoWarning = () => {
  const { t } = useTranslation('ai');
  return (
    <HStack spacing="4px" alignItems="flex-start" justifyContent="start">
      <WarningIcon color="text.warning" boxSize="18px"></WarningIcon>
      <Typography variant="body" color="text.warning">
        {t('esrs.answers.fail')}
      </Typography>
    </HStack>
  );
};

export const AISummaryAvailable = ({
  metric,
  setRowData,
  metricAnswerData,
}: {
  metric: MetricsTableData['metric'];
  setRowData: (
    param: (MetricsTableData & { sourceData?: AggregatedQualitativeAnswers[number] }) | undefined
  ) => void;
  metricAnswerData?: AggregatedQualitativeAnswers[number];
}) => {
  const [searchParams] = useSearchParams();
  const location = useLocation();
  const { t } = useTranslation('ai');

  const isForBusinessUnits = !!metricAnswerData?.reportingUnits?.length;

  return (
    <HStack spacing="4px" alignItems="flex-start" justifyContent="start">
      <AIIcon color="text.action" boxSize="16px" />
      <Link
        to={location.pathname + '?source=true'}
        onClick={() => {
          setRowData?.({ metric: metric, sourceData: metricAnswerData });
          searchParams.set('source', 'true');
        }}
      >
        <Typography variant="detail">
          {isForBusinessUnits
            ? t('esrs.summary.businessUnitsSummaryAvailable')
            : t('esrs.summary.subsidiariesSummaryAvailable')}
        </Typography>
      </Link>
    </HStack>
  );
};
