import { Box, HStack, useToast as useChakraToast } from '@chakra-ui/react';
import { Button } from 'Atoms';
import { Modal } from 'Molecules';
import { Typography } from 'Tokens';
import { AIIcon } from 'Tokens/Icons/Function';
import { Dispatch, SetStateAction } from 'react';

export const InteractionBlocker = ({ id }: { id?: string }) => {
  return (
    <Box
      id={id}
      position="fixed"
      top={0}
      left={0}
      width="100%"
      height="100%"
      backgroundColor="transparent"
      zIndex={1401}
    />
  );
};

export const AIProgressModal = ({
  isOpen,
  onClose,
  setIsGenerating,
  cancelRequest,
}: {
  isOpen: boolean;
  onClose: () => void;
  setIsGenerating: Dispatch<SetStateAction<boolean>>;
  cancelRequest: () => void;
}) => {
  const chakraToast = useChakraToast();
  return (
    <Modal
      cancelText="Stay on the page"
      confirmText="Close & stop AI"
      title="AI is writing..."
      isOpen={isOpen}
      onClose={onClose}
      onConfirm={() => {
        cancelRequest();
        setIsGenerating(false);
        chakraToast.closeAll();
        onClose();
      }}
      containerProps={{ zIndex: 1402 }}
    >
      <Typography variant="body">
        Answers generation is in progress. If you close this window, you will lose the progress. Are
        you sure you want to close this page and open another?
      </Typography>
    </Modal>
  );
};

export const AnswersRegenerationModal = ({
  isOpen,
  onClose,
  extractAndGenerate,
  selectedFileId,
}: {
  isOpen: boolean;
  onClose: () => void;
  extractAndGenerate: (fileId: string) => Promise<any>;
  selectedFileId: string;
}) => {
  return (
    <Modal
      size="xs"
      isOpen={isOpen}
      onClose={onClose}
      hasHeader={false}
      confirmText="Write new texts"
      onConfirm={() => {
        extractAndGenerate(selectedFileId);
        onClose();
      }}
    >
      <Typography variant="bodyStrong">
        Do you want AI to generate new answers for all these data points? Current versions of
        answers will be erased and new answers will be generated
      </Typography>
    </Modal>
  );
};

export const AnswersApprovalWarningModal = ({
  isOpen,
  onClose,
  onConfirm,
}: {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
}) => {
  return (
    <Modal
      size="xs"
      isOpen={isOpen}
      onClose={onClose}
      hasHeader={false}
      confirmText="Close without saving"
      onConfirm={() => {
        onConfirm();
        onClose();
      }}
    >
      <Typography variant="bodyStrong">
        There are some answers that were not approved. Unapproved answers will not be saved
      </Typography>
    </Modal>
  );
};

export const AIStickyToolbar = ({
  onApprove,
  onDiscard,
  count,
}: {
  onApprove: () => void;
  onDiscard: () => void;
  count: number;
}) => {
  return (
    <HStack
      width="924px"
      bg="bg.accent"
      color="text.onAccent"
      position="fixed"
      borderRadius="8px"
      paddingX="16px"
      paddingY="8px"
      bottom="4%"
      left="50%"
      transform="translateX(-50%)"
      zIndex={1450}
    >
      <HStack w="100%" justifyContent="space-between">
        <HStack spacing="8px">
          <AIIcon boxSize="20px" color="inherit" />
          <Typography variant="bodyStrong" color="text.onAccent">
            {count} answers were generated. Approve results to save answers
          </Typography>
        </HStack>

        <HStack spacing="16px">
          <Button
            bg="bg.default"
            color="text.default"
            variant="secondary"
            size="sm"
            onClick={onApprove}
            _hover={{ bg: 'bg.accent' }}
          >
            Approve all
          </Button>
          <Button color="text.onAccent" variant="ghost" size="sm" onClick={onDiscard}>
            Discard
          </Button>
        </HStack>
      </HStack>
    </HStack>
  );
};
