import { RefetchQueriesInclude } from '@apollo/client';
import {
  HStack,
  IconButton,
  Box,
  Menu,
  MenuButton,
  MenuList,
  MenuGroup,
  MenuItem,
  Divider,
} from '@chakra-ui/react';
import { Button, TruncatableText } from 'Atoms';
import { useRemoveAttachment } from 'Molecules/InputCard/InputCardDocumentation.hooks';
import { Typography } from 'Tokens';
import { ChevronDownIcon } from 'Tokens/Icons/Direction';
import { AddIcon, RemoveIcon } from 'Tokens/Icons/Function';
import { CheckIcon } from 'Tokens/Icons/Status';
import { Attachment, AttachmentBox } from 'models';
import { useCallback, useMemo, useState } from 'react';
import { FileIcon, defaultStyles } from 'react-file-icon';
import { getNameExtension } from 'utils/files';

export const DisclosureRequirementAttachment = ({
  attachments,
  onAttachmentDrawerOpen,
  refetchQueries,
}: {
  attachments: AttachmentBox['attachments'];
  onAttachmentDrawerOpen: () => void;
  refetchQueries: RefetchQueriesInclude;
}) => {
  const unassignAttachment = useRemoveAttachment();

  const removeAttachment = useCallback(
    (attachmentId: string) => {
      return unassignAttachment(attachmentId, refetchQueries);
    },
    [unassignAttachment]
  );

  // Take only the first attachment since we only allow selecting one file through the drawer
  const attachment: Attachment = useMemo(() => attachments?.[0], [attachments]);
  const file = useMemo(() => attachment?.file, [attachment]);
  const { extension } = useMemo(() => {
    return getNameExtension(file?.storageFile?.name ?? '');
  }, [file]);

  return (
    <>
      {!attachment ? (
        <Button
          px={0}
          variant="ghost"
          size="sm"
          color="text.selected"
          _hover={{ bg: 'bg.hover', color: 'text.selected' }}
          _active={{ bg: 'bg.pressed' }}
          leftIcon={<AddIcon boxSize="16px" color="inherit" />}
          onClick={onAttachmentDrawerOpen}
        >
          Add document
        </Button>
      ) : (
        <HStack
          h="28px"
          bg="bg.default"
          justifyContent="center"
          alignItems="center"
          spacing="6px"
          p="4px"
          border="1px solid"
          borderColor="border.decorative"
          borderRadius="5px"
        >
          <Box w="16.67px">
            <FileIcon
              extension={extension}
              {...defaultStyles[extension as keyof typeof defaultStyles]}
              labelUppercase
            />
          </Box>

          <Typography variant="bodyStrong">{file.title}</Typography>

          <IconButton
            variant="ghost"
            aria-label="CloseButton"
            size="xs"
            icon={<RemoveIcon color="inherit" />}
            onClick={() => removeAttachment(attachment.id)}
          />
        </HStack>
      )}
    </>
  );
};

export const PolicyAttachment = ({
  attachments,
  selectedPolicyFileId,
  setSelectedPolicyFileId,
}: {
  attachments: AttachmentBox['attachments'];
  selectedPolicyFileId: string;
  setSelectedPolicyFileId: (fileId: string) => void;
}) => {
  const [selectedFileId, setSelectedFileId] = useState<string>('');

  const selectedAttachment = useMemo(
    () => attachments?.find((attachment) => attachment.file?.id === selectedPolicyFileId),
    [attachments, selectedPolicyFileId]
  );

  const selectedFile = useMemo(() => selectedAttachment?.file, [selectedAttachment]);

  const { extension } = useMemo(() => {
    return getNameExtension(selectedFile?.storageFile?.name ?? '');
  }, [selectedFile]);

  return (
    <>
      {!selectedPolicyFileId ? (
        <Menu gutter={2} closeOnSelect={false}>
          <MenuButton
            as={Button}
            variant="ghost"
            color="text.selected"
            _hover={{ bg: 'bg.hover', color: 'text.selected' }}
            _active={{ bg: 'bg.pressed' }}
            rightIcon={<ChevronDownIcon boxSize="16px" color="inherit" />}
            size="sm"
            px={0}
          >
            Select policy
          </MenuButton>
          <MenuList p="8px" w="280px">
            <MenuGroup p="8px">
              {attachments?.map((attachment) => {
                return (
                  <MenuItem
                    p="8px"
                    m="0px"
                    w="100%"
                    onClick={() => setSelectedFileId(attachment.file?.id)}
                  >
                    <HStack
                      w="100%"
                      justifyContent="space-between"
                      color={selectedFileId === attachment.file?.id ? 'text.selected' : ''}
                    >
                      <HStack>
                        <TruncatableText
                          color="inherit"
                          text={attachment.file.title}
                          variant="bodyStrong"
                        />
                      </HStack>
                      {attachment.file?.id === selectedFileId && <CheckIcon color="inherit" />}
                    </HStack>
                  </MenuItem>
                );
              })}
            </MenuGroup>
            <Divider color="border.decorative" my="8px" ml="-8px" pr="16px" />
            <Box w="100%">
              <Button
                isDisabled={!selectedFileId}
                variant="primary"
                w="100%"
                onClick={() => setSelectedPolicyFileId(selectedFileId)}
              >
                Use this file
              </Button>
            </Box>
          </MenuList>
        </Menu>
      ) : (
        <HStack
          h="28px"
          bg="bg.default"
          justifyContent="center"
          alignItems="center"
          spacing="6px"
          p="4px"
          border="1px solid"
          borderColor="border.decorative"
          borderRadius="5px"
        >
          <Box w="16.67px">
            <FileIcon
              extension={extension}
              {...defaultStyles[extension as keyof typeof defaultStyles]}
              labelUppercase
            />
          </Box>

          <Typography variant="bodyStrong">{selectedFile?.title}</Typography>

          <IconButton
            variant="ghost"
            aria-label="CloseButton"
            size="xs"
            icon={<RemoveIcon color="inherit" />}
            onClick={() => setSelectedPolicyFileId('')}
          />
        </HStack>
      )}
    </>
  );
};
