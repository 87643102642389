import {
  Compliance,
  TaxonomyResultColumns,
  TaxonomyResultRow,
} from 'Features/TaxonomyResultsTable/TaxonomyResultsTableTypes';

export function calculateCompliance(data: Compliance[] | string[]): Compliance {
  if (data.some((d) => d === 'inProgress')) {
    return 'inProgress';
  } else if (data.every((d) => d === 'compliant')) {
    return 'compliant';
  } else if (data.every((d) => d === 'notCompliant')) {
    return 'notCompliant';
  } else {
    return 'partiallyCompliant';
  }
}

export function sortByActivityNumber(
  a: TaxonomyResultRow<Partial<TaxonomyResultColumns>>,
  b: TaxonomyResultRow<Partial<TaxonomyResultColumns>>
): number {
  const rowName = a.rowName;
  const rowName2 = b.rowName;

  const [a1, a2] = rowName.split('.');
  const [b1, b2] = rowName2.split('.');

  if (a1 === b1) {
    const [secondNumber, restOfActivityName] = a2.split(' ');
    const [secondNumber2, restOfActivityName2] = b2.split(' ');

    const a2Number = Number(secondNumber);
    const b2Number = Number(secondNumber2);

    if (a2Number === b2Number) {
      return restOfActivityName?.localeCompare(restOfActivityName2 ?? '');
    }
    return a2Number - b2Number;
  }
  return Number(a1) - Number(b1);
}
