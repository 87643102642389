import { useCallback, useMemo } from 'react';
import {
  CompanyAssessmentsDocument_,
  useDeleteCompanyAssessmentMutation,
  useActivityAssessmentQuery,
  ActivityAssessment,
  useUpsertCompanyAssessmentMutation,
  BusinessUnitAssessment_Constraint_,
  BusinessUnitAssessment_Update_Column_,
  CompanyAssessmentAggregate_Constraint_,
  CompanyAssessmentAggregate_Update_Column_,
  BusinessUnitAssessment_Insert_Input_,
  ActivityReport_Constraint_,
  ActivityReport_Update_Column_,
  Financials_Constraint_,
  ActivityAssessmentQuery_,
  ObjectiveWithQuestions,
  CompanyAssessment,
  CompanyAssessmentDocument_,
  Financials_Update_Column_,
  useActivityAssessmentAnswersQuery,
  Answer,
  useFlagCompanyAssessmentAsDirtyMutation,
  useUpdateAssessmentLockMutation,
  useGroupsQuery,
  useInvestorsQuery,
  InvestorsQuery_,
  CompanyAssessmentDetailsFieldsFragment_,
  CompanyAssessmentsQuery_,
  QuarterEnum_Enum_,
} from 'models';
import { DEFAULT_ACTIVITY_TAG, resolveActivity } from 'utils/scores/questions';
import { ObjectiveId, sortObjectives } from 'utils/objectives/objectivesOrder';
import {
  formatDisplayQuarterYear,
  formatQuarterYear,
  getQuarterFromDate,
  stringToDate,
} from 'utils/date';
import { useCurrentCompanyId, useToast } from 'utils/hooks';
import { endOfYear, startOfYear } from 'date-fns';
import { createFinancials } from 'utils/financials';
import { useTranslation } from 'utils/translation';
import { getActivityResult, getGeneralAssessmentResult } from 'utils/scores/taxonomyScore';
import { useUnshareCompanyAssessmentWithGroup } from 'containers/Groups';
import { useUnshareCompanyAssessment } from 'containers/Investors';
import {
  useActivityAssessmentVersion,
  useGetNewestValidActivityVersion,
} from './VersioningOfQuestions.hooks';
import { captureException } from '@sentry/react';
import { sortBy, uniqBy } from 'lodash';

export const COMPANY_LEVEL_GENERAL_ASSESSMENT_ID = 'company-general-questions';

export const REPORTING_METHODS = ['yearly', 'quarterly', 'half-yearly'];
export const COMPANY_LEVEL_ASSESSMENT_ID = 'company';
export const GENERAL_ACTIVITY_REF = '0.0';

export const getInvestorsByReportingPeriods = (
  investors: InvestorsQuery_['investors'],
  reportingPeriod: string
) => {
  const sortedInvestors = sortBy(investors, (investor) => investor.sharedAssessment);
  return uniqBy(
    sortedInvestors.filter(
      (investor) => formatQuarterYear(investor.quarter, investor.year) === reportingPeriod
    ),
    'portfolio.ownerCompany.name'
  );
};

export function useDeleteCompanyAssessment() {
  const { companyId } = useCurrentCompanyId();
  const [deleteCompanyAssessment] = useDeleteCompanyAssessmentMutation();
  const { unshareCompanyAssessment: unshareAssessmentWithPortfolio } =
    useUnshareCompanyAssessment();
  const { unshareCompanyAssessment: unshareAssessmentWithGroup } =
    useUnshareCompanyAssessmentWithGroup();

  const { data: groups } = useGroupsQuery({
    variables: {
      companyId,
    },
    skip: !companyId,
  });

  const { data: investors } = useInvestorsQuery({
    variables: {
      companyId,
    },
    skip: !companyId,
  });

  return useCallback(
    (id: string) =>
      deleteCompanyAssessment({
        variables: {
          id,
          deletedAt: 'now()',
        },
        refetchQueries: [CompanyAssessmentsDocument_],
        awaitRefetchQueries: true,
      }).then(async () => {
        const portfoliosSharedWith = investors?.investors.filter(
          (p) => p.sharedCompanyAssessmentId === id
        );
        const groupsSharedWith = groups?.groups.filter((g) => g.sharedCompanyAssessmentId === id);
        if (groupsSharedWith?.length) {
          await Promise.all(
            groupsSharedWith.map((g) => unshareAssessmentWithGroup(g.id, g.ownerGroupAssessmentId))
          );
          if (portfoliosSharedWith?.length)
            await Promise.all(
              portfoliosSharedWith.map((p) => unshareAssessmentWithPortfolio(p.id, p.portfolioId))
            );
        }
      }),
    [deleteCompanyAssessment, companyId]
  );
}

export const resolveActivityAssessment = (
  activityAssessmentAndQuestions: ActivityAssessmentQuery_ | undefined,
  isMSSAlignedAndCompleted?: boolean
): { activityAssessment: ActivityAssessment; objectives: ObjectiveWithQuestions[] } | undefined => {
  // TODO: Return loading and error states instead?
  if (
    !activityAssessmentAndQuestions?.activityAssessment ||
    !activityAssessmentAndQuestions.objectives
  )
    return undefined;

  const { resolvedQuestions, resolvedActivityTag } = resolveActivity(
    activityAssessmentAndQuestions.objectives,
    activityAssessmentAndQuestions.activityAssessment?.activityVersion.tagExpression ??
      DEFAULT_ACTIVITY_TAG,
    undefined,
    isMSSAlignedAndCompleted
  );

  const questionsSortedByObjective =
    activityAssessmentAndQuestions.objectives
      .filter((o) => o.questions.length > 0)
      .sort(sortObjectives) ?? [];

  const resolvedActivityAssessment = {
    ...activityAssessmentAndQuestions.activityAssessment,
    activityTag: resolvedActivityTag,
  };
  return {
    activityAssessment: resolvedActivityAssessment,
    objectives: questionsSortedByObjective.map((objective) => {
      return {
        ...objective,
        key: objective.key as ObjectiveId,
        questions: resolvedQuestions
          .filter((rq) => rq.objective.key === objective.key)
          .map((rq) => ({
            ...rq,
            ...objective.questions.find((q) => q.id === rq.id)?.olderRevisions,
          })),
        // oldQuestions: oldResolvedQuestions
        //   .filter(
        //     (rq) =>
        //       rq.objective.key === objective.key &&
        //       objective.oldQuestions.find((q) => q.id === rq.id)
        //   )
        //   .map((rq) => ({
        //     ...rq,
        //     ...objective.oldQuestions.find((q) => q.id === rq.id)?.newerRevisions,
        //   })),
      };
    }),
  };
};

export const useActivityAssessment = (id: ActivityAssessment['id'], version?: number) => {
  const { currentVersion, previousVersion } = useActivityAssessmentVersion(id, { skip: !!version });
  const { data, ...rest } = useActivityAssessmentQuery({
    variables: {
      activityAssessmentId: id,
      version: version ?? currentVersion ?? 1,
      prevVersion: previousVersion ?? 1,
    },
    skip: !id || (!version && !currentVersion),
  });

  const isMSSAlignedAndCompleted = useMemo(
    () =>
      (data?.activityAssessment?.bAssessment.cAssessment.bAssesssments[0].activityReports[0]
        .cachedResult?.isAligned &&
        data?.activityAssessment?.bAssessment.cAssessment.bAssesssments[0].activityReports[0]
          .cachedResult?.isCompleted) ??
      false,
    [data]
  );

  const resolvedActivityAssessmentAndQuestions = useMemo(() => {
    return resolveActivityAssessment(data, isMSSAlignedAndCompleted);
  }, [id, data, isMSSAlignedAndCompleted]);

  return {
    data: resolvedActivityAssessmentAndQuestions,
    ...rest,
  };
};

export const useActivityAssessmentWithAnswers = (
  id: ActivityAssessment['id'],
  version?: number
) => {
  const { currentVersion } = useActivityAssessmentVersion(id, { skip: !!version });

  const {
    data: objectiveData,
    loading: objectiveloading = true,
    error: objectiveError,
  } = useActivityAssessment(id, version);

  const {
    data: answerData,
    loading: answerLoading = true,
    error: answerError,
  } = useActivityAssessmentAnswersQuery({
    variables: {
      activityAssessmentId: id,
      version: version ?? currentVersion ?? 1,
    },
    skip: !id || (!version && !currentVersion),
  });

  const answerMap = useMemo(
    () =>
      answerData?.answers.reduce(
        (map, answer) => ({ ...map, [answer.id]: answer }),
        {} as Record<string, Answer>
      ) || {},
    [answerData]
  );

  const objectives = useMemo(() => {
    return (
      objectiveData?.objectives.map((o) => ({
        ...o,
        questions: o.questions.map((q) => ({
          ...q,
          answer: { ...q.answer, ...answerMap[q?.answer?.id] },
          subQuestions: q.subQuestions?.map((subQuestion) => ({
            ...subQuestion,
            questions: subQuestion.questions.map((sqQuestion) => ({
              ...sqQuestion,
              answer: {
                ...sqQuestion.answer,
                ...answerMap[sqQuestion.answer?.id],
              },
            })),
          })),
        })),
      })) ?? []
    );
  }, [objectiveData, answerMap]);

  return {
    data: { ...objectiveData, objectives },
    loading: objectiveloading || answerLoading,
    error: objectiveError || answerError,
  };
};

export const useActivityResult = (id: ActivityAssessment['id']) => {
  const { data, ...rest } = useActivityAssessment(id);

  const result = useMemo(() => {
    const { activityAssessment, objectives } = data ?? {};
    if (!activityAssessment || !objectives) {
      return undefined;
    }
    if (activityAssessment.activity.reference === GENERAL_ACTIVITY_REF) {
      return getGeneralAssessmentResult({ activityAssessment, objectives });
    }
    return getActivityResult({ activityAssessment, objectives });
  }, [data, id]);

  return { data: result, ...rest };
};

export const useUpsertCompanyAssessment = () => {
  const { companyId } = useCurrentCompanyId();
  const [upsertCompanyAssessment] = useUpsertCompanyAssessmentMutation();
  const generalFinancials = createFinancials();
  const totalFinancials = createFinancials();

  const { getNewestValidActivityVersion } = useGetNewestValidActivityVersion();

  return useCallback(
    async (
      assessment: {
        id?: string;
        title: string;
        contactPersonId: string;
        startDate: string;
        businessUnits: Array<{ id: string; isDeleted: boolean }>;
      },
      cAssessment: CompanyAssessment
    ) => {
      const startDate = assessment.startDate ?? startOfYear(new Date());
      const endDate = endOfYear(stringToDate(assessment.startDate));

      const generalActivityVersionNumber = await getNewestValidActivityVersion(
        GENERAL_ACTIVITY_REF,
        startDate,
        assessment.id ?? '00000000-0000-0000-0000-000000000000'
      );

      const defaultBusinessUnitAssessment: BusinessUnitAssessment_Insert_Input_[] = [
        {
          businessUnit: {
            data: {
              name: 'Reporting unit',
              companyId: companyId,
              contactPersonId: assessment.contactPersonId,
              labels: [],
            },
          },
        },
      ];
      const assessmentBusinessUnits: BusinessUnitAssessment_Insert_Input_[] =
        assessment.businessUnits?.map((bu) => ({
          businessUnitId: bu.id,
          deletedAt: bu.isDeleted ? 'now()' : null,
        })) ?? [];

      const businessUnits =
        assessmentBusinessUnits.length === 0
          ? defaultBusinessUnitAssessment
          : assessmentBusinessUnits;

      return upsertCompanyAssessment({
        variables: {
          cAssessment: {
            id: assessment?.id ?? undefined,
            startDate: startDate,
            endDate: endDate,
            companyId: companyId,
            isLocked: cAssessment?.isLocked ?? false,
            // aggregateId: cAssessment?.aggregate?.id,
            aggregate: {
              data: {
                id: cAssessment?.aggregate?.id,
                title: assessment.title,
                interval: 'yearly',
                contactPersonId: assessment.contactPersonId,
                startDate: assessment.startDate ?? startDate,
                companyId: companyId,
              },
              on_conflict: {
                constraint: CompanyAssessmentAggregate_Constraint_.CompanyAssessmentAggregatePkey_,
                update_columns: [
                  CompanyAssessmentAggregate_Update_Column_.StartDate_,
                  CompanyAssessmentAggregate_Update_Column_.Title_,
                  CompanyAssessmentAggregate_Update_Column_.ContactPersonId_,
                ],
              },
            },
            bAssesssments: {
              data: [
                ...businessUnits,
                {
                  // General activity for the company assessment
                  hasGeneralAssessment: true,
                  activityReports: {
                    data: [
                      {
                        activityRef: GENERAL_ACTIVITY_REF,
                        activityVersionNumber: generalActivityVersionNumber,
                        financials: {
                          data: {
                            ...generalFinancials,
                          },
                          on_conflict: {
                            constraint: Financials_Constraint_.FinancialsReportId_801651ecUniq_,
                            update_columns: [Financials_Update_Column_.UpdatedAt_],
                          },
                        },
                      },
                    ],
                    on_conflict: {
                      constraint:
                        ActivityReport_Constraint_.ActivityReportBAssessmentIdActivityRef_614b7372Uniq_,
                      update_columns: [ActivityReport_Update_Column_.CachedResultId_],
                    },
                  },
                } as BusinessUnitAssessment_Insert_Input_,
              ],
              on_conflict: {
                constraint:
                  BusinessUnitAssessment_Constraint_.BusinessUnitAssessmentCAssessmentIdBusinessUnitIdN_8db61b88U_,
                update_columns: [BusinessUnitAssessment_Update_Column_.DeletedAt_],
              },
            },
            totalFinancials: {
              data: {
                ...totalFinancials,
              },
              on_conflict: {
                constraint: Financials_Constraint_.FinancialsCAssessmentIdKey_,
                update_columns: [Financials_Update_Column_.UpdatedAt_],
              },
            },
          },
        },
        refetchQueries: [CompanyAssessmentDocument_, CompanyAssessmentsDocument_],
        awaitRefetchQueries: true,
      });
    },
    [upsertCompanyAssessment, getNewestValidActivityVersion, companyId]
  );
};

export const useFlagAndUnlockAssessment = () => {
  const [flagAssessment] = useFlagCompanyAssessmentAsDirtyMutation();
  const [updateAssessmentLock] = useUpdateAssessmentLockMutation();
  const toast = useToast();
  const { t } = useTranslation(['common']);

  const flagAndUnlockAssessment = async (assessment: Partial<CompanyAssessment>) => {
    try {
      if (!assessment) return;

      await flagAssessment({ variables: { cachedResultId: assessment.cachedResultId } });

      if (assessment.isLocked) {
        await updateAssessmentLock({
          variables: { companyAssessmentId: assessment.id, isLocked: false },
        });
        toast({
          text: t('common:assessment.isLocked.toast.backInProgress'),
          duration: null,
        });
      }
    } catch (e) {
      captureException(e, {
        extra: { errorMessage: t('common:assessment.isLocked.toast.error') },
      });
      toast({
        text: t('common:assessment.isLocked.toast.error'),
        variant: 'danger',
      });
    }
  };

  return [flagAndUnlockAssessment];
};

export const useCompanyAssessmentsMapByReportingPeriod = (
  assessments: CompanyAssessmentDetailsFieldsFragment_[],
  investors: InvestorsQuery_['investors']
) => {
  // reporting periods
  const sortedReportingPeriods = sortBy(
    investors.filter((curInvestor) => !!curInvestor?.year),
    ['year', 'quarter']
  );
  const reportingPeriods = uniqBy(
    sortedReportingPeriods.map((curInvestor) => {
      const value = formatQuarterYear(curInvestor?.quarter, curInvestor?.year);
      const label = formatDisplayQuarterYear(value);
      return {
        label,
        value,
      };
    }),
    'value'
  );

  // assessments Map
  const assessmentsMap = new Map<string, CompanyAssessmentsQuery_['assessments'] | undefined>();
  for (const assessment of assessments) {
    let startDate: Date;
    let quarter: QuarterEnum_Enum_;
    let year: number;

    if (assessment.portfoliosWithAccess.length > 0) {
      quarter = assessment.portfoliosWithAccess[0].quarter;
      year = assessment.portfoliosWithAccess[0].year ?? new Date().getFullYear();
    } else {
      startDate = new Date(assessment.aggregate.startDate);
      quarter = getQuarterFromDate(startDate);
      year = startDate.getFullYear();
    }

    const formattedStartDate = formatQuarterYear(quarter, year);

    const isInReportingPeriods = reportingPeriods.some(
      (reportingPeriod) => formattedStartDate === reportingPeriod.value
    );

    if (isInReportingPeriods) {
      if (!assessmentsMap.has(formattedStartDate)) {
        assessmentsMap.set(formattedStartDate, []);
      }
      const assessmentsWithPeriod = assessmentsMap.get(formattedStartDate);
      assessmentsWithPeriod?.push(assessment);
      assessmentsMap.set(formattedStartDate, assessmentsWithPeriod);
    } else {
      if (!assessmentsMap.has('other')) {
        assessmentsMap.set('other', []);
      }
      const otherAssessments = assessmentsMap.get('other');
      otherAssessments?.push(assessment);
      assessmentsMap.set('other', otherAssessments);
    }
  }
  return { assessments: assessmentsMap, reportingPeriods };
};
