import { VStack, HStack, Image, useColorMode, useColorModePreference, Box } from '@chakra-ui/react';
import { Typography } from 'Tokens';
import { useEffect } from 'react';
import { useLocalStorage } from 'react-use';

enum ColorModesEnum {
  dark = 'dark',
  light = 'light',
  system = 'system',
}

const colorModes = {
  [ColorModesEnum.light]: {
    name: 'Light',
    image: '/assets/illustration_light_mode.svg',
    colorMode: ColorModesEnum.light,
  },
  [ColorModesEnum.dark]: {
    name: 'Dark',
    image: '/assets/illustration_dark_mode.svg',
    colorMode: ColorModesEnum.dark,
  },
  [ColorModesEnum.system]: {
    name: 'System',
    image: '/assets/illustration_system_mode.svg',
    colorMode: ColorModesEnum.system,
  },
};

const ModeCard = ({
  image,
  name,
  selected,
  toggle,
}: {
  image: string;
  name: string;
  selected: boolean;
  toggle: () => void;
}) => {
  return (
    <VStack alignItems="start" gap="6px" cursor="pointer" onClick={toggle}>
      <Box
        outline={selected ? '2px solid' : 'none'}
        outlineColor={selected ? 'border.selected.accent' : ''}
        borderRadius="6px"
        overflow="hidden"
        transition="all 0.2s"
      >
        <Image
          src={image}
          transition="all 0.2s ease-in-out"
          _hover={{
            transform: 'scale(1.1)',
          }}
        />
      </Box>
      <Typography
        variant="bodyStrong"
        color={selected ? 'text.selected' : 'text.default'}
        transition="all 0.2s"
      >
        {name}
      </Typography>
    </VStack>
  );
};

export const DarkModeSwitch = () => {
  const { setColorMode, colorMode } = useColorMode();
  const systemColorMode = useColorModePreference();
  const [selectedMode, setSelectedMode] = useLocalStorage(
    'current-color-mode',
    ColorModesEnum.light
  );

  useEffect(() => {
    if (selectedMode === ColorModesEnum.system) {
      setColorMode(systemColorMode);
    } else {
      setColorMode(selectedMode || colorMode);
    }
  }, [selectedMode, colorMode, systemColorMode]);

  return (
    <HStack justifyContent="space-between" padding="8px" gap="6px">
      {Object.values(colorModes).map((mode) => (
        <ModeCard
          key={mode.colorMode}
          image={mode.image}
          name={mode.name}
          selected={selectedMode === mode.colorMode}
          toggle={() => {
            setColorMode(mode.colorMode);
            setSelectedMode(mode.colorMode);
          }}
        />
      ))}
    </HStack>
  );
};
