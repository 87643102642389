import React from 'react';

import { Input, InputProps } from 'Atoms';
import { SearchIcon } from 'Tokens/Icons/Function';

const SearchInputComponent = React.forwardRef(function SearchInput(
  {
    search,
    setSearch,
    placeholder,
    withLeftIcon = true,
    size = 'md',
    ...inputProps
  }: {
    search: string;
    setSearch: (s: string) => void;
    placeholder: string;
    withLeftIcon?: boolean;
  } & InputProps,
  ref: React.ForwardedRef<HTMLInputElement>
) {
  return (
    <Input
      leftIcon={
        withLeftIcon ? (
          <SearchIcon boxSize={size === 'sm' ? '12px' : '16px'} color="inherit" />
        ) : null
      }
      value={search}
      placeholder={placeholder}
      size={size}
      onChange={(e) => setSearch(e.target.value)}
      ref={ref}
      {...inputProps}
    />
  );
});

export const SearchInput = React.memo(SearchInputComponent);
