import {
  VStack,
  Box,
  Table as ChakraTable,
  Thead,
  Th,
  HStack,
  Tr,
  Td,
  Tbody,
} from '@chakra-ui/react';
import { ESRSNumberInput, TruncatableText } from 'Atoms';
import { HelpTooltip, Select } from 'Molecules';
import { Typography } from 'Tokens';
import { StepLabels } from 'containers/Esrs/EsrsUtilComponents';
import { Control, Controller, UseFormWatch } from 'react-hook-form';
import { ActionFields } from '../../Requirement';
import { GetTargetsQuery_ } from 'models';
import { RemoveIcon } from 'Tokens/Icons/Function';
import { Menu } from 'Molecules/Menu';
import { useRef } from 'react';

export const RelatedTargets = ({
  control,
  watch,
  targetOptions,
  targetsData,
}: {
  control: Control<ActionFields, any>;
  watch: UseFormWatch<ActionFields>;
  targetOptions: { value: string; label: string }[];
  targetsData: GetTargetsQuery_['targets'];
}) => {
  const selectRef = useRef<any>(null);

  const handleRemoveTarget = (target: string) => {
    selectRef?.current?.setValue([
      ...selectRef?.current
        ?.getValue()
        .filter((a: { value: string; label: string }) => a.value !== target),
    ]);
  };

  return (
    <Box
      position="relative"
      borderLeft="1px dashed"
      pl="32px"
      borderColor="border.hover"
      pb="40px"
      w="100%"
    >
      <StepLabels
        title="Related targets"
        description="Link this action to related targets"
        ml="33px"
      />
      <VStack w="100%" alignItems="start">
        <Controller
          name="targets"
          control={control}
          render={({ field: { onChange, value } }) => (
            <Select<{ value: string; label: string }, true>
              ref={selectRef}
              value={value}
              onChange={(val) => {
                onChange(val);
              }}
              options={targetOptions}
              size="md"
              isMulti
              placeholder="Select target"
            />
          )}
        />
        {watch('targets').length && (
          <Controller
            name="estimates"
            control={control}
            render={({ field: { onChange, value } }) => (
              <Box border="1px solid" borderColor="border.decorative" borderRadius="8px">
                <ChakraTable sx={{ tableLayout: 'fixed' }}>
                  <Thead>
                    <Th padding="16px 8px" textTransform="none" letterSpacing="normal">
                      <Typography variant="bodyStrong">Target</Typography>
                    </Th>
                    <Th padding="16px 8px" textTransform="none" letterSpacing="normal">
                      <HStack spacing="4px">
                        <Typography variant="bodyStrong">Add estimated effect</Typography>
                        <HelpTooltip
                          placement="bottom"
                          label="Add the estimated effect the action will have on the target."
                        />
                      </HStack>
                    </Th>
                    <Th padding="16px 8px" width="10%"></Th>
                  </Thead>
                  <Tbody>
                    {watch('targets').map((target, index) => (
                      <Tr key={index}>
                        <Td
                          padding="8px"
                          textAlign="right"
                          border={index === watch('targets').length - 1 ? 'none' : ''}
                        >
                          <TruncatableText
                            text={
                              targetsData.find((tl) => tl.id === target.value)?.name ?? target.label
                            }
                            variant="body"
                          />
                        </Td>
                        <Td
                          padding="8px"
                          textAlign="left"
                          border={index === watch('targets').length - 1 ? 'none' : ''}
                        >
                          <ESRSNumberInput
                            value={
                              !!value?.[`${target.value}`]
                                ? Number(value?.[`${target.value}`])
                                : undefined
                            }
                            onChange={(val) =>
                              onChange({
                                ...(watch('estimates') ?? {}),
                                [`${target.value}`]: val,
                              })
                            }
                            unit={
                              targetsData.find((td) => td.id === target.value)?.metric
                                ?.unitOfMeasurement ?? ''
                            }
                            width="155px"
                            height="28px"
                            size="sm"
                          />
                        </Td>
                        <Td
                          padding="8px"
                          textAlign="right"
                          border={index === watch('targets').length - 1 ? 'none' : ''}
                        >
                          <Menu
                            sections={[
                              {
                                actions: [
                                  {
                                    id: 'remove',
                                    title: 'Remove',
                                    variant: 'destructive',
                                    leftElement: <RemoveIcon color="inherit" />,
                                    onClick: () => handleRemoveTarget(target.value),
                                  },
                                ],
                              },
                            ]}
                          />
                        </Td>
                      </Tr>
                    ))}
                  </Tbody>
                </ChakraTable>
              </Box>
            )}
          />
        )}
      </VStack>
    </Box>
  );
};
