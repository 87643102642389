import { Box, VStack } from '@chakra-ui/react';
import { StepLabels } from 'containers/Esrs/EsrsUtilComponents';
import React, { useMemo } from 'react';
import { Control, Controller } from 'react-hook-form';
import { FormField, Input } from 'Atoms';
import { UserAssigner } from 'Organisms';
import { Select } from 'Molecules';
import { GetReportingUnitsQuery_, GetSubsidiariesQuery_ } from 'models';
import { ActionFields } from '../../Requirement';

export const ActionGeneralInformation = ({
  control,
  isReadOnly,
  isGroup,
  subsidiariesData,
  reportingUnits,
}: {
  control: Control<ActionFields, any>;
  isReadOnly: boolean;
  isGroup: boolean;
  subsidiariesData: NonNullable<GetSubsidiariesQuery_['esrs']>['subsidiaries'] | undefined;
  reportingUnits: GetReportingUnitsQuery_['reportingUnits'] | undefined;
}) => {
  const subsidiariesOptions = useMemo(
    () => [
      { label: 'All', value: 'All' },
      ...(subsidiariesData?.map((s) => ({
        label: s.company.name ?? '',
        value: s.id,
      })) ?? []),
    ],
    [subsidiariesData]
  );
  const reportingUnitsOptions = useMemo(
    () =>
      reportingUnits?.map((ru) => ({
        label: ru?.name ?? '',
        value: ru.id,
      })) ?? [],
    [reportingUnits]
  );
  return (
    <Box
      position="relative"
      borderLeft="1px dashed"
      pl="32px"
      borderColor="border.hover"
      pb="40px"
      w="100%"
    >
      <StepLabels title="General information" ml="33px" />
      <VStack spacing="20px" alignItems="start" width="100%">
        <Controller
          name="title"
          control={control}
          rules={{ required: true }}
          render={({ field: { onChange, value } }) => (
            <FormField id="name" label="Action name" isRequired={true} labelMarginBottom="6px">
              <Input
                width="100%"
                value={value}
                onChange={(e) => onChange(e.target.value)}
                placeholder="Placeholder"
                isDisabled={isReadOnly}
              />
            </FormField>
          )}
        />
        <Controller
          control={control}
          name="ownerId"
          rules={{ required: true }}
          render={({ field: { onChange, value } }) => (
            <Box width="200px">
              <FormField id="owner" label="Owner" isRequired={true} labelMarginBottom="6px">
                <UserAssigner
                  assignedTo={String(value)}
                  setAssignedTo={(id) => onChange(id)}
                  placeholder="Select owner"
                  isDisabled={isReadOnly}
                />
              </FormField>
            </Box>
          )}
        />
        {!isReadOnly &&
          (isGroup ? (
            <Controller
              name="subsidiaries"
              control={control}
              rules={{ required: true }}
              render={({ field: { onChange, value } }) => (
                <FormField
                  id="subsidiaries"
                  label="Subsidiaries"
                  isRequired={true}
                  labelMarginBottom="6px"
                >
                  <Select<{ value: string; label: string }, true>
                    value={value}
                    onChange={(val) => {
                      if (val.some((v) => v.value === 'All')) {
                        onChange(
                          subsidiariesData?.map((s) => ({
                            label: s.company.name ?? '',
                            value: s.id,
                          }))
                        );
                      } else onChange(val);
                    }}
                    options={subsidiariesOptions}
                    size="md"
                    isMulti
                    placeholder="Select subsidiaries"
                    isDisabled={isReadOnly}
                  />
                </FormField>
              )}
            />
          ) : (
            <Controller
              name="reportingUnits"
              control={control}
              rules={{ required: true }}
              render={({ field: { onChange, value } }) => (
                <FormField
                  id="businessUnits"
                  label="Business units"
                  isRequired={true}
                  labelMarginBottom="6px"
                >
                  <Select<{ value: string; label: string }, true>
                    value={value}
                    onChange={(val) => {
                      onChange(val);
                    }}
                    options={reportingUnitsOptions}
                    size="md"
                    isMulti
                    isDisabled={isReadOnly}
                  />
                </FormField>
              )}
            />
          ))}
      </VStack>
    </Box>
  );
};
