import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { IconButton, EmptyState } from 'Atoms';
import { Typography } from 'Tokens';
import {
  VStack,
  Box,
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Heading,
  Skeleton,
  HStack,
} from '@chakra-ui/react';
import { Drawer, SearchInput, Select } from 'Molecules';
import { useTranslation } from 'utils/translation';
import { useDebounce, useCurrentCompanyId } from 'utils/hooks';
import mixpanel from 'mixpanel-browser';
import { TRACKING_EVENTS } from '../../utils/mixpanel';
import {
  Article,
  Tag,
  useArticles,
  useArticleSearch,
  useArticlesWithTags,
} from './KnowledgeBase.hooks';
import { useSearchParams } from 'react-router-dom';
import { ArticleBox } from 'containers/Article';
import { CheckIcon } from 'Tokens/Icons/Status';
import { RefreshIcon } from 'Tokens/Icons/Function';
import { ChevronDownIcon, ChevronRightIcon } from 'Tokens/Icons/Direction';
import { ResourceIcon } from 'Tokens/Icons/Data';
import { GlobalContext } from 'containers/Navigation';

export const ArticleResultItem = ({ article }: { article: Article }) => {
  const handleClick = () => {
    mixpanel.track(TRACKING_EVENTS.KNOWLEDGE_BASE.ARTICLE_CLICK, {
      articleId: article.id,
      title: article.title,
    });
  };

  return (
    <AccordionItem
      width="100%"
      borderWidth="1px"
      borderColor="border.decorative"
      outlineColor="border.decorative"
      borderRadius="12px"
      flex="1"
    >
      {({ isExpanded }) => (
        <>
          <AccordionButton onClick={handleClick}>
            <HStack justifyContent="flex-start" spacing="14px" minH="60px" alignItems="center">
              {isExpanded ? (
                <ChevronDownIcon color="inherit" />
              ) : (
                <ChevronRightIcon color="inherit" />
              )}
              <Typography variant="h3" textAlign="start">
                {article.title}
              </Typography>
            </HStack>
          </AccordionButton>
          <AccordionPanel pb={4}>
            <ArticleBox showTitle={false} preLoadedArticle={article} margin={2} />
          </AccordionPanel>
        </>
      )}
    </AccordionItem>
  );
};

const Loader = () => {
  return (
    <Accordion width="100%">
      {[0, 1, 2, 3].map((i) => (
        <AccordionItem
          width="100%"
          key={i}
          marginBottom="md"
          borderWidth="1px"
          borderColor="secondary.main"
          outlineColor="secondary.main"
          borderRadius="4px"
          flex="1"
        >
          <Box bg="#E0E0E0" w="100%" p={4} color="white"></Box>
        </AccordionItem>
      ))}
    </Accordion>
  );
};

const getAccordion = (
  articlesToShow: Article[],
  isLoading: boolean,
  setSearchValue: (s: string) => void
) => {
  const { t } = useTranslation('common');

  if (!isLoading) {
    return (
      <Accordion
        as={VStack}
        allowToggle
        allowMultiple
        width="100%"
        defaultIndex={articlesToShow.length === 1 ? 0 : undefined}
        spacing="12px"
      >
        {articlesToShow.length > 0 &&
          articlesToShow.map((i) => <ArticleResultItem key={i.id} article={i} />)}
        {!isLoading && articlesToShow.length === 0 && (
          <Box w="100%" h="100%" flexGrow="1">
            <EmptyState
              title={t('common:search.filter.emptyTitle')}
              description={t('common:search.filter.emptyDescription')}
              callToAction={{
                text: t('common:search.filter.emptyBtn'),
                variant: 'secondary',
                onClick: () => {
                  setSearchValue('');
                },
                leftIcon: <RefreshIcon color="inherit" />,
              }}
              component={true}
            />
          </Box>
        )}
      </Accordion>
    );
  }
  return <Loader />;
};

export const KNOWLEDGE_BASE_SEARCH_PARAMS = 'withKnowledgeBase';
export const KNOWLEDGE_BASE_ACRTICLE_SEARCH_PARAMS = 'article';
export const KNOWLEDGE_BASE_SLUG_SEARCH_PARAMS = 'slug';

export const useKnowledgeBase = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const { isOpen, currentArticle, articleTitle } = useMemo(() => {
    const knowledgeBaseOpen = searchParams.get(KNOWLEDGE_BASE_SEARCH_PARAMS) === 'true';
    const slug = searchParams.get(KNOWLEDGE_BASE_SLUG_SEARCH_PARAMS);
    const articleTitleParam = searchParams.get(KNOWLEDGE_BASE_ACRTICLE_SEARCH_PARAMS);
    return {
      isOpen: knowledgeBaseOpen,
      currentArticle: slug,
      articleTitle: articleTitleParam,
    };
  }, [searchParams]);

  const onOpen = useCallback(
    (slug?: string, articleId?: string) => {
      setSearchParams(
        {
          [KNOWLEDGE_BASE_SEARCH_PARAMS]: 'true',
          [KNOWLEDGE_BASE_SLUG_SEARCH_PARAMS]: slug ? slug : '',
          ...(articleId
            ? { [KNOWLEDGE_BASE_ACRTICLE_SEARCH_PARAMS]: articleId }
            : { [KNOWLEDGE_BASE_ACRTICLE_SEARCH_PARAMS]: '' }),
        },
        { replace: true }
      );
    },
    [searchParams, setSearchParams]
  );

  const resetSearchParams = useCallback(() => {
    setSearchParams(
      {
        [KNOWLEDGE_BASE_SEARCH_PARAMS]: 'true',
        [KNOWLEDGE_BASE_SLUG_SEARCH_PARAMS]: '',
        [KNOWLEDGE_BASE_ACRTICLE_SEARCH_PARAMS]: '',
      },
      { replace: true }
    );
  }, [searchParams, setSearchParams]);

  const onClose = useCallback(() => {
    setSearchParams(
      {
        [KNOWLEDGE_BASE_SEARCH_PARAMS]: '',
      },
      { replace: true }
    );
  }, [searchParams, setSearchParams]);

  return {
    isOpen,
    onOpen,
    onClose,
    currentArticle,
    articleTitle,
    resetSearchParams,
  };
};

type TagOption = {
  value: string;
  label: string;
  description: string;
};

const TopicSelector = ({
  selected,
  setSelected,
  tags,
  loading,
}: {
  selected: string;
  setSelected: (val?: string) => void;
  tags: Tag[];
  loading: boolean;
}) => {
  const { t } = useTranslation('common');

  const tagOptions: TagOption[] = tags.map((tag) => {
    return {
      value: tag.id,
      label: tag.name,
      description: tag.description,
    };
  });

  if (loading) return <Skeleton width="300px" isLoaded={!loading} height="32px" />;
  return (
    <Select<TagOption>
      value={tagOptions.find((x) => x.value === selected)}
      onChange={(newVal) => setSelected(newVal?.value ?? undefined)}
      options={[
        {
          value: '',
          label: t('common:words.all'),
          description: t('common:knowledgeBase.display'),
        },
        ...tagOptions,
      ]}
      size="sm"
      isSearchable={false}
      components={{
        Option: (props) => (
          <HStack
            {...props.innerProps}
            width="100%"
            justifyContent="space-between"
            alignItems="center"
            padding="8px 16px"
            cursor="pointer"
            _hover={{
              bg: 'bg.hover',
            }}
            onClick={(e) => props?.innerProps?.onClick?.(e)}
          >
            <VStack alignItems="flex-start">
              <Typography
                variant="bodyStrong"
                color={selected === props.data.value ? 'text.selected' : 'text.default'}
              >
                {props.data.label}
              </Typography>
              <Typography variant="detail" color="text.default">
                {props.data.description}
              </Typography>
            </VStack>
            <VStack color="text.selected">
              {selected === props.data.value && <CheckIcon color="inherit" />}
            </VStack>
          </HStack>
        ),
        Control: ({ innerProps, ...props }) => (
          <Box
            {...innerProps}
            borderColor={
              props.isFocused || props.menuIsOpen ? 'border.selected.accent' : 'border.default'
            }
            borderRadius="8px"
            borderWidth="1px"
            _hover={{
              borderColor:
                props.isFocused || props.menuIsOpen ? 'border.selected.accent' : 'border.hover',
            }}
          >
            <HStack justifyContent="space-between" paddingX="8px">
              {props.getValue()?.[0] && (
                <Typography variant="body">
                  Topic: {props.getValue()?.[0]?.label ?? t('common:words.all')}
                </Typography>
              )}{' '}
              <>{props.children}</>
            </HStack>
          </Box>
        ),
      }}
      placeholder={t('common:knowledgeBase.select')}
    />
  );
};

export function KnowledgeBase() {
  const { t } = useTranslation('common');

  const { onOpen, onClose, isOpen, currentArticle, articleTitle, resetSearchParams } =
    useKnowledgeBase();
  const { companyId } = useCurrentCompanyId();
  const [searchValue, setSearchValue] = useState('');
  const [activeTag, setActiveTag] = useState<string>('');
  const [loading, setLoading] = useState(false);
  const { helpCenterOpen, setHelpCenterOpen } = useContext(GlobalContext);

  useEffect(() => {
    if (articleTitle === '' || !articleTitle) setSearchValue('');
    else if (!!articleTitle?.length) setSearchValue(articleTitle);
  }, [articleTitle]);

  const debouncedQuery = useDebounce(searchValue, searchValue === articleTitle ? 0 : 1000);

  const { searched, searchIsLoading } = useArticleSearch(debouncedQuery);

  const { articles } = useArticles();
  const { tags, tagsAreLoading } = useArticlesWithTags();

  const articlesToShow = useMemo(() => {
    if (currentArticle) {
      return articles.filter((i) => i.slug === currentArticle);
    }
    if (searchValue !== '') {
      return searched;
    }
    if (activeTag !== '') {
      return tags.find((i) => i.id === activeTag)?.articles.map((i) => i.Article) ?? [];
    }
    return articles ?? [];
  }, [articles, searched, activeTag]);

  const setSearch = (input: { value: string }) => {
    resetSearchParams();
    setLoading(true);
    setSearchValue(input.value);
    setActiveTag('');
    setTimeout(() => setLoading(false), 1200);
  };

  useEffect(() => {
    if (helpCenterOpen) {
      onOpen();
    }
  }, [helpCenterOpen]);

  return (
    <>
      <Drawer
        onClose={() => {
          onClose();
          setHelpCenterOpen(false);
        }}
        isOpen={isOpen}
        size="lg"
        returnFocusOnClose={false}
        header={<Heading fontSize="2xl">{t('common:knowledgeBase.title')}</Heading>}
      >
        <VStack
          alignItems="flex-start"
          width="100%"
          spacing="md"
          onClick={(e) => {
            if ((e.target as HTMLElement).tagName == 'A') onClose();
          }}
        >
          <HStack width="100%" spacing="8px">
            <SearchInput
              search={searchValue}
              setSearch={(input) => setSearch({ value: input })}
              placeholder={t('common:knowledgeBase.search')}
              width="100%"
              size="md"
              onFocus={() => {
                mixpanel.track(TRACKING_EVENTS.KNOWLEDGE_BASE.SEARCH, {
                  companyId,
                  query: searchValue,
                });
              }}
            />

            <TopicSelector
              tags={tags ?? []}
              selected={activeTag ?? ''}
              setSelected={(val) => setActiveTag(val ?? '')}
              loading={tagsAreLoading}
            />
          </HStack>

          {getAccordion(articlesToShow, loading || searchIsLoading, setSearchValue)}
        </VStack>
      </Drawer>
      <IconButton
        variant="secondary"
        size="sm"
        icon={<ResourceIcon color="inherit" />}
        aria-label={t('common:knowledgeBase.title')}
        tooltipLabel={t('common:knowledgeBase.title')}
        onClick={() => {
          onOpen('');
          mixpanel.track(TRACKING_EVENTS.KNOWLEDGE_BASE.OPEN, {
            companyId,
            location: window.location.pathname,
          });
        }}
      />
    </>
  );
}
