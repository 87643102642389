import { VStack } from '@chakra-ui/react';
import { useUserData } from '@nhost/react';
import { FormField, Input } from 'Atoms';
import { useCreateInitialCompany } from 'containers/Navigation';
import { CompanyTypeObject, CompanyTypeSelector, CurrencyField } from 'containers/Settings/pieces';
import { CompanyInvitationsModal } from 'Features/CompanyInvitations';
import { Loader, Modal } from 'Molecules';
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import { useToast } from 'utils/hooks';
import { nhost } from 'utils/nhost';
import { useTranslation } from 'utils/translation';
import { hasSalesOrSupportRole } from 'utils/users';

type CompanyFields = {
  name: string;
  currency: string;
  companyTypes: CompanyTypeObject;
};

export const CompanyRegisterModal = ({
  isOpen,
  onClose,
  header,
}: {
  isOpen: boolean;
  onClose?: () => void;
  header?: string;
}) => {
  const toast = useToast();
  const user = useUserData();
  const createInitialCompany = useCreateInitialCompany();
  const { t } = useTranslation('common');
  const [searchParams] = useSearchParams();
  const invitationId = searchParams.get('invitationId');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const {
    control,
    formState: { isValid },
    handleSubmit,
  } = useForm<CompanyFields>();

  const onSubmit = (company: CompanyFields) => {
    if (!isLoading) {
      setIsLoading(true);
      if (!hasSalesOrSupportRole(user?.roles)) {
        return;
      }
      createInitialCompany(
        user?.id ?? '',
        company.name,
        company.companyTypes.isPortfolioOwner,
        company.companyTypes.isGroupOwner,
        company.currency
      )
        .then(() => {
          setIsLoading(false);
          nhost.auth.refreshSession();
          location.reload();
        })
        .catch(() =>
          toast({
            text: `${t('common:toast.companyCreateFailed.title')} 
          ${t('common:toast.companyCreateFailed.description')}`,
            variant: 'danger',
          })
        );
    }
  };

  if (!user) return <Loader />;
  if (invitationId)
    return (
      <CompanyInvitationsModal
        invitationId={invitationId}
        onClose={() => onClose?.()}
        user={user}
      />
    );
  return (
    <Modal
      isOpen={isOpen}
      size="xl"
      title={header ?? t('common:companyModal.createCompany')}
      onClose={() => onClose?.()}
      confirmText={t('common:companyModal.createCompany')}
      confirmButtonProps={{ type: 'submit', form: 'company-form', isDisabled: !isValid, isLoading }}
    >
      <form onSubmit={handleSubmit(onSubmit)} id="company-form">
        <VStack spacing="32px" pt="8px">
          <Controller
            name="name"
            control={control}
            render={({ field: { onChange, value }, formState: { isValid: isFieldValid } }) => (
              <FormField
                label={t('common:fields.companyName.label')}
                isInvalid={!isFieldValid}
                id="name"
                isRequired
              >
                <Input
                  value={value}
                  placeholder={t('common:fields.companyName.placeholder')}
                  onChange={(e) => onChange(e.currentTarget.value)}
                  width="50%"
                />
              </FormField>
            )}
          />
          <Controller
            name="currency"
            control={control}
            render={({ field: { onChange, value } }) => (
              <CurrencyField value={value} setValue={onChange} />
            )}
          />
          <Controller
            name="companyTypes"
            control={control}
            render={({ field: { onChange } }) => <CompanyTypeSelector setValue={onChange} />}
          />
        </VStack>
      </form>
    </Modal>
  );
};
