import { useTranslation, tenum } from 'utils/translation';
import { FormField } from 'Atoms';
import React from 'react';
import { Select } from 'Molecules';
import { Box, FormLabel, HStack, VStack } from '@chakra-ui/react';
import { Typography } from 'Tokens';

// ADD CURRENCIES HERE
export const CURRENCIES = [
  tenum('common:fields.currency.NOK'),
  tenum('common:fields.currency.EUR'),
  tenum('common:fields.currency.USD'),
  tenum('common:fields.currency.GBP'),
  tenum('common:fields.currency.SEK'),
  tenum('common:fields.currency.DKK'),
  tenum('common:fields.currency.CHF'),
  tenum('common:fields.currency.PLN'),
  tenum('common:fields.currency.CZK'),
  tenum('common:fields.currency.HUF'),
  tenum('common:fields.currency.TRY'),
  tenum('common:fields.currency.BGN'),
  tenum('common:fields.currency.HRK'),
  tenum('common:fields.currency.RON'),
  tenum('common:fields.currency.INR'),
];

export function CurrencyField({
  value,
  setValue,
}: {
  value: string;
  setValue: (newVal: string) => void;
}) {
  const { t } = useTranslation('common');
  return (
    <FormField id="currency" isRequired>
      <HStack w="100%" justifyContent="space-between">
        <VStack alignItems="start" gap="2px">
          <FormLabel mb="0px">{t('common:fields.currency.label')}</FormLabel>
          <Typography variant="detail">{t('common:fields.currency.label_long')}</Typography>
        </VStack>
        <Box width="30%">
          <Select
            value={{
              value,
              label: CURRENCIES.includes(value as any)
                ? t('common:fields.currency.' + value)
                : value,
            }}
            onChange={(v: any) => v && setValue(v.value)}
            isSearchable={true}
            isMulti={false}
            options={
              CURRENCIES.map((v) => ({
                value: v as string,
                label: t('common:fields.currency.' + v) as string,
              })) as [{ value: string; label: string }]
            }
            size="md"
          />
        </Box>
      </HStack>
    </FormField>
  );
}
