import { Box, FormLabel, HStack, VStack } from '@chakra-ui/react';
import { AutoResizeTextarea, FormField } from 'Atoms';
import { Typography } from 'Tokens';

export const ToneOfVoiceField = ({
  value,
  onChange,
}: {
  value: string;
  onChange: (...event: any[]) => void;
}) => {
  return (
    <FormField id="preferredLanguage">
      <HStack w="100%" spacing="16px" alignItems="start">
        <VStack alignItems="start" gap="2px">
          <FormLabel mb="0px">Tone of voice</FormLabel>
          <Typography variant="detail">
            Add a text example from one of your company’s previous reports to help fine-tune the
            tone of the AI-generated answers. We suggest, for instance, copying a paragraph or two
            from last year’s sustainability report
          </Typography>
        </VStack>
        <Box w="356px">
          <AutoResizeTextarea
            onChange={onChange}
            value={value}
            placeholder="Add your text example"
            minW="356px"
            maxW="356px"
            minH="76px"
          />
        </Box>
      </HStack>
    </FormField>
  );
};
