import { VStack } from '@chakra-ui/react';
import { FC, PropsWithChildren } from 'react';
import { Typography } from 'Tokens';

export const MetricChartMenuBox: FC<PropsWithChildren<{ title: string }>> = ({
  title,
  children,
}) => {
  return (
    <VStack
      spacing="8px"
      p="16px"
      alignItems="flex-start"
      w="100%"
      bgColor="bg.muted"
      border="1px solid"
      borderColor="border.decorative"
      borderRadius="8px"
    >
      <Typography variant="bodyStrong">{title}</Typography>
      {children}
    </VStack>
  );
};
