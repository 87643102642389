import { Box } from '@chakra-ui/react';
import { EmptyState } from 'Atoms';
import {
  GroupAssessmentQuery_,
  useCompanyAssessmentQuery,
  useInvestorsQuery,
} from 'models/__generated__/graphql';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { EUTaxonomyIllustration } from 'Tokens';
import { useTranslation } from 'utils/translation';
import { Loader } from 'Molecules';
import { Results } from 'Features/Results';
import { BusinessUnitsSortingType } from 'containers/Assessments/pieces/Assessment.hooks';
import { useCurrentCompanyId } from 'utils/hooks';

export const ConsolidatedResults = ({
  assessment,
}: {
  assessment: GroupAssessmentQuery_['assessment'];
}) => {
  const { t } = useTranslation('group');
  const { companyId } = useCurrentCompanyId();
  const navigate = useNavigate();

  const { data: companyAssessment, loading } = useCompanyAssessmentQuery({
    variables: {
      cAssessmentId: assessment?.consolidatedCompanyAssessmentId ?? '',
    },
    skip: !assessment?.consolidatedCompanyAssessmentId,
  });

  const cAssessment = useMemo(() => {
    return companyAssessment?.companyAssessment;
  }, [companyAssessment, assessment]);

  const { data: investorsData } = useInvestorsQuery({
    variables: {
      companyId,
    },
    skip: !companyId,
  });

  const investors = useMemo(() => {
    return investorsData?.investors ?? [];
  }, [investorsData]);

  if (loading) return <Loader isLoaded={!loading} />;

  const businessUnitsSorting: BusinessUnitsSortingType = {
    sortAlphabetically: true,
    groupsFirst: true,
    setSortAlphabetically: function (): void {},
    setGroupsFirst: function (): void {},
    toggleSortingAlphabetically: function (): void {},
    toggleSortingGroupsFirst: function (): void {},
  };

  if (!!cAssessment?.cachedResultId) {
    return (
      <Results
        cAssessment={cAssessment}
        isGroup={true}
        businessUnitsSorting={businessUnitsSorting}
        investors={investors}
      />
    );
  }

  return (
    <Box w="100%" flexGrow="1" pt="16px">
      <EmptyState
        title={t('results.empty.title')}
        description={t('results.empty.description')}
        callToAction={{
          text: t('results.empty.action'),
          variant: 'secondary',
          onClick: () => {
            navigate('financials');
          },
        }}
        icon={<EUTaxonomyIllustration boxSize="120px" color="border.default" />}
      />
    </Box>
  );
};
