import { ActivityReport, COMPANY_LEVEL, TableSpecificDataType } from './History.hooks';
import { formatTimestamp } from 'Molecules/HistoryItem/HistoryHelpers';
import { AuditLogEntryFieldsFragment_, CompanyAssessmentContextQuery_ } from 'models';
import { isArray } from 'lodash';
import { areArraysOfStringEqual } from 'utils/strings';

export const taxonomyAuditLogsCases = ({
  contextData,
  auditLog,
}: {
  contextData: CompanyAssessmentContextQuery_;
  auditLog: AuditLogEntryFieldsFragment_;
}): TableSpecificDataType => {
  const { table, newData, timestamp, oldData } = auditLog;
  const context = contextData?.company;
  const portfolioCompaniesContext = contextData?.portfolioCompanies;
  let contextualInfo = '';
  let metaInfo: { [key: string]: string } = {};
  let dataChanges = null;
  let operation =
    auditLog?.operation === 'UPDATE' && oldData?.deletedAt === null && newData?.deletedAt !== null
      ? 'DELETE'
      : auditLog?.operation;
  let withReplacement = false;
  let isSkipped = false;

  switch (table) {
    case 'Answer': {
      dataChanges = [];
      const answerContext = context?.bAssesssments?.find((bAssessment) =>
        bAssessment?.activityReports?.some((activityReport) =>
          activityReport?.answers?.some((answer) => answer?.id === newData.id)
        )
      );

      const answer = answerContext?.activityReports
        ?.flatMap((activityReport) => activityReport.answers || [])
        .find((answer2) => answer2?.id === newData.id);
      contextualInfo = answer?.question?.title ?? '';

      const activityReport =
        answerContext?.activityReports?.find((ar) =>
          ar?.answers?.some((answ) => answ.id === newData.id)
        ) ?? ({} as ActivityReport);

      dataChanges.push({
        oldData: isArray(oldData?.data) ? oldData?.data.join(', ') : oldData?.data,
        newData: isArray(newData?.data) ? newData?.data.join(', ') : newData?.data,
        questionType: answer?.question?.type,
      });

      metaInfo = {
        timestamp: formatTimestamp(timestamp),
        location: 'Screening',
        level: answerContext?.businessUnit?.name ?? COMPANY_LEVEL,
        businessUnit: activityReport?.activity?.name,
      };
      break;
    }
    case 'BusinessUnit': {
      dataChanges = [];
      const businessUnitContextualInfo = [];
      const businessUnitContext = context?.bAssesssments?.find(
        (bAssessment) => bAssessment?.businessUnit?.id === newData.id
      );
      const reportingGroupName = businessUnitContext?.reportingGroup?.name;

      const nameChanged = oldData?.name !== newData?.name;
      const labelsChanged = !areArraysOfStringEqual(oldData?.labels, newData?.labels);

      if (nameChanged) {
        businessUnitContextualInfo.push('Name');
        dataChanges.push({
          oldData: oldData?.name,
          newData: newData?.name,
        });
      }
      if (labelsChanged) {
        businessUnitContextualInfo.push('Labels');
        dataChanges.push({
          oldData: oldData?.labels.join(', '),
          newData: newData?.labels.join(', '),
        });
      }

      contextualInfo = businessUnitContextualInfo.join(', ');

      metaInfo = {
        timestamp: formatTimestamp(timestamp),
        location: reportingGroupName ? `Reporting group: ${reportingGroupName}` : COMPANY_LEVEL,
      };
      break;
    }
    case 'BusinessUnitAssessment': {
      dataChanges = [];
      const businessUnitAssessmentContext = context?.bAssesssments?.find(
        (bAssessment) => bAssessment?.id === newData.id
      );
      const reportingGroups = context?.reportingGroups;

      const levelChanged = oldData?.reportingGroupId !== newData?.reportingGroupId;

      const deletedAtChanged = oldData?.deletedAt !== newData?.deletedAt;
      const businessUnitName = businessUnitAssessmentContext?.businessUnit?.name;
      const reportingGroupName = businessUnitAssessmentContext?.reportingGroup?.name;
      contextualInfo = (businessUnitName ?? '') + (levelChanged ? "'s location:" : '');

      if (levelChanged) {
        dataChanges.push({
          oldData:
            reportingGroups?.find(
              (reportingGroup) => reportingGroup?.id === oldData.reportingGroupId
            )?.name || COMPANY_LEVEL,
          newData:
            reportingGroups?.find(
              (reportingGroup) => reportingGroup?.id === newData.reportingGroupId
            )?.name || COMPANY_LEVEL,
        });
      }
      if (deletedAtChanged) {
        dataChanges.push({
          oldData: businessUnitName,
          newData: null,
        });
      }

      metaInfo = {
        timestamp: formatTimestamp(timestamp),
        location: reportingGroupName ? `Reporting group: ${reportingGroupName}` : COMPANY_LEVEL,
      };

      break;
    }
    case 'ReportingGroup': {
      dataChanges = [];
      const reportingGroupContext = context?.reportingGroups?.find(
        (reportingGroup) => reportingGroup?.id === newData?.id
      );
      contextualInfo = `Location: ${reportingGroupContext?.parent?.name ?? COMPANY_LEVEL}`;

      dataChanges.push({
        oldData: oldData?.name,
        newData: newData?.name,
      });

      metaInfo = {
        timestamp: formatTimestamp(timestamp),
      };
      break;
    }
    case 'CompanyAssessmentAggregate': {
      dataChanges = [];
      const companyAssessmentContextualInfo = [];
      const companyUsers = context?.company?.users;

      const titleChanged = oldData?.title !== newData?.title;
      const startDateChanged = oldData?.startDate !== newData?.startDate;
      const contactPersonChanged = oldData?.contactPersonId !== newData?.contactPersonId;

      if (titleChanged) {
        companyAssessmentContextualInfo.push('Title');
        dataChanges.push({
          oldData: oldData?.title,
          newData: newData?.title,
        });
      }
      if (startDateChanged) {
        companyAssessmentContextualInfo.push('Reporting period');
        dataChanges.push({
          oldData: new Date(oldData?.startDate).getFullYear(),
          newData: new Date(newData?.startDate).getFullYear(),
        });
      }
      if (contactPersonChanged) {
        companyAssessmentContextualInfo.push('Contact person');
        dataChanges.push({
          oldData: companyUsers?.find(
            (companyUser) => companyUser.user.id === oldData?.contactPersonId
          )?.user.displayName,
          newData: companyUsers?.find(
            (companyUser) => companyUser.user.id === newData?.contactPersonId
          )?.user.displayName,
        });
      }
      contextualInfo = companyAssessmentContextualInfo.join(', ');

      metaInfo = {
        timestamp: formatTimestamp(timestamp),
        location: !titleChanged ? oldData?.title : '',
      };

      break;
    }
    case 'ActivityReport': {
      const activityContext = context?.bAssesssments?.find((bAssessment) =>
        bAssessment?.activityReports?.some(
          (activityReport) => activityReport?.activityRef === newData.activityRef
        )
      );
      const bAssessmentContext = context?.bAssesssments?.find(
        (bAssessment) => bAssessment?.id === newData?.bAssessmentId
      );

      if (bAssessmentContext?.hasGeneralAssessment === false && newData?.activityRef === '0.0') {
        isSkipped = true;
        break;
      }

      const activityName = activityContext?.activityReports?.find(
        (activityReport) => activityReport?.activityRef === newData.activityRef
      )?.activity?.name;
      contextualInfo = activityName ?? '';

      metaInfo = {
        timestamp: formatTimestamp(timestamp),
        location: 'Screening',
        level: activityContext?.businessUnit?.name ?? COMPANY_LEVEL,
      };
      break;
    }
    case 'ReportDownloads': {
      contextualInfo = 'EU Taxonomy report';

      metaInfo = {
        timestamp: formatTimestamp(timestamp),
        location: 'Results',
      };
      break;
    }
    case 'Financials': {
      dataChanges = [];
      const financialsContextualInfo = [];
      const financialsContext = context?.bAssesssments?.find((bAssessment) =>
        bAssessment?.activityReports?.some((ar) => ar?.id === newData.reportId)
      );

      const activityContext = financialsContext?.activityReports?.find(
        (activityReport) => activityReport?.id === newData.reportId
      );

      const opexChanged = oldData?.opex !== newData?.opex;
      const capexChanged = oldData?.capex !== newData?.capex;
      const revenueChanged = oldData?.revenue !== newData?.revenue;
      const adaptationCapexChanged = oldData?.adaptationCapex !== newData?.adaptationCapex;
      const adaptationOpexChanged = oldData?.adaptationOpex !== newData?.adaptationOpex;

      if (opexChanged) {
        financialsContextualInfo.push('Opex');
        dataChanges.push({
          oldData: oldData?.opex,
          newData: newData?.opex,
        });
      }
      if (capexChanged) {
        financialsContextualInfo.push('Capex');
        dataChanges.push({
          oldData: oldData?.capex,
          newData: newData?.capex,
        });
      }
      if (revenueChanged) {
        financialsContextualInfo.push('Turnover');
        dataChanges.push({
          oldData: oldData?.revenue,
          newData: newData?.revenue,
        });
      }
      if (adaptationCapexChanged) {
        financialsContextualInfo.push('adaptationCapex');
        dataChanges.push({
          oldData: oldData?.adaptationCapex,
          newData: newData?.adaptationCapex,
        });
      }
      if (adaptationOpexChanged) {
        financialsContextualInfo.push('adaptationOpex');
        dataChanges.push({
          oldData: oldData?.adaptationOpex,
          newData: newData?.adaptationOpex,
        });
      }
      contextualInfo = financialsContextualInfo.join(', ');

      metaInfo = {
        timestamp: formatTimestamp(timestamp),
        location: financialsContext
          ? financialsContext?.businessUnit?.name ?? 'Financial summary'
          : 'Total in the financial statement',
        activity:
          (activityContext?.activityRef === '0.0' ? 'Total' : activityContext?.activity?.name) ??
          '',
      };
      break;
    }
    case 'PortfolioCompany': {
      withReplacement = true;

      const portfolioCompanyContext = portfolioCompaniesContext?.find(
        (portfolio) => portfolio?.id === newData?.id
      );
      const portfolioCompanyName = portfolioCompanyContext?.portfolio?.ownerCompany?.name;
      const portfolioName = portfolioCompanyContext?.portfolio?.name;
      const portfolioYear = portfolioCompanyContext?.year;
      const portfolioInfoAvailable = !!portfolioCompanyName && !!portfolioName && !!portfolioYear;

      const oldPortfolioCompanyContext = portfolioCompaniesContext?.find(
        (portfolio) => portfolio?.id === oldData?.id
      );
      const oldPortfolioCompanyName = oldPortfolioCompanyContext?.portfolio?.ownerCompany?.name;
      const oldPortfolioName = oldPortfolioCompanyContext?.portfolio?.name;
      const oldPortfolioYear = oldPortfolioCompanyContext?.year;
      const oldPortfolioInfoAvailable =
        !!oldPortfolioCompanyName && !!oldPortfolioName && !!oldPortfolioYear;

      if (!newData?.sharedCompanyAssessmentId) {
        operation = 'UNSHARE';
        contextualInfo = oldPortfolioInfoAvailable
          ? ` ${oldPortfolioCompanyName} , ${oldPortfolioName} - ${oldPortfolioYear} `
          : 'unavailable';
      } else {
        operation = 'SHARE';
        contextualInfo = portfolioInfoAvailable
          ? ` ${portfolioCompanyName} , ${portfolioName} - ${portfolioYear} `
          : 'unavailable';
      }

      metaInfo = {
        timestamp: formatTimestamp(timestamp),
      };
      break;
    }
  }
  return { contextualInfo, metaInfo, dataChanges, operation, withReplacement, isSkipped };
};
