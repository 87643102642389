import { Box, HStack, VStack } from '@chakra-ui/react';
import { DateInput } from 'Atoms';
import { Typography } from 'Tokens';
import { StepLabels } from 'containers/Esrs/EsrsUtilComponents';
import { Control, Controller, UseFormWatch } from 'react-hook-form';
import { Select } from 'Molecules';
import { ActionFields } from '../../Requirement';
import { timeHorizonOptions } from '../../DisclosureRequirements.hooks';

export const ActionTimeline = ({
  control,
  watch,
  isReadOnly,
}: {
  control: Control<ActionFields, any>;
  watch: UseFormWatch<ActionFields>;
  isReadOnly: boolean;
}) => {
  return (
    <Box
      position="relative"
      borderLeft="1px dashed"
      pl="32px"
      borderColor="border.hover"
      pb="40px"
      w="100%"
    >
      <StepLabels title="Timeline" ml="33px" />
      <VStack spacing="20px" alignItems="start" width="100%">
        <VStack spacing="6px" alignItems="start">
          <Typography variant="bodyStrong">Time horizon for completion of action</Typography>
          <Box w="200px" zIndex={2}>
            <Controller
              name="timeHorizon"
              control={control}
              render={({ field: { value, onChange } }) => (
                <Select<{ value: string; label: string }>
                  value={timeHorizonOptions.find((opt) => opt.value === value)}
                  options={timeHorizonOptions}
                  onChange={(v) => onChange(v?.value)}
                  placeholder="Select"
                  isDisabled={isReadOnly}
                />
              )}
            />
          </Box>
        </VStack>
        <HStack alignItems="end" w="100%">
          <Box w="200px">
            <Controller
              name="startDate"
              control={control}
              render={({ field }) => (
                <VStack spacing="6px" w="100%" alignItems="start">
                  <Typography variant="bodyStrong">Start date</Typography>
                  <DateInput
                    width="200px"
                    value={field.value}
                    setValue={(date) => field.onChange(date)}
                    disabled={isReadOnly}
                    showMonthDropdown
                    showYearDropdown
                  />
                </VStack>
              )}
            />
          </Box>
          <Box w="200px">
            <Controller
              name="dueDate"
              control={control}
              render={({ field }) => (
                <VStack spacing="6px" w="100%" alignItems="start">
                  <Typography variant="bodyStrong">Due date</Typography>
                  <DateInput
                    width="200px"
                    value={field.value}
                    setValue={(date) => field.onChange(date)}
                    disabled={isReadOnly}
                    minDate={watch('startDate')}
                    showMonthDropdown
                    showYearDropdown
                  />
                </VStack>
              )}
            />
          </Box>
        </HStack>
      </VStack>
    </Box>
  );
};
