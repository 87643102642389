import { Box, HStack } from '@chakra-ui/react';
import { Infobox } from 'Atoms/Infobox';
import { TodoIcon } from 'Tokens/Icons/Custom';
import { CheckIcon } from 'Tokens/Icons/Status';
import { HomePageLogoDesignIllustration } from 'Tokens/Illustrations';
import { Typography } from 'Tokens/Typography';
import { useUserSetting } from 'containers/Navigation';
import React from 'react';
import { useTranslation } from 'utils/translation';

const DoneStatus = () => {
  const { t } = useTranslation('home');
  return (
    <HStack>
      <CheckIcon color="text.info" />
      <Typography variant="bodyStrong" color="text.info">
        {t('common:buttons.done')}
      </Typography>
    </HStack>
  );
};

const TodoStatus = () => {
  const { t } = useTranslation('home');
  return (
    <HStack>
      <TodoIcon color="text.muted" />
      <Typography variant="bodyStrong" color="text.muted">
        {t('common:buttons.todo')}
      </Typography>
    </HStack>
  );
};

export const HomeWelcomeBox = ({
  isWelcomeModalClosed,
  onToggleWelcomeModal,
  hasCommunityAccess,
}: {
  isWelcomeModalClosed: boolean;
  onToggleWelcomeModal: (value: boolean) => void;
  hasCommunityAccess: boolean;
}) => {
  const { t } = useTranslation('home');
  const [hasAcceptedCommunityTerms] = useUserSetting<string | null>(
    'has-accepted-community-terms',
    null
  );

  return (
    <Infobox
      withIcon={false}
      title={t('home:welcomeModal.title')}
      description={t('home:welcomeModal.description')}
      status="info"
      isVisible={!isWelcomeModalClosed}
      onClose={() => onToggleWelcomeModal(true)}
      marginBottom="48px"
      padding="24px 0px 24px 28px"
      height="186px"
      textWidth="586px"
      spacing="0px"
      rightElement={
        <Box position="absolute" right="-1px" top="0">
          <HomePageLogoDesignIllustration boxSize="186px" />
        </Box>
      }
      extra={hasAcceptedCommunityTerms && hasCommunityAccess ? <DoneStatus /> : <TodoStatus />}
    />
  );
};
