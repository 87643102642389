import {
  AttachmentBox,
  AttachmentBox_Constraint_,
  AttachmentBox_Update_Column_,
  GetDisclosureRequirementAttachmentDocument_,
  useGetDisclosureRequirementAttachmentQuery,
  useUpsertDisclosureRequirementAttachmentMutation,
} from 'models';
import { useMemo } from 'react';

export const useDisclosureRequirementAttachment = ({
  materialStandardId,
  reportingUnitId,
  disclosureRequirementRef,
}: {
  materialStandardId: string;
  reportingUnitId: string;
  disclosureRequirementRef: string;
}) => {
  const [upserDRtAttachmentMutation] = useUpsertDisclosureRequirementAttachmentMutation();

  const { data: documentationData, loading: attachmentDataLoading } =
    useGetDisclosureRequirementAttachmentQuery({
      variables: {
        disclosureRequirementRef: disclosureRequirementRef,
        materialStandardId: materialStandardId,
        reportingUnitId: reportingUnitId,
      },
      skip: !disclosureRequirementRef || !materialStandardId || !reportingUnitId,
    });

  const attachmentBox: AttachmentBox | undefined = useMemo(
    () => documentationData?.esrs_DisclosureRequirementAttachment[0]?.attachmentBox ?? undefined,
    [documentationData]
  );

  const upsertDRAttachment = () =>
    upserDRtAttachmentMutation({
      variables: {
        objects: {
          disclosureRequirementRef,
          materialStandardId: materialStandardId,
          reportingUnitId: reportingUnitId,
          attachmentBox: {
            data: {},
            on_conflict: {
              constraint:
                AttachmentBox_Constraint_.AttachmentBoxDisclosureRequirementAttachmentIdKey_,
              update_columns: [AttachmentBox_Update_Column_.DisclosureRequirementAttachmentId_],
            },
          },
        },
      },
      refetchQueries: [GetDisclosureRequirementAttachmentDocument_],
    });

  return {
    attachmentBox,
    attachmentDataLoading,
    upsertDRAttachment,
  };
};
