import { Modal, Select } from 'Molecules';
import {
  DataCollectionLevel,
  GROUP_DATA_GATHERING_OPTIONS,
  SUBSIDIARY_DATA_GATHERING_OPTIONS,
  FREQUENCY_OPTIONS,
  TableMetricData,
} from '../DataCollection.d';
import { Box, ListItem, UnorderedList, VStack } from '@chakra-ui/react';
import { Controller, useForm, useFormState } from 'react-hook-form';
import { MaterialMetricFields } from './MetricConfigModalParent';
import { FormField, Infobox } from 'Atoms';
import {
  useAssessmentReportingUnits,
  useMaterialStandardId,
} from 'containers/Esrs/EsrsAssessment.hooks';
import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  GetMetricsDrDocument_,
  EsrsAssessmentDocument_,
  MaterialMetricsPerDisclosureDocument_,
  QuestionType_Enum_,
  ReportingUnitsMetricsPerDisclosureDocument_,
  useUpdateSubsidiariesMaterialityMutation,
  useUpsertMaterialMetricsMutation,
  GetRequiredBuMaterialMetricsDocument_,
  GetRequiredMaterialMetricsDocument_,
} from 'models';
import { useToast } from 'utils/hooks';
import { getFlattenedMaterialMetrics } from '../DataCollection.hooks';
import { FrequencyEnums } from '../..';
import { Typography } from 'Tokens';

export const BulkEditModal = ({
  selectedRows,
  isOpen,
  onClose,
  isGroup,
}: {
  selectedRows: TableMetricData[];
  isOpen: boolean;
  onClose: () => void;
  isGroup: boolean;
}) => {
  const { esrsAssessmentId = '', standardRef = '' } = useParams();
  const [showAll, setShowAll] = useState(false);

  const { companyAssessmentId: materialStandardId } = useMaterialStandardId(
    standardRef,
    esrsAssessmentId
  );

  const { reportingUnitAssessments: reportingUnits } =
    useAssessmentReportingUnits(esrsAssessmentId);
  const hasReportingUnits = useMemo(
    () => reportingUnits?.filter((ru) => ru.isCompanyLevel === false)?.length,
    [reportingUnits]
  );

  const metricsWithMultipleParents = useMemo(
    () => selectedRows.filter((row) => (row.parentMetrics.length ?? 0) > 1),
    [selectedRows]
  );

  const areAllRowsChildren = useMemo(
    () => selectedRows.every((row) => row.isChild),
    [selectedRows]
  );

  const [firstMetricDataCollection, firstMetricFrequency] = useMemo(() => {
    const firstSelectedMetric = selectedRows?.[0]?.materialMetrics.find(
      (mm) => mm.materialStandardId === materialStandardId
    );
    return [firstSelectedMetric?.dataCollection, firstSelectedMetric?.frequency];
  }, [selectedRows, materialStandardId]);

  const isDataCollectionMatching = useMemo(() => {
    return selectedRows.every(
      (row) =>
        row.materialMetrics.find((mm) => mm.materialStandardId === materialStandardId)
          ?.dataCollection === firstMetricDataCollection
    );
  }, [selectedRows, materialStandardId, firstMetricDataCollection]);

  const isFrequencyMatching = useMemo(() => {
    return selectedRows.every(
      (row) =>
        row.materialMetrics.find((mm) => mm.materialStandardId === materialStandardId)
          ?.frequency === firstMetricFrequency
    );
  }, [selectedRows, materialStandardId, firstMetricFrequency]);

  const [defaultDataCollectionLevel, defaultFrequency] = useMemo(() => {
    const dataCollection = isDataCollectionMatching ? firstMetricDataCollection : undefined;
    const frequency = isFrequencyMatching ? firstMetricFrequency : undefined;
    return [dataCollection, frequency];
  }, [
    selectedRows,
    isDataCollectionMatching,
    isFrequencyMatching,
    firstMetricDataCollection,
    firstMetricFrequency,
  ]);

  const toast = useToast();

  const [upsertMaterialMetric] = useUpsertMaterialMetricsMutation();
  const [updateSubsidiariesMateriality] = useUpdateSubsidiariesMaterialityMutation();

  const { control, handleSubmit, reset } = useForm<MaterialMetricFields>({
    defaultValues: {
      dataCollection: defaultDataCollectionLevel as DataCollectionLevel,
      frequency: defaultFrequency as FrequencyEnums,
    },
    mode: 'all',
    reValidateMode: 'onBlur',
  });

  const { isValid, errors } = useFormState({ control });

  const dataGatheringOptions = useMemo(() => {
    return isGroup ? GROUP_DATA_GATHERING_OPTIONS : SUBSIDIARY_DATA_GATHERING_OPTIONS;
  }, [isGroup]);

  const flattenedMaterialMetrics: {
    id: string;
    metricRef: string;
    materialStandardId: string;
    metricType: QuestionType_Enum_;
    isMaterial: boolean | null | undefined;
  }[] = useMemo(() => {
    return getFlattenedMaterialMetrics(selectedRows, materialStandardId);
  }, [selectedRows]);

  const [someSelectedMetricsAreNarrative, allSelectedMetricsAreNarrative] = useMemo(() => {
    return [
      selectedRows.some((row) => row.metricType === QuestionType_Enum_.LongText_),
      selectedRows.every((row) => row.metricType === QuestionType_Enum_.LongText_),
    ];
  }, [selectedRows]);

  const handleConfirm = async (data: MaterialMetricFields) => {
    try {
      await upsertMaterialMetric({
        variables: {
          objects: flattenedMaterialMetrics.map((materialMetric) => {
            return {
              id: materialMetric.id,
              materialStandardId: materialMetric.materialStandardId,
              metricRef: materialMetric.metricRef,
              frequency:
                materialMetric.metricType === QuestionType_Enum_.LongText_
                  ? FrequencyEnums.yearly
                  : data.frequency,
              dataCollection: data.dataCollection,
              isMaterial: materialMetric.isMaterial,
            };
          }),
        },
        refetchQueries: [
          GetMetricsDrDocument_,
          ReportingUnitsMetricsPerDisclosureDocument_,
          GetRequiredMaterialMetricsDocument_,
          GetRequiredBuMaterialMetricsDocument_,
          MaterialMetricsPerDisclosureDocument_,
          EsrsAssessmentDocument_,
        ],
      });
      toast({
        text: 'Configured metric data collection settings',
      });

      const dataCollectionCheck =
        data.dataCollection === DataCollectionLevel.subsidiaries &&
        !(
          isDataCollectionMatching && firstMetricDataCollection === DataCollectionLevel.subsidiaries
        );

      if (isGroup && dataCollectionCheck) {
        updateSubsidiariesMateriality({
          variables: { id: materialStandardId },
        });
      }
    } catch (error) {
      console.log(error);
      toast({
        text: 'Unable to configure metric data collection settings',
        variant: 'danger',
      });
    }
  };

  useEffect(() => {
    if (!!selectedRows.length)
      reset({
        dataCollection: defaultDataCollectionLevel as DataCollectionLevel,
        frequency: defaultFrequency as FrequencyEnums,
      });
  }, [selectedRows]);

  return (
    <Modal
      size="sm"
      isOpen={isOpen}
      onClose={onClose}
      title={`Edit ${selectedRows.length} ${areAllRowsChildren ? 'datapoints' : 'metrics'}`}
      confirmButtonProps={{
        type: 'submit',
        form: 'bulk-edit-form',
        isDisabled: !isValid,
      }}
      onConfirm={onClose}
    >
      <VStack width="100%" alignItems="stretch" spacing="16px">
        {!!metricsWithMultipleParents?.length && (
          <Box w="100%">
            <Infobox
              status="warning"
              title="Important"
              closable={false}
              description={
                <VStack spacing="0px" alignItems="start">
                  <Typography variant="body">
                    Note that some of the selected metrics are used to calculate several other
                    metrics, so changing the settings here will propagate throughout the system.
                    {!showAll && '... '}
                    {!showAll && (
                      <Typography
                        variant="bodyStrong"
                        color="text.muted"
                        cursor="pointer"
                        as="span"
                        onClick={() => setShowAll(true)}
                      >
                        Show more
                      </Typography>
                    )}
                    {showAll &&
                      ' Below is the full list of metrics that rely on metrics you are editing.'}
                  </Typography>
                  {showAll && (
                    <>
                      <br />
                      <UnorderedList>
                        {metricsWithMultipleParents?.map((metric) => (
                          <ListItem>
                            {metric.shortTitle}
                            <UnorderedList>
                              {metric.parentMetrics.map((parent) => (
                                <ListItem>{parent.parentMetric.title}</ListItem>
                              ))}
                            </UnorderedList>
                          </ListItem>
                        ))}
                      </UnorderedList>
                      {showAll && (
                        <Typography
                          variant="bodyStrong"
                          color="text.muted"
                          cursor="pointer"
                          as="span"
                          mt="8px"
                          onClick={() => setShowAll(false)}
                        >
                          Show less
                        </Typography>
                      )}
                    </>
                  )}
                </VStack>
              }
            />
          </Box>
        )}
        <form id="bulk-edit-form" onSubmit={handleSubmit(handleConfirm)}>
          <VStack alignItems="start" spacing="16px">
            {someSelectedMetricsAreNarrative && (
              <Infobox
                closable={false}
                status="neutral"
                description="For some metrics you’ve selected it is not possible to adjust frequency, so this change would be applied only where possible"
              />
            )}
            {!allSelectedMetricsAreNarrative && (
              <Controller
                name="frequency"
                control={control}
                rules={{
                  required: 'Required',
                }}
                render={({ field: { onChange, value: fieldValue } }) => (
                  <FormField
                    label="Frequency"
                    id="frequency"
                    isInvalid={!!errors.dataCollection}
                    error={errors.frequency?.message}
                    isRequired
                  >
                    <VStack width="100%" spacing="6px" alignItems="start">
                      <Select<{ value: string; label: string }>
                        width="100%"
                        placeholder="Select"
                        value={{ label: fieldValue, value: fieldValue }}
                        options={FREQUENCY_OPTIONS}
                        onChange={(freq) => {
                          onChange(freq?.value ?? undefined);
                        }}
                      />
                    </VStack>
                  </FormField>
                )}
              />
            )}
            <Controller
              name="dataCollection"
              control={control}
              rules={{
                required: 'Required',
              }}
              render={({ field: { onChange, value: fieldValue } }) => (
                <FormField
                  label="Data gathering level"
                  id="dataLevel"
                  isInvalid={!!errors.dataCollection}
                  error={errors.dataCollection?.message}
                  isRequired
                >
                  <VStack width="100%" spacing="6px" alignItems="start">
                    <Select<{ value: string; label: string }>
                      width="100%"
                      placeholder="Select"
                      value={{
                        label:
                          dataGatheringOptions.find((option) => option.value === fieldValue)
                            ?.label ?? '',
                        value: fieldValue ?? '',
                      }}
                      options={dataGatheringOptions}
                      onChange={(dc) => {
                        onChange(dc?.value ?? undefined);
                      }}
                      isOptionDisabled={(option) =>
                        !hasReportingUnits && option.value === 'reportingUnits'
                      }
                    />
                  </VStack>
                </FormField>
              )}
            />
          </VStack>
        </form>
      </VStack>
    </Modal>
  );
};
