import { HStack, VStack } from '@chakra-ui/react';
import { CompanyAssessment } from 'models';
import { Typography } from 'Tokens';
import { ExcelFileIcon } from 'Tokens/Icons/FileIcons';
import { useTranslation } from 'utils/translation';
import { generateTaxonomyTable } from './table-generator';
import { useTaxonomyTableData } from './TaxonomyTableGenerator.hooks';
import mixpanel from 'mixpanel-browser';
import { TRACKING_EVENTS } from 'utils/mixpanel';

export const TaxonomyTableGenerator = ({
  assessment,
  isGroup,
}: {
  assessment?: CompanyAssessment;
  isGroup?: boolean;
}) => {
  const { data: taxonomyData } = useTaxonomyTableData(assessment?.id, isGroup);
  const { t } = useTranslation('assessment');
  const generate = () => {
    generateTaxonomyTable(taxonomyData);

    mixpanel.track(TRACKING_EVENTS.ASSESSMENTS.TABLE_DOWNLOAD);
  };

  return (
    <HStack w="100%" spacing="10.33px" onClick={generate}>
      <ExcelFileIcon />
      <VStack alignItems="start" spacing="0px">
        <Typography variant="bodyStrong">{t('assessment:export.euTable.table')}</Typography>
        <Typography variant="detail">{t('assessment:export.euTable.doc')}</Typography>
      </VStack>
    </HStack>
  );
};
