import React, { ChangeEvent, useMemo } from 'react';
import { useCompanyUsersQuery } from 'models';
import { HStack, Skeleton } from '@chakra-ui/react';
import { SingleTagSelector } from 'Molecules';
import { useCurrentCompanyId } from 'utils/hooks';
import { useTranslation } from 'utils/translation';

export const UserAssigner = ({
  assignedTo,
  setAssignedTo,
  placeholder,
  isDisabled,
  width = '100%',
}: {
  assignedTo?: string;
  setAssignedTo: (event: string | ChangeEvent<Element>) => void;
  placeholder?: string;
  isDisabled?: boolean;
  width?: string;
}) => {
  const { companyId } = useCurrentCompanyId();
  const { data, loading } = useCompanyUsersQuery({
    variables: { id: companyId },
    skip: !companyId,
  });
  const users = useMemo(() => {
    return data?.users ?? [];
  }, [data]);
  const { t } = useTranslation('common');

  return (
    <Skeleton isLoaded={!loading && !!data} width={width} justifyContent="stretch">
      <HStack width="100%" justifyContent="stretch">
        <SingleTagSelector
          value={assignedTo}
          options={users.map((user) => ({
            label: user.displayName,
            value: user.id,
          }))}
          onChange={(value?: string) => value && setAssignedTo(value)}
          placeholder={placeholder ?? t('common:selectUser')}
          isDisabled={isDisabled}
        />
      </HStack>
    </Skeleton>
  );
};
