import { HStack } from '@chakra-ui/react';
import { DocumentationFile } from 'models';
import { AddNewFileButton } from '../AddNewFileButton';
import { SearchBar } from './FileSearchBar';

export const FileListHeader = ({
  search,
  setSearch,
  selectedFiles,
  setSelectedFiles,
  drive = false,
}: {
  search: string;
  setSearch: (s: string) => void;
  selectedFiles: DocumentationFile[];
  setSelectedFiles: (files: DocumentationFile[]) => void;
  drive?: boolean;
}) => {
  // Need to add sort bar
  return (
    <HStack width="100%" justifyContent="space-between">
      <SearchBar search={search} setSearch={setSearch} ref={(node) => node?.focus()} />
      <AddNewFileButton
        selectedFiles={selectedFiles}
        setSelectedFiles={setSelectedFiles}
        primaryCTA={drive}
      />
    </HStack>
  );
};
