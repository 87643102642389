import { SideMenuItem, SideMenuSection } from './pieces';
import { TeamMembers } from 'containers/TeamMembers';
import { Drive } from 'containers/Drive/Drive';
import { Settings } from 'containers/Settings';
import { Assessments } from 'containers/Assessments';
import { Portfolios } from 'containers/Portfolios/Portfolios';
import { AllCompanies } from 'containers/AllCompanies';
import { Pai } from 'containers/CompanyPai';
import { Home } from 'containers/Home';
import { Community } from 'containers/Community';
import { SettingsIcon } from 'Tokens/Icons/Function';
import {
  CompanyIcon,
  DashboardIcon,
  DonutChartIcon,
  DriveIcon,
  ListIcon,
  TeamMembersIcon,
  PortfolioIcon,
  GroupIcon,
  ESGPortalIcon,
  HistogramIcon,
  IntegrationsIcon,
} from 'Tokens/Icons/Data';
import { GroupAssessments } from 'containers/GroupAssessment';
import { Groups } from 'containers/Groups';
import { EsrsAssessments } from 'containers/Esrs/EsrsAssessments';
import { Integrations } from 'containers/Integrations/Integrations';

export enum AccessFlags {
  ESRS = 'esrs',
  PAI = 'pai',
  TAXONOMY = 'assessments',
}

export const REGULAR_COMPANY_MODULES_SECTION_1: SideMenuItem[] = [
  {
    Icon: DashboardIcon,
    title: 'Home',
    path: '/',
    key: 'home',
    section: SideMenuSection.primary,
    component: <Home />,
  },
  {
    Icon: DonutChartIcon,
    path: 'assessments',
    title: 'Assessments',
    section: SideMenuSection.primary,
    component: <Assessments />,
    key: AccessFlags.TAXONOMY,
  },
  {
    Icon: ListIcon,
    path: 'pai',
    title: 'PAI and other indicators',
    section: SideMenuSection.primary,
    component: <Pai />,
    disabledTooltip:
      'The PAI (Principle adverse impact) and other indicators module is not part of your current subscription. Contact us to upgrade your subscription and get access to this module!',
    key: AccessFlags.PAI,
  },
  {
    Icon: HistogramIcon,
    path: 'esrs',
    title: 'ESRS',
    section: SideMenuSection.primary,
    component: <EsrsAssessments />,
    disabledTooltip:
      'The ESRS module is not part of your current subscription. Contact us to upgrade your subscription and get access to this module!',
    key: AccessFlags.ESRS,
  },
];

export const REGULAR_COMPANY_MODULES_SECTION_2: SideMenuItem[] = [
  {
    Icon: GroupIcon,
    path: 'groups',
    title: 'Groups',
    section: SideMenuSection.secondary,
    component: <Groups />,
    key: 'groups',
  },
  {
    Icon: DriveIcon,
    path: 'drive',
    title: 'Drive',
    section: SideMenuSection.secondary,
    component: <Drive />,
    disabledTooltip:
      'Uploading and management of files is currently not enabled for this company. Contact us in the chat if you have any questions.',
    key: 'drive',
  },
  {
    Icon: IntegrationsIcon,
    path: 'integrations',
    title: 'Integrations',
    section: SideMenuSection.secondary,
    component: <Integrations />,
    key: 'integrations',
  },
];

export const PORTFOLIO_COMPANY_MODULES_SECTION_1: SideMenuItem[] = [
  {
    Icon: PortfolioIcon,
    path: 'portfolios',
    title: 'Portfolios',
    section: SideMenuSection.primary,
    component: <Portfolios />,
    key: 'portfolio',
  },
  {
    Icon: CompanyIcon,
    path: 'all-companies',
    title: 'All Companies',
    section: SideMenuSection.primary,
    component: <AllCompanies />,
    key: 'allCompanies',
  },
];

export const PORTFOLIO_COMPANY_MODULES_SECTION_2: SideMenuItem[] = [
  {
    Icon: DriveIcon,
    path: 'drive',
    title: 'Drive',
    section: SideMenuSection.secondary,
    component: <Drive />,
    key: 'drive',
  },
];

export const GROUP_COMPANY_MODULES_SECTION_1: SideMenuItem[] = [
  {
    Icon: DashboardIcon,
    title: 'Home',
    path: '/',
    key: 'home',
    section: SideMenuSection.primary,
    component: <Home />,
  },
  {
    Icon: DonutChartIcon,
    path: 'assessments',
    title: 'Assessments',
    section: SideMenuSection.primary,
    component: <GroupAssessments />,
    key: AccessFlags.TAXONOMY,
  },
  {
    Icon: HistogramIcon,
    path: 'esrs',
    title: 'ESRS',
    section: SideMenuSection.primary,
    component: <EsrsAssessments />,
    disabledTooltip:
      'The ESRS module is not part of your current subscription. Contact us to upgrade your subscription and get access to this module!',
    key: AccessFlags.ESRS,
  },
];

export const GROUP_COMPANY_MODULES_SECTION_2: SideMenuItem[] = [
  {
    Icon: GroupIcon,
    path: 'groups',
    title: 'Groups',
    section: SideMenuSection.secondary,
    component: <Groups />,
    key: 'groups',
  },
  {
    Icon: DriveIcon,
    path: 'drive',
    title: 'Drive',
    section: SideMenuSection.secondary,
    component: <Drive />,
    disabledTooltip:
      'Uploading and management of files is currently not enabled for this company. Contact us in the chat if you have any questions.',
    key: 'drive',
  },
  {
    Icon: IntegrationsIcon,
    path: 'integrations',
    title: 'Integrations',
    section: SideMenuSection.secondary,
    component: <Integrations />,
    key: 'integrations',
  },
];

export const GENERAL_MODULES: SideMenuItem[] = [
  {
    Icon: TeamMembersIcon,
    path: 'team-members',
    title: 'Team Members',
    section: SideMenuSection.general,
    component: <TeamMembers />,
    key: 'teamMembers',
  },
  {
    Icon: SettingsIcon,
    path: 'settings/company-details',
    title: 'Settings',
    section: SideMenuSection.general,
    component: <Settings />,
    key: 'settings',
  },
  {
    Icon: ESGPortalIcon,
    path: 'community',
    title: 'Celsia ESG Portal',
    section: SideMenuSection.esg,
    component: <Community />,
    key: 'community',
  },
];

export const ALL_MODULES: SideMenuItem[] = [
  ...REGULAR_COMPANY_MODULES_SECTION_1,
  ...REGULAR_COMPANY_MODULES_SECTION_2,
  ...PORTFOLIO_COMPANY_MODULES_SECTION_1,
  ...PORTFOLIO_COMPANY_MODULES_SECTION_2,
  ...GROUP_COMPANY_MODULES_SECTION_1,
  ...GROUP_COMPANY_MODULES_SECTION_2,
  ...GENERAL_MODULES,
];
