import {
  IconChevronsRight,
  IconArrowBarToDown,
  IconArrowBarToLeft,
  IconArrowBarToRight,
  IconArrowBarToUp,
  IconArrowDownRight,
  IconArrowLeft,
  IconArrowNarrowDown,
  IconArrowNarrowLeft,
  IconArrowNarrowRight,
  IconArrowNarrowUp,
  IconArrowUpRight,
  IconChevronDown,
  IconChevronLeft,
  IconChevronRight,
  IconChevronUp,
  IconCornerDownRight,
  IconArrowRight,
} from '@tabler/icons-react';
import { tablerIcon } from './Icons';

export const ArrowLeftIcon = tablerIcon(IconArrowLeft);
export const ArrowRightIcon = tablerIcon(IconArrowRight);

export const ArrowNarrowLeftIcon = tablerIcon(IconArrowNarrowLeft);
export const ArrowNarrowRightIcon = tablerIcon(IconArrowNarrowRight);
export const ArrowNarrowDownIcon = tablerIcon(IconArrowNarrowDown);
export const ArrowNarrowUpIcon = tablerIcon(IconArrowNarrowUp);

export const ChevronLeftIcon = tablerIcon(IconChevronLeft);
export const ChevronRightIcon = tablerIcon(IconChevronRight);
export const ChevronDownIcon = tablerIcon(IconChevronDown);
export const ChevronUpIcon = tablerIcon(IconChevronUp);
export const ChevronsRightIcon = tablerIcon(IconChevronsRight);

export const ArrowBarToLeftIcon = tablerIcon(IconArrowBarToLeft);
export const ArrowBarToRightIcon = tablerIcon(IconArrowBarToRight);
export const ArrowBarToBottomIcon = tablerIcon(IconArrowBarToDown);
export const ArrowBarToTopIcon = tablerIcon(IconArrowBarToUp);

export const ArrowUpRightIcon = tablerIcon(IconArrowUpRight);
export const ArrowDownRightIcon = tablerIcon(IconArrowDownRight);

export const CornerDownRightIcon = tablerIcon(IconCornerDownRight);
export const ArrowCornerDownRight = tablerIcon(IconCornerDownRight);
