import { HStack, VStack } from '@chakra-ui/react';
import { Modal, Select } from 'Molecules';
import { Typography } from 'Tokens';
import { DocumentIcon } from 'Tokens/Icons/Data';
import { AIIcon } from 'Tokens/Icons/Function';
import { CompanyAssessmentDetailsFieldsFragment_ } from 'models';
import React, { useState } from 'react';
import { DuplicateAssessmentModal } from './DuplicateAssessmentModal';
import { useNavigate } from 'react-router-dom';
import { useToast } from 'utils/hooks';
import { useTranslation } from 'utils/translation';

type SelectOptions = 'duplicate' | 'new';

export const AddAssessmentModal = ({
  onClose,
  isOpen,
  assessments,
  reportingPeriod,
  isOther,
}: {
  onClose: () => void;
  isOpen: boolean;
  assessments: CompanyAssessmentDetailsFieldsFragment_[] | undefined;
  reportingPeriod?: string;
  isOther?: boolean;
}) => {
  const toast = useToast();
  const { t } = useTranslation('assessment');
  const [selectedOption, setSelectedOption] = useState<SelectOptions>('duplicate');
  const [selectedAssessment, setSelectedAssessment] =
    useState<CompanyAssessmentDetailsFieldsFragment_>();
  const navigate = useNavigate();
  const onConfirm = () => {
    if (selectedOption === 'new') {
      navigate(`new/${isOther ? '' : reportingPeriod ?? ''}`);
    }
    if (selectedOption === 'duplicate') {
      if (!selectedAssessment) {
        toast({
          text: t('assessment:duplicateAssessment'),
          variant: 'danger',
        });
        return;
      }
    }
    onClose();
  };

  const handleSelect = (option: SelectOptions) => {
    setSelectedOption(option);
  };

  const isDuplicateDisabled = !assessments || assessments.length === 0;

  return (
    <>
      <Modal
        title={'Add assessment'}
        confirmText="Confirm"
        onClose={onClose}
        isOpen={isOpen}
        onConfirm={onConfirm}
        size="smPlus"
      >
        <HStack width="100%" justifyContent="space-between" alignItems="stretch">
          <VStack
            gap="16px"
            alignItems="flex-start"
            borderWidth={selectedOption === 'duplicate' ? '2px' : '1px'}
            borderColor={selectedOption === 'duplicate' ? 'border.selected.accent' : ''}
            borderRadius="8px"
            p="16px"
            flex="1"
            minWidth="0"
            onClick={!isDuplicateDisabled ? () => handleSelect('duplicate') : undefined}
            cursor={isDuplicateDisabled ? 'not-allowed' : 'pointer'}
            opacity={isDuplicateDisabled ? 0.5 : 1}
          >
            <AIIcon />
            <VStack gap="4px" alignItems="flex-start">
              <Typography variant="h3">{t('assessment:duplicatePrevious')}</Typography>
              <Typography variant="body">{t('assessment:selectDuplicate')} </Typography>
            </VStack>

            <Select
              options={assessments?.map((assessment) => ({
                label: assessment.aggregate.title,
                value: assessment.id,
              }))}
              isDisabled={isDuplicateDisabled}
              onChange={(option) => {
                const selectedOptionAssessment = assessments?.find(
                  (assessment) => assessment.id === option?.value
                );
                setSelectedAssessment(selectedOptionAssessment);
              }}
            />
          </VStack>

          <VStack
            gap="16px"
            alignItems="flex-start"
            borderWidth={selectedOption === 'new' ? '2px' : '1px'}
            borderColor={selectedOption === 'new' ? 'border.selected.accent' : ''}
            borderRadius="8px"
            p="16px"
            flex="1"
            minWidth="0"
            onClick={() => handleSelect('new')}
            cursor="pointer"
          >
            <DocumentIcon />
            <VStack gap="4px" alignItems="flex-start">
              <Typography variant="h3">{t('assessment:startAssessment.fromScratch')}</Typography>
              <Typography variant="body">
                {t('assessment:startAssessment.beginAssessment')}{' '}
              </Typography>
            </VStack>
          </VStack>
        </HStack>
      </Modal>

      <DuplicateAssessmentModal
        isOpen={!!selectedAssessment}
        onClose={() => setSelectedAssessment(undefined)}
        id={selectedAssessment?.id}
        startDate={reportingPeriod || `Q1-${new Date().getFullYear()}`}
        withYearSelector={!!isOther}
      />
    </>
  );
};
