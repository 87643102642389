import React, { useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import App, { ErrorElement } from './App';
import * as Sentry from '@sentry/react';
import { ColorModeScript } from '@chakra-ui/react';
import { ignoreErrorsList } from 'utils/sentry';
import { nhost } from 'utils/nhost';
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';
import { captureConsoleIntegration } from '@sentry/integrations';

Sentry.init({
  dsn: import.meta.env.REACT_APP_SENTRY_DSN,
  environment: import.meta.env.REACT_APP_VERCEL_ENV,
  integrations: [
    captureConsoleIntegration,
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
  ],
  tracesSampleRate: 1.0,
  ignoreErrors: ignoreErrorsList,
  beforeSend: (event) => {
    const { isAuthenticated, isLoading: isAuthLoading } = nhost.auth.getAuthenticationStatus();
    if (!isAuthenticated || isAuthLoading) {
      return null;
    }
    return event;
  },
});

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <Sentry.ErrorBoundary fallback={<ErrorElement />}>
      <ColorModeScript initialColorMode="system" />
      <App />
    </Sentry.ErrorBoundary>
  </React.StrictMode>
);
