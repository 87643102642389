import { AutoResizeTextarea } from 'Atoms';
import { useEffect, useMemo, useRef } from 'react';
import { DRQAnswersType, DRQuestionsType } from './DRQuestionTable';

export const TextInputAnswer = ({
  answers,
  setAnswers,
  question,
}: {
  answers: DRQAnswersType;
  setAnswers: (params: DRQAnswersType) => void;
  question: DRQuestionsType[number];
}) => {
  const answer = useMemo(() => answers?.[question.reference], [answers]);
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  const handleChangeAnswer = (val: string) => {
    setAnswers({
      ...answers,
      [question.reference]: {
        value: val,
        factValue: undefined,
      },
    });
  };

  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.minHeight = 'auto';
      const tdHeight = textareaRef.current.parentElement?.parentElement?.clientHeight;
      textareaRef.current.style.height = tdHeight ? `${tdHeight - 12}px` : 'unset';
      textareaRef.current.style.minHeight = tdHeight ? `${tdHeight - 12}px` : 'unset';
    }
  }, [textareaRef.current]);

  return (
    <AutoResizeTextarea
      minH="unset"
      variant="ghost"
      ref={textareaRef}
      borderRadius="6px"
      lineHeight="20px"
      size="md"
      placeholder="Write your answer"
      value={answer?.value ?? ''}
      onChange={({ target }) => {
        handleChangeAnswer(target.value);
      }}
      onClick={(e) => e.stopPropagation()}
    />
  );
};
