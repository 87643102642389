import { Box, Collapse } from '@chakra-ui/react';
import { AlertStatus, ExternalLink, Infobox, VisibleIf } from 'Atoms';
import { useKnowledgeBase } from 'Features';
import { ARTICLE_NA_ANSWERS } from 'Features/KnowledgeBase/KnowledgeBaseArticles';
import { Typography } from 'Tokens';
import { useTranslation } from 'utils/translation';

export type WarningStatus =
  | 'none'
  | 'notApplicable'
  | 'notAlignedWarning'
  | 'notAlignedCritical'
  | 'noClimateRisksIdentified';

const WARNING_BOX_FIELDS = {
  none: {
    isVisible: false,
    status: 'neutral',
    title: undefined,
    description: undefined,
  },
  notApplicable: {
    isVisible: true,
    status: 'warning',
    title: undefined,
    description: 'question:answerNotApplicable',
  },
  notAlignedWarning: {
    isVisible: true,
    status: 'warning',
    title: 'question:warning',
    description: 'question:answerNotCompliant',
  },
  notAlignedCritical: {
    isVisible: true,
    status: 'critical',
    title: 'question:warning',
    description: 'question:answerNotCompliant',
  },
  noClimateRisksIdentified: {
    isVisible: true,
    status: 'warning',
    title: undefined,
    description: 'question:noClimateRisksIdentified',
  },
};

export type InputCardWarningProps = {
  status: WarningStatus;
  alignmentErrorMessage?: string | null;
};

export const InputCardWarning = ({ status, alignmentErrorMessage }: InputCardWarningProps) => {
  const { t } = useTranslation(['question']);
  const title = WARNING_BOX_FIELDS[status].title;
  const { onOpen } = useKnowledgeBase();

  const description = WARNING_BOX_FIELDS[status].description;
  return (
    <Box
      width="100%"
      padding="16px"
      paddingTop={WARNING_BOX_FIELDS[status].isVisible ? '16px' : '0px'}
    >
      <Collapse in={WARNING_BOX_FIELDS[status].isVisible} animateOpacity unmountOnExit>
        <Infobox
          status={WARNING_BOX_FIELDS[status].status as AlertStatus}
          title={title && t(title)}
          description={
            <Typography>
              {alignmentErrorMessage || (description && t(description))}
              <VisibleIf condition={status === 'notApplicable'}>
                <ExternalLink
                  onClick={() => onOpen(ARTICLE_NA_ANSWERS.slug)}
                  textDecoration="none"
                  fontSize={'md'}
                >
                  {t('question:howShouldTheNAAnswerOptionBeUsed')}
                </ExternalLink>
              </VisibleIf>
            </Typography>
          }
          closable={false}
        />
      </Collapse>
    </Box>
  );
};
