import { ArrowLeftIcon, ArrowRightIcon } from 'Tokens/Icons/Direction';
import { HStack, VStack } from '@chakra-ui/react';
import { IconButton, TruncatableText } from 'Atoms';
import { Typography } from 'Tokens';
import { useNavigate } from 'react-router-dom';

type NavigationElement = {
  title: string;
  label?: string;
  reference?: string;
  link: string;
};

export const BottomNavigation = ({
  nextElement,
  prevElement,
}: {
  nextElement?: NavigationElement;
  prevElement?: NavigationElement;
}) => {
  const navigate = useNavigate();

  return (
    <HStack justifyContent="space-between" w="100%" pt="32px" pb="48px">
      <HStack w="45%">
        {prevElement && (
          <HStack gap="4px">
            <IconButton
              aria-label="prev-dr"
              variant="ghost"
              size="sm"
              icon={<ArrowLeftIcon />}
              onClick={() => navigate(prevElement.link)}
            />
            <VStack alignItems="flex-start" gap="0px">
              <Typography variant="detail">{prevElement.label}</Typography>
              <TruncatableText
                variant="bodyStrong"
                text={prevElement?.title ?? ''}
                tooltipMaxWidth="400px"
              />
            </VStack>
          </HStack>
        )}
      </HStack>
      <HStack w="45%" justifyContent="flex-end">
        {nextElement && (
          <HStack gap="4px">
            <VStack alignItems="flex-end" gap="0px">
              <Typography variant="detail">{nextElement.label}</Typography>
              <TruncatableText
                variant="bodyStrong"
                text={nextElement?.title ?? ''}
                tooltipMaxWidth="400px"
              />
            </VStack>
            <IconButton
              aria-label="next-dr"
              variant="ghost"
              size="sm"
              icon={<ArrowRightIcon />}
              onClick={() => navigate(nextElement.link)}
            />
          </HStack>
        )}
      </HStack>
    </HStack>
  );
};
