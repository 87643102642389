import { Box, HStack, VStack } from '@chakra-ui/react';
import { Typography } from 'Tokens';
import { FC } from 'react';
import { TooltipProps } from 'recharts';
import { NameType, ValueType } from 'recharts/types/component/DefaultTooltipContent';

export const MetricChartCustomTooltip: FC<
  TooltipProps<ValueType, NameType> & { metricTitle?: string; isBreakdown?: boolean }
> = ({ payload, active, metricTitle, isBreakdown }) => {
  if (active && payload && payload.length) {
    return (
      <Box
        maxWidth="215px"
        p="8px"
        borderRadius="10px"
        boxShadow="0px 4px 6px -2px rgba(0, 0, 0, 0.05), 0px 0px 15px -3px rgba(0, 0, 0, 0.10)"
        bgColor="white"
      >
        <HStack
          width="100%"
          spacing="8px"
          justifyContent="start"
          alignItems="start"
          position="relative"
        >
          <Box
            width="4px"
            height="100%"
            position="absolute"
            borderRadius="2px"
            bgColor={payload[0].color}
          />
          <VStack pl="12px" spacing="8px" justifyContent="start" alignItems="start">
            <VStack spacing="0px" justifyContent="start" alignItems="start">
              <Typography variant="h2">{payload[0].value}</Typography>
              <Typography variant="body" color="text.muted">
                {payload[0].payload.unit}
              </Typography>
            </VStack>
            <Typography variant="bodyStrong">
              {metricTitle} {isBreakdown ? payload[0].name : null}
            </Typography>
            <Typography variant="body">{payload[0].payload.name}</Typography>
          </VStack>
        </HStack>
      </Box>
    );
  }

  return null;
};
