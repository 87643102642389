import { InMemoryCache, InMemoryCacheConfig, makeVar } from '@apollo/client';

const loggedInUserVar = makeVar<string | null>(
  (typeof window !== 'undefined' && localStorage?.getItem('celsia-logged-in-email')) || null
);

export const setLoggedInUser = (loggedInEmail?: string | null) => {
  if (loggedInEmail !== undefined)
    localStorage?.setItem('celsia-logged-in-email', loggedInEmail ?? '');

  return loggedInUserVar(loggedInEmail);
};

export const createApolloCache = (config: InMemoryCacheConfig) =>
  new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          loggedInUser: {
            read() {
              return setLoggedInUser();
            },
          },
        },
      },
      Activity: {
        keyFields: ['reference'],
      },
      Objective: {
        keyFields: ['key'],
      },
      Sector: {
        keyFields: ['reference'],
      },
      QuestionSet: {
        keyFields: ['reference'],
      },
      QuestionChoice: {
        keyFields: false,
      },
      CachedResult: {
        keyFields: ['activityRef', 'id', 'companyId'],
      },
      Company: {
        keyFields: ['id'],
      },
      ActivityReport: {
        keyFields: ['id'],
      },
      BusinessUnit: {
        keyFields: ['id'],
      },
      BusinessUnitAssessment: {
        keyFields: ['id', 'businessUnitId', 'cAssessmentId', 'cachedResultId'],
      },
      CompanyAssessment: {
        keyFields: ['id', 'companyId', 'cachedResultId', 'aggregateId'],
      },
      Thread: {
        keyFields: ['id', 'answerId'],
      },
      Answer: {
        keyFields: ['id', 'questionId'],
      },
      Comment: {
        keyFields: ['id', 'threadId'],
      },
      EsrsMetric: {
        keyFields: ['reference'],
      },
      EsrsAssessment_by_pk: {
        keyFields: ['id'],
      },
      esrs: {
        keyFields: ['reference'],
      },
      esrs_MaterialMetric: {
        fields: {
          metric: {
            keyArgs: ['reference'],
          },
        },
        keyFields: ['id'],
      },
      EsrsStandard_by_pk: {
        keyFields: ['reference'],
      },
      EsrsStandard: {
        keyFields: ['reference'],
      },
      esrs_Answer: {
        keyFields: ['id'],
        fields: {
          standard: {
            keyArgs: ['reference'],
          },
        },
      },
      esrs_MaterialStandard: {
        keyFields: ['id'],
        fields: {
          standard: {
            keyArgs: ['reference'],
          },
        },
      },
    },
    ...config,
  });
