import React, { useMemo } from 'react';
import { Control, Controller } from 'react-hook-form';
import { useGetActionsDocumentation } from '../Actions.hooks';
import { Box, HStack, VStack, useDisclosure } from '@chakra-ui/react';
import { AttachmentDrawer } from 'Features/Screening/AttachmentsDrawer';
import {
  ActionFieldsFragment_,
  DocumentationFileDetailsFragment_,
  GetActionDocumentationByIdDocument_,
  ShortUser,
} from 'models';
import {
  InputCardDocumentation,
  InputCardDocumentationProps,
} from 'Molecules/InputCard/InputCardDocumentation';
import { File } from 'Molecules';
import { StepLabels } from 'containers/Esrs/EsrsUtilComponents';
import { ActionFields } from '../../Requirement';

export const ActionDocumentation = ({
  control,
  action,
  user,
  filesAttached,
  setFilesAttached,
  setNote,
}: {
  control: Control<ActionFields, any>;
  action: ActionFieldsFragment_ | undefined;
  user: ShortUser | null;
  filesAttached: DocumentationFileDetailsFragment_[] | undefined;
  setFilesAttached: (param: DocumentationFileDetailsFragment_[] | undefined) => void;
  setNote: (param: string | null | undefined) => void;
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { attachmentBox, noteHistory, documentationData, documentationLoading } =
    useGetActionsDocumentation(action);

  const showDocumentation: InputCardDocumentationProps = useMemo(() => {
    return {
      currentAuthor: user ?? undefined,
      attachmentBox: attachmentBox,
      noteHistory: noteHistory,
      openAttachmentDrawer: onOpen,
      refetchQueries: [GetActionDocumentationByIdDocument_],
      isInputCard: false,
      isLoading: documentationLoading,
      onNoteChanged: !!noteHistory ? undefined : (newNote) => setNote(newNote),
      isDisabled: false,
      placeholder: 'Describe your plan',
    };
  }, [documentationData, noteHistory, user, documentationLoading]);

  return (
    <Box position="relative" pl="32px" w="100%">
      <StepLabels
        title="Provide documentation"
        description="Document anything for internal or audit purposes"
        ml="33px"
      />
      <Controller
        name="documentation"
        control={control}
        render={() => (
          <VStack spacing="6px" w="520px" alignItems="start">
            <AttachmentDrawer
              isOpen={isOpen}
              refetch={[GetActionDocumentationByIdDocument_]}
              onClose={onClose}
              attachmentBox={attachmentBox}
              onAttachClicked={
                !!attachmentBox
                  ? undefined
                  : (files) => {
                      setFilesAttached(files);
                    }
              }
            >
              <InputCardDocumentation {...showDocumentation} />
            </AttachmentDrawer>
            {!attachmentBox && (
              <HStack mt="-16px">
                {filesAttached?.map((file, index) => <File file={file} key={index} />)}
              </HStack>
            )}
          </VStack>
        )}
      />
    </Box>
  );
};
