import { ListItem, UnorderedList } from '@chakra-ui/react';
import { IconListDetails } from '@tabler/icons-react';
import { Infobox } from 'Atoms';
import { CompanyDetailsQuery_, Investor, useGroupsQuery } from 'models';
import { useMemo } from 'react';
import { useTranslation } from 'utils/translation';

export const ReminderBox = ({
  investors,
  company,
}: {
  investors: Investor[];
  company?: CompanyDetailsQuery_['company'][number];
}) => {
  const { t } = useTranslation('common');

  const { data: groupsData } = useGroupsQuery({
    variables: {
      companyId: company?.id,
    },
    skip: !company?.id,
  });

  const groups = useMemo(() => {
    return groupsData?.groups ?? [];
  }, [groupsData]);

  const shareWith = useMemo(() => {
    const list = [];
    if (investors?.length > 0) list.push('investor');
    if (groups?.length > 0) list.push('parent company');

    return list.join(' and ');
  }, [groups, investors]);

  return (
    <Infobox
      status="neutral"
      icon={<IconListDetails width="20px" />}
      title={t('common:results.reminder.title')}
      closable={false}
      description={
        <UnorderedList>
          <ListItem>{t('common:results.reminder.bullet1')}</ListItem>
          <ListItem>{t('common:results.reminder.bullet2')}</ListItem>
          {shareWith.length > 0 ? (
            <ListItem>{t('common:results.reminder.bullet3', { name: shareWith })}</ListItem>
          ) : null}
          <ListItem>{t('common:results.reminder.bullet4')}</ListItem>
        </UnorderedList>
      }
    />
  );
};
