import { Box, HStack, VStack, useDisclosure } from '@chakra-ui/react';
import { AdditionalTypeTag, Button } from 'Atoms';
import { LearnMoreDrawer } from 'Molecules';
import { ActiveStepLabels } from 'containers/Esrs/EsrsUtilComponents';
import { useMemo } from 'react';
import { MappedDisclosureRequirements, MaterialityState } from '../DoubleMaterialityAssessment.d';

export const DrLearnMore = ({
  isCollectOnly,
  isStandardMandatory,
  disclosureRequirement,
}: {
  isCollectOnly: boolean;
  isStandardMandatory: boolean;
  disclosureRequirement?: MappedDisclosureRequirements[number];
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const drMateriality = useMemo(
    () => disclosureRequirement?.materialityStatus,
    [disclosureRequirement]
  );
  const isMandatoryOrMaterialMandatory = useMemo(() => {
    if (
      drMateriality === MaterialityState.mandatory ||
      drMateriality === MaterialityState.materialMandatory
    ) {
      return true;
    }
    if (isCollectOnly) {
      return isStandardMandatory;
    }
    return false;
  }, [drMateriality, isCollectOnly, isStandardMandatory]);

  const canCollectDisclosure = useMemo(
    () =>
      drMateriality === MaterialityState.collectData ||
      drMateriality === MaterialityState.doNotCollect,
    [drMateriality]
  );

  const additionalTypes = useMemo(
    () =>
      isMandatoryOrMaterialMandatory && !disclosureRequirement?.metrics?.length
        ? undefined
        : disclosureRequirement?.additionalTypes,
    [disclosureRequirement]
  );

  return (
    <>
      <Box
        borderLeft={
          (disclosureRequirement?.metrics ?? []).length > 0 ||
          (!isMandatoryOrMaterialMandatory && (isCollectOnly ? canCollectDisclosure : true))
            ? '1px dashed'
            : 'unset'
        }
        pl="32px"
        ml="14px"
        borderColor="border.hover"
        pb="32px"
      >
        <ActiveStepLabels
          clickable={false}
          title={'Understand Disclosure Requirement'}
          hasSteps={false}
          ml="63px"
        />
        <VStack w="100%" spacing="8px" alignItems="start">
          <VStack alignItems="start" spacing="20px">
            {!!additionalTypes?.length && (
              <HStack spacing="4px">
                {additionalTypes?.map((aType) => {
                  return (
                    <AdditionalTypeTag
                      key={aType.reference}
                      label={aType.title ?? aType.reference}
                      helpText={aType.description ?? ''}
                    />
                  );
                })}
              </HStack>
            )}
            <Button w="111px" size="md" variant="secondary" onClick={onOpen}>
              Learn more
            </Button>
          </VStack>
        </VStack>
      </Box>
      <LearnMoreDrawer
        isOpen={isOpen}
        onClose={onClose}
        description={disclosureRequirement?.description ?? ''}
        header={disclosureRequirement?.title}
      />
    </>
  );
};
