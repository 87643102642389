import { Box, HStack, Stack, VStack } from '@chakra-ui/react';
import { Typography } from 'Tokens';
import React from 'react';
import { getAvatarComponent } from './HistoryHelpers';
import { useTranslation } from 'utils/translation';
import { UserInformation } from './HistoryUserInfo';
import { AuditLog } from 'Features/History/History.hooks';
import { VisibleIf } from 'Atoms';
import { defaultStyles, FileIcon } from 'react-file-icon';
import { QuestionType_Enum_ } from 'models';
import { ArrowNarrowDownIcon, ArrowNarrowRightIcon } from 'Tokens/Icons/Direction';
import { Change, diffWords } from 'diff';

export type HistoryItemProps = {
  auditLog: AuditLog;
};

const DataChange = ({
  table,
  text,
  old,
  fileExtension,
}: {
  table?: string;
  text?: string;
  fileExtension?: string;
  old?: boolean;
}) => {
  const { t } = useTranslation(['audit']);
  return (
    <HStack>
      {table === 'Attachment' && (
        <Box boxSize="14px">
          <FileIcon
            extension={fileExtension}
            {...defaultStyles[fileExtension as keyof typeof defaultStyles]}
            labelUppercase
          />
        </Box>
      )}
      <Typography
        key={text}
        variant="body"
        as="span"
        {...(old ? { textDecoration: 'line-through', color: 'text.hint' } : {})}
      >
        {text ?? t('audit:common.empty')}
      </Typography>
    </HStack>
  );
};

const DiffDataChange = ({ oldData, newData }: { oldData?: string; newData?: string }) => {
  const oldValue = !!oldData && !Number.isNaN(oldData) ? String(oldData) : '';
  const newValue = !!newData && !Number.isNaN(newData) ? String(newData) : '';
  const changes = diffWords(oldValue ?? '', newValue ?? '');
  return changes.map((part: Change, index: number) => {
    const style = part.added
      ? { color: 'text.selected', backgroundColor: 'bg.selected' }
      : part.removed
        ? { textDecoration: 'line-through', color: 'text.hint' }
        : { color: 'text.default' };

    return (
      <Typography key={index} variant="body" as="span" {...style}>
        {part.value}
      </Typography>
    );
  });
};

export const HistoryItem = ({ auditLog }: HistoryItemProps) => {
  const { t } = useTranslation(['audit']);

  const { user, operation, table, contextualInfo, metaInfo, dataChanges, withReplacement } =
    auditLog;

  const displayName = user?.displayName || 'Organization';
  const avatarComponent = getAvatarComponent(user);
  const tableDisplayName = withReplacement
    ? t(`audit:replacement.${table}`)
    : t(`audit:table.${table}`);
  const operationText = t(`audit:operation.${operation}`);
  const metaInfoText = Object.values(metaInfo).join(' • ');

  if (dataChanges?.every((dataChange) => !dataChange.oldData && !dataChange.newData)) return null;
  return (
    <HStack
      alignItems="flex-start"
      padding="16px 16px 16px 16px"
      width="100%"
      borderBottom="1px  solid"
      borderColor="border.decorative"
      spacing="8px"
    >
      <Box width="fit-content">{avatarComponent}</Box>
      <VStack spacing="12px" alignItems="flex-start" justifyContent="flex-start" w="100%">
        <VStack alignItems="flex-start" justifyContent="flex-start" spacing="4px">
          <UserInformation
            displayName={displayName}
            operationText={operationText}
            tableDisplayName={tableDisplayName}
            table={table}
            contextualInfo={contextualInfo}
          />
          <Typography variant="body">{contextualInfo}</Typography>
          <Typography variant="detail" color="text.hint">
            {metaInfoText}
          </Typography>
        </VStack>
        <VStack>
          {dataChanges?.map((dataChange, index) => {
            const shouldShowDiffStyle =
              table === 'Answer' &&
              (dataChange?.questionType === QuestionType_Enum_.LongText_ ||
                dataChange?.questionType === QuestionType_Enum_.ShortText_);
            return (
              <Stack
                key={`Datachange-${index}`}
                bgColor="bg.muted"
                width="100%"
                padding="8px"
                alignItems={shouldShowDiffStyle ? 'flex-start' : 'center'}
                flexDirection={shouldShowDiffStyle ? 'column' : 'row'}
              >
                <DataChange
                  table={table}
                  text={dataChange?.oldData}
                  fileExtension={dataChange?.oldFileExtension}
                  old
                />
                <VisibleIf condition={operation !== 'DELETE'}>
                  <Typography key={'arrow'} variant="body" as="span">
                    <Box boxSize="16px">
                      {shouldShowDiffStyle ? (
                        <ArrowNarrowDownIcon color="text.muted" />
                      ) : (
                        <ArrowNarrowRightIcon color="text.muted" />
                      )}
                    </Box>
                  </Typography>
                  {shouldShowDiffStyle ? (
                    <HStack>
                      <DiffDataChange oldData={dataChange?.oldData} newData={dataChange?.newData} />
                    </HStack>
                  ) : (
                    <DataChange
                      table={table}
                      text={dataChange?.newData}
                      fileExtension={dataChange?.newFileExtension}
                    />
                  )}
                </VisibleIf>
              </Stack>
            );
          })}
        </VStack>
      </VStack>
    </HStack>
  );
};
