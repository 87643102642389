import { Box, useDisclosure } from '@chakra-ui/react';
import { ActiveStepLabels } from 'containers/Esrs/EsrsUtilComponents';
import { AttachmentDrawer } from 'Features/Screening/AttachmentsDrawer';
import {
  InputCardDocumentation,
  InputCardDocumentationProps,
} from 'Molecules/InputCard/InputCardDocumentation';
import { Button } from 'Atoms';
import { ArrowNarrowRightIcon } from 'Tokens/Icons/Direction';
import {
  AttachmentBox,
  GetMaterialStandardDocument_,
  GetMaterialStandardQuery_,
  NoteHistory,
  ShortUser,
} from 'models';
import { useMemo } from 'react';
import { useUserData } from '@nhost/react';

export const ProvideReasoning = ({
  step,
  showDrTable,
  materialStandard,
  onDoneClick,
  setStep,
}: {
  step: number;
  showDrTable: boolean;
  materialStandard?: GetMaterialStandardQuery_['materialityAssessment'][number];
  onDoneClick: () => void;
  setStep: (val: number) => void;
}) => {
  const user: ShortUser | null = useUserData();
  const {
    isOpen: isAttachmentDrawerOpen,
    onOpen: onAttachmentDrawerOpen,
    onClose: onAttachmentDrawerClose,
  } = useDisclosure();

  const attachmentBox: AttachmentBox | undefined = useMemo(
    () => materialStandard?.attachmentBox ?? undefined,
    [materialStandard]
  );

  const noteHistory: NoteHistory | undefined = useMemo(
    () => materialStandard?.noteHistory ?? undefined,
    [materialStandard]
  );

  const showDocumentation: InputCardDocumentationProps = useMemo(() => {
    return {
      currentAuthor: user ?? undefined,
      attachmentBox: attachmentBox,
      noteHistory: noteHistory,
      openAttachmentDrawer: onAttachmentDrawerOpen,
      refetchQueries: [GetMaterialStandardDocument_],
      isInputCard: false,
      flexDirection: 'column-reverse',
    };
  }, [attachmentBox, noteHistory, user, materialStandard]);

  return (
    <Box
      borderLeft={showDrTable ? '1px dashed' : 'none'}
      ml="16px"
      pl="32px"
      borderColor="border.hover"
      pb="24px"
      position="relative"
    >
      <ActiveStepLabels
        title="Reasoning for choice"
        description="Provide reasoning for why the the topic is material or not. If such reasoning is already documented elsewhere (e.g in an uploaded materiality assessment), please write a short note referring to this documentation."
        active={step === 1}
        mb={step === 1 ? '24px' : '0px'}
        done={step > 1}
        ml="33px"
        goBack={() => setStep(1)}
        descriptionWidth="490px"
      />
      {step === 1 && (
        <Box w="490px">
          <AttachmentDrawer
            isOpen={isAttachmentDrawerOpen}
            refetch={[GetMaterialStandardDocument_]}
            onClose={onAttachmentDrawerClose}
            attachmentBox={attachmentBox}
          >
            <InputCardDocumentation {...showDocumentation} flexDirection="column" width="490px" />
          </AttachmentDrawer>
          {showDrTable ? (
            <Button
              variant="primary"
              alignSelf="start"
              rightIcon={<ArrowNarrowRightIcon color="text.onAccent" />}
              mb="24px"
              onClick={() => setStep(step + 1)}
            >
              Next step
            </Button>
          ) : (
            <Button
              variant="primary"
              type="submit"
              onClick={onDoneClick}
              alignSelf="start"
              rightIcon={<ArrowNarrowRightIcon color="text.onAccent" />}
            >
              Done
            </Button>
          )}
        </Box>
      )}
    </Box>
  );
};
