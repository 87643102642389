import { CalendarIcon } from '@chakra-ui/icons';
import {
  VStack,
  HStack,
  useColorMode,
  Popover,
  PopoverTrigger,
  Circle,
  PopoverContent,
  PopoverBody,
} from '@chakra-ui/react';
import { ProgressBar } from 'Atoms';
import { Typography, colors } from 'Tokens';
import { QUARTERS_FIELDS } from 'containers/Esrs/pieces/DisclosureRequirements/Requirement';
import { useMemo } from 'react';
import { PieChart } from 'react-minimal-pie-chart';
import {
  AggregatedMetricsTableData,
  AggregatedQualitativeAnswers,
  MetricAnswer,
  ReportingUnitType,
  SubsidiaryAssessmentsType,
  hasChildOnBULevel,
  hasChildOnSubLevel,
  isFullMetricOnBULevel,
  isFullMetricOnSubLevel,
} from '../../../AggregatedMetrics';
import { DetailsCard } from '../../../MetricSidebar/MetricSource';
import { getUniqueDataPoints, MetricsTableData } from '../../../MetricAnswers.hooks';
import { useCheckProgress } from './MetricProgress.hooks';
import { useParams } from 'react-router-dom';

const CollectedPopover = ({
  row,
  answersData,
  isGroup,
  progress,
}: {
  row: AggregatedMetricsTableData;
  answersData?: AggregatedQualitativeAnswers;
  isGroup: boolean;
  progress: number;
}) => {
  const answerData = answersData?.find((data) => data.metricRef === row.metric?.reference);
  const { standardRef = '' } = useParams();

  const [isSubsidiaries, isBusinessUnits] = useMemo(
    () => [isFullMetricOnSubLevel(row.metric), isFullMetricOnBULevel(row.metric)],
    [row]
  );

  const isMixed = useMemo(
    () =>
      row.subRows?.length &&
      ((hasChildOnSubLevel(row.metric) && !isSubsidiaries) ||
        (hasChildOnBULevel(row.metric) && !isBusinessUnits)),
    [row]
  );

  const companyLevelTotal = useMemo(() => answerData?.answer?.datapoints[0]?.value, [answerData]);

  const getSubsidiaryAnswer = (sub: {
    subsidiary: SubsidiaryAssessmentsType;
    answer: MetricAnswer | undefined;
    isCompanyLevel?: boolean;
    reportingUnits?: {
      reportingUnit: ReportingUnitType;
      answer: MetricAnswer | undefined;
    }[];
  }) => {
    if (!sub.answer && !sub.reportingUnits?.filter((ru) => !!ru.answer).length) return undefined;

    const datapoint = sub.answer?.datapoints.find((dp) =>
      dp.datapointTags.some((dpTag) => dpTag.tagValue === row.tagName)
    );
    let total = Number(datapoint?.value || 0);

    if (row.tagName) {
      sub.reportingUnits?.forEach((ru) => {
        const ruAnswer =
          Number(
            ru.answer?.datapoints.find((dp) =>
              dp.datapointTags.some((dpTag) => dpTag.tagValue === row.tagName)
            )?.value
          ) ?? 0;
        if (!isNaN(ruAnswer)) total = total + ruAnswer;
      });

      const subMaterialMetric = sub?.subsidiary.materialStandards
        .find((mS) => mS.standardRef === standardRef)
        ?.materialMetrics.find((m) => m.metricRef === sub.answer?.metricRef);

      const areDatapointTagsMaterial = datapoint?.datapointTags.every((dpTag) =>
        subMaterialMetric?.materialMetricTags.some((materialTag) =>
          materialTag.materialTagValues.some((tagValue) => tagValue.tagValue === dpTag.tagValue)
        )
      );

      return areDatapointTagsMaterial ? String(isNaN(total) ? 0 : total) : undefined;
    }

    if (sub.answer && !sub.reportingUnits?.filter((ru) => !!ru.answer).length) {
      return sub.answer?.datapoints[0]?.value;
    }

    sub.reportingUnits?.forEach((ru) => {
      const ruAnswer = Number(ru.answer?.datapoints?.[0]?.value);
      if (!isNaN(ruAnswer)) total = total + ruAnswer;
    });

    return String(isNaN(total) ? 0 : total);
  };

  const getBusinessUnitAnswer = (businessUnit: {
    reportingUnit: ReportingUnitType;
    answer: MetricAnswer | undefined;
  }) => {
    if (!businessUnit.answer) return undefined;

    const materialTags = row.metric.materialMetrics[0].materialMetricTags.map(
      (tag) => tag.materialTagValues
    );

    const datapoints = businessUnit.answer?.datapoints.map((dp) => ({
      ...dp,
      tagValues: dp.datapointTags.map((dpTag) => dpTag.tagValue),
      metricRef: row.metric?.reference,
    }));
    const uniqueDatapoints = getUniqueDataPoints(datapoints);

    if (row.tagName) {
      return businessUnit.answer?.datapoints?.find((dp) =>
        dp.datapointTags.some((dpTag) => dpTag.tagValue === row.tagName)
      )?.value;
    }

    const isCompanyLevel = businessUnit.reportingUnit.isCompanyLevel;

    if (isCompanyLevel) return businessUnit.answer?.datapoints?.[0]?.value;

    return String(
      uniqueDatapoints
        ?.filter(
          (dp) =>
            !!dp.datapointTags.length &&
            dp.datapointTags.every((dpTag: { tagType: string; tagValue: string }) =>
              materialTags.some((mt) =>
                mt.some((mtv) => mtv.tagType === dpTag.tagType && mtv.tagValue === dpTag.tagValue)
              )
            )
        )
        .reduce((acc, curr) => acc + Number(curr.value), 0)
    );
  };

  return (
    <VStack alignItems="start" justifyContent="start" p={0} spacing="16px">
      <VStack alignItems="start" w="100%" gap="8px">
        <HStack justifyContent="space-between" w="100%">
          <Typography variant="bodyStrong">Data collection progress</Typography>
          <Typography variant="h4">{progress}%</Typography>
        </HStack>
        <ProgressBar completed={progress} />
      </VStack>
      <VStack alignItems="start" w="100%" gap="8px">
        {isMixed && (
          <DetailsCard
            title={'Company level'}
            author={answerData?.answer?.datapoints?.[0]?.owner?.displayName ?? ''}
            answer={companyLevelTotal}
            metricRef={answerData?.metricRef}
            reportingUnitId={answerData?.answer?.reportingUnit.id}
            isGroup={isGroup}
            isNumeric
            choices={answerData?.answer?.datapoints?.[0]?.datapointChoices.map(
              (c) => c.choice.title
            )}
          />
        )}
        {isSubsidiaries
          ? answerData?.subsidiaries?.map((sub) => {
              return (
                <DetailsCard
                  title={sub.subsidiary.company.name}
                  author={sub.answer?.datapoints?.[0]?.owner?.displayName ?? ''}
                  answer={getSubsidiaryAnswer(sub)}
                  subsidiary={sub.subsidiary}
                  metricRef={answerData.metricRef}
                  isAggregated={!!row.subRows?.length}
                  isGroup={isGroup}
                  isNumeric
                  choices={sub.answer?.datapoints?.[0]?.datapointChoices.map((c) => c.choice.title)}
                />
              );
            })
          : isBusinessUnits || isMixed
            ? answerData?.reportingUnits?.map((ru) => (
                <DetailsCard
                  title={ru.reportingUnit.name}
                  author={ru.answer?.datapoints?.[0]?.owner?.displayName ?? ''}
                  answer={getBusinessUnitAnswer(ru)}
                  metricRef={answerData.metricRef}
                  reportingUnitId={ru.reportingUnit.id}
                  isGroup={isGroup}
                  isNumeric
                  choices={ru.answer?.datapoints?.[0]?.datapointChoices.map((c) => c.choice.title)}
                />
              ))
            : QUARTERS_FIELDS.map((quarter) => (
                <HStack justifyContent="space-between" w="100%">
                  <HStack>
                    <CalendarIcon />
                    <Typography variant="body" color="text.muted">
                      {quarter}
                    </Typography>
                  </HStack>
                  {row.result?.[quarter] ? (
                    <Typography variant="body" color="text.muted">
                      {parseFloat(row.result?.[quarter].toFixed(2))}
                    </Typography>
                  ) : (
                    <Typography variant="body" color="text.hint">
                      N/A
                    </Typography>
                  )}
                </HStack>
              ))}
      </VStack>
    </VStack>
  );
};

export const MetricProgress = ({
  row,
  answersData,
  companyReportingUnit,
  isGroup,
}: {
  row: MetricsTableData;
  isGroup: boolean;
  answersData?: AggregatedQualitativeAnswers;
  companyReportingUnit?: string;
}) => {
  const { colorMode } = useColorMode();
  const isDarkMode = useMemo(() => colorMode === 'dark', [colorMode]);
  const color = useMemo(() => (isDarkMode ? '_dark' : 'default'), [isDarkMode]);

  const progress = useCheckProgress(row, answersData, companyReportingUnit);

  return (
    <Popover trigger="hover">
      <PopoverTrigger>
        <HStack>
          <Circle size="16px" alignItems="center">
            <PieChart
              lineWidth={32}
              data={[
                {
                  title: 'Collected',
                  value: progress,
                  color: colors['bg.progress'][color],
                },
                {
                  title: 'Missing',
                  value: 100 - progress,
                  color: colors['bg.unknown'][color],
                },
              ]}
            />
          </Circle>
          <Typography variant="body" cursor="default">
            {Number.isNaN(progress) ? 0 : progress}%
          </Typography>
        </HStack>
      </PopoverTrigger>
      <PopoverContent
        w="326px"
        border="none"
        boxShadow=" 0px 0px 24px -2px #0F0F2E1F"
        borderRadius="10px"
      >
        <PopoverBody p="16px">
          <CollectedPopover
            row={row}
            answersData={answersData}
            isGroup={isGroup}
            progress={progress}
          />
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};
