// DowntimeNotification.tsx
import React, { useEffect, useState } from 'react';
import { HStack, IconButton } from '@chakra-ui/react';
import { useTranslation } from 'utils/translation';
import { Typography } from 'Tokens';
import { WarningIcon } from 'Tokens/Icons/Status';
import { RemoveIcon } from 'Tokens/Icons/Function';
import { format } from 'date-fns';

type DowntimeNotificationProps = {
  startTime: Date;
  endTime: Date;
  onClose: () => void;
};

export const DowntimeNotification: React.FC<DowntimeNotificationProps> = ({
  startTime,
  endTime,
  onClose,
}) => {
  const { t } = useTranslation('home');
  const [isVisible, setIsVisible] = useState(false);

  const endDateFormatted = format(endTime, 'EEEE, MMMM do');
  const startTimeFormatted = format(startTime, 'HH:mm');
  const endTimeFormatted = format(endTime, 'HH:mm');

  useEffect(() => {
    const currentTime = new Date();
    if (currentTime >= startTime && currentTime <= endTime) {
      setIsVisible(true);
    }
  }, [startTime, endTime]);
  if (!isVisible) return null;

  return (
    <HStack
      backgroundColor="bg.decor.08"
      padding="10px 40px"
      justifyContent="center"
      position="relative"
    >
      <WarningIcon color="text.onAccent" />
      <Typography variant="bodyStrong" color="text.onAccent">
        {t('home:downtimeNotification', {
          endDateFormatted: endDateFormatted,
          startTimeFormatted: startTimeFormatted,
          endTimeFormatted: endTimeFormatted,
        })}
      </Typography>
      <IconButton
        aria-label={t('home:closeButtonLabel')}
        position="absolute"
        top="50%"
        right="16px"
        transform="translateY(-50%)"
        icon={<RemoveIcon color="text.onAccent" />}
        variant="ghost"
        size="xs"
        onClick={onClose}
      />
    </HStack>
  );
};
