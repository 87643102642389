import { useTranslation } from 'utils/translation';
import { ContentHeader, ContentLayout } from 'Molecules';
import { Button, EmptyState } from 'Atoms';
import { Box, HStack, useDisclosure, VStack } from '@chakra-ui/react';
import { PaiIndicatorsIllustration, Typography } from 'Tokens';
import { EsrsAssessmentModal } from './EsrsAssessmentModal';
import { EsrsGroupAssessmentTable } from './EsrsGroupAssessmentTable';
import { useGetEsrsAssessmentList } from './EsrsAssessment.hooks';

export type EsrsAssessmentEditFields = {
  id: string;
  reportingYear: Date;
};

const EsrsAssessmentsEmptyState = ({
  onCallToActionClick,
}: {
  onCallToActionClick: () => void;
}) => {
  const { t } = useTranslation(['common', 'esrs']);
  return (
    <Box w="100%" flexGrow="1">
      <EmptyState
        title={t('esrs:assessment.noReport')}
        description={t('esrs:assessment.noEsrsAssessmenttDescription')}
        callToAction={{
          text: t('common:assessment.createAssessment'),
          variant: 'primary',
          onClick: () => {
            onCallToActionClick();
          },
        }}
        icon={<PaiIndicatorsIllustration boxSize="120px" color="border.default" />}
      />
    </Box>
  );
};

export const EsrsAssessmentList = () => {
  const { t } = useTranslation(['common', 'esrs', 'assessment']);
  const { isOpen, onClose, onOpen } = useDisclosure();

  const { esrsAssessments, isGroup, loading } = useGetEsrsAssessmentList();

  return (
    <ContentLayout
      header={<ContentHeader title={t('esrs:assessment.header')} />}
      isLoading={loading}
    >
      {esrsAssessments.length ? (
        <VStack alignItems="start" w="100%" spacing="4px">
          <HStack width="100%" justifyContent="space-between">
            <Typography variant="h2">Assessments</Typography>
            <Button onClick={onOpen}>{t('common:assessment.createAssessment')}</Button>
          </HStack>
          <EsrsGroupAssessmentTable esrsGroupAssessments={esrsAssessments} isGroup={isGroup} />
        </VStack>
      ) : (
        <EsrsAssessmentsEmptyState onCallToActionClick={onOpen} />
      )}
      <EsrsAssessmentModal isOpen={isOpen} onClose={onClose} />
    </ContentLayout>
  );
};
