import { MetricsTableData } from '../../../MetricAnswers.hooks';

export const getRowName = (row: MetricsTableData) => {
  if (row.tagName) {
    if (row.parentMetric) {
      return `${row.metric.shortTitle ?? row.metric.title}`;
    }
    if (row?.subRows) {
      return `${row.metric.shortTitle ?? row.metric.title} ${row.tagName}`;
    }
    return row.tagName;
  }

  return `${row.metric.shortTitle ?? row.metric.title} ${row.tagType ? 'by ' : ''}`;
};
