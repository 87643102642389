import { Flex, Stack, HStack } from '@chakra-ui/react';
import { useUserData } from '@nhost/react';
import { NotificationsMenu, KnowledgeBase } from 'Features';
import CompanySwitcher from 'Features/CompanySwitcher/CompanySwitcher';
import { AvatarMenu } from 'Organisms';

export const TOP_MENU_HEIGHT = '48px';
export const TopMenu = () => {
  const user = useUserData();
  return (
    <Stack
      direction="row"
      padding="0px 16px 4px"
      bg="bg.default"
      width="100%"
      justifyContent="space-between"
      alignItems="flex-end"
      minHeight={TOP_MENU_HEIGHT}
      zIndex={3}
    >
      {user ? <CompanySwitcher user={user} /> : undefined}
      <Flex display="flex" gridGap="8px">
        <HStack spacing="6px">
          {user ? <NotificationsMenu /> : undefined}
          {user ? <KnowledgeBase /> : undefined}
        </HStack>
        <HStack spacing="0px">{user ? <AvatarMenu user={user} /> : undefined}</HStack>
      </Flex>
    </Stack>
  );
};
