import { Box, VStack } from '@chakra-ui/react';
import { Button } from 'Atoms/Buttons';
import { ArrowNarrowRightIcon } from 'Tokens/Icons/Direction';
import { Typography } from 'Tokens/Typography';
import React from 'react';
import { useNavigate } from 'react-router-dom';

type FrameworkBoxProps = {
  title: string;
  description: string;
  link: string;
};

export const FrameworkBox = ({ title, description, link }: FrameworkBoxProps) => {
  const navigate = useNavigate();

  return (
    <Box
      borderRadius="10px"
      shadow="interactive.accent"
      bg="bg.elevated.interactive"
      p="20px"
      pb="16px"
      onClick={() => navigate(link)}
      _hover={{ shadow: 'interactive.hover', background: 'bg.elevated.interactive.hover' }}
      cursor="pointer"
    >
      <VStack alignItems="start" spacing="20px">
        <VStack alignItems="start" spacing="6px">
          <Typography variant="h3" color="text.default">
            {title}
          </Typography>
          <Typography variant="body" color="text.muted">
            {description}
          </Typography>
        </VStack>
        <Button
          variant="body.strong"
          color="text.selected"
          size="sm"
          onClick={() => navigate(link)}
          ml="-5px"
          rightIcon={<ArrowNarrowRightIcon boxSize="18px" color="inherited" paddingTop="2px" />}
        >
          Open
        </Button>
      </VStack>
    </Box>
  );
};
