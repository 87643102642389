import {
  Center,
  VStack,
  StackProps,
  Image,
  ImageProps,
  Divider,
  Box,
  HStack,
  Popover,
  PopoverAnchor,
  PopoverContent,
  PopoverArrow,
} from '@chakra-ui/react';
import { Button, Link, Tooltip } from 'Atoms';
import { Typography } from 'Tokens';
import { useLocation, useNavigate } from 'react-router-dom';
import { SideMenuProps, SideMenuSection, SideMenuItem } from '.';
import { TFunction, useTranslation } from 'utils/translation';
import { useFeatureFlags, useHiddenFlags, useUserSetting } from '../Navigation.hooks';
import { useCurrentCompanyId, useCurrentCompany } from 'utils/hooks';
import { useMemo } from 'react';
import { CollapseButton, SideMenuItems } from './SideMenuItems';
import mixpanel from 'mixpanel-browser';
import { TRACKING_EVENTS } from 'utils/mixpanel';

export const getModuleName = (t: TFunction, name: string) =>
  name ? t(`common:module.${name}`) : '';

const CelsiaShortLogo = (props: ImageProps) => (
  <Image src="/assets/C.svg" alt="Celsia logo icon" {...props} />
);
const CelsiaLongLogo = (props: ImageProps) => (
  <Image src="/assets/elsia.svg" alt="Celsia logo icon" {...props} />
);

const CLOSED_SIDE_MENU_WIDTH = '56px';
const OPENED_SIDE_MENU_WIDTH = '228px';

const SideMenuWrapper = ({ isOpen, ...props }: { isOpen: boolean } & StackProps) => (
  <VStack
    maxWidth={isOpen ? OPENED_SIDE_MENU_WIDTH : CLOSED_SIDE_MENU_WIDTH}
    minWidth={isOpen ? OPENED_SIDE_MENU_WIDTH : CLOSED_SIDE_MENU_WIDTH}
    bg="bg.accent"
    color="border.onAccent"
    p="4px 6px 8px"
    spacing="0px"
    transition="all 0.4s"
    justifyContent="space-between"
    overflow="hidden"
    {...props}
  />
);

const HomeLink = ({ isOpen }: { isOpen: boolean }) => {
  const { companyId } = useCurrentCompanyId();
  return (
    <Link
      to={`/${companyId}`}
      color="white"
      textAlign="center"
      minHeight="56px"
      width="100%"
      borderRadius="8px"
      py="16px"
      position="relative"
      _hover={{ bg: 'bg.hover' }}
      _pressed={{ bg: 'bg.pressed' }}
    >
      <Center as="span" width="100%" height="100%">
        <HStack spacing="0px" width="100%" overflow="hidden" justifyContent="center">
          <CelsiaShortLogo
            boxSize="32px"
            left={isOpen ? '64.4px' : '6px'}
            position="absolute"
            transition="all 0.4s"
          />
          <CelsiaLongLogo
            height="32px"
            left={isOpen ? '64.4px' : '6px'}
            opacity={isOpen ? 1 : 0}
            position="absolute"
            transition="all 0.4s"
          />
        </HStack>
      </Center>
    </Link>
  );
};

export const SideMenuBlock = ({ item, isOpen }: { item: SideMenuItem; isOpen: boolean }) => {
  const { company } = useCurrentCompany();
  const flags = useFeatureFlags({ company });
  const { community: hasCommunityAccess } = useFeatureFlags({ company });
  const hiddenFlags = useHiddenFlags();
  const { pathname } = useLocation();
  const { t } = useTranslation(['common']);
  const navigate = useNavigate();
  const isHome = item?.path === '/';
  const isCurrent = () => (isHome ? pathname === `/${company?.id}` : pathname.includes(item.path));
  const [isCommunityPopupDismissed, setIsCommunityPopupDismissed] = useUserSetting(
    'community-popup-dismissed',
    false
  );

  const showCommunityPopup = useMemo(() => {
    return item.key === 'community' && !isCommunityPopupDismissed;
  }, [flags, item, isCommunityPopupDismissed]);

  if (hiddenFlags?.[item.key]) {
    return;
  }

  if (flags?.[item.key] === false && item.key !== 'community') {
    return (
      <Tooltip placement="right" label={item?.disabledTooltip} mt="1px">
        <Box
          key={item.path}
          color="text.onAccent.disabled"
          width="100%"
          textDecoration="none"
          bg="bg.accent"
          cursor="not-allowed"
        >
          <HStack as="span" spacing="8px" p="12px">
            <item.Icon boxSize="20px" color="inherit" />
            <Typography
              color="inherit"
              variant="bodyStrong"
              minWidth="164px"
              transition="all 0.4s"
              noOfLines={1}
              opacity={isOpen ? 1 : 0}
            >
              {getModuleName(t, item.key)}
            </Typography>
          </HStack>
        </Box>
      </Tooltip>
    );
  }

  return (
    <Popover
      placement="right"
      isOpen={showCommunityPopup}
      onClose={() => setIsCommunityPopupDismissed(true)}
      closeOnBlur={false}
      isLazy={true}
    >
      <Link
        key={item.path}
        to={item.path === '/' ? `/${company?.id}` : item.path}
        color="text.onAccent"
        bg={isCurrent() ? 'bg.onAccent.selected' : 'none'}
        width="100%"
        borderRadius="8px"
        textDecoration="none"
        cursor="pointer"
        _pressed={{ bg: 'bg.pressed' }}
        _hover={{
          bg: 'bg.onAccent.hover',
        }}
        onClick={() => {
          if (item.path === 'community') {
            mixpanel.track(TRACKING_EVENTS.SIDE_BAR.ESG_PORTAL, {
              path: item.path,
              hasESGPortalAccess: hasCommunityAccess,
            });
          }
        }}
      >
        <PopoverAnchor>
          <Box>
            <Tooltip
              placement="right"
              label={!isOpen && getModuleName(t, item.key)}
              mt="1px"
              maxW="200px"
            >
              <HStack as="span" spacing="8px" p="12px">
                <item.Icon boxSize="20px" color="inherit" />
                <Typography
                  color="inherit"
                  variant="bodyStrong"
                  minWidth="164px"
                  transition="all 0.4s"
                  noOfLines={1}
                  opacity={isOpen ? 1 : 0}
                >
                  {getModuleName(t, item.key)}
                </Typography>
              </HStack>
            </Tooltip>
          </Box>
        </PopoverAnchor>
      </Link>
      <PopoverContent bg="bg.promo" border="none" w="356px">
        <PopoverArrow bg="bg.promo" border="none" />
        <VStack w="100%" alignContent="start" padding="16px" spacing="4px">
          <Typography variant="h2" color="text.onAccent" w="100%">
            {t('common:popup.community.title')}
          </Typography>
          <Typography variant="body" color="text.onAccent">
            {t('common:popup.community.body')}
          </Typography>
          <HStack justify="end" w="100%">
            <Button
              variant="ghost"
              textColor="text.onAccent"
              onClick={() => setIsCommunityPopupDismissed(true)}
            >
              {t('common:popup.community.negativeAction')}
            </Button>
            <Button
              onClick={() => {
                setIsCommunityPopupDismissed(true);
                navigate(item.path);
              }}
            >
              {t('common:popup.community.positiveAction')}
            </Button>
          </HStack>
        </VStack>
      </PopoverContent>
    </Popover>
  );
};

export const SideMenu = ({ items }: SideMenuProps) => {
  // const [isOpen, setIsOpen] = useState(false);
  const [sideMenuState, setSideMenuState] = useUserSetting('side-menu-state', true);

  const toggle = () => setSideMenuState(!sideMenuState);

  return (
    <SideMenuWrapper isOpen={sideMenuState}>
      <VStack wrap="wrap" width="100%" spacing="2px" h="100%" alignItems="start">
        <HomeLink isOpen={sideMenuState} />\
        <SideMenuItems section={SideMenuSection.primary} isOpen={sideMenuState} items={items} />
        <Center px="12px" py="11px" width="100%" alignItems="flex-start">
          <Divider
            border="1px"
            borderRadius="3px"
            borderColor="border.onAccent"
            orientation="horizontal"
          />
        </Center>
        <SideMenuItems section={SideMenuSection.secondary} isOpen={sideMenuState} items={items} />
      </VStack>
      <VStack wrap="wrap" width="100%" spacing="16px" alignItems="start">
        <VStack spacing="2px" width={'100%'} py="4px">
          <SideMenuItems section={SideMenuSection.general} isOpen={sideMenuState} items={items} />
          <Center px="12px" py="11px" width="100%" alignItems="flex-start">
            <Divider
              border="1px"
              borderRadius="3px"
              borderColor="border.onAccent"
              orientation="horizontal"
            />
          </Center>
          <SideMenuItems section={SideMenuSection.esg} isOpen={sideMenuState} items={items} />
        </VStack>
        <CollapseButton isOpen={sideMenuState} toggle={toggle} />
      </VStack>
    </SideMenuWrapper>
  );
};
