import { HStack, VStack } from '@chakra-ui/react';
import { FrameworkBox } from 'Molecules/FrameworkBox';
import { Typography } from 'Tokens/Typography';
import { useFeatureFlags } from 'containers/Navigation';
import React from 'react';
import { useCurrentCompany } from 'utils/hooks';
import { useTranslation } from 'utils/translation';

export const HomeReportingFrameworks = () => {
  const { t } = useTranslation('home');
  const { company } = useCurrentCompany();
  const {
    assessments: hasTaxonomyAccess,
    pai: hasPaiAccess,
    esrs: hasESRSAccess,
  } = useFeatureFlags({ company });

  return (
    <VStack alignItems="stretch" justifyContent="flex-start" spacing="0px">
      <HStack height="100%" mb="8px">
        <Typography variant="bodyStrong" color="text.default">
          {t('home:cards.frameworks')}
        </Typography>
      </HStack>
      <HStack spacing="10px" height="100%" alignItems="stretch" pb="48px">
        {hasTaxonomyAccess && (
          <FrameworkBox
            title={t('home:cards.eutaxonomy.title')}
            description={t('home:cards.eutaxonomy.description')}
            link="assessments"
          />
        )}
        {hasESRSAccess && (
          <FrameworkBox
            title={t('home:cards.esrs.title')}
            description={t('home:cards.esrs.description')}
            link="esrs"
          />
        )}
        {hasPaiAccess && (
          <FrameworkBox
            title={t('home:cards.pai.title')}
            description={t('home:cards.pai.description')}
            link="pai"
          />
        )}
      </HStack>
    </VStack>
  );
};
