import { HStack } from '@chakra-ui/react';
import { Button, ButtonSize } from 'Atoms';

export const ViewSwitcher = ({
  setSelected,
  selected,
  views,
  size = 'md',
}: {
  setSelected: (val: string) => void;
  selected: string;
  views: string[];
  size?: ButtonSize;
}) => {
  return (
    <HStack spacing="0px" borderRadius="8px" borderColor="border.decorative">
      {views.map((view, index) => (
        <Button
          key={view}
          size={size}
          variant="secondary"
          bgColor={selected === view ? 'bg.selected' : undefined}
          border={selected === view ? 'none' : '1px solid'}
          borderLeft={index !== 0 ? '0px' : undefined}
          borderRight={index !== 1 ? '0px' : undefined}
          borderRadius={
            index === 0 ? '8px 0px 0px 8px' : index === views.length - 1 ? '0px 8px 8px 0px' : '0px'
          }
          borderColor="border.decorative"
          onClick={() => setSelected(view)}
        >
          {view}
        </Button>
      ))}
    </HStack>
  );
};
