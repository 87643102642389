import { useColorMode } from '@chakra-ui/react';
import { useMemo } from 'react';
import { ResponsiveContainer, BarChart, CartesianGrid, XAxis, YAxis, Tooltip, Bar } from 'recharts';
import { colors } from 'Tokens';
import { stringToColor } from 'utils/generateRandomColors';
import { FiltersType } from '../MetricChart.hooks';
import { MetricChartCustomTooltip } from './MetricChartCustomTooltip';

export const MetricBarGraph = ({
  chartData,
  title,
  filters,
  selectedTagValues,
  isYearly,
}: {
  chartData: {
    [a: string]: string | number;
  }[];
  title: string;
  filters: FiltersType;
  selectedTagValues: string[];
  isYearly: boolean;
}) => {
  const { colorMode } = useColorMode();
  const isDarkMode = useMemo(() => colorMode === 'dark', [colorMode]);
  const color = useMemo(() => (isDarkMode ? '_dark' : 'default'), [isDarkMode]);

  return (
    <ResponsiveContainer width="100%">
      <BarChart
        data={chartData}
        margin={{
          top: 10,
          right: 0,
          left: -15,
          bottom: 0,
        }}
      >
        <CartesianGrid vertical={false} stroke={colors['bg.interactive.accent'][color]} />
        <XAxis
          dataKey="name"
          fontSize="12px"
          stroke="transparent"
          tick={{
            fill: colors['text.hint'][color],
          }}
        />
        <YAxis
          fontSize="12px"
          stroke="transparent"
          tick={{
            fill: colors['text.hint'][color],
          }}
        />
        <Tooltip
          content={
            <MetricChartCustomTooltip metricTitle={title} isBreakdown={!!filters.tagBreakdown} />
          }
          shared={false}
        />
        {filters.tagBreakdown ? (
          selectedTagValues?.map((tagValue) => (
            <Bar
              dataKey={tagValue}
              barSize={isYearly ? 100 : 36}
              stackId="a"
              fill={stringToColor(tagValue)}
            />
          ))
        ) : (
          <Bar dataKey="value" barSize={isYearly ? 100 : 36} stackId="a" fill="#9999FF" />
        )}
      </BarChart>
    </ResponsiveContainer>
  );
};
