import { Center } from '@chakra-ui/react';
import { Select } from 'Molecules';
import { Typography } from 'Tokens';
import { FilterChange } from '../MetricChart';
import { MetricChartMenuBox } from './MetricChartMenuBox';

export const MetricChartTagsBreakdown = ({
  tagOptions,
  isBreakdownAvailable,
  handleFilterChange,
}: {
  tagOptions: string[];
  isBreakdownAvailable: boolean;
  handleFilterChange: FilterChange;
}) => {
  return (
    <MetricChartMenuBox title="Breakdown">
      {isBreakdownAvailable ? (
        <Select
          isMulti={false}
          isClearable={true}
          options={
            tagOptions?.map((tag) => ({
              label: tag,
              value: tag,
            })) ?? []
          }
          onChange={(option) => handleFilterChange('tagBreakdown', option?.value)}
        />
      ) : (
        <Center w="100%" h="36px" alignItems="start">
          <Typography variant="body" color="text.hint">
            No breakdown available
          </Typography>
        </Center>
      )}
    </MetricChartMenuBox>
  );
};
