import { Button, EmptyState, Tabs, Tooltip, VisibleIf, Tag } from 'Atoms';
import { AvatarGroup, BackButton, ContentHeader, ContentLayout } from 'Molecules';
import { useEffect, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'utils/translation';
import { useCurrentCompanyId } from 'utils/hooks';
import { ConsolidatedFinancials } from '../ConsolidateFinancials';
import { ConsolidatedResults } from '../ConsolidatedResults/ConsolidatedResults';
import { GroupCompanies } from '../Companies/GroupCompanies';
import { Box, Divider, HStack, IconButton, useDisclosure } from '@chakra-ui/react';
import {
  CompanyAssessmentDocument_,
  GroupsQuery_,
  InvestorsQuery_,
  useCompanyAssessmentQuery,
  useGroupAssessmentQuery,
  useGroupsQuery,
  useInvestorsQuery,
  useUpdateGroupAssessmentLockMutation,
} from 'models';
import { stringToYear } from 'utils/date';
import { StatusSelector } from 'containers/Assessments/pieces/StatusSelector';
import { PrivateIcon } from 'Tokens/Icons/Function';
import { ShareGroupAssessmentModal } from './ShareAssessmentModal';
import { CompanyIllustration } from 'Tokens';
import { SelectCompaniesModal } from '../Companies/SelectCompaniesModal';
import { groupOwnerContext } from 'containers/GroupAssessment/GroupAssessment.hooks';

const CompaniesSharedWith = ({
  groupsData,
  investorsData,
  consolidatedCompanyAssessmentId,
}: {
  groupsData: GroupsQuery_ | undefined;
  investorsData: InvestorsQuery_ | undefined;
  consolidatedCompanyAssessmentId?: string;
}) => {
  const { t } = useTranslation('group');

  const sharedWith = useMemo(() => {
    return investorsData?.investors
      .filter(
        (portfolio) => portfolio.sharedCompanyAssessmentId === consolidatedCompanyAssessmentId
      )
      .map((p) => {
        return {
          logo: p.portfolio.ownerCompany.logoUrl,
          companyName: p.portfolio.ownerCompany.name,
        };
      })
      .concat(
        ...(groupsData?.groups
          .filter((group) => group.sharedCompanyAssessmentId === consolidatedCompanyAssessmentId)
          .map((g) => {
            return {
              logo: g.ownerGroupAssessment.company?.logoUrl,
              companyName: g.ownerGroupAssessment.company?.name ?? '',
            };
          }) ?? [])
      );
  }, [investorsData, groupsData]);

  return (
    <>
      {sharedWith?.length ? (
        <AvatarGroup
          namesOrLogos={sharedWith?.map((c) => {
            return {
              name: c.companyName ?? '',
              logoUrl: c.logo ?? '',
            };
          })}
        />
      ) : (
        <Tooltip label={t('assessment.privateTooltip')}>
          <Box>
            <IconButton
              size="md"
              variant="ghost"
              icon={<PrivateIcon />}
              aria-label="Not shared"
              backgroundColor="bg.unknown"
            />
          </Box>
        </Tooltip>
      )}
    </>
  );
};

export const GroupAssessment = () => {
  const { gAssessmentId } = useParams();
  const navigate = useNavigate();
  const { currentTab } = useParams();
  const { t } = useTranslation('group');
  const { companyId } = useCurrentCompanyId();
  const [
    updateAssessmentLock,
    { loading: updateAssessmentLockLoading, error: updateAssessmentLockError },
  ] = useUpdateGroupAssessmentLockMutation();
  const { isOpen, onClose, onOpen } = useDisclosure();
  const { isOpen: isSubOpen, onClose: onSubClose, onOpen: onSubOpen } = useDisclosure();

  useEffect(() => {
    if (!currentTab) {
      navigate('companies', { replace: true });
    }
  }, [currentTab]);

  const { data, loading } = useGroupAssessmentQuery({
    variables: {
      assessmentId: gAssessmentId,
    },
    skip: !gAssessmentId,
    context: groupOwnerContext,
  });

  const assessment = useMemo(() => {
    return data?.assessment;
  }, [data]);

  const { data: investorsData, loading: investorLoading } = useInvestorsQuery({
    variables: {
      companyId,
    },
    skip: !companyId,
  });

  const { data: groupsData, loading: groupLoading } = useGroupsQuery({
    variables: {
      companyId,
    },
    skip: !companyId,
  });

  const { data: consolidatedAssessmentData } = useCompanyAssessmentQuery({
    variables: {
      cAssessmentId: assessment?.consolidatedCompanyAssessmentId,
    },
    skip: !assessment?.consolidatedCompanyAssessmentId,
  });

  const consolidatedAssessment = useMemo(() => {
    return consolidatedAssessmentData?.companyAssessment;
  }, [consolidatedAssessmentData]);

  const hasSharableAssessments = useMemo(() => {
    return (
      investorsData?.investors.some(
        (portfolio) =>
          portfolio.sharedCompanyAssessmentId === assessment?.consolidatedCompanyAssessmentId ||
          portfolio.sharedCompanyAssessmentId === null
      ) ||
      groupsData?.groups.some(
        (group) =>
          group.sharedCompanyAssessmentId === assessment?.consolidatedCompanyAssessmentId ||
          group.sharedCompanyAssessmentId === null
      )
    );
  }, [investorsData, groupsData]);

  const unlockAssessment = () => {
    updateAssessmentLock({
      variables: { cAssessmentId: assessment?.consolidatedCompanyAssessmentId, isLocked: false },
      refetchQueries: [CompanyAssessmentDocument_],
    });
  };

  const lockAssessment = () => {
    updateAssessmentLock({
      variables: { cAssessmentId: assessment?.consolidatedCompanyAssessmentId, isLocked: true },
      refetchQueries: [CompanyAssessmentDocument_],
    });
  };

  return (
    <ContentLayout
      backButton={<BackButton onClick={() => navigate(`/${companyId}/assessments`)} />}
      header={
        <ContentHeader
          title={assessment?.name ?? ''}
          titleExtra={<Tag size="xs">{stringToYear(assessment?.period)}</Tag>}
          actions={[
            <HStack>
              <StatusSelector
                companyAssessment={consolidatedAssessment}
                unlockAssessment={unlockAssessment}
                lockAssessment={lockAssessment}
                lockLoading={updateAssessmentLockLoading}
                lockError={updateAssessmentLockError}
              />
              <VisibleIf
                condition={hasSharableAssessments === true}
                children={
                  <HStack>
                    <Divider
                      orientation="vertical"
                      border="1px"
                      borderRadius="3px"
                      height="20px"
                      borderColor="border.decorative"
                      backgroundColor="border.decorative"
                      ml="12px !important"
                      mr="4px !important"
                    />

                    <CompaniesSharedWith
                      groupsData={groupsData}
                      investorsData={investorsData}
                      consolidatedCompanyAssessmentId={assessment?.consolidatedCompanyAssessmentId}
                    />
                    <Button variant="primary" onClick={onOpen} isDisabled={!hasSharableAssessments}>
                      {t('assessment.status.share')}
                    </Button>
                  </HStack>
                }
              ></VisibleIf>
            </HStack>,
          ]}
        />
      }
      isLoading={loading || investorLoading || groupLoading}
    >
      {!assessment?.subsidiaries.length ? (
        <Box w="100%" flexGrow="1">
          <EmptyState
            title={t('companies.empty.title')}
            description={t('companies.empty.description')}
            callToAction={{
              text: t('companies.addSubsidiary'),
              variant: 'primary',
              onClick: () => {
                onSubOpen();
              },
            }}
            icon={<CompanyIllustration boxSize="120px" />}
          />
          <SelectCompaniesModal isOpen={isSubOpen} onClose={onSubClose} assessment={assessment} />
        </Box>
      ) : (
        <Tabs
          defaultIndex={0}
          currentTab={currentTab}
          onChange={(newTab) => navigate(`${newTab}`)}
          items={[
            {
              title: t('group:assessment.tabs.companies'),
              id: 'companies',
              content: <GroupCompanies assessment={assessment} />,
            },
            {
              title: t('group:assessment.tabs.financials'),
              id: 'financials',
              content: <ConsolidatedFinancials assessment={assessment} />,
            },
            {
              title: t('group:assessment.tabs.results'),
              id: 'results',
              content: <ConsolidatedResults assessment={assessment} />,
            },
          ]}
        />
      )}
      <ShareGroupAssessmentModal
        isOpen={isOpen}
        onClose={onClose}
        investorsData={investorsData}
        groupsData={groupsData}
      />
    </ContentLayout>
  );
};
