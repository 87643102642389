import { Divider, VStack } from '@chakra-ui/react';
import { IconButton } from 'Atoms';
import { AddIcon, MinusIcon, RotateIcon } from 'Tokens/Icons/Function';

export const ImageControlsToolbar = ({
  zoom,
  setZoom,
  rotation,
  setRotation,
}: {
  zoom: number;
  setZoom: (val: number) => void;
  rotation: number;
  setRotation: (val: number) => void;
}) => {
  return (
    <VStack
      bg="bg.elevated.overlay"
      position="absolute"
      left="12px"
      zIndex={999}
      top="50%"
      borderRadius="6px"
      transform="translateY(-50%)"
      padding="2px"
      gap="4px"
    >
      <IconButton
        size="sm"
        variant="ghost"
        aria-label="zoom-in"
        icon={<AddIcon />}
        onClick={() => zoom <= 2.5 && setZoom(zoom + 0.1)}
      />
      <IconButton
        size="sm"
        variant="ghost"
        aria-label="zoom-in"
        icon={<MinusIcon />}
        onClick={() => zoom > 1 && setZoom(zoom - 0.1)}
      />
      <Divider borderWidth="1px" borderRadius="3px" w="50%" borderColor="border.decorative" />
      <IconButton
        size="sm"
        variant="ghost"
        aria-label="zoom-in"
        icon={<RotateIcon />}
        onClick={() => setRotation(rotation + 90)}
      />
    </VStack>
  );
};
