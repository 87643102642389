import {
  AttachmentBox_Constraint_,
  AttachmentBox_Update_Column_,
  EsrsAssessmentDocument_,
  Esrs_MaterialMetric_Constraint_,
  Esrs_MaterialMetric_Update_Column_,
  Esrs_Policy_Constraint_,
  GetActionsDrDocument_,
  GetDisclosureRequirementGroupsDocument_,
  GetBuDisclosureRequirementMetricsDocument_,
  GetMaterialStandardDocument_,
  GetMetricsDrDocument_,
  GetOnboardingEsrsAssessmentDocument_,
  GetParentMaterialityDocument_,
  GetPoliciesDrDocument_,
  GetTargetsDrDocument_,
  MaterialStandardMaterialRequirementsDocument_,
  NoteHistory_Constraint_,
  NoteHistory_Update_Column_,
  useCopyMultipleMaterialStandardsMutation,
  GetParentAssessmentToCopyQuery_,
  Esrs_Policy_Update_Column_,
} from 'models';
import { useCallback } from 'react';
import { DataCollectionLevel } from '../DataCollection';

type ParentStandardsType =
  | NonNullable<
      NonNullable<GetParentAssessmentToCopyQuery_['EsrsAssessment_by_pk']>['parentAssessment']
    >['materialStandards']
  | undefined;

export const useCopyParentAssessment = () => {
  const [addAssessment] = useCopyMultipleMaterialStandardsMutation();
  return useCallback((parentStandards: ParentStandardsType, esrsAssessmentId: string) => {
    return addAssessment({
      variables: {
        materialStandards:
          parentStandards?.map((standard) => ({
            assessmentId: esrsAssessmentId,
            standardRef: standard.standardRef,
            isDataGatheringOnly: true,
            isMaterial:
              standard.isMaterial &&
              standard.materialMetrics
                .filter((m) => m.childrenMetrics_aggregate.aggregate?.count === 0)
                .some((m) => m.dataCollection === DataCollectionLevel.subsidiaries),
            materialMetrics: {
              data: standard.materialMetrics.map(
                ({ metricRef, isMaterial, frequency, dataCollection }) => ({
                  metricRef,
                  isMaterial:
                    dataCollection === DataCollectionLevel.subsidiaries ? isMaterial : false,
                  isDataGatheringOnly: true,
                  frequency,
                })
              ),
              on_conflict: {
                constraint:
                  Esrs_MaterialMetric_Constraint_.MaterialMetricMaterialStandardIdMetricRefKey_,
                update_columns: [
                  Esrs_MaterialMetric_Update_Column_.IsMaterial_,
                  Esrs_MaterialMetric_Update_Column_.DataCollection_,
                ],
              },
            },
            policies: {
              data: standard.standard.disclosureRequirementGroups
                .flatMap((group) => group.policyRequirements)
                .flatMap((req) => req.reference)
                ?.map((disclosure) => ({
                  disclosureRequirementRef: disclosure,
                  noteHistory: {
                    data: {},
                    on_conflict: {
                      constraint: NoteHistory_Constraint_.NoteHistoryPolicyIdKey_,
                      update_columns: [],
                    },
                  },
                  attachmentBox: {
                    data: {},
                    on_conflict: {
                      constraint: AttachmentBox_Constraint_.AttachmentBoxPoliciesIdKey_,
                      update_columns: [],
                    },
                  },
                })),
              on_conflict: {
                constraint: Esrs_Policy_Constraint_.PolicyAssessmentIdDisclosureRequirementRefKey_,
                update_columns: [Esrs_Policy_Update_Column_.DisclosureRequirementRef_],
              },
            },
            attachmentBox: {
              data: {},
              on_conflict: {
                constraint: AttachmentBox_Constraint_.AttachmentBoxMaterialityAssessmentIdKey_,
                update_columns: [AttachmentBox_Update_Column_.MaterialStandardId_],
              },
            },
            noteHistory: {
              data: {},
              on_conflict: {
                constraint: NoteHistory_Constraint_.NoteHistoryMaterialityAssessmentIdKey_,
                update_columns: [NoteHistory_Update_Column_.MaterialStandardId_],
              },
            },
          })) ?? [],
      },
      refetchQueries: [
        GetOnboardingEsrsAssessmentDocument_,
        EsrsAssessmentDocument_,
        GetMaterialStandardDocument_,
        GetParentMaterialityDocument_,
        MaterialStandardMaterialRequirementsDocument_,
        GetDisclosureRequirementGroupsDocument_,
        GetTargetsDrDocument_,
        GetActionsDrDocument_,
        GetPoliciesDrDocument_,
        GetMetricsDrDocument_,
        GetBuDisclosureRequirementMetricsDocument_,
      ],
    });
  }, []);
};
