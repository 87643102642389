import { Box, HStack, VStack } from '@chakra-ui/react';
import { Typography } from 'Tokens';
import { CheckIcon } from 'Tokens/Icons/Status';

const StepOutline = ({ noBorder }: { noBorder?: boolean }) => {
  return (
    <VStack position="relative" h="100%" gap="0px">
      <Box borderRadius="50%">
        <Box
          border="1px dashed"
          borderColor="border.hover"
          borderRadius="50%"
          boxSize="28px"
          position="relative"
        >
          <CheckIcon
            position="absolute"
            boxSize="16px"
            color="border.hover"
            top="50%"
            left="50%"
            transform="translate(-50%, -50%)"
          />
        </Box>
      </Box>
      {!noBorder && (
        <Box borderLeft="1px dashed" borderColor="border.hover" height={`calc(100% - 28px)`} />
      )}
    </VStack>
  );
};

export const StepWrapper = ({
  title,
  subtitle,
  isLastStep,
  children,
}: {
  title?: string;
  subtitle?: string;
  isLastStep?: boolean;
  children?: React.ReactNode[] | React.ReactNode | string;
}) => {
  return (
    <HStack alignItems="start" gap="16px" w="100%">
      <StepOutline noBorder={isLastStep} />
      <VStack alignItems="start" gap="16px" pb="48px" w="100%">
        <VStack alignItems="start" gap="4px" maxWidth="903px">
          <Typography variant="h2">{title}</Typography>
          <Typography variant="body">{subtitle}</Typography>
        </VStack>
        {children}
      </VStack>
    </HStack>
  );
};
