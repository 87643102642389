import {
  Box,
  Divider,
  HStack,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  VStack,
} from '@chakra-ui/react';
import { Button } from 'Atoms';
import { SearchInput } from 'Molecules/SearchInput';
import { Typography } from 'Tokens';
import { CheckIcon } from 'Tokens/Icons/Status';
import { ReactElement, useMemo, useState } from 'react';

const YearMenu = ({
  showCurrentYear,
  startDate,
  year,
  handleYear,
  value,
  disabledAddedValues,
}: {
  showCurrentYear?: boolean;
  startDate?: number;
  year: number;
  handleYear: (param: number) => void;
  value: number[] | number;
  disabledAddedValues?: number[];
}) => {
  const isSelected = useMemo(
    () => (Array.isArray(value) ? value.includes(year) : value === year),
    [value, year]
  );
  const isDisabled = useMemo(
    () => isSelected && disabledAddedValues?.includes(year),
    [isSelected, disabledAddedValues, year]
  );

  return (
    <MenuItem onClick={() => handleYear(year)} isDisabled={isDisabled}>
      <HStack justifyContent="space-between" w="100%">
        <VStack gap="0px" alignItems="start">
          <HStack gap="4px">
            <Typography
              variant="bodyStrong"
              color={isDisabled ? 'text.disabled' : isSelected ? 'text.selected' : 'text.default'}
            >
              {year}
            </Typography>{' '}
            {showCurrentYear && year === (startDate ?? new Date().getFullYear()) && (
              <Typography
                variant="body"
                color={isDisabled ? 'text.disabled' : isSelected ? 'text.selected' : 'text.hint'}
              >
                (current year)
              </Typography>
            )}
          </HStack>
          {isDisabled && <Typography variant="detail">Already added</Typography>}
        </VStack>

        {isSelected && <CheckIcon color={isDisabled ? 'text.disabled' : 'text.selected'} />}
      </HStack>
    </MenuItem>
  );
};

export const YearSelector = ({
  buttonText,
  buttonLeftIcon,
  value,
  setValue,
  confirmButtonText,
  disabledAddedValues,
  closeOnSelect = true,
  allowMultiple,
  showCurrentYear,
  onChange,
  onConfirm,
  startDate,
  title,
  previousYearsMode,
}: {
  buttonText?: string;
  buttonLeftIcon?: ReactElement;
  value: number[] | number;
  setValue: ((val: number[]) => void) | ((val: number) => void);
  confirmButtonText?: string;
  disabledAddedValues?: number[];
  closeOnSelect?: boolean;
  allowMultiple?: boolean;
  showCurrentYear?: boolean;
  onChange?: () => void;
  onConfirm?: () => void;
  startDate?: number;
  title?: string;
  previousYearsMode?: boolean;
}) => {
  const [search, setSearch] = useState('');
  const [isMenuOpen, setIsMenuOpen] = useState<boolean | undefined>();
  const years = useMemo(() => {
    const min = startDate ?? new Date().getFullYear();
    const max = min + 100;

    const yearsArray = previousYearsMode
      ? Array.from({ length: 100 }, (_, index) => min + 1 - index - 1)
      : Array.from({ length: max - min + 1 }, (_, index) => min + index);

    return yearsArray;
  }, [startDate]);
  const filteredYears = useMemo(
    () => years.filter((year) => String(year).includes(search)),
    [years, search]
  );
  const handleYear = (year: number) => {
    if (Array.isArray(value) && allowMultiple) {
      if (value.includes(year)) {
        (setValue as (val: number[]) => void)(value.filter((y) => y !== year));
      } else (setValue as (val: number[]) => void)([...value, year]);
    } else if (!allowMultiple) {
      (setValue as (val: number) => void)(year);
      if (closeOnSelect) {
        setIsMenuOpen(false);
      }
    }
    if (onChange) {
      onChange();
    }
  };

  return (
    <Menu closeOnSelect={false} isOpen={showCurrentYear ? undefined : isMenuOpen}>
      <MenuButton
        as={Button}
        variant="ghost"
        w="fit-content"
        leftIcon={buttonLeftIcon}
        onClick={() => {
          if (!allowMultiple) setIsMenuOpen(!isMenuOpen);
          else setIsMenuOpen(undefined);
        }}
        transform="translateX(-8px)"
      >
        <Typography variant="bodyStrong">{buttonText}</Typography>
      </MenuButton>
      <MenuList p="8px" w={showCurrentYear ? '190px' : '172px'}>
        {title && (
          <Typography variant="bodyStrong" mb="4px">
            {title}
          </Typography>
        )}
        <SearchInput
          width="100%"
          placeholder="Search"
          search={search}
          setSearch={setSearch}
          marginBottom="4px"
        />
        <Box
          maxH="160px"
          overflowY="auto"
          css={{
            '&::-webkit-scrollbar': {
              display: 'none',
            },
          }}
        >
          {filteredYears.map((year) => {
            return (
              <YearMenu
                year={year}
                handleYear={handleYear}
                startDate={startDate}
                value={value}
                showCurrentYear={showCurrentYear}
                disabledAddedValues={disabledAddedValues}
              />
            );
          })}
        </Box>

        {onConfirm && (
          <VStack gap="0px">
            <Divider color="border.decorative" mb="8px" ml="-8px" pr="16px" />
            <Box w="100%">
              <Button
                variant="primary"
                w="100%"
                onClick={() => {
                  onConfirm();
                  setIsMenuOpen(false);
                }}
              >
                {confirmButtonText ?? 'Add'}
              </Button>
            </Box>
          </VStack>
        )}
      </MenuList>
    </Menu>
  );
};
