import { HStack, StackProps } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { Typography, TypographyProps } from 'Tokens';
import { LinkIcon } from 'Tokens/Icons/Function';
import { useToast } from 'utils/hooks';

export const LinkAnchor = ({
  id,
  text,
  variant = 'h4',
  marginLeft,
  hideIcon,
  reference,
}: {
  id: string;
  text?: string;
  variant?: TypographyProps['variant'];
  marginLeft?: StackProps['marginLeft'];
  hideIcon?: boolean;
  reference?: string;
}) => {
  const toast = useToast();
  const handleLinkClick = () => {
    const url = window.location.origin + window.location.pathname + `?metricRef=${id}`;
    navigator.clipboard.writeText(url);
    toast({
      text: 'Link copied to clipboard',
      duration: 2000,
      closable: false,
      fitContent: true,
    });
  };
  return (
    <HStack spacing="4px" alignItems="start" marginLeft={marginLeft}>
      <Link to={`?metricRef=${id}`} onClick={handleLinkClick}>
        {!hideIcon && (
          <LinkIcon
            color="text.muted"
            boxSize="16px"
            opacity={0}
            transition="opacity 0.2s"
            _hover={{ opacity: 1 }}
            alignItems="center"
            mt="4px"
          />
        )}
      </Link>
      {text && (
        <Typography variant={variant} maxWidth="680px" as="span">
          {reference && (
            <Typography variant="body" color="text.hint" as="span" mr="6px">
              {reference}
            </Typography>
          )}
          {text}
        </Typography>
      )}
    </HStack>
  );
};
