import {
  ActionFields,
  DREnums,
  useAssessmentReportingUnits,
  useMaterialStandardId,
} from 'containers/Esrs';
import {
  Esrs_ActionTarget_Constraint_,
  GetActionsDocument_,
  Esrs_ActionReportingUnit_Constraint_,
  Esrs_ActionTarget_Update_Column_,
  useDeleteActionMutation,
  useDeleteActionTargetMutation,
  useUpsertActionMutation,
  ActionFieldsFragment_,
  AttachmentBox_Constraint_,
  NoteHistory_Constraint_,
  AttachmentBox_Update_Column_,
  NoteHistory_Update_Column_,
  DocumentationFileDetailsFragment_,
  useUpsertAttachmentsMutation,
  DocumentationFile,
  useAddNoteMutation,
  useGetActionDocumentationByIdQuery,
  AttachmentBox,
  NoteHistory,
  Esrs_ActionSubsidiary_Constraint_,
  useDeleteActionSubsidiaryMutation,
  useDeleteActionReportingsUnitMutation,
  EsrsAssessmentActionsDocument_,
  GetActionsDrDocument_,
  GetActionsQuery_,
  GetActionsDrQuery_,
  GetParentActionsDrQuery_,
  GetActionsSubsidiariesQuery_,
  useGetActionsDrQuery,
  useGetActionsSubsidiariesQuery,
  useGetParentActionsDrQuery,
  useGetTargetsQuery,
  GetDisclosureRequirementGroupsDocument_,
  ShortUser,
  useGetActionQuery,
  useActionQuestionsQuery,
  useGetSubsidiariesQuery,
  useUpsertDrawerActionMutation,
} from 'models';
import { useCallback, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useCompanyType, useCurrentCompany, useToast } from 'utils/hooks';
import { getDisclosureRequirementLink } from 'utils/links';

export const NEW_TARGET = 'new_target';

export type Action =
  | GetActionsQuery_['actions'][number]
  | NonNullable<
      GetActionsSubsidiariesQuery_['esrs']
    >['subsidiaries'][number]['materialStandards'][number]['actions'][number];

export type CombinedActionsData = {
  title: string;
  isSubOrParent: boolean;
  disclosureRequirementRef: string;
  earliestYear?: string;
  latestYear?: string;
  companyName?: string;
  targets: {
    title: string;
    id: number;
  }[];
  numberOfTargets: number;
  owner?: ShortUser | null;
  actionId: number;
  action: Action;
  standardRef: string;
};

export const useGetAddActionData = () => {
  const {
    esrsAssessmentId = '',
    standardRef = '',
    disclosureRequirementRef = '',
    actionId = '',
  } = useParams();
  const { isGroup } = useCompanyType();
  const { companyAssessmentId } = useMaterialStandardId(standardRef, esrsAssessmentId);

  const { data: actionData, loading: actionLoading } = useGetActionQuery({
    variables: {
      id: actionId,
    },
    skip: !actionId,
  });
  const action = useMemo(() => actionData?.esrs_Actions_by_pk ?? undefined, [actionData]);

  const { data: targets, loading: targetsLoading } = useGetTargetsQuery({
    variables: {
      assessmentId: companyAssessmentId,
      disclosureRequirementRef,
    },
    skip: !companyAssessmentId || !disclosureRequirementRef,
  });
  const targetsData = useMemo(() => targets?.targets ?? [], [targets]);

  const { reportingUnitAssessments: reportingUnits, loading: ruLoading } =
    useAssessmentReportingUnits(esrsAssessmentId ?? '');

  const { data: subsidiariesData, loading: subsidiariesLoading } = useGetSubsidiariesQuery({
    variables: { esrsAssessmentId },
    skip: !isGroup || !esrsAssessmentId,
  });
  const subsidiaries = useMemo(
    () => subsidiariesData?.esrs?.subsidiaries ?? [],
    [subsidiariesData]
  );

  const { data: questionsData, loading: questionsLoading } = useActionQuestionsQuery({
    variables: {
      standardRef,
    },
    skip: !standardRef,
  });
  const questions = useMemo(() => questionsData?.ActionQuestion, [questionsData]);

  const targetOptions = useMemo(
    () =>
      targetsData.map((target) => ({
        value: target.id,
        label: target.name,
      })),
    [targetsData]
  );
  return {
    action,
    isGroup,
    questions,
    targetOptions,
    targetsData,
    subsidiaries,
    reportingUnits,
    materialityAssessmentId: companyAssessmentId,
    loading:
      actionLoading || targetsLoading || questionsLoading || subsidiariesLoading || ruLoading,
  };
};

export const useDeleteAction = () => {
  const [deleteAction] = useDeleteActionMutation();
  const toast = useToast();
  const navigate = useNavigate();
  const {
    companyId = '',
    esrsAssessmentId = '',
    standardRef = '',
    disclosureRequirementRef = '',
  } = useParams();
  const link = getDisclosureRequirementLink({
    companyId,
    assessmentId: esrsAssessmentId,
    standardRef,
    disclosureRequirement: {
      reference: disclosureRequirementRef ?? '',
      type: DREnums.action,
    },
  });
  return useCallback(
    (actionId: string) => {
      deleteAction({
        variables: { actionId },
        refetchQueries: [
          GetActionsDrDocument_,
          GetActionsDocument_,
          GetDisclosureRequirementGroupsDocument_,
        ],
      })
        .then(() => {
          toast({ text: 'Action deleted' });
          navigate(link);
        })
        .catch(() => toast({ text: 'Failed to delete action', variant: 'danger' }));
    },
    [deleteAction]
  );
};

export const useUpsertAction = () => {
  const [upsertAction] = useUpsertActionMutation();
  const [upsertDrawerAction] = useUpsertDrawerActionMutation();
  const [deleteActionTarget] = useDeleteActionTargetMutation();
  const [deleteActionReportingUnit] = useDeleteActionReportingsUnitMutation();
  const [deleteActionSubsidiary] = useDeleteActionSubsidiaryMutation();
  const [upsertAttachments] = useUpsertAttachmentsMutation();
  const [addNote] = useAddNoteMutation();
  const toast = useToast();
  const navigate = useNavigate();
  const { companyId = '', esrsAssessmentId = '', standardRef = '' } = useParams();

  return useCallback(
    (params: {
      data: ActionFields;
      actionToEdit: ActionFieldsFragment_ | undefined;
      disclosureRequirementRef: string;
      materialityAssessmentId: string;
      isGroup: boolean;
      isDrawer: boolean;
      userId: string | undefined;
      drawerTargetId?: string;
      setNewAction?:
        | ((param: { value: string; label: string; estimate: number | null }) => void)
        | undefined;
      onClose?: () => void;
      note?: string | null;
      filesAttached?: DocumentationFileDetailsFragment_[];
      setNote?: (param: string | null) => void;
      setFilesAttached?: (param: DocumentationFileDetailsFragment_[]) => void;
      pageRedirect?: boolean;
    }) => {
      const {
        data,
        actionToEdit,
        disclosureRequirementRef,
        materialityAssessmentId,
        isGroup,
        isDrawer,
        userId,
        drawerTargetId,
        setNewAction,
        onClose,
        note,
        filesAttached,
        setFilesAttached,
        setNote,
      } = params;
      const {
        subsidiaries,
        reportingUnits,
        estimate,
        estimates,
        targets,
        documentation,
        dueDate,
        ...rest
      } = data;

      const actionTargetsToDelete: string[] = isDrawer
        ? []
        : actionToEdit?.actionTargets
            .filter(
              (actionTarget) =>
                !data.targets?.find((target) => target.value === actionTarget.target.id)
            )
            ?.map(({ id }) => id) ?? [];

      const drawerActionTarget = drawerTargetId
        ? [{ targetId: drawerTargetId, estimate: estimate }]
        : [];

      const changedActionTargets = targets?.filter(
        (target) =>
          !actionToEdit?.actionTargets?.find(
            (actionTarget) =>
              actionTarget.target.id === target.value &&
              actionTarget.estimate === Number(estimates?.[`${target.value}`])
          )
      );
      const chosenTargets = isDrawer
        ? drawerActionTarget
        : actionToEdit === undefined
          ? targets?.map((target) => ({
              targetId: target.value,
              estimate: Number(data?.estimates?.[`${target.value}`] ?? ''),
            }))
          : changedActionTargets?.map((target) => ({
              targetId: target.value,
              estimate: Number(estimates?.[`${target.value}`] ?? ''),
            })) ?? [];

      const reportingUnitToDelete: string[] = isGroup
        ? []
        : actionToEdit?.actionReportingUnits
            .filter((ru) => !reportingUnits?.find((ruA) => ruA.value === ru.reportingUnit.id))
            .map((ru) => ru.id) ?? [];

      const chosenReportingUnits = isGroup
        ? []
        : actionToEdit === undefined
          ? reportingUnits?.map((ru) => ({
              reportingUnitId: ru.value,
            })) ?? []
          : reportingUnits
              ?.filter(
                (ruA) =>
                  !actionToEdit?.actionReportingUnits?.find(
                    (ru) => ru.reportingUnit.id === ruA.value
                  )
              )
              ?.map((ru) => ({
                reportingUnitId: ru.value,
              })) ?? [];

      const subsidiariesToDelete: string[] = !isGroup
        ? []
        : actionToEdit?.actionSubsidiaries
            ?.filter((as) => !subsidiaries?.find((s) => s.value === as.id))
            ?.map((s) => s.id) ?? [];

      const chosenSubsidiaries = !isGroup
        ? []
        : actionToEdit === undefined
          ? subsidiaries?.map((s) => ({
              subsidiaryAssessmentId: s.value,
            }))
          : subsidiaries
              ?.filter((s) => !actionToEdit?.actionSubsidiaries?.find((as) => as.id === s.value))
              ?.map((s) => ({
                subsidiaryAssessmentId: s.value,
              })) ?? [];

      const noteAttachmentFields: { [key: string]: any } = {};

      if (!actionToEdit?.attachmentBox?.id) {
        noteAttachmentFields.attachmentBox = {
          data: {},
          on_conflict: {
            constraint: AttachmentBox_Constraint_.AttachmentBoxActionIdKey_,
            update_columns: [AttachmentBox_Update_Column_.ActionId_],
          },
        };
      }

      if (!actionToEdit?.noteHistory?.id) {
        noteAttachmentFields.noteHistory = {
          data: {},
          on_conflict: {
            constraint: NoteHistory_Constraint_.NoteHistoryActionIdKey_,
            update_columns: [NoteHistory_Update_Column_.ActionId_],
          },
        };
      }

      const actionRelation = isGroup
        ? {
            actionSubsidiaries: {
              data: chosenSubsidiaries ?? {},
              on_conflict: {
                constraint: Esrs_ActionSubsidiary_Constraint_.ActionSubsidiaryPkey_,
              },
            },
          }
        : {
            actionReportingUnits: {
              data: chosenReportingUnits ?? [],
              on_conflict: {
                constraint: Esrs_ActionReportingUnit_Constraint_.ActionReportingUnitPkey_,
              },
            },
          };

      (isDrawer ? upsertDrawerAction : upsertAction)({
        variables: {
          input: {
            id: actionToEdit?.id,
            disclosureRequirementRef,
            assessmentId: materialityAssessmentId,
            deadline: data.dueDate,
            ...rest,
            actionTargets: {
              data: chosenTargets,
              on_conflict: {
                constraint: Esrs_ActionTarget_Constraint_.ActionTargetActionIdTargetIdKey_,
                update_columns: [Esrs_ActionTarget_Update_Column_.Estimate_],
              },
            },
            ...actionRelation,
            ...noteAttachmentFields,
          },
        },
        refetchQueries: [
          GetActionsDrDocument_,
          GetActionsDocument_,
          GetDisclosureRequirementGroupsDocument_,
          EsrsAssessmentActionsDocument_,
        ],
        onCompleted: (res) => {
          setNewAction?.({
            value: res?.insert_esrs_Actions_one?.id ?? '',
            label: res?.insert_esrs_Actions_one?.title ?? data.title,
            estimate: estimate ?? null,
          });

          if (isDrawer && params.pageRedirect) {
            window.open(
              `${getDisclosureRequirementLink({
                companyId: actionToEdit?.materialStandard.esrsAssessment.company.id,
                assessmentId: actionToEdit?.materialStandard.assessmentId,
                standardRef,
                disclosureRequirement: {
                  reference: disclosureRequirementRef ?? '',
                  type: DREnums.action,
                },
              })}/action-form/${res.insert_esrs_Actions_one?.id}/edit`
            );
          }
        },
      })
        .then((res) => {
          if (filesAttached?.length) {
            const fileData =
              filesAttached?.map((file: DocumentationFile) => {
                return {
                  attachmentBoxId: res.data?.insert_esrs_Actions_one?.attachmentBox?.id,
                  fileId: file.id,
                };
              }) ?? [];

            upsertAttachments({
              variables: {
                attachmentInputs: fileData,
              },
            }).then(() => setFilesAttached?.([]));
          }
          if (note) {
            addNote({
              variables: {
                noteInput: {
                  body: note,
                  noteHistoryId: res.data?.insert_esrs_Actions_one?.noteHistory?.id,
                  authorId: userId,
                },
              },
            }).then(() => setNote?.(''));
          }
          deleteActionTarget({
            variables: {
              id: actionTargetsToDelete,
            },
            refetchQueries: [GetActionsDrDocument_, GetActionsDocument_],
          });
          deleteActionReportingUnit({
            variables: {
              id: reportingUnitToDelete,
            },
            refetchQueries: [GetActionsDrDocument_],
          });
          deleteActionSubsidiary({
            variables: {
              id: subsidiariesToDelete,
            },
            refetchQueries: [GetActionsDrDocument_],
          });
          toast({ text: actionToEdit ? 'Action edited' : 'Action added' });
          onClose?.();
          if (!isDrawer)
            navigate(
              getDisclosureRequirementLink({
                companyId,
                assessmentId: esrsAssessmentId,
                standardRef,
                disclosureRequirement: {
                  reference: disclosureRequirementRef ?? '',
                  type: DREnums.action,
                },
              })
            );
        })
        .catch(() => {
          toast({ text: 'Failed to add action', variant: 'danger' });
        });
    },
    [upsertAction]
  );
};

export const useCombineActions = (
  {
    actions,
    parent,
    subsidiaries,
  }: {
    actions?: GetActionsDrQuery_['DisclosureRequirement_by_pk'];
    parent?: GetParentActionsDrQuery_['esrs_Actions'];
    subsidiaries?: NonNullable<GetActionsSubsidiariesQuery_['esrs']>['subsidiaries'];
  },
  companyName?: string
) => {
  const mapTargets = (action: Action) => {
    return action.actionTargets.map((target: any) => ({
      title: target.target.name ?? '',
      id: target.target.id,
    }));
  };

  const mapAction = (action: Action, isSubOrParent: boolean, company?: string) => ({
    title: action.title,
    isSubOrParent,
    earliestYear: String(action.startDate).split('-')[0],
    latestYear: String(action.deadline).split('-')[0],
    disclosureRequirementRef: actions?.reference ?? '',
    companyName: company,
    targets: mapTargets(action),
    numberOfTargets: action.actionTargets?.length,
    owner: action.owner,
    actionId: action.id,
    action: action,
    standardRef: action.materialStandard.standard.reference,
  });

  const currentData = useMemo(
    () =>
      (actions && actions?.actions?.map((action) => mapAction(action, false, companyName))) ?? [],
    [actions]
  );

  const subsidiariesData = useMemo(
    () =>
      subsidiaries
        ?.flatMap((s) =>
          s.materialStandards[0]?.actions.map((action) => mapAction(action, true, s.company.name))
        )
        .filter((s) => s !== undefined) ?? [],
    [subsidiaries]
  );

  const parentData = useMemo(
    () =>
      parent
        ?.map((action) =>
          mapAction(action, true, parent?.[0]?.materialStandard.esrsAssessment.company.name)
        )
        .filter((p) => p !== undefined) ?? [],
    [parent]
  );

  const combinedData: CombinedActionsData[] = useMemo(
    () => currentData?.concat(subsidiariesData).concat(parentData) ?? [],
    [currentData, subsidiariesData, parentData]
  );

  return combinedData;
};

export const useGetActionsData = () => {
  const { esrsAssessmentId = '', standardRef = '', disclosureRequirementRef = '' } = useParams();

  const { company } = useCurrentCompany();
  const {
    companyAssessmentId,
    parentAssessmentId,
    loading: materialLoading,
  } = useMaterialStandardId(standardRef, esrsAssessmentId);

  const { data: actionsData, loading: actionsLoading } = useGetActionsDrQuery({
    variables: {
      reference: disclosureRequirementRef,
      esrsAssessmentId,
      standardRef,
    },
    skip: !disclosureRequirementRef || !esrsAssessmentId || !standardRef,
  });

  const actionDR = useMemo(() => actionsData?.DisclosureRequirement_by_pk, [actionsData]);

  const { data: targetsData, loading: targetsLoading } = useGetTargetsQuery({
    variables: {
      assessmentId: companyAssessmentId,
      disclosureRequirementRef,
    },
    skip: !companyAssessmentId || !disclosureRequirementRef,
  });

  const targets = useMemo(() => targetsData?.targets, [targetsData]);

  const { data: subData, loading: subsidiariesLoading } = useGetActionsSubsidiariesQuery({
    variables: {
      esrsAssessmentId,
      standardRef,
    },
    skip: !esrsAssessmentId || !standardRef || !company?.isGroupOwner,
  });

  const subsidiaries = useMemo(() => subData?.esrs?.subsidiaries, [subData]);

  const { data: pData, loading: parentLoading } = useGetParentActionsDrQuery({
    variables: {
      parentStandardId: parentAssessmentId,
    },
    skip: !parentAssessmentId || !standardRef,
  });
  const parentActions = useMemo(() => pData?.esrs_Actions, [pData]);

  const combinedData = useCombineActions(
    { actions: actionDR, parent: parentActions, subsidiaries },
    company?.name
  );

  return {
    actionDR,
    combinedData,
    targets,
    loading:
      actionsLoading || targetsLoading || subsidiariesLoading || parentLoading || materialLoading,
  };
};

export const useGetActionsDocumentation = (actionToEdit: ActionFieldsFragment_ | undefined) => {
  const { data: documentationData, loading: documentationLoading } =
    useGetActionDocumentationByIdQuery({
      variables: {
        actionId: actionToEdit?.id,
      },
      skip: !actionToEdit?.id,
    });

  const attachmentBox: AttachmentBox | undefined = useMemo(
    () => documentationData?.esrs_Actions_by_pk?.attachmentBox ?? undefined,
    [documentationData]
  );

  const noteHistory: NoteHistory | undefined = useMemo(
    () => documentationData?.esrs_Actions_by_pk?.noteHistory ?? undefined,
    [documentationData]
  );
  return {
    attachmentBox,
    noteHistory,
    documentationData,
    documentationLoading,
  };
};

export const getSubsidiaries = (action?: ActionFieldsFragment_) => {
  return (
    action?.actionSubsidiaries.map((s) => ({
      value: s.subsidiaryAssessment.id,
      label: s?.subsidiaryAssessment?.company?.name ?? '',
    })) ?? []
  );
};

export const getReportingUnits = (action?: ActionFieldsFragment_) => {
  return (
    action?.actionReportingUnits.map((ru) => ({
      value: ru.reportingUnit.id,
      label: ru.reportingUnit.name ?? '',
    })) ?? []
  );
};

export const getTargets = (action?: ActionFieldsFragment_) => {
  const targets =
    action?.actionTargets?.map((actionTarget) => ({
      value: actionTarget.target.id,
      label: actionTarget.target?.name?.toLowerCase(),
      estimate: actionTarget.estimate,
    })) ?? [];
  return targets;
};

export const getEstimates = (action?: ActionFieldsFragment_) => {
  const estimatesObject: { [key: string]: string } = {};
  action?.actionTargets?.forEach((item) => {
    estimatesObject[item.target.id] = String(item.estimate);
  });
  return estimatesObject;
};
