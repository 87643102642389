import {
  Box,
  HStack,
  Popover,
  PopoverArrow,
  PopoverCloseButton,
  PopoverContent,
  VStack,
} from '@chakra-ui/react';
import { IconButton, TruncatableText } from 'Atoms';
import { Typography } from 'Tokens';
import { HelpIcon } from 'Tokens/Icons/Status';
import { useMemo } from 'react';
import { PopoverTypes } from './Requirement';

const SIDEBAR_POPOVER_TEXT = {
  [PopoverTypes.closed]: {
    title: 'Always here',
    description:
      'Hover over here to view other disclosure requirements or to navigate back to your assessment overview. You can always switch back to have an expanded menu.',
  },
  [PopoverTypes.open]: {
    title: 'Need more space?',
    description: 'Collapse this menu and enjoy the full-screen focused mode',
  },
};

export const DisclosureRequirementTitle = ({
  title,
  onOpen,
  reference,
}: {
  title: string;
  onOpen: () => void;
  reference?: string;
}) => (
  <HStack>
    {reference && (
      <Typography variant="bodyLarge" color="text.hint">
        {reference}
      </Typography>
    )}
    <Typography variant="h3">{title}</Typography>
    <IconButton
      aria-label="learn more"
      variant="ghost"
      icon={<HelpIcon />}
      size="sm"
      onClick={() => onOpen()}
    />
  </HStack>
);

export const ListItem = ({
  key,
  text,
  related,
  company,
  extra,
  onClick,
}: {
  key: number;
  text: string;
  related: { text: string; color?: string };
  company: string;
  extra?: JSX.Element;
  onClick?: () => void;
}) => {
  return (
    <VStack alignItems="start" w="100%" key={key} onClick={onClick} cursor="pointer">
      <HStack
        h="44px"
        borderRadius="8px"
        p="12px 16px"
        w="100%"
        bg="bg.interactive"
        justifyContent="space-between"
      >
        <Box>
          <TruncatableText variant="bodyStrong" text={text} />
        </Box>
        <HStack spacing="12px">
          <Typography variant="body" color="text.default">
            {company}
          </Typography>
          <Typography variant="body" color={related.color ?? 'text.default'}>
            {related.text}
          </Typography>
          {extra}
        </HStack>
      </HStack>
    </VStack>
  );
};

export const SideBarPopover = ({
  popoverType,
  isSidebarClosed,
  children,
  isOpenedTooltipDismissed,
  isClosedTooltipDismissed,
  setClosedTooltipDismissed,
  setOpenedTooltipDismissed,
}: {
  popoverType: PopoverTypes.closed | PopoverTypes.open;
  isSidebarClosed: boolean;
  children: React.ReactNode;
  isOpenedTooltipDismissed: boolean;
  isClosedTooltipDismissed: boolean;
  setClosedTooltipDismissed: (val: boolean) => void;
  setOpenedTooltipDismissed: (val: boolean) => void;
}) => {
  const hideContent = useMemo(
    () => popoverType === PopoverTypes.closed && !isSidebarClosed,
    [popoverType, isSidebarClosed]
  );

  const isOpen = useMemo(() => {
    return popoverType === PopoverTypes.closed
      ? !isClosedTooltipDismissed
      : !isOpenedTooltipDismissed;
  }, [popoverType, isClosedTooltipDismissed, isOpenedTooltipDismissed]);

  const handleClose = () => {
    if (popoverType === PopoverTypes.closed) setClosedTooltipDismissed(true);
    else setOpenedTooltipDismissed(true);
  };

  return (
    <Popover
      placement={popoverType === PopoverTypes.closed ? 'right' : 'right-start'}
      offset={popoverType === PopoverTypes.closed ? [0, 8] : [0, 12]}
      isOpen={isOpen}
      isLazy={true}
      autoFocus={false}
      strategy="fixed"
      onClose={handleClose}
    >
      {children}
      {!hideContent && (
        <PopoverContent bg="bg.promo" border="none" p="16px" w="291px">
          <PopoverArrow bg="bg.promo" border="none" />
          <PopoverCloseButton color="text.onAccent" margin="8px" border="none" />
          <VStack gap="8px" alignItems="start" pr="24px">
            <Typography variant="h2" padding="0px" color="text.onAccent">
              {SIDEBAR_POPOVER_TEXT[popoverType].title}
            </Typography>
            <Typography variant="body" color="text.onAccent">
              {SIDEBAR_POPOVER_TEXT[popoverType].description}
            </Typography>
          </VStack>
        </PopoverContent>
      )}
    </Popover>
  );
};
