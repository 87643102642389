import { sortBy, uniqBy } from 'lodash';
import {
  Document,
  Paragraph,
  Table,
  TableCell,
  TableRow,
  WidthType,
  VerticalAlign,
  TextRun,
  LevelFormat,
  AlignmentType,
  convertInchesToTwip,
  Footer,
  PageNumber,
  NumberFormat,
} from 'docx';
import {
  AggregatedMetricsExtraData,
  AggregatedMetricsTableData,
  DREnums,
} from '../../DisclosureRequirements';
import {
  DocumentInput,
  EsrsReportCategory,
  EsrsReportDisclosureRequirement,
  EsrsReportMetric,
  EsrsReportStandard,
  ReportMDR,
  SimplifiedReportNarrativeMetric,
} from '../Report.types';
import { styles, tableBorders, TextStyle } from './reportThemes';
import { MdrmMetricAnswers } from '../MetricAnswerCalculation';
import { MDRM_QUESTIONS } from '../../DisclosureRequirements/Metrics/MetricSidebar/MetricMdrm';
import { ORDER_OF_CATEGORIES } from 'utils/categoriesOrder';
import { mapUnitToCompanyCurrency, percentageToNumber } from 'containers/Esrs/utils';
import { TableData } from 'Molecules/NestedTable';
import { ActionFieldsFragment_, QuestionType_Enum_, TargetFieldsFragment_ } from 'models';
import { simplifyNarrativeMetrics } from '../Report.hooks';
import { ClimateTargetQuestionRefs, adjustMDRReportData, getReportMDRA } from '../ReportUtils';

const getRowTitle = (row: AggregatedMetricsExtraData) => {
  const isTag = !!row.tags?.length;
  const metricTitle = row.metric?.shortTitle ?? row.metric?.title;
  if (row.tagName) {
    return row.tagName;
  } else if (isTag) {
    return row.tags?.[0]?.tagValue;
  } else {
    return `${metricTitle}${
      row.tagType && row.tagType !== 'undefined' ? ` by ${row.tagType} ` : ''
    }`;
  }
};

const getTableRowText = (row: TableData<AggregatedMetricsExtraData>) => {
  const percentage = row.percentage;
  const result = row.result?.Year ?? 0;
  const isResultNaN = isNaN(result);
  const parsedResult = isResultNaN ? 0 : parseFloat(result.toFixed(2));
  const stringifiedResult = String(parsedResult) ?? '--';

  return percentage ? stringifiedResult + ` (${percentage}%)` : stringifiedResult;
};

const addEmptySpace = (spacing: number): Paragraph => {
  return new Paragraph({
    text: '',
    spacing: {
      after: spacing,
    },
  });
};

export class EsrsDocumentCreator {
  public async create(input: DocumentInput, companyCurrency: string): Promise<Document> {
    const document = new Document({
      creator: 'Celsia.io',
      revision: 1,
      subject: 'Esrs report',
      title: `${input.data.companyName} - ESRS report ${input.data.reportingYear}`,
      styles: styles,
      numbering: {
        config: [
          {
            reference: 'number-system',
            levels: [
              {
                level: 0,
                format: LevelFormat.UPPER_ROMAN,
                text: '%1.',
                alignment: AlignmentType.START,
                style: {
                  paragraph: {
                    indent: { left: convertInchesToTwip(0.25), hanging: convertInchesToTwip(0.25) },
                  },
                },
              },
            ],
          },
        ],
      },
      sections: [
        {
          properties: {
            page: {
              margin: {
                left: convertInchesToTwip(1.25),
                right: convertInchesToTwip(1.25),
              },
              pageNumbers: {
                start: 1,
                formatType: NumberFormat.DECIMAL,
              },
            },
          },
          children: this.createSections(
            {
              categories: input.data.categories,
              companyName: input.data.companyName,
              reportingYear: input.data.reportingYear,
            },
            companyCurrency
          ),
          footers: {
            default: new Footer({
              children: [
                new Paragraph({
                  children: [
                    new TextRun({
                      children: [PageNumber.CURRENT],
                    }),
                  ],
                  alignment: AlignmentType.CENTER,
                }),
              ],
            }),
          },
        },
      ],
    });

    return document;
  }

  public createSections(
    {
      companyName,
      reportingYear,
      categories,
    }: {
      companyName: string;
      reportingYear: number;
      categories: EsrsReportCategory[];
    },
    companyCurrency: string
  ) {
    return [
      new Paragraph({
        text: `ESRS sustainability statement for ${companyName} ${reportingYear}`,
        style: TextStyle.title,
        spacing: {
          after: 400,
        },
      }),
      this.createCategories(categories ?? [], companyCurrency),
    ].flat();
  }

  public createCategories(categories: EsrsReportCategory[], companyCurrency: string) {
    const visibleCategories = categories
      .filter((category) =>
        category.standards.some((standard) =>
          standard.disclosureRequirements.some(
            (dr) => !dr.isHidden && dr.metrics.some((metric) => metric.completed)
          )
        )
      )
      .sort((a, b) => ORDER_OF_CATEGORIES[a.title] - ORDER_OF_CATEGORIES[b.title]);
    const displayedCategories = visibleCategories.flatMap((category) => {
      return [
        new Paragraph({
          text: category.title,
          style: TextStyle.h3,
          spacing: {
            after: 300,
          },
          numbering: {
            level: 0,
            reference: 'number-system',
          },
        }),
        this.createStandards(category.standards, companyCurrency),
      ];
    });
    return displayedCategories.flat();
  }

  public createStandards(standards: EsrsReportStandard[], companyCurrency: string) {
    const visibleStandards = standards
      .filter(
        (standard) =>
          !standard.disclosureRequirements.every(
            (dr) => dr.isHidden || dr.metrics.every((metric) => metric.metric.isHidden)
          )
      )
      .sort((a, b) => a.reference.localeCompare(b.reference));
    const displayedStandards = visibleStandards.flatMap((standard) => {
      return [
        new Paragraph({
          text: `${standard.reference} ${standard.title}`,
          style: TextStyle.h1,
          spacing: {
            after: 200,
          },
        }),
        this.createDisclosures(
          standard.disclosureRequirements,
          companyCurrency,
          standard.targetQuestions,
          standard.actionQuestions,
          standard.reference
        ),
      ];
    });
    return displayedStandards.flat();
  }

  public createDisclosures(
    disclosureRequirements: EsrsReportDisclosureRequirement[],
    companyCurrency: string,
    targetQuestions: EsrsReportStandard['targetQuestions'],
    actionQuestions: EsrsReportStandard['actionQuestions'],
    standardRef: string
  ) {
    const visibleDisclosureRequirements = disclosureRequirements.filter((dr) =>
      dr.metrics.some((metric) => !metric.metric.isHidden)
    );
    const displayedDisclosureRequirements = visibleDisclosureRequirements
      .sort((a, b) => a.order - b.order)
      .flatMap((dr) => {
        const isTarget = dr.type === DREnums.target;
        const isAction = dr.type === DREnums.action;
        const isPolicy = dr.type === DREnums.policy;
        if (isTarget || isAction || isPolicy) {
          const drTypeName = isTarget ? 'targets' : isAction ? 'actions' : 'policies';

          const withAssociationMetrics =
            dr.metrics.filter((metric) => metric.metric.metric.hasAssociation === true) ?? [];
          const withoutAssociationMetrics =
            dr.metrics.filter((metric) => metric.metric.metric.hasAssociation === false) ?? [];

          const withAssociationMetricsArray = withAssociationMetrics?.length
            ? [
                new Paragraph({
                  text: `Disclosures per standard if ${drTypeName} have not been set`,
                  style: TextStyle.h3,
                  spacing: {
                    after: 150,
                  },
                }),
                this.createMetrics(withAssociationMetrics, companyCurrency),
              ]
            : [];

          const withoutAssociationMetricsArray = withoutAssociationMetrics?.length
            ? [
                new Paragraph({
                  text: `Disclosures per standard if ${drTypeName} are set`,
                  style: TextStyle.h3,
                  spacing: {
                    after: 150,
                    before: 200,
                  },
                }),
                this.createMetrics(withoutAssociationMetrics, companyCurrency),
              ]
            : [];

          return [
            new Paragraph({
              text: `${dr.reference} – ${dr.title}`,
              style: TextStyle.h2,
              spacing: {
                after: 150,
              },
            }),
            ...withoutAssociationMetricsArray,
            isTarget
              ? this.createTargets(dr.targets, targetQuestions, standardRef)
              : this.createActions(dr.actions, actionQuestions),
            ...withAssociationMetricsArray,
          ];
        } else
          return [
            new Paragraph({
              text: `${dr.reference} – ${dr.title}`,
              style: TextStyle.h2,
              spacing: {
                after: 150,
              },
            }),
            this.createMetrics(dr.metrics, companyCurrency),
          ];
      });
    return displayedDisclosureRequirements.flat();
  }

  public createMetrics(metrics: EsrsReportMetric[], companyCurrency: string) {
    const visibleMetrics = metrics.filter((metric) => !metric.metric.isHidden);

    // Qualitative and boolean Metrics
    const narrativeMetrics = visibleMetrics.filter(
      (m) => m.metric.metric.metricType !== QuestionType_Enum_.Decimal_
    );

    const quantitativeMetrics = visibleMetrics.filter(
      (m) => m.metric.metric.metricType === QuestionType_Enum_.Decimal_
    );

    const quantitativeMetricsSection = this.createQuantitativeMetrics(
      quantitativeMetrics,
      companyCurrency
    );
    const narrativeMetricsSection = this.createNarrativeMetrics(narrativeMetrics);

    return [quantitativeMetricsSection, narrativeMetricsSection].flat();
  }

  public createQuantitativeMetrics(
    quantitativeMetrics: EsrsReportMetric[],
    companyCurrency: string
  ) {
    const displayedMetrics = quantitativeMetrics.flatMap((metric) => {
      const { shortTitle, title } = metric.metric.metric;

      const hasAnswer = metric.tableData?.result?.Year;

      const metricHeader = hasAnswer
        ? []
        : new Paragraph({
            text: shortTitle ?? title,
            style: TextStyle.h4,
            spacing: {
              after: 100,
            },
          });

      const mdrmSection = this.createMdrm(metric);
      const metricTableSection = this.createMetricTable(metric, companyCurrency);

      return [mdrmSection, metricHeader, metricTableSection, addEmptySpace(250)];
    });

    return displayedMetrics.flat();
  }

  public createMetricTable(metric: EsrsReportMetric, companyCurrency: string) {
    const quantitativeMetricTable = new Table({
      columnWidths: [4000, 2000, 2000],
      margins: {
        top: 60,
        bottom: 60,
        right: 60,
        left: 60,
      },
      width: {
        size: '100%',
        type: WidthType.PERCENTAGE,
      },
      borders: tableBorders,
      rows: [
        new TableRow({
          tableHeader: true,
          children: [
            new TableCell({
              verticalAlign: VerticalAlign.CENTER,
              width: {
                size: '60%',
                type: WidthType.PERCENTAGE,
              },
              children: [
                new Paragraph({
                  text: 'Metric',
                  style: TextStyle.tableTitle,
                }),
              ],
            }),
            new TableCell({
              verticalAlign: VerticalAlign.CENTER,
              width: {
                size: '20%',
                type: WidthType.PERCENTAGE,
              },
              children: [
                new Paragraph({
                  text: 'Unit',
                  style: TextStyle.tableTitle,
                }),
              ],
            }),
            new TableCell({
              verticalAlign: VerticalAlign.CENTER,
              width: {
                size: '20%',
                type: WidthType.PERCENTAGE,
              },
              children: [
                new Paragraph({
                  text: 'Total',
                  style: TextStyle.tableTitle,
                }),
              ],
            }),
          ],
        }),
        this.createDataPointRows(metric, companyCurrency),
      ].flat(),
    });

    if (metric.metric.metric.metricType !== QuestionType_Enum_.Decimal_) return [];

    return quantitativeMetricTable;
  }

  public createDataPointRows(metric: EsrsReportMetric, companyCurrency: string) {
    const metricResults = metric.tableData;
    const metricInfo = metric.metric.metric;

    const displayedRows = [
      new TableRow({
        children: [
          new TableCell({
            verticalAlign: VerticalAlign.TOP,
            children: [
              new Paragraph({
                text: metricInfo.shortTitle ?? metricInfo.title,
                style: TextStyle.body,
                children: [
                  new TextRun({
                    text: metricResults.tagType ? ` by ${metricResults.tagType}` : '',
                  }),
                ],
              }),
            ],
          }),
          new TableCell({
            verticalAlign: VerticalAlign.TOP,
            children: [
              new Paragraph({
                text: metricInfo.unitOfMeasurement
                  ? mapUnitToCompanyCurrency(metricInfo.unitOfMeasurement, companyCurrency)
                  : 'NA',
                style: TextStyle.body,
              }),
            ],
          }),
          new TableCell({
            verticalAlign: VerticalAlign.TOP,
            children: [
              new Paragraph({
                text: getTableRowText(metric.tableData),
                style: TextStyle.body,
              }),
            ],
          }),
        ],
      }),
      this.createNestedDataPointsRows(metricResults, companyCurrency),
    ];
    return displayedRows.flat();
  }

  public createNestedDataPointsRows(
    metricResults: AggregatedMetricsTableData,
    companyCurrency: string,
    depth = 1
  ) {
    const subRows = metricResults.subRows;

    if (!subRows?.length) return [];

    const nestedRows: TableRow[] =
      subRows?.flatMap((row) => {
        return [
          new TableRow({
            children: [
              new TableCell({
                verticalAlign: VerticalAlign.TOP,
                children: [
                  new Paragraph({
                    indent: {
                      start: depth * 220,
                    },
                    text: getRowTitle(row),
                    style: TextStyle.body,
                  }),
                ],
              }),
              new TableCell({
                verticalAlign: VerticalAlign.TOP,
                children: [
                  new Paragraph({
                    text: row.metric.unitOfMeasurement
                      ? mapUnitToCompanyCurrency(row.metric.unitOfMeasurement, companyCurrency)
                      : 'NA',
                    style: TextStyle.body,
                  }),
                ],
              }),
              new TableCell({
                verticalAlign: VerticalAlign.TOP,
                children: [
                  new Paragraph({
                    text: getTableRowText(row),
                    style: TextStyle.body,
                  }),
                ],
              }),
            ],
          }),
          ...(this.createNestedDataPointsRows(row, companyCurrency, depth + 1) ?? []),
        ];
      }) ?? [];

    return nestedRows.flat();
  }

  public createMdrm(metric: EsrsReportMetric) {
    const isNarrativeMetric = metric.metric.metric.metricType !== QuestionType_Enum_.Decimal_;

    if (isNarrativeMetric) return [];

    const metricMdrmDataPoints = metric.mdrmAnswers;

    const displayedMdrm = metricMdrmDataPoints.flatMap((mdrm) => {
      const { shortTitle, title } = mdrm.metric;

      const mdrmAnswersArray = Object.values(mdrm.answers);
      const noMdrmAnswered = mdrmAnswersArray.every((answer) => !answer || answer === '');
      if (noMdrmAnswered) return [];

      return [
        new Paragraph({
          text: shortTitle ?? title,
          style: TextStyle.h4,
          spacing: {
            after: 100,
          },
        }),
        this.createMetricMdrmAnswers(mdrm),
        addEmptySpace(100),
      ];
    });

    return displayedMdrm.flat();
  }

  public createMetricMdrmAnswers(mdrmMetricAnswers: MdrmMetricAnswers) {
    const displayedAnswers = MDRM_QUESTIONS.flatMap((q) => {
      const answer = mdrmMetricAnswers.answers?.[q.id];

      if (!!answer) {
        return [
          new Paragraph({
            text: answer,
            style: TextStyle.body,
            spacing: {
              after: 100,
            },
          }),
        ];
      }

      return [];
    });

    return displayedAnswers.flat();
  }

  public createNarrativeMetrics(narrativeMetrics: EsrsReportMetric[]) {
    const filteredMetrics = simplifyNarrativeMetrics(narrativeMetrics);

    const displayedMetrics = filteredMetrics.flatMap((metric) => {
      const narrativeMetricSection = this.createNarrativeMetric(metric, true);

      return [narrativeMetricSection, addEmptySpace(200)];
    });

    return displayedMetrics.flat();
  }

  public createNarrativeMetric(metric: SimplifiedReportNarrativeMetric, isTopLevel: boolean) {
    const { shortTitle, title } = metric.materialMetric.metric;

    const titleParagraph = new Paragraph({
      text: shortTitle ?? title,
      style: TextStyle.h4,
      spacing: {
        after: 100,
      },
    });

    const hasAnswer = !!metric?.textAnswer && metric?.textAnswer !== '';
    const isBooleanOrEnum =
      metric.materialMetric.metric.metricType === QuestionType_Enum_.YesNo_ ||
      metric.materialMetric.metric.metricType === QuestionType_Enum_.MultipleChoice_ ||
      metric.materialMetric.metric.metricType === QuestionType_Enum_.SingleChoice_;

    // Split answers with multiple lines into paragraphs
    const answerParagraphs = this.createNarrativeAnswer(metric, hasAnswer);

    const textBefore = metric.isBeforeVisible
      ? [
          new Paragraph({
            text: metric.textBefore,
            style: TextStyle.body,
            spacing: {
              after: 100,
            },
          }),
        ]
      : [];

    const textAfter = metric.isAfterVisible
      ? [
          new Paragraph({
            text: metric.textAfter,
            style: TextStyle.body,
            spacing: {
              before: 100,
            },
          }),
        ]
      : [];

    if (metric.children.length > 0 && !metric.isLocked) {
      const nestedChildren: Paragraph[] = (
        metric.children.flatMap((child) => {
          const narrativeMetricSection = this.createNarrativeMetric(child, false);

          return [narrativeMetricSection, addEmptySpace(100)];
        }) ?? []
      ).flat();

      const booleanOrEnumParentParagraph = [answerParagraphs, addEmptySpace(50)].flat();

      return [
        titleParagraph,
        ...textBefore,
        // Case when the metric is a boolean or enum parent, we show its answer, otherwise we show only title
        isBooleanOrEnum ? booleanOrEnumParentParagraph : [],
        nestedChildren,
        ...textAfter,
      ].flat();
    }

    return isTopLevel || !hasAnswer ? [titleParagraph, ...answerParagraphs] : [...answerParagraphs];
  }

  public createNarrativeAnswer(metric: SimplifiedReportNarrativeMetric, hasAnswer: boolean) {
    const textAnswer = metric.textAnswer ?? '';
    const answerLines = (hasAnswer ? textAnswer.split('\n') : ['No answer provided.']) ?? [];

    const answerParagraphs = answerLines.map(
      (line) =>
        new Paragraph({
          text: line,
          style: hasAnswer ? TextStyle.body : TextStyle.bodyItalic,
          spacing: {
            afterAutoSpacing: true,
          },
        })
    );

    return answerParagraphs;
  }

  public createTargets(
    targets: TargetFieldsFragment_[] | undefined,
    targetQuestions: EsrsReportStandard['targetQuestions'],
    standardRef: string
  ) {
    if (!targets?.length) return [];

    const displayedTargets = targets.flatMap((target) => {
      const targetSection = this.createTarget(target, targetQuestions, standardRef);
      return [targetSection, addEmptySpace(200)];
    });

    const summaryTable = this.createTargetsSummaryTable(targets);

    return [...displayedTargets.flat(), ...summaryTable];
  }

  public createTarget(
    target: TargetFieldsFragment_,
    targetQuestions: EsrsReportStandard['targetQuestions'],
    standardRef: string
  ) {
    const isClimateChange = standardRef === 'ESRS-E1';
    const reportTargetsMDR = adjustMDRReportData({
      target,
      isDocx: true,
      isClimateChange,
      flatTargetQuestions: targetQuestions,
    });

    const filteredTargetQuestions = isClimateChange
      ? targetQuestions.filter(
          (question) => !ClimateTargetQuestionRefs.includes(question.reference)
        )
      : targetQuestions;

    const groupTQ = uniqBy(
      filteredTargetQuestions.map((tq) => tq.group)?.filter((tq) => !!tq) ?? [],
      'reference'
    );
    const groupedTargetQuestions = groupTQ.map((group) => ({
      ...group,
      targetQuestions: filteredTargetQuestions.filter((q) => q.groupRef === group?.reference) ?? [],
    }));

    const singleTargetQuestions = filteredTargetQuestions.filter((tq) => tq.groupRef === null);

    const title = target.name;

    const titleParagraph = new Paragraph({
      text: title,
      style: TextStyle.h3,
      spacing: {
        after: 300,
      },
    });

    const titleMDRT = new Paragraph({
      text: 'MDR-T',
      style: TextStyle.h4,
      spacing: {
        after: 200,
      },
    });

    const titleOtherDisclosures = new Paragraph({
      text: 'Other Disclosures',
      style: TextStyle.h4,
      spacing: {
        after: 200,
      },
    });

    const displayedMDRT = reportTargetsMDR.flatMap((mdr) => {
      const mdrtSection = this.createMDR({ mdr: mdr as ReportMDR, target });
      return [...mdrtSection, addEmptySpace(200)];
    });

    const displayedTargetQuestionGroup = groupedTargetQuestions.flatMap((group) => {
      const groupQuestionTitle = new Paragraph({
        text: group.title,
        style: TextStyle.h4,
        spacing: {
          after: 150,
        },
      });
      const groupTargetQuestionsSection = group.targetQuestions?.flatMap((question) => {
        const targetQuestionSection = this.createTargetActionQuestion(
          question,
          target.answers,
          true
        );
        return [...targetQuestionSection, addEmptySpace(100)];
      });
      return [groupQuestionTitle, ...groupTargetQuestionsSection, addEmptySpace(100)];
    });

    const displayedSingleTargetQuestion = singleTargetQuestions.flatMap((question) => {
      const targetQuestionSection = this.createTargetActionQuestion(question, target.answers);
      return [...targetQuestionSection, addEmptySpace(100)];
    });

    return [
      titleParagraph,
      titleMDRT,
      ...displayedMDRT,
      titleOtherDisclosures,
      ...displayedTargetQuestionGroup,
      ...displayedSingleTargetQuestion,
    ];
  }

  public createActions(
    actions: ActionFieldsFragment_[] | undefined,
    actionQuestions: EsrsReportStandard['actionQuestions']
  ) {
    if (!actions?.length) return [];

    const displayedActions = actions.flatMap((action) => {
      const actionSection = this.createAction(action, actionQuestions);
      return [actionSection, addEmptySpace(200)];
    });

    return displayedActions.flat();
  }

  public createAction(
    action: ActionFieldsFragment_,
    actionQuestions: EsrsReportStandard['actionQuestions']
  ) {
    const actionsMDR = getReportMDRA();

    const groupActionQuestions = uniqBy(
      actionQuestions.map((aq) => aq.group)?.filter((aq) => !!aq) ?? [],
      'reference'
    );
    const groupedActionQuestions = groupActionQuestions.map((group) => ({
      ...group,
      actionQuestions: actionQuestions.filter((q) => q.groupRef === group?.reference) ?? [],
    }));

    const singleActionQuestions = actionQuestions.filter((tq) => tq.groupRef === null);

    const title = action.title;

    const titleParagraph = new Paragraph({
      text: title,
      style: TextStyle.h3,
      spacing: {
        after: 300,
      },
    });

    const titleMDRA = new Paragraph({
      text: 'MDR-A',
      style: TextStyle.h4,
      spacing: {
        after: 200,
      },
    });

    const titleOtherDisclosures = new Paragraph({
      text: 'Other Disclosures',
      style: TextStyle.h4,
      spacing: {
        after: 200,
      },
    });

    const displayedMDRA = actionsMDR.flatMap((mdr) => {
      const mdrtSection = this.createMDR({ mdr, action });
      return [...mdrtSection, addEmptySpace(200)];
    });

    const displayedActionQuestionGroup = groupedActionQuestions.flatMap((group) => {
      const groupQuestionTitle = new Paragraph({
        text: group.title,
        style: TextStyle.h4,
        spacing: {
          after: 150,
        },
      });
      const groupActionQuestionsSection = group.actionQuestions?.flatMap((question) => {
        const actionQuestionSection = this.createTargetActionQuestion(
          question,
          action.answers,
          true
        );
        return [...actionQuestionSection, addEmptySpace(100)];
      });
      return [groupQuestionTitle, ...groupActionQuestionsSection, addEmptySpace(100)];
    });

    const displayedSingleActionQuestion = singleActionQuestions.flatMap((question) => {
      const actionQuestionSection = this.createTargetActionQuestion(question, action.answers);
      return [...actionQuestionSection, addEmptySpace(100)];
    });

    return [
      titleParagraph,
      titleMDRA,
      ...displayedMDRA,
      titleOtherDisclosures,
      ...displayedActionQuestionGroup,
      ...displayedSingleActionQuestion,
    ];
  }

  public createMDR(data: {
    mdr: ReportMDR;
    target?: TargetFieldsFragment_;
    action?: ActionFieldsFragment_;
  }) {
    const { mdr, target, action } = data;
    const title = mdr.title as string;

    const titleParagraph = new Paragraph({
      text: title,
      style: TextStyle.h4,
      spacing: {
        after: 100,
      },
      indent: {
        left: mdr.isChild ? 400 : 0,
      },
    });

    const field = mdr?.secondField ?? mdr.field;
    const fieldAnswer = !!target
      ? target?.[field as keyof TargetFieldsFragment_]
      : action?.[field as keyof ActionFieldsFragment_];
    const answer = mdr?.value ?? fieldAnswer;

    if (mdr.hideAnswer && !!answer) return [titleParagraph];

    const answerParagraphs = new Paragraph({
      text: !!answer ? String(answer) : 'No answer provided.',
      style: !!answer ? TextStyle.body : TextStyle.bodyItalic,
      spacing: {
        afterAutoSpacing: true,
      },
      indent: {
        left: mdr.isChild ? 400 : 0,
      },
    });
    if (mdr.field === 'none') return [titleParagraph];
    return [titleParagraph, answerParagraphs];
  }

  public createTargetActionQuestion(
    question:
      | EsrsReportStandard['actionQuestions'][number]
      | EsrsReportStandard['targetQuestions'][number],
    answers: any,
    indent?: boolean
  ) {
    const title = question.title;

    const titleParagraph = new Paragraph({
      text: title,
      style: TextStyle.h4,
      spacing: {
        after: 100,
      },
      indent: {
        left: indent ? 400 : 0,
      },
    });

    const answer = answers?.[question.reference]?.value;

    const answerParagraphs = new Paragraph({
      text: !!answer ? String(answer) : 'No answer provided.',
      style: !!answer ? TextStyle.body : TextStyle.bodyItalic,
      spacing: {
        afterAutoSpacing: true,
      },
      indent: {
        left: indent ? 400 : 0,
      },
    });

    return [titleParagraph, answerParagraphs];
  }

  public createTargetsSummaryTable(targets: TargetFieldsFragment_[]) {
    if (!targets?.length) return [];

    const nbMilestoneYears = Math.max(...targets.map((t) => t.milestones?.length ?? 0));
    const milestoneMap = Array.from({ length: nbMilestoneYears }, (_, i) => i);

    const titleParagraph = new Paragraph({
      text: 'Summary',
      style: TextStyle.h4,
      spacing: {
        after: 100,
      },
    });

    const columnsNb = 2 + nbMilestoneYears;
    const firstColumnWidth = 10000 - columnsNb * 1000;

    const targetsTable = new Table({
      columnWidths: [firstColumnWidth, 1000, 1000, ...milestoneMap.map(() => 1000)],
      margins: {
        top: 60,
        bottom: 60,
        right: 60,
        left: 60,
      },
      borders: tableBorders,
      rows: [
        new TableRow({
          tableHeader: true,
          children: [
            new TableCell({
              verticalAlign: VerticalAlign.CENTER,
              children: [
                new Paragraph({
                  text: 'Target name',
                  style: TextStyle.tableTitle,
                }),
              ],
            }),
            new TableCell({
              verticalAlign: VerticalAlign.CENTER,
              children: [
                new Paragraph({
                  text: 'MDR-T Baseline value (decimal)',
                  style: TextStyle.tableTitle,
                }),
              ],
            }),
            new TableCell({
              verticalAlign: VerticalAlign.CENTER,
              children: [
                new Paragraph({
                  text: 'MDR-T Baseline year (decimal)',
                  style: TextStyle.tableTitle,
                }),
              ],
            }),
            ...milestoneMap.map(
              (index) =>
                new TableCell({
                  verticalAlign: VerticalAlign.CENTER,
                  children: [
                    new Paragraph({
                      text:
                        index === nbMilestoneYears - 1 ? 'End target' : `Milestone ${index + 1}`,
                      style: TextStyle.tableTitle,
                    }),
                  ],
                })
            ),
          ],
        }),
        ...targets.map((target) => this.createTargetRows(target, milestoneMap)),
      ].flat(),
    });

    return [titleParagraph, targetsTable];
  }

  public createTargetRows(target: TargetFieldsFragment_, milestoneMap: number[]) {
    const baseline = target.keyResults.find((kr) => kr.reportingUnitId === null);

    const displayedRows = [
      new TableRow({
        children: [
          new TableCell({
            verticalAlign: VerticalAlign.TOP,
            children: [
              new Paragraph({
                text: target.name,
                style: TextStyle.body,
              }),
            ],
          }),
          new TableCell({
            verticalAlign: VerticalAlign.TOP,
            children: [
              new Paragraph({
                text: baseline?.baseline ?? 'NA',
                style: TextStyle.body,
              }),
            ],
          }),
          new TableCell({
            verticalAlign: VerticalAlign.TOP,
            children: [
              new Paragraph({
                text: baseline?.baseYear ? `${new Date(baseline?.baseYear).getFullYear()}` : 'NA',
                style: TextStyle.body,
              }),
            ],
          }),
          ...milestoneMap.map((index) => {
            const milestones = sortBy(target.milestones, 'year');
            const milestone = milestones[index];
            const milestoneResult = milestone?.milestoneResults.find(
              (mr) => mr.reportingUnitId === null
            )?.value;

            const targetValue = percentageToNumber(milestoneResult, Number(baseline?.baseline));
            return new TableCell({
              verticalAlign: VerticalAlign.TOP,
              children: [
                new Paragraph({
                  text: !milestone
                    ? ''
                    : !milestoneResult || !targetValue
                      ? 'NA'
                      : `${targetValue} (${milestone.year})`,
                  style: TextStyle.body,
                }),
              ],
            });
          }),
        ],
      }),
    ];
    return displayedRows.flat();
  }
}
