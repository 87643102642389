import { HStack, Button, Box } from '@chakra-ui/react';
import { HelpTooltip } from 'Molecules';
import { TableData } from 'Molecules/NestedTable';
import { ArrowCornerDownRight } from 'Tokens/Icons/Direction';
import { AggregatedMetricsTableData } from '../../../AggregatedMetrics';
import { Row } from '@tanstack/react-table';
import { goToSource } from './GoToSource.hooks';
import { MetricsTableData } from '../../../MetricAnswers.hooks';

export const GoToSource = ({
  row,
  isNarrative,
  filteredMetrics,
  setNumberOfRows,
}: {
  row: Row<TableData<AggregatedMetricsTableData>>;
  isNarrative?: boolean;
  filteredMetrics: MetricsTableData[];
  setNumberOfRows: React.Dispatch<React.SetStateAction<Record<string, number>>>;
}) => {
  return (
    <Box pl={`${row.depth * 24 + 44}px`} mt={isNarrative ? '24px' : '0px'} position="relative">
      <HStack spacing="4px" position="absolute" top="50%" transform="translateY(-50%)">
        <ArrowCornerDownRight color="text.default" />
        <Button
          variant="ghost"
          size="sm"
          onClick={() =>
            goToSource(row.original.referenceToSource ?? '', filteredMetrics, setNumberOfRows)
          }
        >
          Go to sub-metrics
        </Button>
        <HelpTooltip
          placement="bottom"
          label={
            'This metric is calculated using several sub-metrics that have been previously reported. For details, go to sub-metrics'
          }
        />
      </HStack>
    </Box>
  );
};
