import {
  ActivityAssessment,
  ActivityTagEnum,
  AssessmentFlags,
  BareFinancials,
  BusinessUnitAssessmentResults,
  BusinessUnitCachedResult,
  CompanyAssessmentResults,
  GeneralActivityCachedResult,
  ObjectiveKeyEnum,
  ObjectiveWithQuestions,
  RawCompanyAssessmentResults,
  ScreenedFinancials,
  CalculatedActivityResult,
  TaxonomyScore,
} from 'models';
import { EntityAlignmentStatus } from 'Organisms/AlignmentStatus';
import {
  allFinancialSections,
  scoreSections,
  getScreenedActivityFinancials,
} from 'utils/financials';
import { isNullish } from 'utils/numbers';
import {
  isActivityAligned,
  isDNSHQuestionAligned,
  isQuestionAligned,
  isQuestionAnswered,
  isQuestionApplicable,
  isQuestionDNSH,
  isQuestionRequired,
  isQuestionSC,
  isQuestionSCAndApplicable,
  isSCAutoAligned,
  isSCQuestionAligned,
} from './questions';

const alwaysEnablingActivityReferences = [
  'adaptation-engineering',
  '10.1',
  '9.2a',
  '8.4',
  '9.3.',
  '10.2',
];

export const checkIfAlwaysEnablingActivity = (activityRef: string) =>
  alwaysEnablingActivityReferences.includes(activityRef);

export const allNonAdaptationObjectives = [
  ObjectiveKeyEnum.social,
  ObjectiveKeyEnum.mitigation,
  ObjectiveKeyEnum.water,
  ObjectiveKeyEnum.biodiversity,
  ObjectiveKeyEnum.circular,
  ObjectiveKeyEnum.pollution,
];

export const getNotAlignedScore = (eligible: number, aligned: number, inProgress: number) => {
  return eligible - (aligned + inProgress);
};

export const getNotEligibleScore = (eligible: number) => {
  return 100 - eligible;
};

export const expandTaxonomyScore = (score: TaxonomyScore) => {
  const { eligible, aligned, inProgress = 0 } = score;
  const notAligned = getNotAlignedScore(eligible, aligned, inProgress);
  const notEligible = getNotEligibleScore(eligible);
  return { ...score, notAligned: notAligned < 0 ? 0 : notAligned, notEligible };
};

export const defaultFinancials: BareFinancials = {
  revenue: 0,
  capex: 0,
  opex: 0,
  adaptationCapex: 0,
  adaptationOpex: 0,
  isEstimate: false,
};

const CRA_QUESTIONS_VERSIONS = [
  { version: 1, questions: [{ uniqueId: 'DNSH_A_1', acceptedAnswers: ['YES'] }] },
  {
    version: 2,
    questions: [
      { uniqueId: 'DNSH_A_2a', acceptedAnswers: ['YES'] },
      { uniqueId: 'DNSH_A_3c', acceptedAnswers: ['YES', 'NA'] },
    ],
  },
];

const getFinancialsProgress = (financials: BareFinancials | undefined | null): number => {
  if (!financials) return 0;
  if (!financials.isEstimate) return 100;
  return allFinancialSections.map((section) => financials[section])?.some((value) => value > 0)
    ? 100
    : 0; // TODO: Use this instead
};

const getScreeningProgress = (objectives: ObjectiveWithQuestions[]): number => {
  const requiredQuestions = objectives.flatMap((o) => o.questions).filter(isQuestionRequired) || [];
  const completedQuestions = requiredQuestions.filter(isQuestionAnswered);
  return (completedQuestions.length / requiredQuestions.length) * 100;
};

const getSocialSafeguardsAlignmentStatus = ({
  isAligned,
  isCompleted,
  isStarted,
}: {
  isAligned: boolean;
  isCompleted: boolean;
  isStarted: boolean;
}) => {
  if (!isStarted) return EntityAlignmentStatus.todo;
  if (isAligned) {
    return isCompleted ? EntityAlignmentStatus.compliant : EntityAlignmentStatus.soFarCompliant;
  }

  return EntityAlignmentStatus.notCompliant;
};

const getActivityAlignmentStatus = ({
  screeningProgress,
  substantialContributionObjectives,
  significantHarmObjectives,
  possibleSubstantialContributionObjectives,
  isCompleted,
  isEnabling,
  isAdaptationCompleted,
  isAdaptationOnlyActivity,
  isAdaptationOnlySC,
  canBeEnabling,
  areAllNonAdaptationObjectivesCompleted,
}: {
  screeningProgress: number;
  substantialContributionObjectives: string[];
  significantHarmObjectives: string[];
  possibleSubstantialContributionObjectives: string[];
  isCompleted: boolean;
  isEnabling: boolean;
  isAdaptationCompleted: boolean;
  isAdaptationOnlyActivity: boolean;
  isAdaptationOnlySC: boolean;
  canBeEnabling: boolean;
  areAllNonAdaptationObjectivesCompleted: boolean;
}) => {
  const screeningNotStarted = screeningProgress === 0;
  const hasSignificantHarm = significantHarmObjectives.length >= 1;
  const canContributeToAdaptationPlus =
    possibleSubstantialContributionObjectives.length > 1 &&
    possibleSubstantialContributionObjectives.includes(ObjectiveKeyEnum.adaptation);

  if (screeningNotStarted) return EntityAlignmentStatus.todo;
  if (hasSignificantHarm) return EntityAlignmentStatus.notCompliant;

  if (isAdaptationOnlyActivity) {
    if (canBeEnabling) {
      if (isAdaptationOnlySC && isAdaptationCompleted && isEnabling) {
        return EntityAlignmentStatus.compliant;
      } else if (isAdaptationOnlySC && isAdaptationCompleted) {
        return EntityAlignmentStatus.nonEnablingAdaptationOnlyCompliant;
      } else {
        return isCompleted
          ? EntityAlignmentStatus.notCompliant
          : EntityAlignmentStatus.soFarCompliant;
      }
    } else if (isAdaptationOnlySC && isAdaptationCompleted) {
      return EntityAlignmentStatus.adaptationOnlyCompliant;
    }
  }

  if (canContributeToAdaptationPlus) {
    if (isAdaptationOnlySC && isAdaptationCompleted && !isEnabling) {
      return areAllNonAdaptationObjectivesCompleted
        ? EntityAlignmentStatus.adaptationOnlyCompliant
        : EntityAlignmentStatus.soFarPartiallyCompliant;
    } else if (
      areAllNonAdaptationObjectivesCompleted &&
      !isAdaptationCompleted &&
      substantialContributionObjectives.length === 0
    ) {
      return EntityAlignmentStatus.soFarNoSubstantialContribution;
    } else if (isEnabling) {
      return isCompleted ? EntityAlignmentStatus.compliant : EntityAlignmentStatus.soFarCompliant;
    }
  }

  if (substantialContributionObjectives.length > 0) {
    return isCompleted ? EntityAlignmentStatus.compliant : EntityAlignmentStatus.soFarCompliant;
  }

  return isCompleted
    ? EntityAlignmentStatus.noSubstantialContribution
    : EntityAlignmentStatus.soFarNoSubstantialContribution;
};

// TODO: Add comments explanining the methodology
// Start with a paragraph explaing all, then in the code explaing what specific it does
export const getActivityResult = ({
  activityAssessment,
  objectives,
}: {
  activityAssessment: ActivityAssessment;
  objectives: ObjectiveWithQuestions[];
}): CalculatedActivityResult => {
  const financialsProgress = getFinancialsProgress(activityAssessment.financials);
  const screeningProgress = getScreeningProgress(objectives);

  // Objectives that it's possible for this activity to do substantial contribution
  const possibleSubstantialContributionObjectives = objectives
    .filter((o) => o.questions.some(isQuestionSC))
    .map((o) => o.key);
  // Objectives where this activity is actually doing substantial contribution
  const substantialContributionObjectives = objectives
    .filter((o) => o.questions.some(isQuestionSCAndApplicable) || isSCAutoAligned(o.questions))
    .filter((o) =>
      o.questions.filter(isQuestionSC).filter(isQuestionAnswered).every(isSCQuestionAligned)
    )
    .map((o) => o.key);

  // Objectives where this activity is actually doing substantial contribution
  const significantHarmObjectives = objectives
    .filter(
      (o) =>
        !o.questions.filter(isQuestionDNSH).filter(isQuestionAnswered).every(isDNSHQuestionAligned)
    )
    .map((o) => o.key);

  const isAdaptationCompleted =
    objectives
      .find((o) => o.key === ObjectiveKeyEnum.adaptation)
      ?.questions.every(isQuestionAnswered) ?? true;

  // Don't do harm, and at least one contribution
  const isAligned = isActivityAligned(objectives);

  const isCompleted = screeningProgress === 100;

  // If an activity can only contribute to adaptation
  const isAdaptationOnlyActivity =
    possibleSubstantialContributionObjectives.length === 1 &&
    possibleSubstantialContributionObjectives.includes(ObjectiveKeyEnum.adaptation);

  const canBeEnabling = activityAssessment.activityVersion.tagExpression.includes(
    ActivityTagEnum.enabling
  );

  const isAlwaysEnablingActivity = checkIfAlwaysEnablingActivity(
    activityAssessment.activity.reference
  );

  const isEnabling = activityAssessment.activityTag === ActivityTagEnum.enabling;
  const isTransitional = activityAssessment.activityTag === ActivityTagEnum.transitional;

  // If an activity is only contributing to adaptation
  const isAdaptationOnlySC =
    substantialContributionObjectives.length == 1 &&
    substantialContributionObjectives.includes(ObjectiveKeyEnum.adaptation);

  const financials = activityAssessment.financials ?? defaultFinancials;

  const areAllNonAdaptationObjectivesCompleted = objectives
    .filter((o) => o.key !== ObjectiveKeyEnum.adaptation)
    .flatMap((o) => o.questions)
    .filter(isQuestionRequired)
    .every(isQuestionAnswered);

  // Adaptation financials are only eligible, if they have done a climate risk assessment
  // This flag is used to check this. However, it should be replaced with something less 'hardcoded' in the future.
  // E.g. a new tag, with a similar expression
  const hasClimateRiskAssessment = CRA_QUESTIONS_VERSIONS.some((version) =>
    version.questions.every((craQ) => {
      const answer = objectives
        .find((o) => o.key === ObjectiveKeyEnum.adaptation)
        ?.questions.filter(isQuestionApplicable)
        .find((q) => q.uniqueId === craQ.uniqueId)?.answer?.data;
      return craQ.acceptedAnswers.includes(answer);
    })
  );

  const assessmentFlags: AssessmentFlags = {
    isAdaptationOnlySC,
    isAdaptationOnlyActivity,
    isAligned,
    isCompleted,
    isEnabling,
    isTransitional,
    canBeEnabling,
    hasClimateRiskAssessment,
    isAlwaysEnablingActivity,
  };

  const status = getActivityAlignmentStatus({
    screeningProgress,
    substantialContributionObjectives,
    possibleSubstantialContributionObjectives,
    significantHarmObjectives,
    isCompleted,
    isAdaptationOnlyActivity,
    isAdaptationOnlySC,
    isAdaptationCompleted,
    isEnabling,
    canBeEnabling,
    areAllNonAdaptationObjectivesCompleted,
  });

  const screenedFinancials = getScreenedActivityFinancials(financials, assessmentFlags);

  const score: CalculatedActivityResult['score'] = scoreSections.reduce(
    (agg, s) => ({
      ...agg,
      [s]: {
        total: 100,
        aligned: isAligned && isCompleted ? 100 : 0,
        inProgress: isAligned && !isCompleted ? 100 : 0,
        eligible: !isAdaptationOnlyActivity || isEnabling || isAlwaysEnablingActivity ? 100 : 0, // TODO: This might be wrong, check if adpataioncapex should be included
        enabling: isEnabling ? 100 : 0, // TODO: This might be wrong, check if adpataioncapex should be included
        transitional: isTransitional ? 100 : 0,
      },
    }),
    {} as CalculatedActivityResult['score']
  );

  return {
    activityRef: activityAssessment.activity.reference,
    activityTag: activityAssessment.activityTag,
    activityVersionNumber: activityAssessment.activityVersionNumber,
    progress: {
      financials: financialsProgress,
      screening: screeningProgress,
    },
    financials: screenedFinancials,
    score,
    isAligned,
    isCompleted,
    objectivesState: {
      possibleSubstantialContributionObjectives: possibleSubstantialContributionObjectives,
      significantHarmObjectives: significantHarmObjectives,
      substantialContributionObjectives: substantialContributionObjectives,
      hasClimateRiskAssessment: hasClimateRiskAssessment,
    },
    status,
  };
};

export const getGeneralAssessmentResult = ({
  activityAssessment,
  objectives,
}: {
  activityAssessment: ActivityAssessment;
  objectives: ObjectiveWithQuestions[];
}): Omit<GeneralActivityCachedResult, 'id'> & { status: EntityAlignmentStatus } => {
  const financialsProgress = getFinancialsProgress(activityAssessment.financials);
  const screeningProgress = getScreeningProgress(objectives);

  const isAligned = objectives
    .filter((o) => o.key === ObjectiveKeyEnum.social)
    .flatMap((o) => o.questions)
    .filter(isQuestionAnswered)
    .filter(isQuestionRequired)
    .every(isQuestionAligned);

  const isCompleted = screeningProgress === 100;
  const isStarted = screeningProgress > 0;

  const score: CalculatedActivityResult['score'] = scoreSections.reduce(
    (agg, s) => ({
      ...agg,
      [s]: {
        total: 100,
        aligned: isAligned && isCompleted ? 100 : 0,
        inProgress: isAligned && !isCompleted ? 100 : 0,
        eligible: 0,
      },
    }),
    {} as CalculatedActivityResult['score']
  );

  const generalFinancials = activityAssessment.financials ?? defaultFinancials;
  const financials: CalculatedActivityResult['financials'] = scoreSections.reduce(
    (agg, s) => ({
      ...agg,
      [s]: {
        total: generalFinancials[s],
        inProgress: 0,
        aligned: 0,
        notAligned: 0,
        eligible: 0,
        notEligible: 0,
        enabling: 0,
        transitional: 0,
      },
    }),
    {} as CalculatedActivityResult['financials']
  );

  const status = getSocialSafeguardsAlignmentStatus({ isAligned, isCompleted, isStarted });

  return {
    activityRef: activityAssessment.activity.reference,
    activityVersionNumber: activityAssessment.activityVersionNumber,
    progress: {
      financials: financialsProgress,
      screening: screeningProgress,
    },
    financials: financials,
    score,
    isAligned,
    isCompleted,
    objectivesState: {},
    status,
  };
};
export type AssessmentStatus = {
  isAligned?: boolean | null;
  isCompleted: boolean;
};
export const getInProgressFinancials = (
  status: AssessmentStatus,
  financials: ScreenedFinancials
) => {
  if (!status.isAligned) {
    return 0;
  }
  if (!status.isCompleted) {
    return financials.inProgress + financials.aligned;
  }
  return financials.inProgress;
};

export const getAlignedFinancials = (status: AssessmentStatus, financials: ScreenedFinancials) => {
  if (!status.isAligned || !status.isCompleted) {
    return 0;
  }
  return financials.aligned;
};

export const getNotAlignedFinancials = (
  status: AssessmentStatus,
  financials: ScreenedFinancials
) => {
  if (!status.isAligned) {
    return financials.eligible;
  }
  return financials.notAligned;
};

export const getEnablingFinancials = (status: AssessmentStatus, financials: ScreenedFinancials) => {
  if (!status.isAligned) {
    return 0;
  }
  return financials.enabling;
};

const getTransitionalFinancials = (status: AssessmentStatus, financials: ScreenedFinancials) => {
  if (!status.isAligned) {
    return 0;
  }
  return financials.transitional;
};

export const numberOrZero = (n: number | null) => (Number.isNaN(n) || n == null ? 0 : n);

export const sumWithUndefined = (...addends: any[]) =>
  addends.reduce((sum, addend) => sum + numberOrZero(addend), 0);

export const getBusinessUnitAssessmentResult = ({
  businessUnitFinancials = defaultFinancials,
  generalAssessmentResult,
  activityAssessmentResults,
  hasGeneralAssessment,
}: {
  businessUnitFinancials: BareFinancials | undefined;
  generalAssessmentResult: GeneralActivityCachedResult;
  activityAssessmentResults: BusinessUnitAssessmentResults['activityResults'];
  hasGeneralAssessment: boolean;
}): Omit<BusinessUnitCachedResult, 'id'> => {
  const aggregatedActivityFinancials: CalculatedActivityResult['financials'] = scoreSections.reduce(
    (agg, section) => ({
      ...agg,
      [section]: {
        total: sumWithUndefined(
          ...activityAssessmentResults.map((ar) => ar.cachedResult?.financials[section]?.total)
        ),
        inProgress: sumWithUndefined(
          ...activityAssessmentResults.map((ar) => ar.cachedResult?.financials[section]?.inProgress)
        ),
        aligned: sumWithUndefined(
          ...activityAssessmentResults.map((ar) => ar.cachedResult?.financials[section]?.aligned)
        ),
        notAligned: sumWithUndefined(
          ...activityAssessmentResults.map((ar) => ar.cachedResult?.financials[section]?.notAligned)
        ),
        eligible: sumWithUndefined(
          ...activityAssessmentResults.map((ar) => ar.cachedResult?.financials[section]?.eligible)
        ),
        notEligible: sumWithUndefined(
          ...activityAssessmentResults.map(
            (ar) => ar.cachedResult?.financials[section]?.notEligible
          )
        ),
        enabling: sumWithUndefined(
          ...activityAssessmentResults.map((ar) => ar.cachedResult?.financials[section]?.enabling)
        ),
        transitional: sumWithUndefined(
          ...activityAssessmentResults.map(
            (ar) => ar.cachedResult?.financials[section]?.transitional
          )
        ),
      },
    }),
    {} as CalculatedActivityResult['financials']
  );

  const financials: CalculatedActivityResult['financials'] = scoreSections.reduce(
    (agg, section) => ({
      ...agg,
      [section]: {
        total: businessUnitFinancials[section],
        inProgress: getInProgressFinancials(
          generalAssessmentResult,
          aggregatedActivityFinancials[section]
        ),
        aligned: getAlignedFinancials(
          generalAssessmentResult,
          aggregatedActivityFinancials[section]
        ),
        notAligned: getNotAlignedFinancials(
          generalAssessmentResult,
          aggregatedActivityFinancials[section]
        ),
        eligible: numberOrZero(aggregatedActivityFinancials[section].eligible),
        notEligible:
          numberOrZero(businessUnitFinancials[section]) -
          numberOrZero(aggregatedActivityFinancials[section].eligible),
        enabling: getEnablingFinancials(
          generalAssessmentResult,
          aggregatedActivityFinancials[section]
        ),
        transitional: getTransitionalFinancials(
          generalAssessmentResult,
          aggregatedActivityFinancials[section]
        ),
      },
    }),
    {} as CalculatedActivityResult['financials']
  );
  const allResults = [
    generalAssessmentResult,
    ...activityAssessmentResults.map((ar) => ar.cachedResult),
  ];
  const screeningResults = hasGeneralAssessment
    ? [generalAssessmentResult, ...activityAssessmentResults.map((ar) => ar.cachedResult)]
    : [...activityAssessmentResults.map((ar) => ar.cachedResult)];

  const progress: CalculatedActivityResult['progress'] = {
    financials:
      sumWithUndefined(...allResults.map((r) => r?.progress.financials)) / allResults.length,
    screening: screeningResults.length
      ? sumWithUndefined(...screeningResults.map((r) => r?.progress.screening)) /
        screeningResults.length
      : 0,
  };

  const score: CalculatedActivityResult['score'] = scoreSections.reduce(
    (scores, section) => ({
      ...scores,
      [section]: {
        total: 100,
        aligned: (financials[section].aligned / financials[section].total) * 100,
        inProgress: (financials[section].inProgress / financials[section].total) * 100,
        eligible: (financials[section].eligible / financials[section].total) * 100,
        enabling: (financials[section].enabling / financials[section].total) * 100,
        transitional: (financials[section].transitional / financials[section].total) * 100,
      },
    }),
    {} as CalculatedActivityResult['score']
  );

  const isCompleted = allResults.every((r) => !!r?.isCompleted);

  return { financials, progress, isCompleted, score, objectivesState: {} };
};

export const getCompanyAssessmentResult = ({
  companyFinancials = defaultFinancials,
  generalAssessmentResult,
  businessUnitAssessmentResults,
}: {
  companyFinancials: BareFinancials | undefined;
  generalAssessmentResult: GeneralActivityCachedResult;
  businessUnitAssessmentResults: BusinessUnitAssessmentResults[];
}): Omit<BusinessUnitCachedResult, 'id'> => {
  const aggregatedBusinessUnitFinancials: CalculatedActivityResult['financials'] =
    scoreSections.reduce(
      (agg, section) => ({
        ...agg,
        [section]: {
          total: sumWithUndefined(
            ...businessUnitAssessmentResults.map(
              (br) => br.cachedResult?.financials[section]?.total
            )
          ),
          inProgress: sumWithUndefined(
            ...businessUnitAssessmentResults.map(
              (br) => br.cachedResult?.financials[section]?.inProgress
            )
          ),
          aligned: sumWithUndefined(
            ...businessUnitAssessmentResults.map(
              (br) => br.cachedResult?.financials[section]?.aligned
            )
          ),
          notAligned: sumWithUndefined(
            ...businessUnitAssessmentResults.map(
              (br) => br.cachedResult?.financials[section]?.notAligned
            )
          ),
          eligible: sumWithUndefined(
            ...businessUnitAssessmentResults.map(
              (br) => br.cachedResult?.financials[section]?.eligible
            )
          ),
          notEligible: sumWithUndefined(
            ...businessUnitAssessmentResults.map(
              (br) => br.cachedResult?.financials[section]?.notEligible
            )
          ),
          enabling: sumWithUndefined(
            ...businessUnitAssessmentResults.map(
              (br) => br.cachedResult?.financials[section]?.enabling
            )
          ),
          transitional: sumWithUndefined(
            ...businessUnitAssessmentResults.map(
              (br) => br.cachedResult?.financials[section]?.transitional
            )
          ),
        },
      }),
      {} as CalculatedActivityResult['financials']
    );

  const financials: CalculatedActivityResult['financials'] = scoreSections.reduce(
    (agg, section) => ({
      ...agg,
      [section]: {
        total: companyFinancials[section],
        inProgress: aggregatedBusinessUnitFinancials[section].inProgress,
        aligned: aggregatedBusinessUnitFinancials[section].aligned,
        notAligned: aggregatedBusinessUnitFinancials[section].notAligned,
        eligible: aggregatedBusinessUnitFinancials[section].eligible,
        notEligible:
          numberOrZero(companyFinancials[section]) -
          numberOrZero(aggregatedBusinessUnitFinancials[section].eligible),
        enabling: aggregatedBusinessUnitFinancials[section].enabling,
        transitional: aggregatedBusinessUnitFinancials[section].transitional,
      },
    }),
    {} as CalculatedActivityResult['financials']
  );
  const allResults = [
    generalAssessmentResult,
    ...businessUnitAssessmentResults.map((ar) => ar.cachedResult),
  ];

  const progress: CalculatedActivityResult['progress'] = {
    financials:
      sumWithUndefined(...allResults.map((r) => r?.progress.financials)) / allResults.length,
    screening:
      sumWithUndefined(...allResults.map((r) => r?.progress.screening)) / allResults.length,
  };

  const score: CalculatedActivityResult['score'] = scoreSections.reduce(
    (scores, section) => ({
      ...scores,
      [section]: {
        total: 100,
        aligned: (financials[section].aligned / financials[section].total) * 100,
        inProgress: (financials[section].inProgress / financials[section].total) * 100,
        eligible: (financials[section].eligible / financials[section].total) * 100,
        enabling: (financials[section].enabling / financials[section].total) * 100,
        transitional: (financials[section].transitional / financials[section].total) * 100,
      },
    }),
    {} as CalculatedActivityResult['score']
  );

  const isCompleted = allResults.every((r) => !!r?.isCompleted);

  return { financials, progress, isCompleted, score, objectivesState: {} };
};

export const transformCompanyAssessmentCachedResults = (
  companyResults: RawCompanyAssessmentResults
): CompanyAssessmentResults => {
  return {
    ...companyResults,
    generalAssessmentResult:
      companyResults.generalBusinessUnitResult[0].generalAssessmentResults[0],
    businessUnitResults: companyResults.businessUnitResults.map((br) => {
      return {
        ...br,
        businessUnit: {
          name: br?.businessUnit?.name ?? '',
          id: br?.businessUnit?.id ?? '',
        },
        generalAssessmentResult: br.generalAssessmentResults[0],
      };
    }),
  };
};

// Not used??
export const aggregateScores = (scores: CalculatedActivityResult['score'][] = []) => {
  const sectionScores = scoreSections.reduce(
    (
      scoresMap: { [key: string]: { eligible: number; aligned: number; inProgress: number } },
      section
    ) => {
      const aggregateEligible = scores.reduce((agg, score) => {
        return agg + score[section]?.eligible;
      }, 0);

      const aggregateAligned = scores.reduce((agg, score) => {
        return agg + score[section]?.aligned;
      }, 0);

      const aggregateInprogress = scores.reduce((agg, score) => {
        return agg + (score[section]?.inProgress ?? 0);
      }, 0);

      const shouldAlignedBeNan = scores.every((score) => isNullish(score[section]?.aligned));

      scoresMap[section] = {
        aligned: shouldAlignedBeNan ? NaN : aggregateAligned,
        eligible: aggregateEligible,
        inProgress: aggregateInprogress,
      };
      return scoresMap;
    },
    {}
  );

  return sectionScores;
};

export const getInProgressSCObjectives = (objectives: ObjectiveWithQuestions[]) =>
  objectives
    .filter((objective) => {
      const objectiveQuestions = objective.questions;
      const SCQuestions = objectiveQuestions.filter(isQuestionSC);
      const DNSHQuestions = objectiveQuestions.filter(isQuestionDNSH);
      return (
        SCQuestions.length > 0 &&
        SCQuestions.filter(isQuestionAnswered)
          .filter(isQuestionRequired)
          .every(isSCQuestionAligned) &&
        DNSHQuestions.filter(isQuestionAnswered)
          .filter(isQuestionRequired)
          .every(isDNSHQuestionAligned)
      );
    })
    .map((objective) => objective.key);

export const getAllSCObjectives = (objectives: ObjectiveWithQuestions[]) =>
  objectives
    .filter((objective) => objective.questions.filter(isQuestionSC).length)
    .map((objective) => objective.key);

export const getHarmObjectives = (objectives: ObjectiveWithQuestions[]) =>
  objectives
    .filter((objective) => {
      const objectiveQuestions = objective.questions;
      const DNSHQuestions = objectiveQuestions.filter(isQuestionDNSH);
      return (
        DNSHQuestions.length > 0 &&
        !DNSHQuestions.filter(isQuestionAnswered)
          .filter(isQuestionRequired)
          .every(isDNSHQuestionAligned)
      );
    })
    .map((objective) => objective.key);

export const aggregateCachedResults = (allResults: BusinessUnitCachedResult[]) => {
  const aggregatedBusinessUnitFinancials: CalculatedActivityResult['financials'] =
    scoreSections.reduce(
      (agg, section) => ({
        ...agg,
        [section]: {
          total: sumWithUndefined(
            ...allResults.map((cachedResult) => cachedResult?.financials[section]?.total)
          ),
          inProgress: sumWithUndefined(
            ...allResults.map((cachedResult) => cachedResult?.financials[section]?.inProgress)
          ),
          aligned: sumWithUndefined(
            ...allResults.map((cachedResult) => cachedResult?.financials[section]?.aligned)
          ),
          notAligned: sumWithUndefined(
            ...allResults.map((cachedResult) => cachedResult?.financials[section]?.notAligned)
          ),
          eligible: sumWithUndefined(
            ...allResults.map((cachedResult) => cachedResult?.financials[section]?.eligible)
          ),
          notEligible: sumWithUndefined(
            ...allResults.map((cachedResult) => cachedResult?.financials[section]?.notEligible)
          ),
          enabling: sumWithUndefined(
            ...allResults.map((cachedResult) => cachedResult?.financials[section]?.enabling)
          ),
          transitional: sumWithUndefined(
            ...allResults.map((cachedResult) => cachedResult?.financials[section]?.transitional)
          ),
        },
      }),
      {} as CalculatedActivityResult['financials']
    );

  const financials: CalculatedActivityResult['financials'] = scoreSections.reduce(
    (agg, section) => ({
      ...agg,
      [section]: {
        total: aggregatedBusinessUnitFinancials[section].total,
        inProgress: aggregatedBusinessUnitFinancials[section].inProgress,
        aligned: aggregatedBusinessUnitFinancials[section].aligned,
        notAligned: aggregatedBusinessUnitFinancials[section].notAligned,
        eligible: aggregatedBusinessUnitFinancials[section].eligible,
        notEligible: aggregatedBusinessUnitFinancials[section].notEligible ?? undefined,
        enabling: aggregatedBusinessUnitFinancials[section].enabling,
        transitional: aggregatedBusinessUnitFinancials[section].transitional,
      },
    }),
    {} as CalculatedActivityResult['financials']
  );

  const progress: CalculatedActivityResult['progress'] = {
    financials:
      sumWithUndefined(...allResults.map((r) => r?.progress.financials)) / allResults.length,
    screening:
      sumWithUndefined(...allResults.map((r) => r?.progress.screening)) / allResults.length,
  };

  const score: CalculatedActivityResult['score'] = scoreSections.reduce(
    (scores, section) => ({
      ...scores,
      [section]: {
        total: 100,
        aligned: (financials[section].aligned / financials[section].total) * 100,
        inProgress: (financials[section].inProgress / financials[section].total) * 100,
        eligible: (financials[section].eligible / financials[section].total) * 100,
        notEligible: (financials[section].notEligible / financials[section].total) * 100,
        enabling: (financials[section].enabling / financials[section].total) * 100,
        transitional: (financials[section].transitional / financials[section].total) * 100,
      },
    }),
    {} as CalculatedActivityResult['score']
  );

  return { progress, score };
};
