import { Box, HStack, useDisclosure, VStack } from '@chakra-ui/react';
import { AttachmentDrawer } from 'Features/Screening/AttachmentsDrawer';
import {
  AttachmentBox,
  GetPoliciesDocumentationByIdDocument_,
  useGetPoliciesDrQuery,
  GetDisclosureRequirementGroupsDocument_,
  AttachmentBox_Constraint_,
  useUpsertPolicyMutation,
  NoteHistory_Constraint_,
  GetPoliciesDrDocument_,
} from 'models';
import { LearnMoreDrawer, Loader, SearchInput } from 'Molecules';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Typography } from 'Tokens';
import { useCompanyType } from 'utils/hooks';
import { DisclosureRequirementTitle } from '../DisclosureRequirementsUtilsComponents';
import { PoliciesSubsidiaries } from './PoliciesSubsidiaries';
import { getNameExtension } from 'utils/files';
import { defaultStyles, FileIcon } from 'react-file-icon';
import { Accordion, Button, EmptyState, Infobox } from 'Atoms';
import { useRemoveAttachment } from 'Molecules/InputCard/InputCardDocumentation.hooks';
import { RefetchQueriesInclude } from '@apollo/client';
import { FileEditorContext } from 'containers/Drive/pieces';
import { AddIcon, RemoveIcon } from 'Tokens/Icons/Function';
import { useTranslation } from 'utils/translation';
import { Menu } from 'Molecules/Menu';
import { useParams } from 'react-router-dom';
import { DocumentCheckmarkIcon, TargetIcon, TargetOffIcon } from 'Tokens/Icons/Data';
import {
  AggregatedMetrics,
  AggregatedQualitativeAnswers,
  MetricsTableData,
  useGetAggregatedMetricsData,
} from '../Metrics';
import { useMaterialStandardId } from 'containers/Esrs/EsrsAssessment.hooks';
import { IconHelpCircle } from '@tabler/icons-react';

const Header = () => (
  <VStack spacing="16px" alignItems="start" w="100%">
    <Box w="100%" borderBottom="1px solid" borderColor="border.decorative" pb="8px">
      <Typography variant="h3">Disclosure Requirements</Typography>
    </Box>
    <Box w="100%">
      <Infobox
        status="neutral"
        withIcon={false}
        description="ESRS requires to disclose on different data points based on whether all material sustainability matters are covered by adopted policies or not. Please answer the relevant data points below."
        closable={false}
      />
    </Box>
  </VStack>
);

const PolicyFileList = ({
  attachments,
  onAttachmentDrawerOpen,
  refetchQueries,
}: {
  attachments: AttachmentBox['attachments'];
  onAttachmentDrawerOpen: () => void;
  refetchQueries: RefetchQueriesInclude;
}) => {
  const { t } = useTranslation('question');
  const [search, setSearch] = useState<string>('');

  const filteredAttachments = useMemo(() => {
    return attachments.filter((attachment) => attachment.file.title.toLowerCase().includes(search));
  }, [attachments, search]);

  const unassignAttachment = useRemoveAttachment();

  const removeAttachment = useCallback(
    (attachmentId: string) => {
      return unassignAttachment(attachmentId, refetchQueries);
    },
    [unassignAttachment]
  );

  const Editor = useContext(FileEditorContext);

  return (
    <VStack w="100%" spacing="16px" alignItems="stretch">
      <HStack w="100%">
        <SearchInput search={search} setSearch={setSearch} placeholder="Filter" height="28px" />
        <Button variant="primary" onClick={onAttachmentDrawerOpen} size="sm">
          Add file
        </Button>
      </HStack>
      {filteredAttachments.length === 0 ? (
        <Box w="100%" h="100%">
          <EmptyState
            title={t('common:search.filter.emptyTitle')}
            description={t('common:search.filter.emptyDescription')}
            component={true}
          />
        </Box>
      ) : (
        <VStack spacing="0px">
          {filteredAttachments.map((attachment) => {
            const file = attachment.file;
            const { extension } = getNameExtension(file.storageFile?.name ?? '');

            const actions = Editor.actions(file);
            actions.pop();
            actions.unshift({
              id: 'detach',
              title: t('question:fileDetach'),
              leftElement: <RemoveIcon color="inherit" />,
              onClick: () => {
                removeAttachment(attachment.id);
              },
            });

            return (
              <HStack
                h="48px"
                w="100%"
                justifyContent="center"
                alignItems="center"
                borderTop="1px solid"
                borderColor="border.decorative"
              >
                <Box w="40px" boxSize="24px">
                  <FileIcon
                    extension={extension}
                    {...defaultStyles[extension as keyof typeof defaultStyles]}
                    labelUppercase
                  />
                </Box>
                <Box w="90%" px="8px" alignItems="flex-start">
                  <Typography noOfLines={1} textOverflow="ellipsis" variant="bodyStrong">
                    {attachment.file.title}
                  </Typography>
                </Box>
                <Menu
                  sections={[
                    {
                      actions: actions,
                    },
                  ]}
                />
              </HStack>
            );
          })}
        </VStack>
      )}
    </VStack>
  );
};

export const Policies = ({
  setMetricSidebarOpen,
}: {
  setMetricSidebarOpen: (val: boolean) => void;
}) => {
  const { standardRef = '', esrsAssessmentId = '', disclosureRequirementRef = '' } = useParams();
  const { companyType, loading } = useCompanyType();
  const {
    isOpen: isAttachmentDrawerOpen,
    onOpen: onAttachmentDrawerOpen,
    onClose: onAttachmentDrawerClose,
  } = useDisclosure();
  const { isOpen, onClose, onOpen } = useDisclosure();
  const isGroup = useMemo(() => companyType === 'group-company', [companyType]);
  const [upsertPolicy] = useUpsertPolicyMutation();
  const [rowData, setRowData] = useState<
    MetricsTableData & { sourceData?: AggregatedQualitativeAnswers[number] }
  >();
  const { companyAssessmentId } = useMaterialStandardId(standardRef, esrsAssessmentId);

  const { data: policiesData, loading: policiesLoading } = useGetPoliciesDrQuery({
    variables: {
      reference: disclosureRequirementRef,
      esrsAssessmentId,
      standardRef,
    },
    skip: !disclosureRequirementRef || !esrsAssessmentId || !standardRef,
  });

  const [policyDR, policy] = useMemo(
    () => [
      policiesData?.DisclosureRequirement_by_pk,
      policiesData?.DisclosureRequirement_by_pk?.policies[0],
    ],
    [policiesData]
  );

  const { metrics: adoptedPolicyMetrics } = useGetAggregatedMetricsData(false);
  const { metrics: notAdoptedPolicyMetrics } = useGetAggregatedMetricsData(true);

  const attachmentBox = useMemo(() => {
    return policy?.attachmentBox ?? undefined;
  }, [policy]);

  const { title = '', description = '' } = policyDR || {};

  useEffect(() => {
    if (!policiesLoading && !attachmentBox) {
      upsertPolicy({
        variables: {
          input: {
            assessmentId: companyAssessmentId,
            disclosureRequirementRef: disclosureRequirementRef,
            noteHistory: {
              data: {},
              on_conflict: {
                constraint: NoteHistory_Constraint_.NoteHistoryPolicyIdKey_,
                update_columns: [],
              },
            },
            attachmentBox: {
              data: {},
              on_conflict: {
                constraint: AttachmentBox_Constraint_.AttachmentBoxPoliciesIdKey_,
                update_columns: [],
              },
            },
          },
        },
      });
    }
  }, [policiesLoading, attachmentBox]);

  if (attachmentBox?.attachments.length === 0 && !policyDR?.policies.length)
    return (
      <AttachmentDrawer
        isOpen={isAttachmentDrawerOpen}
        refetch={[GetPoliciesDocumentationByIdDocument_, GetDisclosureRequirementGroupsDocument_]}
        onClose={onAttachmentDrawerClose}
        attachmentBox={attachmentBox}
      >
        <VStack spacing="12px" alignItems="start" w="100%" height="100%">
          <DisclosureRequirementTitle
            title="Policies"
            reference={disclosureRequirementRef}
            onOpen={onOpen}
          />

          <LearnMoreDrawer
            header={title}
            isOpen={isOpen}
            onClose={onClose}
            description={description ?? ''}
          />
        </VStack>
      </AttachmentDrawer>
    );

  const accordionItems = [
    {
      id: 'adopted',
      label: 'Disclosure requirements for adopted policies',
      icon: <TargetIcon mt="4px" />,
      content: (
        <AggregatedMetrics
          setMetricSidebarOpen={setMetricSidebarOpen}
          rowData={rowData}
          setRowData={setRowData}
        />
      ),
    },
    {
      id: 'notAdopted',
      label: (
        <Typography variant="h3" as={'p'}>
          Disclosure requirements if
          <Typography variant="h3" as={'span'} color="text.critical">
            {' '}
            not all material sustainability matters are covered{' '}
          </Typography>
          by adopted policies
        </Typography>
      ),
      icon: <TargetOffIcon color="border.critical.accent" mt="3px" />,
      content: (
        <AggregatedMetrics
          withAssociation={true}
          setMetricSidebarOpen={setMetricSidebarOpen}
          rowData={rowData}
          setRowData={setRowData}
        />
      ),
    },
  ];

  const filteredAccordionItems = useMemo(() => {
    return accordionItems.filter((item) => {
      if (item.id === 'adopted') {
        return (adoptedPolicyMetrics ?? []).length > 0;
      } else if (item.id === 'notAdopted') {
        return (notAdoptedPolicyMetrics ?? []).length > 0;
      }
    });
  }, [accordionItems, adoptedPolicyMetrics, notAdoptedPolicyMetrics]);

  if (loading || policiesLoading) {
    return <Loader />;
  }

  return (
    <AttachmentDrawer
      isOpen={isAttachmentDrawerOpen}
      refetch={[GetPoliciesDrDocument_, GetDisclosureRequirementGroupsDocument_]}
      onClose={onAttachmentDrawerClose}
      attachmentBox={attachmentBox}
    >
      <VStack spacing="20px" alignItems="start" w="100%">
        <DisclosureRequirementTitle
          title="Policies"
          reference={policyDR?.reference}
          onOpen={onOpen}
        />
        <VStack spacing="32px" w="100%" alignItems="stretch">
          <VStack alignItems="start" spacing="6px" w="100%">
            <Typography
              variant="h3"
              borderBottom="1px solid"
              w="100%"
              borderColor="border.decorative"
              paddingBottom="8px"
              marginBottom="12px"
            >
              Manage policies
            </Typography>

            {attachmentBox?.attachments.length === 0 || !policyDR?.policies.length ? (
              <EmptyState
                description="Upload policies adopted to manage material impacts, risks and opportunities related to the topic"
                icon={<DocumentCheckmarkIcon boxSize="20px" />}
                variant="white"
                component={true}
                callToAction={{
                  text: 'Add policies',
                  variant: 'ghost',
                  leftIcon: <AddIcon />,
                  onClick: () => {
                    onAttachmentDrawerOpen();
                  },
                }}
              />
            ) : (
              <PolicyFileList
                attachments={attachmentBox?.attachments ?? []}
                onAttachmentDrawerOpen={onAttachmentDrawerOpen}
                refetchQueries={[GetPoliciesDrDocument_, GetDisclosureRequirementGroupsDocument_]}
              />
            )}
          </VStack>
          <VStack alignItems="start" spacing="24px">
            <Header />
            <Accordion variant="solid" lazyLoadContent items={filteredAccordionItems} />
          </VStack>
        </VStack>

        {isGroup && <PoliciesSubsidiaries />}

        <LearnMoreDrawer
          header={title}
          isOpen={isOpen}
          onClose={onClose}
          description={description ?? ''}
        />
      </VStack>
    </AttachmentDrawer>
  );
};
