import { HStack, Box } from '@chakra-ui/react';
import { ColumnDef } from '@tanstack/react-table';
import { Checkbox, EmptyState, Radio, Tag, Tooltip } from 'Atoms';
import { UserAvatar } from 'Organisms';
import { Typography, NothingFoundIllustration } from 'Tokens';
import { RefreshIcon } from 'Tokens/Icons/Function';
import { orderBy } from 'lodash';
import { DocumentationFile } from 'models';
import { useContext, useMemo } from 'react';
import { FileIcon, defaultStyles } from 'react-file-icon';
import { useTranslation } from 'react-i18next';
import { formatDateTime } from 'utils/date';
import { getFileFilter, getNameExtension } from 'utils/files';
import { FileEditorContext } from '../FileEditor';
import { Menu } from 'Molecules/Menu';
import { Table } from 'Molecules';

export const FileListTable = ({
  search,
  setSearch,
  files,
  selectedFiles,
  setSelectedFiles,
  toggleFileSelected,
  drive,
  singleFileSelect,
}: {
  search: string;
  setSearch: (s: string) => void;
  files: DocumentationFile[];
  selectedFiles: DocumentationFile[];
  setSelectedFiles: (files: DocumentationFile[]) => void;
  toggleFileSelected: (file: DocumentationFile) => void;
  drive: boolean;
  singleFileSelect: boolean;
}) => {
  const filter = useMemo(() => getFileFilter(search), [search]);
  const filteredFiles = useMemo(() => {
    return orderBy(files, ['title'], ['asc']).filter(filter);
  }, [filter, files]);
  const { t } = useTranslation('common');

  const allFilesChecked = useMemo(
    () => filteredFiles.length === selectedFiles.length,
    [filteredFiles, selectedFiles]
  );
  const someFilesChecked = useMemo(
    () => selectedFiles.length > 0 && !allFilesChecked,
    [selectedFiles, allFilesChecked]
  );

  const columns = useMemo(() => {
    const generalColumns: ColumnDef<DocumentationFile>[] = [
      {
        header: 'Title',
        id: 'title',
        size: 300,
        accessorFn: (file) => file.title,
        cell: ({ row }) => {
          const { extension } = getNameExtension(row.original.storageFile?.name ?? '');

          return (
            <HStack width="100%">
              <Box boxSize="24px">
                <FileIcon
                  extension={extension}
                  {...defaultStyles[extension as keyof typeof defaultStyles]}
                  labelUppercase
                />
              </Box>
              <Tooltip label={row.original.title}>
                <Typography noOfLines={1} textOverflow="ellipsis" variant="bodyStrong">
                  {row.original.title}
                </Typography>
              </Tooltip>
            </HStack>
          );
        },
      },
      {
        header: 'Labels',
        id: 'labels',
        accessorFn: (file) => file.tags,
        size: 50,
        cell: ({ row }) => {
          return (
            <HStack>
              {row.original.tags.map((tag: string) => (
                <Tag key={tag} as={search ? 'button' : undefined}>
                  {tag}
                </Tag>
              ))}
            </HStack>
          );
        },
      },
      {
        header: 'Last updated',
        id: 'lastUpdated',
        size: 150,
        accessorFn: (file) => file.updatedAt,
        cell: ({ row }) => {
          return (
            <HStack>
              <UserAvatar user={row.original.uploadedBy} size="sm" />
              <Typography noOfLines={1} textOverflow="ellipsis" variant="bodyStrong">
                {formatDateTime(row.original.updatedAt)}
              </Typography>
            </HStack>
          );
        },
      },
      {
        header: '',
        id: 'actions',
        size: 1,
        enableResizing: false,
        cell: ({ row }) => {
          const Editor = useContext(FileEditorContext);
          const actions = Editor.actions(row.original);

          return (
            <Menu
              sections={[
                {
                  actions: actions,
                },
              ]}
            />
          );
        },
      },
    ];
    return [...generalColumns];
  }, [files, filteredFiles, selectedFiles, someFilesChecked, allFilesChecked]);

  if (!drive) {
    if (singleFileSelect) {
      columns.unshift({
        header: '',
        id: 'radio',
        size: 1,
        enableResizing: false,
        cell: ({ row }) => {
          return (
            <Radio
              isChecked={selectedFiles.find((val) => val.id === row.original.id) !== undefined}
              onChange={() => {
                setSelectedFiles([row.original]);
              }}
            />
          );
        },
      });
    } else
      columns.unshift({
        header: () => {
          return (
            <Checkbox
              isChecked={allFilesChecked}
              isIndeterminate={someFilesChecked}
              onChange={(e) => {
                if (e.target.checked) {
                  setSelectedFiles(files);
                } else {
                  setSelectedFiles([]);
                }
              }}
            />
          );
        },
        size: 1,
        enableResizing: false,
        id: 'checkbox',
        cell: ({ row }) => {
          return (
            <Checkbox
              isChecked={selectedFiles.find((val) => val.id === row.original.id) !== undefined}
              onChange={() => {
                return toggleFileSelected(row.original);
              }}
            />
          );
        },
      });
  }

  return filteredFiles.length ? (
    <Table headerPadding="8px" columns={columns} data={filteredFiles} cellPadding="8px" />
  ) : (
    <Box w="100%" flexGrow="1" mt="16px">
      <EmptyState
        title={t('common:search.filter.emptyTitle')}
        description={t('common:search.filter.emptyDescription')}
        callToAction={{
          text: t('common:search.filter.emptyBtn'),
          variant: 'secondary',
          onClick: () => {
            setSearch('');
          },
          leftIcon: <RefreshIcon color="inherit" />,
        }}
        icon={<NothingFoundIllustration boxSize="120px" />}
      />
    </Box>
  );
};
