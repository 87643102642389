import { HStack, VStack, useDisclosure } from '@chakra-ui/react';
import { AttachmentDrawer } from 'Features/Screening/AttachmentsDrawer';
import { useGetTargetDocumentation } from '../Targets.hooks';
import {
  InputCardDocumentation,
  InputCardDocumentationProps,
} from 'Molecules/InputCard/InputCardDocumentation';
import { useMemo } from 'react';
import { DocumentationFileDetailsFragment_, GetTargetDocumentationByIdDocument_ } from 'models';
import { User } from '@nhost/react';
import { File } from 'Molecules';

export const TargetDocumentation = ({
  targetId,
  user,
  filesAttached,
  setNote,
  setFilesAttached,
}: {
  targetId: string | undefined;
  user: User | null;
  filesAttached: DocumentationFileDetailsFragment_[] | undefined;
  setNote: (param: string | null | undefined) => void;
  setFilesAttached: (param: DocumentationFileDetailsFragment_[]) => void;
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const { attachmentBox, noteHistory, documentationData, documentationLoading } =
    useGetTargetDocumentation(targetId);

  const showDocumentation: InputCardDocumentationProps = useMemo(() => {
    return {
      currentAuthor: user ?? undefined,
      attachmentBox: attachmentBox,
      noteHistory: noteHistory,
      openAttachmentDrawer: onOpen,
      refetchQueries: [GetTargetDocumentationByIdDocument_],
      isInputCard: false,
      isLoading: documentationLoading,
      onNoteChanged: !!noteHistory ? undefined : (newNote) => setNote(newNote),
    };
  }, [documentationData, noteHistory, attachmentBox, user, documentationLoading]);
  return (
    <VStack spacing="6px" w="100%" alignItems="start">
      <AttachmentDrawer
        isOpen={isOpen}
        refetch={[GetTargetDocumentationByIdDocument_]}
        onClose={onClose}
        attachmentBox={attachmentBox}
        onAttachClicked={
          !!attachmentBox
            ? undefined
            : (files) => {
                setFilesAttached(files);
              }
        }
      >
        <InputCardDocumentation {...showDocumentation} />
      </AttachmentDrawer>
      {!attachmentBox && (
        <HStack mt="-16px">
          {filesAttached?.map((file, index) => <File file={file} key={index} />)}
        </HStack>
      )}
    </VStack>
  );
};
