import { HStack, VStack } from '@chakra-ui/react';
import {
  CompanyAssessmentDetails,
  CompanyAssessmentDetailsFieldsFragment_,
  InvestorsQuery_,
} from 'models';
import React, { useMemo } from 'react';
import { AssessmentEmptyState } from './AssessmentEmptyState';
import { ReportingPeriodTileHeader } from './ReportingPeriodTileHeader';
import { AssessmentsTable } from 'containers/Assessments/pieces/AssessmentsTable';
import { getInvestorsByReportingPeriods } from 'containers/Assessments/Assessments.hooks';

export const ReportingPeriodTile = ({
  assessments,
  allAssessments,
  openModal,
  investors,
  setCompanyAssessment,
  reportingPeriod,
  setSelectedReportingPeriod,
  isOther,
}: {
  assessments: CompanyAssessmentDetailsFieldsFragment_[] | undefined;
  allAssessments: CompanyAssessmentDetailsFieldsFragment_[] | undefined;
  openModal?: () => void;
  investors: InvestorsQuery_['investors'];
  setCompanyAssessment?: (val: CompanyAssessmentDetails) => void;
  reportingPeriod?: { label: string; value: string };
  setSelectedReportingPeriod?: (val?: string) => void;
  isOther?: boolean;
}) => {
  const hasAssessments = useMemo(() => {
    return assessments && assessments.length > 0;
  }, [assessments]);

  const investorsByReportingPeriod = useMemo(() => {
    return getInvestorsByReportingPeriods(investors, reportingPeriod?.value ?? '');
  }, [investors]);

  return (
    <VStack
      border="1px solid"
      borderColor="border.decorative"
      borderRadius="8px"
      padding="12px 16px 16px 16px"
      width="100%"
      gap="16px"
    >
      <ReportingPeriodTileHeader
        investors={investorsByReportingPeriod}
        isOther={isOther}
        title={reportingPeriod?.label ?? ''}
      />
      <HStack width="100%">
        {hasAssessments ? (
          <AssessmentsTable
            allAssessments={allAssessments ?? []}
            assessments={assessments ?? []}
            openModal={openModal}
            investors={investors}
            setCompanyAssessment={setCompanyAssessment}
            isOther={isOther}
            setSelectedReportingPeriod={setSelectedReportingPeriod}
            reportingPeriod={reportingPeriod?.value ?? ''}
          />
        ) : (
          <AssessmentEmptyState
            allAssessments={allAssessments}
            isOther={isOther}
            investors={investorsByReportingPeriod}
            reportingPeriod={reportingPeriod?.value}
          />
        )}
      </HStack>
    </VStack>
  );
};
