import { HStack, VStack } from '@chakra-ui/react';
import { Accordion, Tag } from 'Atoms';
import React, { useMemo } from 'react';
import { ReportingGroup } from '../../models/types';
import { CompanyFinancialResults } from './Financials.hooks';
import { Typography } from 'Tokens';
import { BusinessUnitFinancialTable } from './FinancialTable';
import { useTranslation } from 'utils/translation';
import { ReportingGroupFieldsFragment_ } from 'models';
import { FolderIcon } from 'Tokens/Icons/Data';

const getGroupChildren = (group: ReportingGroup, allGroups: ReportingGroup[]): ReportingGroup[] => {
  const children = allGroups.filter((g) => g.parentId === group.id);
  if (children.length === 0) return [];
  return [...children, ...children.map((child) => getGroupChildren(child, allGroups)).flat()];
};

const getGroupIsDone = (
  group: ReportingGroup,
  allBusinessUnits: CompanyFinancialResults['businessUnits'],
  allReportingGroups: ReportingGroup[]
) => {
  const children = getGroupChildren(group, allReportingGroups);
  const childrenBu = allBusinessUnits.filter((bu) =>
    [...children, group].find((grp) => grp.id === bu.reportingGroupId)
  );
  if (childrenBu.length === 0) return undefined;
  return childrenBu.every((bu) => bu.financials?.isEstimate === false);
};

export const GroupFinancials = ({
  reportingGroup,
  businessUnits,
  children,
  allBusinessUnits,
  allReportingGroups,
  onUpdate,
  bg,
  isLocked,
}: {
  reportingGroup: ReportingGroup;
  businessUnits: CompanyFinancialResults['businessUnits'];
  children: ReportingGroupFieldsFragment_[];
  allBusinessUnits: CompanyFinancialResults['businessUnits'];
  allReportingGroups: ReportingGroupFieldsFragment_[];
  onUpdate: (businessUnit: CompanyFinancialResults['businessUnits'][number]) => void;
  isLocked: boolean;
  bg: 'solid' | 'outlined';
}) => {
  const { t } = useTranslation('common');
  const isDone = useMemo(() => {
    return getGroupIsDone(reportingGroup, allBusinessUnits, allReportingGroups);
  }, [allBusinessUnits, businessUnits]);
  return (
    <Accordion
      defaultIndex={[0]}
      variant={bg}
      marginBottom="12px"
      items={[
        {
          label: reportingGroup.name,
          icon: <FolderIcon marginTop="8px" />,
          accordionNb: children.length + businessUnits.length,
          tag:
            isDone !== undefined
              ? [
                  <Tag variant={isDone ? 'success' : 'default'}>
                    {isDone ? t('common:buttons.done') : t('common:buttons.todo')}
                  </Tag>,
                ]
              : [<Tag>{t('common:words.empty')}</Tag>],
          content: (
            <VStack alignItems="flex-start" bg={bg === 'solid' ? 'bg.muted' : 'white'}>
              {children &&
                children.map((rg) => (
                  <GroupFinancials
                    key={rg.id}
                    reportingGroup={rg}
                    children={allReportingGroups.filter((child) => child.parentId === rg.id)}
                    allBusinessUnits={allBusinessUnits}
                    allReportingGroups={allReportingGroups}
                    isLocked={isLocked}
                    businessUnits={allBusinessUnits.filter((bu) =>
                      rg.businessUnits.find((rgBu) => rgBu.businessUnit?.id === bu.businessUnit?.id)
                    )}
                    onUpdate={onUpdate}
                    bg="outlined"
                  />
                ))}
              {businessUnits.length ? (
                businessUnits.map((bu) => (
                  <BusinessUnitFinancialTable
                    key={bu.businessUnit?.id}
                    businessUnit={bu}
                    isLocked={isLocked}
                    onUpdate={(newBu) => {
                      onUpdate(newBu);
                    }}
                  />
                ))
              ) : (
                <>
                  {!children.length && (
                    <HStack width="100%" justifyContent="center">
                      <Typography variant="bodyStrong">
                        {t('common:assessment.groupFinancials.reporting')}
                      </Typography>
                    </HStack>
                  )}
                </>
              )}
            </VStack>
          ),
        },
      ]}
    />
  );
};
