import {
  Box,
  Divider,
  HStack,
  Menu,
  MenuButton,
  MenuGroup,
  MenuItem,
  MenuList,
} from '@chakra-ui/react';
import { Button } from 'Atoms';
import { useNavigate } from 'react-router-dom';
import { Typography } from 'Tokens';
import { ChevronDownIcon } from 'Tokens/Icons/Direction';
import { CheckIcon } from 'Tokens/Icons/Status';
import { formatDisplayQuarterYear } from 'utils/date';
import { useTranslation } from 'utils/translation';

export const ReportYearSelector = ({
  selectedYear,
  setSelectedYear,
  reportingYears,
  isAuditor = false,
  withAddPeriod = true,
}: {
  selectedYear: string | undefined;
  setSelectedYear: (selectedYear: string) => void;
  reportingYears: string[];
  isAuditor?: boolean;
  withAddPeriod?: boolean;
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation('portfolio');

  const handleNavigate = () => {
    if (isAuditor) {
      return;
    }
    const years = reportingYears.map((reportingYear) => Number(reportingYear.split('-')[1]));
    const sortedUniqueYears = [...new Set(years)].sort();
    const latestReportingYear = sortedUniqueYears[sortedUniqueYears.length - 1];
    navigate(`Q1-${latestReportingYear + 1}/create`);
  };
  return (
    <Menu gutter={2}>
      <MenuButton
        as={Button}
        minWidth="96px"
        width="fit-content"
        h="36px"
        bg="bg.default"
        borderWidth="1px"
        borderColor="border.default"
        _hover={{ borderColor: 'border.hover' }}
        _focus={{
          borderColor: 'border.selected.accent',
          boxShadow: 'none',
        }}
        _active={{
          bg: 'bg.default',
        }}
        borderRadius="8px"
        p="8px"
        rightIcon={<ChevronDownIcon color="inherit" />}
        textAlign="left"
        color="text.default"
      >
        {formatDisplayQuarterYear(selectedYear)}
      </MenuButton>
      <MenuList p="8px">
        <MenuGroup p="8px">
          {reportingYears.sort().map((year) => (
            <MenuItem p="8px" m="0px" w="100%" onClick={() => setSelectedYear(year)}>
              <HStack
                w="100%"
                justifyContent="space-between"
                color={selectedYear === year ? 'text.selected' : ''}
              >
                <HStack>
                  <Typography color={'inherit'} variant="bodyStrong">
                    {formatDisplayQuarterYear(year)}
                  </Typography>
                </HStack>
                {year === selectedYear && <CheckIcon color="inherit" />}
              </HStack>
            </MenuItem>
          ))}
        </MenuGroup>
        {withAddPeriod && (
          <>
            <Divider color="border.decorative" my="8px" ml="-8px" pr="16px" />

            <Box w="100%">
              <Button w="100%" onClick={handleNavigate} isDisabled={isAuditor}>
                {t('addPeriod')}
              </Button>
            </Box>
          </>
        )}
      </MenuList>
    </Menu>
  );
};
