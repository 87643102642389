import { VStack } from '@chakra-ui/react';
import { useTaxonomyAuditLogs } from 'Features/History/History.hooks';
import {
  HistorySideBarFiltering,
  HistorySidebar,
  HistorySidebarHeader,
} from 'Features/History/HistorySidebar';
import { Drawer } from 'Molecules';
import { CompanyAssessment } from 'models';
import { useState } from 'react';

export const AssessmentHistory = ({
  cAssessment,
  isHistorySidebarOpen,
  setIsHistorySidebarOpen,
}: {
  cAssessment: CompanyAssessment;
  isHistorySidebarOpen: boolean;
  setIsHistorySidebarOpen: (open: boolean) => void;
}) => {
  const {
    auditLogs,
    loading: auditLogsLoading,
    refetch: refetchAuditLogs,
  } = useTaxonomyAuditLogs(cAssessment);
  const [searchValue, setSearchValue] = useState('');

  return (
    <Drawer
      onClose={() => {
        setIsHistorySidebarOpen(false);
      }}
      isOpen={!!cAssessment && isHistorySidebarOpen}
      width="400px"
      hasOverlay={false}
      returnFocusOnClose={false}
      header={
        <VStack width="100%" paddingBottom="0px">
          <HistorySidebarHeader />
          <HistorySideBarFiltering
            title={cAssessment?.aggregate.title ?? ''}
            refetchAuditLogs={refetchAuditLogs}
            searchValue={searchValue}
            setSearchValue={setSearchValue}
          />
        </VStack>
      }
      headerProps={{
        paddingBottom: '0px',
        borderLeft: '1px solid',
        borderColor: 'border.decorative',
      }}
      bodyProps={{
        padding: '0px',
        borderLeft: '1px solid',
        borderColor: 'border.decorative',
      }}
    >
      <HistorySidebar
        key="history-drawer"
        auditLogs={auditLogs}
        loading={auditLogsLoading}
        searchValue={searchValue}
      />
    </Drawer>
  );
};
