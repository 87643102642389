import { resolveActivityAssessment } from 'containers/Assessments/Assessments.hooks';
import {
  ActivityAssessmentQuery_,
  ActivityReport_Insert_Input_,
  ActivityResults,
  BusinessUnitAssessmentResults,
  BusinessUnitAssessment_Insert_Input_,
  CompanyAssessmentResults,
  CompanyAssessment_Insert_Input_,
  GeneralActivityCachedResult,
  RawCompanyAssessmentResults,
  useCompanyAssessmentCachedResultsSubscription,
  useUpsertCompanyAssessmentResultsMutation,
  CachedResult_On_Conflict_,
  CachedResult_Constraint_,
  CachedResult_Update_Column_,
  BusinessUnitCachedResult,
  useGetGroupConsolidatedAssessmentResultQuery,
} from 'models';
import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  defaultFinancials,
  getActivityResult,
  getBusinessUnitAssessmentResult,
  getCompanyAssessmentResult,
  getGeneralAssessmentResult,
  transformCompanyAssessmentCachedResults,
} from 'utils/scores/taxonomyScore';
import { useCurrentCompanyId, useToast } from 'utils/hooks';
import { keyBy, omit } from 'lodash';
import {
  AlignmentStatusItem,
  EntityAlignmentStatus,
  AlignmentType,
} from 'Organisms/AlignmentStatus';
import { isValidBool } from 'utils';
import { captureException } from '@sentry/react';
import { getSubstantialContributionProportion } from 'utils/scores/substantialContribution';
import { nhost } from 'utils/nhost';

type ActivityAssesments = {
  [key: string]:
    | ActivityAssessmentQuery_['objectives']
    | ActivityAssessmentQuery_['activityAssessment'];
};

type AllActivityAssessmentsResult = {
  allActivityAssessments: {
    data: ActivityAssesments;
  };
};

export const cacheResultsOnConflict: CachedResult_On_Conflict_ = {
  constraint: CachedResult_Constraint_.CachedResultPkey_,
  update_columns: [
    CachedResult_Update_Column_.Financials_,
    CachedResult_Update_Column_.ActivityTag_,
    CachedResult_Update_Column_.Score_,
    CachedResult_Update_Column_.IsAligned_,
    CachedResult_Update_Column_.IsCompleted_,
    CachedResult_Update_Column_.Progress_,
    CachedResult_Update_Column_.IsDirty_,
    CachedResult_Update_Column_.ObjectivesState_,
    CachedResult_Update_Column_.Status_,
  ],
};

const calculateActivityAssessmentResults = (
  activityCachedResult: ActivityResults,
  activityAssessment: ActivityAssessmentQuery_,
  isMSSAlignedAndCompleted: boolean
): ActivityResults | undefined => {
  if (!activityAssessment) return undefined;

  const resolvedActivityAssessment = resolveActivityAssessment(
    activityAssessment,
    isMSSAlignedAndCompleted
  );
  if (!resolvedActivityAssessment) return undefined;

  const activityResult = getActivityResult(resolvedActivityAssessment);

  const activityAssessmentResult = {
    id: activityCachedResult.cachedResult?.id,
    ...activityResult,
  };

  return { ...activityCachedResult, cachedResult: activityAssessmentResult };
};

const calculateBusinessUnitAssessmentResults = (
  businessUnitCachedResult: BusinessUnitAssessmentResults,
  companyLevelGeneralAssessmentResult: GeneralActivityCachedResult,
  generalAssessment: ActivityAssessmentQuery_,
  activities: Array<ActivityAssessmentQuery_>
): BusinessUnitAssessmentResults | undefined => {
  const resultsById: Record<string, ActivityAssessmentQuery_> = keyBy<ActivityAssessmentQuery_>(
    activities,
    (a) => a.activityAssessment?.id
  );
  if (!generalAssessment) return undefined;

  let generalAssessmentResult: GeneralActivityCachedResult = companyLevelGeneralAssessmentResult;

  const resolvedGeneralAssessment = resolveActivityAssessment(generalAssessment, undefined);
  if (!resolvedGeneralAssessment) return undefined;
  const businessUnitGeneralResults = getGeneralAssessmentResult(resolvedGeneralAssessment);

  if (businessUnitCachedResult.hasGeneralAssessment) {
    generalAssessmentResult = {
      id:
        businessUnitCachedResult.generalAssessmentResult.cachedResult?.id ===
        companyLevelGeneralAssessmentResult.id
          ? undefined
          : businessUnitCachedResult.generalAssessmentResult.cachedResult?.id,
      ...businessUnitGeneralResults,
    };
  } else {
    generalAssessmentResult = {
      ...generalAssessmentResult,
      financials: businessUnitGeneralResults.financials,
      progress: {
        financials: businessUnitGeneralResults.progress.financials,
        screening: 100,
      },
    };
  }

  const activityResults = businessUnitCachedResult.activityResults
    .map((ar) =>
      calculateActivityAssessmentResults(
        ar,
        resultsById[ar.activityAssessmentId],
        (generalAssessmentResult?.isAligned && generalAssessmentResult.isCompleted) ?? false
      )
    )
    .filter((ar): ar is ActivityResults => !!ar);

  const businessUnitFinancials =
    generalAssessment.activityAssessment?.financials || defaultFinancials;

  const businessUnitAssessmentResult = {
    id: businessUnitCachedResult.cachedResult?.id,
    ...getBusinessUnitAssessmentResult({
      businessUnitFinancials,
      generalAssessmentResult,
      activityAssessmentResults: activityResults,
      hasGeneralAssessment: businessUnitCachedResult?.hasGeneralAssessment,
    }),
  };

  return {
    ...businessUnitCachedResult,
    cachedResult: businessUnitAssessmentResult,
    activityResults,
    generalAssessmentResult: {
      ...businessUnitCachedResult.generalAssessmentResult,
      cachedResult: generalAssessmentResult,
    },
  };
};

const mapActivityAssessmentsToIds = (values: ActivityAssesments) => {
  const byActivityId = Object.keys(values).reduce((acc: any, key: string) => {
    if (key.includes('activity')) {
      const activityAssessmentId = (
        values[key as string] as ActivityAssessmentQuery_['activityAssessment']
      )?.id;
      if (activityAssessmentId) {
        acc[activityAssessmentId] = {
          activityAssessment: values[
            key as string
          ] as ActivityAssessmentQuery_['activityAssessment'],
          objectives: values[
            `objectives${key.slice(8)}` as string
          ] as ActivityAssessmentQuery_['objectives'],
        };
      }
    }
    return acc;
  }, {});

  return byActivityId as unknown as { [key: string]: ActivityAssessmentQuery_ };
};

const useCalculateCompanyAssessmentResults = (
  companyAssessmentStructure?: RawCompanyAssessmentResults
) => {
  const toast = useToast();
  const companyCachedResults = useMemo(() => {
    return companyAssessmentStructure
      ? transformCompanyAssessmentCachedResults(companyAssessmentStructure)
      : undefined;
  }, [companyAssessmentStructure]);

  const idsWithVersion = useMemo(() => {
    const ids: Array<{ id?: string | null; version?: number | null }> = [
      {
        id: companyCachedResults?.generalAssessmentResult?.activityAssessmentId,
        version: companyCachedResults?.generalAssessmentResult?.activityVersionNumber,
      },
      ...(companyCachedResults?.businessUnitResults
        .map((bu) => ({
          id: bu.generalAssessmentResult?.activityAssessmentId,
          version: bu.generalAssessmentResult?.activityVersionNumber,
        }))
        ?.flat() ?? []),
      ...(companyCachedResults?.businessUnitResults
        .map((bu) =>
          bu.activityResults.map((ar) => ({
            id: ar?.activityAssessmentId,
            version: ar?.activityVersionNumber,
          }))
        )
        .flat() ?? []),
    ];

    if (ids.some((i) => !!i.id)) return ids ?? [];
  }, [companyAssessmentStructure, companyCachedResults]);

  const calculateResults = useCallback(async () => {
    const { res, error } = await nhost.functions.call<AllActivityAssessmentsResult>(
      'all-activity-assessments',
      {
        idsWithVersion,
      }
    );

    if (error || !res) {
      captureException(new Error(error.message), {
        extra: {
          errorMessage: 'Error in calculating all activity assessments result',
          message: error.message,
          error: error.error,
          status: error.status,
        },
      });
      toast({
        text: 'Failed to calculate results',
        variant: 'danger',
      });

      return;
    }

    const byActivityId = mapActivityAssessmentsToIds(res.data.allActivityAssessments.data);

    if (!byActivityId) return undefined;

    const companyLevelGeneralAssessment =
      byActivityId[companyCachedResults?.generalAssessmentResult.activityAssessmentId];

    if (!companyLevelGeneralAssessment) return undefined;

    const resolvedGeneralAssessment = resolveActivityAssessment(companyLevelGeneralAssessment);

    if (!resolvedGeneralAssessment) return undefined;
    const generalAssessmentResult = {
      id: companyCachedResults?.generalAssessmentResult?.cachedResult?.id,
      ...getGeneralAssessmentResult(resolvedGeneralAssessment),
    };
    const businessUnitAssessments =
      companyCachedResults?.businessUnitResults.map((bu) => {
        const businessUnitGeneralAssessment =
          byActivityId[bu.generalAssessmentResult?.activityAssessmentId];

        const businessUnitActivities = bu.activityResults.map(
          (ar) => byActivityId[ar?.activityAssessmentId]
        );
        return {
          cachedResults: bu,
          generalAssessment: businessUnitGeneralAssessment,
          activities: businessUnitActivities,
        };
      }) ?? [];

    const businessUnitResults = businessUnitAssessments
      .map((bu) =>
        calculateBusinessUnitAssessmentResults(
          bu.cachedResults,
          generalAssessmentResult,
          bu.generalAssessment,
          bu.activities
        )
      )
      .filter((br): br is BusinessUnitAssessmentResults => !!br);
    const companyFinancials =
      resolvedGeneralAssessment.activityAssessment?.financials || defaultFinancials;
    const companyResults = {
      id: companyCachedResults?.cachedResult?.id,
      ...getCompanyAssessmentResult({
        companyFinancials,
        generalAssessmentResult,
        businessUnitAssessmentResults: businessUnitResults,
      }),
    };

    return {
      ...companyCachedResults,
      generalAssessmentResult: {
        ...companyCachedResults?.generalAssessmentResult,
        cachedResult: generalAssessmentResult,
      },
      businessUnitResults,
      cachedResult: companyResults,
    } as CompanyAssessmentResults;
  }, [companyCachedResults, companyAssessmentStructure]);

  return calculateResults;
};

export const useUpsertCacheResults = () => {
  const { companyId } = useCurrentCompanyId();
  const toast = useToast();
  const [upsertCacheResults] = useUpsertCompanyAssessmentResultsMutation();

  return useCallback(
    (result: CompanyAssessmentResults) => {
      const companyUpdate: CompanyAssessment_Insert_Input_ = {
        id: result.companyAssessmentId,
        endDate: result.endDate,
        startDate: result.startDate,
        aggregateId: result.aggregateId,
        companyId: companyId,
        cachedResult: {
          data: {
            companyId: companyId,
            // activityVersion: 1,
            ...omit(result.cachedResult, '__typename'),
            objectivesState: {
              ...result.cachedResult?.objectivesState,
              substantialContributionProportion: getSubstantialContributionProportion(result),
            },
            isDirty: false,
          },
          on_conflict: cacheResultsOnConflict,
        },
      };

      const bAssessmentsResults: BusinessUnitAssessment_Insert_Input_[] =
        result.businessUnitResults.map((bu) => ({
          id: bu.bAssessmentId,
          cAssessmentId: result.companyAssessmentId,
          cachedResult: {
            data: {
              companyId: companyId,
              ...omit(bu.cachedResult, '__typename'),
              isDirty: false,
            },
            on_conflict: cacheResultsOnConflict,
          },
        }));

      const activityAssesments: ActivityReport_Insert_Input_[] = result.businessUnitResults
        .map((bu) => bu.activityResults.map((act) => ({ ...act, bAssessmentId: bu.bAssessmentId })))
        .flat()
        .map((act) => ({
          id: act.activityAssessmentId,
          activityRef: act.activityRef,
          activityVersionNumber: act.activityVersionNumber,
          bAssessmentId: act.bAssessmentId,
          cachedResult: {
            data: {
              companyId: companyId,
              ...omit(act.cachedResult, '__typename'),
              isDirty: false,
            },
            on_conflict: cacheResultsOnConflict,
          },
        }));
      const generalAssessments: ActivityReport_Insert_Input_[] = [
        ...result.businessUnitResults
          .map((bu) => bu.generalAssessmentResult)
          .flat()
          .map((ga) => ({
            id: ga.activityAssessmentId,
            activityRef: '0.0',
            activityVersionNumber: ga.activityVersionNumber,
            bAssessmentId: ga.bAssessmentId,
            cachedResult: {
              data: {
                companyId: companyId,
                ...omit(ga.cachedResult, '__typename'),
                isDirty: false,
              },
              on_conflict: cacheResultsOnConflict,
            },
          })),
        {
          id: result.generalAssessmentResult.activityAssessmentId,
          activityRef: '0.0',
          activityVersionNumber: result.generalAssessmentResult.activityVersionNumber,
          bAssessmentId: result.generalAssessmentResult.bAssessmentId,
          cachedResult: {
            data: {
              companyId: companyId,
              activityVersionNumber: result.generalAssessmentResult.activityVersionNumber,
              ...omit(result.generalAssessmentResult.cachedResult, '__typename'),
              isDirty: false,
            },
            on_conflict: cacheResultsOnConflict,
          },
        },
      ];

      return upsertCacheResults({
        variables: {
          companyUpdate,
          bAssessmentsResults,
          activityAssesments: [...activityAssesments, ...generalAssessments],
        },
      }).catch((e) => {
        captureException(e, {
          extra: { errorMessage: 'Error in upsert cache results' },
        });
        toast({
          text: 'Failed to update score',
          variant: 'danger',
        });
      });
    },
    [upsertCacheResults, companyId]
  );
};
export const useCompanyAssessmentResults = (
  cAssessmentId: string,
  skip?: boolean,
  isGroup?: boolean
) => {
  const upsertCache = useUpsertCacheResults();
  const [isCalculating, setIsCalculating] = useState(false);
  const [results, setResults] = useState<CompanyAssessmentResults>();
  const toast = useToast();
  const {
    data: companyAssessmentResults,
    loading,
    ...rest
  } = useCompanyAssessmentCachedResultsSubscription({
    variables: { cAssessmentId },
    skip: !cAssessmentId || skip || isGroup,
  });
  const { data: consolidatedCompanyAssessmentData, loading: groupLoading } =
    useGetGroupConsolidatedAssessmentResultQuery({
      variables: { cAssessmentId },
      skip: !cAssessmentId || skip || !isGroup,
    });
  if (isGroup) {
    const consolidatedCompanyAssessment: CompanyAssessmentResults = {
      companyAssessmentId: cAssessmentId,
      aggregateId: consolidatedCompanyAssessmentData?.companyAssessment?.aggregate?.id,
      startDate: consolidatedCompanyAssessmentData?.companyAssessment?.startDate,
      endDate: consolidatedCompanyAssessmentData?.companyAssessment?.endDate,
      cachedResult: consolidatedCompanyAssessmentData?.companyAssessment?.cachedResult,
      generalAssessmentResult: {
        activityAssessmentId:
          consolidatedCompanyAssessmentData?.companyAssessment?.generalAssessment[0]
            ?.generalAssessment[0]?.id,
        activityVersionNumber:
          consolidatedCompanyAssessmentData?.companyAssessment?.generalAssessment[0]
            ?.generalAssessment[0]?.cachedResult?.activityVersionNumber ?? 1,
        bAssessmentId:
          consolidatedCompanyAssessmentData?.companyAssessment?.generalAssessment[0]?.id,
        cachedResult:
          consolidatedCompanyAssessmentData?.companyAssessment?.generalAssessment[0]
            ?.generalAssessment[0]?.cachedResult,
      },
      businessUnitResults:
        consolidatedCompanyAssessmentData?.companyAssessment?.bAssessments?.map((bAssessment) => ({
          bAssessmentId: bAssessment.id,
          businessUnit: bAssessment.businessUnit ?? {
            id: '',
            name: '',
          },
          cachedResult: bAssessment.cachedResult,
          hasGeneralAssessment: true,
          generalAssessmentResult: {
            activityAssessmentId: bAssessment.generalAssessment[0]?.id,
            activityVersionNumber: bAssessment.generalAssessment[0]?.activityVersionNumber,
            bAssessmentId: bAssessment.id,
            cachedResult: bAssessment.generalAssessment[0]?.cachedResult,
          },
          activityResults: bAssessment.activityReports.map((activityReport) => ({
            activityAssessmentId: activityReport.id,
            activityRef: activityReport.activity.reference,
            activityVersionNumber: activityReport.activityVersionNumber,
            cachedResult: activityReport.cachedResult,
            isMSSGAligned: bAssessment.generalAssessment[0]?.cachedResult?.isAligned,
          })),
        })) ?? [],
    };
    return {
      data: consolidatedCompanyAssessment,
      loading: groupLoading,
    };
  }
  const calculateCompanyAssessmentResults = useCalculateCompanyAssessmentResults(
    companyAssessmentResults?.companyResults ?? undefined
  );

  const handleReCalculate = useCallback(async () => {
    setIsCalculating(true);
    let res;
    try {
      res = await calculateCompanyAssessmentResults();
      if (res) {
        await upsertCache(res);
        setResults(res);
        setIsCalculating(false);
      }
    } catch (e) {
      console.error(e);
      captureException(e, {
        extra: { errorMessage: 'Error in upsert cache results' },
      });
      toast({
        text: 'Failed to update score',
        variant: 'danger',
      });
    }
  }, [calculateCompanyAssessmentResults, upsertCache]);

  useEffect(() => {
    if (companyAssessmentResults?.companyResults) {
      if (
        !companyAssessmentResults.companyResults.cachedResult ||
        companyAssessmentResults.companyResults.cachedResult?.isDirty === true // Recaculate if dirty
      ) {
        handleReCalculate();
      }
      const transformedResults = transformCompanyAssessmentCachedResults(
        companyAssessmentResults.companyResults
      );
      setResults(transformedResults);
    }
  }, [companyAssessmentResults, calculateCompanyAssessmentResults, cAssessmentId]);

  return {
    data: results,
    loading: loading || isCalculating,
    ...rest,
  };
};

export type AlignementStatusResults = {
  [key: string]: AlignmentStatusItem[];
};

const getAlignmentStatusForMSS = (
  isAlignedResults: boolean[],
  isCompletedResults: boolean[],
  isStartedResults: boolean[]
) => {
  const allCompleted = isCompletedResults.every((a) => a);
  const allAligned = isAlignedResults.every((a) => a);
  const nonStarted = isStartedResults.every((a) => !a);

  if (!isAlignedResults.length || !isCompletedResults.length || nonStarted) {
    return {
      type: AlignmentType.mss,
      status: EntityAlignmentStatus.todo,
      isInherited: false,
    };
  }

  if (allAligned)
    return {
      type: AlignmentType.mss,
      status: allCompleted ? EntityAlignmentStatus.compliant : EntityAlignmentStatus.soFarCompliant,
      isInherited: false,
    };
  return {
    type: AlignmentType.mss,
    status: EntityAlignmentStatus.notCompliant,
    isInherited: false,
  };
};

export const getAlignmentStatusForActivities = (
  activities: ActivityResults[]
): AlignmentStatusItem => {
  let status = EntityAlignmentStatus.soFarCompliant;
  const noneStarted = activities?.every((a) => a.cachedResult?.progress?.screening === 0);
  if (noneStarted) {
    status = EntityAlignmentStatus.todo;
  }

  const allCompliant = activities?.every(
    (a) =>
      a.cachedResult?.status === EntityAlignmentStatus.compliant ||
      a.cachedResult?.status === EntityAlignmentStatus.nonEnablingAdaptationOnlyCompliant ||
      a.cachedResult?.status === EntityAlignmentStatus.adaptationOnlyCompliant ||
      a.cachedResult?.status === EntityAlignmentStatus.partiallyCompliant
  );
  if (allCompliant) {
    status = EntityAlignmentStatus.compliant;
  }

  const anyNonCompliant = activities?.some(
    (a) =>
      a.cachedResult?.status === EntityAlignmentStatus.notCompliant ||
      a.cachedResult?.status === EntityAlignmentStatus.noSubstantialContribution ||
      a.cachedResult?.status === EntityAlignmentStatus.soFarNoSubstantialContribution
  );
  if (anyNonCompliant) {
    status = EntityAlignmentStatus.notCompliant;
  }

  return {
    type: AlignmentType.activities,
    status,
    isInherited: false,
  };
};

export const useAlignmentStatuses = (cAssessmentId: string, skip?: boolean) => {
  const { data, loading } = useCompanyAssessmentResults(cAssessmentId, skip);
  const alignmentStatuses = useMemo(() => {
    const companyGeneral = data?.generalAssessmentResult.cachedResult;
    const companyMSS = getAlignmentStatusForMSS(
      isValidBool(companyGeneral?.isAligned) ? [companyGeneral?.isAligned ?? false] : [],
      isValidBool(companyGeneral?.isCompleted) ? [companyGeneral?.isCompleted ?? false] : [],
      companyGeneral?.progress.screening > 0 ? [true] : [false]
    );
    const results: AlignementStatusResults = {
      company: [companyMSS],
    };
    data?.businessUnitResults?.forEach((bu) => {
      const buActivities = bu.activityResults;
      const buMSS = bu?.hasGeneralAssessment
        ? getAlignmentStatusForMSS(
            isValidBool(bu?.generalAssessmentResult?.cachedResult?.isAligned)
              ? [bu?.generalAssessmentResult?.cachedResult?.isAligned as boolean]
              : [],
            isValidBool(bu?.generalAssessmentResult?.cachedResult?.isCompleted)
              ? [bu?.generalAssessmentResult?.cachedResult?.isCompleted as boolean]
              : [],
            bu?.generalAssessmentResult?.cachedResult?.progress?.screening > 0 ? [true] : [false]
          )
        : { ...companyMSS, isInherited: true };
      const buActivitiesResults = getAlignmentStatusForActivities(buActivities);
      results[bu?.businessUnit?.id] = [buMSS, buActivitiesResults];
    });
    return results;
  }, [data]);

  return {
    alignmentResults: alignmentStatuses,
    loading,
  };
};

export type ActivityShortResult = {
  name: string;
  reference: string;
  score?: BusinessUnitCachedResult['score'];
  financials?: BusinessUnitCachedResult['financials'];
};
