import { Box, VStack, Button, useDisclosure } from '@chakra-ui/react';
import { Accordion, Infobox } from 'Atoms';
import { Typography } from 'Tokens';
import { TargetIcon, TargetOffIcon } from 'Tokens/Icons/Data';
import React, { useMemo, useState } from 'react';
import {
  AggregatedMetrics,
  AggregatedQualitativeAnswers,
  MetricsTableData,
  useGetAggregatedMetricsData,
} from '../Metrics';
import { IconHelpCircle } from '@tabler/icons-react';
import { LearnMoreDrawer } from 'Molecules';

const Header = () => (
  <VStack spacing="16px" alignItems="start" w="100%">
    <Box w="100%" borderBottom="1px solid" borderColor="border.decorative" pb="8px">
      <Typography variant="h3">Disclosure Requirements</Typography>
    </Box>
    <Box w="100%">
      <Infobox
        status="neutral"
        withIcon={false}
        description="ESRS requires to disclose on different data points based when you do or don't have actions for each topic. Please answer the relevant data points below."
        closable={false}
      />
    </Box>
  </VStack>
);

export const ActionDisclosureRequirement = ({
  setMetricSidebarOpen,
}: {
  setMetricSidebarOpen: (val: boolean) => void;
}) => {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const [rowData, setRowData] = useState<
    MetricsTableData & { sourceData?: AggregatedQualitativeAnswers[number] }
  >();

  const { metrics: plannedActionMetrics } = useGetAggregatedMetricsData(false);
  const { metrics: notPlannedActionMetrics } = useGetAggregatedMetricsData(true);

  const accordionItems = [
    {
      id: 'planned',
      label: 'Disclosure requirements when actions are planned',
      icon: <TargetIcon mt="4px" />,
      content: (
        <AggregatedMetrics
          setMetricSidebarOpen={setMetricSidebarOpen}
          rowData={rowData}
          setRowData={setRowData}
        />
      ),
      extra: (
        <Button
          variant="ghost"
          leftIcon={<IconHelpCircle size="16px" />}
          fontWeight={500}
          onClick={(e) => {
            e.preventDefault();
            onOpen();
          }}
        >
          Learn more
        </Button>
      ),
    },
    {
      id: 'notPlanned',
      label: (
        <Typography variant="h3" as={'p'}>
          Disclosure requirements when actions are
          <Typography variant="h3" as={'span'} color="text.critical">
            {' '}
            not planned
          </Typography>
        </Typography>
      ),
      icon: <TargetOffIcon color="border.critical.accent" mt="3px" />,
      content: (
        <AggregatedMetrics
          withAssociation={true}
          setMetricSidebarOpen={setMetricSidebarOpen}
          rowData={rowData}
          setRowData={setRowData}
        />
      ),
      extra: (
        <Button
          variant="ghost"
          leftIcon={<IconHelpCircle size="16px" />}
          fontWeight={500}
          onClick={(e) => {
            e.preventDefault();
            onOpen();
          }}
        >
          Learn more
        </Button>
      ),
    },
  ];

  const filteredAccordionItems = useMemo(() => {
    return accordionItems.filter((item) => {
      if (item.id === 'planned') {
        return (plannedActionMetrics ?? []).length > 0;
      } else if (item.id === 'notPlanned') {
        return (notPlannedActionMetrics ?? []).length > 0;
      }
    });
  }, [accordionItems, plannedActionMetrics, notPlannedActionMetrics]);

  return (
    <VStack alignItems="start" w="100%" spacing="24px">
      <Header />
      <Accordion variant="solid" items={filteredAccordionItems} />
      <LearnMoreDrawer
        isOpen={isOpen}
        onClose={onClose}
        header="Action plan Disclosure Requirements"
        description='<div><p>ESRS requires disclosure on different data points based on whether you have an action plan for this topic or not. Please answer the relevant data points in one of the groups on this page.</p>
        <p><br></p>
        <p><span style="font-weight:600;" data-token-index="1" class="notion-enable-hover">Disclosure requirements when actions are planned</span></p>
        <p>Provide answers in this group if actions are planned for one or more of the material sustainability matters.</p>
        <p><br></p>
        <p><span style="font-weight:600;" data-token-index="3" class="notion-enable-hover">Disclosure requirements when actions are not planned</span></p>
        <p>Provide answers in this group if actions are not planned for one or more of the material sustainability matters.</p></div>'
      />
    </VStack>
  );
};
