import { Box, UseDisclosureReturn } from '@chakra-ui/react';
import { useCurrentQuestion } from 'context';
import mixpanel from 'mixpanel-browser';
import { AlertDialog } from 'Molecules';
import { Suspense, useContext } from 'react';
import {
  useCurrentCompanyId,
  createObjEditorContext,
  EditorContextProviderProps,
  EditorContextType,
  useToast,
  useObjectEditor,
} from 'utils/hooks';
import { TRACKING_EVENTS } from 'utils/mixpanel';
import { useTranslation } from 'utils/translation';
import { useDeleteFile, useUpsertFile, useCompanyFiles, UpsertFileProps } from '../Drive.hooks';
import { DocumentationFileDetailsFragment_, DocumentationFile } from 'models';
import { nhost } from 'utils/nhost';
import { FileEditModal } from './FileEditModal';
import { saveAs } from 'file-saver';
import { Menu, MenuAction } from 'Molecules/Menu';
import { DeleteIcon, DownloadIcon, EditIcon, RemoveIcon } from 'Tokens/Icons/Function';

export const FileEditorContext = createObjEditorContext<DocumentationFile>();
const hardCodedError = 'Fragment must have exactly one definition.';

export type FileEditorContextType = EditorContextType<DocumentationFile>;

export function FileActions({ file }: { file: DocumentationFile }) {
  const Editor = useContext(FileEditorContext);
  const { answer, onAnswerChange, t } = useCurrentQuestion();
  const attachments = answer?.attachmentBox?.attachments ?? [];
  const actions = Editor.actions(file);
  if (attachments.find((f) => f.file.id === file.id))
    actions.unshift({
      id: 'unassign',
      title: t('question:fileUnassign'),
      leftElement: <RemoveIcon transform="scale(66.666%)" color="inherit" />,
      onClick: () => {
        onAnswerChange({
          attachments: attachments.filter((f) => f.file.id !== file.id).map(({ file: f }) => f),
        });
      },
    });
  return (
    <Menu
      sections={[
        {
          actions: actions,
        },
      ]}
    />
  );
}

export function FileEditor({
  file: origFile,
  isOpen,
  onClose,
}: Pick<UseDisclosureReturn, 'isOpen' | 'onClose'> & {
  file?: DocumentationFile;
}) {
  const toast = useToast();
  const upsertFile = useUpsertFile();
  const { files } = useCompanyFiles();
  const { t } = useTranslation('files');
  const isEditing = !!origFile;

  const handleSave = (data: UpsertFileProps) =>
    upsertFile({ ...data, id: origFile?.id })
      .then((newFile) => {
        if (newFile) {
          const context = { file: newFile };
          toast({
            text: isEditing ? t('files:toast.updated', context) : t('files:toast.created', context),
          });
          onClose();
        }
        return newFile ?? undefined;
      })
      .catch((e) => {
        const context = { file: data };
        if ({ ...e }.message !== hardCodedError) {
          toast({
            text: isEditing ? t('files:updateFailed', context) : t('files:uploadFailed', context),
            variant: 'danger',
          });
        } else {
          toast({
            text: isEditing ? t('files:toast.updated', context) : t('files:toast.created', context),
          });
          onClose();
        }
      });
  return (
    <Suspense>
      <FileEditModal
        isOpen={isOpen}
        onClose={onClose}
        saveFile={handleSave}
        file={origFile}
        allTags={files?.flatMap((f) => f.tags) ?? []}
      />
    </Suspense>
  );
}

export function FileEditorContextProvider({
  children,
  contextRef,
}: React.PropsWithChildren<EditorContextProviderProps<DocumentationFile>>) {
  const deleteFile = useDeleteFile();
  const { t } = useTranslation(['files', 'common']);
  const { companyId } = useCurrentCompanyId();

  const downloadAttachedFile = async (file: DocumentationFile) => {
    return nhost.storage
      .getPresignedUrl({ fileId: file?.storageFile?.id })
      .then(({ presignedUrl }) => {
        mixpanel.track(TRACKING_EVENTS.DRIVE.DOWNLOAD, {
          file: file.title,
          companyId,
        });
        if (presignedUrl?.url)
          saveAs(presignedUrl.url, file.storageFile?.name ?? file.title.replaceAll('.', '_'));
      });
  };

  const { editorProps, currentObject, deleteDialogProps, context } =
    useObjectEditor<DocumentationFile>({
      contextRef,
      deleteObject: async (
        obj: DocumentationFileDetailsFragment_
      ): Promise<DocumentationFileDetailsFragment_ | null | undefined> =>
        deleteFile({ id: obj.id, storageFileId: obj?.storageFile?.id ?? '' }),
      actions: [
        (file) =>
          ({
            id: 'download',
            title: t('common:actions.download'),
            leftElement: <DownloadIcon color="inherit" />,

            onClick: () => downloadAttachedFile(file),
          }) as MenuAction,
        {
          id: 'edit',
          title: t('common:actions.edit'),
          onClick: 'edit',
          leftElement: <EditIcon color="inherit" />,
        },
        {
          id: 'delete',
          variant: 'destructive',
          title: t('common:actions.delete'),
          onClick: 'delete',
          leftElement: <DeleteIcon color="inherit" />,
        },
      ],
    });
  return (
    <FileEditorContext.Provider value={context}>
      {children}
      <Suspense>
        <FileEditor {...editorProps} file={currentObject} />
      </Suspense>
      <AlertDialog
        title={<Box> {t('files:deleteConfirmation.title', { file: currentObject })} </Box>}
        {...deleteDialogProps}
      >
        {t('files:deleteFileConfirmation')}
      </AlertDialog>
    </FileEditorContext.Provider>
  );
}
