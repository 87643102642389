import React from 'react';
import { AvatarBadge } from '@chakra-ui/react';
import { CheckIcon, WarningIcon } from 'Tokens/Icons/Status';

export const AvatarCustomBadge = ({ status }: { status?: string }) => {
  const isSuccess = status === 'success';
  const isWarning = status === 'warning';

  return (
    <AvatarBadge
      boxSize="14px"
      bg={isSuccess ? 'text.success' : isWarning ? 'text.warning' : 'bg.default'}
      borderColor="red"
      border="1px"
    >
      {isSuccess && <CheckIcon color="white" boxSize="9px" />}
      {isWarning && <WarningIcon color="white" boxSize="9px" />}
    </AvatarBadge>
  );
};
