import { Select } from 'Molecules';
import { FilterChange } from '../MetricChart';
import { MetricChartMenuBox } from './MetricChartMenuBox';

type OptionsType =
  | {
      id: any;
      name: string;
    }[]
  | undefined;

export const MetricChartFilterSelector = ({
  isGroup,
  subsidiaryOptions,
  handleFilterChange,
  businessUnitOptions,
}: {
  isGroup: boolean;
  subsidiaryOptions: OptionsType;
  businessUnitOptions: OptionsType;
  handleFilterChange: FilterChange;
}) => {
  return (
    <MetricChartMenuBox title="Filters">
      {isGroup ? (
        <Select
          isMulti={true}
          isClearable={true}
          options={subsidiaryOptions?.map((subOption) => ({
            label: subOption.name,
            value: subOption.id,
          }))}
          onChange={(option) =>
            handleFilterChange(
              'subFilter',
              option.map((opt) => opt.value)
            )
          }
        />
      ) : (
        <Select
          isMulti={true}
          isClearable={true}
          options={businessUnitOptions?.map((buOption) => ({
            label: buOption.name,
            value: buOption.id,
          }))}
          onChange={(option) =>
            handleFilterChange(
              'ruFilter',
              option.map((opt) => opt.value)
            )
          }
        />
      )}
    </MetricChartMenuBox>
  );
};
