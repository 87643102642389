import ExcelJS, { Style, TableColumnProperties } from 'exceljs';
import { incrementString, TransformedCompany } from './consolidated-financials-excel-generator';
import { getPositiveOrZero } from 'utils/financials';

const setExplanatoryText = (
  sheet: ExcelJS.Worksheet,
  explanatoryText: string,
  cellRange: string,
  backgroundColor: string
) => {
  const explanatoryTextCell = sheet.getCell(cellRange);
  explanatoryTextCell.value = explanatoryText;

  const explanatoryTextStyle: Partial<Style> = {
    font: {
      size: 14,
    },
    alignment: {
      vertical: 'middle',
      horizontal: 'center',
      wrapText: true,
    },
    border: {
      top: { style: 'thin' },
      left: { style: 'thin' },
      bottom: { style: 'thin' },
      right: { style: 'thin' },
    },
    fill: {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: backgroundColor },
    },
  };

  sheet.mergeCells(cellRange);
  explanatoryTextCell.style = explanatoryTextStyle;
};
export const SUBSIDIARIES_SHEET_NAME = 'Subsidiaries (to consolidate)';

export const createSubsidiariesSheet = async ({
  workbook,
  companyList,
}: {
  workbook: ExcelJS.Workbook;
  companyList: TransformedCompany[];
}): Promise<ExcelJS.Workbook> => {
  const sheet = workbook.addWorksheet(SUBSIDIARIES_SHEET_NAME);

  const whatToConsolidateText = `The parent entities should consolidate: 
  - all their subsidiaries, including third country subsidiaries, as per Chapter 6 of the Accounting Directive;
  - all their activities (i.e. turnover, CapEx, OpEx) and assets and those of their EU and non-EU subsidiaries/entities, including those performed in third countries
  - Remember to convert all numbers to the parent company’s currency since no currency conversions happen in the app.`;

  const howToConsolidateText = `How to consolidate editing this excel?
  Edit data in columns G to K - Do not edit the line "Total".
  For a reference point, you can indicate the Turnover, CapEx and OpEx of your annual report in the tables' bottom line.
  When consolidation is finished, export the sheet as a CSV UTF-8 and upload it on Celsia.`;

  setExplanatoryText(sheet, whatToConsolidateText, 'B2:F9', 'C7EAFF');
  setExplanatoryText(sheet, howToConsolidateText, 'G2:K9', '98FB98');

  const explanatoryTextEnd = 10;
  let tableStart = 11;

  companyList.forEach((company) => {
    const rows: any[][] = [];

    company.reportingUnits.forEach((reportingUnit) => {
      reportingUnit.activities.forEach((activity) => {
        rows.push([
          null,
          company.currency,
          reportingUnit.name,
          reportingUnit.id,
          activity.activity,
          activity.turnover,
          activity.capex,
          activity.opex,
          activity.adaptationCapex,
          activity.adaptationOpex,
        ]);
      });

      rows.push([
        null,
        company.currency,
        reportingUnit.name,
        reportingUnit.id,
        reportingUnit.nonEligible.activity,
        getPositiveOrZero(
          reportingUnit.nonEligible.total -
            reportingUnit.activities.reduce((acc, activity) => acc + activity.turnover, 0)
        ),
        getPositiveOrZero(
          reportingUnit.nonEligible.totalCapex -
            reportingUnit.activities.reduce((acc, activity) => acc + activity.capex, 0)
        ),
        getPositiveOrZero(
          reportingUnit.nonEligible.totalOpex -
            reportingUnit.activities.reduce((acc, activity) => acc + activity.opex, 0)
        ),
        0,
        0,
      ]);
    });

    rows.push([
      null,
      company.currency,
      'Company non-eligible activities',
      null,
      company.nonEligible.activity,
      company.nonEligible.turnover,
      company.nonEligible.capex,
      company.nonEligible.opex,
      0,
      0,
    ]);

    const subsidiaryColumns: TableColumnProperties[] = [
      { name: company.name, totalsRowLabel: 'Total' },
      { name: 'Currency' },
      { name: 'Reporting unit' },
      { name: 'Reporting unit Id' },
      { name: 'Activity' },
      { name: 'Turnover', totalsRowFunction: 'sum' },
      { name: 'CapEx', totalsRowFunction: 'sum' },
      { name: 'OpEx', totalsRowFunction: 'sum' },
      { name: 'Adaptation CapEx', totalsRowFunction: 'sum' },
      { name: 'Adaptation OpEx', totalsRowFunction: 'sum' },
      { name: 'Notes' },
    ];

    sheet.addTable({
      ref: `B${tableStart}`,
      name: `TABLE${tableStart}`,
      headerRow: true,
      totalsRow: true,
      style: {
        theme: 'TableStyleLight2',
        showRowStripes: true,
      },
      columns: subsidiaryColumns,
      rows,
    });

    sheet.addRow([
      '',
      `Financial statement total for ${company.name}`,
      null,
      null,
      null,
      null,
      company.totalFinancials.turnover,
      company.totalFinancials.capex,
      company.totalFinancials.opex,
      null,
      null,
      company.totalFinancials.notes,
    ]);

    let columnIndex = 'A';
    for (let i = 0; i < subsidiaryColumns.length; i++) {
      columnIndex = incrementString(columnIndex);
      if (i < 5 && i != 1) {
        sheet.getColumn(columnIndex).width = 30;
      } else {
        sheet.getColumn(columnIndex).width = 15;
      }
    }

    const lightGreenStyle: ExcelJS.Fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: '98FB98' },
    };

    sheet.getRows(tableStart, rows.length + 1)?.forEach((row) => {
      row.eachCell((cell, colNumber) => {
        if (colNumber > 6 && colNumber < 12) {
          cell.fill = lightGreenStyle;
          cell.border = {
            top: { style: 'thin' },
            bottom: { style: 'thin' },
          };
        }
      });
    });

    tableStart += rows.length + 5;
  });

  sheet.getRows(explanatoryTextEnd, sheet.lastRow?.number ?? 1)?.forEach((row) => {
    row.eachCell((cell) => {
      cell.alignment = {
        wrapText: true,
      };
    });
  });

  return workbook;
};
