import { Circle, SimpleGrid, HStack, VStack, useColorMode } from '@chakra-ui/react';
import { Typography, colors } from 'Tokens';
import { NoDataMessage, ZeroFinancialsMessage } from 'Organisms';
import { useMemo } from 'react';
import { PieChart } from 'react-minimal-pie-chart';
import { formatDigits } from 'utils/numbers';
import { TFunction, useTranslation } from 'utils/translation';
import { HelpIcon } from 'Tokens/Icons/Status';
import { Button } from 'Atoms';

const CHART_SIZES = {
  lg: '104px',
  md: '128px',
  sm: '56px',
  xs: '24px',
};
export enum DataPoint {
  aligned = 'aligned',
  notAligned = 'notAligned',
  notEligible = 'notEligible',
  inProgress = 'inProgress',
  notEligibleAligned = 'notEligibleAligned',
}

export type ChartData = {
  [DataPoint.aligned]?: number;
  [DataPoint.notAligned]?: number;
  [DataPoint.notEligible]?: number;
  [DataPoint.notEligibleAligned]?: number;
};
type ScoreChartSize = 'xs' | 'sm' | 'md' | 'lg';

type ScoreChartProps = {
  data: ChartData;
  size?: ScoreChartSize;
};

const DATA_POINT_COLORS: { [key: string]: { [key: string]: string } } = {
  [DataPoint.aligned]: {
    default: colors['bg.compliant.accent'].default,
    _dark: colors['bg.compliant.accent']._dark,
  },
  [DataPoint.notAligned]: {
    default: colors['bg.notCompliant.accent'].default,
    _dark: colors['bg.notCompliant.accent']._dark,
  },
  [DataPoint.notEligible]: {
    default: colors['bg.notEligible.accent'].default,
    _dark: colors['bg.notEligible.accent']._dark,
  },
  [DataPoint.notEligibleAligned]: {
    default: colors['bg.notEligibleAligned.accent'].default,
    _dark: colors['bg.notEligibleAligned.accent']._dark,
  },
  [DataPoint.inProgress]: {
    default: colors['bg.unknown'].default,
    _dark: colors['bg.unknown']._dark,
  },
};

const DATA_POINTS_ORDER: { [key: string]: number } = {
  [DataPoint.notEligible]: 4,
  [DataPoint.notEligibleAligned]: 3,
  [DataPoint.aligned]: 2,
  [DataPoint.inProgress]: 5,
  [DataPoint.notAligned]: 1,
};

const buildSeries = (
  data: ScoreChartProps['data'],
  t: TFunction,
  color: string
): Array<{ title: string; value: number; color: string }> => {
  return [
    ...Object.entries(data ?? {}).map(([key, value]) => ({
      title: t('assessment:score.' + key),
      value: value,
      color: DATA_POINT_COLORS[key][color],
    })),
    {
      title: t('assessment:score.inProgress'),
      value: 100 - Object.values(data).reduce((acc, value) => acc + value, 0),
      color: DATA_POINT_COLORS.inProgress[color],
    },
  ].sort((a, b) => DATA_POINTS_ORDER[a.title] - DATA_POINTS_ORDER[b.title]);
};

export const ScoreChart = ({ data, size = 'lg' }: ScoreChartProps) => {
  const { colorMode } = useColorMode();
  const isDarkMode = useMemo(() => colorMode === 'dark', [colorMode]);
  const color = useMemo(() => (isDarkMode ? '_dark' : 'default'), [isDarkMode]);
  const { t } = useTranslation('assessment');
  const series = useMemo(() => buildSeries(data, t, color), [data, t]);

  return (
    <Circle size={CHART_SIZES[size]} alignItems="center">
      <PieChart data={series} lineWidth={28} startAngle={270} />
    </Circle>
  );
};

export const ScoreChartWithLegend = ({
  title,
  data,
  size,
  noData,
  isZero = false,
  onDetailsClick,
  businessUnitId,
}: ScoreChartProps & {
  title: string | 'revenue' | 'capex' | 'opex';
  noData?: boolean;
  isZero?: boolean;
  onDetailsClick?: () => void;
  businessUnitId?: string;
}) => {
  const { colorMode } = useColorMode();
  const isDarkMode = useMemo(() => colorMode === 'dark', [colorMode]);
  const color = useMemo(() => (isDarkMode ? '_dark' : 'default'), [isDarkMode]);
  const { t } = useTranslation(['assessment', 'common']);
  const series = useMemo(() => buildSeries(data, t, color), [data]);
  if (noData || isZero) {
    return (
      <VStack
        width="100%"
        alignItems="center"
        justifyContent="center"
        bg="bg.muted"
        borderRadius="8px"
        padding="16px"
        height="100%"
        minHeight="174px"
      >
        {isZero ? <ZeroFinancialsMessage title={title} isLarge /> : <NoDataMessage isLarge />}
      </VStack>
    );
  }
  return (
    <VStack
      width="100%"
      alignItems="stretch"
      justifyContent="flex-start"
      bg="bg.muted"
      borderRadius="8px"
      padding="16px"
      height="100%"
      minHeight="174px"
    >
      <HStack width="100%" justifyContent="space-between">
        <Typography variant="h3">{t(`common:financials.${title}`)}</Typography>
        {onDetailsClick && (
          <Button
            variant="ghost"
            size="sm"
            leftIcon={<HelpIcon boxSize="16px" />}
            onClick={(e) => {
              e.stopPropagation();
              onDetailsClick();
            }}
          >
            {t('common:forms.details')}
          </Button>
        )}
      </HStack>
      {noData || isZero ? (
        isZero ? (
          <ZeroFinancialsMessage title={title} />
        ) : (
          <NoDataMessage />
        )
      ) : (
        <HStack width="100%" gap="16px">
          <ScoreChart data={data} size={size} />
          <SimpleGrid columns={2} spacingY="22px" width="100%">
            {series.map((item) => (
              <HStack width="100%" alignItems="flex-start" key={item.title} mr="2px">
                <VStack alignItems="flex-start">
                  <Circle size="8px" marginTop="6px" bg={item.color} />
                </VStack>
                <VStack alignItems="space-around" spacing="0px">
                  <HStack justifyContent="flex-start">
                    <Typography
                      variant="bodyLarge"
                      data-testid={businessUnitId + '-' + title + '-' + item.title}
                    >
                      {formatDigits(item.value, 0)}%
                    </Typography>
                  </HStack>
                  <HStack justifyContent="flex-start">
                    <Typography variant="detail" wordBreak="break-all">
                      {item.title}
                    </Typography>
                  </HStack>
                </VStack>
              </HStack>
            ))}
          </SimpleGrid>
        </HStack>
      )}
    </VStack>
  );
};
