import { AlertStatus, Infobox } from 'Atoms';
import {
  MaterialityState,
  MaterialityStatus,
  MetricMaterialityState,
} from '../DoubleMaterialityAssessment.d';
import { LockedIcon } from 'Tokens/Icons/Function';
import { CheckIcon, InfoIcon, WarningIcon } from 'Tokens/Icons/Status';

export const MATERIALITY_LABELS = {
  [MaterialityState.toAssess]: 'Select',
  [MaterialityState.notMaterial]: MaterialityStatus.notMaterial,
  [MaterialityState.material]: MaterialityStatus.material,
  [MaterialityState.collectData]: MaterialityStatus.collectData,
  [MaterialityState.gatherData]: MaterialityStatus.gatherData,
  [MaterialityState.doNotCollect]: MaterialityStatus.doNotCollect,
};

export const COLLECT_ONLY_OPTIONS = [
  {
    label: MaterialityStatus.collectData,
    value: MetricMaterialityState.collectData,
  },
  {
    label: MaterialityStatus.doNotCollect,
    value: MetricMaterialityState.doNotCollect,
  },
];

export const METRIC_MATERIALITY_OPTIONS = [
  {
    label: MaterialityStatus.material,
    value: MetricMaterialityState.material,
  },
  {
    label: MaterialityStatus.notMaterial,
    value: MetricMaterialityState.notMaterial,
  },
  {
    label: MaterialityStatus.gatherData,
    value: MetricMaterialityState.gatherData,
  },
];

export const DR_STATES = {
  [MaterialityState.mandatory]: {
    status: 'neutral' as AlertStatus,
    title: 'Mandatory',
    description: 'This disclosure requirement is mandatory for all companies',
    icon: <LockedIcon color="text.muted" />,
  },
  [MaterialityState.materialMandatory]: {
    status: 'info' as AlertStatus,
    title: 'Material',
    description: 'This disclosure requirement is material because topic was assessed as material',
    icon: <LockedIcon color="text.info" />,
  },
  [MaterialityState.material]: {
    status: 'info' as AlertStatus,
    title: MaterialityStatus.material,
    description: 'You have assessed this disclosure requirement as material',
    icon: <CheckIcon color="text.info" />,
  },
  [MaterialityState.notMaterial]: {
    status: 'neutral' as AlertStatus,
    title: MaterialityStatus.notMaterial,
    description: 'You have assessed this disclosure requirement as not material',
    icon: <CheckIcon color="inherit" />,
  },
  [MaterialityState.toAssess]: {
    status: 'warning' as AlertStatus,
    title: 'To assess',
    description: 'Learn more about the DR and assess if it is material for your company or not',
    icon: <WarningIcon color="text.warning" />,
  },
  [MaterialityState.gatherData]: {
    status: 'neutral' as AlertStatus,
    title: MaterialityStatus.gatherData,
    description:
      'You have assessed this disclosure requirement as not material; however, you will still be able to collect data for it.',
    icon: <CheckIcon color="inherit" />,
  },
  [MaterialityState.collectDataMandatory]: {
    status: 'info' as AlertStatus,
    title: 'Collect data',
    description:
      'This disclosure requirement is material for a parent, so it is required to collect data',
    icon: <LockedIcon color="text.info" />,
  },
  [MaterialityState.doNotCollect]: {
    status: 'info' as AlertStatus,
    title: 'Not required to collect data',
    description:
      'This DR is not material for a parent, so you don’t have to collect data. However, you can choose to collect data if you wish',
    icon: <InfoIcon mt="3px" />,
  },
  [MaterialityState.collectData]: {
    status: 'info' as AlertStatus,
    title: 'Collect data',
    description: 'You have assessed this disclosure requirement as material',
    icon: <CheckIcon color="text.info" />,
  },
};

export const DisclosureRequirementInfo = ({ status }: { status: MaterialityState }) => {
  return (
    <Infobox
      status={DR_STATES[status].status}
      titleProps={{ variant: DR_STATES[status].status === 'info' ? 'h3' : 'h2' }}
      title={DR_STATES[status].title}
      description={DR_STATES[status].description}
      closable={false}
      textVariant="h3"
      icon={DR_STATES[status].icon}
    />
  );
};
