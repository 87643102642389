import { VStack, HStack, CircularProgress, Skeleton, Box } from '@chakra-ui/react';
import { Typography } from 'Tokens';
import { CompanyBottomUpIcon, CompanyTopDownIcon } from 'Tokens/Icons/Custom';
import { useNavigate, useParams } from 'react-router-dom';
import { Tag, Tooltip } from 'Atoms';
import { MaterialStandard } from '../../GroupAssessment/GroupAssessmentOverview.hooks';
import { WarningIcon } from 'Tokens/Icons/Status';
import { useState, useEffect } from 'react';
import { nhost } from 'utils/nhost';

export const StandardProgressCard = ({
  standard,
  standardIndex,
  categoryIndex,
  standardLength,
  isFlagged,
  navigateTo,
  icon,
  iconToolTipLabel,
  loading,
  progress,
}: {
  standard: MaterialStandard;
  standardIndex: number;
  categoryIndex: number;
  standardLength: number;
  isFlagged: boolean;
  navigateTo: () => void;
  icon?: React.ReactNode;
  iconToolTipLabel: string;
  loading: boolean;
  progress: string;
}) => {
  return (
    <VStack
      shadow="interactive"
      bg="bg.elevated.interactive"
      borderRadius="10px"
      p="20px"
      gap="8px"
      alignItems="start"
      minHeight="148px"
      _hover={{
        shadow: 'interactive.hover',
        background: 'bg.elevated.interactive.hover',
      }}
      cursor="pointer"
      key={standard.standardRef}
      onClick={navigateTo}
      justifyContent="space-between"
      {...(categoryIndex === 0 && (standardLength > 1 ? standardIndex === 1 : standardIndex === 0)
        ? { className: 'walktour-standard-overview' }
        : {})}
    >
      <HStack alignItems="start" justifyContent="space-between" width="100%">
        <VStack spacing="4px" alignItems="start">
          <Typography variant="h4">{standard.standardName}</Typography>
          <Typography variant="detail" color="text.hint">
            {standard.standardRef}
          </Typography>
        </VStack>
        {isFlagged && (
          <Box>
            <Tooltip label="Please review and update targets baseline and milestones values to make sure a correct reporting.">
              <Tag variant="warning" size="xs">
                <WarningIcon color="text.warning" />
              </Tag>
            </Tooltip>
          </Box>
        )}
      </HStack>
      <HStack spacing="8px">
        {icon && (
          <Tag variant="undefined" size="xs">
            <Tooltip label={iconToolTipLabel}>{icon}</Tooltip>
          </Tag>
        )}
        <Skeleton isLoaded={!loading}>
          <HStack spacing="6px">
            <CircularProgress size="16px" color="text.info" value={Number(progress ?? '0')} />
            <Typography variant="body">{`${progress}%`}</Typography>
          </HStack>
        </Skeleton>
      </HStack>
    </VStack>
  );
};

export const StandardProgress = ({
  standard,
  standardIndex,
  categoryIndex,
  standardLength,
  isFlagged,
  drRefs,
  parentAssessmentId,
}: {
  standard: MaterialStandard;
  standardIndex: number;
  categoryIndex: number;
  standardLength: number;
  isFlagged: boolean;
  drRefs: string[];
  parentAssessmentId: string | undefined;
}) => {
  const { esrsAssessmentId } = useParams();
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState('0');

  const getProgress = async () => {
    setLoading(true);
    const res = await nhost.functions.call('esrs/progress/standard-progress', {
      drRefs,
      assessmentId: esrsAssessmentId,
      standardRef: standard.standardRef,
      parentAssessmentId,
      type: 'company',
    });

    if (!res.error) {
      setProgress((res.res?.data as { progress: string })?.progress);
    }
    setLoading(false);
  };

  useEffect(() => {
    getProgress();
  }, [standard, esrsAssessmentId, parentAssessmentId]);

  const navigate = useNavigate();
  const navigateTo = () => {
    navigate(`standard/${standard.standardRef}`);
  };
  return (
    <StandardProgressCard
      standard={standard}
      standardIndex={standardIndex}
      categoryIndex={categoryIndex}
      standardLength={standardLength}
      isFlagged={isFlagged}
      navigateTo={navigateTo}
      icon={
        standard.isMaterialForGroup && !standard.isMaterialForSubsidiary ? (
          <CompanyTopDownIcon boxSize="16px" />
        ) : null
      }
      iconToolTipLabel={'Assessed as material by a parent company'}
      loading={loading}
      progress={progress}
    />
  );
};

export const ReportingUnitStandardProgress = ({
  standard,
  standardIndex,
  categoryIndex,
  reportingUnitId,
  drRefs,
  parentAssessmentId,
}: {
  standard: MaterialStandard;
  standardIndex: number;
  categoryIndex: number;
  reportingUnitId: string;
  drRefs: string[];
  parentAssessmentId: string | undefined;
}) => {
  const navigate = useNavigate();
  const { esrsAssessmentId } = useParams();
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState('0');

  const getProgress = async () => {
    setLoading(true);
    const res = await nhost.functions.call('esrs/progress/standard-progress', {
      drRefs,
      assessmentId: esrsAssessmentId,
      standardRef: standard.standardRef,
      parentAssessmentId,
      reportingUnitId,
      type: 'reportingUnit',
    });

    if (!res.error) {
      setProgress((res.res?.data as { progress: string })?.progress);
    }
    setLoading(false);
  };

  useEffect(() => {
    getProgress();
  }, [drRefs, esrsAssessmentId, standard, parentAssessmentId, reportingUnitId]);

  const navigateTo = () => {
    navigate(`bu-standard/${standard.standardRef}/bu/${reportingUnitId}`);
  };
  return (
    <StandardProgressCard
      standard={standard}
      standardIndex={standardIndex}
      categoryIndex={categoryIndex}
      standardLength={0}
      isFlagged={false}
      navigateTo={navigateTo}
      icon={
        standard.isMaterialForGroup ? (
          <CompanyTopDownIcon boxSize="16px" />
        ) : (
          <CompanyBottomUpIcon boxSize="16px" />
        )
      }
      iconToolTipLabel={
        standard.isMaterialForGroup
          ? 'Assessed as material by a parent company'
          : 'Assessed as material by this company'
      }
      loading={loading}
      progress={progress}
    />
  );
};

export const GroupStandardProgress = ({
  standard,
  standardIndex,
  categoryIndex,
  drRefs,
}: {
  standard: MaterialStandard;
  standardIndex: number;
  categoryIndex: number;
  drRefs: string[];
}) => {
  const navigate = useNavigate();
  const { esrsAssessmentId } = useParams();
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState('0');

  const getProgress = async () => {
    setLoading(true);
    const res = await nhost.functions.call('esrs/progress/standard-progress', {
      drRefs,
      assessmentId: esrsAssessmentId,
      standardRef: standard.standardRef,
      type: 'group',
    });

    if (!res.error) {
      setProgress((res.res?.data as { progress: string })?.progress);
    }
    setLoading(false);
  };

  useEffect(() => {
    getProgress();
  }, [drRefs, esrsAssessmentId, standard]);
  const navigateTo = () => {
    navigate(`standard/${standard.standardRef}`);
  };
  return (
    <StandardProgressCard
      standard={standard}
      standardIndex={standardIndex}
      categoryIndex={categoryIndex}
      standardLength={0}
      isFlagged={false}
      navigateTo={navigateTo}
      icon={
        !standard.isMaterialForGroup && standard.isMaterialForSubsidiary ? (
          <CompanyBottomUpIcon boxSize="16px" />
        ) : null
      }
      iconToolTipLabel={'Not material for the group, but material for one or several subsidiaries'}
      loading={loading}
      progress={progress}
    />
  );
};
