import { AxiosError } from 'axios';
import saveAs from 'file-saver';
import { useCurrentCompany, useToast } from 'utils/hooks';
import { nhost } from 'utils/nhost';

const base64ToBlob = (b64Data: string, contentType = '', sliceSize = 512) => {
  const byteCharacters = atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, { type: contentType });
  return blob;
};

export const usePortfolioBulkActions = () => {
  const { company } = useCurrentCompany();
  const toast = useToast();

  const downloadTemplate = async (
    reportingPeriod = 2024,
    activityRefs: string[] = ['transport-hazardous'],
    selectedCompanies: string[]
  ) => {
    if (!company) throw new Error('No company');

    try {
      const { res, error } = await nhost.functions.call('bulk/portfolios/download-template', {
        ownerCompanyId: company?.id,
        reportingPeriod,
        activityRefs,
        selectedCompanies,
        currency: company?.currency,
      });

      if (error) {
        throw new Error(`Error downloading template: ${error.error}`);
      }

      const blob = new Blob([base64ToBlob(res.data as string)], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });

      saveAs(blob, `${company?.name}-portfolio-template.xlsx`);
    } catch (e) {
      const error = e as AxiosError;
      const response = error.response;

      if (response?.data instanceof Blob) {
        const text = await response.data.text();
        const json = JSON.parse(text);
        toast({
          text: json.error || 'Something went wrong, please try again',
          variant: 'danger',
        });
      }
    }
  };

  const uploadTemplate = async (file: File) => {
    const { fileMetadata } = await nhost.storage.upload({ file });

    return nhost.functions.call('bulk/portfolios/upload-template', {
      fileId: fileMetadata?.id,
    });
  };

  return {
    downloadTemplate,
    uploadTemplate,
  };
};

export const usePaiBulkActions = () => {
  const { company } = useCurrentCompany();
  const toast = useToast();

  const downloadTemplate = async (reportingPeriod = 2024) => {
    if (!company) throw new Error('No company');

    try {
      const { res, error } = await nhost.functions.call(
        'bulk/portfolios/pai/download-pai-template',
        {
          reportingPeriod,
          currency: company?.currency,
        }
      );

      if (error) {
        throw new Error(`Error downloading template: ${error.error}`);
      }

      const blob = new Blob([base64ToBlob(res.data as string)], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });

      saveAs(blob, `${company?.name}-pai-template.xlsx`);
    } catch (e) {
      const error = e as AxiosError;
      const response = error.response;

      if (response?.data instanceof Blob) {
        const text = await response.data.text();
        const json = JSON.parse(text);
        toast({
          text: json.error || 'Something went wrong, please try again',
          variant: 'danger',
        });
      }
    }
  };

  const uploadTemplate = async (file: File) => {
    const { fileMetadata } = await nhost.storage.upload({ file });
    const fileBuffer = await file.arrayBuffer().then((buffer) => new Uint8Array(buffer));

    return nhost.functions.call('bulk/portfolios/pai/upload-pai-template', {
      file,
      fileBuffer,
      fileId: fileMetadata?.id,
    });
  };

  return {
    downloadTemplate,
    uploadTemplate,
  };
};
