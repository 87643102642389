import { Box, DrawerCloseButton, HStack, VStack } from '@chakra-ui/react';
import { useEsrsMetricData } from 'containers/Esrs/pieces/DisclosureRequirements/Metrics/Metrics.hooks';
import { AdditionalTypeTag } from 'Atoms';
import { Drawer } from 'Molecules/Drawer';
import { MetricTagsList } from 'Molecules/MetricTagsList';
import { Typography, TypographyVariant } from 'Tokens';
import { Loader } from 'Molecules/Loader';
import parse, { DOMNode, domToReact } from 'html-react-parser';

export const LearnMoreContent = ({
  header,
  description,
  customHeader,
}: {
  header?: string;
  description?: string;
  customHeader?: JSX.Element;
}) => (
  <VStack alignItems="first" spacing="24px">
    {customHeader ? (
      customHeader
    ) : (
      <Typography variant="h2" mr="40px">
        {header}
      </Typography>
    )}
    <VStack alignItems="first">
      <Typography variant="h3">Guidance</Typography>
      <Box
        p="16px"
        borderRadius="8px"
        border="1px solid"
        borderColor="border.disabled"
        maxW="300px"
      >
        {parse(description ?? '', {
          replace: (domNode) => {
            if (domNode.type === 'tag' && domNode.name === 'table') {
              return (
                <Box style={{ overflowX: 'auto' }}>
                  <table style={{ tableLayout: 'fixed' }}>
                    {domToReact(domNode.childNodes as unknown as DOMNode[], {
                      replace: (childNode) => {
                        if (childNode.type === 'tag' && childNode.name === 'td') {
                          return (
                            <td style={{ minWidth: '150px', padding: '4px' }}>
                              {domToReact(childNode.childNodes as unknown as DOMNode[])}
                            </td>
                          );
                        }
                      },
                    })}
                  </table>
                </Box>
              );
            }
          },
        })}
      </Box>
    </VStack>
  </VStack>
);

export const LearnMoreDrawer = ({
  isOpen,
  onClose,
  header,
  description,
  customHeader,
}: {
  isOpen: boolean;
  onClose: () => void;
  header?: string;
  description?: string;
  customHeader?: JSX.Element;
}) => {
  return (
    <Drawer isOpen={isOpen} onClose={onClose} size="md" hideHeader={true}>
      <VStack alignItems="first" position="relative">
        <Box>
          <Box position="absolute" pl="40px !important" right="-8px">
            <DrawerCloseButton />
          </Box>
        </Box>
        <LearnMoreContent header={header} description={description} customHeader={customHeader} />
      </VStack>
    </Drawer>
  );
};

export const MetricLearnMoreHeader = ({
  metricRef,
  tags,
  titleVariant = 'h2',
}: {
  metricRef: string;
  tags?: { type: string }[];
  titleVariant?: TypographyVariant;
}) => {
  const { metric, metricRefNumber, metricAdditionalTypes, metricDataLoading } = useEsrsMetricData(
    metricRef ?? ''
  );

  if (metricDataLoading) return <Loader />;

  return (
    <>
      <Typography variant={titleVariant} mr="40px">
        {metric?.shortTitle ?? metric?.title}
        {!!tags?.length && ' by '}
        {!!tags?.length && (
          <MetricTagsList
            tags={tags?.map((tag) => tag.type) ?? []}
            isHStack={false}
            maxWidthInPixels={196}
          />
        )}
      </Typography>
      <VStack alignItems="start">
        <Typography variant="h3">ESRS name</Typography>
        <Typography variant="body">{metric?.title}</Typography>
      </VStack>
      <VStack alignItems="start">
        <Typography variant="h3">ESRS reference</Typography>
        <Typography variant="body">{metricRefNumber}</Typography>
      </VStack>
      {!!metricAdditionalTypes?.length && (
        <VStack alignItems="start">
          <Typography variant="h3">Additional information</Typography>
          <HStack spacing="4px">
            {metricAdditionalTypes?.map((aType) => {
              return (
                <AdditionalTypeTag
                  key={aType.reference}
                  label={aType.title ?? ''}
                  helpText={aType.description ?? ''}
                  maxWidthInPixels={196}
                />
              );
            })}
          </HStack>
        </VStack>
      )}
    </>
  );
};
