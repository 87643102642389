import { Select } from 'Molecules';
import { UserRole_Enum_ } from 'models';
import { useTranslation } from 'utils/translation';

const defaultRole = UserRole_Enum_.Editor_;

export const RoleSelector = ({
  value = defaultRole,
  onChange,
}: {
  value?: UserRole_Enum_;
  onChange: (role: UserRole_Enum_) => void;
}) => {
  const { t } = useTranslation('common');

  const roleOptions = [
    { value: UserRole_Enum_.Admin_, label: t('common:words.admin') },
    { value: UserRole_Enum_.Editor_, label: t('common:words.editor') },
  ];

  return (
    <Select<{ value: UserRole_Enum_; label: string }>
      size="sm"
      width="150px"
      options={roleOptions}
      value={{
        value,
        label: roleOptions.find((r) => r.value === value)?.label ?? value,
      }}
      onChange={(e) => onChange(e?.value ?? defaultRole)}
    />
  );
};
