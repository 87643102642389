import { ColumnDef } from '@tanstack/react-table';
import { ScoreSectionsEnum } from 'models';
import { Table } from 'Molecules';
import { useCurrentCompany } from 'utils/hooks';
import { ObjectiveId } from 'utils/objectives/objectivesOrder';
import { useTranslation } from 'utils/translation';
import {
  ActivitiesCell,
  ActivityStatusCell,
  AlignmentCell,
  ColumnHeader,
  ComplianceCell,
  SubstantialContributionsCell,
  FinancialsCell,
  isMainRow,
} from './TaxonomyResultsTableCells';
import {
  FilteredTaxonomyResultTableRows,
  FilteredTaxonomyResultTableRow,
} from './TaxonomyResultsTableTypes';

export const TaxonomyResultsTable = ({
  rows,
  substantialContributionsColumns,
  scoreSection,
}: {
  rows: FilteredTaxonomyResultTableRows;
  substantialContributionsColumns: ObjectiveId[];
  scoreSection: ScoreSectionsEnum;
}) => {
  const { t } = useTranslation('eu_table');
  const { company } = useCurrentCompany();

  const sCColumns: ColumnDef<FilteredTaxonomyResultTableRow>[] =
    substantialContributionsColumns.map((scKey) => {
      return {
        header: () => (
          <ColumnHeader
            title={t(scKey)}
            subtitle={t('substantialContribution')}
            alignRight={true}
          />
        ),
        accessorKey: scKey,
        enableSorting: false,
        cell: ({ row }) => {
          const score = row.original.columnData[scKey];
          return (
            <SubstantialContributionsCell score={score} mainRow={isMainRow(row.original.rowKey)} />
          );
        },
        minSize: 144,
      };
    });

  const columns: ColumnDef<FilteredTaxonomyResultTableRow>[] = [
    {
      header: () => <ColumnHeader title={t('eu_table:activities')} />,
      accessorKey: 'name',
      enableSorting: false,
      cell: ({ row }) => {
        return (
          <ActivitiesCell
            activityName={row.original.rowName}
            mainRow={isMainRow(row.original.rowKey)}
          />
        );
      },
      minSize: 242,
    },
    {
      header: () => (
        <ColumnHeader
          title={t(`eu_table:financials.${scoreSection}`, { currency: company?.currency ?? 'NOK' })}
          alignRight={true}
        />
      ),
      accessorKey: 'financials',
      enableSorting: false,
      cell: ({ row }) => (
        <FinancialsCell
          financials={row.original.columnData.financials}
          mainRow={isMainRow(row.original.rowKey)}
        />
      ),
      minSize: 112,
    },
    {
      header: () => <ColumnHeader title={t('eu_table:alignment')} alignRight={true} />,
      accessorKey: 'alignment',
      enableSorting: false,
      cell: ({ row }) => (
        <AlignmentCell
          rowName={row.original.rowName}
          alignment={row.original.columnData.alignment}
          mainRow={isMainRow(row.original.rowKey)}
        />
      ),
      minSize: 112,
    },
    ...sCColumns,
    {
      header: () => (
        <ColumnHeader
          title={t('eu_table:noSignificantHarm')}
          tooltip={t('eu_table:resultsTable.tooltip.noSignificantHarm')}
        />
      ),
      accessorKey: 'doNoSignificantHarm',
      enableSorting: false,
      cell: ({ row }) => (
        <ComplianceCell compliance={row.original.columnData.doNoSignificantHarm} />
      ),
      minSize: 146,
    },
    {
      header: () => (
        <ColumnHeader
          title={t('eu_table:minimumSocialSafeguards')}
          tooltip={t('eu_table:resultsTable.tooltip.minimumSocialSafeguards')}
        />
      ),
      accessorKey: 'minimumSocialSafeguards',
      enableSorting: false,
      cell: ({ row }) => (
        <ComplianceCell compliance={row.original.columnData.minimumSocialSafeguards} />
      ),
      minSize: 146,
    },
    {
      header: () => (
        <ColumnHeader
          title={t('eu_table:transitionalOrEnabling')}
          tooltip={t('eu_table:resultsTable.tooltip.activityStatus')}
        />
      ),
      accessorKey: 'activity',
      enableSorting: false,
      cell: ({ row }) => (
        <ActivityStatusCell
          activityStatus={row.original.columnData.activityTagStatus}
          mainRow={isMainRow(row.original.rowKey)}
        />
      ),
      minSize: 162,
    },
  ];
  return (
    <Table
      // arbirarily large pageSize to avoid pagination
      pageSize={200}
      columns={columns}
      data={rows}
      headerPadding="0px 8px 8px 8px"
      cellPadding="0px 8px 0px 8px"
      allowSorting={false}
      headerAlignment="top"
    ></Table>
  );
};
