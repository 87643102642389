import { Divider, HStack } from '@chakra-ui/react';
import { Button, IconButton } from 'Atoms/Buttons';
import { Typography } from 'Tokens';
import { RemoveIcon } from 'Tokens/Icons/Function';

export const StickyToolbar = ({
  onClick,
  count,
  onClose,
  actionText,
  actionIcon,
  text,
}: {
  onClick: () => void;
  count: number;
  onClose: () => void;
  actionText: string;
  actionIcon: JSX.Element;
  text?: string;
}) => {
  return (
    <HStack width="100%" justifyContent="center">
      <HStack
        position="absolute"
        bg="bg.accent"
        justifyContent="space-between"
        width="924px"
        color="text.onAccent"
        borderRadius="8px"
        paddingX="16px"
        paddingY="8px"
        bottom="4%"
        zIndex={1}
      >
        <Typography variant="bodyStrong" color="text.onAccent">
          {count} {text ?? 'selected'}
        </Typography>
        <HStack spacing="16px">
          <Button
            color="text.onAccent"
            variant="ghost"
            size="sm"
            onClick={onClick}
            leftIcon={actionIcon}
          >
            {actionText}
          </Button>
          <Divider
            orientation="vertical"
            border="2px"
            borderRadius="3px"
            height="20px"
            borderColor="border.onAccent"
            backgroundColor="border.onAccent"
          />
          <IconButton
            color="text.onAccent"
            aria-label="ss"
            variant="ghost"
            icon={<RemoveIcon color="inherit" />}
            onClick={onClose}
          />
        </HStack>
      </HStack>
    </HStack>
  );
};
