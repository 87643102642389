import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Esrs_Target_Constraint_,
  Esrs_Target_Update_Column_,
  AttachmentBox_Constraint_,
  AttachmentBox_Update_Column_,
  NoteHistory_Constraint_,
  NoteHistory_Update_Column_,
  useUpsertMultipleMaterialStandardsMutation,
  EsrsAssessmentTargetsDocument_,
  GetDisclosureRequirementGroupsDocument_,
  GetTargetsDocument_,
  GetTargetsDrDocument_,
  GetTargetsSubsidiariesQuery_,
  useCreateShortTargetMutation,
  useUnlinkTargetParentMutation,
  GetTargetDocument_,
  GetTargetQuery_,
  Esrs_Baseline_Constraint_,
} from 'models';
import { useToast } from 'utils/hooks';

const notesAttachments = {
  attachmentBox: {
    data: {},
    on_conflict: {
      constraint: AttachmentBox_Constraint_.AttachmentBoxTargetIdKey_,
      update_columns: [AttachmentBox_Update_Column_.TargetId_],
    },
  },
  noteHistory: {
    data: {},
    on_conflict: {
      constraint: NoteHistory_Constraint_.NoteHistoryTargetIdKey_,
      update_columns: [NoteHistory_Update_Column_.TargetId_],
    },
  },
};

const getSubsidiaryTargetsToInsert = (
  values: {
    name: string;
    ownerId: string;
    isBottomUp: boolean;
  },
  disclosureRequirementRef: string,
  assessments?: {
    id: any;
    assessmentId: any;
  }[],
  selectedMetric?: string,
  subsidiaries?: NonNullable<GetTargetsSubsidiariesQuery_['esrs']>['subsidiaries'],
  targetId?: string,
  keyResults?: NonNullable<GetTargetQuery_['target']>['keyResults']
) => {
  const baseline = keyResults?.find((kr) => kr.reportingUnitId === null);

  return {
    data: [
      ...(subsidiaries?.map((subsidiary) => {
        const subTarget = subsidiary.materialStandards?.[0]?.targets?.find(
          (target) =>
            target.parentTargetId === targetId ||
            (target.parentTargetId === null && target.metricRef === selectedMetric)
        );
        const subTargetId = subTarget?.id;
        const assessmentId =
          subsidiary.materialStandards?.[0]?.id ??
          assessments?.filter((a) => a.assessmentId === subsidiary.id)?.[0]?.id;
        const subBaseline = subTarget?.keyResults?.find((kr) => kr.reportingUnitId === null);
        return {
          id: subTargetId,
          disclosureRequirementRef,
          assessmentId,
          name: 'New target',
          metricRef: selectedMetric,
          isRequired: true,
          ownerId: values.ownerId,
          keyResults: subBaseline
            ? undefined
            : {
                data: [
                  {
                    baseYear: baseline?.baseYear,
                    reportingUnitId: null,
                  },
                ],
                on_conflict: {
                  constraint:
                    Esrs_Baseline_Constraint_.KeyResultBusinessUnitAssessmentIdTargetIdKey_,
                  update_columns: [],
                },
              },
          ...notesAttachments,
        };
      }) ?? []),
    ],
    on_conflict: {
      constraint: Esrs_Target_Constraint_.TargetPkey_,
      update_columns: [
        Esrs_Target_Update_Column_.IsRequired_,
        Esrs_Target_Update_Column_.ParentTargetId_,
      ],
    },
  };
};

export const useAddTargetModal = () => {
  const [createTarget] = useCreateShortTargetMutation();
  const [addAssessments] = useUpsertMultipleMaterialStandardsMutation();
  const [unlinkTarget] = useUnlinkTargetParentMutation();
  const toast = useToast();
  const navigate = useNavigate();

  return useCallback(
    (data: {
      values: {
        name: string;
        ownerId: string;
        isBottomUp: boolean;
      };
      onClose: () => void;
      materialStandardId: string;
      standardRef: string;
      disclosureRequirementRef: string;
      subsidiaries: NonNullable<GetTargetsSubsidiariesQuery_['esrs']>['subsidiaries'] | undefined;
      isGroup: boolean;
      targetToEdit?: {
        id: string;
        metricRef: string;
        ownerId: string;
        name: string;
        isBottomUp?: boolean;
        keyResults?: NonNullable<GetTargetQuery_['target']>['keyResults'];
      };
      selectedMetric: string | undefined;
      isIntensityMetric: boolean;
    }) => {
      const {
        values,
        onClose,
        materialStandardId,
        standardRef,
        disclosureRequirementRef,
        subsidiaries,
        isGroup,
        targetToEdit,
        selectedMetric,
        isIntensityMetric,
      } = data;

      const withSubsidiaries = values.isBottomUp && isGroup;

      const unlinkSubsidiaries =
        values.isBottomUp === false && targetToEdit?.isBottomUp === true && isGroup;

      const missingAssessments = withSubsidiaries
        ? subsidiaries?.filter((s) => !s.materialStandards.length) ?? []
        : [];

      addAssessments({
        variables: {
          materialStandards: missingAssessments.map((assessment) => ({
            assessmentId: assessment.id,
            standardRef,
            isMaterial: true,
          })),
        },
      }).then((res) => {
        createTarget({
          variables: {
            target: {
              id: targetToEdit?.id,
              disclosureRequirementRef,
              assessmentId: materialStandardId,
              name: values.name,
              ownerId: values.ownerId,
              metricRef: selectedMetric,
              isBottomUp: isIntensityMetric ? false : values.isBottomUp,
              ...notesAttachments,
              subsidiariesTargets: withSubsidiaries
                ? getSubsidiaryTargetsToInsert(
                    values,
                    disclosureRequirementRef,
                    res.data?.insert_esrs_MaterialStandard?.returning,
                    selectedMetric,
                    subsidiaries,
                    targetToEdit?.id,
                    targetToEdit?.keyResults
                  )
                : undefined,
            },
          },
          refetchQueries: [
            GetTargetDocument_,
            GetDisclosureRequirementGroupsDocument_,
            GetTargetsDrDocument_,
            GetTargetsDocument_,
            EsrsAssessmentTargetsDocument_,
          ],
          awaitRefetchQueries: true,
        })
          .then((response) => {
            if (unlinkSubsidiaries) {
              const subsidiariesTargetIds = subsidiaries?.map(
                (subsidiary) =>
                  subsidiary.materialStandards?.[0]?.targets?.find(
                    (target) => target.parentTargetId === targetToEdit.id
                  )?.id
              );
              unlinkTarget({
                variables: { ids: subsidiariesTargetIds?.filter((id) => !!id) },
                refetchQueries: [GetTargetsDrDocument_, GetTargetsDocument_, GetTargetDocument_],
              });
            }

            if (targetToEdit) onClose();
            else navigate(`target-form/${response.data?.insert_esrs_Target_one?.id}/edit`);
          })
          .catch(() =>
            toast({
              text: 'Failed to create target.',
              variant: 'danger',
            })
          );
      });
    },
    [createTarget]
  );
};
