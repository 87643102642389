import {
  Table as ChakraTable,
  Thead,
  Tr,
  Th,
  Tbody,
  Box,
  HStack,
  IconButton,
  Td,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import { Typography } from 'Tokens';
import { HelpIcon } from 'Tokens/Icons/Status';
import { ActionQuestionsQuery_, TargetQuestionsQuery_ } from 'models';
import { QuestionType } from 'utils/scores/questions';
import { BooleanInputAnswer } from './BooleanInputAnswer';
import { NumberInputAnswer } from './NumberInputAnswer';
import { TextInputAnswer } from './TextInputAnswer';
import React, { useState } from 'react';
import { LearnMoreDrawer } from 'Molecules';
import { DescriptionIcon } from 'Tokens/Icons/Data';
import { SelectInputAnswer } from './SelectInputAnswer';

export type DRQAnswersType = {
  [key: string]: {
    value: string | number | null;
    factValue: boolean | undefined;
    choices?: string[] | undefined;
  };
};
export type DRQuestionsType = (ActionQuestionsQuery_['ActionQuestion'][number] & {
  choiceSet?: TargetQuestionsQuery_['TargetQuestion'][number]['choiceSet'];
})[];
export type DRQuestionsFields<T extends { answers: DRQAnswersType }> = T;
export enum DRQuestionsEnum {
  answers = 'answers',
}

type LearnMoreType =
  | {
      title: string;
      description: string | null | undefined;
    }
  | undefined;

const Question = ({
  answers,
  setAnswers,
  question,
  setLearnMore,
  onOpen,
}: {
  answers: DRQAnswersType;
  setAnswers: (params: DRQAnswersType) => void;
  question: DRQuestionsType[number];
  setLearnMore: (param: LearnMoreType) => void;
  onOpen: () => void;
}) => {
  return (
    <Tr borderTop={'1px solid'} borderColor="border.decorative" key={question.reference}>
      <Td p="0px" border="none" pl="0px" verticalAlign="top">
        <HStack p="14px 8px" spacing="8px" alignItems="start" height="100%" id={question.reference}>
          <VStack alignItems="center" justifyContent="center" h="20px">
            <DescriptionIcon />
          </VStack>
          <Typography id={question?.reference} variant="body">
            {question.title}
          </Typography>
        </HStack>
      </Td>
      <Td p="0px" border="none" verticalAlign="top" px="8px" py="6px">
        {question.questionType === QuestionType.Decimal_ ? (
          <NumberInputAnswer question={question} answers={answers} setAnswers={setAnswers} />
        ) : question.questionType === QuestionType.YesNo_ ? (
          <BooleanInputAnswer question={question} answers={answers} setAnswers={setAnswers} />
        ) : question.questionType === QuestionType.SingleChoice_ ||
          question.questionType === QuestionType.MultipleChoice_ ? (
          <SelectInputAnswer question={question} answers={answers} setAnswers={setAnswers} />
        ) : (
          <TextInputAnswer question={question} answers={answers} setAnswers={setAnswers} />
        )}
      </Td>
      <Td p="0px 8px" border="none" textAlign="right" verticalAlign="top">
        <Box my="10px">
          <IconButton
            aria-label="side-bar"
            variant="ghost"
            icon={<HelpIcon />}
            onClick={() => {
              setLearnMore({ title: question.title, description: question.description });
              onOpen();
            }}
          />
        </Box>
      </Td>
    </Tr>
  );
};

export const DRQuestionsTable = ({
  answers,
  setAnswers,
  questions,
}: {
  answers: DRQAnswersType;
  setAnswers: (params: DRQAnswersType) => void;
  questions: DRQuestionsType;
}) => {
  const theadStyle = {
    letterSpacing: 'normal',
    borderColor: 'border.decorative',
    px: '8px',
    height: '48px',
  };

  const [learnMore, setLearnMore] = useState<LearnMoreType>();
  const { onOpen, isOpen, onClose } = useDisclosure();

  return (
    <>
      <ChakraTable w="100%">
        <Thead w="100%" borderBottom="1px solid" borderColor="border.decorative">
          <Tr w="100%">
            <Th {...theadStyle} textTransform="none" w="40%">
              <Typography variant="bodyStrong">Data point</Typography>
            </Th>
            <Th {...theadStyle} textTransform="none" w="fit-content">
              <Typography variant="bodyStrong">Answer</Typography>
            </Th>
            <Th {...theadStyle} w="5%"></Th>
          </Tr>
        </Thead>
        <Tbody>
          {questions.map((question) => (
            <Question
              question={question}
              answers={answers}
              setAnswers={setAnswers}
              setLearnMore={setLearnMore}
              onOpen={onOpen}
            />
          ))}
        </Tbody>
      </ChakraTable>
      <LearnMoreDrawer
        header={learnMore?.title}
        isOpen={isOpen}
        onClose={onClose}
        description={learnMore?.description ?? ''}
      />
    </>
  );
};
