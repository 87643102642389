import React, { useState, useMemo } from 'react';
import { Box, HStack, VStack } from '@chakra-ui/react';
import { ContentLayout, Select, SearchInput } from 'Molecules';
import { useTranslation } from 'utils/translation';
import { AllCompaniesTable } from './pieces/AllCompaniesTable';
import usePortfolios, { portfolioOwnerContext } from 'containers/Portfolios/Portfolios.hooks';
import { getFilter } from './AllCompanies.hooks';
import { useCurrentCompanyId } from 'utils/hooks';
import { useCompaniesFromAllPortfoliosQuery } from 'models';
import { EmptyState } from 'Atoms';
import { useNavigate } from 'react-router-dom';
import { CompanyIllustration, NothingFoundIllustration } from 'Tokens';
import { RefreshIcon } from 'Tokens/Icons/Function';

const ALL_PORTFOLIOS_FILTER = 'All portfolios';

const FilterBar = ({
  searchValue,
  setSearchValue,
  selectedPortfolio,
  setSelectedPortfolio,
}: {
  searchValue: string;
  setSearchValue: (value: string) => void;
  selectedPortfolio: string;
  setSelectedPortfolio: (value: string) => void;
}) => {
  const { portfolios } = usePortfolios();

  return (
    <HStack width="100%" justifyContent="space-between">
      <HStack spacing="8px" width="408px">
        <SearchInput
          search={searchValue}
          setSearch={setSearchValue}
          placeholder="Filter companies"
          minW="200px"
        />
        <Select<{ value: string; label: string }>
          value={
            portfolios.find((v) => v.id === selectedPortfolio)
              ? {
                  label: portfolios.find((v) => v.id === selectedPortfolio)?.name ?? '',
                  value: selectedPortfolio,
                }
              : { value: ALL_PORTFOLIOS_FILTER, label: 'All portfolios' }
          }
          onChange={(value) => setSelectedPortfolio(value?.value ? value.value : 'All portfolios')}
          options={[
            ...portfolios.map((portfolio) => {
              return { value: portfolio.id, label: portfolio.name };
            }),
            { value: ALL_PORTFOLIOS_FILTER, label: 'All portfolios' },
          ]}
          size="md"
        />
      </HStack>
    </HStack>
  );
};

export const AllCompanies = () => {
  const { t } = useTranslation('common');
  const [searchValue, setSearchValue] = useState<string>('');
  const [selectedPortfolio, setSelectedPortfolio] = useState<string>(ALL_PORTFOLIOS_FILTER);
  const { companyId } = useCurrentCompanyId();
  const navigate = useNavigate();

  const filter = useMemo(
    () => getFilter(searchValue, selectedPortfolio),
    [searchValue, selectedPortfolio]
  );

  const { data, loading } = useCompaniesFromAllPortfoliosQuery({
    variables: {
      ownerCompanyId: companyId,
    },
    skip: !companyId,
    context: portfolioOwnerContext,
  });
  const allCompaniesArray = useMemo(() => {
    return (data?.allCompanies ?? []).filter(filter);
  }, [filter, data]);

  return (
    <ContentLayout header={t('common:menu.allCompanies')} isLoading={loading}>
      <VStack spacing="24px" width="100%" alignItems="flex-start" flexGrow="1">
        <FilterBar
          searchValue={searchValue}
          setSearchValue={setSearchValue}
          selectedPortfolio={selectedPortfolio}
          setSelectedPortfolio={setSelectedPortfolio}
        />
        {!allCompaniesArray.length && !searchValue ? (
          <Box w="100%" flexGrow="1">
            <EmptyState
              title={t('common:allCompanies.emptyTitle')}
              description={t('common:allCompanies.emptyDescription')}
              callToAction={{
                text: t('common:allCompanies.emptyBtn'),
                variant: 'secondary',
                onClick: () => {
                  navigate('/portfolio');
                },
              }}
              icon={<CompanyIllustration boxSize="120px" />}
            />
          </Box>
        ) : (
          <>
            {allCompaniesArray.length ? (
              <AllCompaniesTable allCompaniesData={allCompaniesArray} />
            ) : (
              <Box w="100%" flexGrow="1">
                <EmptyState
                  title={t('common:search.filter.emptyTitle')}
                  description={t('common:search.filter.emptyDescription')}
                  callToAction={{
                    text: t('common:search.filter.emptyBtn'),
                    variant: 'secondary',
                    onClick: () => {
                      setSearchValue('');
                    },
                    leftIcon: <RefreshIcon color="inherit" />,
                  }}
                  icon={<NothingFoundIllustration boxSize="120px" />}
                />
              </Box>
            )}
          </>
        )}
      </VStack>
    </ContentLayout>
  );
};
