import { businessUnitsSorter } from 'containers/Assessments/pieces/Assessment.hooks';
import { useGetFinancialsQuery, GetFinancialsQuery_, Financials } from 'models';
import { useMemo } from 'react';
import { allFinancialSections, createBareFinancials } from 'utils/financials';

export type CompanyFinancialResults = {
  companyFinancials?: GetFinancialsQuery_['companyFinancials'][0];
  totalFinancials?: GetFinancialsQuery_['totalFinancials'][0] | null;
  businessUnits: Array<{
    id: string;
    reportingGroupId?: string;
    orderIndex: string;
    businessUnit: GetFinancialsQuery_['businessUnits'][0]['businessUnit'];
    financials: GetFinancialsQuery_['businessUnits'][0]['businessUnitFinancials'][0]['financials'];
    activities: Array<{
      activity?: GetFinancialsQuery_['businessUnits'][0]['activities'][0]['activity'];
      financials?: GetFinancialsQuery_['businessUnits'][0]['activities'][0]['financials'];
      id?: string;
      bAssessmentId?: string;
    }>;
  }>;
};

export const toCSVString = (value: number | string | undefined) => {
  if (value === undefined) {
    return '';
  }
  return String(value).replace(/\"/g, '""');
};

export const useFinancials = ({
  cAssessmentId,
  sortAlphabetically,
  groupsFirst,
}: {
  cAssessmentId?: string;
  sortAlphabetically?: boolean;
  groupsFirst?: boolean;
}) => {
  const { data, ...rest } = useGetFinancialsQuery({
    variables: {
      cId: cAssessmentId,
    },
    skip: !cAssessmentId,
    fetchPolicy: 'no-cache',
  });

  const financials: CompanyFinancialResults = useMemo(() => {
    const companyFinancials = data?.companyFinancials[0];
    const totalFinancials = data?.totalFinancials?.[0] ?? null;
    const businessUnits =
      data?.businessUnits.map((bu) => ({
        id: bu.id,
        businessUnit: bu?.businessUnit,
        reportingGroupId: bu?.reportingGroupId,
        orderIndex: bu.orderIndex,
        financials:
          bu?.businessUnitFinancials?.[0]?.financials ??
          (createBareFinancials(
            0,
            0,
            0,
            0
          ) as CompanyFinancialResults['businessUnits'][0]['financials']),
        activities: bu?.activities.map((act) => ({
          activity: act.activity,
          financials:
            act.financials ??
            (createBareFinancials(
              0,
              0,
              0,
              0
            ) as CompanyFinancialResults['businessUnits'][0]['activities'][0]['financials']),
          id: act?.id,
          bAssessmentId: act?.bAssessmentId,
        })),
      })) ?? [];
    if (sortAlphabetically) {
      businessUnits.sort((a, b) =>
        businessUnitsSorter(a.businessUnit?.name ?? '', b.businessUnit?.name ?? '')
      );
    }

    return {
      companyFinancials,
      businessUnits,
      totalFinancials,
    };
  }, [data, sortAlphabetically, groupsFirst]);

  return { financials, ...rest };
};

export const useContainsNegativeNumbers = (cAssessmentId: string) => {
  const { financials } = useFinancials({ cAssessmentId });

  const containsNegativeNumbers = useMemo(() => {
    const financialsArray = [
      financials.companyFinancials,
      ...financials.businessUnits.map((bu) => bu.financials),
      ...financials.businessUnits.flatMap((bu) => bu.activities.map((act) => act.financials)),
    ].filter((fin): fin is Financials => !!fin);

    return financialsArray.some((fin) =>
      Object.entries(fin)
        .filter(([label]) => (allFinancialSections as string[]).includes(label))
        .some(([_, val]) => val < 0)
    );
  }, [financials]);

  return containsNegativeNumbers;
};
