export enum LinkType {
  Taxonomy = 'taxonomy',
  Esrs = 'esrs',
  Pai = 'pai',
  Portfolios = 'portfolios',
}

export const BreadcrumbsList: { [key: string]: { [key: string]: string } } = {
  taxonomy: {
    home: 'EU Taxonomy',
    edit: 'Configure assessment',
    new: 'Create assessment',
  },
  esrs: {
    home: 'ESRS',
    settings: 'Configure assessment',
    config: 'Set company structure',
    report: 'Create report',
    'target-form': 'Add target',
    'action-form': 'Add action',
  },
  pai: {
    home: 'PAI',
  },
  portfolios: {
    home: 'Portfolios',
    edit: 'Configure portfolio period',
    create: ' Add reporting period',
  },
};

export const ID_REGEX = /\/?[\da-f]{8}-[\da-f]{4}-[\da-f]{4}-[\da-f]{4}-[\da-f]{12}/;
export const ESRS_REF_REGEX = /[ESG]\d+-\d+/;

export const PATHS_TO_IGNORE = [
  'materiality',
  'company',
  'standard',
  'data-collection',
  'disclosure-requirement',
  'screening',
  'financials',
  'results',
  'overview',
  'data-input',
  'companies',
  'year',
  'chart',
  'bu-standard',
  'bu',
  'first',
];

export const ESRS_PATHS_TO_IGNORE = [
  'target',
  'action',
  'policy',
  'strategy',
  'governance',
  'strategy',
  'metric',
  'process',
  'other',
  'edit',
  'iro',
  'Basis for preparation',
  'basis for preparation',
];

export const PORTFOLIOS_PATHS_TO_IGNORE = ['eu-taxonomy', 'pai'];

export const PAI_PATHS_TO_IGNORE = ['q1', 'q2', 'q3', 'q4'];

export const PATHS_TO_BREAK_AT = ['/screening', '/basis%20for%20preparation'];
