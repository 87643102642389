export enum Language {
  EnglishUK = 'english-uk',
  EnglishUS = 'english-us',
  French = 'french',
  German = 'german',
  Spanish = 'spanish',
  Dutch = 'dutch',
  Portuguese = 'portuguese',
  Italian = 'italian',
  Norwegian = 'norwegian',
  Swedish = 'swedish',
  Danish = 'danish',
  Finnish = 'finnish',
  Polish = 'polish',
  Turkish = 'turkish',
  Russian = 'russian',
  Romanian = 'romanian',
  Croatian = 'croatian',
  Czech = 'czech',
  Bulgarian = 'bulgarian',
  Greek = 'greek',
}

export const LANGUAGES = [
  {
    value: Language.EnglishUK,
    label: 'English (UK)',
  },
  {
    value: Language.EnglishUS,
    label: 'English (US)',
  },
  {
    value: Language.French,
    label: 'French',
  },
  {
    value: Language.German,
    label: 'German',
  },
  {
    value: Language.Spanish,
    label: 'Spanish',
  },
  {
    value: Language.Dutch,
    label: 'Dutch',
  },
  {
    value: Language.Portuguese,
    label: 'Portuguese',
  },
  {
    value: Language.Italian,
    label: 'Italian',
  },
  {
    value: Language.Norwegian,
    label: 'Norwegian',
  },
  {
    value: Language.Swedish,
    label: 'Swedish',
  },
  {
    value: Language.Danish,
    label: 'Danish',
  },
  {
    value: Language.Finnish,
    label: 'Finnish',
  },
  {
    value: Language.Polish,
    label: 'Polish',
  },
  {
    value: Language.Turkish,
    label: 'Turkish',
  },
  {
    value: Language.Russian,
    label: 'Russian',
  },
  {
    value: Language.Romanian,
    label: 'Romanian',
  },
  {
    value: Language.Croatian,
    label: 'Croatian',
  },
  {
    value: Language.Czech,
    label: 'Czech',
  },
  {
    value: Language.Bulgarian,
    label: 'Bulgarian',
  },
  {
    value: Language.Greek,
    label: 'Greek',
  },
];
