import { Button, HStack, MenuButton, Switch } from '@chakra-ui/react';
import { Menu } from 'Molecules/Menu';
import { Typography } from 'Tokens';
import { ChevronDownIcon } from 'Tokens/Icons/Direction';
import { useTranslation } from 'utils/translation';

const AdvancedDateSwitch = ({
  isChecked,
  onChange,
  isDisabled,
}: {
  isChecked: boolean;
  onChange: () => void;
  isDisabled?: boolean;
}) => {
  const { t } = useTranslation(['portfolio', 'common']);

  return (
    <Menu
      size="md"
      customMenuButton={
        <MenuButton
          as={Button}
          variant="ghost"
          size="sm"
          padding="0px 4px"
          rightIcon={<ChevronDownIcon color="inherit" />}
          _hover={{ bg: 'bg.hover' }}
          isDisabled={isDisabled}
        >
          {t('common:advanced')}
        </MenuButton>
      }
      sections={[
        {
          actions: [
            {
              id: 'enable-advanced-reporting-period',
              render: () => {
                return (
                  <HStack
                    justifyContent="space-between"
                    w="100%"
                    onClick={(e) => e.stopPropagation()}
                  >
                    <Typography color="text.muted" variant="bodyStrong">
                      {t('portfolio:customPeriod')}
                    </Typography>
                    <Switch isChecked={isChecked} onChange={onChange} isDisabled={isDisabled} />
                  </HStack>
                );
              },
            },
          ],
        },
      ]}
    />
  );
};

export default AdvancedDateSwitch;
