import { HStack, VStack } from '@chakra-ui/react';
import { useUserData } from '@nhost/react';
import { Alert, Button, FormField, Input } from 'Atoms';
import { Loader, Modal, Table } from 'Molecules';
import { Typography } from 'Tokens';
import { RefreshIcon } from 'Tokens/Icons/Function';
import {
  EsrsAssessmentsYearsQuery_,
  GetIntegrationApiKeyDocument_,
  useEsrsAssessmentsYearsQuery,
  useGetIntegrationApiKeyQuery,
  useUpsertIntegrationKeyMutation,
} from 'models';
import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useToast } from 'utils/hooks';
import { nhost } from 'utils/nhost';

export const MoreScopeIntegrationModal = ({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: () => void;
}) => {
  const { companyId = '' } = useParams();
  const user = useUserData();
  const toast = useToast();
  const [upsertIntegrationKey] = useUpsertIntegrationKeyMutation();

  const [apiKey, setApiKey] = useState<string>();
  const [isSaving, setIsSaving] = useState(false);
  const [syncObject, setSyncObject] = useState<{ [key: number]: boolean }>();
  const [existingYears, setExistingYears] = useState<number[]>([]);
  const [loading, setLoading] = useState(false);

  const { data, loading: dataLoading } = useGetIntegrationApiKeyQuery({
    variables: {
      companyId,
      integrationSystemName: 'moreScope',
    },
    skip: !companyId,
  });

  const { data: esrsAssessmentsData, loading: esrsAssessmentsLoading } =
    useEsrsAssessmentsYearsQuery({
      variables: {
        companyId,
      },
      skip: !companyId,
    });

  const integration = useMemo(() => data?.esrs_CompanyIntegration?.[0], [data]);

  useEffect(() => setApiKey(integration?.apiKey), [integration]);

  const assessments = useMemo(() => esrsAssessmentsData?.esrsAssessments, [esrsAssessmentsData]);

  const fetchExistingYears = async () => {
    const years = assessments?.map((a) => a.reportingYear);
    if (!years) return;

    setLoading(true);
    const res = await nhost.functions.call('esrs/get-morescope-years', {
      apiKey: integration?.apiKey,
      years: years,
    });

    if (res.error) {
      setExistingYears(years);
    } else {
      setExistingYears((res.res?.data as { result: number[] })?.result);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchExistingYears();
  }, [assessments, integration?.apiKey]);

  const filteredAssessments = useMemo(
    () => assessments?.filter((a) => existingYears?.includes(a.reportingYear)),
    [assessments, existingYears]
  );

  const hasApiKey = useMemo(() => {
    const key = integration?.apiKey;
    return !!key && key !== '';
  }, [integration]);

  const onConfirm = () => {
    setIsSaving(true);
    upsertIntegrationKey({
      variables: {
        object: {
          id: integration?.id,
          companyId: companyId,
          integrationSystemName: 'moreScope',
          apiKey: apiKey,
        },
      },
      refetchQueries: [GetIntegrationApiKeyDocument_],
    })
      .then(() => {
        toast({ text: 'API key saved' });
        setIsSaving(false);
      })
      .catch(() => {
        toast({ text: 'Failed to save ApiKey', variant: 'danger' });
        setIsSaving(false);
      });
  };

  const onAssessmentIntegration = async (values: {
    assessmentId: string;
    year: number;
    projectLeaderId: string;
    companyReportingUnitId: string;
  }) => {
    const { assessmentId, year, projectLeaderId, companyReportingUnitId } = values;
    try {
      setSyncObject({ ...(syncObject ?? {}), [year]: true });
      const res = await nhost.functions.call('esrs/integrate-morescope-data', {
        assessmentId: assessmentId,
        assessmentYear: year,
        companyId: companyId,
        companyReportingUnitId: companyReportingUnitId,
        userId: user?.id ?? '',
        assessmentProjectLeaderId: projectLeaderId,
      });
      if (res.error) {
        setSyncObject({ ...(syncObject ?? {}), [year]: false });
        if (res.error.message === 'E1-6 not assessed') {
          toast({
            text: 'Please assess the disclosure requirement E1-6 in Climate Change first.',
            variant: 'danger',
            closable: true,
            duration: null,
          });
          return;
        } else {
          console.error(res.error);
          throw new Error('Error integrating data: ' + res.error.message);
        }
      }
      setSyncObject({ ...(syncObject ?? {}), [year]: false });
      toast({
        text: 'Data integrated successfully',
      });
    } catch (error) {
      setSyncObject({ ...(syncObject ?? {}), [year]: false });
      console.error(error);
      toast({
        variant: 'danger',
        text: 'Unable to create integrate data. Please review API Key',
      });
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} title="MoreScope integration" hasFooter={false}>
      {loading || esrsAssessmentsLoading || dataLoading ? (
        <Loader />
      ) : (
        <VStack alignItems="start" w="100%" spacing="16px">
          <FormField label="API Key" id="apiKey">
            <HStack>
              <Input
                value={apiKey}
                type="password"
                onChange={(e) => setApiKey(e.target.value)}
                width="100%"
              />
              <Button variant="primary" onClick={onConfirm} isLoading={isSaving}>
                Save
              </Button>
            </HStack>
          </FormField>
          <VStack alignItems="start" w="100%">
            {!hasApiKey && (
              <Alert
                status="warning"
                withIcon={true}
                closable={false}
                title="Add API key to be able to sync data"
              />
            )}
            {!!integration?.apiKey && !!existingYears?.length && (
              <FormField label="Click to sync data for ESRS" id="years">
                <Table<EsrsAssessmentsYearsQuery_['esrsAssessments'][number]>
                  columns={[
                    {
                      header: '',
                      accessorKey: 'name',
                      cell: ({ row }) => (
                        <Typography variant="body">{row.original.reportingYear}</Typography>
                      ),
                    },
                    {
                      header: '',
                      accessorKey: 'year',
                      cell: ({ row }) => {
                        const assessment = row.original;
                        return (
                          <HStack w="100%" justifyContent="flex-end">
                            <Button
                              leftIcon={<RefreshIcon color={hasApiKey ? 'text.action' : ''} />}
                              justifyContent="flex-end"
                              variant="secondary"
                              onClick={() =>
                                onAssessmentIntegration({
                                  year: assessment.reportingYear,
                                  assessmentId: assessment.id,
                                  projectLeaderId: assessment.projectLeaderId ?? '',
                                  companyReportingUnitId: assessment.reportingUnits?.[0]?.id,
                                })
                              }
                              isDisabled={!hasApiKey}
                              isLoading={syncObject?.[assessment.reportingYear]}
                            >
                              Sync
                            </Button>
                          </HStack>
                        );
                      },
                    },
                  ]}
                  headerHeight="0px"
                  headerPadding="0px"
                  headerBorderColor="border.decorative"
                  cellPadding="8px"
                  data={filteredAssessments ?? []}
                />
              </FormField>
            )}
          </VStack>
        </VStack>
      )}
    </Modal>
  );
};
