import { Button, ButtonProps } from 'Atoms';

export const FilterTag = ({
  children,
  selected = false,
  ...props
}: { selected?: boolean } & ButtonProps) => (
  <Button
    variant="secondary"
    selected={selected}
    borderRadius="6px"
    color="text.default"
    _hover={{
      color: 'text.default',
    }}
    size="sm"
    {...props}
  >
    {children}
  </Button>
);
