import { Divider, VStack } from '@chakra-ui/react';
import { Typography } from 'Tokens';

export const SectionHeader = ({ title }: { title: string }) => {
  return (
    <VStack alignItems="flex-start" spacing="8px" w="100%">
      <VStack alignItems="flex-start" spacing="0px" w="100%">
        <Typography variant="h2">{title}</Typography>
      </VStack>
      <Divider height="1px" border="none" background="border.decorative"></Divider>
    </VStack>
  );
};
