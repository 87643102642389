import { AvatarGroup as ChakraAvatarGroup, HStack, VStack } from '@chakra-ui/react';
import { Avatar, AvatarSize } from 'Atoms';
import { Typography } from 'Tokens';
import { getUsernameColor } from 'Organisms/UserAvatar/UserAvatar.utils';
import { TeamMember } from 'containers/TeamMembers';
import { useMemo } from 'react';

export type NameWithLogo = {
  name: string;
  logoUrl?: string;
};

export const AvatarGroup = ({
  names,
  size = 'md',
  truncatedAvatarSize = 'sm',
  namesOrLogos,
  members,
  isRounded = true,
  maximumNumberOfAvatars = 3,
}: {
  names?: string[];
  size?: AvatarSize;
  truncatedAvatarSize?: AvatarSize;
  namesOrLogos?: NameWithLogo[];
  members?: TeamMember[];
  isRounded?: boolean;
  maximumNumberOfAvatars?: number;
}) => {
  const showTruncatedAvatar = useMemo(
    () =>
      (members?.length ?? 0) > maximumNumberOfAvatars ||
      (names?.length ?? 0) > maximumNumberOfAvatars ||
      (namesOrLogos?.length ?? 0) > maximumNumberOfAvatars,
    [members, names, namesOrLogos]
  );
  const truncatedCount = useMemo(() => {
    if (names && names.length > maximumNumberOfAvatars)
      return names.slice(maximumNumberOfAvatars).length;
    else if (namesOrLogos && namesOrLogos.length > maximumNumberOfAvatars)
      return namesOrLogos.slice(maximumNumberOfAvatars).length;
    else if (members && members.length > maximumNumberOfAvatars)
      return members.slice(maximumNumberOfAvatars).length;
  }, [names, namesOrLogos, members]);

  const truncatedTootlip = useMemo(() => {
    if (showTruncatedAvatar) {
      return (
        <VStack alignItems="start" gap="8px" p="4px">
          {names &&
            names.slice(maximumNumberOfAvatars).map((name) => (
              <Typography variant="body" color="text.onAccent" maxW="200px">
                {name}
              </Typography>
            ))}
          {namesOrLogos &&
            namesOrLogos.slice(maximumNumberOfAvatars).map((nameOrLogo) => (
              <Typography variant="body" color="text.onAccent" maxW="200px">
                {nameOrLogo.name}
              </Typography>
            ))}
          {members &&
            members.slice(maximumNumberOfAvatars).map((member) => (
              <Typography variant="body" color="text.onAccent" maxW="200px">
                {member.name ?? member.email}
              </Typography>
            ))}
        </VStack>
      );
    }
    return '';
  }, [names, namesOrLogos, members, showTruncatedAvatar]);

  return (
    <HStack>
      <ChakraAvatarGroup size={size} max={maximumNumberOfAvatars} spacing="-6px">
        {names
          ?.slice(0, maximumNumberOfAvatars)
          .map((name) => <Avatar zIndex={3} name={name} size={size} key={name} />)}
        {namesOrLogos?.slice(0, maximumNumberOfAvatars).map((avatar) => {
          const hasLogo = avatar.logoUrl && !avatar.logoUrl?.includes('default=blank');
          return (
            <Avatar
              key={avatar.name}
              name={avatar.name}
              zIndex={3}
              size={size}
              src={hasLogo ? avatar.logoUrl : undefined}
              bg={getUsernameColor(avatar.name)}
              borderRadius={isRounded ? '50%' : '6px'}
            />
          );
        })}
        {members?.slice(0, maximumNumberOfAvatars).map((member) => {
          const active = member.id && !member.disabled;
          return (
            <Avatar
              key={member.id}
              size="sm"
              zIndex={3}
              name={member?.name ?? member.email}
              color={active ? 'text.onAccent' : 'text.default'}
              backgroundColor={active ? 'bg.accent' : 'bg.disabled.accent'}
            />
          );
        })}
      </ChakraAvatarGroup>
      {showTruncatedAvatar && (
        <Avatar
          ml="-12px"
          zIndex={1}
          size={truncatedAvatarSize}
          name={`+ ${truncatedCount}`}
          tooltip={truncatedTootlip}
          borderRadius={isRounded ? '50%' : '6px'}
        />
      )}
    </HStack>
  );
};
