import { Box, VStack } from '@chakra-ui/react';
import { Esrs_Answer_, QuestionType_Enum_, ShortUser } from 'models';
import { useMemo, Dispatch, SetStateAction, useState, MouseEvent } from 'react';
import { AggregatedMetricsTableData, MetricsTableData } from '../../..';
import { AssessableMetrics } from '../../../Metrics';
import { AnswerPopover } from './AnswerPopover';
import { useGetDatapointValues } from '../../../MetricsTable/MetricsTableComponents/InputFields/QuantitativeInputs';
import { ClickableReportAnswer } from './ClickableReportAnswer';
import { AnswerTextInput } from './AnswerTextInput';
import { getIsMetricLocked } from 'containers/Esrs/utils';
import { SelectedMetric } from 'containers/Esrs';

export const NarrativeReportAnswer = ({
  metric,
  textAnswer,
  aggregatedMetrics,
  companyLevelReportingUnitId,
  currency,
  answersMap,
  materialStandardId,
  projectLeader,
  padding = 24,
  selectedMetric,
  rowData,
  setSelectedMetric,
  setRowData,
}: {
  metric: AssessableMetrics[number];
  companyLevelReportingUnitId?: string;
  aggregatedMetrics: AggregatedMetricsTableData[];
  textAnswer?: string;
  currency?: string;
  answersMap: Map<string, string>;
  projectLeader?: ShortUser;
  materialStandardId: string;
  padding?: number;
  selectedMetric?: SelectedMetric | null;
  rowData?: MetricsTableData;
  setSelectedMetric: (metric: SelectedMetric) => void;
  setRowData: Dispatch<SetStateAction<MetricsTableData | undefined>>;
}) => {
  const [isHovered, setIsHovered] = useState(false);
  const [hasHoveredChild, setHasHoveredChild] = useState(false);
  const { dataPointPerYear, onDatapointChange, answer } = useGetDatapointValues(
    {
      metric,
    },
    companyLevelReportingUnitId ?? ''
  );

  const areChildrenSelected = useMemo(() => {
    if (metric.childrenMetrics.length) {
      const checkChildren = (child: AssessableMetrics[number]): boolean => {
        if (child.childrenMetrics.length) {
          return child.childrenMetrics.some((m) => m.childMetric && checkChildren(m.childMetric));
        }
        return selectedMetric === child;
      };
      return metric.childrenMetrics.some((m) => m.childMetric && checkChildren(m.childMetric));
    }
    return false;
  }, [metric, selectedMetric]);

  const hasChildren = useMemo(() => metric.childrenMetrics.length, [metric]);

  const isStandaloneMetric = useMemo(
    () => !hasChildren && !metric.parentMetrics.length,
    [metric, hasChildren]
  );

  const isChildButNotParent = useMemo(
    () => metric.parentMetrics.length && !hasChildren,
    [metric, hasChildren]
  );

  const [hasBeforeInfo, hasAfterInfo] = useMemo(
    () => [
      dataPointPerYear?.metadata?.before?.isVisible,
      dataPointPerYear?.metadata?.after?.isVisible,
    ],
    [dataPointPerYear]
  );

  const isSelected = useMemo(
    () => selectedMetric?.reference === metric.reference,
    [selectedMetric, metric]
  );

  const isMetricLocked = useMemo(
    () => getIsMetricLocked(metric.unlockCondition, dataPointPerYear?.factValue ?? ''),
    [metric, dataPointPerYear]
  );

  const handleClick = (e?: MouseEvent) => {
    e?.stopPropagation();
    setSelectedMetric(metric as SelectedMetric);
    if (selectedMetric === metric) setRowData(undefined);
    else setRowData({ metric });
  };

  const findAggregatedMetric = (childMetric: AggregatedMetricsTableData['metric']) => {
    const foundMetric = aggregatedMetrics.find(
      (mt) => mt.metric.reference === childMetric.reference
    );
    if (foundMetric) return foundMetric;

    if (childMetric.parentMetrics.length) {
      let subRow;
      childMetric.parentMetrics.some((m) => {
        const parentMetric = aggregatedMetrics.find(
          (met) => met.metric.reference === m.parentMetricRef
        );

        if (parentMetric) {
          subRow = parentMetric.subRows?.find(
            (subR) => subR.metric.reference === childMetric.reference
          );

          if (subRow) return true;
        }
        return false;
      });
      return subRow;
    }
  };

  return (
    <VStack
      alignItems="stretch"
      position="relative"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      cursor="pointer"
      width={`calc(100% + ${padding}px)`}
      ml={`-${padding}px`}
      paddingLeft={`${padding}px`}
      _before={{
        content: `''`,
        position: 'absolute',
        top: 0,
        left: 0,
        bottom: 0,
        opacity: isHovered || isSelected || areChildrenSelected ? 1 : 0,
        width: '2px',
        borderLeft: hasChildren && !isSelected ? '2px dotted' : '2px solid',
        borderColor: 'border.selected.accent',
        transition: 'opacity 0.3s',
      }}
      boxSizing="border-box"
      gap="8px"
      borderColor="border.selected.accent"
      paddingY="4px"
      bg={isSelected ? 'bg.selected' : 'none'}
      transition="all 0.4s"
      onClick={handleClick}
    >
      {!hasHoveredChild && (
        <AnswerPopover
          metric={metric}
          padding={padding}
          owner={answer?.datapoints?.[0]?.owner ?? projectLeader}
          isVisible={isHovered}
        />
      )}
      {hasChildren ? (
        <VStack
          alignItems="start"
          gap="8px"
          w="100%"
          onMouseEnter={() => setHasHoveredChild(true)}
          onMouseLeave={() => setHasHoveredChild(false)}
        >
          {hasBeforeInfo && (
            <Box paddingY="4px" w="100%">
              <AnswerTextInput
                key={metric.reference}
                metricRef={metric.reference}
                textAnswer={dataPointPerYear?.metadata.before.text}
                placeholder={
                  dataPointPerYear?.metadata.before
                    ? undefined
                    : `Write your answer (${metric.title})`
                }
                answer={answer as Esrs_Answer_}
                dataPointPerYear={dataPointPerYear}
                onDatapointChange={onDatapointChange}
                placement={'before'}
                isSelected={isSelected}
                onFocus={handleClick}
              />
            </Box>
          )}
          <VStack alignItems="start" gap="8px" w="100%">
            {metric.metricType === QuestionType_Enum_.YesNo_ && (
              <AnswerTextInput
                key={metric.reference}
                metricRef={metric.reference}
                textAnswer={textAnswer ?? ''}
                placeholder={textAnswer ? undefined : `Write your answer (${metric.title})`}
                answer={answer as Esrs_Answer_}
                dataPointPerYear={dataPointPerYear}
                onDatapointChange={onDatapointChange}
                onFocus={handleClick}
                isSelected={isSelected}
              />
            )}
            {!isMetricLocked &&
              metric.childrenMetrics.map(
                ({ childMetric }) =>
                  childMetric &&
                  (childMetric.metricType === QuestionType_Enum_.Decimal_ ? (
                    <ClickableReportAnswer
                      metric={childMetric as AssessableMetrics[number]}
                      aggregatedMetric={findAggregatedMetric(childMetric)}
                      materialStandardId={materialStandardId}
                      companyLevelReportingUnitId={companyLevelReportingUnitId}
                      currency={currency}
                      projectLeader={projectLeader}
                      setSelectedMetric={setSelectedMetric}
                      setRowData={setRowData}
                      rowData={rowData}
                      padding={padding - 8}
                      selectedMetric={selectedMetric}
                    />
                  ) : (
                    <NarrativeReportAnswer
                      key={childMetric.reference}
                      metric={childMetric as AssessableMetrics[number]}
                      aggregatedMetrics={aggregatedMetrics}
                      textAnswer={answersMap.get(childMetric.reference)}
                      answersMap={answersMap}
                      materialStandardId={materialStandardId}
                      companyLevelReportingUnitId={companyLevelReportingUnitId}
                      currency={currency}
                      selectedMetric={selectedMetric}
                      projectLeader={projectLeader}
                      padding={padding - 8}
                      setSelectedMetric={setSelectedMetric}
                      rowData={rowData}
                      setRowData={setRowData}
                    />
                  ))
              )}
          </VStack>
        </VStack>
      ) : (
        isChildButNotParent && (
          <>
            <AnswerPopover
              metric={metric}
              padding={padding}
              owner={answer?.datapoints?.[0]?.owner ?? projectLeader}
              isVisible={isHovered}
            />
            <AnswerTextInput
              key={metric.reference}
              metricRef={metric.reference}
              textAnswer={textAnswer}
              placeholder={textAnswer ? undefined : `Write your answer (${metric.title})`}
              answer={answer as Esrs_Answer_}
              dataPointPerYear={dataPointPerYear}
              onDatapointChange={onDatapointChange}
              isSelected={isSelected}
              onFocus={handleClick}
            />
          </>
        )
      )}
      {hasAfterInfo && (
        <Box paddingY="4px">
          <AnswerTextInput
            key={metric.reference}
            metricRef={metric.reference}
            textAnswer={dataPointPerYear?.metadata.after.text}
            placeholder={
              dataPointPerYear?.metadata.before ? undefined : `Write your answer (${metric.title})`
            }
            answer={answer as Esrs_Answer_}
            dataPointPerYear={dataPointPerYear}
            onDatapointChange={onDatapointChange}
            placement={'after'}
            isSelected={isSelected}
            onFocus={handleClick}
          />
        </Box>
      )}
      {isStandaloneMetric && (
        <>
          <AnswerPopover
            metric={metric}
            padding={padding}
            owner={answer?.datapoints?.[0]?.owner ?? projectLeader}
            isVisible={isHovered}
          />
          <AnswerTextInput
            key={metric.reference}
            metricRef={metric.reference}
            textAnswer={textAnswer ?? ''}
            placeholder={textAnswer ? undefined : `Write your answer (${metric.title})`}
            answer={answer as Esrs_Answer_}
            dataPointPerYear={dataPointPerYear}
            onDatapointChange={onDatapointChange}
            onFocus={handleClick}
            isSelected={isSelected}
          />
        </>
      )}
    </VStack>
  );
};
