import { HStack, VStack } from '@chakra-ui/react';
import { Typography } from 'Tokens/Typography';
import React, { useMemo } from 'react';
import { useTranslation } from 'utils/translation';
import { useCurrentCompany } from 'utils/hooks';
import { GetReportsQuery_ } from 'models';
import { HomeAssessments } from './HomeAssessments';

export const HomeReportsCards = ({
  data,
  companyId,
}: {
  data: GetReportsQuery_ | undefined;
  companyId: string;
}) => {
  const { t } = useTranslation('home');
  const { company } = useCurrentCompany();
  const isGroup = useMemo(() => company?.isGroupOwner ?? false, [company]);

  return (
    <VStack alignItems="stretch" justifyContent="flex-start" spacing="0px">
      <HStack height="100%" mb="8px" justifyContent="space-between">
        <Typography variant="bodyStrong" color="text.default">
          {t('home:cards.assessments')}
        </Typography>
        <Typography variant="body">Sorted by: last edited</Typography>
      </HStack>
      <HStack spacing="16px" height="100%" alignItems="stretch">
        <HomeAssessments assessments={data} isGroup={isGroup} companyId={companyId} />
      </HStack>
    </VStack>
  );
};
