export type GeneratedNumericAnswer = {
  metricTitleWithTag: string;
  answer: string;
  metricRef: string;
  metricTitle: string;
  tags?: {
    tagType: string;
    tagValue: string;
  }[];
};

export type GeneratedNarrativeAnswer = {
  metricTitle: string;
  metricRef: string;
  answer: string;
};

export enum GeneratedAnswerStatus {
  approved = 'approved',
  discarded = 'discarded',
}

export type GeneratedAnswer = {
  metricTitleWithTag: string;
  answer: string;
  metricRef: string;
  metricTitle: string;
  tags?: {
    tagType: string;
    tagValue: string;
  }[];
  status: GeneratedAnswerStatus | null;
};
