const colors = [
  'bg.decor.01',
  'bg.decor.02',
  'bg.decor.03',
  'bg.decor.04',
  'bg.decor.05',
  'bg.decor.06',
  'bg.decor.07',
  'bg.decor.08',
];

const stringToHash = (str?: string): number => {
  let hash = 0;
  if (str === undefined || str.length === 0) {
    return hash;
  }
  for (let i = 0; i < str.length; i++) {
    const char = str.charCodeAt(i);
    hash = (hash << 5) - hash + char;
    hash |= 0;
  }
  return Math.abs(hash);
};

export const getUsernameColor = (username?: string): string => {
  const hash = stringToHash(username);
  const colorIndex = hash % colors.length;
  return colors[colorIndex];
};
